import Bugsnag from "@bugsnag/js";
import React, { Component } from "react";
import cookie from "react-cookies";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
//Import Icon Css
import "./assets/css/materialdesignicons.min.css";
import ScrollToTop from "./components/common/scrollToTop";
import routes from "./routes";
// Import Scss
import "./theme.scss";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    try {
      if (!cookie.load("source_"))
        cookie.save(
          "source_",
          window.location.href + " prev:" + document?.referrer,
          {
            path: "/",
            maxAge: 8640000,
            secure: true,
          }
        );
      // if ("geolocation" in window.navigator) {
      //   /* geolocation is available */
      //   window.navigator.geolocation.getCurrentPosition((position) => {
      //     cookie.save(
      //       "locationsource_",
      //       JSON.stringify(
      //         position?.coords?.latitude + "," + position?.coords?.longitude
      //       ),
      //       {
      //         path: "/",
      //         maxAge: 8640000,
      //         secure: true,
      //       }
      //     );
      //   });
      // } else {
      //   /* geolocation IS NOT available */
      // }
    } catch (e) {
      Bugsnag.notify(e);
    }

    return (
      <React.Fragment>
        <Router>
          <ScrollToTop />
          <Switch>
            {routes.map((route, idx) => (
              <Route
                path={route.path}
                component={route.component}
                key={idx}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
