import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Professional 4 ton Services in Port Elizabeth"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/4-ton-port-elizabeth"}
          pageHeading={"Professional 4 ton Services in Port Elizabeth"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get professional 4 ton services in Port Elizabeth with QikTruck. Our team of experienced drivers and state-of-the-art vehicles ensure that your deliveries are made with the highest level of professionalism and reliability. Sign up now and start enjoying the peace of mind that comes with our services."}
          pageDescription2={"Need professional 4 ton services in Port Elizabeth? QikTruck has you covered! Our team of experienced drivers and state-of-the-art vehicles ensure that your deliveries are made with the highest level of professionalism and reliability. From package delivery to furniture transportation, we have the solutions you need to ensure a smooth and stress-free experience. Sign up now and start enjoying the peace of mind that comes with our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
