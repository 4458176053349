import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/about"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="hero-wrapper mb-4 about-us">
                  <h1 id="about">Available job positions</h1>
                  <p className="quote">
                    "QikTruck makes it easy to find reliable and capable
                    vehicles and drivers for all of your transportation needs."
                  </p>
                  <br />
                  <h3 id="contact">
                    {" "}
                    <a
                      href="https://careers.qiktruck.co.za"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-arrow-right"></i>
                      Click here to Register on our Recruitment Portal
                      <i className="mdi mdi-arrow-right"></i>
                    </a>
                  </h3>
                  <br />
                  <br />
                  <br />
                  <br />
                  <h4>Available Job Positions:</h4>
                  <ul>
                    <li>
                      <p>Finance Intern</p>
                    </li>
                    <li>
                      <p>Logistics Administrator</p>
                    </li>
                    <li>
                      <p>Field Agent - Sales representative</p>
                    </li>
                    <li>
                      <p>Social Media Manager</p>
                    </li>
                    <li>
                      <p>Social Media Influencer - 50K+ followers only</p>
                    </li>
                    <li>
                      <p>ReactJS Developer</p>
                    </li>
                    <li>
                      <p>NodeJS Developer</p>
                    </li>
                    <li>
                      <p>+ In Service Training</p>
                    </li>
                  </ul>
                  <h4>Available Partnerships:</h4>
                  <ul>
                    <li>
                      <p>Vehicle Finance </p>
                    </li>
                    <li>
                      <p>Vehicle Maintenance </p>
                    </li>
                    <li>
                      <p>Vehicle Cleaning </p>
                    </li>
                    <li>
                      <p>Vehicle Parts supplier </p>
                    </li>
                    <li>
                      <p>Vehicle Fuel supplier </p>
                    </li>
                    <li>
                      <p>Real Estate Agent discounts</p>
                    </li>
                  </ul>
                  <br />
                  Send email{" "}
                  <a href="mailto:support@qiktruck.co.za">
                    support@qiktruck.co.za if you are a business that would like
                    to partner with us.
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
