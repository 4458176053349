import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import Marquee from "react-fast-marquee";
import Stories from "react-insta-stories";
import validator from "./validator";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import LetterByLetter from "../../components/common/LetterByLetter";
import ServicesList from "../../components/common/ServicesList";
import VehiclesTypes from "../../components/Vehicles/Vehicles";
import constants from "../../Constants";
import Suggestions from "../../Suggestions";
import util from "../../util";
import reviews from "../articles/reviews";
import { parseInt } from "lodash";

const DevAPIURL = constants.DevAPIURLPROD;
const suggestions = Suggestions.suggestions;

const ErrorList = ({ errors }) => (
  <ul className="errors-list">
    {errors.map((error, index) => (
      <li key={index}>
        <a href={`#${error.property}`}>{error.error}</a>
      </li>
    ))}
  </ul>
);
class Section extends Component {
  constructor() {
    super();

    let promoCode = "";
    let vehicleId = "";
    let justbook = "";
    let deliverylat = "";
    let description = "";
    let deliverylng = "";
    let collectionlat = "";
    let collectionlng = "";
    let category = "handheld-items";
    let runners = 1;
    let floors = 0;
    let email = "";
    let mobilenumber = "";
    let nointro = "";

    try {
      const queryParams = new URLSearchParams(window.location.search);
      nointro = queryParams.get("nointro") || "";
      promoCode = queryParams.get("promocode") || "";
      justbook = queryParams.get("justbook") || "";
      description = queryParams.get("description") || "";
      deliverylat = queryParams.get("deliverylat") || "";
      deliverylng = queryParams.get("deliverylng") || "";
      collectionlat = queryParams.get("collectionlat") || "";
      collectionlng = queryParams.get("collectionlng") || "";
      category = queryParams.get("category") || "handheld-items";
      runners = queryParams.get("runners") || 1;
      floors = queryParams.get("floors") || 0;
      email = queryParams.get("email") || "";
      mobilenumber = queryParams.get("mobilenumber") || "";
      vehicleId = queryParams.get("v") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      justbook,
      nointro,
      loading: false,
      success: false,
      trackYourOrder: false,
      openSuccessModal: false,
      openGoodsEditor: false,
      acceptedOffer: "",
      apierror: "",
      orderid: "",
      goodslist: [],
      collection: {
        lat: collectionlat,
        lng: collectionlng,
      },
      delivery: {
        lat: deliverylat,
        lng: deliverylng,
      },
      fullQuote: {
        tripdistance: 0,
        tripduration: 0,
        sellingprice: 0,
        storedQuoteId: "",
        trucklist: [],
      },
      firstname: "",
      lastname: "",
      email,
      mobilenumber,
      collectiondate: "",
      collectiontime: "",
      description,
      vehicletypes: [],
      vehiclecategories: [],
      showOtherCategories: false,
      usercategory: category,
      vehicleconfigurations: {},
      openDiscountCode: false,
      promoCode: promoCode || "",
      vehicleId: vehicleId || "",
      runners,
      floors,
      vehicleProfile: {},
      stories: [],
      expanded: [],
      goodtype: "",
      length: 0,
      width: 0,
      height: 0,
      kgs: 1,
      quantity: 1,
      goodsitemindex: -1,
      searchgoods: "",
    };
  }

  componentDidMount() {
    if (this.validStepOne()) this.getQuote();
  }

  validStepOne() {
    const {
      delivery,
      collection,
      firstname,
      lastname,
      email,
      mobilenumber,
      collectiondate,
      collectiontime,
      description,
      goodslist,
    } = this.state;
    if (email.length === 0) return false;
    if (mobilenumber.length === 0) return false;
    if (delivery.lat === 0) return false;
    if (collection.lat === 0) return false;
    return !(
      (collection.lat === delivery.lat && collection.lng === delivery.lng) ||
      firstname.length === 0 ||
      lastname.length === 0 ||
      email.length === 0 ||
      mobilenumber.length === 0 ||
      collectiondate.length === 0 ||
      collectiontime.length === 0 ||
      goodslist.length === 0 ||
      description.length === 0
    );
  }

  getQuote() {
    const {
      delivery,
      collection,
      usercategory,
      promoCode,
      runners,
      floors,
      description,
      goodslist,
      firstname,
      lastname,
      email,
      mobilenumber,
      collectiondate,
      collectiontime,
    } = this.state;
    if (!this.validStepOne()) return;

    this.setState({
      loading: true,
      apierror: "",
      acceptedOffer: "",
      fullQuote: {
        tripdistance: 0,
        tripduration: 0,
        sellingprice: 0,
        storedQuoteId: "",
        discount: "",
        trucklist: [],
      },
    });
    axios
      .post(
        DevAPIURL + "/dimensions-quote",
        {
          category: usercategory,
          promocode: promoCode,
          runners,
          floors,
          source_:
            util.navigator_() + util.source_() + " qtref:" + document?.referrer,
          quotevehicleid: this.state.vehicleProfile.vehicleid,
          description,
          delivery,
          collection,
          email,
          mobilenumber,
          firstname,
          lastname,
          collectiondate,
          collectiontime,
          goods: goodslist.map((g) => ({
            type: g.goodtype,
            width: parseFloat(g.width),
            height: parseFloat(g.height),
            length: parseFloat(g.length),
            kgs: parseFloat(g.kgs),
            quantity: parseFloat(g.quantity),
          })),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: constants.API_AUTH_KEY,
          },
        }
      )
      .then((response) => {
        if (response.data.sellingprice > 0) {
          this.setState(
            {
              fullQuote: {
                tripdistance: response.data.tripdistance,
                tripduration: response.data.tripduration,
                sellingprice: response.data.sellingprice,
                storedQuoteId: response.data.quoteid,
                trucklist: response.data.trucklist,
                discount: response.data.discount,
              },
            },
            () => {
              window.document.getElementById("fullquote").scrollIntoView();
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          apierror: "Check your addresses and try again.",
        });
        Bugsnag.notify(e);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }
  acceptQuote() {
    this.setState({
      loading: true,
      apierror: "",
    });
    const { fullQuote } = this.state;

    axios
      .post(
        DevAPIURL + "/accept-quote",
        {
          acceptedquoteid: fullQuote.storedQuoteId,
          acceptedoffer: "sellingprice",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: constants.API_AUTH_KEY,
          },
        }
      )
      .then((response) => {
        this.setState(
          {
            loading: false,
            success: response.status === 200,
            openSuccessModal: true,
            quoteid: response?.data?.quoteid,
          },
          () => {
            if (response.status === 200 && response?.data?.quoteid?.length > 0)
              cookie.save(
                "currentOrder",
                `email=${this.state.email}&quoteid=${response.data.quoteid}`,
                {
                  path: "/",
                  maxAge: 86400,
                  secure: true,
                }
              );
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Request failed. Please try again.",
        });
      });
  }

  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }

  render() {
  
    const {
      vehicletypes,
      promoCode,
      runners,
      floors,
      loading,
      length,
      goodtype,
      width,
      height,
      kgs,
      quantity,
    } = this.state;
    return (
      <React.Fragment>
        <div id={"pagefragmenttop"}></div>
        {this.state?.stories?.length > 0 && (
          <Stories
            stories={this.state?.stories}
            defaultInterval={1500}
            width={"100vw"}
            height={"100vh"}
            onAllStoriesEnd={() => this.setState({ stories: [] })}
          />
        )}
        {this.props.seo}
        <section className="hero-section" id={"pagetop"}>
          <Modal isOpen={this.state.success && this.state.openSuccessModal}>
            <ModalHeader>
              <i className={"mdi mdi-check orange-circle  order-received"}></i>
              Order Received
            </ModalHeader>
            <ModalBody>
              <ListGroup className="black-text">
                <ListGroupItem>
                  <i
                    className={
                      "mdi mdi-check orange-circle order-received-list"
                    }
                  ></i>
                  A driver will be in touch shortly to confirm your order.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-email order-received-list"}></i>
                  You will receive an email with your invoice shortly.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  You can pay now or after the delivery.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  Follow the link in your email to make a payment.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-account-alert order-received-list"}
                  ></i>
                  Produce your ID when the driver arrives.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={
                      "mdi mdi-check orange-circle order-received-list"
                    }
                  ></i>
                  Happy moving!
                </ListGroupItem>
              </ListGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.setState(
                    {
                      openSuccessModal: false,
                      trackYourOrder: true,
                    },
                    () => {
                      window.location.reload();
                    }
                  )
                }
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.openDiscountCode}>
            <ModalHeader>
              <i className={"mdi mdi-clipboard-text order-received"}></i>
              More options
            </ModalHeader>
            <ModalBody>
              <Label> Add Helpers </Label>
              <br />
              <span>
                How many people do you need to carry and load the goods? The
                driver may not be able to help.
              </span>
              <Input
                id="runners"
                name="runners"
                type="number"
                defaultValue={runners}
                min={0}
                onChange={(e) => {
                  this.setState({ runners: parseInt(e.target.value, 10) });
                }}
              />
              <br />
              <br />
              <Label>Add Floors </Label>
              <br />
              <span>
                How many floors are we required to carry the goods? Add more
                information in your description later.
              </span>
              <Input
                id="floors"
                name="floors"
                type="number"
                min={0}
                defaultValue={floors}
                onChange={(e) => {
                  this.setState({ floors: parseInt(e.target.value, 10) });
                }}
              />
              <br />
              <br />
              <Label>Discount code</Label>
              <br />
              <span>Lucky one! Our Discount codes are up to 30% off!</span>
              <Input
                id="promocode"
                name="promocode"
                type="promocode"
                defaultValue={promoCode}
                placeholder="Type your discount code here |"
                onChange={(e) => {
                  this.setState({ promoCode: e.target.value.toUpperCase() });
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    openDiscountCode: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.getQuote();
                  this.setState({
                    openDiscountCode: false,
                  });
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.openGoodsEditor}>
            <ModalHeader>
              <i className={"mdi mdi-clipboard-text order-received"}></i>
              Add an item to your trip
            </ModalHeader>
            <ModalBody>
              <div className="dimensions-item">
                <Label> Description</Label>
                <br />
                <Input
                  id="goodtype"
                  name="text"
                  type="textarea"
                  defaultValue={goodtype}
                  style={{ minHeight: 20 }}
                  placeholder="Describe your item here"
                  onChange={(e) => {
                    this.setState({ goodtype: e.target.value });
                  }}
                />
                <br />
              </div>
              <div className="dimensions">
                <div className="dimensions-item">
                  <Label>Length</Label>
                  <br />
                  <Input
                    id="length"
                    name="length"
                    type="number"
                    defaultValue={length}
                    min={0}
                    onChange={(e) => {
                      this.setState({ length: parseFloat(e.target.value, 10) });
                    }}
                  />
                  <Label>Metres each</Label>
                </div>
                <div className="dimensions-item">
                  <Label>Width</Label>
                  <br />
                  <Input
                    id="width"
                    name="width"
                    type="number"
                    defaultValue={width}
                    min={0}
                    onChange={(e) => {
                      this.setState({ width: parseFloat(e.target.value, 10) });
                    }}
                  />
                  <Label>Metres</Label>
                </div>
                <div className="dimensions-item">
                  <Label>Height</Label>
                  <Input
                    id="height"
                    name="height"
                    type="number"
                    defaultValue={height}
                    min={0}
                    onChange={(e) => {
                      this.setState({ height: parseFloat(e.target.value, 10) });
                    }}
                  />
                  <Label>Metres</Label>
                </div>
                <div className="dimensions-item">
                  <Label>Weight</Label>
                  <br />
                  <Input
                    id="kgs"
                    name="kgs"
                    type="number"
                    defaultValue={kgs}
                    min={0}
                    onChange={(e) => {
                      this.setState({ kgs: parseFloat(e.target.value, 10) });
                    }}
                  />
                  <Label>KGs each</Label>
                </div>
                <div className="dimensions-item">
                  <Label> Quantity </Label>
                  <br />
                  <Input
                    id="quantity"
                    name="quantity"
                    type="number"
                    defaultValue={quantity}
                    min={0}
                    onChange={(e) => {
                      this.setState({ quantity: parseInt(e.target.value, 10) });
                    }}
                  />
                  <Label>Units</Label>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {this.state.goodsitemindex >= 0 && (
                <i
                  className="mdi mdi-close remove-goods-item"
                  onClick={() => {
                    let goodslist = this.state.goodslist;
                    goodslist.splice(this.state.goodsitemindex, 1);
                    this.setState({
                      goodslist,
                      goodsitemindex: -1,
                      openGoodsEditor: false,
                    });
                  }}
                >
                  remove
                </i>
              )}
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    openGoodsEditor: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  let goodslist = this.state.goodslist;
                  if (this.state.goodsitemindex >= 0)
                    goodslist[this.state.goodsitemindex] = {
                      goodtype,
                      length,
                      width,
                      height,
                      kgs,
                      quantity,
                    };
                  else
                    goodslist.push({
                      goodtype,
                      length,
                      width,
                      height,
                      kgs,
                      quantity,
                    });

                  this.getQuote();
                  this.setState(
                    {
                      openGoodsEditor: false,
                      goodslist,
                    },
                    () => {
                      window.document
                        .getElementById("goodslist")
                        .scrollIntoView();
                    }
                  );
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </Modal>
          <Container>
            {!this.state.vehicleProfile?.firstname &&
              !this.state.justbook &&
              this.state.nointro !== "true" && (
                <Row>
                  <Col lg={12}>
                    <div className="hero-wrapper mb-4">
                      {this.props.heading || (
                        <h2 className="main-headline">
                          <LetterByLetter />
                          <br />
                          <VehiclesTypes />
                        </h2>
                      )}
                      {this.props.description && (
                        <p>{this.props.description}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            {!this.state.vehicleProfile?.firstname &&
              this.state.nointro !== "true" &&
              (this.props.services || <ServicesList />)}

            <div className="store-front">
              <Row>
                <Col lg={12}>
                  <div className="divvyup b-center"></div>
                </Col>
                <Col lg={12}>
                  <div
                    className="hero-wrapper mb-4 goods-list-start"
                    id={"quotestart"}
                  >
                    <Row>
                      <Col lg={12} sm={12}>
                        <h4 id="goodslist">List your goods</h4>
                        {
                          <>
                            <button
                              className="add-goods-item"
                              onClick={() =>
                                this.setState({
                                  openGoodsEditor: true,
                                  goodsitemindex: -1,
                                  goodtype: "",
                                  length: 0,
                                  width: 0,
                                  height: 0,
                                  kgs: 1,
                                  quantity: 1,
                                })
                              }
                            >
                              <i className="mdi mdi-plus"></i>
                              Add new item{" "}
                            </button>
                            <div className="goods-list">
                              {this.state.goodslist.length === 0 && (
                                <div className="goods-list-item empty-list">
                                  <i className="mdi mdi-close"></i>
                                  No goods listed
                                  <br />
                                  <br />
                                </div>
                              )}

                              {this.state.goodslist.map((good, index) => (
                                <div
                                  key={index}
                                  className="goods-list-item"
                                  onClick={() => {
                                    this.setState({
                                      openGoodsEditor: true,
                                      ...good,
                                      goodsitemindex: index,
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-check"></i>
                                  <span className="goodtype">
                                    {good.goodtype}
                                  </span>
                                  {!good.label ? (
                                    <>
                                      <span className="volume">
                                        {good.length}x{good.width}x{good.height}{" "}
                                        (m)
                                      </span>
                                      <span className="kgs">
                                        {good.kgs}kg each
                                      </span>
                                    </>
                                  ) : (
                                    <span className="kgs">{good.label}</span>
                                  )}
                                  <i className="mdi mdi-close remove-goods-item">
                                    remove
                                  </i>
                                </div>
                              ))}
                              <div className="goods-list-item hint">
                                approx:{" "}
                                {this.state.goodslist.reduce((acc, good) => {
                                  return acc + good.quantity;
                                }, 0)}{" "}
                                items
                              </div>
                            </div>

                            <div className="search-goods">
                              <input
                                className="search-goods-input"
                                placeholder="Search example goods"
                                onChange={(e) => {
                                  this.setState({
                                    searchgoods: e.target.value,
                                  });
                                }}
                                onFocus={(e) => {
                                  this.setState({
                                    searchgoods: e.target.value || " ",
                                  });
                                }}
                              />
                              {this.state.searchgoods?.length > 0 && (
                                <div className="search-goods-list">
                                  <div className="close-search-goods-list">
                                    <i
                                      className="mdi mdi-close"
                                      onClick={() => {
                                        this.setState({
                                          searchgoods: "",
                                        });
                                      }}
                                    ></i>
                                  </div>

                                  {suggestions
                                    .filter((item) =>
                                      item.item
                                        .toLowerCase()
                                        .includes(
                                          this.state.searchgoods?.toLowerCase()
                                        )
                                    )
                                    .map((suggestion, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="search-goods-list-item"
                                          onClick={() => {
                                            let goodslist =
                                              this.state.goodslist;
                                            goodslist.push({
                                              goodtype: suggestion.item,
                                              length: suggestion.length,
                                              width: suggestion.width,
                                              height: suggestion.height,
                                              kgs: suggestion.kgs,
                                              quantity: suggestion.quantity,
                                              label: suggestion.label,
                                            });

                                            this.setState({
                                              goodslist,
                                              searchgoods: "",
                                            });
                                          }}
                                        >
                                          <span className="goodtype">
                                            <span>1 x</span> {suggestion.item}
                                          </span>
                                          {!suggestion.label ? (
                                            <>
                                              <span className="volume">
                                                {suggestion.length}x
                                                {suggestion.width}x
                                                {suggestion.height} m
                                              </span>
                                              <span className="kgs">
                                                {suggestion.kgs}kg
                                              </span>
                                            </>
                                          ) : (
                                            <span className="kgs">
                                              {suggestion.label}
                                            </span>
                                          )}
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </>
                        }
                      </Col>
                      <Col lg={12}>
                        <div className="divvyup b-center"></div>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup autoComplete="new-password">
                          <h3 className="font-18" id={"addresses"}>
                            From address{" "}
                          </h3>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="collection"
                            placeholder=""
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error("COLLECTION place not found: ")
                                );
                              }
                              this.setState({
                                collection: {
                                  lat: place?.geometry?.location?.lat(),
                                  lng: place?.geometry?.location?.lng(),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup className="mb-4" autoComplete="new-password">
                          <h3 className="font-18">Delivery address </h3>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="delivery"
                            placeholder=""
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error("DELIVERY place not found: ")
                                );
                              }
                              this.setState({
                                delivery: {
                                  lat: place?.geometry?.location?.lat(),
                                  lng: place?.geometry?.location?.lng(),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FormGroup>
                          <Input
                            id="description"
                            name="text"
                            type="textarea"
                            defaultValue={this.state.description}
                            style={{ minHeight: 60 }}
                            placeholder="Instructions for our drivers *"
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} sm={12}>
                        <FormGroup>
                          <div
                            onClick={() =>
                              this.setState({ openDiscountCode: true })
                            }
                            className="moreoptions"
                          >
                            {floors} Floor{floors === 1 ? "" : "s"}. {runners}{" "}
                            Helper
                            {runners === 1 ? "" : "s"}.
                            {promoCode?.length > 0
                              ? "- Discount code: " + promoCode
                              : " Discounts"}
                            {" >"}
                            <span></span>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={12}>
                        <div className="divvyup b-center"></div>
                      </Col>

                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="firstname">Your Firstname</Label>
                          <Input
                            id="firstname"
                            name="firstname"
                            type="text"
                            onChange={(e) => {
                              this.setState({ firstname: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="lastname">Surname</Label>
                          <Input
                            id="lastname"
                            name="lastname"
                            type="text"
                            onChange={(e) => {
                              this.setState({ lastname: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Input
                            id="email"
                            name="email"
                            placeholder="Email *"
                            defaultValue={this.state.email}
                            onChange={(e) => {
                              this.setState({
                                email: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>{" "}
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Input
                            id="mobilenumber"
                            name="mobilenumber"
                            placeholder="Mobile number *"
                            defaultValue={this.state.mobilenumber}
                            onChange={(e) => {
                              this.setState({
                                mobilenumber: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiondate">Collection date</Label>
                          <Input
                            id="collectiondate"
                            name="collectiondate"
                            placeholder="Collection date"
                            type="date"
                            onChange={(e) => {
                              this.setState({ collectiondate: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiontime">Time</Label>
                          <Input
                            id="collectiontime"
                            name="collectiontime"
                            placeholder="Time"
                            type="time"
                            onChange={(e) => {
                              this.setState({ collectiontime: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="instant-quotations-parent">
                      <Button
                        className="instant-quotations"
                        color="primary"
                        onClick={() => {
                          this.getQuote();
                        }}
                        disabled={!this.validStepOne() || loading}
                      >
                        GET QUOTATIONS
                        <i className="mdi mdi-arrow-down-bold ml-2"></i>
                      </Button>
                    </div>
                    <ErrorList errors={validator(this.state)} />
                    {this.state.loading === false &&
                      this.state.apierror.length > 0 && (
                        <p className="error-message">{this.state.apierror}</p>
                      )}
                  </div>
                </Col>
              </Row>
              {this.state.loading === true && (
                <div className="loading center">
                  <div className="loading-text">Loading ...</div>
                  <br />
                  <div className="loading-text">Calculating ...</div>
                </div>
              )}
              <div className="divvy b-center"></div>
              {this.state.fullQuote.sellingprice > 0 && (
                <div id="fullquote">
                  <span className="trip-info-price">
                    <CurrencyFormat
                      value={this.state.fullQuote.sellingprice / 100} //cents to rands
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"R"}
                      decimalScale={2}
                    />
                  </span>
                  <br />
                  <p className="trip-info">
                    <span>
                      {Math.round(this.state.fullQuote.tripdistance / 1000)} km
                      for{" "}
                    </span>
                    <span>
                      {Math.round(this.state.fullQuote.tripduration / 60)}{" "}
                      minutes
                      <i className="mdi mdi-star ml-2"></i>
                    </span>{" "}
                    best local price.
                  </p>
                  <Button
                    className="b-center mb-4"
                    color="primary"
                    onClick={() => {
                      this.acceptQuote();
                    }}
                    disabled={this.state.loading}
                  >
                    Accept Quotation
                    <i className="mdi mdi-arrow-right ml-2"></i>
                  </Button>
                  <br />
                  {this.state.loading === true && (
                    <div className="loading center">
                      <div className="loading-text">Finding a driver ...</div>
                    </div>
                  )}
                </div>
              )}
              <div id="trucklist">
                {this.state.fullQuote.trucklist?.length > 0 &&
                  this.state.fullQuote.trucklist?.map((trk, index) => {
                    const im = this.getImage(trk.type);
                    return (
                      <div
                        className="trucklisttype"
                        key={index + trk.type}
                        onClick={() => {
                          this.acceptQuote();
                        }}
                      >
                        <img src={im} alt={trk.type} />{" "}
                        <span>{trk.goods.toFixed(2)} ton</span>
                      </div>
                    );
                  })}
              </div>
            </div>

            <h3 className="font-18">Customer reviews</h3>
            <Marquee
              gradient={true}
              gradientWidth={0}
              speed={6}
              direction="right"
            >
              {reviews.map((a, index) => (
                <div key={index}>
                  <div className="home-reviews">
                    <h1>{a.heading}</h1>
                    <div className="date-article-added">
                      <i className="mdi mdi-clock-outline ml-2"></i>
                      {a.date}
                      <i className="mdi mdi-pin ml-2"></i>
                      {a.location}
                      {Array.from({ length: a.category }).map((x, index) => (
                        <i className="mdi mdi-star" key={index}></i>
                      ))}
                    </div>
                    <p>{a.copy}</p>
                  </div>
                </div>
              ))}
            </Marquee>
            <br />
          </Container>
          <div style={{ display: "none", color: "white", height: 0, width: 0 }}>
            {vehicletypes?.map((v, index) => {
              const image = this.getImage(v.type);
              return (
                <div key={index}>
                  <h2>{v.type}</h2>
                  <img src={image} alt={v.tip.shortform} />
                  <h2>{v.type}</h2>
                  <h2>{v.displaytype}</h2>
                  <h2>{v.longform}</h2>
                </div>
              );
            })}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
