import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-snapshot";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

Bugsnag.start({
  apiKey: "fc2543c37d2f9ab50c8c2d6c9f33420e",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const app = (
  <BrowserRouter>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>
);
// ReactDOM.render(app, document.getElementById("root"));
const rootElement = document.getElementById("root");
render(app, rootElement);
serviceWorker.unregister();
