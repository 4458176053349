import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/investors"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>
                  QikTruck: <span className="orange">Moving goods</span>
                  <span className="green">on-demand</span>
                </h1>
                <br />
                <br />
                <h2>Vision Statement:</h2>
                <ul>
                  <li>
                    Our vision at QikTruck is to be the go-to solution for
                    anyone looking to move goods quickly and efficiently.
                  </li>
                  <li>
                    We see our fleet of owner drivers and rental vehicles
                    providing a unique and affordable option for our customers.
                  </li>
                  <li>
                    We are committed to empowering people to move goods through
                    our innovative on-demand delivery and truck hire services.
                  </li>
                  <li>
                    We are dedicated to providing high-quality service at a
                    reasonable price, with a focus on customer satisfaction and
                    value.
                  </li>
                </ul>
                <h2>Mission Statement:</h2>
                <ul>
                  <li>
                    Our mission at QikTruck is to make it easy for people to
                    move anything, anytime, with the convenience of our online
                    solutions.
                  </li>
                  <li>
                    Our team of experienced and professional drivers is equipped
                    with the expertise and experience to handle any situation
                    with speed and efficiency.
                  </li>
                  <li>
                    We offer a range of moving options to fit any budget,
                    including on-demand deliveries, truck hire, and a fleet of
                    owner drivers and rental vehicles.
                  </li>
                  <li>
                    We believe that our unique approach to on-demand deliveries
                    and truck hire empowers people to move goods in a way that
                    is convenient, affordable, and efficient.
                  </li>
                  <li>
                    Trust us to provide you with the high-quality service you
                    need to get your goods where they need to go.
                  </li>
                </ul>
                <h2>Values:</h2>
                <ul>
                  <li>
                    <strong>Customer Satisfaction:</strong> Our top priority is
                    ensuring customer satisfaction through well insured services
                    and timely delivery of goods.
                  </li>
                  <li>
                    <strong>Transparency:</strong> We make sure to clearly post
                    all offerings and prices before purchase.
                  </li>
                  <li>
                    <strong>Value:</strong> We offer competitive rates and a
                    variety of moving options to fit every budget.
                  </li>
                  <li>
                    <strong>Expertise:</strong> Our drivers are highly trained
                    and experienced, able to handle any situation with speed and
                    efficiency.
                  </li>
                </ul>

                <h2>Who We Are:</h2>
                <ul>
                  <li>
                    A young team of professional Computer Scientists,
                    Logisticians, Marketers, Accountants and Drivers
                  </li>
                  <li>
                    Dedicated to providing on-demand services for short or long
                    distance transportation..
                  </li>
                  <li>
                    Committed to providing high-quality service at a reasonable
                    price
                  </li>
                  <li>
                    Equipped with the expertise and experience to handle any
                    situation with speed and efficiency.
                  </li>
                </ul>
                <h2>What We Offer:</h2>
                <ul>
                  <li>On-demand movement of goods from point A to point B.</li>
                  <li>Truck and driver hire</li>
                  <li>A fleet of owner drivers and rental vehicles</li>
                  <li>Convenient online solutions</li>
                  <li>Route optimisation</li>
                  <li>Supplier database analytics</li>
                  <li>
                    Clear pricing and a range of options to fit any budget
                  </li>
                </ul>
                <h2>Why Choose Us:</h2>
                <ul>
                  <li>Customer satisfaction is our top priority</li>
                  <li>We are transparent about our prices and services</li>
                  <li>
                    We offer competitive rates and a variety of moving options
                  </li>
                  <li>Our drivers are highly trained and experienced</li>
                  <li>
                    We are dedicated to providing high-quality service at a
                    reasonable price.
                  </li>
                </ul>
                <p>
                  QikTruck is a digital platform that connects people in need of
                  transportation services with local professional drivers who
                  have vehicles with load capacity. Our platform allows
                  communities to control their expenses and manage their
                  transportation needs by providing a fast and readily available
                  method to move goods of any size and form from point A to
                  point B. <br />
                  <br />
                  We have created user-friendly software for our customers,
                  driver and partners, making it easy for people to find and
                  connect with local professional drivers in their community.
                  All vehicles on our platform are carefully vetted and
                  inspected to ensure they meet our high standards for safety
                  and reliability. Our drivers are trained to provide top-notch
                  service, so you can trust that your goods will be transported
                  safely and efficiently. <br />
                  <br />
                  At QikTruck, we take safety and insurance very seriously. All
                  of our drivers are required to have appropriate insurance
                  coverage, and we provide additional insurance coverage for
                  goods in transit. We also have a robust safety program in
                  place, with regular training and assessments to ensure that
                  our drivers are always operating at the highest level of
                  safety. In the unlikely event that an accident occurs, our
                  dedicated support team is available 24/7 to assist with any
                  issues and help resolve any problems. We are committed to
                  providing the best possible service to our customers and the
                  communities we serve, and we take every measure to ensure that
                  our platform is safe and reliable for everyone involved.
                </p>
                <br />
                <br />
                <p>
                  QikTruck was created by a group of Computer Scientists and
                  Logistics Experts led by Manu Matsiyana, located in Gauteng
                  South Africa. They saw a need for a more efficient and
                  cost-effective way for people to connect with local
                  professional drivers for their transportation needs and
                  created the QikTruck platform to address this need. Using
                  their knowledge of software engineering and their passion for
                  logistics, the team developed the QikTruck platform and
                  launched the company in Randburg in January 2022. QikTruck has
                  since attracted over 3000 partner drivers with readily
                  available load capacity in South Africa. <br />
                  <br />
                  Fleet load capacity:{" "}
                  <strong>10,000 tonnes available daily.</strong> <br />
                  Fleet estimated value: <strong>R950 Million.</strong> <br />
                  Monthly quotations requests: <strong>15000.</strong>
                  <br />
                  This has attracted interest from Business Development,
                  Marketing, and Logistics experts who have joined and teamed up
                  to work on and improve the offerings from QikTruck. With over
                  1000 applications every month for available positions beyond
                  the current working capacity of the organisation. Our
                  operations have also attracted partnership interest from large
                  corporations and reputable industry leaders such as Imperial
                  Logistics.
                  <br /> Our platform connects people in need of transportation
                  services with local professional drivers who have vehicles
                  with load capacity. This allows communities to control their
                  expenses and manage their transportation needs by providing a
                  fast and readily available method to move goods of any size
                  and form from point A to point B. We have created
                  user-friendly software for our customers and driver app,
                  making it easy for people to find and connect with local
                  professional drivers in their community. All vehicles on our
                  platform are carefully vetted and inspected to ensure they
                  meet our high standards for safety and reliability. Our
                  drivers are trained to provide top-notch service, so you can
                  trust that your goods will be transported safely and
                  efficiently. <br /> <br />
                  <strong>Driver marketplace</strong>
                  <br /> <br />
                  QikTruck operates a driver marketplace that connects drivers
                  with customers who need transportation services. Drivers can
                  sign up for free and create a profile that includes their
                  vehicle type, load capacity, and other information. Customers
                  can then search for drivers in their area and request a quote
                  for their transportation needs. <br /> <br />
                  The QikTruck teams dedicates a good amount of time creating
                  partnerships with companies that provide subsidized services
                  to registered drivers.
                  <br /> <br />
                  <ul>
                    <li>
                      Driver Health Care Services:{" "}
                      <a
                        href="https://welo.health/ "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://welo.health/{" "}
                      </a>
                    </li>
                    <li>
                      Driver Health Care Services:{" "}
                      <a href="/welo" target="_blank" rel="noopener noreferrer">
                        https://qiktruck.co.za/welo
                      </a>
                    </li>
                    <li>
                      Vehicle insurance:
                      <a
                        href="https://www.outsurance.co.za/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.outsurance.co.za/{" "}
                      </a>
                    </li>
                    <li>Vehicle tracking: </li>
                    <li>Goods in transit insurance:</li>
                    <li>Vehicle maintenance: </li>
                    <li>Vehicle parts: </li>
                    <li>Food outlets for drivers: </li>
                  </ul>
                  <br /> <br />
                  With QikTruck, you can trust that your transportation needs
                  will be handled by reliable and capable vehicles and drivers
                  using safe and reliable vehicles. We are dedicated to
                  providing the best possible service to our customers and the
                  communities we serve. In addition to our platform and
                  software, we also offer a range of other services to support
                  our customers and drivers. For example, we provide training
                  and support to drivers to help them improve their skills and
                  knowledge, ensuring that they are always ready to provide the
                  best possible service. We also offer a range of tools and
                  resources to help our customers plan and manage their
                  transportation needs, including a comprehensive online
                  dashboard that provides real-time tracking and updates. At
                  QikTruck, we are committed to being a leader in the
                  transportation industry. We are constantly innovating and
                  improving our platform and services, and we are dedicated to
                  providing the best possible experience for our customers and
                  drivers. Whether you are a business owner in need of
                  transportation services or a local professional driver looking
                  for work, QikTruck is here to help you succeed.
                </p>
                <p>
                  Please contact: <b> support@qiktruck.co.za </b>for more
                  information.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
