import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import driverdashboards from "./driverdashboards";
import ContactUs from "../../components/common/ContactUs";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/driver-guide"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="hero-wrapper mb-4 driverguide">
                  <h3 id="about">Partner Driver Guide</h3>
                  <p>
                    Congratulations if you received this link from our support
                    team.
                    <br />
                    You are now one step from reaching delivery targets and
                    peace of mind.
                  </p>
                  <a
                    href="https://play.google.com/store/apps/details?id=za.co.qiktruck.driver"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2>Download your mobile app here</h2>
                    <img
                      src="https://media.qiktruck.co.za/download-our-app-from-playstore.jpg"
                      width={200}
                      alt="download-our-app-from-playstore"
                    />
                  </a>
                  <br />
              
                  <br />
                  <br />
                  <h3 id="howitworks">How to log in as a Driver</h3>

                  <ul style={{ listStyle: "numbered" }}>
                    <li>
                      <p>
                        <a href="/partner-drivers?dutq=driverguide">
                          Register your vehicle first
                        </a>
                        , then go to{" "}
                        <a
                          href="https://driver.qiktruck.co.za/dashboard"
                          rel="noopener noreferrer"
                        >
                          driver.qiktruck.co.za
                        </a>
                      </p>
                    </li>
                    {driverdashboards.map((dd) => (
                      <li>
                        <div>
                          <div className="driverguideinfo">
                            <h6 className="driverguideheader">
                              {dd.captionHeader}
                            </h6>
                            <div className="driverguidetext">
                              {dd.captionText}
                            </div>
                          </div>
                          {dd.srcs.map((src) => (
                            <img
                              alt={dd.alt}
                              src={src}
                              className="driverguideimg"
                            />
                          ))}
                          <br />
                          <br />
                        </div>
                      </li>
                    ))}

                    <li>
                      <h5>Happy moving</h5>
                      <p>
                        The numbers you see in the screenshots are for example
                        purposes only.
                      </p>
                    </li>
                  </ul>

                  <h3 id="contact">Contact Us</h3>
                  <p>
                    If you have any questions and feebdack about this service,
                    You can contact us:
                  </p>
                  <ContactUs />
                  <br />
                  <br />
                  <img
                    src="https://media.qiktruck.co.za/become-a-partner.gif"
                    alt="become-a-partner"
                    width="100%"
                    maxWidth="500px"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
