import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import SEO from "react-seo-component";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import constants from "../../Constants";
import util from "../../util";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();
    // let directUserToQuote = "";
    // try {
    //   const queryParams = new URLSearchParams(window.location.search);
    //   directUserToQuote = queryParams.get("dutq") || "";
    //   // if (!directUserToQuote || directUserToQuote.length === 0)
    //     // window.location.href = "/quote";
    // } catch (error) {
    //   Bugsnag.notify(error);
    // }
    this.state = {
      loading: false,
      openSuccessModal: false,
      truckType: "",
      apierror: "",
      location: {
        lat: 0,
        lng: 0,
      },
      firstname: "",
      lastname: "",
      email: "",
      mobilenumber: "",
      idnumber: "",
      vin: "",
      licenseplatenumber: "",
      vehicles: 0,
      vehicletypes: [],
    };
    // this.registrationform = React.createRef();
    // this.vehiclepicker = React.createRef();
  }
  validStepOne() {
    const {
      truckType,
      location,
      firstname,
      lastname,
      email,
      mobilenumber,
      idnumber,
      licenseplatenumber,
    } = this.state;
    if (
      truckType === "" ||
      firstname?.length < 2 ||
      lastname?.length < 1 ||
      email?.length < 5 ||
      mobilenumber?.length < 8 ||
      idnumber?.length < 5 ||
      licenseplatenumber?.length < 5 ||
      truckType === ""
    )
      return false;
    if (location.lat === 0) return false;
    return true;
  }

  registerVehicle() {
    const {
      truckType,
      location,
      firstname,
      lastname,
      email,
      mobilenumber,
      idnumber,
      licenseplatenumber,
    } = this.state;
    this.setState({ loading: true, apierror: "" });
    axios
      .post(baseURL + "/register-vehicle", {
        truckType,
        location,
        firstname,
        lastname,
        email,
        mobilenumber,
        idnumber,
        licenseplatenumber,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        if (response.data.vehicles > 0) {
          this.setState({
            loading: false,
            vehicles: response.data.vehicles,
            openSuccessModal: true,
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, apierror: e.message });
        Bugsnag.notify(e);
      });
  }

  render() {
    const { vehicletypes } = this.state;
    if (vehicletypes.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
        });
      });
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and movers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/partner-driver.jpeg"}
          pathname={"https://qiktruck.co.za/partner-drivers"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section" id="location">
          <Modal isOpen={this.state.openSuccessModal}>
            <ModalHeader>
              <i className={"mdi mdi-check-circle order-received"}></i>
              Vehicle registered successfully
            </ModalHeader>
            <ModalBody>
              <ListGroup className="black-text">
                <ListGroupItem>
                  <i className={"mdi mdi-email order-received-list"}></i>
                  Check your email for your account information.
                </ListGroupItem>

                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  Follow the instructions in the email.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-check-circle order-received-list"}></i>
                  Download the driver mobile app!
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-check-circle order-received-list"}></i>
                  Reviews and inspections take about 20 business days.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-check-circle order-received-list"}></i>
                  Share and talk about QikTruck with your network!
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-check-circle order-received-list"}></i>
                  Happy moving!
                </ListGroupItem>
              </ListGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.setState(
                    {
                      openSuccessModal: false,
                      trackYourOrder: true,
                    },
                    () => {
                      window.location.href = "https://driver.qiktruck.co.za";
                    }
                  )
                }
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          <Container>
            <h5 className="text-uppercase" id={"vehiclepicker"}>
              Vehicle registration for jobs
            </h5>
            <Row>
              <Col lg={6}>
                <div className="hints">
                  <p>
                    <i className={"mdi mdi-check-circle order-received"}></i>
                    Registration is free.{" "}
                  </p>
                  <p>
                    <i className={"mdi mdi-check-circle order-received"}></i>
                    Commission to QikTruck on all trips. 20% - 40% as service fee including VAT depending on vehicle size and contract requirements.{" "}
                  </p>
                  <p>
                    <i className={"mdi mdi-check-circle order-received"}></i>
                    Short term and long term contracts.{" "}
                  </p>
                  <p>
                    <i className={"mdi mdi-check-circle order-received"}></i>
                    Local and long distance trips.{" "}
                  </p>
                  <p>
                    <i className={"mdi mdi-star orange order-received"}></i>
                    <i className={"mdi mdi-star orange order-received"}></i>
                    <i className={"mdi mdi-star orange order-received"}></i>
                    <i className={"mdi mdi-star orange order-received"}></i>
                    <i className={"mdi mdi-star orange order-received"}></i>
                    Rated service.{" "}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="hints">
                  <p>
                    <b>Step 1: </b>
                    <i
                      className={"mdi mdi-arrow-right orange order-received"}
                    ></i>
                    Register your vehicle below
                  </p>
                  <p>
                    <b>Step 2: </b>
                    <i
                      className={"mdi mdi-arrow-right orange order-received"}
                    ></i>{" "}
                    Online documents support@qiktruck.co.za
                  </p>
                  <p>
                    <b>Step 3: </b>
                    <i
                      className={"mdi mdi-arrow-right orange order-received"}
                    ></i>{" "}
                    Application processing
                  </p>
                  <p>
                    <b>Step 4: </b>
                    <i
                      className={"mdi mdi-arrow-right orange order-received"}
                    ></i>{" "}
                    Driver training and certification free
                  </p>
                  <p>
                    <b>Step 5: </b>
                    <i
                      className={"mdi mdi-arrow-right orange order-received"}
                    ></i>{" "}
                    Customer allocation per vehicle location
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <h6>Pick your vehicle size</h6>
              <Col lg={12}>
                <div className="hero-wrapper mb-4">
                  <Row className="mb-5 landing-menu">
                    {vehicletypes
                      .filter((v) => v.display === true)
                      ?.map((vehicle) => {
                        const image = `https://media.qiktruck.co.za/${vehicle.type}.png`;
                        return (
                          <Col
                            key={vehicle.type}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={6}
                            xl={2}
                            className={"grey-border-bottom "}
                            onClick={() =>
                              this.setState(
                                {
                                  truckType: vehicle.type,
                                },
                                () => {
                                  window.document
                                    .getElementById("registrationform")
                                    .scrollIntoView();
                                }
                              )
                            }
                          >
                            <div
                              className={
                                this.state.truckType === vehicle.type
                                  ? "selected-truck"
                                  : ""
                              }
                            ></div>
                            <img
                              src={image}
                              alt=""
                              className={
                                "menu-truck " +
                                (this.state.truckType === vehicle.type
                                  ? "menu-truck-selected"
                                  : "")
                              }
                              height="20"
                            />
                            <i
                              className={
                                "mdi mdi-check-circle ml-2 " +
                                (this.state.truckType === vehicle.type
                                  ? "checked-truck"
                                  : "hide")
                              }
                            ></i>
                            <p className="menu-truck-tip">
                              {vehicle.tip.shortform}
                              <br /> <b>±{vehicle.tonnes} Ton</b>
                            </p>
                          </Col>
                        );
                      })}{" "}
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={6}
                      xl={2}
                      className={"grey-border-bottom "}
                    ></Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <p id={"registrationform"}></p>
            <h3>Let's create your profile</h3>
            <p>
              We use this information for criminal checks, vehicle theft
              reports, fraud checks and other means to secure customer property.
              <br />
            </p>
            <hr />
            <Row>
              <Col lg={6} sm={6}>
                <FormGroup>
                  <Label for="firstname">
                    Owner/Driver Firstname{" "}
                    <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="firstname"
                    name="firstname"
                    type="text"
                    onChange={(e) => {
                      this.setState({ firstname: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6} sm={6}>
                <FormGroup>
                  <Label for="lastname">
                    Surname <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="lastname"
                    name="lastname"
                    type="text"
                    onChange={(e) => {
                      this.setState({ lastname: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={6}>
                <FormGroup>
                  <Label for="email">
                    Email <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6} sm={6}>
                <FormGroup>
                  <Label for="mobilenumber">
                    Mobile number <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="mobilenumber"
                    name="mobilenumber"
                    type="tel"
                    onChange={(e) => {
                      this.setState({ mobilenumber: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={6}>
                <FormGroup>
                  <Label for="idnumber">
                    ID/Passport number{" "}
                    <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="idnumber"
                    name="idnumber"
                    onChange={(e) => {
                      this.setState({ idnumber: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6} sm={6}>
                {/* <FormGroup>
                  <Label for="vin">
                    Vehicle VIN <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="vin"
                    name="vin"
                    onChange={(e) => {
                      this.setState({ vin: e.target.value });
                    }}
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="licenseplatenumber">
                    Vehicle License Plate Number{" "}
                    <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <Input
                    id="licenseplatenumber"
                    name="licenseplatenumber"
                    onChange={(e) => {
                      this.setState({ licenseplatenumber: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={6}>
                <FormGroup className="mb-4">
                  <Label className="font-15">
                    Vehicle Location <span style={{ color: "#e03c31" }}>*</span>
                  </Label>
                  <PlacesAutocomplete
                    type="text"
                    className="form-control"
                    id="location"
                    placeholder="enter street address"
                    required
                    onPlaceSelected={(place) => {
                      if (!place?.geometry?.location?.lat()) {
                        Bugsnag.notify(
                          new Error(
                            "V REGISTRATION place not found: " +
                              this.state.email
                          )
                        );
                      }
                      this.setState({
                        location: {
                          lat: place?.geometry?.location?.lat(),
                          lng: place?.geometry?.location?.lng(),
                        },
                      });
                    }}
                  />
                  {this.state.location.lat === 0 ||
                  this.state.location.lat === 0 ? (
                    <small className="red">Select your vehicle location</small>
                  ) : (
                    <p>Location set</p>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {this.state.loading === false && this.state.apierror.length > 0 && (
              <p className="error-message">{this.state.apierror}</p>
            )}
            <div>
              <Button
                className="float-right mb-4"
                color="primary"
                size="sm"
                onClick={() => {
                  this.registerVehicle();
                }}
                disabled={!this.validStepOne() || this.state.loading}
              >
                Register vehicle
                <i className="mdi mdi-arrow-up-bold ml-2"></i>
              </Button>
              <div
                className="selected-button-truck"
                onClick={() =>
                  window.document
                    .getElementById("vehiclepicker")
                    .scrollIntoView()
                }
              >
                <div className="highlight"></div>
                {this.state.truckType?.length > 0 ? (
                  <>
                    <img
                      src={`https://media.qiktruck.co.za/${this.state.truckType}.png`}
                      alt=""
                    />
                    <br />
                    <span style={{ color: "grey" }}>Change vehicle?</span>
                  </>
                ) : (
                  <span style={{ color: "red" }}>Select a vehicle</span>
                )}
              </div>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
