import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import Bugsnag from "@bugsnag/js";
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyD2BbvjWvF73TNFX3c0BTBlN6kmNc0Rheg");
export default function LatLngToAddress({ lat, lng }) {
  const [address, setAddress] = useState("");
  useEffect(() => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        setAddress(response.results[0].formatted_address);
      },
      (error) => {
        Bugsnag.notify(error);
      }
    );
  }, [lat, lng]);

  return <>{address}</>;
}
