import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Row } from "reactstrap";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "../landing/section";
class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "services", navheading: "Services" },
        { id: 3, idnm: "features", navheading: "Features" },
        { id: 4, idnm: "clients", navheading: "Clients" },
        { id: 6, idnm: "pricing", navheading: "Plans" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          seo={
            <SEO
              title={
                "Hire a Bakkie with QikTruck for your deliveries. Online quotation today!"
              }
              titleTemplate={"QikTruck"}
              titleSeparator={`-`}
              description={
                "QikTruck helps you hire bakkies and save your time. Hire the bakkie and driver you need today, without waiting."
              }
              image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
              pathname={"https://qiktruck.co.za/bakkie-hire"}
              twitterUsername={"qiktrucksa"}
            />
          }
          heading={
            <h2 className="main-headline">
              Moving your house, furniture or goods?
            </h2>
          }
          services={
            <>
              <Row>
                <Col lg={6} md={6} className="other-services">
                  <h3 className="font-18">Experienced movers</h3>
                  <p>
                    <i className="mdi mdi-star"></i>Door to door goods
                    deliveries.
                  </p>
                  <p>
                    <i className="mdi mdi-star"></i>Purchases from Retail
                    stores, Online stores.
                  </p>
                  <p>
                    <i className="mdi mdi-star"></i>Packing and goods handling.
                  </p>
                </Col>
                <Col lg={6} md={6} className="other-services">
                  <h3 className="font-18">Delivery services</h3>
                  <p>
                    <i className="mdi mdi-star"></i> On demand - Same day
                    deliveries guaranteed.
                  </p>
                  <p>
                    <i className="mdi mdi-star"></i>Need helpers to carry your
                    goods?
                  </p>
                  <p>
                    <i className="mdi mdi-star"></i>Drivers available within 30
                    minutes of your booking.
                  </p>
                </Col>
              </Row>
            </>
          }
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
