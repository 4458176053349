import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import VehiclesTypes from "../../components/Vehicles/Vehicles";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/driver-packages"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <VehiclesTypes />
                <br />
                <h1>Low orders in your area?</h1>
                <p>Now you can boost your driver profile and get more jobs.</p>
                <p>
                  Review the packages below and pick one that fits your budget
                  as a Driver Partner.
                </p>
                <h2>Boost your Driver profile</h2>

                <h5>How it works</h5>
                <p>
                  When you Boost your driver profile, we send jobs to you before
                  we send them to our Free package driver partners on QikTruck
                  EasyDrive.
                </p>
                <p>
                  Giving you higher preference for jobs on QikTruck QikDrive. We
                  also advertise your load capacity to our business partners.
                  Higher paying jobs.
                </p>

                <br />
              </Col>
            </Row>

            <Row className="justify-content-center" id="EasyDrivePackage">
              <Col lg={4} sm={12} md={4} className="driver-packages EasyDrive">
                <h3>EasyDrive</h3>
                <a href="/partner-drivers?dutq=packages">
                  <h4>
                    R0 <span>P/M</span>
                    <i className="mdi mdi-thumb-up ml-3">default</i>
                  </h4>
                </a>
                <p>
                  <i className="mdi mdi-star"></i>Always Free for all approved
                  Driver Partners.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>You get at least 1 job a
                  month.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Review 20+ quotations.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>13% - 30% service fee.
                </p>

                <p>
                  <i className="mdi mdi-star"></i>15km pick up radius.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Driver required.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Round robin jobs with other
                  drivers.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Jobs are not guaranteed.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>No insurance cover.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Free Registration,
                  Criminal/Fraud check and Vehicle inspection.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Free Access to Driver website
                  and mobile app.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>All new vehicles start here.
                  No Monthly Fees.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>If your vehicle is already
                  approved. You are already on EasyDrive. No need for
                  registration. Boost your profile.
                </p>
                <a href="/partner-drivers?dutq=packages">
                  <h4>
                    Register your vehicle.
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages QikDrive"
                id="QikDrivePackage"
              >
                <a href="https://driver.qiktruck.co.za/boost-qikdrive">
                  <h3>
                    QikDrive <span className="span">BOOST</span>
                    <span role="img" aria-label="sheep">
                      &#128293;
                    </span>
                  </h3>
                  <h4>
                    R450 <span>P/M</span>
                    <i>subscribe here </i>{" "}
                  </h4>
                </a>
                <p>
                  <i className="mdi mdi-star"></i>Must be an approved member of
                  QikTruck EasyDrive.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>10 + jobs a month.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Review 100+ quotations.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Earn at least R6000 per boost.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>20% - 40% service fee depending on vehicle size.
                </p>

                <p>
                  <i className="mdi mdi-star"></i>30 day cash back or credits
                  (if no jobs are assigned).
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Transporter Profile upgrades
                  and branding photoshoots.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>You get Priority jobs. Top of
                  the list.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Up to 100km pick up radius.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Goods in Transit cover
                  (application required).
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Advanced Driver website and
                  mobile app.
                </p>
                <a href="https://driver.qiktruck.co.za/boost-qikdrive">
                  <h4>
                    Boost now
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>

              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages PremiumDrive"
                id="PremiumDrivePackage"
              >
                <a href="https://driver.qiktruck.co.za/boost-premiumdrive">
                  <h3>PremiumDrive</h3>
                  <h4>
                    R950 <span>P/M</span>
                    <i className="mdi mdi-arrow-right ml-3">apply</i>
                  </h4>
                </a>
                <p>
                  <i className="mdi mdi-star"></i>Vehicle Rental services.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Driver not required.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Daily contracts.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Weekly contracts.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Monthly contracts.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Long term contracts.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Earn at least R15000 per boost
                  from reviewing and processing 100s of quotations.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>20% - 40% service fee.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>30 day cash back (if no jobs
                  are assigned).
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Vehicle insurance.
                  (application required).
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Goods in Transit cover.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Regular vehicle service.
                  (application required).
                </p>
                <a href="https://driver.qiktruck.co.za/boost-premiumdrive">
                  <h4>
                    Apply now
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <br />
                  <br />
                  <i className="mdi mdi-note"></i> Remember... QikTruck will
                  always offer a Free version for our transporters to get jobs
                  on QikTruck EasyDrive.
                  <br />
                  <i className="mdi mdi-note"></i> All new partner drivers
                  automatically join the QikTruck EasyDrive program when
                  documents are approved.
                  <br />
                  <i className="mdi mdi-note"></i> You can Boost your profile to
                  the QikTruck QikDrive package if there are no jobs in your
                  area.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
