import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Secure Delivery Services in East London"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/delivery-east-london"}
          pageHeading={"Secure Delivery Services in East London"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get secure Delivery services in East London with our team of trained professionals and advanced security measures at QikTruck. We prioritize the safety and protection of your packages and ensure that all deliveries are made with the highest level of security. Sign up now and start feeling confident in the security of our services."}
          pageDescription2={"Need secure Delivery services in East London? QikTruck has you covered! Our team of trained professionals and advanced security measures prioritize the safety and protection of your packages. From package delivery to furniture transportation, we have the solutions you need to ensure that all deliveries are made with the highest level of security. Sign up now and start feeling confident in the security of our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
