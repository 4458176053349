import React, { Component } from "react";
import Faq from "react-faq-component";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import data from "./faqs";
import ContactUs from "../../components/common/ContactUs";
const styles = {
  // bgColor: 'white',
  titleTextColor: "#5b5b5b",
  rowTitleColor: "green",
  // rowContentColor: 'grey',
  // arrowColor: #e03c31,
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/about"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="hero-wrapper mb-4 about-us">
                  <h1 id="about">Frequently Asked Questions</h1>

                  <p className="quote">
                    "QikTruck makes it easy to find reliable and capable vehicles and drivers
                    for all of your transportation needs."
                  </p>
                  <br />
                  <Faq data={data} styles={styles} config={config} />
                  <br />
                  <br />
                  <h3 id="contact">Contact Us</h3>
                  <p>
                    If you have any questions and feebdack about this service,
                    You can contact us:
                  </p>
                  <ContactUs />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
