import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Expert h100 Services in Hermanus"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/h100-hermanus"}
          pageHeading={"Expert h100 Services in Hermanus"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get expert h100 services in Hermanus with QikTruck. Our team of highly-trained professionals and modern equipment ensure that your transportation and delivery needs are met with the highest level of expertise. Sign up now and start benefiting from our exceptional services."}
          pageDescription2={"Need expert h100 services in Hermanus? QikTruck has you covered! Our team of highly-trained professionals and modern equipment ensure that your transportation and delivery needs are met with the highest level of expertise. From package delivery to furniture transportation, we have the solutions you need to ensure a seamless experience. Sign up now and start benefiting from our exceptional services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
