import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Green Vehicle rental Services in Bethal"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/vehicle-rental-bethal"}
          pageHeading={"Green Vehicle rental Services in Bethal"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get green Vehicle rental services in Bethal with QikTruck. Our fleet of electric vehicles and commitment to sustainability make us the leading choice for environmentally-conscious transportation and delivery. Sign up now and start reducing your carbon footprint with our services."}
          pageDescription2={"Looking for green Vehicle rental services in Bethal? QikTruck is the solution! Our fleet of electric vehicles and commitment to sustainability make us the leading choice for environmentally-conscious transportation and delivery. From package delivery to furniture transportation, we have the solutions you need to reduce your carbon footprint. Sign up now and start enjoying the benefits of our eco-friendly services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
