const data = [
  {
    alt: "driver login",
    captionHeader: "Enter your ID / Passport number",
    captionText:
      "Your account is only activated when you submit all documents.",
    srcs: [
      `https://media.qiktruck.co.za/driverloginscreen.png`,
      `https://media.qiktruck.co.za/driverloginscreenfilled.png`,
      `https://media.qiktruck.co.za/driverloginotpscreen.png`,
    ],
  },
  {
    alt: "enter pin",
    captionHeader: "Copy your One time password from your email",
    captionText:
      "Do not share this onetime password with anyone. We will never call you to ask for this password.",
    srcs: [`https://media.qiktruck.co.za/driverloginscreenpassword.png`],
  },
  {
    alt: "dashboard one login",
    captionHeader: "Your first time dashboard",
    captionText: "Manage all your jobs here.",
    srcs: [
      `https://media.qiktruck.co.za/driverdashboardscreen.png`,
      `https://media.qiktruck.co.za/driverdashboardscreenvehicles.png`,
      `https://media.qiktruck.co.za/driverdashboardscreenvehiclestatus.png`,
    ],
  },
  {
    alt: "dashboard new job",
    captionHeader: "Accept jobs",
    captionText: "You can accept, decline, cancel or complete jobs.",
    srcs: [
      `https://media.qiktruck.co.za/driverpreviewnewjob.png`,
      `https://media.qiktruck.co.za/driverpreviewnewjobview.png`,
      `https://media.qiktruck.co.za/driverpreviewnewjobviewaccept.png`,
      `https://media.qiktruck.co.za/driverpreviewnewjobviewfinal.png`,
    ],
  },
];

export default data;
