import axios from "axios";
import util from "../../util";
import React from "react";
import { Button } from "reactstrap";
import constants from "../../Constants";
const baseURL = constants.baseURL;

const ReviewsSlider = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [showEmail, showEmailer] = React.useState(false);
  const [showResponse, setResponse] = React.useState(null);
  const [subscription, setSubscription] = React.useState(false);
  const sendRequest = () => {
    setResponse(null);
    setLoading(true);
    axios
      .post(baseURL + "/customer-subscription-request", {
        ...subscription,
        subscription: props.subscription,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        setResponse(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setResponse({});
      });
  };

  return (
    <div className="reviews-slider-container">
      {!showEmail && (
        <div>
          <Button color="primary" onClick={() => showEmailer(!showEmail)}>
            Free Trial Account
            <i className="mdi mdi-arrow-right"></i>
          </Button>
          <i>
            <p>*valid for 30 days</p>
          </i>
        </div>
      )}
      {showEmail && showResponse?.status !== 200 && (
        <div className="biz-email-container">
          <i
            className="mdi mdi-close-circle-outline red"
            onClick={() => showEmailer(!showEmail)}
          ></i>
          <h5>Get in touch</h5>

          <input
            type="company"
            placeholder="Your company name"
            className="biz-email-input"
            onChange={(e) => {
              setSubscription({ ...subscription, company: e.target.value });
            }}
          />
          <input
            type="email"
            placeholder="email"
            className="biz-email-input"
            onChange={(e) => {
              setSubscription({ ...subscription, email: e.target.value });
            }}
          />
          <input
            type="mobilenumber"
            placeholder="mobile number"
            className="biz-email-input"
            onChange={(e) => {
              setSubscription({
                ...subscription,
                mobilenumber: e.target.value,
              });
            }}
          />
          <input
            type="fullname"
            placeholder="full name"
            className="biz-email-input"
            onChange={(e) => {
              setSubscription({
                ...subscription,
                fullname: e.target.value,
              });
            }}
          />
          <input
            type="location"
            placeholder="location"
            className="biz-email-input"
            onChange={(e) => {
              setSubscription({
                ...subscription,
                location: e.target.value,
              });
            }}
          />
          <textarea
            type="description"
            placeholder="Describe your transportation needs"
            className="biz-email-input"
            onChange={(e) => {
              setSubscription({
                ...subscription,
                description: e.target.value,
              });
            }}
          />
          <Button
            color="primary"
            onClick={() => sendRequest()}
            disabled={loading}
          >
            Submit
          </Button>
        </div>
      )}
      {showResponse &&
        (showResponse.status === 200 ? (
          <div className="biz-email-container ">
            <i className="mdi mdi-star orange font-32"></i>

            <h5>Thank you for your interest in QikTruck!</h5>
            <p>
              We will be in touch shortly to discuss your transportation needs.
            </p>
          </div>
        ) : (
          <div className="biz-email-container error">
            <i
              className="mdi mdi-close-circle-outline red"
              onClick={() => setResponse(null)}
            ></i>
            <h5>An error occured, please try again</h5>
            <p>
              Send us an email at support@qiktruck.co.za if the error continues.
            </p>
          </div>
        ))}
    </div>
  );
};

export default ReviewsSlider;
