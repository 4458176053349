import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Flags from "country-flag-icons/react/3x2";
import logolight from "../../assets/images/logo-light.png";



class FooterLinks extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          style={{
            backgroundColor: "#4f6367",
            padding: 30,
            paddingBottom: 5,
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="float-sm-left">
                <a href="/">
                  <img
                    src={logolight}
                    alt="qiktruck-logo"
                    height="70"
                    className="logo-dark"
                  />
                </a>
              </div>
              <br />
              <br />
              <br />
              <p className="copyright-desc text-white float-sm-right">
                {"  "} Software and Operations. Mastered!{" "}
                <Flags.ZA className="country-flag" />
                {new Date().getFullYear()} © QikTruck. Pty Ltd. South Africa.
              </p>
              <br />

              <br />
              <br />
              <br />
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
