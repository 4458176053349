const baseURLs = {
  suggestions: [
    {
      item: "Bachelor flat",
      width: 1,
      height: 0.5,
      length: 0.5,
      kgs: 65,
      quantity: 15,
      label: "A full bachelor flat move",
    },
    {
      item: "1 bedroom house",
      width: 0.5,
      height: 0.5,
      length: 0.5,
      kgs: 35,
      quantity: 42,
      label: "A full 1 bedroom move",
    },
    {
      quantity: 40,
      item: "2 bedroom house",
      width: 1,
      height: 0.5,
      length: 1,
      kgs: 40,
      label: "A full 2 bedroom move",
    },
    {
      item: "3 bedroom house",
      width: 1,
      height: 0.5,
      length: 1,
      kgs: 90,
      quantity: 80,
      label: "A full 3 bedroom move",
    },
    {
      item: "King Size Bed",
      length: 2,
      width: 1.8,
      height: 0.6,
      kgs: 90,
      quantity: 1,
    },
    {
      item: "Queen Size Bed",
      length: 2,
      width: 1.5,
      height: 0.6,
      kgs: 75,
      quantity: 1,
    },
    {
      item: "Single Bed",
      length: 2,
      width: 0.9,
      height: 0.6,
      kgs: 50,
      quantity: 1,
    },
    {
      item: "Wardrobe",
      length: 1.2,
      width: 0.6,
      height: 2,
      kgs: 80,
      quantity: 1,
    },
    {
      item: "Dresser",
      length: 1.5,
      width: 0.5,
      height: 0.8,
      kgs: 50,
      quantity: 1,
    },
    {
      item: "Nightstand",
      length: 0.5,
      width: 0.5,
      height: 0.6,
      kgs: 20,
      quantity: 1,
    },
    {
      item: "Dining Table",
      length: 2,
      width: 1,
      height: 0.75,
      kgs: 60,
      quantity: 1,
    },
    {
      item: "Dining Chair",
      length: 0.5,
      width: 0.5,
      height: 1,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Sofa (3 seater)",
      length: 2,
      width: 0.9,
      height: 0.9,
      kgs: 80,
      quantity: 1,
    },
    {
      item: "Armchair",
      length: 0.9,
      width: 0.9,
      height: 0.9,
      kgs: 40,
      quantity: 1,
    },
    {
      item: "Coffee Table",
      length: 1.2,
      width: 0.6,
      height: 0.4,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "TV Stand",
      length: 1.5,
      width: 0.4,
      height: 0.5,
      kgs: 25,
      quantity: 1,
    },
    {
      item: '50" Television',
      length: 1.1,
      width: 0.06,
      height: 0.7,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Fridge",
      length: 0.9,
      width: 0.7,
      height: 1.7,
      kgs: 90,
      quantity: 1,
    },
    {
      item: "Washing Machine",
      length: 0.6,
      width: 0.6,
      height: 0.85,
      kgs: 70,
      quantity: 1,
    },
    {
      item: "Dishwasher",
      length: 0.6,
      width: 0.6,
      height: 0.85,
      kgs: 60,
      quantity: 1,
    },
    {
      item: "Stove",
      length: 0.6,
      width: 0.6,
      height: 0.85,
      kgs: 50,
      quantity: 1,
    },
    {
      item: "Microwave",
      length: 0.5,
      width: 0.35,
      height: 0.3,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Desk",
      length: 1.5,
      width: 0.75,
      height: 0.75,
      kgs: 40,
      quantity: 1,
    },
    {
      item: "Office Chair",
      length: 0.6,
      width: 0.6,
      height: 1,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Bookshelf",
      length: 0.8,
      width: 0.3,
      height: 2,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Rug (8'x10')",
      length: 2.4,
      width: 3,
      height: 0.01,
      kgs: 20,
      quantity: 1,
    },
    {
      item: "Boxes",
      length: 0.45,
      width: 0.45,
      height: 0.45,
      kgs: "x",
      quantity: 1,
    },
    {
      item: "Patio Furniture Set",
      length: 2,
      width: 1,
      height: 1,
      kgs: 50,
      quantity: 1,
    },
    {
      item: "Grill",
      length: 1.3,
      width: 0.6,
      height: 1.1,
      kgs: 40,
      quantity: 1,
    },
    {
      item: "Bicycle",
      length: 1.8,
      width: 0.6,
      height: 1,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Treadmill",
      length: 1.8,
      width: 0.8,
      height: 1.4,
      kgs: 80,
      quantity: 1,
    },
    {
      item: "Elliptical Machine",
      length: 1.8,
      width: 0.65,
      height: 1.7,
      kgs: 100,
      quantity: 1,
    },
    {
      item: "Weight Bench",
      length: 1.5,
      width: 1.2,
      height: 1.2,
      kgs: 40,
      quantity: 1,
    },
    {
      item: "Piano (Upright)",
      length: 1.5,
      width: 0.6,
      height: 1.2,
      kgs: 200,
      quantity: 1,
    },
    {
      item: "Piano (Grand)",
      length: 2.2,
      width: 1.5,
      height: 1,
      kgs: 400,
      quantity: 1,
    },
    {
      item: "Large Painting",
      length: 2,
      width: 0.05,
      height: 1.5,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Large Mirror",
      length: 2,
      width: 0.05,
      height: 1.5,
      kgs: 20,
      quantity: 1,
    },
    {
      item: "Vacuum Cleaner",
      length: 0.3,
      width: 0.3,
      height: 1,
      kgs: 7,
      quantity: 1,
    },
    {
      item: "Ironing Board",
      length: 1.4,
      width: 0.35,
      height: 0.1,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Iron",
      length: 0.3,
      width: 0.15,
      height: 0.15,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Computer Tower",
      length: 0.45,
      width: 0.2,
      height: 0.4,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Laptop",
      length: 0.35,
      width: 0.24,
      height: 0.02,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Printer",
      length: 0.5,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Floor Lamp",
      length: 0.3,
      width: 0.3,
      height: 1.7,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Table Lamp",
      length: 0.3,
      width: 0.3,
      height: 0.5,
      kgs: 3,
      quantity: 1,
    },
    { item: "Fan", length: 0.4, width: 0.4, height: 0.5, kgs: 5, quantity: 1 },
    {
      item: "Air Conditioner",
      length: 0.6,
      width: 0.6,
      height: 0.4,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Toolbox",
      length: 0.6,
      width: 0.3,
      height: 0.3,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Ladder",
      length: 1.8,
      width: 0.5,
      height: 0.1,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Lawn Mower",
      length: 1.5,
      width: 0.6,
      height: 1,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Pillow",
      length: 0.7,
      width: 0.5,
      height: 0.2,
      kgs: 1,
      quantity: 1,
    },
    {
      item: "Bed Sheet Set (Q)",
      length: 0.3,
      width: 0.2,
      height: 0.1,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Comforter (Q)",
      length: 0.6,
      width: 0.5,
      height: 0.2,
      kgs: 4,
      quantity: 1,
    },
    {
      item: "Towel Set",
      length: 0.3,
      width: 0.2,
      height: 0.1,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Pots and Pans Set",
      length: 0.5,
      width: 0.5,
      height: 0.3,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Dinnerware Set",
      length: 0.4,
      width: 0.4,
      height: 0.3,
      kgs: 8,
      quantity: 1,
    },
    {
      item: "Cutlery Set",
      length: 0.3,
      width: 0.2,
      height: 0.1,
      kgs: 3,
      quantity: 1,
    },
    {
      item: "Blender",
      length: 0.2,
      width: 0.2,
      height: 0.4,
      kgs: 3,
      quantity: 1,
    },
    {
      item: "Toaster",
      length: 0.3,
      width: 0.2,
      height: 0.2,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Coffee Maker",
      length: 0.3,
      width: 0.2,
      height: 0.4,
      kgs: 3,
      quantity: 1,
    },
    {
      item: "Food Processor",
      length: 0.3,
      width: 0.3,
      height: 0.4,
      kgs: 4,
      quantity: 1,
    },
    {
      item: "Garage Tools (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Lawnmower",
      length: 1.5,
      width: 0.6,
      height: 1,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Power Drill",
      length: 0.3,
      width: 0.1,
      height: 0.2,
      kgs: 1.5,
      quantity: 1,
    },
    {
      item: "Circular Saw",
      length: 0.3,
      width: 0.2,
      height: 0.3,
      kgs: 4,
      quantity: 1,
    },
    {
      item: "Extension Ladder",
      length: 2.5,
      width: 0.5,
      height: 0.1,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Paint Can",
      length: 0.2,
      width: 0.2,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Garden Hose",
      length: 0.3,
      width: 0.3,
      height: 0.1,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Wheelbarrow",
      length: 1.5,
      width: 0.6,
      height: 0.6,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Bicycle",
      length: 1.8,
      width: 0.6,
      height: 1,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Curtains (per set)",
      length: 1.5,
      width: 0.01,
      height: 2,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Area Rug (8'x10')",
      length: 2.4,
      width: 3,
      height: 0.01,
      kgs: 20,
      quantity: 1,
    },
    {
      item: "Vacuum Cleaner",
      length: 0.3,
      width: 0.3,
      height: 1,
      kgs: 7,
      quantity: 1,
    },
    {
      item: "Ironing Board",
      length: 1.4,
      width: 0.35,
      height: 0.1,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Iron",
      length: 0.3,
      width: 0.15,
      height: 0.15,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Computer Tower",
      length: 0.45,
      width: 0.2,
      height: 0.4,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Laptop",
      length: 0.35,
      width: 0.24,
      height: 0.02,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Printer",
      length: 0.5,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Floor Lamp",
      length: 0.3,
      width: 0.3,
      height: 1.7,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Table Lamp",
      length: 0.3,
      width: 0.3,
      height: 0.5,
      kgs: 3,
      quantity: 1,
    },
    { item: "Fan", length: 0.4, width: 0.4, height: 0.5, kgs: 5, quantity: 1 },
    {
      item: "Air Conditioner",
      length: 0.6,
      width: 0.6,
      height: 0.4,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Toolbox",
      length: 0.6,
      width: 0.3,
      height: 0.3,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Ladder",
      length: 1.8,
      width: 0.5,
      height: 0.1,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Lawn Mower",
      length: 1.5,
      width: 0.6,
      height: 1,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Garden Tools (Set)",
      length: 1,
      width: 0.2,
      height: 0.1,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "BBQ Grill",
      length: 1.3,
      width: 0.6,
      height: 1.1,
      kgs: 40,
      quantity: 1,
    },
    {
      item: "Patio Furniture Set",
      length: 2,
      width: 1,
      height: 1,
      kgs: 50,
      quantity: 1,
    },
    {
      item: "Potted Plants",
      length: 0.5,
      width: 0.5,
      height: 1,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Children's Toys (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Books (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "DVDs/Blu-rays (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Wine Bottles (each)",
      length: 0.1,
      width: 0.1,
      height: 0.3,
      kgs: 1.5,
      quantity: 1,
    },
    {
      item: "Pet Supplies (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Gaming Console",
      length: 0.3,
      width: 0.25,
      height: 0.05,
      kgs: 3.5,
      quantity: 1,
    },
    {
      item: "Sound System",
      length: 0.5,
      width: 0.4,
      height: 0.2,
      kgs: 8,
      quantity: 1,
    },
    {
      item: "Small Heater",
      length: 0.3,
      width: 0.2,
      height: 0.25,
      kgs: 4,
      quantity: 1,
    },
    {
      item: "Small Air Conditioner",
      length: 0.4,
      width: 0.4,
      height: 0.75,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Electric Fan",
      length: 0.4,
      width: 0.4,
      height: 0.5,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Humidifier",
      length: 0.3,
      width: 0.3,
      height: 0.5,
      kgs: 4,
      quantity: 1,
    },
    {
      item: "Dehumidifier",
      length: 0.3,
      width: 0.3,
      height: 0.5,
      kgs: 15,
      quantity: 1,
    },
    {
      item: "Exercise Equipment",
      length: 1,
      width: 1.5,
      height: 1,
      kgs: 50,
      quantity: 1,
    },
    {
      item: "Yoga Mat",
      length: 0.6,
      width: 0.1,
      height: 0.1,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Musical Instruments",
      length: 0.5,
      width: 0.5,
      height: 0.5,
      kgs: 4,
      quantity: 1,
    },
    {
      item: "Art Supplies (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Sewing Machine",
      length: 0.4,
      width: 0.2,
      height: 0.3,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Craft Supplies (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Board Games (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Camping Gear (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Suitcases",
      length: 1,
      width: 0.3,
      height: 0.5,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Photo Albums (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 1,
      quantity: 1,
    },
    {
      item: "Office Supplies (Box)",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Cleaning Supplies",
      length: 0.6,
      width: 0.4,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Dumbbell ",
      length: 0.2,
      width: 0.1,
      height: 0.1,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Kettlebell",
      length: 0.2,
      width: 0.2,
      height: 0.3,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Weight Plate",
      length: 0.03,
      width: 0.01,
      height: 0.02,
      kgs: 10,
      quantity: 1,
    },
    {
      item: "Barbell bar",
      length: 2.2,
      width: 0.03,
      height: 0.03,
      kgs: 20,
      quantity: 1,
    },
    {
      item: "Weight Bench",
      length: 1.5,
      width: 0.6,
      height: 0.5,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Squat Rack",
      length: 1.2,
      width: 1.2,
      height: 2.2,
      kgs: 60,
      quantity: 1,
    },
    {
      item: "Treadmill",
      length: 1.8,
      width: 0.8,
      height: 1.4,
      kgs: 80,
      quantity: 1,
    },
    {
      item: "Stationary Bike",
      length: 1,
      width: 0.5,
      height: 1.2,
      kgs: 35,
      quantity: 1,
    },
    {
      item: "Rowing Machine",
      length: 2.4,
      width: 0.6,
      height: 1,
      kgs: 30,
      quantity: 1,
    },
    {
      item: "Yoga Mat",
      length: 1.8,
      width: 0.6,
      height: 0.01,
      kgs: 2,
      quantity: 1,
    },
    {
      item: "Exercise Ball",
      length: 0.65,
      width: 0.65,
      height: 0.65,
      kgs: 1,
      quantity: 1,
    },
    {
      item: "Resistance Bands",
      length: 1.5,
      width: 0.01,
      height: 0.01,
      kgs: 0.5,
      quantity: 1,
    },
    {
      item: "Jump Rope",
      length: 3,
      width: 0.01,
      height: 0.01,
      kgs: 0.5,
      quantity: 1,
    },
    {
      item: "Foam Roller",
      length: 0.6,
      width: 0.15,
      height: 0.15,
      kgs: 1,
      quantity: 1,
    },
    {
      item: "Pull-Up Bar",
      length: 1,
      width: 0.3,
      height: 0.1,
      kgs: 5,
      quantity: 1,
    },
    {
      item: "Punching Bag",
      length: 1,
      width: 0.5,
      height: 0.5,
      kgs: 25,
      quantity: 1,
    },
    {
      item: "Medicine Ball",
      length: 0.35,
      width: 0.35,
      height: 0.35,
      kgs: 5,
    },
  ],
};

export default baseURLs;
