import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Trusted bakkie hire  Providers in Kroonstad"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/bakkie-hire--kroonstad"}
          pageHeading={"Trusted bakkie hire  Providers in Kroonstad"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get trusted bakkie hire  services in Kroonstad with our team of experienced drivers and state-of-the-art vehicles at QikTruck. We have a long history of delivering excellence and customer satisfaction. Sign up now and start enjoying the peace of mind that comes with working with a trusted provider."}
          pageDescription2={"Need trusted bakkie hire  services in Kroonstad? Look no further! Our team of experienced drivers and state-of-the-art vehicles at QikTruck have a long history of delivering excellence and customer satisfaction. We are dedicated to ensuring that your transportation and delivery needs are met with the highest level of professionalism and reliability. Sign up now and start enjoying the peace of mind that comes with working with a trusted provider."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
