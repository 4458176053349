import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import constants from "../../Constants";
import facebook from "../../assets/images/facebook-bg-min.png";
import linkedin from "../../assets/images/linkedin-bg-min.png";
import twitter from "../../assets/images/twitter-bg-min.png";
import instagram from "../../assets/images/instagram-bg-min.png";
import ContentLoader from "react-content-loader";
import util from "../../util";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let urlImage;

    try {
      const queryParams = new URLSearchParams(window.location.search);
      urlImage = queryParams.get("id");
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      urlImage,
      openSuccessModal: false,
      truckType: "",
      apierror: "",
      location: {
        lat: 0,
        lng: 0,
      },
      firstname: "",
      lastname: "",
      leaderlink: "",
      mobilenumber: "",
      idnumber: "",
      vin: "",
      licenseplatenumber: "",
      vehicles: 0,
      socialmediacontent: [],
      medias: {
        facebook: facebook,
        linkedin: linkedin,
        twitter: twitter,
        instagram: instagram,
      },
    };
    // this.registrationform = React.createRef();
    // this.vehiclepicker = React.createRef();
  }

  componentDidMount() {
    axios.get(baseURL + "/social-media-content-leaders").then((repos) => {
      const leaders = repos.data;
      this.setState({
        leaders,
      });
    });
  }

  submitmediacontentleaderlink() {
    const { leaderlink } = this.state;
    this.setState({
      leaderlinkloading: true,
      linksubmitted: false,
    });
    axios
      .post(baseURL + "/submit-media-content-leader-link", {
        link: leaderlink,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        this.setState({
          leaderlinkloading: false,
          linksubmitted: true,
          leaderlink: "",
        });
      })
      .catch((e) => {
        this.setState({
          leaderlinkloading: false,
          linksubmitted: false,
        });
      });
  }

  render() {
    const { socialmediacontent } = this.state;
    if (socialmediacontent.length === 0)
      axios.get(baseURL + "/social-media-content").then((repos) => {
        const socialmediacontentdata = repos.data;
        this.setState({
          socialmediacontent: socialmediacontentdata,
        });
      });
    return (
      <React.Fragment>
        <SEO
          title={"Promote and Win!"}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Get the most likes, comments and shares to win a share of R10000"
          }
          image={"https://media.qiktruck.co.za/partner-driver.jpeg"}
          pathname={"https://qiktruck.co.za/partner-drivers"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section" id="location">
          <Container>
            <Row>
              <Col lg={12}>
                <h5 className="text-uppercase orange" id={"vehiclepicker"}>
                  Promote and Win your share of{" "}
                  <h1 className="orange">R10 000</h1>
                  every month
                </h5>
                <br />
                <br />
              </Col>
            </Row>
            {this.state.urlImage && (
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <p>
                    Download the file below and share on your social media with
                    this hashtag
                    <b> #qiktruck2023</b>
                  </p>
                  <a
                    href={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <LazyLoadImage
                      src={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                      alt={this.state.urlImage}
                      className={"social-content-preview"}
                      effect="blur"
                    />
                  </a>
                  <div className={"social-content-parent"}>
                    <a
                      href={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={"social-content-preview-downloader"}
                      download
                    >
                      DOWNLOAD and Share
                      <i className="mdi mdi-arrow-top-right"></i>
                      <br />
                      <a
                        href={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={twitter}
                          alt=""
                          className="footer-social-logo"
                          height="30"
                        />
                      </a>
                      <a
                        href={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={facebook}
                          alt=""
                          className="footer-social-logo"
                          height="30"
                        />
                      </a>
                      <a
                        href={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={linkedin}
                          alt=""
                          className="footer-social-logo"
                          height="30"
                        />
                      </a>
                      <a
                        href={`https://compress.media.qiktruck.co.za/${this.state.urlImage}.png`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={instagram}
                          alt=""
                          className="footer-social-logo"
                          height="30"
                        />
                      </a>
                    </a>
                  </div>
                  <br />
                  <br />
                  <br />
                  <hr />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <div className="hero-wrapper mb-4">
                  <br />
                  <h3 className=" EasyDrivePackage">
                    <a href="/social">Leaderboard</a>
                  </h3>
                  <p>Likes, comments and shares all combined.</p>
                  {!this.state.leaders && (
                    <ContentLoader
                      viewBox="0 0 380 70"
                      speed={5}
                      backgroundColor={"lightgrey"}
                      foregroundColor={"#d9ffe3"}
                    >
                      {/* Only SVG shapes */}
                      <rect x="0" y="30" rx="5" ry="5" width="61" height="41" />
                      <rect
                        x="80"
                        y="37"
                        rx="4"
                        ry="4"
                        width="200"
                        height="13"
                      />
                      <rect
                        x="80"
                        y="60"
                        rx="3"
                        ry="3"
                        width="280"
                        height="10"
                      />
                    </ContentLoader>
                  )}
                  {this.state.leaders?.map((lead, index) => (
                    <div
                      className={
                        "leader-board-member " + (index < 5 ? "winners" : "")
                      }
                      key={index}
                    >
                      <div className="leader-board-member-count">
                        {index + 1}
                      </div>
                      <img
                        src={
                          "https://media.qiktruck.co.za/leaders/" + lead.image
                        }
                        alt={lead.image}
                        width={40}
                      />
                      <div className="leader-board-member-name">
                        {lead.username}
                      </div>
                      <a
                        className="leader-board-member-link"
                        href={lead.postlink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={this.state.medias[lead.socialtype.toLowerCase()]}
                          alt=""
                          className="footer-social-logo"
                          height="20"
                        />
                        engagement {lead.total}
                        <i className="mdi mdi-arrow-top-right"></i>
                      </a>
                    </div>
                  ))}
                  <br />
                </div>
                <br />
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="hero-wrapper mb-4 leader-board-member-submission">
                  <br />
                  <p>
                    <b>Not on the list?</b>
                  </p>
                  <p>Send us the link to your post.</p>
                  <input
                    name="leader-link"
                    id="leader-link"
                    placeholder="paste here"
                    onChange={(e) =>
                      this.setState({ leaderlink: e.target.value })
                    }
                  />
                  <button
                    className="leaderlink"
                    disabled={
                      this.state.leaderlink?.length < 10 ||
                      this.state.leaderlinkloading === true
                    }
                    onClick={() => this.submitmediacontentleaderlink()}
                  >
                    Submit your link
                  </button>
                  {this.state.leaderlinkloading === true && (
                    <p>Submitting your link...</p>
                  )}
                  {this.state.linksubmitted && (
                    <p style={{ color: "green" }}>
                      Link submitted.
                      <br />
                      You can send another link.
                    </p>
                  )}
                  <br />
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col lg={12} md={12} sm={12} xs={12} xl={12}>
                <br />
                <p id={"registrationform"}></p>
                <h3>Choose content to post</h3>
                <br />
                <p></p>
                <p>
                  1. Click + Download a photo or video below to your device.
                  <br />
                  <br />
                  2. Share on Facebook, Twitter, Instagram or LinkedIn with the
                  hashtag <b> #qiktruck2023</b> <br />
                  <br />
                  3. Do not modify the content.
                  <br />
                  <br />
                  4. Check the leaderboard.
                  <br />
                  <br />
                  5. Top 5 share R10 000 every monthend.
                  <br />
                  <br />
                  6. Minimum total of 3K likes, comments and shares.
                </p>
              </Col>
              {socialmediacontent?.map((content) => {
                const image = `https://media.qiktruck.co.za/social-content/${content}.png`;
                return (
                  <Col key={content} lg={4} md={4} sm={4} xs={4} xl={2}>
                    <a href={`/social?id=${content}&from=social`}>
                      <LazyLoadImage
                        src={image}
                        alt={image}
                        className={"social-content-preview"}
                        effect="blur"
                      />
                    </a>
                  </Col>
                );
              })}
            </Row>

            <hr />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
