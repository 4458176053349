import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import Stories from "react-insta-stories";
import SEO from "react-seo-component";
import { withRouter } from "react-router";
import LatLngToAddress from "../../components/common/LatLngToAddress";
import ProfileMenuHeader from "../customer-profile/ProfileMenuHeader";

import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import constants from "../../Constants";
import util from "../../util";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let customercontractid = "";
    let contractbooking = "";
    let contractorderid = "";
    let vehicleId = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      customercontractid = queryParams.get("customercontractid") || "";
      contractorderid = queryParams.get("contractorderid") || "";
      contractbooking = queryParams.get("contractbooking") || "";
      vehicleId = queryParams.get("v") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      loading: false,
      success: false,
      trackYourOrder: false,
      openSuccessModal: false,
      truckType: "1.5tonopen",
      acceptedOffer: "",
      apierror: "",
      orderid: "",
      collection: {
        lat: 0,
        lng: 0,
      },
      delivery: {
        lat: 0,
        lng: 0,
      },
      fullQuote: {
        tripDistance: 0,
        tripDuration: 0,
        sellingPrice: 0,
        storedQuoteId: "",
      },
      vehicleId: vehicleId || "",
      vehicleProfile: {},
      stories: [],
      contractbooking,
      deliveryadd: false,
      collectionadd: false,
      customercontractid,
      trucktype: "",
      distance: "",
      vehicleid: "",
      orderstatus: "",
      driverpaid: "",
      collectiondate: "",
      collectiontime: "",
      collectionemail: "",
      collectionmobilenumber: "",
      collectionfirstname: "",
      collectionlastname: "",
      collectionlat: 0,
      collectionlng: 0,
      collectiondescription: "",
      deliveryemail: "",
      deliverymobilenumber: "",
      deliveryfirstname: "",
      deliverylastname: "",
      deliverylat: 0,
      deliverylng: 0,
      deliverydescription: "",
      contractorderid,
    };
  }

  componentDidMount() {
    this.getVehicleProfile();
    this.customerGetTrip();
  }

  customerGetTrip() {
    const { contractorderid } = this.state;

    this.setState({
      loading: true,
      apierror: "",
    });
    axios
      .post(
        baseURL + "/customer-get-trip",
        {
          contractorderid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: cookie.load("qtcid_"),
          },
        }
      )
      .then((response) => {
        this.setState({
          ...response.data,
        });
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally(() => this.setState({ loading: false }));
  }
  getVehicleProfile() {
    const { vehicleId, truckType } = this.state;
    if (vehicleId?.length > 3)
      axios
        .post(baseURL + "/vehicle-profile", {
          vehicleid: vehicleId,
          source_:
            util.navigator_() + util.source_() + " qtref:" + document?.referrer,
          quotevehicleid: this.state.vehicleProfile.vehicleid,
        })
        .then((repos) => {
          const vehicleProfile = repos.data;
          this.setState({
            vehicleProfile,
            truckType: vehicleProfile.trucktype || truckType,
          });
          if (vehicleProfile?.idnumber?.length > 0)
            window.document.getElementById("pagefragmenttop").scrollIntoView();
        });
  }

  validStepOne() {
    const {
      collectiondate,
      collectiontime,
      deliverylat,
      collectionlat,
      deliverylng,
      collectionlng,
    } = this.state;
    if (collectiondate.length === 0) return false;
    if (collectiontime.length === 0) return false;
    if (deliverylat === 0) return false;
    if (collectionlat === 0) return false;
    if (collectionlat === deliverylat && collectionlng === deliverylng)
      return false;
    return true;
  }
  validStepTwo() {
    const {
      firstname,
      lastname,
      email,
      mobilenumber,
      collectiondate,
      collectiontime,
      description,
    } = this.state;

    if (
      firstname.length === 0 ||
      lastname.length === 0 ||
      email.length === 0 ||
      mobilenumber.length === 0 ||
      collectiondate.length === 0 ||
      collectiontime.length === 0 ||
      description.length === 0
    )
      return false;

    return true;
  }
  updateContractOrder() {
    const {
      collectiondate,
      collectiontime,
      collectionemail,
      collectionmobilenumber,
      collectionfirstname,
      collectionlastname,
      collectionlat,
      collectionlng,
      collectiondescription,
      deliveryemail,
      deliverymobilenumber,
      deliveryfirstname,
      deliverylastname,
      deliverylat,
      deliverylng,
      deliverydescription,
      contractorderid,
    } = this.state;
    if (!this.validStepOne()) return;
    this.setState({ loading: true, apierror: "" });
    axios
      .post(
        baseURL + "/customer-update-trip",
        {
          contractorderid,
          collectiondate,
          collectiontime,
          collectionemail,
          collectionmobilenumber,
          collectionfirstname,
          collectionlastname,
          collectionlat,
          collectionlng,
          collectiondescription,
          deliveryemail,
          deliverymobilenumber,
          deliveryfirstname,
          deliverylastname,
          deliverylat,
          deliverylng,
          deliverydescription,
          source_:
            util.navigator_() + util.source_() + " qtref:" + document?.referrer,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: cookie.load("qtcid_"),
          },
        }
      )
      .then((response) => {
        this.props.history.push(
          "/contract-trip?contractorderid=" + response.data
        );
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          apierror: "Check your addresses and try again.",
        });
        Bugsnag.notify(e);
      })
      .finally(() => this.setState({ loading: false }));
  }

  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }
  getProfileImage(photo) {
    try {
      return `https://advertsmedia.qiktruck.co.za/${photo}`;
    } catch (error) {
      return "";
    }
  }
  render() {
    return (
      <React.Fragment>
        <div id={"pagefragmenttop"}></div>
        {this.state?.stories?.length > 0 && (
          <Stories
            stories={this.state?.stories}
            defaultInterval={1500}
            width={"100vw"}
            height={"100vh"}
            onAllStoriesEnd={() => this.setState({ stories: [] })}
          />
        )}
        <SEO
          title={"Quick, Simple and Affordable Furniture Delivery"}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Find the best furniture delivery and hire a truck or vehicle quotations in your area today!"
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/furniture-delivery-services"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section" id={"pagetop"}>
          <Modal isOpen={this.state.success && this.state.openSuccessModal}>
            <ModalHeader>
              <i className={"mdi mdi-check-circle  order-received"}></i>
              Order Received
            </ModalHeader>
            <ModalBody>
              <ListGroup className="black-text">
                <ListGroupItem>
                  <i className={"mdi mdi-check-circle order-received-list"}></i>
                  A driver will be in touch shortly to confirm your order.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-email order-received-list"}></i>
                  You will receive an email with your invoice shortly.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  You can pay now or after the delivery.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  Follow the link in your email to make a payment.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-account-alert order-received-list"}
                  ></i>
                  Produce your ID when the driver arrives.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-check-circle order-received-list"}></i>
                  Happy moving!
                </ListGroupItem>
              </ListGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.setState(
                    {
                      openSuccessModal: false,
                      trackYourOrder: true,
                    },
                    () => {
                      this.props.history.push(
                        "/track-order?paynow=yes&orderid=" +
                          this.state.orderid +
                          "&email=" +
                          this.state.email
                      );
                    }
                  )
                }
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          <Container>
            <div className="store-front">
              <Row>
                <Col lg={12}>
                  <div className="hero-wrapper mb-4">
                    <Row>
                      <ProfileMenuHeader heading={"Edit your trip"} />
                      <div className="vehicle-profile">
                        <div className="vehicle-profile-avatar">
                          <div className="vehicle-profile-avatar"></div>
                          <div className="vehicle-profile-vehicletype">
                            <img
                              src={this.getImage(
                                this.state.vehicleProfile.trucktype
                              )}
                              alt=""
                              height={60}
                            />
                          </div>
                        </div>
                        <div className="vehicle-profile-data">
                          <div className="vehicle-profile-names">
                            <i className="mdi mdi-face ml-2"></i>{" "}
                            {this.state.vehicleProfile.firstname}{" "}
                            {this.state.vehicleProfile.lastname}
                          </div>
                          <div className="vehicle-profile-number">
                            <div>
                              <i className="mdi mdi-phone ml-2"></i>{" "}
                              {this.state.vehicleProfile.mobilenumber}
                            </div>
                          </div>
                        </div>
                        <div className="vehicle-profile-images">
                          {this.state.vehicleProfile?.images?.map(
                            (v, index) => (
                              <img
                                key={index}
                                src={this.getProfileImage(v)}
                                onClick={() => {
                                  window.document
                                    .getElementById("pagefragmenttop")
                                    .scrollIntoView();

                                  this.setState({
                                    stories:
                                      this.state.vehicleProfile?.images?.map(
                                        (m) => this.getProfileImage(m)
                                      ),
                                  });
                                }}
                                alt=""
                                height={60}
                              />
                            )
                          )}
                        </div>
                        <p>
                          Distance:{" "}
                          <b>{Math.floor(this.state.distance / 1000)}</b>km.
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiondate">Date</Label>
                          <Input
                            id="collectiondate"
                            name="collectiondate"
                            placeholder="Collection date"
                            type="date"
                            defaultValue={this.state.collectiondate}
                            onChange={(e) => {
                              this.setState({ collectiondate: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiontime">Time</Label>
                          <Input
                            id="collectiontime"
                            name="collectiontime"
                            placeholder="Time"
                            type="time"
                            defaultValue={this.state.collectiontime}
                            onChange={(e) => {
                              this.setState({ collectiontime: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FormGroup autoComplete="new-password">
                          <h3 className="font-18" id={"addresses"}>
                            Collection Address{" "}
                          </h3>
                          <p className=" location-hint">
                            <LatLngToAddress
                              lat={this.state?.collectionlat}
                              lng={this.state?.collectionlng}
                            />
                          </p>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="collection"
                            placeholder=""
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error(
                                    "COLLECTION place not found: " +
                                      this.state.truckType
                                  )
                                );
                              }
                              this.setState({
                                collectionlat: place?.geometry?.location?.lat(),
                                collectionlng: place?.geometry?.location?.lng(),
                              });
                            }}
                          />{" "}
                          <i
                            className="mdi mdi-arrow-right"
                            onClick={() =>
                              this.setState({
                                collectionadd: !this.state.collectionadd,
                              })
                            }
                          >
                            Collection instructions
                          </i>
                        </FormGroup>

                        {this.state.collectionadd && (
                          <Row className="contract-trip-booker">
                            <Col lg={4} md={4} sm={12}>
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                  id="email"
                                  name="email"
                                  type="email"
                                  defaultValue={this.state.collectionemail}
                                  onChange={(e) => {
                                    this.setState({
                                      collectionemail: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                              <FormGroup>
                                <Label for="mobilenumber">Mobile number</Label>
                                <Input
                                  id="mobilenumber"
                                  defaultValue={
                                    this.state.collectionmobilenumber
                                  }
                                  name="mobilenumber"
                                  type="tel"
                                  onChange={(e) => {
                                    this.setState({
                                      collectionmobilenumber: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                              <FormGroup>
                                <Label for="description">Instructions</Label>
                                <Input
                                  id="description"
                                  name="text"
                                  type="textarea"
                                  defaultValue={
                                    this.state.collectiondescription
                                  }
                                  style={{ minHeight: 120 }}
                                  placeholder="Your instructions for the driver..."
                                  onChange={(e) => {
                                    this.setState({
                                      collectiondescription: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FormGroup className="mb-4" autoComplete="new-password">
                          <h3 className="font-18">Delivery Address </h3>
                          <p className=" location-hint">
                            <LatLngToAddress
                              lat={this.state?.deliverylat}
                              lng={this.state?.deliverylng}
                            />
                          </p>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="delivery"
                            placeholder=""
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error(
                                    "DELIVERY place not found: " +
                                      this.state.truckType
                                  )
                                );
                              }
                              this.setState({
                                deliverylat: place?.geometry?.location?.lat(),
                                deliverylng: place?.geometry?.location?.lng(),
                              });
                            }}
                          />{" "}
                          <i
                            className="mdi mdi-arrow-right"
                            onClick={() =>
                              this.setState({
                                deliveryadd: !this.state.deliveryadd,
                              })
                            }
                          >
                            Delivery instructions
                          </i>
                        </FormGroup>

                        {this.state.deliveryadd && (
                          <Row className="contract-trip-booker">
                            <Col lg={4} md={4} sm={12}>
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                  id="email"
                                  name="email"
                                  type="email"
                                  defaultValue={this.state.deliveryemail}
                                  onChange={(e) => {
                                    this.setState({
                                      deliveryemail: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                              <FormGroup>
                                <Label for="mobilenumber">Mobile number</Label>
                                <Input
                                  id="mobilenumber"
                                  defaultValue={this.state.deliverymobilenumber}
                                  name="mobilenumber"
                                  type="tel"
                                  onChange={(e) => {
                                    this.setState({
                                      deliverymobilenumber: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                              <FormGroup>
                                <Label for="description">Instructions</Label>
                                <Input
                                  id="description"
                                  name="text"
                                  type="textarea"
                                  defaultValue={this.state.deliverydescription}
                                  style={{ minHeight: 120 }}
                                  placeholder="Your instructions for the driver..."
                                  onChange={(e) => {
                                    this.setState({
                                      deliverydescription: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <div
                      style={{
                        width: "100%",
                        display: "block",
                        float: "left",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Button
                        className="float-right mb-4"
                        color="primary"
                        onClick={() => {
                          this.updateContractOrder();
                        }}
                        style={{ width: "fit-content", marginTop: "20px" }}
                        disabled={
                          this.state.loading ||
                          this.state.vehicleProfile.orderstatus === "stopped" ||
                          this.state.vehicleProfile.orderstatus === "cancelled" ||
                          this.state.vehicleProfile.orderstatus === "complete"
                        }
                      >
                        Update Job
                        <i className="mdi mdi-arrow-down-bold ml-2"></i>
                      </Button>
                    </div>

                    {this.state.loading === false &&
                      this.state.apierror.length > 0 && (
                        <p className="error-message">{this.state.apierror}</p>
                      )}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Section);
