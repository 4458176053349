import cookie from "react-cookies";
import Bugsnag from "@bugsnag/js";
function navigator_() {
  try {
    var _navigator = {};
    for (var i in window.navigator) _navigator[i] = navigator[i];
    delete _navigator.plugins;
    delete _navigator.mimeTypes;
    return JSON.stringify(_navigator) + " <<navigator>> ";
  } catch (error) {
    Bugsnag.notify(error);
    return "";
  }
}
function source_() {
  try {
    return (
      (cookie.load("source_") || "") + " <<url>> NOLOCATOIN,NOLOCATOIN"
      // (cookie.load("locationsource_") || "")
    );
  } catch (error) {
    Bugsnag.notify(error);
    return "";
  }
}
const navs = {
  navigator_,
  source_,
};

export default navs;
