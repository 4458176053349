const data = [
  {
    heading: "Mr Andile Mahlangu",
    date: "20 March 2022",
    category: 5,
    location: "Johannesburg",
    copy: "I have found your site to be invaluable in not only the ease of ordering a truck from you, but also in understanding how much it will cost with different quotes.",
  },

  {
    heading: "Dr Grant du Plessis",
    date: "1 June 2022",
    category: 4,
    location: "Sandton",
    copy: "I just recently had QikTruck take care of my delivery and I couldn't be happier. It saved me so much time and stress not having to go anywhere myself. The customer service was fantastic and the driver was really polite.",
  },
  {
    heading: "Mr Thabang Makwetla",
    date: "1 Feb 2022",
    category: 5,
    location: "Pretoria",
    copy: "I would like to say thanks for making my move so easy! I own a business in the city centre and needed to change premises. Your trucks were on time, and I appreciate all of your help.",
  },
  {
    heading: "Mr Sibusio Dube",
    date: "13 July 2022",
    category: 4,
    location: "Kempton Park",
    copy: "I just had all my furniture delivered by QikTruck and I am so pleased. I don't know how people get around without their service. They were really good and it really helped me out when I needed it.",
  },
  {
    heading: "Anonymous",
    date: "16 May 2022",
    category: 5,
    location: "Soweto",
    copy: "I found out about QikTruck after I tried to call a couple different companies with no luck. I managed to get in touch with one of your representatives who was so helpful and delivery trucks were as fast as promised. Thank you!",
  },
  {
    heading: "Mrs Janet Kristok",
    date: "17 July 2022",
    category: 4,
    location: "Roodeport",
    copy: "We needed a quick and easy way for our company to transport equipment in bulk, and I found exactly what we were looking for when I stumbled on QikTruck. We can't say enough good things about QikTruck's service. It was fast, efficient, and everything went according to plan.",
  },
];

export default data;
