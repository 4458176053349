import React, { Component } from "react";
import SEO from "react-seo-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import ReviewsSlider from "./reviewSlider";
import BusinessProspect from "./businessProspect";
const getImage = (trucktype) => {
  try {
    return `https://media.qiktruck.co.za/${trucktype}.png`;
  } catch (error) {
    return "";
  }
};

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/regular-deliveries"}
          twitterUsername={"qiktrucksa"}
        />
        <Container className="pricing-container">
          <Row className="hero-section">
            <Col md={6}>
              {/* Placeholder for the hero image or video */}
              <img
                src="https://media.qiktruck.co.za/partners/partners-hero-removebg-preview.png"
                alt="Logistics Services"
                className="hero-image"
              />
            </Col>
            <Col md={6}>
              <h1>Efficient Custom Logistics Solutions For Your Business.</h1>
              <p>
                Choose from our flexible packages to get the best fit for your
                business.
              </p>
            </Col>
          </Row>
          <div className="divvyup b-center"></div>
          <Row className="benefits-section">
            <Col md={6}>
              <h2>Core Benefits</h2>
              <ul>
                <li>
                  <i className="mdi mdi-star orange"></i> Insurance for all your
                  shipments and logistics.
                </li>
                <li>24/7 support to ensure smooth operations.</li>
                <li>
                  <i className="mdi mdi-star orange"></i> Admin Booking
                  Dashboard.
                </li>
                <li>Short distance and long distance.</li>
                <li>
                  <i className="mdi mdi-star orange"></i> Vehicle and driver
                  available on demand.
                </li>
                {/* Add other benefits as needed */}
              </ul>
            </Col>
            <Col md={6}>
              <ReviewsSlider />
            </Col>
          </Row>
          <div className="divvyup b-center"></div>
          <Row>
            <Col md={4}>
              <Card className="pricing-card">
                <CardBody>
                  <CardTitle className="pricing-title">
                    Basic Subscription
                  </CardTitle>
                  <CardText className="biz-price">
                    Price: <span>350 p/m</span>
                  </CardText>
                  <ul>
                    <li>Goods Insurance</li>
                    <li>
                      0.5 ton vehicle + driver
                      <img
                        src={getImage("minivanopen")}
                        alt="bakkie"
                        className="price-truck-image"
                      />
                    </li>
                    <li>Admin Booking Dashboard</li>
                    <li>On demand pricing</li>
                    <li>Short distance trips</li>
                    <li>You only pay for what you use</li>
                    <li>3 hour lead time for trip confirmation.</li>
                  </ul>
                  <BusinessProspect subscription={"basic"} />
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="pricing-card">
                <CardBody>
                  <CardTitle className="pricing-title">
                    Standard Subscription
                  </CardTitle>
                  <CardText className="biz-price">
                    Price: <span>550 p/m</span>
                  </CardText>
                  <ul>
                    <li>Everything in Basic</li>
                    <li>
                      1 ton vehicle + driver{" "}
                      <img
                        src={getImage("1tonopen")}
                        alt="bakkie"
                        className="price-truck-image"
                      />
                    </li>
                    <li>
                      1.5 ton vehicle + driver{" "}
                      <img
                        src={getImage("1.5tonclosed")}
                        alt="bakkie"
                        className="price-truck-image"
                      />
                    </li>
                    <li>Packing</li>
                    <li>Short + Long distance trips</li>
                    <li>You only pay for what you use</li>
                    <li>1 hour 30 minutes lead time for trip confirmation.</li>
                  </ul>
                  <BusinessProspect subscription={"standard"} />
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="pricing-card">
                <CardBody>
                  <CardTitle className="pricing-title">
                    Premium Subscription
                  </CardTitle>
                  <CardText className="biz-price">
                    Price: <span>850 p/m</span>
                  </CardText>
                  <ul>
                    <li>Everything in Standard</li>
                    <li>
                      4 ton vehicle + driver{" "}
                      <img
                        src={getImage("4tonopen")}
                        alt="bakkie"
                        className="price-truck-image"
                      />
                    </li>
                    <li>
                      8 ton vehicle + driver{" "}
                      <img
                        src={getImage("8tonclosed")}
                        alt="bakkie"
                        className="price-truck-image"
                      />
                    </li>
                    <li>24/7 support</li>
                    <li>Helpers + handling</li>
                    <li>Account manager</li>
                    <li>Discounted pricing</li>
                    <li>You only pay for what you use</li>
                    <li>30 minute lead time for trip confirmation.</li>
                  </ul>
                  <BusinessProspect subscription={"premium"} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="divvyup b-center"></div>

          <Row>
            <Col>
              <h2>Trusted By</h2>
              <div className="logos">
                <img
                  src="https://media.qiktruck.co.za/partners/abacus-insurance.co.za-1-2.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/amjelectrical.co.za-3-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/asiproperty.co.za-4-2.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/baobabtrees.com-5-19.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/8ef69eab1f409c49aba7.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/bespokeconcepts.co.za-7-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/calgrom3.com-11-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/cropped-Falcus-Manufacturing-FavIcon.jpg"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/cropped-gymfablgo2.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/dbenterprise.co.za-15-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/ecotechafrica.co.za-18-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/famousbrands.co.za-21-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/greengab.co.za-24-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/khumo.co.za-29-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/liquidmovers.co.za-32-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/mijeb.co.za-36-2.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/oily-logo-black.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/paecotec-logo.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/skzn.co.za-52-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/tridentsec.co.za-64-1.png"
                  alt="Company 1"
                  className="company-logo"
                />
                <img
                  src="https://media.qiktruck.co.za/partners/vdlv.co.za-69-1.png"
                  alt="Company 1"
                  className="company-logo"
                />{" "}
              </div>
            </Col>
          </Row>
          <div className="divvyup b-center"></div>
          <h6>and many more...</h6>
          <div className="divvyup b-center"></div>
        </Container>
      </React.Fragment>
    );
  }
}

export default Section;
