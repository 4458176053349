import Bugsnag from "@bugsnag/js";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import cookie from "react-cookies";
import { withRouter } from "react-router";
import SEO from "react-seo-component";
import { Container } from "reactstrap";
import constants from "../../Constants";
import ProfileMenuHeader from "../customer-profile/ProfileMenuHeader";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let contractorderid = "";
    let email = "";
    let paynow = "";
    let success = "";
    let cancel = "";
    let customercontractid = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      contractorderid = queryParams.get("contractorderid") || "";
      email = queryParams.get("email") || "";
      paynow = queryParams.get("paynow") || "";
      success = queryParams.get("success") || "";
      cancel = queryParams.get("cancel") || "";
      customercontractid = queryParams.get("customercontractid") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      loading: false,
      trackedOrderValid: false,
      apierror: "",
      contractorderid,
      customercontractid,
      email,
      paynow,
      trips: [],
      onceoff: [],
      success,
      cancel,
    };
  }
  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }

  customerGetTrips(vehicleid) {
    this.setState({
      loading: true,
      apierror: "",
    });
    axios
      .post(
        baseURL + "/customer-get-trips",
        {
          vehicleid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: cookie.load("qtcid_"),
          },
        }
      )
      .then((response) => {
        this.setState({
          trips: response.data?.contract?.filter((f) =>
            this.state.customercontractid?.length > 0
              ? f.customercontractid === this.state.customercontractid
              : true
          ),
          onceoff:
            this.state.customercontractid?.length > 0
              ? []
              : response.data.onceoff,
        });
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.customerGetTrips();
  }

  render() {
    const { trips, onceoff } = this.state;
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/track-order"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section contract-trip-track" id="location">
          <Container style={{ display: "grid" }}>
            <ProfileMenuHeader heading={"Your trips"} />
            {this.state.customercontractid?.length > 0 && (
              <p>{this.state.customercontractid}</p>
            )}
            {this.state.loading === false && this.state.apierror.length > 0 && (
              <p className="error-message">{this.state.apierror}</p>
            )}
            {/* {loading && <p>Loading...</p>} <h5>Contract trips</h5>
            {trips?.length === 0 && !loading && (
              <>
                <p>
                  {customercontractid?.length > 0 ? (
                    <>
                      <br />
                      <b>No trips in this contract.</b>
                      <br />

                      <br />
                    </>
                  ) : (
                    <>
                      <a href="/customer-truck-hire">
                        Create a Daily, Weekly or Monthly contract >
                      </a>
                      <br />
                      <br />
                      Or book a <a href="/#quotestart">once off trip ></a>
                    </>
                  )}
                </p>
              </>
            )} */}
            {trips?.map((cv) => {
              const cvimage = this.getImage(cv.trucktype);
              return (
                <div className="contract-trips-list">
                  <div className="contract-trips-date">
                    {" "}
                    {moment(cv.collectiondate).format("MMM DD YYYY")} at{" "}
                    {cv.collectiontime}
                  </div>
                  {cv.contractorderid?.split("-")[0]}
                  <img
                    src={cvimage}
                    alt=""
                    className={"contract-vehicle-list-imagecv"}
                  />
                  <a
                    className="vehicle-names"
                    href={
                      "/contract-trip?contractorderid=" + cv.contractorderid
                    }
                  >
                    {cv.firstname} {cv.lastname} {cv.licensenumber}
                    {" >> "}
                    {Math.floor(cv.distance / 1000)}km,{" "}
                    <b className={"trip-status " + cv.orderstatus}>
                      {cv.orderstatus}
                    </b>
                  </a>
                </div>
              );
            })}
            {this.state.customercontractid === "" && (
              <>
                {onceoff?.length === 0 && (
                  <>
                    <p>
                      Book a <a href="/#quotestart">once off trip ></a>
                    </p>
                    <p>
                      or{" "}
                      <a href="/customer-truck-hire">
                        Create a Daily, Weekly or Monthly contract >
                      </a>
                    </p>
                  </>
                )}
                {onceoff?.map((cv) => {
                  const cvimage = this.getImage(cv.trucktype);
                  return (
                    <div className="contract-trips-list">
                      <div className="contract-trips-date">
                        {cv.orderid?.split("-")[0]}
                      </div>
                      <a
                        className="vehicle-names"
                        href={
                          "/track-order?email=" +
                          cv.email +
                          "&orderid=" +
                          cv.orderid +
                          "&paynow=yes"
                        }
                      >
                        <img src={cvimage} alt="" />
                        {cv.firstname} {cv.lastname} {cv.licensenumber}
                        {cv.collectiondate} {cv.collectiontime}
                        {" >> "}
                        <b className={"trip-status " + cv.orderstatus}>
                          {cv.orderstatus}
                        </b>
                      </a>
                    </div>
                  );
                })}
              </>
            )}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Section);
