import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Efficient Freight delivery Services in Musina"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/freight-delivery-musina"}
          pageHeading={"Efficient Freight delivery Services in Musina"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get efficient Freight delivery services in Musina with our team of skilled drivers and advanced technology at QikTruck. Our priority is ensuring that your transportation and delivery needs are met in a timely and cost-effective manner. Sign up now and start enjoying the benefits of our services."}
          pageDescription2={"Need efficient Freight delivery services in Musina? QikTruck has you covered! Our team of skilled drivers and advanced technology ensure that your transportation and delivery needs are met in a timely and cost-effective manner. From package delivery to furniture transportation, we have the solutions you need. Sign up now and start enjoying the benefits of our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
