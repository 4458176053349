import axios from "axios";
import Flags from "country-flag-icons/react/3x2";
import { Component, default as React } from "react";
import ContentLoader from "react-content-loader";
import constants from "../../Constants";


const baseURL = constants.baseURL;
class VehiclesBanner extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      openSuccessModal: false,
      truckType: "1.5tonopen",
      apierror: "",
      location: {
        lat: 0,
        lng: 0,
      },
      firstname: "",
      lastname: "",
      email: "",
      mobilenumber: "",
      idnumber: "",
      vin: "",
      licenseplatenumber: "",
      vehicles: 0,
      vehicletypes: [],
    };
  }
  componentDidMount() {
    const { vehicletypes } = this.state;
    if (vehicletypes.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
        });
      });
  }
  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    const { vehicletypes } = this.state;

    return (
      <React.Fragment>
        {vehicletypes?.length > 0 ? (
          <div
            direction="right"
            className="banner-vehicles-fader"
           
            speed={35}
            // gradient={false}
          >
            <br />
            <h4>Locations preview</h4>
            <br />
            {vehicletypes
              ?.filter((v) => v.sell === true)
              ?.map((vehicle, index) => {
                const image = `https://media.qiktruck.co.za/${vehicle.type}.png`;
                return (
                  <div key={index} className="banner-vehicle">
                    <img src={image} alt="" />
                  </div>
                );
              })}
            <Flags.ZA className="country-flag" />
          </div>
        ) : (
          <ContentLoader
            viewBox="0 0 380 70"
            speed={5}
            backgroundColor={"lightgrey"}
            foregroundColor={"#d9ffe3"}
          >
            {/* Only SVG shapes */}
            <rect x="0" y="30" rx="5" ry="5" width="61" height="41" />
            <rect x="80" y="37" rx="4" ry="4" width="200" height="13" />
            <rect x="80" y="60" rx="3" ry="3" width="280" height="10" />
          </ContentLoader>
        )}
      </React.Fragment>
    );
  }
}

export default VehiclesBanner;
