import React from "react";

const data = {
  title: "Frequently asked questions",
  rows: [
    {
      title: "How does it work?",
      content: (
        <ul>
          <li>
            <p>
              <a href="/quote" style={{ borderBottom: "solid 1px blue" }}>
                Customer gets an instant free quotation.
              </a>
            </p>
          </li>
          <li>
            <p>Customer Accepts quotation, pay now or pay later.</p>
          </li>
          <li>
            <p>
              QikTruck mobile app notifies a Driver about the order and price.
            </p>
          </li>
          <li>
            <p>
              The driver checks details on the app and calls the customer to
              verify.
            </p>
          </li>
          <li>
            <p>Driver goes to collect and deliver your goods.</p>
          </li>
          <li>
            <p>
              QikTruck pays the vehicle owner if customer does not pay directly
              to driver.
            </p>
          </li>
          <li>
            <p>Business clients/partners can pay via credit.</p>
          </li>
        </ul>
      ),
    },
    {
      title: "Where do we operate?",
      content: `We operate in all provinces in South Africa.`,
    },
    {
      title: "Do we operate like Uber?",
      content:
        "Our model is like Uber. We have a fleet of internal or partner trucks and a large community of customers.",
    },
    {
      title: "Can you join as a Partner?",
      content: `Yes, partners are welcome. Simply register your vehicle and we will activate your profile. Partners get 80 - 90% of every trip.`,
    },

    {
      title: "Where is the Driver Mobile app?",
      content: (
        <p>
          You can download the app on Google Android Playstore: Called{" "}
          <b>
            <a href="https://play.google.com/store/apps/details?id=za.co.qiktruck.driver">
              QikTruck Driver
            </a>
          </b>
          .
        </p>
      ),
    },
    {
      title: "Can you trust our drivers?",
      content: (
        <p>
          Yes, all our actors and processors go through inspection, fraud checks
          and criminal checks.
        </p>
      ),
    },
    {
      title: "How do drivers get paid?",
      content: (
        <p>
          Drivers can choose payouts weekly, every 2 weeks or monthly.
          <b> No payout limit. </b>
          Payments can take 3 business days to reflect depending in your bank.
        </p>
      ),
    },
    {
      title: "How long does it take to approve a Driver application",
      content: (
        <p>
          Vehicles Inspections, Fraud checks and Criminal checks can take up to
          <b> 20 business days</b>.
        </p>
      ),
    },
    {
      title: "Goods In Transit Insurance? For Driver Partners: ",
      content: (
        <p>
          For Driver Partners: Goods in Transit insurance covers your vehicle
          for loss or damage to goods while in transit, including loading,
          temporary storage, and items being off loaded at any building or place
          of storage at the consignee's premises.
          <ul>
            <li>Steps</li>
            <li>
              1. Contact support@qiktruck and request for a call back from the
              Insurance team.
            </li>
            <li>2.The Insurance team will discuss how the insurance works.</li>
            <li>3. Submit your application to the Insurance team.</li>
            <li>4. Review quotation.</li>
            <li>5. Your vehicle is covered for Goods In Transit.</li>
          </ul>
          <a href="https://qiktruck.co.za/articles/goods-in-transit-insurance-qiktruck">
            {" "}
            Goods In Transit with Insurance
          </a>
          .
        </p>
      ),
    },
    {
      title: "What percentage does Qiktruck take per trip?",
      content: (
        <p>
          Our service fee is currently at = 20% - 40% as service fee including VAT depending on vehicle size and contract requirements. You can view more details here{" "}
          <a href="https://qiktruck.co.za/pricing"> Pricing page</a>.
        </p>
      ),
    },
    {
      title: "How do I pay QikTruck?",
      content: (
        <p>
          Bank name: <b>FNB</b>
          <br />
          Branch code: <b>255355</b>
          <br />
          Account number: <b>62934406986</b>
          <br />
          Ref: <b>orderid or ID number or your email</b>
          <br /> <br />
          If you are a driver/partner, the service fee is = 20% - 40% as service fee including VAT depending on vehicle size and contract requirements.
          <br />
          If the Trip is R100, you send R20 to QikTruck.
        </p>
      ),
    },
    {
      title: "What goods do we deliver?",
      content: (
        <p>
          If you can fit it in a truck. We will deliver it.
          <br />
          Move anything anytime with our delivery trucks and drivers.
          <br /> Furniture, Equipment, Construction Materials, Food, Vehicles,
          Waste, Fuel and more!
        </p>
      ),
    },

    {
      title: "What is required for driver registration?",
      content: (
        <p>
          <b>Email documents to support@qiktruck.co.za</b>
          <br />
          1. Vehicle Disc.
          <br />
          2. Driver ID copy + Owner ID
          <br />
          3. Driver's license copy
          <br />
          4. Proof of Residence (Driver Municipality bill or Tax invoice or Bank
          statement)
          <br />
          5. A photo of you standing in front of each vehicle with the number
          plate visible.
          <br />
          6. At least 3 photos of your vehicle.
          <br />
          7. Valid SA Bank account (Bank statement or Proof of Account)
        </p>
      ),
    },
    {
      title: "How much is driver registration?",
      content: (
        <p>
          <b>FREE</b>
          <br />
          Driver registration is FREE.
          <br />
          The QikTruck Inspection team drives to partner trucks for inspections
          and training.
          <br />
          Documents submitted must be approved first before inspection.
        </p>
      ),
    },
  ],
};

export default data;
