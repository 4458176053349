import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/investors"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>
                  QikTruck: <span className="red">Welo Health</span>
                </h1>
                <br />
                <p>
                  In a groundbreaking move towards enhancing driver wellness,
                  QikTruck has proudly partnered with Welo Health to introduce
                  the Welocare package, a health care initiative tailored
                  specifically for our valued partner drivers. This partnership,
                  effective from December 1, 2023, signifies a shared commitment
                  between QikTruck and Welo Health to prioritize the health and
                  well-being of those who drive with us.{" "}
                </p>
                <ul>
                  <li>
                    The Welocare package is a comprehensive health solution
                    designed to fit the unique lifestyle of QikTruck drivers. It
                    encompasses{" "}
                    <strong>monthly digital doctor consultations</strong>,
                    allowing drivers to receive medical advice and consultations
                    without the need to take time off the road. These
                    consultations are not only convenient but also ensure that
                    drivers can access professional health care wherever they
                    are.{" "}
                  </li>
                  <li>
                    Understanding the importance of{" "}
                    <strong>regular health check-ups</strong>, the partnership
                    also facilitates quarterly on-site medical check-ups at
                    selected depots. This ensures that drivers can monitor their
                    health regularly and take proactive steps towards
                    maintaining their well-being.{" "}
                  </li>
                  <li>
                    Moreover, <strong>managing chronic conditions</strong> is
                    made easier for our drivers through the{" "}
                    <strong>delivery of chronic medications</strong> from public
                    clinics or hospitals directly to a depot of their choice,
                    every three months. This service aims to minimize the
                    inconvenience of medication collection, helping drivers stay
                    on top of their health without disrupting their schedules.{" "}
                  </li>
                  <li>
                    In recognition of the vital role our drivers play in the
                    success of QikTruck, we are excited to offer a{" "}
                    <strong>50% reimbursement</strong> on the Welocare package.
                    This gesture underscores our dedication to not only
                    supporting our drivers' professional journeys but also
                    investing in their personal health and wellness.{" "}
                  </li>
                  <li>
                    We strongly encourage all QikTruck partner drivers to take
                    this unique opportunity to{" "}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfvpOq_esG-hIMXev1zdfv5no4FhLGlR3BI_-EMjIq6Vu4_pg/viewform?vc=0&c=0&w=1&flr=0">
                      sign up for Welocare  <i className="mdi mdi-arrow-right"></i>
                    </a>
                    . By joining this initiative, drivers are not just{" "}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfvpOq_esG-hIMXev1zdfv5no4FhLGlR3BI_-EMjIq6Vu4_pg/viewform?vc=0&c=0&w=1&flr=0"
                      className="green"
                    >
                      signing up for a health package
                      <i className="mdi mdi-arrow-right"></i>
                    </a>
                    ; they are taking a significant step towards a healthier,
                    more fulfilling life on and off the road.{" "}
                  </li>
                  <li>
                    <p>
                      <br />
                      <br />
                      <video
                        src="https://media.qiktruck.co.za/welo/Qiktruck-Animation.mp4"
                        width={400}
                        controls
                      ></video>
                      <br />
                      <br />
                    </p>
                  </li>
                  <li>
                    Health is our most valuable asset, and at QikTruck, we are
                    committed to ensuring our drivers have the support and
                    resources they need to keep their health in check. Together
                    with Welo Health, we are driving towards a future where
                    every driver is empowered to take control of their health
                    and well-being.{" "}
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfvpOq_esG-hIMXev1zdfv5no4FhLGlR3BI_-EMjIq6Vu4_pg/viewform?vc=0&c=0&w=1&flr=0"
                      className="red"
                    >
                      Join us  <i className="mdi mdi-arrow-right"></i>
                    </a>{" "}
                    in this vital initiative. Sign up for Welocare today and
                    take the first step towards a healthier tomorrow. Your
                    health is your wealth, and at QikTruck, we are here to
                    support you every mile of the journey.
                  </li>
                  <li>
                    <p>
                      <br />
                      <img
                        src="https://media.qiktruck.co.za/welo/Driver-Leaflet.png"
                        width={300}
                        alt=""
                      />
                    </p>
                  </li>
                </ul>

                <p>
                  Please contact: <b> support@qiktruck.co.za </b>for more
                  information.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
