import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Safe and Secure Transportation Services in Port Shepstone"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/transportation-port-shepstone"}
          pageHeading={"Safe and Secure Transportation Services in Port Shepstone"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get safe and secure Transportation services in Port Shepstone with our team of experienced drivers and state-of-the-art vehicles at QikTruck. We take the safety of your packages seriously and use advanced tracking and security measures to ensure their protection. Sign up now and start enjoying the peace of mind that comes with our services."}
          pageDescription2={"Need safe and secure Transportation services in Port Shepstone? Look no further! Our team of experienced drivers and state-of-the-art vehicles at QikTruck take the safety of your packages seriously and use advanced tracking and security measures to ensure their protection. We are committed to delivering your packages safely and securely to their destination. Sign up now and start enjoying the peace of mind that comes with our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
