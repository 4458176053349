import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import news from "../articles/news";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za"}
          twitterUsername={"qiktrucksa"}
        />
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={4} sm={12} className="truck-hire">
              <a href="/">
                <h3 className="font-18">
                  <i className="mdi mdi-clock"></i> Instant quotation
                  <i className="mdi mdi-arrow-right"></i>
                </h3>
                <p>
                  <a href="/truck-hire?src=landing">
                    <i className="mdi mdi-star"></i>Book a trip.
                  </a>
                </p>
                <p>
                  <a href="/bakkie-hire?src=landing">
                    <i className="mdi mdi-star"></i>Ship your goods.
                  </a>
                </p>
                <p>
                  <i className="mdi mdi-star"></i>On demand transportation.
                </p>
              </a>
            </Col>
            <Col lg={4} md={4} sm={12} className="truck-hire">
              <a href="/partner-drivers">
                <h3 className="font-18">
                  <i className="mdi mdi-truck"></i> Driver registration
                  <i className="mdi mdi-arrow-right"></i>
                </h3>
                <p>
                  <a href="/truck-hire?src=landing">
                    <i className="mdi mdi-star"></i>Earn with your vehicle.
                  </a>
                </p>
                <p>
                  <a href="/bakkie-hire?src=landing">
                    <i className="mdi mdi-star"></i>Short distance and long
                    distance trips.
                  </a>
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Insured services.
                </p>
              </a>
            </Col>
            <Col lg={4} md={4} sm={12} className="truck-hire">
              <a href="/developers">
                <h3 className="font-18">
                  <i className="mdi mdi-source-branch"></i> Developer API{" "}
                  <i className="mdi mdi-arrow-right"></i>
                </h3>
                <p>
                  <a href="/truck-hire?src=landing">
                    <i className="mdi mdi-star"></i>Build apps.
                  </a>
                </p>
                <p>
                  <a href="/bakkie-hire?src=landing">
                    <i className="mdi mdi-star"></i>Transport your goods.
                  </a>
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Get quotations and manage
                  trips.
                </p>
              </a>
            </Col>
            <Col lg={12} md={12} sm={12} className="truck-hire fill-width">
              <h3 className="font-18"> In the News</h3>
            </Col>
          </Row>
          <Row>
            {news.map((a, index) => (
              <Col lg={4} key={index}>
                <a
                  className="news-article"
                  href={"/articles/" + a?.pathname?.toLocaleLowerCase()}
                >
                  <div
                    className="article-list-image"
                    style={{
                      backgroundImage: `url(https://media.qiktruck.co.za/${a.image})`,
                    }}
                    alt={a.image}
                  />
                  <h1>{a.heading}</h1>
                  <div className="date-article-added">
                    <i className="mdi mdi-clock-outline ml-2"></i>
                    {a.date}
                    <i className="mdi mdi-pin ml-2"></i>
                    {a.category}
                  </div>
                  <p>{a.copy}</p>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Section;
