import React from "react";
import CurrencyFormat from "react-currency-format";
const PriceMiniView = ({ option, getQuote, disabled }) => {
  const { sellingPrice, truckType } = option;
  return (
    <div
      className={"pricepreview " + (disabled ? "notactive" : "")}
      onClick={() => getQuote()}
      disabled={disabled}
    >
      <div className="pricepreview-trucktype trucktype">
        {truckType.replace("open", "").replace("closed", "")}
      </div>
      <img
        src={`https://media.qiktruck.co.za/${truckType}.png`}
        alt=""
        className={"image"}
        height="30"
      />
      <CurrencyFormat
        value={sellingPrice / 100} //cents to rands
        displayType={"text"}
        thousandSeparator={true}
        prefix={"R"}
        decimalScale={2}
        className="sellingPriceValue price"
      />
    </div>
  );
};

export default PriceMiniView;
