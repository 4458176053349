import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/drver-training"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>Truck Driver Training Course</h1>
                <p>
                  Welcome to the truck driver training course for our software
                  company providing driver partner services in South Africa!{" "}
                  <span role="img" aria-label="emoji">
                    🇿🇦
                  </span>
                  Follow these 10 steps to become a skilled and professional
                  driver for our company:
                </p>
                <h2>1. Introduction to the company</h2>
                <p>
                  This step will cover the company's mission, vision, and
                  values, as well as its services and policies. You'll learn
                  about the driver partner program and the expectations for
                  drivers.{" "}
                  <span role="img" aria-label="emoji">
                    🚛
                  </span>
                </p>
                <h2>2. Safety training</h2>
                <p>
                  Safety is our top priority for both you and the cargo. This
                  step will cover topics such as defensive driving, proper
                  loading and securing of cargo, and accident prevention. You'll
                  also learn how to handle emergencies and what to do in case of
                  an accident.{" "}
                  <span role="img" aria-label="emoji">
                    🚫💥
                  </span>
                </p>
                <h2>3. Vehicle maintenance</h2>
                <p>
                  You'll learn how to properly maintain your truck, including
                  checking fluid levels, tire pressure, and brakes. You'll also
                  learn how to report any maintenance issues and how to keep
                  your truck clean and organized.{" "}
                  <span role="img" aria-label="emoji">
                    🚧
                  </span>
                </p>
                <h2>4. Navigation and route planning</h2>
                <p>
                  You'll learn how to use navigation and routing software, as
                  well as how to plan efficient routes that consider factors
                  such as traffic and weather. You'll also learn how to use
                  tools such as load boards and dispatch software to manage your
                  deliveries.{" "}
                  <span role="img" aria-label="emoji">
                    🗺️
                  </span>
                </p>
                <h2>5. Customer service</h2>
                <p>
                  As a driver partner, you'll be representing the company and
                  interacting with customers. This step will cover how to
                  communicate with customers and handle any issues that may
                  arise. You'll learn how to handle customer complaints, as well
                  as how to build positive relationships with customers.{" "}
                  <span role="img" aria-label="emoji">
                    😄
                  </span>
                </p>
                <h2>6. Legal requirements</h2>
                <p>
                  You'll learn about the legal requirements for operating a
                  commercial vehicle in South Africa, including licensing,
                  insurance, and regulations. This step will cover any specific
                  requirements for the company, as well as how to stay up to
                  date on changes in the law.{" "}
                  <span role="img" aria-label="emoji">
                    📜
                  </span>
                </p>
                <h2>7. Load management</h2>
                <p>
                  You'll learn how to properly load and secure cargo, including
                  how to use load restraints and tie-down points. You'll also
                  learn how to calculate the weight and balance of your loads
                  and how to properly document the load.{" "}
                  <span role="img" aria-label="emoji">
                    📊
                  </span>
                </p>
                <h2>8. Fuel management</h2>
                <p>
                  You'll learn how to manage fuel usage, including how to plan
                  routes that minimize fuel consumption and how to report any
                  fuel issues. You'll also learn how to monitor fuel levels and
                  how to refuel safely.{" "}
                  <span role="img" aria-label="emoji">
                    ⛽️
                  </span>
                </p>
                <h2>9. Emergency procedures</h2>
                <p>
                  You'll learn what to do in case of an emergency, including how
                  to handle accidents, fires, or other unexpected situations.
                  You'll learn how to use emergency equipment, such as fire
                  extinguishers, and how to communicate with dispatch and other
                  emergency services.{" "}
                  <span role="img" aria-label="emoji">
                    🚨
                  </span>
                </p>
                <h2>10. Continuing education</h2>
                <p>
                  It's important to stay up to date on new regulations,
                  technologies, and best practices in the trucking industry.
                  This step will cover how to access resources for ongoing
                  learning and development, such as company training programs or
                  industry conferences. You'll also learn how to stay current on
                  industry trends and developments.{" "}
                  <span role="img" aria-label="emoji">
                    📈
                  </span>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
