import React, { useState, useEffect, useMemo } from "react";

const LetterByLetter = () => {
  const phrases = useMemo(
    () => [
      "On demand transportation",
      "Truck and Driver hire",
      "Easy online booking process",
      "Once off trips or regular trips",
      "Software and Logistics",
      "World class customer service",
      "Artificial Intelligence",
      "The future of transportation",
      "You name it, we move it",
    ],
    []
  );

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentText(
        (prevText) => prevText + phrases[currentPhraseIndex][charIndex]
      );
      setCharIndex((prevCharIndex) => prevCharIndex + 1);
    }, 60); // Change this value to control the typing speed

    if (charIndex === phrases[currentPhraseIndex].length) {
      clearTimeout(timer);
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setCurrentText("");
        setCharIndex(0);
      }, 3000); // 3-second pause before the next phrase
    }

    return () => clearTimeout(timer);
  }, [charIndex, currentPhraseIndex, phrases]);

  return <div className="moving-headline">{currentText}</div>;
};

export default LetterByLetter;
