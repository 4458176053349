import Bugsnag from "@bugsnag/js";
import { default as React, useState } from "react";
import SEO from "react-seo-component";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";

import constants from "../../Constants";
const baseURL = constants.baseURL;

const SupplierRegistration = () => {
  const [success, setSuccess] = useState(false);
  const [errored, setErrored] = useState(false);
  const [formData, setFormData] = useState({
    companyname: "",
    homelat: 0,
    homelng: 0,
    firstname: "",
    lastname: "",
    email: "",
    mobilenumber: "",
    category: "",
    businesstype: "",
    description: "",
    transportationpreference: "",
    shippingrequirements: "",
  });
  const [addressData, setAddressData] = useState({
    homelat: 0,
    homelng: 0,
  });

  function validForm() {
    let data = formData;
    const errors = [];

    if (!data.companyname.trim()) {
      errors.push({
        message: "Company name is required.",
        field: "companyname",
      });
    }

    if (addressData.homelat === 0 || addressData.homelng === 0) {
      errors.push({ message: "Address is required.", field: "homelat" });
    }

    if (!data.firstname.trim()) {
      errors.push({ message: "First name is required.", field: "firstname" });
    }

    if (!data.lastname.trim()) {
      errors.push({ message: "Last name is required.", field: "lastname" });
    }

    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!data.email.trim() || !emailRegex.test(data.email)) {
      errors.push({ message: "Valid email is required.", field: "email" });
    }

    const mobileRegex = /^\d/;
    if (!data.mobilenumber.trim() || !mobileRegex.test(data.mobilenumber)) {
      errors.push({
        message: "Valid mobile number is required.",
        field: "mobilenumber",
      });
    }

    if (!data.category.trim()) {
      errors.push({ message: "Category is required.", field: "category" });
    }

    if (!data.businesstype.trim()) {
      errors.push({
        message: "Business type is required.",
        field: "businesstype",
      });
    }

    if (!data.description.trim()) {
      errors.push({
        message: "Description is required.",
        field: "description",
      });
    }

    if (!data.transportationpreference.trim()) {
      errors.push({
        message: "Transportation preference is required.",
        field: "transportationpreference",
      });
    }

    if (!data.shippingrequirements.trim()) {
      errors.push({
        message: "Shipping requirements are required.",
        field: "shippingrequirements",
      });
    }

    return { errors: errors, isValid: errors.length === 0 };
  }

  const validStep = (fields) => {
    let valid = true;
    if (fields)
      [...fields].forEach((field) => {
        valid = valid && field !== "";
      });
    return valid;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name.toLowerCase()]: e.target.value });
  };
  const onPlaceSelected = (place) => {
    if (!place?.geometry?.location?.lat()) {
      Bugsnag.notify(
        new Error("COLLECTION place not found: " + this.state.truckType)
      );
    }
    setAddressData({
      homelat: place?.geometry?.location?.lat(),
      homelng: place?.geometry?.location?.lng(),
    });
  };

  const handleSubmit = async (e) => {
    try {
      setErrored(false);
      e.preventDefault();
      const response = await fetch(baseURL + "/register-supplier", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, ...addressData }),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        setErrored(true);
      }
    } catch (error) {
      setErrored(true);
      Bugsnag.notify(error);
    }
  };

  return (
    <Container className="biz-reg">
      <SEO
        title={"Register your business and get leads."}
        titleTemplate={"QikTruck"}
        titleSeparator={`-`}
        description={
          "Get nmore customers with QikTruck. Register your business for free."
        }
        image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
        pathname={"https://qiktruck.co.za/track-order"}
        twitterUsername={"qiktrucksa"}
      />
      <Row>
        <Col lg={12}>
          <div className="biz ads">
            <h2>Get Leads for your business</h2>{" "}
            <p>
              <i className={"mdi mdi-check-circle order-received"}></i>
              Registration is free.{" "}
            </p>
            <p>
              <i className={"mdi mdi-check-circle order-received"}></i>
              Get leads when our customers move their goods.{" "}
            </p>
            <p>
              <i className={"mdi mdi-check-circle order-received"}></i>
              Increased exposure to potential clients
            </p>
            <i className={"mdi mdi-rocket supplier-rocket"}></i>
          </div>
        </Col>
        <Col lg={6}>
          <div className="biz ads">
            <h6>Benefits</h6>
            <p>
              <i className={"mdi mdi-star orange order-received"}></i>
              Get access to a larger customer base
            </p>
            <p>
              <i className={"mdi mdi-star orange order-received"}></i>
              Effective lead generation process
            </p>
            <p>
              <i className={"mdi mdi-star orange order-received"}></i>
              Qualified leads for your business
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="biz ads">
            <h6>Process</h6>
            <p>
              <b>Step 1: </b>
              <i className={"mdi mdi-arrow-right orange order-received"}></i>
              Save your company details.
            </p>
            <p>
              <b>Step 2: </b>
              <i
                className={"mdi mdi-arrow-right orange order-received"}
              ></i>{" "}
              Verify email and phone number.
            </p>
            <p>
              <b>Step 3: </b>
              <i
                className={"mdi mdi-arrow-right orange order-received"}
              ></i>{" "}
              Review leads on your dashboard.
            </p>
          </div>
        </Col>
      </Row>
      <hr />
      <br />
      <Form onSubmit={handleSubmit}>
        {validStep(["x"]) && (
          <Row>
            <Col lg={6} sm={12}>
              {/* Company Information */}
              <FormGroup>
                <Label for="companyname">Company Name</Label>
                <div className="inputter">
                  <Input
                    type="text"
                    name="companyname"
                    id="companyname"
                    defaultValue={formData.companyname}
                    onChange={handleChange}
                    required
                  />
                  {validForm()
                    .errors.filter((er) => er.field === "companyname")
                    .map((error) => (
                      <p
                        className=" inline-m"
                        key={error.field + new Date().toDateString()}
                      >
                        {error.message}
                      </p>
                    ))}
                </div>
              </FormGroup>
            </Col>
            <Col lg={6} sm={12}>
              <FormGroup>
                <Label for="companyAddress">Company Address</Label>
                <div className="inputter">
                  <PlacesAutocomplete
                    type="text"
                    className="form-control"
                    id="collection"
                    placeholder=""
                    required
                    autoComplete="new-password"
                    onPlaceSelected={onPlaceSelected}
                  />
                  {validForm()
                    .errors.filter((er) => er.field === "homelat")
                    .map((error) => (
                      <p
                        className=" inline-m"
                        key={error.field + new Date().toDateString()}
                      >
                        {error.message}
                      </p>
                    ))}
                </div>
              </FormGroup>
            </Col>
          </Row>
        )}
        <br />
        <br />
        {/* Contact Information */}
        <h5>Contact Information</h5>
        <Row>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="contactName">First Name</Label>
              <div className="inputter">
                <Input
                  type="text"
                  name="firstname"
                  id="firstname"
                  defaultValue={formData.firstname}
                  onChange={handleChange}
                  required
                />
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="contactName">Last Name</Label>
              <div className="inputter">
                <Input
                  type="text"
                  name="lastname"
                  id="lastname"
                  defaultValue={formData.lastname}
                  onChange={handleChange}
                  required
                />
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="email">Email Address</Label>
              <div className="inputter">
                <Input
                  type="email"
                  name="email"
                  id="email"
                  defaultValue={formData.email}
                  onChange={handleChange}
                  required
                />
                {validForm()
                  .errors.filter((er) => er.field === "email")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="mobilenumber">Phone Number</Label>
              <div className="inputter">
                <Input
                  type="tel"
                  name="mobilenumber"
                  id="mobilenumber"
                  defaultValue={formData.mobilenumber}
                  onChange={handleChange}
                  required
                />
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        {/* Products and Services */}
        <h5>Products and Services</h5>
        <Row>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="category">Industry</Label>
              <div className="inputter">
                <Input
                  type="select"
                  name="category"
                  id="category"
                  defaultValue={formData.category}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- Select --</option>
                  <option value="agriculture">Agriculture</option>
                  <option value="automotive">Automotive</option>
                  <option value="aerospace">Aerospace</option>
                  <option value="biotechnology">Biotechnology</option>
                  <option value="construction">Construction</option>
                  <option value="consumer_goods">Consumer Goods</option>
                  <option value="chemicals">Chemicals</option>
                  <option value="defense">Defense</option>
                  <option value="e-commerce">E-commerce</option>
                  <option value="electronics">Electronics</option>
                  <option value="energy">Energy</option>
                  <option value="engineering">Engineering</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="fashion">Fashion</option>
                  <option value="finance">Finance</option>
                  <option value="food_and_beverage">Food and Beverage</option>
                  <option value="healthcare">Healthcare</option>
                  <option value="hospitality">Hospitality</option>
                  <option value="information_technology">
                    Information Technology
                  </option>
                  <option value="insurance">Insurance</option>
                  <option value="logistics">Logistics</option>
                  <option value="manufacturing">Manufacturing</option>
                  <option value="mining">Mining</option>
                  <option value="pharmaceuticals">Pharmaceuticals</option>
                  <option value="publishing">Publishing</option>
                  <option value="real_estate">Real Estate</option>
                  <option value="renewable_energy">Renewable Energy</option>
                  <option value="retail">Retail</option>
                  <option value="telecommunications">Telecommunications</option>
                  <option value="textiles">Textiles</option>
                  <option value="transportation">Transportation</option>
                  <option value="travel_and_tourism">Travel and Tourism</option>
                  <option value="utilities">Utilities</option>
                  <option value="waste_management">Waste Management</option>
                  <option value="wholesale_trade">Wholesale Trade</option>
                  <option value="wood_and_paper_products">
                    Wood and Paper Products
                  </option>
                  <option value="other">Other</option>
                </Input>
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="businesstype">Type</Label>
              <div className="inputter">
                <Input
                  type="select"
                  name="businesstype"
                  id="businesstype"
                  defaultValue={formData.businesstype}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- Select --</option>
                  <option value="manufacturer">Manufacturer</option>
                  <option value="retailer">Retailer</option>
                  <option value="servicer">Service provider</option>
                </Input>
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
          <Col lg={12} sm={12}>
            <FormGroup>
              <Label for="description">
                Description of Products or Services Offered
              </Label>
              <div className="inputter">
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  defaultValue={formData.description}
                  onChange={handleChange}
                  required
                />
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <h5>Shipping and Logistics Preferences</h5>
        <Row>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="transportationpreference">
                Current Transportation
              </Label>
              <div className="inputter">
                <Input
                  type="select"
                  name="transportationpreference"
                  id="transportationpreference"
                  defaultValue={formData.transportationpreference}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- Select --</option>
                  <option value="outsourced">Outsourced Transportation</option>
                  <option value="internal">Internal Transportation</option>
                  <option value="internal">Both</option>
                </Input>
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
          <Col lg={12} sm={12}>
            <FormGroup>
              <Label for="shippingRequirements">
                Shipping and Handling Requirements
              </Label>
              <div className="inputter">
                <Input
                  type="textarea"
                  name="shippingRequirements"
                  id="shippingRequirements"
                  defaultValue={formData.shippingRequirements}
                  onChange={handleChange}
                  required
                />
                {validForm()
                  .errors.filter((er) => er.field === "xxxxxx")
                  .map((error) => (
                    <p
                      className=" inline-m"
                      key={error.field + new Date().toDateString()}
                    >
                      {error.message}
                    </p>
                  ))}
              </div>
            </FormGroup>
          </Col>
        </Row>
        {/* Submit Button */}
        {errored && (
          <p>
            <i className="mdi mdi-alert-circle-outline"></i>
            <span className="text-danger">
              {" "}
              Error submitting form. Check all fields again.
            </span>
          </p>
        )}
        <Button
          type="submit"
          color="secondary float-right"
          disabled={!validForm().isValid}
        >
          Submit Form
        </Button>
        <p></p>
        <i>For all your reliable community business solutions...</i>
        <br />
        <br />
      </Form>
      <Modal isOpen={success}>
        <ModalHeader>
          <i className={"mdi mdi-check orange-circle  order-received"}></i>
          Details Received
        </ModalHeader>
        <ModalBody>
          <p>Hang in there while we process your request.</p>
          <p>We will get back to you in the next 48 hours/2 business days.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => (window.location.href = "/quote")}
          >
            Thank you
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default SupplierRegistration;
