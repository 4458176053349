const data = [
  {
    heading: "News: Driver safety at QikTruck",
    pathname: "driver-safety-at-qikTruck",
    image: "driver-safety.jpeg",
    date: "20 March 2022",
    category: "Internal news",
    copy: "Qiktruck is fully committed to the safety of our drivers and their vehicles. Qiktruck has partnered with insurance companies to help truck owners with low insurance costs.",
  },

  {
    heading: "News: Goods In Transit cover.",
    pathname: "goods-in-transit-insurance-qikTruck",
    image: "goods-in-transit-insurance.png",
    date: "1 June 2022",
    category: "Public news",
    copy: "For Driver Partners: Goods in Transit insurance covers your vehicle for loss or damage to goods while in transit, including loading, temporary storage, and items being off loaded at any building or place of storage at the consignee's premises.",
    links: [
      {
        title:
          "Contact support@qiktruck and request for a call back from the Insurance team",
        url: "mailto:support@qiktruck.co.za",
      },
    ],
  },
  {
    heading: "News: Highly Trained Professional Staff",
    pathname: "highly-trained-professional-staff",
    image: "trained-staff.jpeg",
    date: "15 March 2022",
    copy: "Qiktruck staff is highly trained and equiped for work in the logistics industry. Our partner trucks and drivers have been in the business for decades.",
  },
  {
    heading: "News: Business Relocation Services",
    pathname: "business-relocation-services",
    image: "business-relocation.jpeg",
    date: "20 March 2022",
    category: "Public news",
    copy: "Qiktruck has reliable and professional movers for your office relocation. Simply get your quotation and our driver will call to finalise all the details of your order.",
  },
  {
    heading: "News: Business Courier Services",
    pathname: "business-courier-services",
    image: "business-courier.jpeg",
    date: "30 June 2022",
    category: "Public news",
    copy: "Qiktruck has reliable and professional courier vehicles for your business. Simply get your quotation and our driver will call to finalise all the details of your order.",
  },
  {
    heading: "News: Delivery bikes on the move at QikTruck",
    pathname: "delivery-bikes-on-the-move-at-qikTruck",
    image: "delivery-bike.jpeg",
    date: "21 March 2022",
    category: "Public news",
    copy: "Qiktruck has partnered with many delivery bikes in South Africa. Increasing our load capacity for small packages deliveries. These drivers and bikes deliver medicine, documents and food amongst other light weight goods.",
  },
  {
    heading: "News: Get rewarded for promoting QikTruck",
    pathname: "get-rewarded-for-promoting-qikTruck",
    image: "refer-and-earn.png",
    date: "16 March 2022",
    category: "Public news",
    copy: "Qiktruck invites influencers and business professionals to join our promotions network. Easily generate Discount codes and share them with your network and clients.",
  },

  {
    heading: "News: Driver mobile app access",
    pathname: "driver-mobile-app-qikTruck",
    image: "partner-driver.jpeg",
    date: "21 May 2022",
    category: "Public news",
    copy: "QikTruck drivers registered in 2022 now have access to the driver mobile app. Drivers can get notifications and manage their bookings through the driver app.",
    links: [
      {
        title: "Download the Driver mobile app",
        url: "https://play.google.com/store/apps/details?id=za.co.qiktruck.driver",
      },
    ],
  },

  {
    heading: "News: Towing trucks on demand",
    pathname: "towing-trucks-on-demand",
    image: "tow-truck-news.png",
    date: "18 March 2022",
    category: "Public news",
    copy: "Qiktruck has added a fleet of tow trucks to help you whenever you need to move a vehicle. Simply calculate the costs and order a tow truck now.",
  },
];

export default data;
