import React from "react";
export default function LatLngToAddress({ lat, lng }) {
  return (
    <ul>
      <li>
        <p>
          <a href="mailto:support@qiktruck.co.za">support@qiktruck.co.za</a>
        </p>
      </li>
      <li>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="tel:+27663784460">
            Call center
          </a>
        </p>
      </li>
      <li>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/+27663784460"
          >
            WhatsApp
          </a>
        </p>
      </li>

      <li>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/+27799246991"
          >
            Vehicle Inspections
          </a>
        </p>
      </li>
      <li>Support Team: +27 69 683 6027 support@qiktruck.co.za </li>
      <li>
        <img src="https://media.qiktruck.co.za/team-3.jpeg" alt="qiktruck-team" width={300} />
        <img src="https://media.qiktruck.co.za/warehouse.jpeg" alt="qiktruck-team" width={300} />
        <img src="https://media.qiktruck.co.za/team-1.jpeg" alt="qiktruck-team" width={300} />
      </li>
    </ul>
  );
}
