import React, { Component } from "react";
import Faq from "react-faq-component";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import data from "./faqs";
import ContactUs from "../../components/common/ContactUs";
const styles = {
  // bgColor: 'white',
  titleTextColor: "#5b5b5b",
  rowTitleColor: "green",
  // rowContentColor: 'grey',
  // arrowColor: #e03c31,
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/about"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="hero-wrapper mb-4 about-us">
                  <h1 id="about">About Us</h1>
                  <div className="container mt-5">
                    <p>
                      QikTruck is a digital platform that connects people in
                      need of{" "}
                      <span className="highlight">transportation services</span>{" "}
                      with local drivers who have vehicles with{" "}
                      <span className="highlight">load capacity</span>. Our
                      platform allows communities to control their expenses and
                      manage their transportation needs by providing a fast and
                      readily available method to move goods of any size and
                      form from point A to point B.
                    </p>
                    <p>
                      We have created{" "}
                      <span className="highlight">user-friendly</span> software
                      for our customers and driver app, making it easy for
                      people to find and connect with local drivers in their
                      community. All vehicles on our platform are carefully
                      vetted and inspected to ensure they meet our high
                      standards for <span className="highlight">safety</span>{" "}
                      and <span className="highlight">reliability</span>. Our
                      drivers are trained to provide top-notch service, so you
                      can trust that your goods will be transported safely and
                      efficiently.
                    </p>
                    <p>
                      At QikTruck, we take{" "}
                      <span className="highlight">safety</span> and{" "}
                      <span className="highlight">insurance</span> very
                      seriously. All of our drivers are required to have
                      appropriate insurance coverage, and we provide additional
                      insurance coverage for goods in transit. We also have a
                      robust safety program in place, with regular training and
                      assessments to ensure that our drivers are always
                      operating at the highest level of safety.
                    </p>
                    <p>
                      In the unlikely event that an accident occurs, our
                      dedicated support team is available 24/7 to assist with
                      any issues and help resolve any problems. We are committed
                      to providing the best possible service to our customers
                      and the communities we serve, and we take every measure to
                      ensure that our platform is safe and reliable for everyone
                      involved.
                    </p>
                  </div>
                  <p>
                    Move anything anytime with our delivery trucks and drivers.
                    Furniture, Equipment, Construction Materials, Food,
                    Vehicles, Waste, Fuel and more! Review your instant online
                    quotation now. <br />
                    <br />
                    Simply calculate your costs and order a truck with a driver
                    to come collect and deliver your goods.
                    <br />
                  </p>
                  <br />
                  <h1>How QikTruck Works</h1>
                  <ol>
                    <li>
                      Customers in need of transportation services{" "}
                      <span className="highlight">
                        get a quotation on the QikTruck platform
                      </span>{" "}
                      and provide details about their transportation needs, such
                      as the type of goods to be transported and the pickup and
                      delivery locations.
                    </li>
                    <li>
                      Local drivers with vehicles that meet the load capacity
                      requirements can{" "}
                      <span className="highlight">sign up</span> to be a part of
                      the QikTruck network and submit their vehicle and
                      background information for review.
                    </li>
                    <li>
                      QikTruck reviews and{" "}
                      <span className="highlight">approves drivers</span> who
                      meet the required standards for safety and reliability.
                    </li>
                    <li>
                      When a customer posts a transportation request, the
                      QikTruck platform{" "}
                      <span className="highlight">
                        matches them with available drivers
                      </span>{" "}
                      in their area who have the appropriate vehicle and
                      capacity.
                    </li>
                    <li>
                      The customer and driver{" "}
                      <span className="highlight">
                        communicate through the QikTruck platform
                      </span>{" "}
                      to finalize the details of the transportation, including
                      the price and pickup/delivery times.
                    </li>
                    <li>
                      The driver{" "}
                      <span className="highlight">picks up the goods</span> from
                      the designated pickup location and transports them to the
                      delivery location as agreed upon with the customer.
                    </li>
                    <li>
                      The customer can{" "}
                      <span className="highlight">
                        track the progress of their transportation request
                      </span>{" "}
                      and communicate with the driver through the QikTruck
                      platform.
                    </li>
                    <li>
                      Upon delivery, the customer can{" "}
                      <span className="highlight">review the driver</span> and
                      rate their service on the QikTruck platform.
                    </li>
                    <li>
                      QikTruck processes the payment for the transportation
                      services and{" "}
                      <span className="highlight">
                        pays the driver accordingly
                      </span>
                      .
                    </li>
                  </ol>
                  <p className="quote">
                    "QikTruck makes it easy to find reliable and capable vehicles and drivers
                    for all of your transportation needs."
                  </p>
                  <br />
                  <Faq data={data} styles={styles} config={config} />
                  <br />
                  <br />
                  <h3 id="contact">Contact Us</h3>
                  <p>
                    If you have any questions and feebdack about this service,
                    You can contact us:
                  </p>
                  <ContactUs />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
