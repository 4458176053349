import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={"What is QikTruck?"}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "QikTruck is a platform that connects small trucking companies with shippers/movers in need of transportation services."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/investors"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center what-is-qiktruck">
              <Col>
                <h1>
                  QikTruck: <span className="orange">Moving goods</span>
                  <span className="green">on-demand</span>
                </h1>
                <br />
                <br />
                <h2>What is QikTruck?</h2>
                <ul>
                  <li>
                    <p>
                      At QikTruck, we are proud to be running a fully remote
                      on-demand truck and driver hire company based in Randburg.
                      Our unique and innovative service allows customers to
                      easily and quickly rent trucks and drivers online, without
                      the need for in-person interactions.
                    </p>

                    <p>
                      One of the key advantages of our fully remote model is the
                      convenience it offers to our customers. Rather than having
                      to visit a physical location to rent a truck, they can
                      simply visit our website or app and book a truck and
                      driver in just a few clicks. This saves time and effort,
                      and allows our customers to focus on other aspects of
                      their business.
                    </p>
                    <p>
                      In addition to our customer-facing operations, we have
                      also implemented a fully remote model for our internal
                      operations. This means that all of our departments –
                      including sales, marketing, finance, and HR – are able to
                      work remotely, using a variety of online tools and
                      technologies to collaborate and communicate.
                    </p>

                    <p>
                      This fully remote model has numerous benefits for our
                      company. It allows us to attract top talent from a wider
                      pool of candidates, as we are not limited by geographical
                      location. It also allows our team members to have greater
                      flexibility and control over their work environment, which
                      can lead to increased productivity and job satisfaction.
                    </p>
                    <p>
                      Another advantage of our fully remote model is the
                      flexibility it provides to our customers. With QikTruck,
                      they can choose from a wide range of trucks and drivers,
                      and can easily adjust their rental plans to suit their
                      needs. This means that they can rent a truck and driver
                      for as short or as long a period as they need, and can
                      make changes to their rental plans at any time.
                    </p>

                    <p>
                      Our fully remote model also enables us to offer
                      competitive pricing to our customers. By eliminating the
                      need for physical locations and a large in-house staff, we
                      are able to keep our overhead costs low and pass those
                      savings on to our customers. This makes our truck and
                      driver hire services more affordable for businesses of all
                      sizes.
                    </p>

                    <p>
                      In conclusion, QikTruck is leading the way in the truck
                      and driver hire industry by offering a fully remote,
                      on-demand service that is convenient, flexible, and
                      affordable. Our innovative approach is helping businesses
                      of all sizes streamline their operations and increase
                      efficiency, and is setting a new standard for the industry
                      as a whole. So, if you are in need of a truck and driver,
                      we encourage you to give QikTruck a try – we are confident
                      that you will be satisfied with the service we provide.
                    </p>
                  </li>
                </ul>

                <p>
                  Please contact: <b> support@qiktruck.co.za </b>for more
                  information.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
