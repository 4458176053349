import axios from "axios";
import { Component, default as React } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import constants from "../../Constants";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      success: false,
      apierror: "",
      clienttypes: [],
    };
  }
  render() {
    const { clienttypes } = this.state;

    if (clienttypes?.length === 0)
      axios.get(baseURL + "/client-types").then((repos) => {
        const clienttypesdata = repos.data;
        this.setState({
          clienttypes: clienttypesdata?.clients,
        });
      });
    return (
      <React.Fragment>
        <SEO
          title={"Refer a friend and earn"}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Earn R100 when a friend pays for their delivery on QikTruck."
          }
          image={
            "https://media.qiktruck.co.za/refer-a-friend-andberewarded.png"
          }
          pathname={"https://qiktruck.co.za/promo"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <br />
                <img
                  src="https://media.qiktruck.co.za/refer-a-friend-andberewarded.png"
                  alt="qiktruck-deliveries"
                />
                <br />
                <br />
                <br />
                <p>
                  <b> 1. </b>Create a referal code on the{" "}
                  <a
                    href="https://promo.qiktruck.co.za"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PROMO WEBSITE HERE
                  </a>
                </p>
                <p>
                  <b> 2. </b>Share the promo code with friends and your
                  customers.
                </p>
                <p>
                  <b> 3. </b>Earn up to R100 when they pay for their delivery on
                  QikTruck.
                </p>
                <p>
                  <b> 4. </b>They get a 7% discount too!
                </p>
                <br />
                <h4>Do you work for the following companies?</h4> <br />
                <hr />
                <div className="clients-list">
                  {clienttypes?.map((c, index) => {
                    return (
                      <div
                        key={index}
                        className="client-type"
                        style={{
                          backgroundImage:
                            "url(https://media.qiktruck.co.za/" +
                            c.name +
                            ".png)",
                        }}
                      ></div>
                    );
                  })}
                </div>
                <hr />
                <br /> <br />
                <h1>Earn up to R100 when your promo code is used.</h1>
                <br />
                <p>
                  <b> 1. </b>Create a referal code on the{" "}
                  <a
                    href="https://promo.qiktruck.co.za"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PROMO WEBSITE HERE
                  </a>
                </p>
                <p>
                  <b> 2. </b>Share the promo code with friends and your
                  customers.
                </p>
                <p>
                  <b> 3. </b>Earn up to R100 when they pay for their delivery on
                  QikTruck.
                </p>
                <p>
                  <b> 4. </b>They get a 7% discount too!
                </p>
                <br />
                <h4>Your company is not on the list?</h4>
                <a
                  className="mb-4"
                  href="mailto:support@qiktruck.co.za"
                  color="normal"
                  size="sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get help via Email
                  <i className="mdi mdi-email ml-2"></i>
                </a>
                <br />
                <a
                  href="https://wa.me/+27663784460"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-4"
                  color="normal"
                  size="sm"
                >
                  Get help via Whatsapp
                  <i className="mdi mdi-whatsapp ml-2"></i>
                </a>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
