import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/investors"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>
                  QikTruck: <span className="orange">Insurance</span>
                  <span className="green">on-demand</span>
                </h1>
                <br />
                <br />
                <h2>Our commitment:</h2>
                <ul>
                  <li>
                    At QikTruck, we recognize the paramount importance of
                    securing comprehensive commercial vehicle insurance for our
                    fleet. This commitment to protection serves as the backbone
                    of our operations, ensuring that we are equipped to navigate
                    the unpredictable nature of the logistics and transportation
                    industry with confidence and resilience.
                  </li>
                </ul>
                <h4>Commercial auto insurance:</h4>
                <ul>
                  <li>
                    Commercial auto insurance is indispensable to our business
                    model, safeguarding our fleet against a wide array of
                    potential risks, including accidents, theft, and other
                    unforeseen damages. This coverage is not merely a regulatory
                    requirement; it is a strategic investment in the continuity
                    and stability of our services. It ensures that, in the event
                    of any mishap, the financial implications are managed
                    effectively, thereby minimizing disruptions to our
                    operations and services
                  </li>
                </ul>
                <h4>Goods in Transit (GIT) insurance:</h4>
                <ul>
                  <li>
                    Goods in Transit (GIT) insurance is a specialized type of
                    coverage designed to protect the goods being transported in
                    the course of business against loss, damage, or theft. This
                    insurance is crucial for businesses involved in moving
                    goods, such as courier services, haulage companies, and
                    removal services. GIT insurance covers the goods while they
                    are being moved from one location to another, whether within
                    the UK, Europe, or internationally, depending on the policy​
                    At QikTruck, we understand the critical importance of
                    safeguarding the goods we transport for our clients. That's
                    why we prioritize Goods in Transit (GIT) insurance, an
                    essential component of our risk management strategy. GIT
                    insurance is designed to protect the goods while they are
                    being moved from one location to another, offering coverage
                    against loss, damage, or theft​​​​.
                  </li>
                </ul>

                <h5>Why GIT Insurance is Vital for QikTruck:</h5>

                <ul>
                  <li>
                    Comprehensive Protection: Our GIT insurance covers a wide
                    range of scenarios, including theft, accidents, and damage
                    during loading or unloading. This comprehensive protection
                    is crucial for a logistics company like ours, encountering
                    diverse risks on a daily basis​​​​.{" "}
                  </li>
                  <li>
                    Financial Security: GIT insurance provides QikTruck with a
                    financial safety net, ensuring that we're not out of pocket
                    should the goods we're transporting be damaged or lost. This
                    protection is vital for our financial stability and business
                    continuity​​​​.
                  </li>
                  <li>
                    Building Trust with Clients: By securing GIT insurance,
                    QikTruck demonstrates a commitment to safeguarding our
                    clients' goods. This builds trust and reassures our clients
                    that their valuable cargo is in safe hands, enhancing our
                    reputation as a reliable logistics partner​​​​.{" "}
                  </li>
                  <li>
                    Customized Coverage: Understanding that each transportation
                    job has its unique risks, our GIT insurance policies are
                    tailored to fit the specific needs of our operations and the
                    goods we transport. This flexibility allows us to provide
                    the best possible protection for every consignment​​​​.{" "}
                  </li>
                  <li>
                    Meeting Contractual Obligations: Many of our contracts
                    require GIT insurance. By maintaining comprehensive GIT
                    coverage, QikTruck ensures compliance with these contractual
                    obligations, aligning with industry standards and client
                    expectations​​​​.{" "}
                  </li>
                </ul>

                <h5>Our Commitment:</h5>

                <ul>
                  <li>
                    {" "}
                    QikTruck's commitment to providing reliable and secure
                    logistics services is underpinned by our approach to GIT
                    insurance. We go above and beyond to ensure that our
                    insurance coverage meets the highest industry standards,
                    offering peace of mind to our clients and protecting our
                    business against potential financial losses.{" "}
                  </li>
                  <li>
                    As the logistics industry continues to evolve, so does the
                    importance of robust GIT insurance.{" "}
                  </li>
                  <li>
                    QikTruck remains at the forefront of this evolution,
                    ensuring that our policies reflect the changing needs of our
                    business and the clients we serve. Our dedication to
                    securing comprehensive GIT insurance is just one of the many
                    ways we strive to deliver excellence in all aspects of our
                    services.
                  </li>
                </ul>
                <p>
                  Please contact: <b> support@qiktruck.co.za </b>for more
                  information.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
