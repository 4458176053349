import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/investors"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>Invitation to Partner</h1>
                <p>Dear Real Estate Companies,</p>
                <p>
                  <span role="img" aria-label="emoji">
                    {" "}
                    🚚🏡📦
                  </span>{" "}
                  Are you tired of the stress and hassle of coordinating moving
                  services for your customers? Let us help! 🙌
                </p>
                <p>
                  At QikTruck, we specialize in making the moving process as
                  smooth and seamless as possible. We offer discounted or
                  exclusive transportation services to customers who are
                  relocating, including discounted rates on our moving truck
                  rentals and professional moving services, as well as access to
                  our network of trusted partners for additional services such
                  as packing and storage.
                </p>
                <p>
                  <span role="img" aria-label="emoji">
                    {" "}
                    🤝
                  </span>{" "}
                  We believe that partnering with a reputable real estate
                  company like yours can bring significant value to both of our
                  businesses. By jointly marketing and promoting our moving
                  services to potential customers, we can create a convenient
                  one-stop shop for all their relocation needs. And by exposing
                  your customers to our services, we can potentially attract new
                  customers to both of our businesses. It's a win-win situation!
                  <span role="img" aria-label="emoji">
                    {" "}
                    🤝
                  </span>
                </p>
                <h2>Key Benefits</h2>
                <br />
                <img
                  src="https://media.qiktruck.co.za/promo-realeastate2.png"
                  alt="promo-realeastate-min."
                  style={{ maxWidth: 360 }}
                />

                <br />
                <br />

                <ul>
                  <li>
                    <span role="img" aria-label="emoji">
                      {" "}
                      💸
                    </span>{" "}
                    Discounted or exclusive transportation services for your
                    customers who are moving
                  </li>
                  <li>
                    <span role="img" aria-label="emoji">
                      🏠
                    </span>{" "}
                    Convenient, one-stop shop for all moving needs
                  </li>
                  <li>
                    <span role="img" aria-label="emoji">
                      📣
                    </span>{" "}
                    Joint marketing and promotion of moving services to
                    potential customers
                  </li>
                  <li>
                    <span role="img" aria-label="emoji">
                      🌟
                    </span>{" "}
                    Potential for attracting new customers to both companies
                  </li>
                </ul>
                <p>
                  But that's not all - we see potential for even more creative
                  ways to merge our services and offer unique, value-added
                  experiences to our customers. For example, we could offer
                  special moving packages that include not just transportation
                  and packing services, but also home cleaning, repair, or
                  staging services. Or, we could create a loyalty program that
                  rewards customers for using both our transportation and real
                  estate services, with perks such as discounts or priority
                  service.
                </p>
                <p>
                  <span role="img" aria-label="emoji">
                    {" "}
                    💡
                  </span>{" "}
                  We are open to discussing these and other ideas further, and
                  finding a way to work together that aligns with the goals and
                  needs of both our companies.{" "}
                  <span role="img" aria-label="emoji">
                    💡
                  </span>
                </p>
                <h2>Why Now?</h2>
                <p>
                  The population of young Africans is growing rapidly, and with
                  it the demand for high-quality moving services and real estate
                  services. By partnering with us now, you can position your
                  company to capitalize on this trend and meet the needs of a
                  rapidly expanding market.
                </p>
                <p>
                  If you are interested in partnering with us to make the moving
                  process easier and more convenient for your customers, please
                  don't hesitate to reach out. We look forward to hearing from
                  you!
                </p>
                <h2>What you get from us</h2>
                <ul>
                  <li>
                    Heavy discounts.
                    <p>
                      As we regularly work together, we will make it easier for
                      our customers to move their goods through discounted
                      services.
                    </p>
                  </li>
                  <li>
                    Free marketing content.
                    <p>
                      Our media team will get you up and running with all the
                      photos and videos for the partnership covered by QikTruck.
                    </p>
                  </li>
                  <li>
                    Previous customers.
                    <p>
                      Our customers are always on the move and they look for the
                      best next home or office first.
                    </p>
                  </li>
                </ul>
                <br />
                <img
                  src="https://media.qiktruck.co.za/promo-realeastate.png"
                  alt="promo-realeastate-min."
                  style={{ maxWidth: 360 }}
                />

                <br />
                <br />
                <p>
                  Contact: <strong>support@qiktruck.co.za</strong> for more
                  information.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
