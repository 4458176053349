import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Input } from "reactstrap";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyD2BbvjWvF73TNFX3c0BTBlN6kmNc0Rheg";
const PlacesAutocomplete = ({
  type,
  className,
  id,
  placeholder,
  required,
  onPlaceSelected,
  style,
}) => {
  const { ref } = usePlacesWidget({
    options: {
      types: [],
    },
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected,
  });
  const uniqueName = `address-input-${Date.now()}`;
  return (
    <Input
      style={style}
      innerRef={ref}
      type={type}
      className={className}
      id={id}
      placeholder={placeholder}
      required={required}
      autoComplete="new-password"
      onFocus={(event) => {
        event.target.setAttribute("autocomplete", "new-password");
        event.target.setAttribute("autocomplete", "chrome-off"); // or 'new-password' if 'chrome-off' doesn't work
        event.target.setAttribute("name", uniqueName);
      }}
    />
  );
};

export default PlacesAutocomplete;
