import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Row } from "reactstrap";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "../landing/section";
class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "services", navheading: "Services" },
        { id: 3, idnm: "features", navheading: "Features" },
        { id: 4, idnm: "clients", navheading: "Clients" },
        { id: 6, idnm: "pricing", navheading: "Plans" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          seo={
            <SEO
              title={
                "Bakkie and Driver Hire - Reliable Transportation Services in South Africa "
              }
              titleTemplate={"QikTruck"}
              titleSeparator={`-`}
              description={
                "Looking for affordable bakkie and driver hire in South Africa? QikTruck offers reliable services for moving, deliveries, and more. Our 1.5 ton bakkies have automatic transmission and air con. Book online today!"
              }
              image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
              pathname={"https://qiktruck.co.za/bakkie-hire"}
              twitterUsername={"qiktrucksa"}
            />
          }
          heading={
            <h2 className="main-headline">
              On-demand bakkie and driver hire
            </h2>
          }
          services={
            <Row>
              <Col lg={6} md={6} className="other-services">
                <h3 className="font-18">Bakkie Hire Services</h3>
                <p>
                  <i className="mdi mdi-star"></i>Door to door goods deliveries.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Purchases from Retail stores,
                  Online stores.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Manufacturers, Dispatch
                  centers, Suppliers.
                </p>
              </Col>
              <Col lg={6} md={6} className="other-services">
                <h3 className="font-18">Delivery services</h3>
                <p>
                  <i className="mdi mdi-star"></i> On demand - Same day
                  deliveries guaranteed.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Need helpers to carry your
                  goods?
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Drivers available within 30
                  minutes of your booking.
                </p>
              </Col>
            </Row>
          }
          rows={
            <Row>
              <Col lg={6} md={6} className="other-services">
                <h3 className="font-18">New locations</h3>
                <a href="/bakkie-hire-randburg">randburg</a>
                <br />
                <a href="/bakkie-hire-pretoria">pretoria</a>
                <br />
                <a href="/bakkie-hire-sandton">sandton</a>
                <br />
                <a href="/bakkie-hire-roodeport">roodeport</a>
                <br />
              </Col>
              <Col lg={6} md={6} className="other-services">
                <h3 className="font-18">More Locations</h3>
                <a href="/bakkie-hire-soweto">soweto</a>
                <br />
                <a href="/bakkie-hire-gauteng">gauteng</a>
                <br />
                <a href="/bakkie-hire-midrand">midrand</a>
                <br />
                <a href="/bakkie-hire-centurion">centurion</a>
                <br />
              </Col>
            </Row>
          }
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
