import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "Earn more with QikTruck Boost | QikTruck | On Demand Truck and Driver Hire | Transportation Service"
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "QikTruck Boost is a subscription service tailored for drivers of different truck types. By subscribing to Boost, drivers gain exclusive access to premium job quotations, the power to negotiate prices, and the flexibility to pass on jobs that don't suit their schedule or preferences."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"/boost"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div>
                  <h1>QikTruck Boost:</h1>
                  <br />
                  <h3> A New Way to Grow Your Transportation Business</h3>
                  <p>
                    At QikTruck, we understand the unique challenges and
                    opportunities faced by drivers in the logistics industry.
                    That's why we've developed QikTruck Boost, a groundbreaking
                    service designed to help drivers maximize their earnings,
                    grow their business, and maintain greater control over their
                    work.
                  </p>
                  <br />
                  <h2>What is QikTruck Boost?</h2>
                  <p>
                    QikTruck Boost is a subscription service tailored for
                    drivers of different truck types. By subscribing to Boost,
                    drivers gain exclusive access to premium job quotations, the
                    power to negotiate prices, and the flexibility to pass on
                    jobs that don't suit their schedule or preferences.
                  </p>
                  <br />

                  <h2>How Does It Work?</h2>
                  <p>
                    Once you subscribe to QikTruck Boost, you'll start receiving
                    premium job quotations. You can review these quotations at
                    your leisure, and if a job catches your eye, you have the
                    power to negotiate the price. This ensures that you're
                    always satisfied with the earnings potential of a job before
                    you commit to it.
                  </p>
                  <p>
                    One of the standout features of Boost is the ability to pass
                    on a job. We understand that not every job is the right fit
                    for every driver. Whether it's a scheduling conflict, a
                    distance issue, or any other reason, Boost gives you the
                    power to say no. When you pass on a job, you can provide a
                    reason, and the system will automatically reassign the
                    quotation to another driver.
                  </p>
                  <br />

                  <h2>How Much Does It Cost?</h2>
                  <p>
                    QikTruck Boost offers different subscription rates for
                    different truck types, providing flexibility and value for
                    all drivers. The monthly fees for each truck type are as
                    follows:
                  </p>
                  <ul>
                    <li>
                      Minivan : : : : <b>R350</b>
                    </li>
                    <li>
                      1ton : : : : <b>R450</b>
                    </li>
                    <li>
                      1.5ton : : : : <b>R550</b>
                    </li>
                    <li>
                      4ton : : : : <b>R950</b>
                    </li>
                    <li>
                      8ton : : : : <b>R1200</b>
                    </li>
                  </ul>
                  <p>
                    These fees provide drivers with an affordable way to gain
                    access to a multitude of benefits that can help them grow
                    their business and increase their earnings.
                  </p>

                  <h2>Packages</h2>
                  <p>
                    QikTruck Boost is more than just a subscription service;
                    it's a tool that empowers drivers to take control of their
                    work and drive their business forward. With the ability to
                    negotiate prices, pass on unsuitable jobs, and access
                    premium job quotations, Boost offers drivers an
                    unprecedented level of control and earning potential.
                  </p>
                  <p>
                    At QikTruck, we're committed to providing services that
                    address the unique needs of drivers in the logistics
                    industry. With Boost, we're confident that we're doing just
                    that.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center" id="EasyDrivePackage">
              <Col lg={4} sm={12} md={4} className="driver-packages EasyDrive">
                <h3>EasyDrive</h3>
                <p>
                  <i className="mdi mdi-star"></i>= 20% - 40% as service fee including VAT depending on vehicle size and contract requirements
                </p>
                <p>
                  <i className="mdi mdi-star"></i>All new vehicles start here.
                  No Monthly Fees.
                </p>
                <h4>
                  R0 <span>P/M</span>
                  <i className="mdi mdi-thumb-up ml-3">default</i>
                </h4>
              </Col>
              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages QikDrive"
                id="minivanpackage"
              >
                <h3>
                  Minivan <span className="span">BOOST</span>
                </h3>
                <div className="img-holder">
                  <img
                    src={this.getImage("minivanopen")}
                    alt="minivan"
                    width={100}
                  />
                </div>

                <a href="/boost#pay">
                  <h4>
                    R350 <span>P/M</span>
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages QikDrive"
                id="QikDrivePackage"
              >
                <h3>
                  1 ton <span className="span">BOOST</span>
                </h3>
                <div className="img-holder">
                  <img
                    src={this.getImage("1tonopen")}
                    alt="minivan"
                    width={100}
                  />
                </div>
                <a href="/boost#pay">
                  <h4>
                    R450 <span>P/M</span>
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages QikDrive"
                id="QikDrivePackage"
              >
                <h3>
                  1.5ton <span className="span">BOOST</span>
                </h3>
                <div className="img-holder">
                  <img
                    src={this.getImage("1.5tonopen")}
                    alt="minivan"
                    width={100}
                  />
                </div>
                <a href="/boost#pay">
                  <h4>
                    R550 <span>P/M</span>
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages QikDrive"
                id="QikDrivePackage"
              >
                <h3>
                  4ton <span className="span">BOOST</span>
                </h3>
                <div className="img-holder">
                  <img src={this.getImage("4tonopen")} alt="4ton" width={100} />
                </div>
                <a href="/boost#pay">
                  <h4>
                    R950 <span>P/M</span>
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
              <Col
                lg={4}
                sm={12}
                md={4}
                className="driver-packages QikDrive"
                id="QikDrivePackage"
              >
                <h3>
                  8ton <span className="span">BOOST</span>
                </h3>
                <div className="img-holder">
                  <img
                    src={this.getImage("8tonopen")}
                    alt="minivan"
                    width={100}
                  />
                </div>
                <a href="/boost#pay">
                  <h4>
                    R1200 <span>P/M</span>
                    <i className="mdi mdi-arrow-right ml-3"></i>
                  </h4>
                </a>
              </Col>
            </Row>
            <Row className="pkgs">
              <Col id="pay" lg={12} sm={12} md={12}>
                <br />
                <br />
                <h3>
                  <i className="mdi mdi-bank"></i> Boosting your vehicle is
                  easy.
                </h3>
                <br />
                <br />
              </Col>
              <Col lg={4} sm={12} md={4}>
                <p>
                  <span>1</span> Deposit your monthly fee into our bank account.
                </p>
              </Col>
              <Col lg={4} sm={12} md={4}>
                <p>
                  <span>2</span> Bank reference :> <i>BOOST-platenumber</i>
                </p>
              </Col>
              <Col lg={4} sm={12} md={4}>
                <p>
                  <span>3</span> Notify us via Email or WhatsApp.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <br />
                  <i className="mdi mdi-note"></i> Remember... QikTruck will
                  always offer a Free version for our transporters to get jobs
                  on QikTruck EasyDrive.
                  <br />
                  <i className="mdi mdi-note"></i> All new partner drivers
                  automatically join the QikTruck Free program when documents
                  are approved.
                  <br />
                  <i className="mdi mdi-note"></i> You can Boost your profile if
                  there are no jobs in your area.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>If your vehicle is already
                  approved. Boost your profile.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
