import axios from "axios";
import React, { Component } from "react";
import CurrencyFormat from "react-currency-format";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import constants from "../../Constants";

const baseURL = constants.baseURL;

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typesOfVehicles: [],
    };
  }
  showPricing(vehicle) {
    return (
      <div>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/pricing"}
          twitterUsername={"qiktrucksa"}
        />
        <div className="price-listing">
          Price per KM:{" "}
          <span>
            <CurrencyFormat
              value={vehicle.pricePerMeterCents * 10}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"R"}
              decimalScale={2}
            />
          </span>
        </div>
        <div className="price-listing">
          Base Fee:{" "}
          <span>
            {" "}
            <CurrencyFormat
              value={vehicle.handlingFeeCents / 100}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"R"}
              decimalScale={2}
            />
          </span>
        </div>
        {/* <div className="price-listing">
          Loading time Average:{" "}
          <span>{vehicle.loadingTimeSeconds / 60} minutes</span>
        </div> */}
        <div className="price-listing">
          Size:± <span>{vehicle.tonnes} ton</span>
        </div>
      </div>
    );
  }
  render() {
    if (this.state.typesOfVehicles.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const typesOfVehicles = repos.data?.vehicles;
        this.setState({ typesOfVehicles });
      });
    return (
      <React.Fragment>
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="hero-wrapper  mb-4">
                  <h1
                    style={{
                      fontWeight: "normal",
                      fontSize: 25,
                      paddingTop: 20,
                    }}
                  >
                    <b>Affordable</b> and competitive pricing for{" "}
                    <b>Goods Transportation </b>
                  </h1>
                  <hr />
                  <p>
                    All prices include driver and fuel costs. To make it easy
                    for you to calculate your transportation investment.
                  </p>
                  <a className="prices-cta" href="/#quotestart">
                    Get a Quotation
                  </a>
                  <a className="prices-cta" href="/#quotestart">
                    Enter your pick up and drop off locations
                  </a>
                  <br />
                  <h4>Prices by vehicle type</h4>
                  <h6>Vehicle and driver hire prices + fuel</h6>
                  <Row>
                    {this.state.typesOfVehicles
                      .filter((v) => v.display === true)
                      ?.map((vehicle) => {
                        const image = `https://media.qiktruck.co.za/${vehicle.type}.png`;
                        return (
                          <Col
                            key={vehicle.type}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={6}
                            xl={4}
                            className={"grey-border-bottom prices-truck "}
                            onClick={() =>
                              this.setState({
                                truckType: vehicle.type,
                              })
                            }
                          >
                            <a
                              href={
                                "/?trucktype=" + vehicle.type + "#addresses"
                              }
                            >
                              <div
                                className={
                                  this.state.truckType === vehicle.type
                                    ? "selected-truck"
                                    : ""
                                }
                              ></div>
                              <img
                                src={image}
                                alt=""
                                className={
                                  "menu-truck " +
                                  (this.state.truckType === vehicle.type
                                    ? "menu-truck-selected"
                                    : "")
                                }
                                height="20"
                              />
                              <i
                                className={
                                  "mdi mdi-check-circle ml-2 " +
                                  (this.state.truckType === vehicle.type
                                    ? "checked-truck"
                                    : "hide")
                                }
                              ></i>
                              <p className="menu-truck-tip">
                                {vehicle.tip.shortform}
                              </p>
                              {this.showPricing(vehicle)}
                            </a>
                          </Col>
                        );
                      })}
                  </Row>
                  <br />
                  <p>
                    We ensure goods can fit in a truck before starting any job
                    through inspection.
                  </p>
                  <hr />

                  <h3 id="howitworks">How it works</h3>
                  <ul>
                    <li>
                      <p>
                        <a
                          href="/quote"
                          style={{ borderBottom: "solid 1px blue" }}
                        >
                          Get an instant free quotation.
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>Accept quotation, pay now or pay later.</p>
                    </li>
                    <li>
                      <p>
                        Qiktruck notifies a Driver about the order and price.
                      </p>
                    </li>
                    <li>
                      <p>
                        The driver calls the customer to verify details of the
                        order.
                      </p>
                    </li>
                    <li>
                      <p>
                        A driver will come to collect and deliver your goods.
                      </p>
                    </li>
                    <li>
                      <p>Pay QikTruck as the job starts or at the end.</p>
                    </li>
                    <li>
                      <p>QikTruck pays the Driver/Partner truck.</p>
                    </li>
                    <li>
                      <p>Business clients/partners can pay via credit.</p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
