import axios from "axios";
import React, { Component } from "react";
import { Col, Row, NavLink } from "reactstrap";
import facebook from "../../assets/images/facebook-bg-min.png";
import linkedin from "../../assets/images/linkedin-bg-min.png";
import twitter from "../../assets/images/twitter-bg-min.png";

import whatsapp from "../../assets/images/whatsapp-business-bg-min.png";
import constants from "../../Constants";
import NewsLetter from "../NewsLetter";
import FooterLinks from "./footer-links";

const baseURL = constants.baseURL;
class Footer extends Component {
  state = {
    stats: {
      q: "-",
      o: "-",
      v: "-",
    },
  };
  componentDidMount() {
    axios.get(baseURL + "/stats").then((repos) => {
      const stats = repos.data;
      this.setState({ stats });
    });
  }
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="footer-container">
            <div className="get-a-quotation-now">
              <NavLink href="/quote#quotestart">
                Get a quotation in 30 seconds
              </NavLink>
            </div>
            <Row>
              <Col>
                <p>
                  <span className="usage-stats">
                    {this.state.stats.v}{" "}
                    <span> vehicles available on demand.</span>
                    <br />
                    {this.state.stats.v2}{" "}
                    <span> vehicles available on quotations.</span>
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="footer-sections">
                  <NavLink href="/quote">
                    <img
                      src="https://media.qiktruck.co.za/1.png"
                      className="banner-image"
                      alt="empowering-construction"
                    />
                  </NavLink>
                  <NavLink href="/quote">
                    <img
                      src="https://media.qiktruck.co.za/2.png"
                      className="banner-image"
                      alt="empowering-deliveries"
                    />
                  </NavLink>
                  <NavLink href="/quote">
                    <img
                      src="https://media.qiktruck.co.za/3.png"
                      className="banner-image"
                      alt="empowering-farmers"
                    />
                  </NavLink>
                  <NavLink href="/quote">
                    <img
                      src="https://media.qiktruck.co.za/4.png"
                      className="banner-image"
                      alt="empowering-movers"
                    />
                  </NavLink>
                  <NavLink href="/quote">
                    <img
                      src="https://media.qiktruck.co.za/5.png"
                      className="banner-image"
                      alt="empowering-solar"
                    />
                  </NavLink>
                  <NavLink href="/quote">
                    <img
                      src="https://media.qiktruck.co.za/6.png"
                      className="banner-image"
                      alt="empowering-teams"
                    />
                  </NavLink>
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <div>
                  <NavLink
                    href="/regular-deliveries?src=footer"
                    className="footer-action-button"
                  >
                    <p>
                      <span>Book Regular Deliveries</span> >
                    </p>
                  </NavLink>
                  <NavLink href="/furniture-delivery-services?src=footer">
                    <p>Book Once off Delivery > </p>
                  </NavLink>
                  <NavLink href="/bakkie-hire?src=footer">
                    <p>Bakkie Hire > </p>
                  </NavLink>
                  <NavLink href="/truck-hire?src=footer">
                    <p>Truck Hire > </p>
                  </NavLink>
                  <NavLink href="/vacancies">
                    <p>Jobs / Vacancies </p>
                  </NavLink>{" "}
                  <NavLink href="/contact#contact">
                    <p>Contact Us > </p>
                  </NavLink>
                  <NavLink href="/terms">
                    <p>Terms and Conditions > </p>
                  </NavLink>
                  <NavLink href="/privacy">
                    <p>Privacy Policy > </p>
                  </NavLink>{" "}
                </div>
              </Col>
              <Col lg={4} sm={12}>
                <div>
                  <NavLink
                    href="/partner-drivers?dutq=footer"
                    className="footer-action-button"
                  >
                    <p>
                      <span>Partner Drivers</span> >
                    </p>
                  </NavLink>
                  {/* <NavLink href="/driver-packages">
                    <p>Partner Driver Packages > </p>
                  </NavLink> */}
                  <NavLink href="/driver-guide">
                    <p>Driver Guide > </p>
                  </NavLink>
                  <NavLink href="/howitworks#howitworks">
                    <p>How it Works > </p>
                  </NavLink>

                  <NavLink href="/boost">
                    <p>
                      <span>Driver Boost > </span>
                    </p>
                  </NavLink>
                  <NavLink href="/social">
                    <p>
                      <span>Social Media Content Promotions > </span>
                    </p>
                  </NavLink>
                  <NavLink href="/vehicle-hire-price-survey?src=footer">
                    <p>Vehicle Hire Price Survey > </p>
                  </NavLink>
                  <NavLink href="/pricing">
                    <p>Pricing/Fees > </p>
                  </NavLink>

                  <NavLink href="/about#about">
                    <p>About Us > </p>
                  </NavLink>
                </div>
              </Col>
              <Col lg={4} sm={12}>
                <div>
                  <NavLink
                    href="/regular-deliveries"
                    className="footer-action-button"
                  >
                    <p>
                      <span role="img" aria-label="business">
                        Business Solutions
                      </span>{" "}
                      >
                    </p>
                  </NavLink>
                  <NavLink href="/developers">
                    <p>Developer API > </p>
                  </NavLink>
                  <NavLink href="/transportation-network">
                    <p>Transportation Network > </p>
                  </NavLink>
                  <NavLink href="/investors">
                    <p>Investor Relations > </p>
                  </NavLink>
                  <NavLink href="/articles">
                    <p>In The News > </p>
                  </NavLink>{" "}
                  <NavLink href="/ads">
                    <p>
                      <span>Vehicle Frame Advertising > </span>
                    </p>
                  </NavLink>
                  <NavLink href="/realestate-partnerships">
                    <p>Real Estate Partnerships > </p>
                  </NavLink>{" "}
                  <NavLink href="/faqs">
                    <p>Frequently Asked Questions > </p>
                  </NavLink>
                  <div className="social-links">
                    <NavLink
                      href="https://wa.me/+27663784460"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-social-logo"
                    >
                      <img
                        src={whatsapp}
                        alt="footer-social-logo"
                        className="footer-social-logo-img"
                      />
                    </NavLink>
                    <NavLink
                      href="https://twitter.com/QikTruckSA"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-social-logo"
                    >
                      <img
                        src={twitter}
                        alt="footer-social-logo"
                        className="footer-social-logo-img"
                      />
                    </NavLink>
                    <NavLink
                      href="https://www.facebook.com/QikTruck"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-social-logo"
                    >
                      <img
                        src={facebook}
                        alt="footer-social-logo"
                        className="footer-social-logo-img"
                      />
                    </NavLink>
                    <NavLink
                      href="https://www.linkedin.com/company/qiktruck/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-social-logo"
                    >
                      <img
                        src={linkedin}
                        alt="footer-social-logo"
                        className="footer-social-logo-img"
                      />
                    </NavLink>
                    <a
                      href="tel:+27663784460"
                      className="footer-call-button"
                    >
                      <i className="mdi mdi-phone"></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <NewsLetter />
          </div>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
