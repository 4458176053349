import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Comprehensive Goods transport Services in Paarl"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/goods-transport-paarl"}
          pageHeading={"Comprehensive Goods transport Services in Paarl"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get comprehensive Goods transport services in Paarl with QikTruck. Our wide range of delivery options and customizable solutions make us the go-to choice for all of your transportation and delivery needs. Sign up now and start enjoying the convenience and versatility of our services."}
          pageDescription2={"Need comprehensive Goods transport services in Paarl? QikTruck is here to help! Our wide range of delivery options and customizable solutions make us the go-to choice for all of your transportation and delivery needs. From package delivery to furniture transportation, we have the solutions you need to meet your specific requirements. Sign up now and start enjoying the convenience and versatility of our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
