import landing from "./pages/landing";
import truckHire from "./pages/truck-hire";
import regularDeliveries from "./pages/regular-deliveries";
import truckHireThanks from "./pages/truck-hire-thanks";
import bakkieHire from "./pages/bakkie-hire";
import vehicleHirePriceSurvey from "./pages/vehicle-hire-price-survey";
import deliveriesForYourHome from "./pages/furniture-delivery-services";
import about from "./pages/about";
import goodslist from "./pages/goods-list";
import faqs from "./pages/faqs";
import vacancies from "./pages/vacancies";
import marketplace from "./pages/marketplace";
import investors from "./pages/investors";
import deliverwithprice from "./pages/deliver-with-price";
import f404 from "./pages/404";
import promo from "./pages/promo";
import social from "./pages/social";
import drivertraining from "./pages/driver-training";
import developers from "./pages/developers";
import welo from "./pages/welo";
import insurance from "./pages/insurance";
import transportationnetwork from "./pages/transportation-network";
import unsubscribe from "./pages/unsubscribe";
import remoteworkatqiktruck from "./pages/remote-work-at-qiktruck";
import ads from "./pages/ads";
import driverpackages from "./pages/driver-packages";
import boost from "./pages/boost";
import articles from "./pages/articles";
import checkoutloadcapacity from "./pages/checkout-load-capacity";
import footer from "./components/Footer/footer";
import pricing from "./pages/pricing";
import vehicleRegistration from "./pages/partner-drivers";
import supplierregistration from "./pages/supplier-registration";
import trackorder from "./pages/track-order";
import orders from "./pages/orders";
import realestatepartnerships from "./pages/realestate-partnerships";
import privacy from "./pages/privacy";
import terms from "./pages/terms";
import driverguide from "./pages/driverguide";
import contracttrip from "./pages/contract-trip";
import contracttrips from "./pages/contract-trips";
import contracttripedit from "./pages/contract-trip-edit";
import regularDeliveriesJohannesburg from "./pages/location-based-pages/section-template";
import ton15alberton from "./pages/location-based-pages/1.5-ton-alberton-section";
import ton15aliwalnorth from "./pages/location-based-pages/1.5-ton-aliwal-north-section";
import ton15beaufortwest from "./pages/location-based-pages/1.5-ton-beaufort-west-section";
import ton15benoni from "./pages/location-based-pages/1.5-ton-benoni-section";
import ton15bethal from "./pages/location-based-pages/1.5-ton-bethal-section";
import ton15bhisho from "./pages/location-based-pages/1.5-ton-bhisho-section";
import ton15bloemfontein from "./pages/location-based-pages/1.5-ton-bloemfontein-section";
import ton15brakpan from "./pages/location-based-pages/1.5-ton-brakpan-section";
import ton15brits from "./pages/location-based-pages/1.5-ton-brits-section";
import ton15capetown from "./pages/location-based-pages/1.5-ton-cape-town-section";
import ton15carletonville from "./pages/location-based-pages/1.5-ton-carletonville-section";
import ton15centurion from "./pages/location-based-pages/1.5-ton-centurion-section";
import ton15cradock from "./pages/location-based-pages/1.5-ton-cradock-section";
import ton15deaar from "./pages/location-based-pages/1.5-ton-de-aar-section";
import ton15durban from "./pages/location-based-pages/1.5-ton-durban-section";
import ton15eastlondon from "./pages/location-based-pages/1.5-ton-east-london-section";
import ton15ferndale from "./pages/location-based-pages/1.5-ton-ferndale-section";
import ton15george from "./pages/location-based-pages/1.5-ton-george-section";
import ton15graaffreinet from "./pages/location-based-pages/1.5-ton-graaff-reinet-section";
import ton15grahamstown from "./pages/location-based-pages/1.5-ton-grahamstown-section";
import ton15hermanus from "./pages/location-based-pages/1.5-ton-hermanus-section";
import ton15jeffreysbay from "./pages/location-based-pages/1.5-ton-jeffreys-bay-section";
import ton15johannesburg from "./pages/location-based-pages/1.5-ton-johannesburg-section";
import ton15kimberley from "./pages/location-based-pages/1.5-ton-kimberley-section";
import ton15klerksdorp from "./pages/location-based-pages/1.5-ton-klerksdorp-section";
import ton15knysna from "./pages/location-based-pages/1.5-ton-knysna-section";
import ton15kroonstad from "./pages/location-based-pages/1.5-ton-kroonstad-section";
import ton15kuilsrivier from "./pages/location-based-pages/1.5-ton-kuilsrivier-section";
import ton15ladysmith from "./pages/location-based-pages/1.5-ton-ladysmith-section";
import ton15lebowakgomo from "./pages/location-based-pages/1.5-ton-lebowakgomo-section";
import ton15lichtenburg from "./pages/location-based-pages/1.5-ton-lichtenburg-section";
import ton15mahikeng from "./pages/location-based-pages/1.5-ton-mahikeng-section";
import ton15malmesbury from "./pages/location-based-pages/1.5-ton-malmesbury-section";
import ton15middelburg from "./pages/location-based-pages/1.5-ton-middelburg-section";
import ton15midrand from "./pages/location-based-pages/1.5-ton-midrand-section";
import ton15mmabatho from "./pages/location-based-pages/1.5-ton-mmabatho-section";
import ton15mosselbay from "./pages/location-based-pages/1.5-ton-mossel-bay-section";
import ton15musina from "./pages/location-based-pages/1.5-ton-musina-section";
import ton15nelspruit from "./pages/location-based-pages/1.5-ton-nelspruit-section";
import ton15oudtshoorn from "./pages/location-based-pages/1.5-ton-oudtshoorn-section";
import ton15paarl from "./pages/location-based-pages/1.5-ton-paarl-section";
import ton15pietermaritzburg from "./pages/location-based-pages/1.5-ton-pietermaritzburg-section";
import ton15polokwane from "./pages/location-based-pages/1.5-ton-polokwane-section";
import ton15portelizabeth from "./pages/location-based-pages/1.5-ton-port-elizabeth-section";
import ton15portshepstone from "./pages/location-based-pages/1.5-ton-port-shepstone-section";
import ton15potchefstroom from "./pages/location-based-pages/1.5-ton-potchefstroom-section";
import ton15pretoria from "./pages/location-based-pages/1.5-ton-pretoria-section";
import ton15queenstown from "./pages/location-based-pages/1.5-ton-queenstown-section";
import ton15randburg from "./pages/location-based-pages/1.5-ton-randburg-section";
import ton15roodepoort from "./pages/location-based-pages/1.5-ton-roodepoort-section";
import ton15rustenburg from "./pages/location-based-pages/1.5-ton-rustenburg-section";
import ton15saldanha from "./pages/location-based-pages/1.5-ton-saldanha-section";
import ton15sandton from "./pages/location-based-pages/1.5-ton-sandton-section";
import ton15soweto from "./pages/location-based-pages/1.5-ton-soweto-section";
import ton15springs from "./pages/location-based-pages/1.5-ton-springs-section";
import ton15standerton from "./pages/location-based-pages/1.5-ton-standerton-section";
import ton15thohoyandou from "./pages/location-based-pages/1.5-ton-thohoyandou-section";
import ton15tzaneen from "./pages/location-based-pages/1.5-ton-tzaneen-section";
import ton15uitenhage from "./pages/location-based-pages/1.5-ton-uitenhage-section";
import ton15ulundi from "./pages/location-based-pages/1.5-ton-ulundi-section";
import ton15umtata from "./pages/location-based-pages/1.5-ton-umtata-section";
import ton15upington from "./pages/location-based-pages/1.5-ton-upington-section";
import ton15vanderbijlpark from "./pages/location-based-pages/1.5-ton-vanderbijlpark-section";
import ton15vereeniging from "./pages/location-based-pages/1.5-ton-vereeniging-section";
import ton15volksrust from "./pages/location-based-pages/1.5-ton-volksrust-section";
import ton15vredenburg from "./pages/location-based-pages/1.5-ton-vredenburg-section";
import ton15vryburg from "./pages/location-based-pages/1.5-ton-vryburg-section";
import ton15vryheid from "./pages/location-based-pages/1.5-ton-vryheid-section";
import ton15welkom from "./pages/location-based-pages/1.5-ton-welkom-section";
import ton15worcester from "./pages/location-based-pages/1.5-ton-worcester-section";
import ton1alberton from "./pages/location-based-pages/1-ton-alberton-section";
import ton1aliwalnorth from "./pages/location-based-pages/1-ton-aliwal-north-section";
import ton1beaufortwest from "./pages/location-based-pages/1-ton-beaufort-west-section";
import ton1benoni from "./pages/location-based-pages/1-ton-benoni-section";
import ton1bethal from "./pages/location-based-pages/1-ton-bethal-section";
import ton1bhisho from "./pages/location-based-pages/1-ton-bhisho-section";
import ton1bloemfontein from "./pages/location-based-pages/1-ton-bloemfontein-section";
import ton1brakpan from "./pages/location-based-pages/1-ton-brakpan-section";
import ton1brits from "./pages/location-based-pages/1-ton-brits-section";
import ton1capetown from "./pages/location-based-pages/1-ton-cape-town-section";
import ton1carletonville from "./pages/location-based-pages/1-ton-carletonville-section";
import ton1centurion from "./pages/location-based-pages/1-ton-centurion-section";
import ton1cradock from "./pages/location-based-pages/1-ton-cradock-section";
import ton1deaar from "./pages/location-based-pages/1-ton-de-aar-section";
import ton1durban from "./pages/location-based-pages/1-ton-durban-section";
import ton1eastlondon from "./pages/location-based-pages/1-ton-east-london-section";
import ton1ferndale from "./pages/location-based-pages/1-ton-ferndale-section";
import ton1george from "./pages/location-based-pages/1-ton-george-section";
import ton1graaffreinet from "./pages/location-based-pages/1-ton-graaff-reinet-section";
import ton1grahamstown from "./pages/location-based-pages/1-ton-grahamstown-section";
import ton1hermanus from "./pages/location-based-pages/1-ton-hermanus-section";
import ton1jeffreysbay from "./pages/location-based-pages/1-ton-jeffreys-bay-section";
import ton1johannesburg from "./pages/location-based-pages/1-ton-johannesburg-section";
import ton1kimberley from "./pages/location-based-pages/1-ton-kimberley-section";
import ton1klerksdorp from "./pages/location-based-pages/1-ton-klerksdorp-section";
import ton1knysna from "./pages/location-based-pages/1-ton-knysna-section";
import ton1kroonstad from "./pages/location-based-pages/1-ton-kroonstad-section";
import ton1kuilsrivier from "./pages/location-based-pages/1-ton-kuilsrivier-section";
import ton1ladysmith from "./pages/location-based-pages/1-ton-ladysmith-section";
import ton1lebowakgomo from "./pages/location-based-pages/1-ton-lebowakgomo-section";
import ton1lichtenburg from "./pages/location-based-pages/1-ton-lichtenburg-section";
import ton1mahikeng from "./pages/location-based-pages/1-ton-mahikeng-section";
import ton1malmesbury from "./pages/location-based-pages/1-ton-malmesbury-section";
import ton1middelburg from "./pages/location-based-pages/1-ton-middelburg-section";
import ton1midrand from "./pages/location-based-pages/1-ton-midrand-section";
import ton1mmabatho from "./pages/location-based-pages/1-ton-mmabatho-section";
import ton1mosselbay from "./pages/location-based-pages/1-ton-mossel-bay-section";
import ton1musina from "./pages/location-based-pages/1-ton-musina-section";
import ton1nelspruit from "./pages/location-based-pages/1-ton-nelspruit-section";
import ton1oudtshoorn from "./pages/location-based-pages/1-ton-oudtshoorn-section";
import ton1paarl from "./pages/location-based-pages/1-ton-paarl-section";
import ton1pietermaritzburg from "./pages/location-based-pages/1-ton-pietermaritzburg-section";
import ton1polokwane from "./pages/location-based-pages/1-ton-polokwane-section";
import ton1portelizabeth from "./pages/location-based-pages/1-ton-port-elizabeth-section";
import ton1portshepstone from "./pages/location-based-pages/1-ton-port-shepstone-section";
import ton1potchefstroom from "./pages/location-based-pages/1-ton-potchefstroom-section";
import ton1pretoria from "./pages/location-based-pages/1-ton-pretoria-section";
import ton1queenstown from "./pages/location-based-pages/1-ton-queenstown-section";
import ton1randburg from "./pages/location-based-pages/1-ton-randburg-section";
import ton1roodepoort from "./pages/location-based-pages/1-ton-roodepoort-section";
import ton1rustenburg from "./pages/location-based-pages/1-ton-rustenburg-section";
import ton1saldanha from "./pages/location-based-pages/1-ton-saldanha-section";
import ton1sandton from "./pages/location-based-pages/1-ton-sandton-section";
import ton1soweto from "./pages/location-based-pages/1-ton-soweto-section";
import ton1springs from "./pages/location-based-pages/1-ton-springs-section";
import ton1standerton from "./pages/location-based-pages/1-ton-standerton-section";
import ton1thohoyandou from "./pages/location-based-pages/1-ton-thohoyandou-section";
import ton1tzaneen from "./pages/location-based-pages/1-ton-tzaneen-section";
import ton1uitenhage from "./pages/location-based-pages/1-ton-uitenhage-section";
import ton1ulundi from "./pages/location-based-pages/1-ton-ulundi-section";
import ton1umtata from "./pages/location-based-pages/1-ton-umtata-section";
import ton1upington from "./pages/location-based-pages/1-ton-upington-section";
import ton1vanderbijlpark from "./pages/location-based-pages/1-ton-vanderbijlpark-section";
import ton1vereeniging from "./pages/location-based-pages/1-ton-vereeniging-section";
import ton1volksrust from "./pages/location-based-pages/1-ton-volksrust-section";
import ton1vredenburg from "./pages/location-based-pages/1-ton-vredenburg-section";
import ton1vryburg from "./pages/location-based-pages/1-ton-vryburg-section";
import ton1vryheid from "./pages/location-based-pages/1-ton-vryheid-section";
import ton1welkom from "./pages/location-based-pages/1-ton-welkom-section";
import ton1worcester from "./pages/location-based-pages/1-ton-worcester-section";
import ton4alberton from "./pages/location-based-pages/4-ton-alberton-section";
import ton4aliwalnorth from "./pages/location-based-pages/4-ton-aliwal-north-section";
import ton4beaufortwest from "./pages/location-based-pages/4-ton-beaufort-west-section";
import ton4benoni from "./pages/location-based-pages/4-ton-benoni-section";
import ton4bethal from "./pages/location-based-pages/4-ton-bethal-section";
import ton4bhisho from "./pages/location-based-pages/4-ton-bhisho-section";
import ton4bloemfontein from "./pages/location-based-pages/4-ton-bloemfontein-section";
import ton4brakpan from "./pages/location-based-pages/4-ton-brakpan-section";
import ton4brits from "./pages/location-based-pages/4-ton-brits-section";
import ton4capetown from "./pages/location-based-pages/4-ton-cape-town-section";
import ton4carletonville from "./pages/location-based-pages/4-ton-carletonville-section";
import ton4centurion from "./pages/location-based-pages/4-ton-centurion-section";
import ton4cradock from "./pages/location-based-pages/4-ton-cradock-section";
import ton4deaar from "./pages/location-based-pages/4-ton-de-aar-section";
import ton4durban from "./pages/location-based-pages/4-ton-durban-section";
import ton4eastlondon from "./pages/location-based-pages/4-ton-east-london-section";
import ton4ferndale from "./pages/location-based-pages/4-ton-ferndale-section";
import ton4george from "./pages/location-based-pages/4-ton-george-section";
import ton4graaffreinet from "./pages/location-based-pages/4-ton-graaff-reinet-section";
import ton4grahamstown from "./pages/location-based-pages/4-ton-grahamstown-section";
import ton4hermanus from "./pages/location-based-pages/4-ton-hermanus-section";
import ton4jeffreysbay from "./pages/location-based-pages/4-ton-jeffreys-bay-section";
import ton4johannesburg from "./pages/location-based-pages/4-ton-johannesburg-section";
import ton4kimberley from "./pages/location-based-pages/4-ton-kimberley-section";
import ton4klerksdorp from "./pages/location-based-pages/4-ton-klerksdorp-section";
import ton4knysna from "./pages/location-based-pages/4-ton-knysna-section";
import ton4kroonstad from "./pages/location-based-pages/4-ton-kroonstad-section";
import ton4kuilsrivier from "./pages/location-based-pages/4-ton-kuilsrivier-section";
import ton4ladysmith from "./pages/location-based-pages/4-ton-ladysmith-section";
import ton4lebowakgomo from "./pages/location-based-pages/4-ton-lebowakgomo-section";
import ton4lichtenburg from "./pages/location-based-pages/4-ton-lichtenburg-section";
import ton4mahikeng from "./pages/location-based-pages/4-ton-mahikeng-section";
import ton4malmesbury from "./pages/location-based-pages/4-ton-malmesbury-section";
import ton4middelburg from "./pages/location-based-pages/4-ton-middelburg-section";
import ton4midrand from "./pages/location-based-pages/4-ton-midrand-section";
import ton4mmabatho from "./pages/location-based-pages/4-ton-mmabatho-section";
import ton4mosselbay from "./pages/location-based-pages/4-ton-mossel-bay-section";
import ton4musina from "./pages/location-based-pages/4-ton-musina-section";
import ton4nelspruit from "./pages/location-based-pages/4-ton-nelspruit-section";
import ton4oudtshoorn from "./pages/location-based-pages/4-ton-oudtshoorn-section";
import ton4paarl from "./pages/location-based-pages/4-ton-paarl-section";
import ton4pietermaritzburg from "./pages/location-based-pages/4-ton-pietermaritzburg-section";
import ton4polokwane from "./pages/location-based-pages/4-ton-polokwane-section";
import ton4portelizabeth from "./pages/location-based-pages/4-ton-port-elizabeth-section";
import ton4portshepstone from "./pages/location-based-pages/4-ton-port-shepstone-section";
import ton4potchefstroom from "./pages/location-based-pages/4-ton-potchefstroom-section";
import ton4pretoria from "./pages/location-based-pages/4-ton-pretoria-section";
import ton4queenstown from "./pages/location-based-pages/4-ton-queenstown-section";
import ton4randburg from "./pages/location-based-pages/4-ton-randburg-section";
import ton4roodepoort from "./pages/location-based-pages/4-ton-roodepoort-section";
import ton4rustenburg from "./pages/location-based-pages/4-ton-rustenburg-section";
import ton4saldanha from "./pages/location-based-pages/4-ton-saldanha-section";
import ton4sandton from "./pages/location-based-pages/4-ton-sandton-section";
import ton4soweto from "./pages/location-based-pages/4-ton-soweto-section";
import ton4springs from "./pages/location-based-pages/4-ton-springs-section";
import ton4standerton from "./pages/location-based-pages/4-ton-standerton-section";
import ton4thohoyandou from "./pages/location-based-pages/4-ton-thohoyandou-section";
import ton4tzaneen from "./pages/location-based-pages/4-ton-tzaneen-section";
import ton4uitenhage from "./pages/location-based-pages/4-ton-uitenhage-section";
import ton4ulundi from "./pages/location-based-pages/4-ton-ulundi-section";
import ton4umtata from "./pages/location-based-pages/4-ton-umtata-section";
import ton4upington from "./pages/location-based-pages/4-ton-upington-section";
import ton4vanderbijlpark from "./pages/location-based-pages/4-ton-vanderbijlpark-section";
import ton4vereeniging from "./pages/location-based-pages/4-ton-vereeniging-section";
import ton4volksrust from "./pages/location-based-pages/4-ton-volksrust-section";
import ton4vredenburg from "./pages/location-based-pages/4-ton-vredenburg-section";
import ton4vryburg from "./pages/location-based-pages/4-ton-vryburg-section";
import ton4vryheid from "./pages/location-based-pages/4-ton-vryheid-section";
import ton4welkom from "./pages/location-based-pages/4-ton-welkom-section";
import ton4worcester from "./pages/location-based-pages/4-ton-worcester-section";
import ton8alberton from "./pages/location-based-pages/8-ton-alberton-section";
import ton8aliwalnorth from "./pages/location-based-pages/8-ton-aliwal-north-section";
import ton8beaufortwest from "./pages/location-based-pages/8-ton-beaufort-west-section";
import ton8benoni from "./pages/location-based-pages/8-ton-benoni-section";
import ton8bethal from "./pages/location-based-pages/8-ton-bethal-section";
import ton8bhisho from "./pages/location-based-pages/8-ton-bhisho-section";
import ton8bloemfontein from "./pages/location-based-pages/8-ton-bloemfontein-section";
import ton8brakpan from "./pages/location-based-pages/8-ton-brakpan-section";
import ton8brits from "./pages/location-based-pages/8-ton-brits-section";
import ton8capetown from "./pages/location-based-pages/8-ton-cape-town-section";
import ton8carletonville from "./pages/location-based-pages/8-ton-carletonville-section";
import ton8centurion from "./pages/location-based-pages/8-ton-centurion-section";
import ton8cradock from "./pages/location-based-pages/8-ton-cradock-section";
import ton8deaar from "./pages/location-based-pages/8-ton-de-aar-section";
import ton8durban from "./pages/location-based-pages/8-ton-durban-section";
import ton8eastlondon from "./pages/location-based-pages/8-ton-east-london-section";
import ton8ferndale from "./pages/location-based-pages/8-ton-ferndale-section";
import ton8george from "./pages/location-based-pages/8-ton-george-section";
import ton8graaffreinet from "./pages/location-based-pages/8-ton-graaff-reinet-section";
import ton8grahamstown from "./pages/location-based-pages/8-ton-grahamstown-section";
import ton8hermanus from "./pages/location-based-pages/8-ton-hermanus-section";
import ton8jeffreysbay from "./pages/location-based-pages/8-ton-jeffreys-bay-section";
import ton8johannesburg from "./pages/location-based-pages/8-ton-johannesburg-section";
import ton8kimberley from "./pages/location-based-pages/8-ton-kimberley-section";
import ton8klerksdorp from "./pages/location-based-pages/8-ton-klerksdorp-section";
import ton8knysna from "./pages/location-based-pages/8-ton-knysna-section";
import ton8kroonstad from "./pages/location-based-pages/8-ton-kroonstad-section";
import ton8kuilsrivier from "./pages/location-based-pages/8-ton-kuilsrivier-section";
import ton8ladysmith from "./pages/location-based-pages/8-ton-ladysmith-section";
import ton8lebowakgomo from "./pages/location-based-pages/8-ton-lebowakgomo-section";
import ton8lichtenburg from "./pages/location-based-pages/8-ton-lichtenburg-section";
import ton8mahikeng from "./pages/location-based-pages/8-ton-mahikeng-section";
import ton8malmesbury from "./pages/location-based-pages/8-ton-malmesbury-section";
import ton8middelburg from "./pages/location-based-pages/8-ton-middelburg-section";
import ton8midrand from "./pages/location-based-pages/8-ton-midrand-section";
import ton8mmabatho from "./pages/location-based-pages/8-ton-mmabatho-section";
import ton8mosselbay from "./pages/location-based-pages/8-ton-mossel-bay-section";
import ton8musina from "./pages/location-based-pages/8-ton-musina-section";
import ton8nelspruit from "./pages/location-based-pages/8-ton-nelspruit-section";
import ton8oudtshoorn from "./pages/location-based-pages/8-ton-oudtshoorn-section";
import ton8paarl from "./pages/location-based-pages/8-ton-paarl-section";
import ton8pietermaritzburg from "./pages/location-based-pages/8-ton-pietermaritzburg-section";
import ton8polokwane from "./pages/location-based-pages/8-ton-polokwane-section";
import ton8portelizabeth from "./pages/location-based-pages/8-ton-port-elizabeth-section";
import ton8portshepstone from "./pages/location-based-pages/8-ton-port-shepstone-section";
import ton8potchefstroom from "./pages/location-based-pages/8-ton-potchefstroom-section";
import ton8pretoria from "./pages/location-based-pages/8-ton-pretoria-section";
import ton8queenstown from "./pages/location-based-pages/8-ton-queenstown-section";
import ton8randburg from "./pages/location-based-pages/8-ton-randburg-section";
import ton8roodepoort from "./pages/location-based-pages/8-ton-roodepoort-section";
import ton8rustenburg from "./pages/location-based-pages/8-ton-rustenburg-section";
import ton8saldanha from "./pages/location-based-pages/8-ton-saldanha-section";
import ton8sandton from "./pages/location-based-pages/8-ton-sandton-section";
import ton8soweto from "./pages/location-based-pages/8-ton-soweto-section";
import ton8springs from "./pages/location-based-pages/8-ton-springs-section";
import ton8standerton from "./pages/location-based-pages/8-ton-standerton-section";
import ton8thohoyandou from "./pages/location-based-pages/8-ton-thohoyandou-section";
import ton8tzaneen from "./pages/location-based-pages/8-ton-tzaneen-section";
import ton8uitenhage from "./pages/location-based-pages/8-ton-uitenhage-section";
import ton8ulundi from "./pages/location-based-pages/8-ton-ulundi-section";
import ton8umtata from "./pages/location-based-pages/8-ton-umtata-section";
import ton8upington from "./pages/location-based-pages/8-ton-upington-section";
import ton8vanderbijlpark from "./pages/location-based-pages/8-ton-vanderbijlpark-section";
import ton8vereeniging from "./pages/location-based-pages/8-ton-vereeniging-section";
import ton8volksrust from "./pages/location-based-pages/8-ton-volksrust-section";
import ton8vredenburg from "./pages/location-based-pages/8-ton-vredenburg-section";
import ton8vryburg from "./pages/location-based-pages/8-ton-vryburg-section";
import ton8vryheid from "./pages/location-based-pages/8-ton-vryheid-section";
import ton8welkom from "./pages/location-based-pages/8-ton-welkom-section";
import ton8worcester from "./pages/location-based-pages/8-ton-worcester-section";
import bakkiehirealberton from "./pages/location-based-pages/bakkie-hire--alberton-section";
import bakkiehirealiwalnorth from "./pages/location-based-pages/bakkie-hire--aliwal-north-section";
import bakkiehirebeaufortwest from "./pages/location-based-pages/bakkie-hire--beaufort-west-section";
import bakkiehirebenoni from "./pages/location-based-pages/bakkie-hire--benoni-section";
import bakkiehirebethal from "./pages/location-based-pages/bakkie-hire--bethal-section";
import bakkiehirebhisho from "./pages/location-based-pages/bakkie-hire--bhisho-section";
import bakkiehirebloemfontein from "./pages/location-based-pages/bakkie-hire--bloemfontein-section";
import bakkiehirebrakpan from "./pages/location-based-pages/bakkie-hire--brakpan-section";
import bakkiehirebrits from "./pages/location-based-pages/bakkie-hire--brits-section";
import bakkiehirecapetown from "./pages/location-based-pages/bakkie-hire--cape-town-section";
import bakkiehirecarletonville from "./pages/location-based-pages/bakkie-hire--carletonville-section";
import bakkiehirecenturion from "./pages/location-based-pages/bakkie-hire--centurion-section";
import bakkiehirecradock from "./pages/location-based-pages/bakkie-hire--cradock-section";
import bakkiehiredeaar from "./pages/location-based-pages/bakkie-hire--de-aar-section";
import bakkiehiredurban from "./pages/location-based-pages/bakkie-hire--durban-section";
import bakkiehireeastlondon from "./pages/location-based-pages/bakkie-hire--east-london-section";
import bakkiehireferndale from "./pages/location-based-pages/bakkie-hire--ferndale-section";
import bakkiehiregeorge from "./pages/location-based-pages/bakkie-hire--george-section";
import bakkiehiregraaffreinet from "./pages/location-based-pages/bakkie-hire--graaff-reinet-section";
import bakkiehiregrahamstown from "./pages/location-based-pages/bakkie-hire--grahamstown-section";
import bakkiehirehermanus from "./pages/location-based-pages/bakkie-hire--hermanus-section";
import bakkiehirejeffreysbay from "./pages/location-based-pages/bakkie-hire--jeffreys-bay-section";
import bakkiehirejohannesburg from "./pages/location-based-pages/bakkie-hire--johannesburg-section";
import bakkiehirekimberley from "./pages/location-based-pages/bakkie-hire--kimberley-section";
import bakkiehireklerksdorp from "./pages/location-based-pages/bakkie-hire--klerksdorp-section";
import bakkiehireknysna from "./pages/location-based-pages/bakkie-hire--knysna-section";
import bakkiehirekroonstad from "./pages/location-based-pages/bakkie-hire--kroonstad-section";
import bakkiehirekuilsrivier from "./pages/location-based-pages/bakkie-hire--kuilsrivier-section";
import bakkiehireladysmith from "./pages/location-based-pages/bakkie-hire--ladysmith-section";
import bakkiehirelebowakgomo from "./pages/location-based-pages/bakkie-hire--lebowakgomo-section";
import bakkiehirelichtenburg from "./pages/location-based-pages/bakkie-hire--lichtenburg-section";
import bakkiehiremahikeng from "./pages/location-based-pages/bakkie-hire--mahikeng-section";
import bakkiehiremalmesbury from "./pages/location-based-pages/bakkie-hire--malmesbury-section";
import bakkiehiremiddelburg from "./pages/location-based-pages/bakkie-hire--middelburg-section";
import bakkiehiremidrand from "./pages/location-based-pages/bakkie-hire--midrand-section";
import bakkiehiremmabatho from "./pages/location-based-pages/bakkie-hire--mmabatho-section";
import bakkiehiremosselbay from "./pages/location-based-pages/bakkie-hire--mossel-bay-section";
import bakkiehiremusina from "./pages/location-based-pages/bakkie-hire--musina-section";
import bakkiehirenelspruit from "./pages/location-based-pages/bakkie-hire--nelspruit-section";
import bakkiehireoudtshoorn from "./pages/location-based-pages/bakkie-hire--oudtshoorn-section";
import bakkiehirepaarl from "./pages/location-based-pages/bakkie-hire--paarl-section";
import bakkiehirepietermaritzburg from "./pages/location-based-pages/bakkie-hire--pietermaritzburg-section";
import bakkiehirepolokwane from "./pages/location-based-pages/bakkie-hire--polokwane-section";
import bakkiehireportelizabeth from "./pages/location-based-pages/bakkie-hire--port-elizabeth-section";
import bakkiehireportshepstone from "./pages/location-based-pages/bakkie-hire--port-shepstone-section";
import bakkiehirepotchefstroom from "./pages/location-based-pages/bakkie-hire--potchefstroom-section";
import bakkiehirepretoria from "./pages/location-based-pages/bakkie-hire--pretoria-section";
import bakkiehirequeenstown from "./pages/location-based-pages/bakkie-hire--queenstown-section";
import bakkiehirerandburg from "./pages/location-based-pages/bakkie-hire--randburg-section";
import bakkiehireroodepoort from "./pages/location-based-pages/bakkie-hire--roodepoort-section";
import bakkiehirerustenburg from "./pages/location-based-pages/bakkie-hire--rustenburg-section";
import bakkiehiresaldanha from "./pages/location-based-pages/bakkie-hire--saldanha-section";
import bakkiehiresandton from "./pages/location-based-pages/bakkie-hire--sandton-section";
import bakkiehiresoweto from "./pages/location-based-pages/bakkie-hire--soweto-section";
import bakkiehiresprings from "./pages/location-based-pages/bakkie-hire--springs-section";
import bakkiehirestanderton from "./pages/location-based-pages/bakkie-hire--standerton-section";
import bakkiehirethohoyandou from "./pages/location-based-pages/bakkie-hire--thohoyandou-section";
import bakkiehiretzaneen from "./pages/location-based-pages/bakkie-hire--tzaneen-section";
import bakkiehireuitenhage from "./pages/location-based-pages/bakkie-hire--uitenhage-section";
import bakkiehireulundi from "./pages/location-based-pages/bakkie-hire--ulundi-section";
import bakkiehireumtata from "./pages/location-based-pages/bakkie-hire--umtata-section";
import bakkiehireupington from "./pages/location-based-pages/bakkie-hire--upington-section";
import bakkiehirevanderbijlpark from "./pages/location-based-pages/bakkie-hire--vanderbijlpark-section";
import bakkiehirevereeniging from "./pages/location-based-pages/bakkie-hire--vereeniging-section";
import bakkiehirevolksrust from "./pages/location-based-pages/bakkie-hire--volksrust-section";
import bakkiehirevredenburg from "./pages/location-based-pages/bakkie-hire--vredenburg-section";
import bakkiehirevryburg from "./pages/location-based-pages/bakkie-hire--vryburg-section";
import bakkiehirevryheid from "./pages/location-based-pages/bakkie-hire--vryheid-section";
import bakkiehirewelkom from "./pages/location-based-pages/bakkie-hire--welkom-section";
import bakkiehireworcester from "./pages/location-based-pages/bakkie-hire--worcester-section";
import businessdeliveryalberton from "./pages/location-based-pages/business-delivery-alberton-section";
import businessdeliveryaliwalnorth from "./pages/location-based-pages/business-delivery-aliwal-north-section";
import businessdeliverybeaufortwest from "./pages/location-based-pages/business-delivery-beaufort-west-section";
import businessdeliverybenoni from "./pages/location-based-pages/business-delivery-benoni-section";
import businessdeliverybethal from "./pages/location-based-pages/business-delivery-bethal-section";
import businessdeliverybhisho from "./pages/location-based-pages/business-delivery-bhisho-section";
import businessdeliverybloemfontein from "./pages/location-based-pages/business-delivery-bloemfontein-section";
import businessdeliverybrakpan from "./pages/location-based-pages/business-delivery-brakpan-section";
import businessdeliverybrits from "./pages/location-based-pages/business-delivery-brits-section";
import businessdeliverycapetown from "./pages/location-based-pages/business-delivery-cape-town-section";
import businessdeliverycarletonville from "./pages/location-based-pages/business-delivery-carletonville-section";
import businessdeliverycenturion from "./pages/location-based-pages/business-delivery-centurion-section";
import businessdeliverycradock from "./pages/location-based-pages/business-delivery-cradock-section";
import businessdeliverydeaar from "./pages/location-based-pages/business-delivery-de-aar-section";
import businessdeliverydurban from "./pages/location-based-pages/business-delivery-durban-section";
import businessdeliveryeastlondon from "./pages/location-based-pages/business-delivery-east-london-section";
import businessdeliveryferndale from "./pages/location-based-pages/business-delivery-ferndale-section";
import businessdeliverygeorge from "./pages/location-based-pages/business-delivery-george-section";
import businessdeliverygraaffreinet from "./pages/location-based-pages/business-delivery-graaff-reinet-section";
import businessdeliverygrahamstown from "./pages/location-based-pages/business-delivery-grahamstown-section";
import businessdeliveryhermanus from "./pages/location-based-pages/business-delivery-hermanus-section";
import businessdeliveryjeffreysbay from "./pages/location-based-pages/business-delivery-jeffreys-bay-section";
import businessdeliveryjohannesburg from "./pages/location-based-pages/business-delivery-johannesburg-section";
import businessdeliverykimberley from "./pages/location-based-pages/business-delivery-kimberley-section";
import businessdeliveryklerksdorp from "./pages/location-based-pages/business-delivery-klerksdorp-section";
import businessdeliveryknysna from "./pages/location-based-pages/business-delivery-knysna-section";
import businessdeliverykroonstad from "./pages/location-based-pages/business-delivery-kroonstad-section";
import businessdeliverykuilsrivier from "./pages/location-based-pages/business-delivery-kuilsrivier-section";
import businessdeliveryladysmith from "./pages/location-based-pages/business-delivery-ladysmith-section";
import businessdeliverylebowakgomo from "./pages/location-based-pages/business-delivery-lebowakgomo-section";
import businessdeliverylichtenburg from "./pages/location-based-pages/business-delivery-lichtenburg-section";
import businessdeliverymahikeng from "./pages/location-based-pages/business-delivery-mahikeng-section";
import businessdeliverymalmesbury from "./pages/location-based-pages/business-delivery-malmesbury-section";
import businessdeliverymiddelburg from "./pages/location-based-pages/business-delivery-middelburg-section";
import businessdeliverymidrand from "./pages/location-based-pages/business-delivery-midrand-section";
import businessdeliverymmabatho from "./pages/location-based-pages/business-delivery-mmabatho-section";
import businessdeliverymosselbay from "./pages/location-based-pages/business-delivery-mossel-bay-section";
import businessdeliverymusina from "./pages/location-based-pages/business-delivery-musina-section";
import businessdeliverynelspruit from "./pages/location-based-pages/business-delivery-nelspruit-section";
import businessdeliveryoudtshoorn from "./pages/location-based-pages/business-delivery-oudtshoorn-section";
import businessdeliverypaarl from "./pages/location-based-pages/business-delivery-paarl-section";
import businessdeliverypietermaritzburg from "./pages/location-based-pages/business-delivery-pietermaritzburg-section";
import businessdeliverypolokwane from "./pages/location-based-pages/business-delivery-polokwane-section";
import businessdeliveryportelizabeth from "./pages/location-based-pages/business-delivery-port-elizabeth-section";
import businessdeliveryportshepstone from "./pages/location-based-pages/business-delivery-port-shepstone-section";
import businessdeliverypotchefstroom from "./pages/location-based-pages/business-delivery-potchefstroom-section";
import businessdeliverypretoria from "./pages/location-based-pages/business-delivery-pretoria-section";
import businessdeliveryqueenstown from "./pages/location-based-pages/business-delivery-queenstown-section";
import businessdeliveryrandburg from "./pages/location-based-pages/business-delivery-randburg-section";
import businessdeliveryroodepoort from "./pages/location-based-pages/business-delivery-roodepoort-section";
import businessdeliveryrustenburg from "./pages/location-based-pages/business-delivery-rustenburg-section";
import businessdeliverysaldanha from "./pages/location-based-pages/business-delivery-saldanha-section";
import businessdeliverysandton from "./pages/location-based-pages/business-delivery-sandton-section";
import businessdeliverysoweto from "./pages/location-based-pages/business-delivery-soweto-section";
import businessdeliverysprings from "./pages/location-based-pages/business-delivery-springs-section";
import businessdeliverystanderton from "./pages/location-based-pages/business-delivery-standerton-section";
import businessdeliverythohoyandou from "./pages/location-based-pages/business-delivery-thohoyandou-section";
import businessdeliverytzaneen from "./pages/location-based-pages/business-delivery-tzaneen-section";
import businessdeliveryuitenhage from "./pages/location-based-pages/business-delivery-uitenhage-section";
import businessdeliveryulundi from "./pages/location-based-pages/business-delivery-ulundi-section";
import businessdeliveryumtata from "./pages/location-based-pages/business-delivery-umtata-section";
import businessdeliveryupington from "./pages/location-based-pages/business-delivery-upington-section";
import businessdeliveryvanderbijlpark from "./pages/location-based-pages/business-delivery-vanderbijlpark-section";
import businessdeliveryvereeniging from "./pages/location-based-pages/business-delivery-vereeniging-section";
import businessdeliveryvolksrust from "./pages/location-based-pages/business-delivery-volksrust-section";
import businessdeliveryvredenburg from "./pages/location-based-pages/business-delivery-vredenburg-section";
import businessdeliveryvryburg from "./pages/location-based-pages/business-delivery-vryburg-section";
import businessdeliveryvryheid from "./pages/location-based-pages/business-delivery-vryheid-section";
import businessdeliverywelkom from "./pages/location-based-pages/business-delivery-welkom-section";
import businessdeliveryworcester from "./pages/location-based-pages/business-delivery-worcester-section";
import cheapmoversnearmealberton from "./pages/location-based-pages/cheap-movers-near-me-alberton-section";
import cheapmoversnearmealiwalnorth from "./pages/location-based-pages/cheap-movers-near-me-aliwal-north-section";
import cheapmoversnearmebeaufortwest from "./pages/location-based-pages/cheap-movers-near-me-beaufort-west-section";
import cheapmoversnearmebenoni from "./pages/location-based-pages/cheap-movers-near-me-benoni-section";
import cheapmoversnearmebethal from "./pages/location-based-pages/cheap-movers-near-me-bethal-section";
import cheapmoversnearmebhisho from "./pages/location-based-pages/cheap-movers-near-me-bhisho-section";
import cheapmoversnearmebloemfontein from "./pages/location-based-pages/cheap-movers-near-me-bloemfontein-section";
import cheapmoversnearmebrakpan from "./pages/location-based-pages/cheap-movers-near-me-brakpan-section";
import cheapmoversnearmebrits from "./pages/location-based-pages/cheap-movers-near-me-brits-section";
import cheapmoversnearmecapetown from "./pages/location-based-pages/cheap-movers-near-me-cape-town-section";
import cheapmoversnearmecarletonville from "./pages/location-based-pages/cheap-movers-near-me-carletonville-section";
import cheapmoversnearmecenturion from "./pages/location-based-pages/cheap-movers-near-me-centurion-section";
import cheapmoversnearmecradock from "./pages/location-based-pages/cheap-movers-near-me-cradock-section";
import cheapmoversnearmedeaar from "./pages/location-based-pages/cheap-movers-near-me-de-aar-section";
import cheapmoversnearmedurban from "./pages/location-based-pages/cheap-movers-near-me-durban-section";
import cheapmoversnearmeeastlondon from "./pages/location-based-pages/cheap-movers-near-me-east-london-section";
import cheapmoversnearmeferndale from "./pages/location-based-pages/cheap-movers-near-me-ferndale-section";
import cheapmoversnearmegeorge from "./pages/location-based-pages/cheap-movers-near-me-george-section";
import cheapmoversnearmegraaffreinet from "./pages/location-based-pages/cheap-movers-near-me-graaff-reinet-section";
import cheapmoversnearmegrahamstown from "./pages/location-based-pages/cheap-movers-near-me-grahamstown-section";
import cheapmoversnearmehermanus from "./pages/location-based-pages/cheap-movers-near-me-hermanus-section";
import cheapmoversnearmejeffreysbay from "./pages/location-based-pages/cheap-movers-near-me-jeffreys-bay-section";
import cheapmoversnearmejohannesburg from "./pages/location-based-pages/cheap-movers-near-me-johannesburg-section";
import cheapmoversnearmekimberley from "./pages/location-based-pages/cheap-movers-near-me-kimberley-section";
import cheapmoversnearmeklerksdorp from "./pages/location-based-pages/cheap-movers-near-me-klerksdorp-section";
import cheapmoversnearmeknysna from "./pages/location-based-pages/cheap-movers-near-me-knysna-section";
import cheapmoversnearmekroonstad from "./pages/location-based-pages/cheap-movers-near-me-kroonstad-section";
import cheapmoversnearmekuilsrivier from "./pages/location-based-pages/cheap-movers-near-me-kuilsrivier-section";
import cheapmoversnearmeladysmith from "./pages/location-based-pages/cheap-movers-near-me-ladysmith-section";
import cheapmoversnearmelebowakgomo from "./pages/location-based-pages/cheap-movers-near-me-lebowakgomo-section";
import cheapmoversnearmelichtenburg from "./pages/location-based-pages/cheap-movers-near-me-lichtenburg-section";
import cheapmoversnearmemahikeng from "./pages/location-based-pages/cheap-movers-near-me-mahikeng-section";
import cheapmoversnearmemalmesbury from "./pages/location-based-pages/cheap-movers-near-me-malmesbury-section";
import cheapmoversnearmemiddelburg from "./pages/location-based-pages/cheap-movers-near-me-middelburg-section";
import cheapmoversnearmemidrand from "./pages/location-based-pages/cheap-movers-near-me-midrand-section";
import cheapmoversnearmemmabatho from "./pages/location-based-pages/cheap-movers-near-me-mmabatho-section";
import cheapmoversnearmemosselbay from "./pages/location-based-pages/cheap-movers-near-me-mossel-bay-section";
import cheapmoversnearmemusina from "./pages/location-based-pages/cheap-movers-near-me-musina-section";
import cheapmoversnearmenelspruit from "./pages/location-based-pages/cheap-movers-near-me-nelspruit-section";
import cheapmoversnearmeoudtshoorn from "./pages/location-based-pages/cheap-movers-near-me-oudtshoorn-section";
import cheapmoversnearmepaarl from "./pages/location-based-pages/cheap-movers-near-me-paarl-section";
import cheapmoversnearmepietermaritzburg from "./pages/location-based-pages/cheap-movers-near-me-pietermaritzburg-section";
import cheapmoversnearmepolokwane from "./pages/location-based-pages/cheap-movers-near-me-polokwane-section";
import cheapmoversnearmeportelizabeth from "./pages/location-based-pages/cheap-movers-near-me-port-elizabeth-section";
import cheapmoversnearmeportshepstone from "./pages/location-based-pages/cheap-movers-near-me-port-shepstone-section";
import cheapmoversnearmepotchefstroom from "./pages/location-based-pages/cheap-movers-near-me-potchefstroom-section";
import cheapmoversnearmepretoria from "./pages/location-based-pages/cheap-movers-near-me-pretoria-section";
import cheapmoversnearmequeenstown from "./pages/location-based-pages/cheap-movers-near-me-queenstown-section";
import cheapmoversnearmerandburg from "./pages/location-based-pages/cheap-movers-near-me-randburg-section";
import cheapmoversnearmeroodepoort from "./pages/location-based-pages/cheap-movers-near-me-roodepoort-section";
import cheapmoversnearmerustenburg from "./pages/location-based-pages/cheap-movers-near-me-rustenburg-section";
import cheapmoversnearmesaldanha from "./pages/location-based-pages/cheap-movers-near-me-saldanha-section";
import cheapmoversnearmesandton from "./pages/location-based-pages/cheap-movers-near-me-sandton-section";
import cheapmoversnearmesoweto from "./pages/location-based-pages/cheap-movers-near-me-soweto-section";
import cheapmoversnearmesprings from "./pages/location-based-pages/cheap-movers-near-me-springs-section";
import cheapmoversnearmestanderton from "./pages/location-based-pages/cheap-movers-near-me-standerton-section";
import cheapmoversnearmethohoyandou from "./pages/location-based-pages/cheap-movers-near-me-thohoyandou-section";
import cheapmoversnearmetzaneen from "./pages/location-based-pages/cheap-movers-near-me-tzaneen-section";
import cheapmoversnearmeuitenhage from "./pages/location-based-pages/cheap-movers-near-me-uitenhage-section";
import cheapmoversnearmeulundi from "./pages/location-based-pages/cheap-movers-near-me-ulundi-section";
import cheapmoversnearmeumtata from "./pages/location-based-pages/cheap-movers-near-me-umtata-section";
import cheapmoversnearmeupington from "./pages/location-based-pages/cheap-movers-near-me-upington-section";
import cheapmoversnearmevanderbijlpark from "./pages/location-based-pages/cheap-movers-near-me-vanderbijlpark-section";
import cheapmoversnearmevereeniging from "./pages/location-based-pages/cheap-movers-near-me-vereeniging-section";
import cheapmoversnearmevolksrust from "./pages/location-based-pages/cheap-movers-near-me-volksrust-section";
import cheapmoversnearmevredenburg from "./pages/location-based-pages/cheap-movers-near-me-vredenburg-section";
import cheapmoversnearmevryburg from "./pages/location-based-pages/cheap-movers-near-me-vryburg-section";
import cheapmoversnearmevryheid from "./pages/location-based-pages/cheap-movers-near-me-vryheid-section";
import cheapmoversnearmewelkom from "./pages/location-based-pages/cheap-movers-near-me-welkom-section";
import cheapmoversnearmeworcester from "./pages/location-based-pages/cheap-movers-near-me-worcester-section";
import cheapremovalsalberton from "./pages/location-based-pages/cheap-removals-alberton-section";
import cheapremovalsaliwalnorth from "./pages/location-based-pages/cheap-removals-aliwal-north-section";
import cheapremovalsbeaufortwest from "./pages/location-based-pages/cheap-removals-beaufort-west-section";
import cheapremovalsbenoni from "./pages/location-based-pages/cheap-removals-benoni-section";
import cheapremovalsbethal from "./pages/location-based-pages/cheap-removals-bethal-section";
import cheapremovalsbhisho from "./pages/location-based-pages/cheap-removals-bhisho-section";
import cheapremovalsbloemfontein from "./pages/location-based-pages/cheap-removals-bloemfontein-section";
import cheapremovalsbrakpan from "./pages/location-based-pages/cheap-removals-brakpan-section";
import cheapremovalsbrits from "./pages/location-based-pages/cheap-removals-brits-section";
import cheapremovalscapetown from "./pages/location-based-pages/cheap-removals-cape-town-section";
import cheapremovalscarletonville from "./pages/location-based-pages/cheap-removals-carletonville-section";
import cheapremovalscenturion from "./pages/location-based-pages/cheap-removals-centurion-section";
import cheapremovalscradock from "./pages/location-based-pages/cheap-removals-cradock-section";
import cheapremovalsdeaar from "./pages/location-based-pages/cheap-removals-de-aar-section";
import cheapremovalsdurban from "./pages/location-based-pages/cheap-removals-durban-section";
import cheapremovalseastlondon from "./pages/location-based-pages/cheap-removals-east-london-section";
import cheapremovalsferndale from "./pages/location-based-pages/cheap-removals-ferndale-section";
import cheapremovalsgeorge from "./pages/location-based-pages/cheap-removals-george-section";
import cheapremovalsgraaffreinet from "./pages/location-based-pages/cheap-removals-graaff-reinet-section";
import cheapremovalsgrahamstown from "./pages/location-based-pages/cheap-removals-grahamstown-section";
import cheapremovalshermanus from "./pages/location-based-pages/cheap-removals-hermanus-section";
import cheapremovalsjeffreysbay from "./pages/location-based-pages/cheap-removals-jeffreys-bay-section";
import cheapremovalsjohannesburg from "./pages/location-based-pages/cheap-removals-johannesburg-section";
import cheapremovalskimberley from "./pages/location-based-pages/cheap-removals-kimberley-section";
import cheapremovalsklerksdorp from "./pages/location-based-pages/cheap-removals-klerksdorp-section";
import cheapremovalsknysna from "./pages/location-based-pages/cheap-removals-knysna-section";
import cheapremovalskroonstad from "./pages/location-based-pages/cheap-removals-kroonstad-section";
import cheapremovalskuilsrivier from "./pages/location-based-pages/cheap-removals-kuilsrivier-section";
import cheapremovalsladysmith from "./pages/location-based-pages/cheap-removals-ladysmith-section";
import cheapremovalslebowakgomo from "./pages/location-based-pages/cheap-removals-lebowakgomo-section";
import cheapremovalslichtenburg from "./pages/location-based-pages/cheap-removals-lichtenburg-section";
import cheapremovalsmahikeng from "./pages/location-based-pages/cheap-removals-mahikeng-section";
import cheapremovalsmalmesbury from "./pages/location-based-pages/cheap-removals-malmesbury-section";
import cheapremovalsmiddelburg from "./pages/location-based-pages/cheap-removals-middelburg-section";
import cheapremovalsmidrand from "./pages/location-based-pages/cheap-removals-midrand-section";
import cheapremovalsmmabatho from "./pages/location-based-pages/cheap-removals-mmabatho-section";
import cheapremovalsmosselbay from "./pages/location-based-pages/cheap-removals-mossel-bay-section";
import cheapremovalsmusina from "./pages/location-based-pages/cheap-removals-musina-section";
import cheapremovalsnelspruit from "./pages/location-based-pages/cheap-removals-nelspruit-section";
import cheapremovalsoudtshoorn from "./pages/location-based-pages/cheap-removals-oudtshoorn-section";
import cheapremovalspaarl from "./pages/location-based-pages/cheap-removals-paarl-section";
import cheapremovalspietermaritzburg from "./pages/location-based-pages/cheap-removals-pietermaritzburg-section";
import cheapremovalspolokwane from "./pages/location-based-pages/cheap-removals-polokwane-section";
import cheapremovalsportelizabeth from "./pages/location-based-pages/cheap-removals-port-elizabeth-section";
import cheapremovalsportshepstone from "./pages/location-based-pages/cheap-removals-port-shepstone-section";
import cheapremovalspotchefstroom from "./pages/location-based-pages/cheap-removals-potchefstroom-section";
import cheapremovalspretoria from "./pages/location-based-pages/cheap-removals-pretoria-section";
import cheapremovalsqueenstown from "./pages/location-based-pages/cheap-removals-queenstown-section";
import cheapremovalsrandburg from "./pages/location-based-pages/cheap-removals-randburg-section";
import cheapremovalsroodepoort from "./pages/location-based-pages/cheap-removals-roodepoort-section";
import cheapremovalsrustenburg from "./pages/location-based-pages/cheap-removals-rustenburg-section";
import cheapremovalssaldanha from "./pages/location-based-pages/cheap-removals-saldanha-section";
import cheapremovalssandton from "./pages/location-based-pages/cheap-removals-sandton-section";
import cheapremovalssoweto from "./pages/location-based-pages/cheap-removals-soweto-section";
import cheapremovalssprings from "./pages/location-based-pages/cheap-removals-springs-section";
import cheapremovalsstanderton from "./pages/location-based-pages/cheap-removals-standerton-section";
import cheapremovalsthohoyandou from "./pages/location-based-pages/cheap-removals-thohoyandou-section";
import cheapremovalstzaneen from "./pages/location-based-pages/cheap-removals-tzaneen-section";
import cheapremovalsuitenhage from "./pages/location-based-pages/cheap-removals-uitenhage-section";
import cheapremovalsulundi from "./pages/location-based-pages/cheap-removals-ulundi-section";
import cheapremovalsumtata from "./pages/location-based-pages/cheap-removals-umtata-section";
import cheapremovalsupington from "./pages/location-based-pages/cheap-removals-upington-section";
import cheapremovalsvanderbijlpark from "./pages/location-based-pages/cheap-removals-vanderbijlpark-section";
import cheapremovalsvereeniging from "./pages/location-based-pages/cheap-removals-vereeniging-section";
import cheapremovalsvolksrust from "./pages/location-based-pages/cheap-removals-volksrust-section";
import cheapremovalsvredenburg from "./pages/location-based-pages/cheap-removals-vredenburg-section";
import cheapremovalsvryburg from "./pages/location-based-pages/cheap-removals-vryburg-section";
import cheapremovalsvryheid from "./pages/location-based-pages/cheap-removals-vryheid-section";
import cheapremovalswelkom from "./pages/location-based-pages/cheap-removals-welkom-section";
import cheapremovalsworcester from "./pages/location-based-pages/cheap-removals-worcester-section";
import cheaptransportationalberton from "./pages/location-based-pages/cheap-transportation-alberton-section";
import cheaptransportationaliwalnorth from "./pages/location-based-pages/cheap-transportation-aliwal-north-section";
import cheaptransportationbeaufortwest from "./pages/location-based-pages/cheap-transportation-beaufort-west-section";
import cheaptransportationbenoni from "./pages/location-based-pages/cheap-transportation-benoni-section";
import cheaptransportationbethal from "./pages/location-based-pages/cheap-transportation-bethal-section";
import cheaptransportationbhisho from "./pages/location-based-pages/cheap-transportation-bhisho-section";
import cheaptransportationbloemfontein from "./pages/location-based-pages/cheap-transportation-bloemfontein-section";
import cheaptransportationbrakpan from "./pages/location-based-pages/cheap-transportation-brakpan-section";
import cheaptransportationbrits from "./pages/location-based-pages/cheap-transportation-brits-section";
import cheaptransportationcapetown from "./pages/location-based-pages/cheap-transportation-cape-town-section";
import cheaptransportationcarletonville from "./pages/location-based-pages/cheap-transportation-carletonville-section";
import cheaptransportationcenturion from "./pages/location-based-pages/cheap-transportation-centurion-section";
import cheaptransportationcradock from "./pages/location-based-pages/cheap-transportation-cradock-section";
import cheaptransportationdeaar from "./pages/location-based-pages/cheap-transportation-de-aar-section";
import cheaptransportationdurban from "./pages/location-based-pages/cheap-transportation-durban-section";
import cheaptransportationeastlondon from "./pages/location-based-pages/cheap-transportation-east-london-section";
import cheaptransportationferndale from "./pages/location-based-pages/cheap-transportation-ferndale-section";
import cheaptransportationgeorge from "./pages/location-based-pages/cheap-transportation-george-section";
import cheaptransportationgraaffreinet from "./pages/location-based-pages/cheap-transportation-graaff-reinet-section";
import cheaptransportationgrahamstown from "./pages/location-based-pages/cheap-transportation-grahamstown-section";
import cheaptransportationhermanus from "./pages/location-based-pages/cheap-transportation-hermanus-section";
import cheaptransportationjeffreysbay from "./pages/location-based-pages/cheap-transportation-jeffreys-bay-section";
import cheaptransportationjohannesburg from "./pages/location-based-pages/cheap-transportation-johannesburg-section";
import cheaptransportationkimberley from "./pages/location-based-pages/cheap-transportation-kimberley-section";
import cheaptransportationklerksdorp from "./pages/location-based-pages/cheap-transportation-klerksdorp-section";
import cheaptransportationknysna from "./pages/location-based-pages/cheap-transportation-knysna-section";
import cheaptransportationkroonstad from "./pages/location-based-pages/cheap-transportation-kroonstad-section";
import cheaptransportationkuilsrivier from "./pages/location-based-pages/cheap-transportation-kuilsrivier-section";
import cheaptransportationladysmith from "./pages/location-based-pages/cheap-transportation-ladysmith-section";
import cheaptransportationlebowakgomo from "./pages/location-based-pages/cheap-transportation-lebowakgomo-section";
import cheaptransportationlichtenburg from "./pages/location-based-pages/cheap-transportation-lichtenburg-section";
import cheaptransportationmahikeng from "./pages/location-based-pages/cheap-transportation-mahikeng-section";
import cheaptransportationmalmesbury from "./pages/location-based-pages/cheap-transportation-malmesbury-section";
import cheaptransportationmiddelburg from "./pages/location-based-pages/cheap-transportation-middelburg-section";
import cheaptransportationmidrand from "./pages/location-based-pages/cheap-transportation-midrand-section";
import cheaptransportationmmabatho from "./pages/location-based-pages/cheap-transportation-mmabatho-section";
import cheaptransportationmosselbay from "./pages/location-based-pages/cheap-transportation-mossel-bay-section";
import cheaptransportationmusina from "./pages/location-based-pages/cheap-transportation-musina-section";
import cheaptransportationnelspruit from "./pages/location-based-pages/cheap-transportation-nelspruit-section";
import cheaptransportationoudtshoorn from "./pages/location-based-pages/cheap-transportation-oudtshoorn-section";
import cheaptransportationpaarl from "./pages/location-based-pages/cheap-transportation-paarl-section";
import cheaptransportationpietermaritzburg from "./pages/location-based-pages/cheap-transportation-pietermaritzburg-section";
import cheaptransportationpolokwane from "./pages/location-based-pages/cheap-transportation-polokwane-section";
import cheaptransportationportelizabeth from "./pages/location-based-pages/cheap-transportation-port-elizabeth-section";
import cheaptransportationportshepstone from "./pages/location-based-pages/cheap-transportation-port-shepstone-section";
import cheaptransportationpotchefstroom from "./pages/location-based-pages/cheap-transportation-potchefstroom-section";
import cheaptransportationpretoria from "./pages/location-based-pages/cheap-transportation-pretoria-section";
import cheaptransportationqueenstown from "./pages/location-based-pages/cheap-transportation-queenstown-section";
import cheaptransportationrandburg from "./pages/location-based-pages/cheap-transportation-randburg-section";
import cheaptransportationroodepoort from "./pages/location-based-pages/cheap-transportation-roodepoort-section";
import cheaptransportationrustenburg from "./pages/location-based-pages/cheap-transportation-rustenburg-section";
import cheaptransportationsaldanha from "./pages/location-based-pages/cheap-transportation-saldanha-section";
import cheaptransportationsandton from "./pages/location-based-pages/cheap-transportation-sandton-section";
import cheaptransportationsoweto from "./pages/location-based-pages/cheap-transportation-soweto-section";
import cheaptransportationsprings from "./pages/location-based-pages/cheap-transportation-springs-section";
import cheaptransportationstanderton from "./pages/location-based-pages/cheap-transportation-standerton-section";
import cheaptransportationthohoyandou from "./pages/location-based-pages/cheap-transportation-thohoyandou-section";
import cheaptransportationtzaneen from "./pages/location-based-pages/cheap-transportation-tzaneen-section";
import cheaptransportationuitenhage from "./pages/location-based-pages/cheap-transportation-uitenhage-section";
import cheaptransportationulundi from "./pages/location-based-pages/cheap-transportation-ulundi-section";
import cheaptransportationumtata from "./pages/location-based-pages/cheap-transportation-umtata-section";
import cheaptransportationupington from "./pages/location-based-pages/cheap-transportation-upington-section";
import cheaptransportationvanderbijlpark from "./pages/location-based-pages/cheap-transportation-vanderbijlpark-section";
import cheaptransportationvereeniging from "./pages/location-based-pages/cheap-transportation-vereeniging-section";
import cheaptransportationvolksrust from "./pages/location-based-pages/cheap-transportation-volksrust-section";
import cheaptransportationvredenburg from "./pages/location-based-pages/cheap-transportation-vredenburg-section";
import cheaptransportationvryburg from "./pages/location-based-pages/cheap-transportation-vryburg-section";
import cheaptransportationvryheid from "./pages/location-based-pages/cheap-transportation-vryheid-section";
import cheaptransportationwelkom from "./pages/location-based-pages/cheap-transportation-welkom-section";
import cheaptransportationworcester from "./pages/location-based-pages/cheap-transportation-worcester-section";
import courieralberton from "./pages/location-based-pages/courier--alberton-section";
import courieraliwalnorth from "./pages/location-based-pages/courier--aliwal-north-section";
import courierbeaufortwest from "./pages/location-based-pages/courier--beaufort-west-section";
import courierbenoni from "./pages/location-based-pages/courier--benoni-section";
import courierbethal from "./pages/location-based-pages/courier--bethal-section";
import courierbhisho from "./pages/location-based-pages/courier--bhisho-section";
import courierbloemfontein from "./pages/location-based-pages/courier--bloemfontein-section";
import courierbrakpan from "./pages/location-based-pages/courier--brakpan-section";
import courierbrits from "./pages/location-based-pages/courier--brits-section";
import couriercapetown from "./pages/location-based-pages/courier--cape-town-section";
import couriercarletonville from "./pages/location-based-pages/courier--carletonville-section";
import couriercenturion from "./pages/location-based-pages/courier--centurion-section";
import couriercradock from "./pages/location-based-pages/courier--cradock-section";
import courierdeaar from "./pages/location-based-pages/courier--de-aar-section";
import courierdurban from "./pages/location-based-pages/courier--durban-section";
import couriereastlondon from "./pages/location-based-pages/courier--east-london-section";
import courierferndale from "./pages/location-based-pages/courier--ferndale-section";
import couriergeorge from "./pages/location-based-pages/courier--george-section";
import couriergraaffreinet from "./pages/location-based-pages/courier--graaff-reinet-section";
import couriergrahamstown from "./pages/location-based-pages/courier--grahamstown-section";
import courierhermanus from "./pages/location-based-pages/courier--hermanus-section";
import courierjeffreysbay from "./pages/location-based-pages/courier--jeffreys-bay-section";
import courierjohannesburg from "./pages/location-based-pages/courier--johannesburg-section";
import courierkimberley from "./pages/location-based-pages/courier--kimberley-section";
import courierklerksdorp from "./pages/location-based-pages/courier--klerksdorp-section";
import courierknysna from "./pages/location-based-pages/courier--knysna-section";
import courierkroonstad from "./pages/location-based-pages/courier--kroonstad-section";
import courierkuilsrivier from "./pages/location-based-pages/courier--kuilsrivier-section";
import courierladysmith from "./pages/location-based-pages/courier--ladysmith-section";
import courierlebowakgomo from "./pages/location-based-pages/courier--lebowakgomo-section";
import courierlichtenburg from "./pages/location-based-pages/courier--lichtenburg-section";
import couriermahikeng from "./pages/location-based-pages/courier--mahikeng-section";
import couriermalmesbury from "./pages/location-based-pages/courier--malmesbury-section";
import couriermiddelburg from "./pages/location-based-pages/courier--middelburg-section";
import couriermidrand from "./pages/location-based-pages/courier--midrand-section";
import couriermmabatho from "./pages/location-based-pages/courier--mmabatho-section";
import couriermosselbay from "./pages/location-based-pages/courier--mossel-bay-section";
import couriermusina from "./pages/location-based-pages/courier--musina-section";
import couriernelspruit from "./pages/location-based-pages/courier--nelspruit-section";
import courieroudtshoorn from "./pages/location-based-pages/courier--oudtshoorn-section";
import courierpaarl from "./pages/location-based-pages/courier--paarl-section";
import courierpietermaritzburg from "./pages/location-based-pages/courier--pietermaritzburg-section";
import courierpolokwane from "./pages/location-based-pages/courier--polokwane-section";
import courierportelizabeth from "./pages/location-based-pages/courier--port-elizabeth-section";
import courierportshepstone from "./pages/location-based-pages/courier--port-shepstone-section";
import courierpotchefstroom from "./pages/location-based-pages/courier--potchefstroom-section";
import courierpretoria from "./pages/location-based-pages/courier--pretoria-section";
import courierqueenstown from "./pages/location-based-pages/courier--queenstown-section";
import courierrandburg from "./pages/location-based-pages/courier--randburg-section";
import courierroodepoort from "./pages/location-based-pages/courier--roodepoort-section";
import courierrustenburg from "./pages/location-based-pages/courier--rustenburg-section";
import couriersaldanha from "./pages/location-based-pages/courier--saldanha-section";
import couriersandton from "./pages/location-based-pages/courier--sandton-section";
import couriersoweto from "./pages/location-based-pages/courier--soweto-section";
import couriersprings from "./pages/location-based-pages/courier--springs-section";
import courierstanderton from "./pages/location-based-pages/courier--standerton-section";
import courierthohoyandou from "./pages/location-based-pages/courier--thohoyandou-section";
import couriertzaneen from "./pages/location-based-pages/courier--tzaneen-section";
import courieruitenhage from "./pages/location-based-pages/courier--uitenhage-section";
import courierulundi from "./pages/location-based-pages/courier--ulundi-section";
import courierumtata from "./pages/location-based-pages/courier--umtata-section";
import courierupington from "./pages/location-based-pages/courier--upington-section";
import couriervanderbijlpark from "./pages/location-based-pages/courier--vanderbijlpark-section";
import couriervereeniging from "./pages/location-based-pages/courier--vereeniging-section";
import couriervolksrust from "./pages/location-based-pages/courier--volksrust-section";
import couriervredenburg from "./pages/location-based-pages/courier--vredenburg-section";
import couriervryburg from "./pages/location-based-pages/courier--vryburg-section";
import couriervryheid from "./pages/location-based-pages/courier--vryheid-section";
import courierwelkom from "./pages/location-based-pages/courier--welkom-section";
import courierworcester from "./pages/location-based-pages/courier--worcester-section";
import deliveryalberton from "./pages/location-based-pages/delivery-alberton-section";
import deliveryaliwalnorth from "./pages/location-based-pages/delivery-aliwal-north-section";
import deliverybeaufortwest from "./pages/location-based-pages/delivery-beaufort-west-section";
import deliverybenoni from "./pages/location-based-pages/delivery-benoni-section";
import deliverybethal from "./pages/location-based-pages/delivery-bethal-section";
import deliverybhisho from "./pages/location-based-pages/delivery-bhisho-section";
import deliverybloemfontein from "./pages/location-based-pages/delivery-bloemfontein-section";
import deliverybrakpan from "./pages/location-based-pages/delivery-brakpan-section";
import deliverybrits from "./pages/location-based-pages/delivery-brits-section";
import deliverycapetown from "./pages/location-based-pages/delivery-cape-town-section";
import deliverycarletonville from "./pages/location-based-pages/delivery-carletonville-section";
import deliverycenturion from "./pages/location-based-pages/delivery-centurion-section";
import deliverycradock from "./pages/location-based-pages/delivery-cradock-section";
import deliverydeaar from "./pages/location-based-pages/delivery-de-aar-section";
import deliverydurban from "./pages/location-based-pages/delivery-durban-section";
import deliveryeastlondon from "./pages/location-based-pages/delivery-east-london-section";
import deliveryferndale from "./pages/location-based-pages/delivery-ferndale-section";
import deliverygeorge from "./pages/location-based-pages/delivery-george-section";
import deliverygraaffreinet from "./pages/location-based-pages/delivery-graaff-reinet-section";
import deliverygrahamstown from "./pages/location-based-pages/delivery-grahamstown-section";
import deliveryhermanus from "./pages/location-based-pages/delivery-hermanus-section";
import deliveryjeffreysbay from "./pages/location-based-pages/delivery-jeffreys-bay-section";
import deliveryjohannesburg from "./pages/location-based-pages/delivery-johannesburg-section";
import deliverykimberley from "./pages/location-based-pages/delivery-kimberley-section";
import deliveryklerksdorp from "./pages/location-based-pages/delivery-klerksdorp-section";
import deliveryknysna from "./pages/location-based-pages/delivery-knysna-section";
import deliverykroonstad from "./pages/location-based-pages/delivery-kroonstad-section";
import deliverykuilsrivier from "./pages/location-based-pages/delivery-kuilsrivier-section";
import deliveryladysmith from "./pages/location-based-pages/delivery-ladysmith-section";
import deliverylebowakgomo from "./pages/location-based-pages/delivery-lebowakgomo-section";
import deliverylichtenburg from "./pages/location-based-pages/delivery-lichtenburg-section";
import deliverymahikeng from "./pages/location-based-pages/delivery-mahikeng-section";
import deliverymalmesbury from "./pages/location-based-pages/delivery-malmesbury-section";
import deliverymiddelburg from "./pages/location-based-pages/delivery-middelburg-section";
import deliverymidrand from "./pages/location-based-pages/delivery-midrand-section";
import deliverymmabatho from "./pages/location-based-pages/delivery-mmabatho-section";
import deliverymosselbay from "./pages/location-based-pages/delivery-mossel-bay-section";
import deliverymusina from "./pages/location-based-pages/delivery-musina-section";
import deliverynelspruit from "./pages/location-based-pages/delivery-nelspruit-section";
import deliveryoudtshoorn from "./pages/location-based-pages/delivery-oudtshoorn-section";
import deliverypaarl from "./pages/location-based-pages/delivery-paarl-section";
import deliverypietermaritzburg from "./pages/location-based-pages/delivery-pietermaritzburg-section";
import deliverypolokwane from "./pages/location-based-pages/delivery-polokwane-section";
import deliveryportelizabeth from "./pages/location-based-pages/delivery-port-elizabeth-section";
import deliveryportshepstone from "./pages/location-based-pages/delivery-port-shepstone-section";
import deliverypotchefstroom from "./pages/location-based-pages/delivery-potchefstroom-section";
import deliverypretoria from "./pages/location-based-pages/delivery-pretoria-section";
import deliveryqueenstown from "./pages/location-based-pages/delivery-queenstown-section";
import deliveryrandburg from "./pages/location-based-pages/delivery-randburg-section";
import deliveryroodepoort from "./pages/location-based-pages/delivery-roodepoort-section";
import deliveryrustenburg from "./pages/location-based-pages/delivery-rustenburg-section";
import deliverysaldanha from "./pages/location-based-pages/delivery-saldanha-section";
import deliverysandton from "./pages/location-based-pages/delivery-sandton-section";
import deliverysoweto from "./pages/location-based-pages/delivery-soweto-section";
import deliverysprings from "./pages/location-based-pages/delivery-springs-section";
import deliverystanderton from "./pages/location-based-pages/delivery-standerton-section";
import deliverythohoyandou from "./pages/location-based-pages/delivery-thohoyandou-section";
import deliverytzaneen from "./pages/location-based-pages/delivery-tzaneen-section";
import deliveryuitenhage from "./pages/location-based-pages/delivery-uitenhage-section";
import deliveryulundi from "./pages/location-based-pages/delivery-ulundi-section";
import deliveryumtata from "./pages/location-based-pages/delivery-umtata-section";
import deliveryupington from "./pages/location-based-pages/delivery-upington-section";
import deliveryvanderbijlpark from "./pages/location-based-pages/delivery-vanderbijlpark-section";
import deliveryvereeniging from "./pages/location-based-pages/delivery-vereeniging-section";
import deliveryvolksrust from "./pages/location-based-pages/delivery-volksrust-section";
import deliveryvredenburg from "./pages/location-based-pages/delivery-vredenburg-section";
import deliveryvryburg from "./pages/location-based-pages/delivery-vryburg-section";
import deliveryvryheid from "./pages/location-based-pages/delivery-vryheid-section";
import deliverywelkom from "./pages/location-based-pages/delivery-welkom-section";
import deliveryworcester from "./pages/location-based-pages/delivery-worcester-section";
import doortodoordeliveryalberton from "./pages/location-based-pages/door-to-door-delivery-alberton-section";
import doortodoordeliveryaliwalnorth from "./pages/location-based-pages/door-to-door-delivery-aliwal-north-section";
import doortodoordeliverybeaufortwest from "./pages/location-based-pages/door-to-door-delivery-beaufort-west-section";
import doortodoordeliverybenoni from "./pages/location-based-pages/door-to-door-delivery-benoni-section";
import doortodoordeliverybethal from "./pages/location-based-pages/door-to-door-delivery-bethal-section";
import doortodoordeliverybhisho from "./pages/location-based-pages/door-to-door-delivery-bhisho-section";
import doortodoordeliverybloemfontein from "./pages/location-based-pages/door-to-door-delivery-bloemfontein-section";
import doortodoordeliverybrakpan from "./pages/location-based-pages/door-to-door-delivery-brakpan-section";
import doortodoordeliverybrits from "./pages/location-based-pages/door-to-door-delivery-brits-section";
import doortodoordeliverycapetown from "./pages/location-based-pages/door-to-door-delivery-cape-town-section";
import doortodoordeliverycarletonville from "./pages/location-based-pages/door-to-door-delivery-carletonville-section";
import doortodoordeliverycenturion from "./pages/location-based-pages/door-to-door-delivery-centurion-section";
import doortodoordeliverycradock from "./pages/location-based-pages/door-to-door-delivery-cradock-section";
import doortodoordeliverydeaar from "./pages/location-based-pages/door-to-door-delivery-de-aar-section";
import doortodoordeliverydurban from "./pages/location-based-pages/door-to-door-delivery-durban-section";
import doortodoordeliveryeastlondon from "./pages/location-based-pages/door-to-door-delivery-east-london-section";
import doortodoordeliveryferndale from "./pages/location-based-pages/door-to-door-delivery-ferndale-section";
import doortodoordeliverygeorge from "./pages/location-based-pages/door-to-door-delivery-george-section";
import doortodoordeliverygraaffreinet from "./pages/location-based-pages/door-to-door-delivery-graaff-reinet-section";
import doortodoordeliverygrahamstown from "./pages/location-based-pages/door-to-door-delivery-grahamstown-section";
import doortodoordeliveryhermanus from "./pages/location-based-pages/door-to-door-delivery-hermanus-section";
import doortodoordeliveryjeffreysbay from "./pages/location-based-pages/door-to-door-delivery-jeffreys-bay-section";
import doortodoordeliveryjohannesburg from "./pages/location-based-pages/door-to-door-delivery-johannesburg-section";
import doortodoordeliverykimberley from "./pages/location-based-pages/door-to-door-delivery-kimberley-section";
import doortodoordeliveryklerksdorp from "./pages/location-based-pages/door-to-door-delivery-klerksdorp-section";
import doortodoordeliveryknysna from "./pages/location-based-pages/door-to-door-delivery-knysna-section";
import doortodoordeliverykroonstad from "./pages/location-based-pages/door-to-door-delivery-kroonstad-section";
import doortodoordeliverykuilsrivier from "./pages/location-based-pages/door-to-door-delivery-kuilsrivier-section";
import doortodoordeliveryladysmith from "./pages/location-based-pages/door-to-door-delivery-ladysmith-section";
import doortodoordeliverylebowakgomo from "./pages/location-based-pages/door-to-door-delivery-lebowakgomo-section";
import doortodoordeliverylichtenburg from "./pages/location-based-pages/door-to-door-delivery-lichtenburg-section";
import doortodoordeliverymahikeng from "./pages/location-based-pages/door-to-door-delivery-mahikeng-section";
import doortodoordeliverymalmesbury from "./pages/location-based-pages/door-to-door-delivery-malmesbury-section";
import doortodoordeliverymiddelburg from "./pages/location-based-pages/door-to-door-delivery-middelburg-section";
import doortodoordeliverymidrand from "./pages/location-based-pages/door-to-door-delivery-midrand-section";
import doortodoordeliverymmabatho from "./pages/location-based-pages/door-to-door-delivery-mmabatho-section";
import doortodoordeliverymosselbay from "./pages/location-based-pages/door-to-door-delivery-mossel-bay-section";
import doortodoordeliverymusina from "./pages/location-based-pages/door-to-door-delivery-musina-section";
import doortodoordeliverynelspruit from "./pages/location-based-pages/door-to-door-delivery-nelspruit-section";
import doortodoordeliveryoudtshoorn from "./pages/location-based-pages/door-to-door-delivery-oudtshoorn-section";
import doortodoordeliverypaarl from "./pages/location-based-pages/door-to-door-delivery-paarl-section";
import doortodoordeliverypietermaritzburg from "./pages/location-based-pages/door-to-door-delivery-pietermaritzburg-section";
import doortodoordeliverypolokwane from "./pages/location-based-pages/door-to-door-delivery-polokwane-section";
import doortodoordeliveryportelizabeth from "./pages/location-based-pages/door-to-door-delivery-port-elizabeth-section";
import doortodoordeliveryportshepstone from "./pages/location-based-pages/door-to-door-delivery-port-shepstone-section";
import doortodoordeliverypotchefstroom from "./pages/location-based-pages/door-to-door-delivery-potchefstroom-section";
import doortodoordeliverypretoria from "./pages/location-based-pages/door-to-door-delivery-pretoria-section";
import doortodoordeliveryqueenstown from "./pages/location-based-pages/door-to-door-delivery-queenstown-section";
import doortodoordeliveryrandburg from "./pages/location-based-pages/door-to-door-delivery-randburg-section";
import doortodoordeliveryroodepoort from "./pages/location-based-pages/door-to-door-delivery-roodepoort-section";
import doortodoordeliveryrustenburg from "./pages/location-based-pages/door-to-door-delivery-rustenburg-section";
import doortodoordeliverysaldanha from "./pages/location-based-pages/door-to-door-delivery-saldanha-section";
import doortodoordeliverysandton from "./pages/location-based-pages/door-to-door-delivery-sandton-section";
import doortodoordeliverysoweto from "./pages/location-based-pages/door-to-door-delivery-soweto-section";
import doortodoordeliverysprings from "./pages/location-based-pages/door-to-door-delivery-springs-section";
import doortodoordeliverystanderton from "./pages/location-based-pages/door-to-door-delivery-standerton-section";
import doortodoordeliverythohoyandou from "./pages/location-based-pages/door-to-door-delivery-thohoyandou-section";
import doortodoordeliverytzaneen from "./pages/location-based-pages/door-to-door-delivery-tzaneen-section";
import doortodoordeliveryuitenhage from "./pages/location-based-pages/door-to-door-delivery-uitenhage-section";
import doortodoordeliveryulundi from "./pages/location-based-pages/door-to-door-delivery-ulundi-section";
import doortodoordeliveryumtata from "./pages/location-based-pages/door-to-door-delivery-umtata-section";
import doortodoordeliveryupington from "./pages/location-based-pages/door-to-door-delivery-upington-section";
import doortodoordeliveryvanderbijlpark from "./pages/location-based-pages/door-to-door-delivery-vanderbijlpark-section";
import doortodoordeliveryvereeniging from "./pages/location-based-pages/door-to-door-delivery-vereeniging-section";
import doortodoordeliveryvolksrust from "./pages/location-based-pages/door-to-door-delivery-volksrust-section";
import doortodoordeliveryvredenburg from "./pages/location-based-pages/door-to-door-delivery-vredenburg-section";
import doortodoordeliveryvryburg from "./pages/location-based-pages/door-to-door-delivery-vryburg-section";
import doortodoordeliveryvryheid from "./pages/location-based-pages/door-to-door-delivery-vryheid-section";
import doortodoordeliverywelkom from "./pages/location-based-pages/door-to-door-delivery-welkom-section";
import doortodoordeliveryworcester from "./pages/location-based-pages/door-to-door-delivery-worcester-section";
import equipmenttransportalberton from "./pages/location-based-pages/equipment-transport-alberton-section";
import equipmenttransportaliwalnorth from "./pages/location-based-pages/equipment-transport-aliwal-north-section";
import equipmenttransportbeaufortwest from "./pages/location-based-pages/equipment-transport-beaufort-west-section";
import equipmenttransportbenoni from "./pages/location-based-pages/equipment-transport-benoni-section";
import equipmenttransportbethal from "./pages/location-based-pages/equipment-transport-bethal-section";
import equipmenttransportbhisho from "./pages/location-based-pages/equipment-transport-bhisho-section";
import equipmenttransportbloemfontein from "./pages/location-based-pages/equipment-transport-bloemfontein-section";
import equipmenttransportbrakpan from "./pages/location-based-pages/equipment-transport-brakpan-section";
import equipmenttransportbrits from "./pages/location-based-pages/equipment-transport-brits-section";
import equipmenttransportcapetown from "./pages/location-based-pages/equipment-transport-cape-town-section";
import equipmenttransportcarletonville from "./pages/location-based-pages/equipment-transport-carletonville-section";
import equipmenttransportcenturion from "./pages/location-based-pages/equipment-transport-centurion-section";
import equipmenttransportcradock from "./pages/location-based-pages/equipment-transport-cradock-section";
import equipmenttransportdeaar from "./pages/location-based-pages/equipment-transport-de-aar-section";
import equipmenttransportdurban from "./pages/location-based-pages/equipment-transport-durban-section";
import equipmenttransporteastlondon from "./pages/location-based-pages/equipment-transport-east-london-section";
import equipmenttransportferndale from "./pages/location-based-pages/equipment-transport-ferndale-section";
import equipmenttransportgeorge from "./pages/location-based-pages/equipment-transport-george-section";
import equipmenttransportgraaffreinet from "./pages/location-based-pages/equipment-transport-graaff-reinet-section";
import equipmenttransportgrahamstown from "./pages/location-based-pages/equipment-transport-grahamstown-section";
import equipmenttransporthermanus from "./pages/location-based-pages/equipment-transport-hermanus-section";
import equipmenttransportjeffreysbay from "./pages/location-based-pages/equipment-transport-jeffreys-bay-section";
import equipmenttransportjohannesburg from "./pages/location-based-pages/equipment-transport-johannesburg-section";
import equipmenttransportkimberley from "./pages/location-based-pages/equipment-transport-kimberley-section";
import equipmenttransportklerksdorp from "./pages/location-based-pages/equipment-transport-klerksdorp-section";
import equipmenttransportknysna from "./pages/location-based-pages/equipment-transport-knysna-section";
import equipmenttransportkroonstad from "./pages/location-based-pages/equipment-transport-kroonstad-section";
import equipmenttransportkuilsrivier from "./pages/location-based-pages/equipment-transport-kuilsrivier-section";
import equipmenttransportladysmith from "./pages/location-based-pages/equipment-transport-ladysmith-section";
import equipmenttransportlebowakgomo from "./pages/location-based-pages/equipment-transport-lebowakgomo-section";
import equipmenttransportlichtenburg from "./pages/location-based-pages/equipment-transport-lichtenburg-section";
import equipmenttransportmahikeng from "./pages/location-based-pages/equipment-transport-mahikeng-section";
import equipmenttransportmalmesbury from "./pages/location-based-pages/equipment-transport-malmesbury-section";
import equipmenttransportmiddelburg from "./pages/location-based-pages/equipment-transport-middelburg-section";
import equipmenttransportmidrand from "./pages/location-based-pages/equipment-transport-midrand-section";
import equipmenttransportmmabatho from "./pages/location-based-pages/equipment-transport-mmabatho-section";
import equipmenttransportmosselbay from "./pages/location-based-pages/equipment-transport-mossel-bay-section";
import equipmenttransportmusina from "./pages/location-based-pages/equipment-transport-musina-section";
import equipmenttransportnelspruit from "./pages/location-based-pages/equipment-transport-nelspruit-section";
import equipmenttransportoudtshoorn from "./pages/location-based-pages/equipment-transport-oudtshoorn-section";
import equipmenttransportpaarl from "./pages/location-based-pages/equipment-transport-paarl-section";
import equipmenttransportpietermaritzburg from "./pages/location-based-pages/equipment-transport-pietermaritzburg-section";
import equipmenttransportpolokwane from "./pages/location-based-pages/equipment-transport-polokwane-section";
import equipmenttransportportelizabeth from "./pages/location-based-pages/equipment-transport-port-elizabeth-section";
import equipmenttransportportshepstone from "./pages/location-based-pages/equipment-transport-port-shepstone-section";
import equipmenttransportpotchefstroom from "./pages/location-based-pages/equipment-transport-potchefstroom-section";
import equipmenttransportpretoria from "./pages/location-based-pages/equipment-transport-pretoria-section";
import equipmenttransportqueenstown from "./pages/location-based-pages/equipment-transport-queenstown-section";
import equipmenttransportrandburg from "./pages/location-based-pages/equipment-transport-randburg-section";
import equipmenttransportroodepoort from "./pages/location-based-pages/equipment-transport-roodepoort-section";
import equipmenttransportrustenburg from "./pages/location-based-pages/equipment-transport-rustenburg-section";
import equipmenttransportsaldanha from "./pages/location-based-pages/equipment-transport-saldanha-section";
import equipmenttransportsandton from "./pages/location-based-pages/equipment-transport-sandton-section";
import equipmenttransportsoweto from "./pages/location-based-pages/equipment-transport-soweto-section";
import equipmenttransportsprings from "./pages/location-based-pages/equipment-transport-springs-section";
import equipmenttransportstanderton from "./pages/location-based-pages/equipment-transport-standerton-section";
import equipmenttransportthohoyandou from "./pages/location-based-pages/equipment-transport-thohoyandou-section";
import equipmenttransporttzaneen from "./pages/location-based-pages/equipment-transport-tzaneen-section";
import equipmenttransportuitenhage from "./pages/location-based-pages/equipment-transport-uitenhage-section";
import equipmenttransportulundi from "./pages/location-based-pages/equipment-transport-ulundi-section";
import equipmenttransportumtata from "./pages/location-based-pages/equipment-transport-umtata-section";
import equipmenttransportupington from "./pages/location-based-pages/equipment-transport-upington-section";
import equipmenttransportvanderbijlpark from "./pages/location-based-pages/equipment-transport-vanderbijlpark-section";
import equipmenttransportvereeniging from "./pages/location-based-pages/equipment-transport-vereeniging-section";
import equipmenttransportvolksrust from "./pages/location-based-pages/equipment-transport-volksrust-section";
import equipmenttransportvredenburg from "./pages/location-based-pages/equipment-transport-vredenburg-section";
import equipmenttransportvryburg from "./pages/location-based-pages/equipment-transport-vryburg-section";
import equipmenttransportvryheid from "./pages/location-based-pages/equipment-transport-vryheid-section";
import equipmenttransportwelkom from "./pages/location-based-pages/equipment-transport-welkom-section";
import equipmenttransportworcester from "./pages/location-based-pages/equipment-transport-worcester-section";
import expressdeliveryalberton from "./pages/location-based-pages/express-delivery-alberton-section";
import expressdeliveryaliwalnorth from "./pages/location-based-pages/express-delivery-aliwal-north-section";
import expressdeliverybeaufortwest from "./pages/location-based-pages/express-delivery-beaufort-west-section";
import expressdeliverybenoni from "./pages/location-based-pages/express-delivery-benoni-section";
import expressdeliverybethal from "./pages/location-based-pages/express-delivery-bethal-section";
import expressdeliverybhisho from "./pages/location-based-pages/express-delivery-bhisho-section";
import expressdeliverybloemfontein from "./pages/location-based-pages/express-delivery-bloemfontein-section";
import expressdeliverybrakpan from "./pages/location-based-pages/express-delivery-brakpan-section";
import expressdeliverybrits from "./pages/location-based-pages/express-delivery-brits-section";
import expressdeliverycapetown from "./pages/location-based-pages/express-delivery-cape-town-section";
import expressdeliverycarletonville from "./pages/location-based-pages/express-delivery-carletonville-section";
import expressdeliverycenturion from "./pages/location-based-pages/express-delivery-centurion-section";
import expressdeliverycradock from "./pages/location-based-pages/express-delivery-cradock-section";
import expressdeliverydeaar from "./pages/location-based-pages/express-delivery-de-aar-section";
import expressdeliverydurban from "./pages/location-based-pages/express-delivery-durban-section";
import expressdeliveryeastlondon from "./pages/location-based-pages/express-delivery-east-london-section";
import expressdeliveryferndale from "./pages/location-based-pages/express-delivery-ferndale-section";
import expressdeliverygeorge from "./pages/location-based-pages/express-delivery-george-section";
import expressdeliverygraaffreinet from "./pages/location-based-pages/express-delivery-graaff-reinet-section";
import expressdeliverygrahamstown from "./pages/location-based-pages/express-delivery-grahamstown-section";
import expressdeliveryhermanus from "./pages/location-based-pages/express-delivery-hermanus-section";
import expressdeliveryjeffreysbay from "./pages/location-based-pages/express-delivery-jeffreys-bay-section";
import expressdeliveryjohannesburg from "./pages/location-based-pages/express-delivery-johannesburg-section";
import expressdeliverykimberley from "./pages/location-based-pages/express-delivery-kimberley-section";
import expressdeliveryklerksdorp from "./pages/location-based-pages/express-delivery-klerksdorp-section";
import expressdeliveryknysna from "./pages/location-based-pages/express-delivery-knysna-section";
import expressdeliverykroonstad from "./pages/location-based-pages/express-delivery-kroonstad-section";
import expressdeliverykuilsrivier from "./pages/location-based-pages/express-delivery-kuilsrivier-section";
import expressdeliveryladysmith from "./pages/location-based-pages/express-delivery-ladysmith-section";
import expressdeliverylebowakgomo from "./pages/location-based-pages/express-delivery-lebowakgomo-section";
import expressdeliverylichtenburg from "./pages/location-based-pages/express-delivery-lichtenburg-section";
import expressdeliverymahikeng from "./pages/location-based-pages/express-delivery-mahikeng-section";
import expressdeliverymalmesbury from "./pages/location-based-pages/express-delivery-malmesbury-section";
import expressdeliverymiddelburg from "./pages/location-based-pages/express-delivery-middelburg-section";
import expressdeliverymidrand from "./pages/location-based-pages/express-delivery-midrand-section";
import expressdeliverymmabatho from "./pages/location-based-pages/express-delivery-mmabatho-section";
import expressdeliverymosselbay from "./pages/location-based-pages/express-delivery-mossel-bay-section";
import expressdeliverymusina from "./pages/location-based-pages/express-delivery-musina-section";
import expressdeliverynelspruit from "./pages/location-based-pages/express-delivery-nelspruit-section";
import expressdeliveryoudtshoorn from "./pages/location-based-pages/express-delivery-oudtshoorn-section";
import expressdeliverypaarl from "./pages/location-based-pages/express-delivery-paarl-section";
import expressdeliverypietermaritzburg from "./pages/location-based-pages/express-delivery-pietermaritzburg-section";
import expressdeliverypolokwane from "./pages/location-based-pages/express-delivery-polokwane-section";
import expressdeliveryportelizabeth from "./pages/location-based-pages/express-delivery-port-elizabeth-section";
import expressdeliveryportshepstone from "./pages/location-based-pages/express-delivery-port-shepstone-section";
import expressdeliverypotchefstroom from "./pages/location-based-pages/express-delivery-potchefstroom-section";
import expressdeliverypretoria from "./pages/location-based-pages/express-delivery-pretoria-section";
import expressdeliveryqueenstown from "./pages/location-based-pages/express-delivery-queenstown-section";
import expressdeliveryrandburg from "./pages/location-based-pages/express-delivery-randburg-section";
import expressdeliveryroodepoort from "./pages/location-based-pages/express-delivery-roodepoort-section";
import expressdeliveryrustenburg from "./pages/location-based-pages/express-delivery-rustenburg-section";
import expressdeliverysaldanha from "./pages/location-based-pages/express-delivery-saldanha-section";
import expressdeliverysandton from "./pages/location-based-pages/express-delivery-sandton-section";
import expressdeliverysoweto from "./pages/location-based-pages/express-delivery-soweto-section";
import expressdeliverysprings from "./pages/location-based-pages/express-delivery-springs-section";
import expressdeliverystanderton from "./pages/location-based-pages/express-delivery-standerton-section";
import expressdeliverythohoyandou from "./pages/location-based-pages/express-delivery-thohoyandou-section";
import expressdeliverytzaneen from "./pages/location-based-pages/express-delivery-tzaneen-section";
import expressdeliveryuitenhage from "./pages/location-based-pages/express-delivery-uitenhage-section";
import expressdeliveryulundi from "./pages/location-based-pages/express-delivery-ulundi-section";
import expressdeliveryumtata from "./pages/location-based-pages/express-delivery-umtata-section";
import expressdeliveryupington from "./pages/location-based-pages/express-delivery-upington-section";
import expressdeliveryvanderbijlpark from "./pages/location-based-pages/express-delivery-vanderbijlpark-section";
import expressdeliveryvereeniging from "./pages/location-based-pages/express-delivery-vereeniging-section";
import expressdeliveryvolksrust from "./pages/location-based-pages/express-delivery-volksrust-section";
import expressdeliveryvredenburg from "./pages/location-based-pages/express-delivery-vredenburg-section";
import expressdeliveryvryburg from "./pages/location-based-pages/express-delivery-vryburg-section";
import expressdeliveryvryheid from "./pages/location-based-pages/express-delivery-vryheid-section";
import expressdeliverywelkom from "./pages/location-based-pages/express-delivery-welkom-section";
import expressdeliveryworcester from "./pages/location-based-pages/express-delivery-worcester-section";
import flexibledeliveryoptionsalberton from "./pages/location-based-pages/flexible-delivery-options-alberton-section";
import flexibledeliveryoptionsaliwalnorth from "./pages/location-based-pages/flexible-delivery-options-aliwal-north-section";
import flexibledeliveryoptionsbeaufortwest from "./pages/location-based-pages/flexible-delivery-options-beaufort-west-section";
import flexibledeliveryoptionsbenoni from "./pages/location-based-pages/flexible-delivery-options-benoni-section";
import flexibledeliveryoptionsbethal from "./pages/location-based-pages/flexible-delivery-options-bethal-section";
import flexibledeliveryoptionsbhisho from "./pages/location-based-pages/flexible-delivery-options-bhisho-section";
import flexibledeliveryoptionsbloemfontein from "./pages/location-based-pages/flexible-delivery-options-bloemfontein-section";
import flexibledeliveryoptionsbrakpan from "./pages/location-based-pages/flexible-delivery-options-brakpan-section";
import flexibledeliveryoptionsbrits from "./pages/location-based-pages/flexible-delivery-options-brits-section";
import flexibledeliveryoptionscapetown from "./pages/location-based-pages/flexible-delivery-options-cape-town-section";
import flexibledeliveryoptionscarletonville from "./pages/location-based-pages/flexible-delivery-options-carletonville-section";
import flexibledeliveryoptionscenturion from "./pages/location-based-pages/flexible-delivery-options-centurion-section";
import flexibledeliveryoptionscradock from "./pages/location-based-pages/flexible-delivery-options-cradock-section";
import flexibledeliveryoptionsdeaar from "./pages/location-based-pages/flexible-delivery-options-de-aar-section";
import flexibledeliveryoptionsdurban from "./pages/location-based-pages/flexible-delivery-options-durban-section";
import flexibledeliveryoptionseastlondon from "./pages/location-based-pages/flexible-delivery-options-east-london-section";
import flexibledeliveryoptionsferndale from "./pages/location-based-pages/flexible-delivery-options-ferndale-section";
import flexibledeliveryoptionsgeorge from "./pages/location-based-pages/flexible-delivery-options-george-section";
import flexibledeliveryoptionsgraaffreinet from "./pages/location-based-pages/flexible-delivery-options-graaff-reinet-section";
import flexibledeliveryoptionsgrahamstown from "./pages/location-based-pages/flexible-delivery-options-grahamstown-section";
import flexibledeliveryoptionshermanus from "./pages/location-based-pages/flexible-delivery-options-hermanus-section";
import flexibledeliveryoptionsjeffreysbay from "./pages/location-based-pages/flexible-delivery-options-jeffreys-bay-section";
import flexibledeliveryoptionsjohannesburg from "./pages/location-based-pages/flexible-delivery-options-johannesburg-section";
import flexibledeliveryoptionskimberley from "./pages/location-based-pages/flexible-delivery-options-kimberley-section";
import flexibledeliveryoptionsklerksdorp from "./pages/location-based-pages/flexible-delivery-options-klerksdorp-section";
import flexibledeliveryoptionsknysna from "./pages/location-based-pages/flexible-delivery-options-knysna-section";
import flexibledeliveryoptionskroonstad from "./pages/location-based-pages/flexible-delivery-options-kroonstad-section";
import flexibledeliveryoptionskuilsrivier from "./pages/location-based-pages/flexible-delivery-options-kuilsrivier-section";
import flexibledeliveryoptionsladysmith from "./pages/location-based-pages/flexible-delivery-options-ladysmith-section";
import flexibledeliveryoptionslebowakgomo from "./pages/location-based-pages/flexible-delivery-options-lebowakgomo-section";
import flexibledeliveryoptionslichtenburg from "./pages/location-based-pages/flexible-delivery-options-lichtenburg-section";
import flexibledeliveryoptionsmahikeng from "./pages/location-based-pages/flexible-delivery-options-mahikeng-section";
import flexibledeliveryoptionsmalmesbury from "./pages/location-based-pages/flexible-delivery-options-malmesbury-section";
import flexibledeliveryoptionsmiddelburg from "./pages/location-based-pages/flexible-delivery-options-middelburg-section";
import flexibledeliveryoptionsmidrand from "./pages/location-based-pages/flexible-delivery-options-midrand-section";
import flexibledeliveryoptionsmmabatho from "./pages/location-based-pages/flexible-delivery-options-mmabatho-section";
import flexibledeliveryoptionsmosselbay from "./pages/location-based-pages/flexible-delivery-options-mossel-bay-section";
import flexibledeliveryoptionsmusina from "./pages/location-based-pages/flexible-delivery-options-musina-section";
import flexibledeliveryoptionsnelspruit from "./pages/location-based-pages/flexible-delivery-options-nelspruit-section";
import flexibledeliveryoptionsoudtshoorn from "./pages/location-based-pages/flexible-delivery-options-oudtshoorn-section";
import flexibledeliveryoptionspaarl from "./pages/location-based-pages/flexible-delivery-options-paarl-section";
import flexibledeliveryoptionspietermaritzburg from "./pages/location-based-pages/flexible-delivery-options-pietermaritzburg-section";
import flexibledeliveryoptionspolokwane from "./pages/location-based-pages/flexible-delivery-options-polokwane-section";
import flexibledeliveryoptionsportelizabeth from "./pages/location-based-pages/flexible-delivery-options-port-elizabeth-section";
import flexibledeliveryoptionsportshepstone from "./pages/location-based-pages/flexible-delivery-options-port-shepstone-section";
import flexibledeliveryoptionspotchefstroom from "./pages/location-based-pages/flexible-delivery-options-potchefstroom-section";
import flexibledeliveryoptionspretoria from "./pages/location-based-pages/flexible-delivery-options-pretoria-section";
import flexibledeliveryoptionsqueenstown from "./pages/location-based-pages/flexible-delivery-options-queenstown-section";
import flexibledeliveryoptionsrandburg from "./pages/location-based-pages/flexible-delivery-options-randburg-section";
import flexibledeliveryoptionsroodepoort from "./pages/location-based-pages/flexible-delivery-options-roodepoort-section";
import flexibledeliveryoptionsrustenburg from "./pages/location-based-pages/flexible-delivery-options-rustenburg-section";
import flexibledeliveryoptionssaldanha from "./pages/location-based-pages/flexible-delivery-options-saldanha-section";
import flexibledeliveryoptionssandton from "./pages/location-based-pages/flexible-delivery-options-sandton-section";
import flexibledeliveryoptionssoweto from "./pages/location-based-pages/flexible-delivery-options-soweto-section";
import flexibledeliveryoptionssprings from "./pages/location-based-pages/flexible-delivery-options-springs-section";
import flexibledeliveryoptionsstanderton from "./pages/location-based-pages/flexible-delivery-options-standerton-section";
import flexibledeliveryoptionsthohoyandou from "./pages/location-based-pages/flexible-delivery-options-thohoyandou-section";
import flexibledeliveryoptionstzaneen from "./pages/location-based-pages/flexible-delivery-options-tzaneen-section";
import flexibledeliveryoptionsuitenhage from "./pages/location-based-pages/flexible-delivery-options-uitenhage-section";
import flexibledeliveryoptionsulundi from "./pages/location-based-pages/flexible-delivery-options-ulundi-section";
import flexibledeliveryoptionsumtata from "./pages/location-based-pages/flexible-delivery-options-umtata-section";
import flexibledeliveryoptionsupington from "./pages/location-based-pages/flexible-delivery-options-upington-section";
import flexibledeliveryoptionsvanderbijlpark from "./pages/location-based-pages/flexible-delivery-options-vanderbijlpark-section";
import flexibledeliveryoptionsvereeniging from "./pages/location-based-pages/flexible-delivery-options-vereeniging-section";
import flexibledeliveryoptionsvolksrust from "./pages/location-based-pages/flexible-delivery-options-volksrust-section";
import flexibledeliveryoptionsvredenburg from "./pages/location-based-pages/flexible-delivery-options-vredenburg-section";
import flexibledeliveryoptionsvryburg from "./pages/location-based-pages/flexible-delivery-options-vryburg-section";
import flexibledeliveryoptionsvryheid from "./pages/location-based-pages/flexible-delivery-options-vryheid-section";
import flexibledeliveryoptionswelkom from "./pages/location-based-pages/flexible-delivery-options-welkom-section";
import flexibledeliveryoptionsworcester from "./pages/location-based-pages/flexible-delivery-options-worcester-section";
import fragiledeliveryalberton from "./pages/location-based-pages/fragile-delivery-alberton-section";
import fragiledeliveryaliwalnorth from "./pages/location-based-pages/fragile-delivery-aliwal-north-section";
import fragiledeliverybeaufortwest from "./pages/location-based-pages/fragile-delivery-beaufort-west-section";
import fragiledeliverybenoni from "./pages/location-based-pages/fragile-delivery-benoni-section";
import fragiledeliverybethal from "./pages/location-based-pages/fragile-delivery-bethal-section";
import fragiledeliverybhisho from "./pages/location-based-pages/fragile-delivery-bhisho-section";
import fragiledeliverybloemfontein from "./pages/location-based-pages/fragile-delivery-bloemfontein-section";
import fragiledeliverybrakpan from "./pages/location-based-pages/fragile-delivery-brakpan-section";
import fragiledeliverybrits from "./pages/location-based-pages/fragile-delivery-brits-section";
import fragiledeliverycapetown from "./pages/location-based-pages/fragile-delivery-cape-town-section";
import fragiledeliverycarletonville from "./pages/location-based-pages/fragile-delivery-carletonville-section";
import fragiledeliverycenturion from "./pages/location-based-pages/fragile-delivery-centurion-section";
import fragiledeliverycradock from "./pages/location-based-pages/fragile-delivery-cradock-section";
import fragiledeliverydeaar from "./pages/location-based-pages/fragile-delivery-de-aar-section";
import fragiledeliverydurban from "./pages/location-based-pages/fragile-delivery-durban-section";
import fragiledeliveryeastlondon from "./pages/location-based-pages/fragile-delivery-east-london-section";
import fragiledeliveryferndale from "./pages/location-based-pages/fragile-delivery-ferndale-section";
import fragiledeliverygeorge from "./pages/location-based-pages/fragile-delivery-george-section";
import fragiledeliverygraaffreinet from "./pages/location-based-pages/fragile-delivery-graaff-reinet-section";
import fragiledeliverygrahamstown from "./pages/location-based-pages/fragile-delivery-grahamstown-section";
import fragiledeliveryhermanus from "./pages/location-based-pages/fragile-delivery-hermanus-section";
import fragiledeliveryjeffreysbay from "./pages/location-based-pages/fragile-delivery-jeffreys-bay-section";
import fragiledeliveryjohannesburg from "./pages/location-based-pages/fragile-delivery-johannesburg-section";
import fragiledeliverykimberley from "./pages/location-based-pages/fragile-delivery-kimberley-section";
import fragiledeliveryklerksdorp from "./pages/location-based-pages/fragile-delivery-klerksdorp-section";
import fragiledeliveryknysna from "./pages/location-based-pages/fragile-delivery-knysna-section";
import fragiledeliverykroonstad from "./pages/location-based-pages/fragile-delivery-kroonstad-section";
import fragiledeliverykuilsrivier from "./pages/location-based-pages/fragile-delivery-kuilsrivier-section";
import fragiledeliveryladysmith from "./pages/location-based-pages/fragile-delivery-ladysmith-section";
import fragiledeliverylebowakgomo from "./pages/location-based-pages/fragile-delivery-lebowakgomo-section";
import fragiledeliverylichtenburg from "./pages/location-based-pages/fragile-delivery-lichtenburg-section";
import fragiledeliverymahikeng from "./pages/location-based-pages/fragile-delivery-mahikeng-section";
import fragiledeliverymalmesbury from "./pages/location-based-pages/fragile-delivery-malmesbury-section";
import fragiledeliverymiddelburg from "./pages/location-based-pages/fragile-delivery-middelburg-section";
import fragiledeliverymidrand from "./pages/location-based-pages/fragile-delivery-midrand-section";
import fragiledeliverymmabatho from "./pages/location-based-pages/fragile-delivery-mmabatho-section";
import fragiledeliverymosselbay from "./pages/location-based-pages/fragile-delivery-mossel-bay-section";
import fragiledeliverymusina from "./pages/location-based-pages/fragile-delivery-musina-section";
import fragiledeliverynelspruit from "./pages/location-based-pages/fragile-delivery-nelspruit-section";
import fragiledeliveryoudtshoorn from "./pages/location-based-pages/fragile-delivery-oudtshoorn-section";
import fragiledeliverypaarl from "./pages/location-based-pages/fragile-delivery-paarl-section";
import fragiledeliverypietermaritzburg from "./pages/location-based-pages/fragile-delivery-pietermaritzburg-section";
import fragiledeliverypolokwane from "./pages/location-based-pages/fragile-delivery-polokwane-section";
import fragiledeliveryportelizabeth from "./pages/location-based-pages/fragile-delivery-port-elizabeth-section";
import fragiledeliveryportshepstone from "./pages/location-based-pages/fragile-delivery-port-shepstone-section";
import fragiledeliverypotchefstroom from "./pages/location-based-pages/fragile-delivery-potchefstroom-section";
import fragiledeliverypretoria from "./pages/location-based-pages/fragile-delivery-pretoria-section";
import fragiledeliveryqueenstown from "./pages/location-based-pages/fragile-delivery-queenstown-section";
import fragiledeliveryrandburg from "./pages/location-based-pages/fragile-delivery-randburg-section";
import fragiledeliveryroodepoort from "./pages/location-based-pages/fragile-delivery-roodepoort-section";
import fragiledeliveryrustenburg from "./pages/location-based-pages/fragile-delivery-rustenburg-section";
import fragiledeliverysaldanha from "./pages/location-based-pages/fragile-delivery-saldanha-section";
import fragiledeliverysandton from "./pages/location-based-pages/fragile-delivery-sandton-section";
import fragiledeliverysoweto from "./pages/location-based-pages/fragile-delivery-soweto-section";
import fragiledeliverysprings from "./pages/location-based-pages/fragile-delivery-springs-section";
import fragiledeliverystanderton from "./pages/location-based-pages/fragile-delivery-standerton-section";
import fragiledeliverythohoyandou from "./pages/location-based-pages/fragile-delivery-thohoyandou-section";
import fragiledeliverytzaneen from "./pages/location-based-pages/fragile-delivery-tzaneen-section";
import fragiledeliveryuitenhage from "./pages/location-based-pages/fragile-delivery-uitenhage-section";
import fragiledeliveryulundi from "./pages/location-based-pages/fragile-delivery-ulundi-section";
import fragiledeliveryumtata from "./pages/location-based-pages/fragile-delivery-umtata-section";
import fragiledeliveryupington from "./pages/location-based-pages/fragile-delivery-upington-section";
import fragiledeliveryvanderbijlpark from "./pages/location-based-pages/fragile-delivery-vanderbijlpark-section";
import fragiledeliveryvereeniging from "./pages/location-based-pages/fragile-delivery-vereeniging-section";
import fragiledeliveryvolksrust from "./pages/location-based-pages/fragile-delivery-volksrust-section";
import fragiledeliveryvredenburg from "./pages/location-based-pages/fragile-delivery-vredenburg-section";
import fragiledeliveryvryburg from "./pages/location-based-pages/fragile-delivery-vryburg-section";
import fragiledeliveryvryheid from "./pages/location-based-pages/fragile-delivery-vryheid-section";
import fragiledeliverywelkom from "./pages/location-based-pages/fragile-delivery-welkom-section";
import fragiledeliveryworcester from "./pages/location-based-pages/fragile-delivery-worcester-section";
import freightdeliveryalberton from "./pages/location-based-pages/freight-delivery-alberton-section";
import freightdeliveryaliwalnorth from "./pages/location-based-pages/freight-delivery-aliwal-north-section";
import freightdeliverybeaufortwest from "./pages/location-based-pages/freight-delivery-beaufort-west-section";
import freightdeliverybenoni from "./pages/location-based-pages/freight-delivery-benoni-section";
import freightdeliverybethal from "./pages/location-based-pages/freight-delivery-bethal-section";
import freightdeliverybhisho from "./pages/location-based-pages/freight-delivery-bhisho-section";
import freightdeliverybloemfontein from "./pages/location-based-pages/freight-delivery-bloemfontein-section";
import freightdeliverybrakpan from "./pages/location-based-pages/freight-delivery-brakpan-section";
import freightdeliverybrits from "./pages/location-based-pages/freight-delivery-brits-section";
import freightdeliverycapetown from "./pages/location-based-pages/freight-delivery-cape-town-section";
import freightdeliverycarletonville from "./pages/location-based-pages/freight-delivery-carletonville-section";
import freightdeliverycenturion from "./pages/location-based-pages/freight-delivery-centurion-section";
import freightdeliverycradock from "./pages/location-based-pages/freight-delivery-cradock-section";
import freightdeliverydeaar from "./pages/location-based-pages/freight-delivery-de-aar-section";
import freightdeliverydurban from "./pages/location-based-pages/freight-delivery-durban-section";
import freightdeliveryeastlondon from "./pages/location-based-pages/freight-delivery-east-london-section";
import freightdeliveryferndale from "./pages/location-based-pages/freight-delivery-ferndale-section";
import freightdeliverygeorge from "./pages/location-based-pages/freight-delivery-george-section";
import freightdeliverygraaffreinet from "./pages/location-based-pages/freight-delivery-graaff-reinet-section";
import freightdeliverygrahamstown from "./pages/location-based-pages/freight-delivery-grahamstown-section";
import freightdeliveryhermanus from "./pages/location-based-pages/freight-delivery-hermanus-section";
import freightdeliveryjeffreysbay from "./pages/location-based-pages/freight-delivery-jeffreys-bay-section";
import freightdeliveryjohannesburg from "./pages/location-based-pages/freight-delivery-johannesburg-section";
import freightdeliverykimberley from "./pages/location-based-pages/freight-delivery-kimberley-section";
import freightdeliveryklerksdorp from "./pages/location-based-pages/freight-delivery-klerksdorp-section";
import freightdeliveryknysna from "./pages/location-based-pages/freight-delivery-knysna-section";
import freightdeliverykroonstad from "./pages/location-based-pages/freight-delivery-kroonstad-section";
import freightdeliverykuilsrivier from "./pages/location-based-pages/freight-delivery-kuilsrivier-section";
import freightdeliveryladysmith from "./pages/location-based-pages/freight-delivery-ladysmith-section";
import freightdeliverylebowakgomo from "./pages/location-based-pages/freight-delivery-lebowakgomo-section";
import freightdeliverylichtenburg from "./pages/location-based-pages/freight-delivery-lichtenburg-section";
import freightdeliverymahikeng from "./pages/location-based-pages/freight-delivery-mahikeng-section";
import freightdeliverymalmesbury from "./pages/location-based-pages/freight-delivery-malmesbury-section";
import freightdeliverymiddelburg from "./pages/location-based-pages/freight-delivery-middelburg-section";
import freightdeliverymidrand from "./pages/location-based-pages/freight-delivery-midrand-section";
import freightdeliverymmabatho from "./pages/location-based-pages/freight-delivery-mmabatho-section";
import freightdeliverymosselbay from "./pages/location-based-pages/freight-delivery-mossel-bay-section";
import freightdeliverymusina from "./pages/location-based-pages/freight-delivery-musina-section";
import freightdeliverynelspruit from "./pages/location-based-pages/freight-delivery-nelspruit-section";
import freightdeliveryoudtshoorn from "./pages/location-based-pages/freight-delivery-oudtshoorn-section";
import freightdeliverypaarl from "./pages/location-based-pages/freight-delivery-paarl-section";
import freightdeliverypietermaritzburg from "./pages/location-based-pages/freight-delivery-pietermaritzburg-section";
import freightdeliverypolokwane from "./pages/location-based-pages/freight-delivery-polokwane-section";
import freightdeliveryportelizabeth from "./pages/location-based-pages/freight-delivery-port-elizabeth-section";
import freightdeliveryportshepstone from "./pages/location-based-pages/freight-delivery-port-shepstone-section";
import freightdeliverypotchefstroom from "./pages/location-based-pages/freight-delivery-potchefstroom-section";
import freightdeliverypretoria from "./pages/location-based-pages/freight-delivery-pretoria-section";
import freightdeliveryqueenstown from "./pages/location-based-pages/freight-delivery-queenstown-section";
import freightdeliveryrandburg from "./pages/location-based-pages/freight-delivery-randburg-section";
import freightdeliveryroodepoort from "./pages/location-based-pages/freight-delivery-roodepoort-section";
import freightdeliveryrustenburg from "./pages/location-based-pages/freight-delivery-rustenburg-section";
import freightdeliverysaldanha from "./pages/location-based-pages/freight-delivery-saldanha-section";
import freightdeliverysandton from "./pages/location-based-pages/freight-delivery-sandton-section";
import freightdeliverysoweto from "./pages/location-based-pages/freight-delivery-soweto-section";
import freightdeliverysprings from "./pages/location-based-pages/freight-delivery-springs-section";
import freightdeliverystanderton from "./pages/location-based-pages/freight-delivery-standerton-section";
import freightdeliverythohoyandou from "./pages/location-based-pages/freight-delivery-thohoyandou-section";
import freightdeliverytzaneen from "./pages/location-based-pages/freight-delivery-tzaneen-section";
import freightdeliveryuitenhage from "./pages/location-based-pages/freight-delivery-uitenhage-section";
import freightdeliveryulundi from "./pages/location-based-pages/freight-delivery-ulundi-section";
import freightdeliveryumtata from "./pages/location-based-pages/freight-delivery-umtata-section";
import freightdeliveryupington from "./pages/location-based-pages/freight-delivery-upington-section";
import freightdeliveryvanderbijlpark from "./pages/location-based-pages/freight-delivery-vanderbijlpark-section";
import freightdeliveryvereeniging from "./pages/location-based-pages/freight-delivery-vereeniging-section";
import freightdeliveryvolksrust from "./pages/location-based-pages/freight-delivery-volksrust-section";
import freightdeliveryvredenburg from "./pages/location-based-pages/freight-delivery-vredenburg-section";
import freightdeliveryvryburg from "./pages/location-based-pages/freight-delivery-vryburg-section";
import freightdeliveryvryheid from "./pages/location-based-pages/freight-delivery-vryheid-section";
import freightdeliverywelkom from "./pages/location-based-pages/freight-delivery-welkom-section";
import freightdeliveryworcester from "./pages/location-based-pages/freight-delivery-worcester-section";
import furnituredeliveryalberton from "./pages/location-based-pages/furniture-delivery-alberton-section";
import furnituredeliveryaliwalnorth from "./pages/location-based-pages/furniture-delivery-aliwal-north-section";
import furnituredeliverybeaufortwest from "./pages/location-based-pages/furniture-delivery-beaufort-west-section";
import furnituredeliverybenoni from "./pages/location-based-pages/furniture-delivery-benoni-section";
import furnituredeliverybethal from "./pages/location-based-pages/furniture-delivery-bethal-section";
import furnituredeliverybhisho from "./pages/location-based-pages/furniture-delivery-bhisho-section";
import furnituredeliverybloemfontein from "./pages/location-based-pages/furniture-delivery-bloemfontein-section";
import furnituredeliverybrakpan from "./pages/location-based-pages/furniture-delivery-brakpan-section";
import furnituredeliverybrits from "./pages/location-based-pages/furniture-delivery-brits-section";
import furnituredeliverycapetown from "./pages/location-based-pages/furniture-delivery-cape-town-section";
import furnituredeliverycarletonville from "./pages/location-based-pages/furniture-delivery-carletonville-section";
import furnituredeliverycenturion from "./pages/location-based-pages/furniture-delivery-centurion-section";
import furnituredeliverycradock from "./pages/location-based-pages/furniture-delivery-cradock-section";
import furnituredeliverydeaar from "./pages/location-based-pages/furniture-delivery-de-aar-section";
import furnituredeliverydurban from "./pages/location-based-pages/furniture-delivery-durban-section";
import furnituredeliveryeastlondon from "./pages/location-based-pages/furniture-delivery-east-london-section";
import furnituredeliveryferndale from "./pages/location-based-pages/furniture-delivery-ferndale-section";
import furnituredeliverygeorge from "./pages/location-based-pages/furniture-delivery-george-section";
import furnituredeliverygraaffreinet from "./pages/location-based-pages/furniture-delivery-graaff-reinet-section";
import furnituredeliverygrahamstown from "./pages/location-based-pages/furniture-delivery-grahamstown-section";
import furnituredeliveryhermanus from "./pages/location-based-pages/furniture-delivery-hermanus-section";
import furnituredeliveryjeffreysbay from "./pages/location-based-pages/furniture-delivery-jeffreys-bay-section";
import furnituredeliveryjohannesburg from "./pages/location-based-pages/furniture-delivery-johannesburg-section";
import furnituredeliverykimberley from "./pages/location-based-pages/furniture-delivery-kimberley-section";
import furnituredeliveryklerksdorp from "./pages/location-based-pages/furniture-delivery-klerksdorp-section";
import furnituredeliveryknysna from "./pages/location-based-pages/furniture-delivery-knysna-section";
import furnituredeliverykroonstad from "./pages/location-based-pages/furniture-delivery-kroonstad-section";
import furnituredeliverykuilsrivier from "./pages/location-based-pages/furniture-delivery-kuilsrivier-section";
import furnituredeliveryladysmith from "./pages/location-based-pages/furniture-delivery-ladysmith-section";
import furnituredeliverylebowakgomo from "./pages/location-based-pages/furniture-delivery-lebowakgomo-section";
import furnituredeliverylichtenburg from "./pages/location-based-pages/furniture-delivery-lichtenburg-section";
import furnituredeliverymahikeng from "./pages/location-based-pages/furniture-delivery-mahikeng-section";
import furnituredeliverymalmesbury from "./pages/location-based-pages/furniture-delivery-malmesbury-section";
import furnituredeliverymiddelburg from "./pages/location-based-pages/furniture-delivery-middelburg-section";
import furnituredeliverymidrand from "./pages/location-based-pages/furniture-delivery-midrand-section";
import furnituredeliverymmabatho from "./pages/location-based-pages/furniture-delivery-mmabatho-section";
import furnituredeliverymosselbay from "./pages/location-based-pages/furniture-delivery-mossel-bay-section";
import furnituredeliverymusina from "./pages/location-based-pages/furniture-delivery-musina-section";
import furnituredeliverynelspruit from "./pages/location-based-pages/furniture-delivery-nelspruit-section";
import furnituredeliveryoudtshoorn from "./pages/location-based-pages/furniture-delivery-oudtshoorn-section";
import furnituredeliverypaarl from "./pages/location-based-pages/furniture-delivery-paarl-section";
import furnituredeliverypietermaritzburg from "./pages/location-based-pages/furniture-delivery-pietermaritzburg-section";
import furnituredeliverypolokwane from "./pages/location-based-pages/furniture-delivery-polokwane-section";
import furnituredeliveryportelizabeth from "./pages/location-based-pages/furniture-delivery-port-elizabeth-section";
import furnituredeliveryportshepstone from "./pages/location-based-pages/furniture-delivery-port-shepstone-section";
import furnituredeliverypotchefstroom from "./pages/location-based-pages/furniture-delivery-potchefstroom-section";
import furnituredeliverypretoria from "./pages/location-based-pages/furniture-delivery-pretoria-section";
import furnituredeliveryqueenstown from "./pages/location-based-pages/furniture-delivery-queenstown-section";
import furnituredeliveryrandburg from "./pages/location-based-pages/furniture-delivery-randburg-section";
import furnituredeliveryroodepoort from "./pages/location-based-pages/furniture-delivery-roodepoort-section";
import furnituredeliveryrustenburg from "./pages/location-based-pages/furniture-delivery-rustenburg-section";
import furnituredeliverysaldanha from "./pages/location-based-pages/furniture-delivery-saldanha-section";
import furnituredeliverysandton from "./pages/location-based-pages/furniture-delivery-sandton-section";
import furnituredeliverysoweto from "./pages/location-based-pages/furniture-delivery-soweto-section";
import furnituredeliverysprings from "./pages/location-based-pages/furniture-delivery-springs-section";
import furnituredeliverystanderton from "./pages/location-based-pages/furniture-delivery-standerton-section";
import furnituredeliverythohoyandou from "./pages/location-based-pages/furniture-delivery-thohoyandou-section";
import furnituredeliverytzaneen from "./pages/location-based-pages/furniture-delivery-tzaneen-section";
import furnituredeliveryuitenhage from "./pages/location-based-pages/furniture-delivery-uitenhage-section";
import furnituredeliveryulundi from "./pages/location-based-pages/furniture-delivery-ulundi-section";
import furnituredeliveryumtata from "./pages/location-based-pages/furniture-delivery-umtata-section";
import furnituredeliveryupington from "./pages/location-based-pages/furniture-delivery-upington-section";
import furnituredeliveryvanderbijlpark from "./pages/location-based-pages/furniture-delivery-vanderbijlpark-section";
import furnituredeliveryvereeniging from "./pages/location-based-pages/furniture-delivery-vereeniging-section";
import furnituredeliveryvolksrust from "./pages/location-based-pages/furniture-delivery-volksrust-section";
import furnituredeliveryvredenburg from "./pages/location-based-pages/furniture-delivery-vredenburg-section";
import furnituredeliveryvryburg from "./pages/location-based-pages/furniture-delivery-vryburg-section";
import furnituredeliveryvryheid from "./pages/location-based-pages/furniture-delivery-vryheid-section";
import furnituredeliverywelkom from "./pages/location-based-pages/furniture-delivery-welkom-section";
import furnituredeliveryworcester from "./pages/location-based-pages/furniture-delivery-worcester-section";
import furnituremoversalberton from "./pages/location-based-pages/furniture-movers-alberton-section";
import furnituremoversaliwalnorth from "./pages/location-based-pages/furniture-movers-aliwal-north-section";
import furnituremoversbeaufortwest from "./pages/location-based-pages/furniture-movers-beaufort-west-section";
import furnituremoversbenoni from "./pages/location-based-pages/furniture-movers-benoni-section";
import furnituremoversbethal from "./pages/location-based-pages/furniture-movers-bethal-section";
import furnituremoversbhisho from "./pages/location-based-pages/furniture-movers-bhisho-section";
import furnituremoversbloemfontein from "./pages/location-based-pages/furniture-movers-bloemfontein-section";
import furnituremoversbrakpan from "./pages/location-based-pages/furniture-movers-brakpan-section";
import furnituremoversbrits from "./pages/location-based-pages/furniture-movers-brits-section";
import furnituremoverscapetown from "./pages/location-based-pages/furniture-movers-cape-town-section";
import furnituremoverscarletonville from "./pages/location-based-pages/furniture-movers-carletonville-section";
import furnituremoverscenturion from "./pages/location-based-pages/furniture-movers-centurion-section";
import furnituremoverscradock from "./pages/location-based-pages/furniture-movers-cradock-section";
import furnituremoversdeaar from "./pages/location-based-pages/furniture-movers-de-aar-section";
import furnituremoversdurban from "./pages/location-based-pages/furniture-movers-durban-section";
import furnituremoverseastlondon from "./pages/location-based-pages/furniture-movers-east-london-section";
import furnituremoversferndale from "./pages/location-based-pages/furniture-movers-ferndale-section";
import furnituremoversgeorge from "./pages/location-based-pages/furniture-movers-george-section";
import furnituremoversgraaffreinet from "./pages/location-based-pages/furniture-movers-graaff-reinet-section";
import furnituremoversgrahamstown from "./pages/location-based-pages/furniture-movers-grahamstown-section";
import furnituremovershermanus from "./pages/location-based-pages/furniture-movers-hermanus-section";
import furnituremoversjeffreysbay from "./pages/location-based-pages/furniture-movers-jeffreys-bay-section";
import furnituremoversjohannesburg from "./pages/location-based-pages/furniture-movers-johannesburg-section";
import furnituremoverskimberley from "./pages/location-based-pages/furniture-movers-kimberley-section";
import furnituremoversklerksdorp from "./pages/location-based-pages/furniture-movers-klerksdorp-section";
import furnituremoversknysna from "./pages/location-based-pages/furniture-movers-knysna-section";
import furnituremoverskroonstad from "./pages/location-based-pages/furniture-movers-kroonstad-section";
import furnituremoverskuilsrivier from "./pages/location-based-pages/furniture-movers-kuilsrivier-section";
import furnituremoversladysmith from "./pages/location-based-pages/furniture-movers-ladysmith-section";
import furnituremoverslebowakgomo from "./pages/location-based-pages/furniture-movers-lebowakgomo-section";
import furnituremoverslichtenburg from "./pages/location-based-pages/furniture-movers-lichtenburg-section";
import furnituremoversmahikeng from "./pages/location-based-pages/furniture-movers-mahikeng-section";
import furnituremoversmalmesbury from "./pages/location-based-pages/furniture-movers-malmesbury-section";
import furnituremoversmiddelburg from "./pages/location-based-pages/furniture-movers-middelburg-section";
import furnituremoversmidrand from "./pages/location-based-pages/furniture-movers-midrand-section";
import furnituremoversmmabatho from "./pages/location-based-pages/furniture-movers-mmabatho-section";
import furnituremoversmosselbay from "./pages/location-based-pages/furniture-movers-mossel-bay-section";
import furnituremoversmusina from "./pages/location-based-pages/furniture-movers-musina-section";
import furnituremoversnelspruit from "./pages/location-based-pages/furniture-movers-nelspruit-section";
import furnituremoversoudtshoorn from "./pages/location-based-pages/furniture-movers-oudtshoorn-section";
import furnituremoverspaarl from "./pages/location-based-pages/furniture-movers-paarl-section";
import furnituremoverspietermaritzburg from "./pages/location-based-pages/furniture-movers-pietermaritzburg-section";
import furnituremoverspolokwane from "./pages/location-based-pages/furniture-movers-polokwane-section";
import furnituremoversportelizabeth from "./pages/location-based-pages/furniture-movers-port-elizabeth-section";
import furnituremoversportshepstone from "./pages/location-based-pages/furniture-movers-port-shepstone-section";
import furnituremoverspotchefstroom from "./pages/location-based-pages/furniture-movers-potchefstroom-section";
import furnituremoverspretoria from "./pages/location-based-pages/furniture-movers-pretoria-section";
import furnituremoversqueenstown from "./pages/location-based-pages/furniture-movers-queenstown-section";
import furnituremoversrandburg from "./pages/location-based-pages/furniture-movers-randburg-section";
import furnituremoversroodepoort from "./pages/location-based-pages/furniture-movers-roodepoort-section";
import furnituremoversrustenburg from "./pages/location-based-pages/furniture-movers-rustenburg-section";
import furnituremoverssaldanha from "./pages/location-based-pages/furniture-movers-saldanha-section";
import furnituremoverssandton from "./pages/location-based-pages/furniture-movers-sandton-section";
import furnituremoverssoweto from "./pages/location-based-pages/furniture-movers-soweto-section";
import furnituremoverssprings from "./pages/location-based-pages/furniture-movers-springs-section";
import furnituremoversstanderton from "./pages/location-based-pages/furniture-movers-standerton-section";
import furnituremoversthohoyandou from "./pages/location-based-pages/furniture-movers-thohoyandou-section";
import furnituremoverstzaneen from "./pages/location-based-pages/furniture-movers-tzaneen-section";
import furnituremoversuitenhage from "./pages/location-based-pages/furniture-movers-uitenhage-section";
import furnituremoversulundi from "./pages/location-based-pages/furniture-movers-ulundi-section";
import furnituremoversumtata from "./pages/location-based-pages/furniture-movers-umtata-section";
import furnituremoversupington from "./pages/location-based-pages/furniture-movers-upington-section";
import furnituremoversvanderbijlpark from "./pages/location-based-pages/furniture-movers-vanderbijlpark-section";
import furnituremoversvereeniging from "./pages/location-based-pages/furniture-movers-vereeniging-section";
import furnituremoversvolksrust from "./pages/location-based-pages/furniture-movers-volksrust-section";
import furnituremoversvredenburg from "./pages/location-based-pages/furniture-movers-vredenburg-section";
import furnituremoversvryburg from "./pages/location-based-pages/furniture-movers-vryburg-section";
import furnituremoversvryheid from "./pages/location-based-pages/furniture-movers-vryheid-section";
import furnituremoverswelkom from "./pages/location-based-pages/furniture-movers-welkom-section";
import furnituremoversworcester from "./pages/location-based-pages/furniture-movers-worcester-section";
import goodstransportalberton from "./pages/location-based-pages/goods-transport-alberton-section";
import goodstransportaliwalnorth from "./pages/location-based-pages/goods-transport-aliwal-north-section";
import goodstransportbeaufortwest from "./pages/location-based-pages/goods-transport-beaufort-west-section";
import goodstransportbenoni from "./pages/location-based-pages/goods-transport-benoni-section";
import goodstransportbethal from "./pages/location-based-pages/goods-transport-bethal-section";
import goodstransportbhisho from "./pages/location-based-pages/goods-transport-bhisho-section";
import goodstransportbloemfontein from "./pages/location-based-pages/goods-transport-bloemfontein-section";
import goodstransportbrakpan from "./pages/location-based-pages/goods-transport-brakpan-section";
import goodstransportbrits from "./pages/location-based-pages/goods-transport-brits-section";
import goodstransportcapetown from "./pages/location-based-pages/goods-transport-cape-town-section";
import goodstransportcarletonville from "./pages/location-based-pages/goods-transport-carletonville-section";
import goodstransportcenturion from "./pages/location-based-pages/goods-transport-centurion-section";
import goodstransportcradock from "./pages/location-based-pages/goods-transport-cradock-section";
import goodstransportdeaar from "./pages/location-based-pages/goods-transport-de-aar-section";
import goodstransportdurban from "./pages/location-based-pages/goods-transport-durban-section";
import goodstransporteastlondon from "./pages/location-based-pages/goods-transport-east-london-section";
import goodstransportferndale from "./pages/location-based-pages/goods-transport-ferndale-section";
import goodstransportgeorge from "./pages/location-based-pages/goods-transport-george-section";
import goodstransportgraaffreinet from "./pages/location-based-pages/goods-transport-graaff-reinet-section";
import goodstransportgrahamstown from "./pages/location-based-pages/goods-transport-grahamstown-section";
import goodstransporthermanus from "./pages/location-based-pages/goods-transport-hermanus-section";
import goodstransportjeffreysbay from "./pages/location-based-pages/goods-transport-jeffreys-bay-section";
import goodstransportjohannesburg from "./pages/location-based-pages/goods-transport-johannesburg-section";
import goodstransportkimberley from "./pages/location-based-pages/goods-transport-kimberley-section";
import goodstransportklerksdorp from "./pages/location-based-pages/goods-transport-klerksdorp-section";
import goodstransportknysna from "./pages/location-based-pages/goods-transport-knysna-section";
import goodstransportkroonstad from "./pages/location-based-pages/goods-transport-kroonstad-section";
import goodstransportkuilsrivier from "./pages/location-based-pages/goods-transport-kuilsrivier-section";
import goodstransportladysmith from "./pages/location-based-pages/goods-transport-ladysmith-section";
import goodstransportlebowakgomo from "./pages/location-based-pages/goods-transport-lebowakgomo-section";
import goodstransportlichtenburg from "./pages/location-based-pages/goods-transport-lichtenburg-section";
import goodstransportmahikeng from "./pages/location-based-pages/goods-transport-mahikeng-section";
import goodstransportmalmesbury from "./pages/location-based-pages/goods-transport-malmesbury-section";
import goodstransportmiddelburg from "./pages/location-based-pages/goods-transport-middelburg-section";
import goodstransportmidrand from "./pages/location-based-pages/goods-transport-midrand-section";
import goodstransportmmabatho from "./pages/location-based-pages/goods-transport-mmabatho-section";
import goodstransportmosselbay from "./pages/location-based-pages/goods-transport-mossel-bay-section";
import goodstransportmusina from "./pages/location-based-pages/goods-transport-musina-section";
import goodstransportnelspruit from "./pages/location-based-pages/goods-transport-nelspruit-section";
import goodstransportoudtshoorn from "./pages/location-based-pages/goods-transport-oudtshoorn-section";
import goodstransportpaarl from "./pages/location-based-pages/goods-transport-paarl-section";
import goodstransportpietermaritzburg from "./pages/location-based-pages/goods-transport-pietermaritzburg-section";
import goodstransportpolokwane from "./pages/location-based-pages/goods-transport-polokwane-section";
import goodstransportportelizabeth from "./pages/location-based-pages/goods-transport-port-elizabeth-section";
import goodstransportportshepstone from "./pages/location-based-pages/goods-transport-port-shepstone-section";
import goodstransportpotchefstroom from "./pages/location-based-pages/goods-transport-potchefstroom-section";
import goodstransportpretoria from "./pages/location-based-pages/goods-transport-pretoria-section";
import goodstransportqueenstown from "./pages/location-based-pages/goods-transport-queenstown-section";
import goodstransportrandburg from "./pages/location-based-pages/goods-transport-randburg-section";
import goodstransportroodepoort from "./pages/location-based-pages/goods-transport-roodepoort-section";
import goodstransportrustenburg from "./pages/location-based-pages/goods-transport-rustenburg-section";
import goodstransportsaldanha from "./pages/location-based-pages/goods-transport-saldanha-section";
import goodstransportsandton from "./pages/location-based-pages/goods-transport-sandton-section";
import goodstransportsoweto from "./pages/location-based-pages/goods-transport-soweto-section";
import goodstransportsprings from "./pages/location-based-pages/goods-transport-springs-section";
import goodstransportstanderton from "./pages/location-based-pages/goods-transport-standerton-section";
import goodstransportthohoyandou from "./pages/location-based-pages/goods-transport-thohoyandou-section";
import goodstransporttzaneen from "./pages/location-based-pages/goods-transport-tzaneen-section";
import goodstransportuitenhage from "./pages/location-based-pages/goods-transport-uitenhage-section";
import goodstransportulundi from "./pages/location-based-pages/goods-transport-ulundi-section";
import goodstransportumtata from "./pages/location-based-pages/goods-transport-umtata-section";
import goodstransportupington from "./pages/location-based-pages/goods-transport-upington-section";
import goodstransportvanderbijlpark from "./pages/location-based-pages/goods-transport-vanderbijlpark-section";
import goodstransportvereeniging from "./pages/location-based-pages/goods-transport-vereeniging-section";
import goodstransportvolksrust from "./pages/location-based-pages/goods-transport-volksrust-section";
import goodstransportvredenburg from "./pages/location-based-pages/goods-transport-vredenburg-section";
import goodstransportvryburg from "./pages/location-based-pages/goods-transport-vryburg-section";
import goodstransportvryheid from "./pages/location-based-pages/goods-transport-vryheid-section";
import goodstransportwelkom from "./pages/location-based-pages/goods-transport-welkom-section";
import goodstransportworcester from "./pages/location-based-pages/goods-transport-worcester-section";
import h100alberton from "./pages/location-based-pages/h100-alberton-section";
import h100aliwalnorth from "./pages/location-based-pages/h100-aliwal-north-section";
import h100beaufortwest from "./pages/location-based-pages/h100-beaufort-west-section";
import h100benoni from "./pages/location-based-pages/h100-benoni-section";
import h100bethal from "./pages/location-based-pages/h100-bethal-section";
import h100bhisho from "./pages/location-based-pages/h100-bhisho-section";
import h100bloemfontein from "./pages/location-based-pages/h100-bloemfontein-section";
import h100brakpan from "./pages/location-based-pages/h100-brakpan-section";
import h100brits from "./pages/location-based-pages/h100-brits-section";
import h100capetown from "./pages/location-based-pages/h100-cape-town-section";
import h100carletonville from "./pages/location-based-pages/h100-carletonville-section";
import h100centurion from "./pages/location-based-pages/h100-centurion-section";
import h100cradock from "./pages/location-based-pages/h100-cradock-section";
import h100deaar from "./pages/location-based-pages/h100-de-aar-section";
import h100durban from "./pages/location-based-pages/h100-durban-section";
import h100eastlondon from "./pages/location-based-pages/h100-east-london-section";
import h100ferndale from "./pages/location-based-pages/h100-ferndale-section";
import h100george from "./pages/location-based-pages/h100-george-section";
import h100graaffreinet from "./pages/location-based-pages/h100-graaff-reinet-section";
import h100grahamstown from "./pages/location-based-pages/h100-grahamstown-section";
import h100hermanus from "./pages/location-based-pages/h100-hermanus-section";
import h100jeffreysbay from "./pages/location-based-pages/h100-jeffreys-bay-section";
import h100johannesburg from "./pages/location-based-pages/h100-johannesburg-section";
import h100kimberley from "./pages/location-based-pages/h100-kimberley-section";
import h100klerksdorp from "./pages/location-based-pages/h100-klerksdorp-section";
import h100knysna from "./pages/location-based-pages/h100-knysna-section";
import h100kroonstad from "./pages/location-based-pages/h100-kroonstad-section";
import h100kuilsrivier from "./pages/location-based-pages/h100-kuilsrivier-section";
import h100ladysmith from "./pages/location-based-pages/h100-ladysmith-section";
import h100lebowakgomo from "./pages/location-based-pages/h100-lebowakgomo-section";
import h100lichtenburg from "./pages/location-based-pages/h100-lichtenburg-section";
import h100mahikeng from "./pages/location-based-pages/h100-mahikeng-section";
import h100malmesbury from "./pages/location-based-pages/h100-malmesbury-section";
import h100middelburg from "./pages/location-based-pages/h100-middelburg-section";
import h100midrand from "./pages/location-based-pages/h100-midrand-section";
import h100mmabatho from "./pages/location-based-pages/h100-mmabatho-section";
import h100mosselbay from "./pages/location-based-pages/h100-mossel-bay-section";
import h100musina from "./pages/location-based-pages/h100-musina-section";
import h100nelspruit from "./pages/location-based-pages/h100-nelspruit-section";
import h100oudtshoorn from "./pages/location-based-pages/h100-oudtshoorn-section";
import h100paarl from "./pages/location-based-pages/h100-paarl-section";
import h100pietermaritzburg from "./pages/location-based-pages/h100-pietermaritzburg-section";
import h100polokwane from "./pages/location-based-pages/h100-polokwane-section";
import h100portelizabeth from "./pages/location-based-pages/h100-port-elizabeth-section";
import h100portshepstone from "./pages/location-based-pages/h100-port-shepstone-section";
import h100potchefstroom from "./pages/location-based-pages/h100-potchefstroom-section";
import h100pretoria from "./pages/location-based-pages/h100-pretoria-section";
import h100queenstown from "./pages/location-based-pages/h100-queenstown-section";
import h100randburg from "./pages/location-based-pages/h100-randburg-section";
import h100roodepoort from "./pages/location-based-pages/h100-roodepoort-section";
import h100rustenburg from "./pages/location-based-pages/h100-rustenburg-section";
import h100saldanha from "./pages/location-based-pages/h100-saldanha-section";
import h100sandton from "./pages/location-based-pages/h100-sandton-section";
import h100soweto from "./pages/location-based-pages/h100-soweto-section";
import h100springs from "./pages/location-based-pages/h100-springs-section";
import h100standerton from "./pages/location-based-pages/h100-standerton-section";
import h100thohoyandou from "./pages/location-based-pages/h100-thohoyandou-section";
import h100tzaneen from "./pages/location-based-pages/h100-tzaneen-section";
import h100uitenhage from "./pages/location-based-pages/h100-uitenhage-section";
import h100ulundi from "./pages/location-based-pages/h100-ulundi-section";
import h100umtata from "./pages/location-based-pages/h100-umtata-section";
import h100upington from "./pages/location-based-pages/h100-upington-section";
import h100vanderbijlpark from "./pages/location-based-pages/h100-vanderbijlpark-section";
import h100vereeniging from "./pages/location-based-pages/h100-vereeniging-section";
import h100volksrust from "./pages/location-based-pages/h100-volksrust-section";
import h100vredenburg from "./pages/location-based-pages/h100-vredenburg-section";
import h100vryburg from "./pages/location-based-pages/h100-vryburg-section";
import h100vryheid from "./pages/location-based-pages/h100-vryheid-section";
import h100welkom from "./pages/location-based-pages/h100-welkom-section";
import h100worcester from "./pages/location-based-pages/h100-worcester-section";
import heavyequipmentdeliveryalberton from "./pages/location-based-pages/heavy-equipment-delivery-alberton-section";
import heavyequipmentdeliveryaliwalnorth from "./pages/location-based-pages/heavy-equipment-delivery-aliwal-north-section";
import heavyequipmentdeliverybeaufortwest from "./pages/location-based-pages/heavy-equipment-delivery-beaufort-west-section";
import heavyequipmentdeliverybenoni from "./pages/location-based-pages/heavy-equipment-delivery-benoni-section";
import heavyequipmentdeliverybethal from "./pages/location-based-pages/heavy-equipment-delivery-bethal-section";
import heavyequipmentdeliverybhisho from "./pages/location-based-pages/heavy-equipment-delivery-bhisho-section";
import heavyequipmentdeliverybloemfontein from "./pages/location-based-pages/heavy-equipment-delivery-bloemfontein-section";
import heavyequipmentdeliverybrakpan from "./pages/location-based-pages/heavy-equipment-delivery-brakpan-section";
import heavyequipmentdeliverybrits from "./pages/location-based-pages/heavy-equipment-delivery-brits-section";
import heavyequipmentdeliverycapetown from "./pages/location-based-pages/heavy-equipment-delivery-cape-town-section";
import heavyequipmentdeliverycarletonville from "./pages/location-based-pages/heavy-equipment-delivery-carletonville-section";
import heavyequipmentdeliverycenturion from "./pages/location-based-pages/heavy-equipment-delivery-centurion-section";
import heavyequipmentdeliverycradock from "./pages/location-based-pages/heavy-equipment-delivery-cradock-section";
import heavyequipmentdeliverydeaar from "./pages/location-based-pages/heavy-equipment-delivery-de-aar-section";
import heavyequipmentdeliverydurban from "./pages/location-based-pages/heavy-equipment-delivery-durban-section";
import heavyequipmentdeliveryeastlondon from "./pages/location-based-pages/heavy-equipment-delivery-east-london-section";
import heavyequipmentdeliveryferndale from "./pages/location-based-pages/heavy-equipment-delivery-ferndale-section";
import heavyequipmentdeliverygeorge from "./pages/location-based-pages/heavy-equipment-delivery-george-section";
import heavyequipmentdeliverygraaffreinet from "./pages/location-based-pages/heavy-equipment-delivery-graaff-reinet-section";
import heavyequipmentdeliverygrahamstown from "./pages/location-based-pages/heavy-equipment-delivery-grahamstown-section";
import heavyequipmentdeliveryhermanus from "./pages/location-based-pages/heavy-equipment-delivery-hermanus-section";
import heavyequipmentdeliveryjeffreysbay from "./pages/location-based-pages/heavy-equipment-delivery-jeffreys-bay-section";
import heavyequipmentdeliveryjohannesburg from "./pages/location-based-pages/heavy-equipment-delivery-johannesburg-section";
import heavyequipmentdeliverykimberley from "./pages/location-based-pages/heavy-equipment-delivery-kimberley-section";
import heavyequipmentdeliveryklerksdorp from "./pages/location-based-pages/heavy-equipment-delivery-klerksdorp-section";
import heavyequipmentdeliveryknysna from "./pages/location-based-pages/heavy-equipment-delivery-knysna-section";
import heavyequipmentdeliverykroonstad from "./pages/location-based-pages/heavy-equipment-delivery-kroonstad-section";
import heavyequipmentdeliverykuilsrivier from "./pages/location-based-pages/heavy-equipment-delivery-kuilsrivier-section";
import heavyequipmentdeliveryladysmith from "./pages/location-based-pages/heavy-equipment-delivery-ladysmith-section";
import heavyequipmentdeliverylebowakgomo from "./pages/location-based-pages/heavy-equipment-delivery-lebowakgomo-section";
import heavyequipmentdeliverylichtenburg from "./pages/location-based-pages/heavy-equipment-delivery-lichtenburg-section";
import heavyequipmentdeliverymahikeng from "./pages/location-based-pages/heavy-equipment-delivery-mahikeng-section";
import heavyequipmentdeliverymalmesbury from "./pages/location-based-pages/heavy-equipment-delivery-malmesbury-section";
import heavyequipmentdeliverymiddelburg from "./pages/location-based-pages/heavy-equipment-delivery-middelburg-section";
import heavyequipmentdeliverymidrand from "./pages/location-based-pages/heavy-equipment-delivery-midrand-section";
import heavyequipmentdeliverymmabatho from "./pages/location-based-pages/heavy-equipment-delivery-mmabatho-section";
import heavyequipmentdeliverymosselbay from "./pages/location-based-pages/heavy-equipment-delivery-mossel-bay-section";
import heavyequipmentdeliverymusina from "./pages/location-based-pages/heavy-equipment-delivery-musina-section";
import heavyequipmentdeliverynelspruit from "./pages/location-based-pages/heavy-equipment-delivery-nelspruit-section";
import heavyequipmentdeliveryoudtshoorn from "./pages/location-based-pages/heavy-equipment-delivery-oudtshoorn-section";
import heavyequipmentdeliverypaarl from "./pages/location-based-pages/heavy-equipment-delivery-paarl-section";
import heavyequipmentdeliverypietermaritzburg from "./pages/location-based-pages/heavy-equipment-delivery-pietermaritzburg-section";
import heavyequipmentdeliverypolokwane from "./pages/location-based-pages/heavy-equipment-delivery-polokwane-section";
import heavyequipmentdeliveryportelizabeth from "./pages/location-based-pages/heavy-equipment-delivery-port-elizabeth-section";
import heavyequipmentdeliveryportshepstone from "./pages/location-based-pages/heavy-equipment-delivery-port-shepstone-section";
import heavyequipmentdeliverypotchefstroom from "./pages/location-based-pages/heavy-equipment-delivery-potchefstroom-section";
import heavyequipmentdeliverypretoria from "./pages/location-based-pages/heavy-equipment-delivery-pretoria-section";
import heavyequipmentdeliveryqueenstown from "./pages/location-based-pages/heavy-equipment-delivery-queenstown-section";
import heavyequipmentdeliveryrandburg from "./pages/location-based-pages/heavy-equipment-delivery-randburg-section";
import heavyequipmentdeliveryroodepoort from "./pages/location-based-pages/heavy-equipment-delivery-roodepoort-section";
import heavyequipmentdeliveryrustenburg from "./pages/location-based-pages/heavy-equipment-delivery-rustenburg-section";
import heavyequipmentdeliverysaldanha from "./pages/location-based-pages/heavy-equipment-delivery-saldanha-section";
import heavyequipmentdeliverysandton from "./pages/location-based-pages/heavy-equipment-delivery-sandton-section";
import heavyequipmentdeliverysoweto from "./pages/location-based-pages/heavy-equipment-delivery-soweto-section";
import heavyequipmentdeliverysprings from "./pages/location-based-pages/heavy-equipment-delivery-springs-section";
import heavyequipmentdeliverystanderton from "./pages/location-based-pages/heavy-equipment-delivery-standerton-section";
import heavyequipmentdeliverythohoyandou from "./pages/location-based-pages/heavy-equipment-delivery-thohoyandou-section";
import heavyequipmentdeliverytzaneen from "./pages/location-based-pages/heavy-equipment-delivery-tzaneen-section";
import heavyequipmentdeliveryuitenhage from "./pages/location-based-pages/heavy-equipment-delivery-uitenhage-section";
import heavyequipmentdeliveryulundi from "./pages/location-based-pages/heavy-equipment-delivery-ulundi-section";
import heavyequipmentdeliveryumtata from "./pages/location-based-pages/heavy-equipment-delivery-umtata-section";
import heavyequipmentdeliveryupington from "./pages/location-based-pages/heavy-equipment-delivery-upington-section";
import heavyequipmentdeliveryvanderbijlpark from "./pages/location-based-pages/heavy-equipment-delivery-vanderbijlpark-section";
import heavyequipmentdeliveryvereeniging from "./pages/location-based-pages/heavy-equipment-delivery-vereeniging-section";
import heavyequipmentdeliveryvolksrust from "./pages/location-based-pages/heavy-equipment-delivery-volksrust-section";
import heavyequipmentdeliveryvredenburg from "./pages/location-based-pages/heavy-equipment-delivery-vredenburg-section";
import heavyequipmentdeliveryvryburg from "./pages/location-based-pages/heavy-equipment-delivery-vryburg-section";
import heavyequipmentdeliveryvryheid from "./pages/location-based-pages/heavy-equipment-delivery-vryheid-section";
import heavyequipmentdeliverywelkom from "./pages/location-based-pages/heavy-equipment-delivery-welkom-section";
import heavyequipmentdeliveryworcester from "./pages/location-based-pages/heavy-equipment-delivery-worcester-section";
import localdeliveryalberton from "./pages/location-based-pages/local-delivery-alberton-section";
import localdeliveryaliwalnorth from "./pages/location-based-pages/local-delivery-aliwal-north-section";
import localdeliverybeaufortwest from "./pages/location-based-pages/local-delivery-beaufort-west-section";
import localdeliverybenoni from "./pages/location-based-pages/local-delivery-benoni-section";
import localdeliverybethal from "./pages/location-based-pages/local-delivery-bethal-section";
import localdeliverybhisho from "./pages/location-based-pages/local-delivery-bhisho-section";
import localdeliverybloemfontein from "./pages/location-based-pages/local-delivery-bloemfontein-section";
import localdeliverybrakpan from "./pages/location-based-pages/local-delivery-brakpan-section";
import localdeliverybrits from "./pages/location-based-pages/local-delivery-brits-section";
import localdeliverycapetown from "./pages/location-based-pages/local-delivery-cape-town-section";
import localdeliverycarletonville from "./pages/location-based-pages/local-delivery-carletonville-section";
import localdeliverycenturion from "./pages/location-based-pages/local-delivery-centurion-section";
import localdeliverycradock from "./pages/location-based-pages/local-delivery-cradock-section";
import localdeliverydeaar from "./pages/location-based-pages/local-delivery-de-aar-section";
import localdeliverydurban from "./pages/location-based-pages/local-delivery-durban-section";
import localdeliveryeastlondon from "./pages/location-based-pages/local-delivery-east-london-section";
import localdeliveryferndale from "./pages/location-based-pages/local-delivery-ferndale-section";
import localdeliverygeorge from "./pages/location-based-pages/local-delivery-george-section";
import localdeliverygraaffreinet from "./pages/location-based-pages/local-delivery-graaff-reinet-section";
import localdeliverygrahamstown from "./pages/location-based-pages/local-delivery-grahamstown-section";
import localdeliveryhermanus from "./pages/location-based-pages/local-delivery-hermanus-section";
import localdeliveryjeffreysbay from "./pages/location-based-pages/local-delivery-jeffreys-bay-section";
import localdeliveryjohannesburg from "./pages/location-based-pages/local-delivery-johannesburg-section";
import localdeliverykimberley from "./pages/location-based-pages/local-delivery-kimberley-section";
import localdeliveryklerksdorp from "./pages/location-based-pages/local-delivery-klerksdorp-section";
import localdeliveryknysna from "./pages/location-based-pages/local-delivery-knysna-section";
import localdeliverykroonstad from "./pages/location-based-pages/local-delivery-kroonstad-section";
import localdeliverykuilsrivier from "./pages/location-based-pages/local-delivery-kuilsrivier-section";
import localdeliveryladysmith from "./pages/location-based-pages/local-delivery-ladysmith-section";
import localdeliverylebowakgomo from "./pages/location-based-pages/local-delivery-lebowakgomo-section";
import localdeliverylichtenburg from "./pages/location-based-pages/local-delivery-lichtenburg-section";
import localdeliverymahikeng from "./pages/location-based-pages/local-delivery-mahikeng-section";
import localdeliverymalmesbury from "./pages/location-based-pages/local-delivery-malmesbury-section";
import localdeliverymiddelburg from "./pages/location-based-pages/local-delivery-middelburg-section";
import localdeliverymidrand from "./pages/location-based-pages/local-delivery-midrand-section";
import localdeliverymmabatho from "./pages/location-based-pages/local-delivery-mmabatho-section";
import localdeliverymosselbay from "./pages/location-based-pages/local-delivery-mossel-bay-section";
import localdeliverymusina from "./pages/location-based-pages/local-delivery-musina-section";
import localdeliverynelspruit from "./pages/location-based-pages/local-delivery-nelspruit-section";
import localdeliveryoudtshoorn from "./pages/location-based-pages/local-delivery-oudtshoorn-section";
import localdeliverypaarl from "./pages/location-based-pages/local-delivery-paarl-section";
import localdeliverypietermaritzburg from "./pages/location-based-pages/local-delivery-pietermaritzburg-section";
import localdeliverypolokwane from "./pages/location-based-pages/local-delivery-polokwane-section";
import localdeliveryportelizabeth from "./pages/location-based-pages/local-delivery-port-elizabeth-section";
import localdeliveryportshepstone from "./pages/location-based-pages/local-delivery-port-shepstone-section";
import localdeliverypotchefstroom from "./pages/location-based-pages/local-delivery-potchefstroom-section";
import localdeliverypretoria from "./pages/location-based-pages/local-delivery-pretoria-section";
import localdeliveryqueenstown from "./pages/location-based-pages/local-delivery-queenstown-section";
import localdeliveryrandburg from "./pages/location-based-pages/local-delivery-randburg-section";
import localdeliveryroodepoort from "./pages/location-based-pages/local-delivery-roodepoort-section";
import localdeliveryrustenburg from "./pages/location-based-pages/local-delivery-rustenburg-section";
import localdeliverysaldanha from "./pages/location-based-pages/local-delivery-saldanha-section";
import localdeliverysandton from "./pages/location-based-pages/local-delivery-sandton-section";
import localdeliverysoweto from "./pages/location-based-pages/local-delivery-soweto-section";
import localdeliverysprings from "./pages/location-based-pages/local-delivery-springs-section";
import localdeliverystanderton from "./pages/location-based-pages/local-delivery-standerton-section";
import localdeliverythohoyandou from "./pages/location-based-pages/local-delivery-thohoyandou-section";
import localdeliverytzaneen from "./pages/location-based-pages/local-delivery-tzaneen-section";
import localdeliveryuitenhage from "./pages/location-based-pages/local-delivery-uitenhage-section";
import localdeliveryulundi from "./pages/location-based-pages/local-delivery-ulundi-section";
import localdeliveryumtata from "./pages/location-based-pages/local-delivery-umtata-section";
import localdeliveryupington from "./pages/location-based-pages/local-delivery-upington-section";
import localdeliveryvanderbijlpark from "./pages/location-based-pages/local-delivery-vanderbijlpark-section";
import localdeliveryvereeniging from "./pages/location-based-pages/local-delivery-vereeniging-section";
import localdeliveryvolksrust from "./pages/location-based-pages/local-delivery-volksrust-section";
import localdeliveryvredenburg from "./pages/location-based-pages/local-delivery-vredenburg-section";
import localdeliveryvryburg from "./pages/location-based-pages/local-delivery-vryburg-section";
import localdeliveryvryheid from "./pages/location-based-pages/local-delivery-vryheid-section";
import localdeliverywelkom from "./pages/location-based-pages/local-delivery-welkom-section";
import localdeliveryworcester from "./pages/location-based-pages/local-delivery-worcester-section";
import localmoversnearmealberton from "./pages/location-based-pages/local-movers-near-me-alberton-section";
import localmoversnearmealiwalnorth from "./pages/location-based-pages/local-movers-near-me-aliwal-north-section";
import localmoversnearmebeaufortwest from "./pages/location-based-pages/local-movers-near-me-beaufort-west-section";
import localmoversnearmebenoni from "./pages/location-based-pages/local-movers-near-me-benoni-section";
import localmoversnearmebethal from "./pages/location-based-pages/local-movers-near-me-bethal-section";
import localmoversnearmebhisho from "./pages/location-based-pages/local-movers-near-me-bhisho-section";
import localmoversnearmebloemfontein from "./pages/location-based-pages/local-movers-near-me-bloemfontein-section";
import localmoversnearmebrakpan from "./pages/location-based-pages/local-movers-near-me-brakpan-section";
import localmoversnearmebrits from "./pages/location-based-pages/local-movers-near-me-brits-section";
import localmoversnearmecapetown from "./pages/location-based-pages/local-movers-near-me-cape-town-section";
import localmoversnearmecarletonville from "./pages/location-based-pages/local-movers-near-me-carletonville-section";
import localmoversnearmecenturion from "./pages/location-based-pages/local-movers-near-me-centurion-section";
import localmoversnearmecradock from "./pages/location-based-pages/local-movers-near-me-cradock-section";
import localmoversnearmedeaar from "./pages/location-based-pages/local-movers-near-me-de-aar-section";
import localmoversnearmedurban from "./pages/location-based-pages/local-movers-near-me-durban-section";
import localmoversnearmeeastlondon from "./pages/location-based-pages/local-movers-near-me-east-london-section";
import localmoversnearmeferndale from "./pages/location-based-pages/local-movers-near-me-ferndale-section";
import localmoversnearmegeorge from "./pages/location-based-pages/local-movers-near-me-george-section";
import localmoversnearmegraaffreinet from "./pages/location-based-pages/local-movers-near-me-graaff-reinet-section";
import localmoversnearmegrahamstown from "./pages/location-based-pages/local-movers-near-me-grahamstown-section";
import localmoversnearmehermanus from "./pages/location-based-pages/local-movers-near-me-hermanus-section";
import localmoversnearmejeffreysbay from "./pages/location-based-pages/local-movers-near-me-jeffreys-bay-section";
import localmoversnearmejohannesburg from "./pages/location-based-pages/local-movers-near-me-johannesburg-section";
import localmoversnearmekimberley from "./pages/location-based-pages/local-movers-near-me-kimberley-section";
import localmoversnearmeklerksdorp from "./pages/location-based-pages/local-movers-near-me-klerksdorp-section";
import localmoversnearmeknysna from "./pages/location-based-pages/local-movers-near-me-knysna-section";
import localmoversnearmekroonstad from "./pages/location-based-pages/local-movers-near-me-kroonstad-section";
import localmoversnearmekuilsrivier from "./pages/location-based-pages/local-movers-near-me-kuilsrivier-section";
import localmoversnearmeladysmith from "./pages/location-based-pages/local-movers-near-me-ladysmith-section";
import localmoversnearmelebowakgomo from "./pages/location-based-pages/local-movers-near-me-lebowakgomo-section";
import localmoversnearmelichtenburg from "./pages/location-based-pages/local-movers-near-me-lichtenburg-section";
import localmoversnearmemahikeng from "./pages/location-based-pages/local-movers-near-me-mahikeng-section";
import localmoversnearmemalmesbury from "./pages/location-based-pages/local-movers-near-me-malmesbury-section";
import localmoversnearmemiddelburg from "./pages/location-based-pages/local-movers-near-me-middelburg-section";
import localmoversnearmemidrand from "./pages/location-based-pages/local-movers-near-me-midrand-section";
import localmoversnearmemmabatho from "./pages/location-based-pages/local-movers-near-me-mmabatho-section";
import localmoversnearmemosselbay from "./pages/location-based-pages/local-movers-near-me-mossel-bay-section";
import localmoversnearmemusina from "./pages/location-based-pages/local-movers-near-me-musina-section";
import localmoversnearmenelspruit from "./pages/location-based-pages/local-movers-near-me-nelspruit-section";
import localmoversnearmeoudtshoorn from "./pages/location-based-pages/local-movers-near-me-oudtshoorn-section";
import localmoversnearmepaarl from "./pages/location-based-pages/local-movers-near-me-paarl-section";
import localmoversnearmepietermaritzburg from "./pages/location-based-pages/local-movers-near-me-pietermaritzburg-section";
import localmoversnearmepolokwane from "./pages/location-based-pages/local-movers-near-me-polokwane-section";
import localmoversnearmeportelizabeth from "./pages/location-based-pages/local-movers-near-me-port-elizabeth-section";
import localmoversnearmeportshepstone from "./pages/location-based-pages/local-movers-near-me-port-shepstone-section";
import localmoversnearmepotchefstroom from "./pages/location-based-pages/local-movers-near-me-potchefstroom-section";
import localmoversnearmepretoria from "./pages/location-based-pages/local-movers-near-me-pretoria-section";
import localmoversnearmequeenstown from "./pages/location-based-pages/local-movers-near-me-queenstown-section";
import localmoversnearmerandburg from "./pages/location-based-pages/local-movers-near-me-randburg-section";
import localmoversnearmeroodepoort from "./pages/location-based-pages/local-movers-near-me-roodepoort-section";
import localmoversnearmerustenburg from "./pages/location-based-pages/local-movers-near-me-rustenburg-section";
import localmoversnearmesaldanha from "./pages/location-based-pages/local-movers-near-me-saldanha-section";
import localmoversnearmesandton from "./pages/location-based-pages/local-movers-near-me-sandton-section";
import localmoversnearmesoweto from "./pages/location-based-pages/local-movers-near-me-soweto-section";
import localmoversnearmesprings from "./pages/location-based-pages/local-movers-near-me-springs-section";
import localmoversnearmestanderton from "./pages/location-based-pages/local-movers-near-me-standerton-section";
import localmoversnearmethohoyandou from "./pages/location-based-pages/local-movers-near-me-thohoyandou-section";
import localmoversnearmetzaneen from "./pages/location-based-pages/local-movers-near-me-tzaneen-section";
import localmoversnearmeuitenhage from "./pages/location-based-pages/local-movers-near-me-uitenhage-section";
import localmoversnearmeulundi from "./pages/location-based-pages/local-movers-near-me-ulundi-section";
import localmoversnearmeumtata from "./pages/location-based-pages/local-movers-near-me-umtata-section";
import localmoversnearmeupington from "./pages/location-based-pages/local-movers-near-me-upington-section";
import localmoversnearmevanderbijlpark from "./pages/location-based-pages/local-movers-near-me-vanderbijlpark-section";
import localmoversnearmevereeniging from "./pages/location-based-pages/local-movers-near-me-vereeniging-section";
import localmoversnearmevolksrust from "./pages/location-based-pages/local-movers-near-me-volksrust-section";
import localmoversnearmevredenburg from "./pages/location-based-pages/local-movers-near-me-vredenburg-section";
import localmoversnearmevryburg from "./pages/location-based-pages/local-movers-near-me-vryburg-section";
import localmoversnearmevryheid from "./pages/location-based-pages/local-movers-near-me-vryheid-section";
import localmoversnearmewelkom from "./pages/location-based-pages/local-movers-near-me-welkom-section";
import localmoversnearmeworcester from "./pages/location-based-pages/local-movers-near-me-worcester-section";
import longdistancealberton from "./pages/location-based-pages/long-distance-alberton-section";
import longdistancealiwalnorth from "./pages/location-based-pages/long-distance-aliwal-north-section";
import longdistancebeaufortwest from "./pages/location-based-pages/long-distance-beaufort-west-section";
import longdistancebenoni from "./pages/location-based-pages/long-distance-benoni-section";
import longdistancebethal from "./pages/location-based-pages/long-distance-bethal-section";
import longdistancebhisho from "./pages/location-based-pages/long-distance-bhisho-section";
import longdistancebloemfontein from "./pages/location-based-pages/long-distance-bloemfontein-section";
import longdistancebrakpan from "./pages/location-based-pages/long-distance-brakpan-section";
import longdistancebrits from "./pages/location-based-pages/long-distance-brits-section";
import longdistancecapetown from "./pages/location-based-pages/long-distance-cape-town-section";
import longdistancecarletonville from "./pages/location-based-pages/long-distance-carletonville-section";
import longdistancecenturion from "./pages/location-based-pages/long-distance-centurion-section";
import longdistancecradock from "./pages/location-based-pages/long-distance-cradock-section";
import longdistancedeaar from "./pages/location-based-pages/long-distance-de-aar-section";
import longdistancedurban from "./pages/location-based-pages/long-distance-durban-section";
import longdistanceeastlondon from "./pages/location-based-pages/long-distance-east-london-section";
import longdistanceferndale from "./pages/location-based-pages/long-distance-ferndale-section";
import longdistancegeorge from "./pages/location-based-pages/long-distance-george-section";
import longdistancegraaffreinet from "./pages/location-based-pages/long-distance-graaff-reinet-section";
import longdistancegrahamstown from "./pages/location-based-pages/long-distance-grahamstown-section";
import longdistancehermanus from "./pages/location-based-pages/long-distance-hermanus-section";
import longdistancejeffreysbay from "./pages/location-based-pages/long-distance-jeffreys-bay-section";
import longdistancejohannesburg from "./pages/location-based-pages/long-distance-johannesburg-section";
import longdistancekimberley from "./pages/location-based-pages/long-distance-kimberley-section";
import longdistanceklerksdorp from "./pages/location-based-pages/long-distance-klerksdorp-section";
import longdistanceknysna from "./pages/location-based-pages/long-distance-knysna-section";
import longdistancekroonstad from "./pages/location-based-pages/long-distance-kroonstad-section";
import longdistancekuilsrivier from "./pages/location-based-pages/long-distance-kuilsrivier-section";
import longdistanceladysmith from "./pages/location-based-pages/long-distance-ladysmith-section";
import longdistancelebowakgomo from "./pages/location-based-pages/long-distance-lebowakgomo-section";
import longdistancelichtenburg from "./pages/location-based-pages/long-distance-lichtenburg-section";
import longdistancemahikeng from "./pages/location-based-pages/long-distance-mahikeng-section";
import longdistancemalmesbury from "./pages/location-based-pages/long-distance-malmesbury-section";
import longdistancemiddelburg from "./pages/location-based-pages/long-distance-middelburg-section";
import longdistancemidrand from "./pages/location-based-pages/long-distance-midrand-section";
import longdistancemmabatho from "./pages/location-based-pages/long-distance-mmabatho-section";
import longdistancemosselbay from "./pages/location-based-pages/long-distance-mossel-bay-section";
import longdistancemusina from "./pages/location-based-pages/long-distance-musina-section";
import longdistancenelspruit from "./pages/location-based-pages/long-distance-nelspruit-section";
import longdistanceoudtshoorn from "./pages/location-based-pages/long-distance-oudtshoorn-section";
import longdistancepaarl from "./pages/location-based-pages/long-distance-paarl-section";
import longdistancepietermaritzburg from "./pages/location-based-pages/long-distance-pietermaritzburg-section";
import longdistancepolokwane from "./pages/location-based-pages/long-distance-polokwane-section";
import longdistanceportelizabeth from "./pages/location-based-pages/long-distance-port-elizabeth-section";
import longdistanceportshepstone from "./pages/location-based-pages/long-distance-port-shepstone-section";
import longdistancepotchefstroom from "./pages/location-based-pages/long-distance-potchefstroom-section";
import longdistancepretoria from "./pages/location-based-pages/long-distance-pretoria-section";
import longdistancequeenstown from "./pages/location-based-pages/long-distance-queenstown-section";
import longdistancerandburg from "./pages/location-based-pages/long-distance-randburg-section";
import longdistanceroodepoort from "./pages/location-based-pages/long-distance-roodepoort-section";
import longdistancerustenburg from "./pages/location-based-pages/long-distance-rustenburg-section";
import longdistancesaldanha from "./pages/location-based-pages/long-distance-saldanha-section";
import longdistancesandton from "./pages/location-based-pages/long-distance-sandton-section";
import longdistancesoweto from "./pages/location-based-pages/long-distance-soweto-section";
import longdistancesprings from "./pages/location-based-pages/long-distance-springs-section";
import longdistancestanderton from "./pages/location-based-pages/long-distance-standerton-section";
import longdistancethohoyandou from "./pages/location-based-pages/long-distance-thohoyandou-section";
import longdistancetzaneen from "./pages/location-based-pages/long-distance-tzaneen-section";
import longdistanceuitenhage from "./pages/location-based-pages/long-distance-uitenhage-section";
import longdistanceulundi from "./pages/location-based-pages/long-distance-ulundi-section";
import longdistanceumtata from "./pages/location-based-pages/long-distance-umtata-section";
import longdistanceupington from "./pages/location-based-pages/long-distance-upington-section";
import longdistancevanderbijlpark from "./pages/location-based-pages/long-distance-vanderbijlpark-section";
import longdistancevereeniging from "./pages/location-based-pages/long-distance-vereeniging-section";
import longdistancevolksrust from "./pages/location-based-pages/long-distance-volksrust-section";
import longdistancevredenburg from "./pages/location-based-pages/long-distance-vredenburg-section";
import longdistancevryburg from "./pages/location-based-pages/long-distance-vryburg-section";
import longdistancevryheid from "./pages/location-based-pages/long-distance-vryheid-section";
import longdistancewelkom from "./pages/location-based-pages/long-distance-welkom-section";
import longdistanceworcester from "./pages/location-based-pages/long-distance-worcester-section";
import mightyex8alberton from "./pages/location-based-pages/mighty-ex8-alberton-section";
import mightyex8aliwalnorth from "./pages/location-based-pages/mighty-ex8-aliwal-north-section";
import mightyex8beaufortwest from "./pages/location-based-pages/mighty-ex8-beaufort-west-section";
import mightyex8benoni from "./pages/location-based-pages/mighty-ex8-benoni-section";
import mightyex8bethal from "./pages/location-based-pages/mighty-ex8-bethal-section";
import mightyex8bhisho from "./pages/location-based-pages/mighty-ex8-bhisho-section";
import mightyex8bloemfontein from "./pages/location-based-pages/mighty-ex8-bloemfontein-section";
import mightyex8brakpan from "./pages/location-based-pages/mighty-ex8-brakpan-section";
import mightyex8brits from "./pages/location-based-pages/mighty-ex8-brits-section";
import mightyex8capetown from "./pages/location-based-pages/mighty-ex8-cape-town-section";
import mightyex8carletonville from "./pages/location-based-pages/mighty-ex8-carletonville-section";
import mightyex8centurion from "./pages/location-based-pages/mighty-ex8-centurion-section";
import mightyex8cradock from "./pages/location-based-pages/mighty-ex8-cradock-section";
import mightyex8deaar from "./pages/location-based-pages/mighty-ex8-de-aar-section";
import mightyex8durban from "./pages/location-based-pages/mighty-ex8-durban-section";
import mightyex8eastlondon from "./pages/location-based-pages/mighty-ex8-east-london-section";
import mightyex8ferndale from "./pages/location-based-pages/mighty-ex8-ferndale-section";
import mightyex8george from "./pages/location-based-pages/mighty-ex8-george-section";
import mightyex8graaffreinet from "./pages/location-based-pages/mighty-ex8-graaff-reinet-section";
import mightyex8grahamstown from "./pages/location-based-pages/mighty-ex8-grahamstown-section";
import mightyex8hermanus from "./pages/location-based-pages/mighty-ex8-hermanus-section";
import mightyex8jeffreysbay from "./pages/location-based-pages/mighty-ex8-jeffreys-bay-section";
import mightyex8johannesburg from "./pages/location-based-pages/mighty-ex8-johannesburg-section";
import mightyex8kimberley from "./pages/location-based-pages/mighty-ex8-kimberley-section";
import mightyex8klerksdorp from "./pages/location-based-pages/mighty-ex8-klerksdorp-section";
import mightyex8knysna from "./pages/location-based-pages/mighty-ex8-knysna-section";
import mightyex8kroonstad from "./pages/location-based-pages/mighty-ex8-kroonstad-section";
import mightyex8kuilsrivier from "./pages/location-based-pages/mighty-ex8-kuilsrivier-section";
import mightyex8ladysmith from "./pages/location-based-pages/mighty-ex8-ladysmith-section";
import mightyex8lebowakgomo from "./pages/location-based-pages/mighty-ex8-lebowakgomo-section";
import mightyex8lichtenburg from "./pages/location-based-pages/mighty-ex8-lichtenburg-section";
import mightyex8mahikeng from "./pages/location-based-pages/mighty-ex8-mahikeng-section";
import mightyex8malmesbury from "./pages/location-based-pages/mighty-ex8-malmesbury-section";
import mightyex8middelburg from "./pages/location-based-pages/mighty-ex8-middelburg-section";
import mightyex8midrand from "./pages/location-based-pages/mighty-ex8-midrand-section";
import mightyex8mmabatho from "./pages/location-based-pages/mighty-ex8-mmabatho-section";
import mightyex8mosselbay from "./pages/location-based-pages/mighty-ex8-mossel-bay-section";
import mightyex8musina from "./pages/location-based-pages/mighty-ex8-musina-section";
import mightyex8nelspruit from "./pages/location-based-pages/mighty-ex8-nelspruit-section";
import mightyex8oudtshoorn from "./pages/location-based-pages/mighty-ex8-oudtshoorn-section";
import mightyex8paarl from "./pages/location-based-pages/mighty-ex8-paarl-section";
import mightyex8pietermaritzburg from "./pages/location-based-pages/mighty-ex8-pietermaritzburg-section";
import mightyex8polokwane from "./pages/location-based-pages/mighty-ex8-polokwane-section";
import mightyex8portelizabeth from "./pages/location-based-pages/mighty-ex8-port-elizabeth-section";
import mightyex8portshepstone from "./pages/location-based-pages/mighty-ex8-port-shepstone-section";
import mightyex8potchefstroom from "./pages/location-based-pages/mighty-ex8-potchefstroom-section";
import mightyex8pretoria from "./pages/location-based-pages/mighty-ex8-pretoria-section";
import mightyex8queenstown from "./pages/location-based-pages/mighty-ex8-queenstown-section";
import mightyex8randburg from "./pages/location-based-pages/mighty-ex8-randburg-section";
import mightyex8roodepoort from "./pages/location-based-pages/mighty-ex8-roodepoort-section";
import mightyex8rustenburg from "./pages/location-based-pages/mighty-ex8-rustenburg-section";
import mightyex8saldanha from "./pages/location-based-pages/mighty-ex8-saldanha-section";
import mightyex8sandton from "./pages/location-based-pages/mighty-ex8-sandton-section";
import mightyex8soweto from "./pages/location-based-pages/mighty-ex8-soweto-section";
import mightyex8springs from "./pages/location-based-pages/mighty-ex8-springs-section";
import mightyex8standerton from "./pages/location-based-pages/mighty-ex8-standerton-section";
import mightyex8thohoyandou from "./pages/location-based-pages/mighty-ex8-thohoyandou-section";
import mightyex8tzaneen from "./pages/location-based-pages/mighty-ex8-tzaneen-section";
import mightyex8uitenhage from "./pages/location-based-pages/mighty-ex8-uitenhage-section";
import mightyex8ulundi from "./pages/location-based-pages/mighty-ex8-ulundi-section";
import mightyex8umtata from "./pages/location-based-pages/mighty-ex8-umtata-section";
import mightyex8upington from "./pages/location-based-pages/mighty-ex8-upington-section";
import mightyex8vanderbijlpark from "./pages/location-based-pages/mighty-ex8-vanderbijlpark-section";
import mightyex8vereeniging from "./pages/location-based-pages/mighty-ex8-vereeniging-section";
import mightyex8volksrust from "./pages/location-based-pages/mighty-ex8-volksrust-section";
import mightyex8vredenburg from "./pages/location-based-pages/mighty-ex8-vredenburg-section";
import mightyex8vryburg from "./pages/location-based-pages/mighty-ex8-vryburg-section";
import mightyex8vryheid from "./pages/location-based-pages/mighty-ex8-vryheid-section";
import mightyex8welkom from "./pages/location-based-pages/mighty-ex8-welkom-section";
import mightyex8worcester from "./pages/location-based-pages/mighty-ex8-worcester-section";
import movingalberton from "./pages/location-based-pages/moving-alberton-section";
import movingaliwalnorth from "./pages/location-based-pages/moving-aliwal-north-section";
import movingbeaufortwest from "./pages/location-based-pages/moving-beaufort-west-section";
import movingbenoni from "./pages/location-based-pages/moving-benoni-section";
import movingbethal from "./pages/location-based-pages/moving-bethal-section";
import movingbhisho from "./pages/location-based-pages/moving-bhisho-section";
import movingbloemfontein from "./pages/location-based-pages/moving-bloemfontein-section";
import movingbrakpan from "./pages/location-based-pages/moving-brakpan-section";
import movingbrits from "./pages/location-based-pages/moving-brits-section";
import movingcapetown from "./pages/location-based-pages/moving-cape-town-section";
import movingcarletonville from "./pages/location-based-pages/moving-carletonville-section";
import movingcenturion from "./pages/location-based-pages/moving-centurion-section";
import movingcradock from "./pages/location-based-pages/moving-cradock-section";
import movingdeaar from "./pages/location-based-pages/moving-de-aar-section";
import movingdurban from "./pages/location-based-pages/moving-durban-section";
import movingeastlondon from "./pages/location-based-pages/moving-east-london-section";
import movingferndale from "./pages/location-based-pages/moving-ferndale-section";
import movinggeorge from "./pages/location-based-pages/moving-george-section";
import movinggraaffreinet from "./pages/location-based-pages/moving-graaff-reinet-section";
import movinggrahamstown from "./pages/location-based-pages/moving-grahamstown-section";
import movinghermanus from "./pages/location-based-pages/moving-hermanus-section";
import movingjeffreysbay from "./pages/location-based-pages/moving-jeffreys-bay-section";
import movingjohannesburg from "./pages/location-based-pages/moving-johannesburg-section";
import movingkimberley from "./pages/location-based-pages/moving-kimberley-section";
import movingklerksdorp from "./pages/location-based-pages/moving-klerksdorp-section";
import movingknysna from "./pages/location-based-pages/moving-knysna-section";
import movingkroonstad from "./pages/location-based-pages/moving-kroonstad-section";
import movingkuilsrivier from "./pages/location-based-pages/moving-kuilsrivier-section";
import movingladysmith from "./pages/location-based-pages/moving-ladysmith-section";
import movinglebowakgomo from "./pages/location-based-pages/moving-lebowakgomo-section";
import movinglichtenburg from "./pages/location-based-pages/moving-lichtenburg-section";
import movingmahikeng from "./pages/location-based-pages/moving-mahikeng-section";
import movingmalmesbury from "./pages/location-based-pages/moving-malmesbury-section";
import movingmiddelburg from "./pages/location-based-pages/moving-middelburg-section";
import movingmidrand from "./pages/location-based-pages/moving-midrand-section";
import movingmmabatho from "./pages/location-based-pages/moving-mmabatho-section";
import movingmosselbay from "./pages/location-based-pages/moving-mossel-bay-section";
import movingmusina from "./pages/location-based-pages/moving-musina-section";
import movingnelspruit from "./pages/location-based-pages/moving-nelspruit-section";
import movingoudtshoorn from "./pages/location-based-pages/moving-oudtshoorn-section";
import movingoutalberton from "./pages/location-based-pages/moving-out-alberton-section";
import movingoutaliwalnorth from "./pages/location-based-pages/moving-out-aliwal-north-section";
import movingoutbeaufortwest from "./pages/location-based-pages/moving-out-beaufort-west-section";
import movingoutbenoni from "./pages/location-based-pages/moving-out-benoni-section";
import movingoutbethal from "./pages/location-based-pages/moving-out-bethal-section";
import movingoutbhisho from "./pages/location-based-pages/moving-out-bhisho-section";
import movingoutbloemfontein from "./pages/location-based-pages/moving-out-bloemfontein-section";
import movingoutbrakpan from "./pages/location-based-pages/moving-out-brakpan-section";
import movingoutbrits from "./pages/location-based-pages/moving-out-brits-section";
import movingoutcapetown from "./pages/location-based-pages/moving-out-cape-town-section";
import movingoutcarletonville from "./pages/location-based-pages/moving-out-carletonville-section";
import movingoutcenturion from "./pages/location-based-pages/moving-out-centurion-section";
import movingoutcradock from "./pages/location-based-pages/moving-out-cradock-section";
import movingoutdeaar from "./pages/location-based-pages/moving-out-de-aar-section";
import movingoutdurban from "./pages/location-based-pages/moving-out-durban-section";
import movingouteastlondon from "./pages/location-based-pages/moving-out-east-london-section";
import movingoutferndale from "./pages/location-based-pages/moving-out-ferndale-section";
import movingoutgeorge from "./pages/location-based-pages/moving-out-george-section";
import movingoutgraaffreinet from "./pages/location-based-pages/moving-out-graaff-reinet-section";
import movingoutgrahamstown from "./pages/location-based-pages/moving-out-grahamstown-section";
import movingouthermanus from "./pages/location-based-pages/moving-out-hermanus-section";
import movingoutjeffreysbay from "./pages/location-based-pages/moving-out-jeffreys-bay-section";
import movingoutjohannesburg from "./pages/location-based-pages/moving-out-johannesburg-section";
import movingoutkimberley from "./pages/location-based-pages/moving-out-kimberley-section";
import movingoutklerksdorp from "./pages/location-based-pages/moving-out-klerksdorp-section";
import movingoutknysna from "./pages/location-based-pages/moving-out-knysna-section";
import movingoutkroonstad from "./pages/location-based-pages/moving-out-kroonstad-section";
import movingoutkuilsrivier from "./pages/location-based-pages/moving-out-kuilsrivier-section";
import movingoutladysmith from "./pages/location-based-pages/moving-out-ladysmith-section";
import movingoutlebowakgomo from "./pages/location-based-pages/moving-out-lebowakgomo-section";
import movingoutlichtenburg from "./pages/location-based-pages/moving-out-lichtenburg-section";
import movingoutmahikeng from "./pages/location-based-pages/moving-out-mahikeng-section";
import movingoutmalmesbury from "./pages/location-based-pages/moving-out-malmesbury-section";
import movingoutmiddelburg from "./pages/location-based-pages/moving-out-middelburg-section";
import movingoutmidrand from "./pages/location-based-pages/moving-out-midrand-section";
import movingoutmmabatho from "./pages/location-based-pages/moving-out-mmabatho-section";
import movingoutmosselbay from "./pages/location-based-pages/moving-out-mossel-bay-section";
import movingoutmusina from "./pages/location-based-pages/moving-out-musina-section";
import movingoutnelspruit from "./pages/location-based-pages/moving-out-nelspruit-section";
import movingoutoudtshoorn from "./pages/location-based-pages/moving-out-oudtshoorn-section";
import movingoutpaarl from "./pages/location-based-pages/moving-out-paarl-section";
import movingoutpietermaritzburg from "./pages/location-based-pages/moving-out-pietermaritzburg-section";
import movingoutpolokwane from "./pages/location-based-pages/moving-out-polokwane-section";
import movingoutportelizabeth from "./pages/location-based-pages/moving-out-port-elizabeth-section";
import movingoutportshepstone from "./pages/location-based-pages/moving-out-port-shepstone-section";
import movingoutpotchefstroom from "./pages/location-based-pages/moving-out-potchefstroom-section";
import movingoutpretoria from "./pages/location-based-pages/moving-out-pretoria-section";
import movingoutqueenstown from "./pages/location-based-pages/moving-out-queenstown-section";
import movingoutrandburg from "./pages/location-based-pages/moving-out-randburg-section";
import movingoutroodepoort from "./pages/location-based-pages/moving-out-roodepoort-section";
import movingoutrustenburg from "./pages/location-based-pages/moving-out-rustenburg-section";
import movingoutsaldanha from "./pages/location-based-pages/moving-out-saldanha-section";
import movingoutsandton from "./pages/location-based-pages/moving-out-sandton-section";
import movingoutsoweto from "./pages/location-based-pages/moving-out-soweto-section";
import movingoutsprings from "./pages/location-based-pages/moving-out-springs-section";
import movingoutstanderton from "./pages/location-based-pages/moving-out-standerton-section";
import movingoutthohoyandou from "./pages/location-based-pages/moving-out-thohoyandou-section";
import movingouttzaneen from "./pages/location-based-pages/moving-out-tzaneen-section";
import movingoutuitenhage from "./pages/location-based-pages/moving-out-uitenhage-section";
import movingoutulundi from "./pages/location-based-pages/moving-out-ulundi-section";
import movingoutumtata from "./pages/location-based-pages/moving-out-umtata-section";
import movingoutupington from "./pages/location-based-pages/moving-out-upington-section";
import movingoutvanderbijlpark from "./pages/location-based-pages/moving-out-vanderbijlpark-section";
import movingoutvereeniging from "./pages/location-based-pages/moving-out-vereeniging-section";
import movingoutvolksrust from "./pages/location-based-pages/moving-out-volksrust-section";
import movingoutvredenburg from "./pages/location-based-pages/moving-out-vredenburg-section";
import movingoutvryburg from "./pages/location-based-pages/moving-out-vryburg-section";
import movingoutvryheid from "./pages/location-based-pages/moving-out-vryheid-section";
import movingoutwelkom from "./pages/location-based-pages/moving-out-welkom-section";
import movingoutworcester from "./pages/location-based-pages/moving-out-worcester-section";
import movingpaarl from "./pages/location-based-pages/moving-paarl-section";
import movingpietermaritzburg from "./pages/location-based-pages/moving-pietermaritzburg-section";
import movingpolokwane from "./pages/location-based-pages/moving-polokwane-section";
import movingportelizabeth from "./pages/location-based-pages/moving-port-elizabeth-section";
import movingportshepstone from "./pages/location-based-pages/moving-port-shepstone-section";
import movingpotchefstroom from "./pages/location-based-pages/moving-potchefstroom-section";
import movingpretoria from "./pages/location-based-pages/moving-pretoria-section";
import movingqueenstown from "./pages/location-based-pages/moving-queenstown-section";
import movingrandburg from "./pages/location-based-pages/moving-randburg-section";
import movingroodepoort from "./pages/location-based-pages/moving-roodepoort-section";
import movingrustenburg from "./pages/location-based-pages/moving-rustenburg-section";
import movingsaldanha from "./pages/location-based-pages/moving-saldanha-section";
import movingsandton from "./pages/location-based-pages/moving-sandton-section";
import movingsoweto from "./pages/location-based-pages/moving-soweto-section";
import movingsprings from "./pages/location-based-pages/moving-springs-section";
import movingstanderton from "./pages/location-based-pages/moving-standerton-section";
import movingthohoyandou from "./pages/location-based-pages/moving-thohoyandou-section";
import movingtzaneen from "./pages/location-based-pages/moving-tzaneen-section";
import movinguitenhage from "./pages/location-based-pages/moving-uitenhage-section";
import movingulundi from "./pages/location-based-pages/moving-ulundi-section";
import movingumtata from "./pages/location-based-pages/moving-umtata-section";
import movingupington from "./pages/location-based-pages/moving-upington-section";
import movingvanderbijlpark from "./pages/location-based-pages/moving-vanderbijlpark-section";
import movingvereeniging from "./pages/location-based-pages/moving-vereeniging-section";
import movingvolksrust from "./pages/location-based-pages/moving-volksrust-section";
import movingvredenburg from "./pages/location-based-pages/moving-vredenburg-section";
import movingvryburg from "./pages/location-based-pages/moving-vryburg-section";
import movingvryheid from "./pages/location-based-pages/moving-vryheid-section";
import movingwelkom from "./pages/location-based-pages/moving-welkom-section";
import movingworcester from "./pages/location-based-pages/moving-worcester-section";
import needdeliveryalberton from "./pages/location-based-pages/need-delivery-alberton-section";
import needdeliveryaliwalnorth from "./pages/location-based-pages/need-delivery-aliwal-north-section";
import needdeliverybeaufortwest from "./pages/location-based-pages/need-delivery-beaufort-west-section";
import needdeliverybenoni from "./pages/location-based-pages/need-delivery-benoni-section";
import needdeliverybethal from "./pages/location-based-pages/need-delivery-bethal-section";
import needdeliverybhisho from "./pages/location-based-pages/need-delivery-bhisho-section";
import needdeliverybloemfontein from "./pages/location-based-pages/need-delivery-bloemfontein-section";
import needdeliverybrakpan from "./pages/location-based-pages/need-delivery-brakpan-section";
import needdeliverybrits from "./pages/location-based-pages/need-delivery-brits-section";
import needdeliverycapetown from "./pages/location-based-pages/need-delivery-cape-town-section";
import needdeliverycarletonville from "./pages/location-based-pages/need-delivery-carletonville-section";
import needdeliverycenturion from "./pages/location-based-pages/need-delivery-centurion-section";
import needdeliverycradock from "./pages/location-based-pages/need-delivery-cradock-section";
import needdeliverydeaar from "./pages/location-based-pages/need-delivery-de-aar-section";
import needdeliverydurban from "./pages/location-based-pages/need-delivery-durban-section";
import needdeliveryeastlondon from "./pages/location-based-pages/need-delivery-east-london-section";
import needdeliveryferndale from "./pages/location-based-pages/need-delivery-ferndale-section";
import needdeliverygeorge from "./pages/location-based-pages/need-delivery-george-section";
import needdeliverygraaffreinet from "./pages/location-based-pages/need-delivery-graaff-reinet-section";
import needdeliverygrahamstown from "./pages/location-based-pages/need-delivery-grahamstown-section";
import needdeliveryhermanus from "./pages/location-based-pages/need-delivery-hermanus-section";
import needdeliveryjeffreysbay from "./pages/location-based-pages/need-delivery-jeffreys-bay-section";
import needdeliveryjohannesburg from "./pages/location-based-pages/need-delivery-johannesburg-section";
import needdeliverykimberley from "./pages/location-based-pages/need-delivery-kimberley-section";
import needdeliveryklerksdorp from "./pages/location-based-pages/need-delivery-klerksdorp-section";
import needdeliveryknysna from "./pages/location-based-pages/need-delivery-knysna-section";
import needdeliverykroonstad from "./pages/location-based-pages/need-delivery-kroonstad-section";
import needdeliverykuilsrivier from "./pages/location-based-pages/need-delivery-kuilsrivier-section";
import needdeliveryladysmith from "./pages/location-based-pages/need-delivery-ladysmith-section";
import needdeliverylebowakgomo from "./pages/location-based-pages/need-delivery-lebowakgomo-section";
import needdeliverylichtenburg from "./pages/location-based-pages/need-delivery-lichtenburg-section";
import needdeliverymahikeng from "./pages/location-based-pages/need-delivery-mahikeng-section";
import needdeliverymalmesbury from "./pages/location-based-pages/need-delivery-malmesbury-section";
import needdeliverymiddelburg from "./pages/location-based-pages/need-delivery-middelburg-section";
import needdeliverymidrand from "./pages/location-based-pages/need-delivery-midrand-section";
import needdeliverymmabatho from "./pages/location-based-pages/need-delivery-mmabatho-section";
import needdeliverymosselbay from "./pages/location-based-pages/need-delivery-mossel-bay-section";
import needdeliverymusina from "./pages/location-based-pages/need-delivery-musina-section";
import needdeliverynelspruit from "./pages/location-based-pages/need-delivery-nelspruit-section";
import needdeliveryoudtshoorn from "./pages/location-based-pages/need-delivery-oudtshoorn-section";
import needdeliverypaarl from "./pages/location-based-pages/need-delivery-paarl-section";
import needdeliverypietermaritzburg from "./pages/location-based-pages/need-delivery-pietermaritzburg-section";
import needdeliverypolokwane from "./pages/location-based-pages/need-delivery-polokwane-section";
import needdeliveryportelizabeth from "./pages/location-based-pages/need-delivery-port-elizabeth-section";
import needdeliveryportshepstone from "./pages/location-based-pages/need-delivery-port-shepstone-section";
import needdeliverypotchefstroom from "./pages/location-based-pages/need-delivery-potchefstroom-section";
import needdeliverypretoria from "./pages/location-based-pages/need-delivery-pretoria-section";
import needdeliveryqueenstown from "./pages/location-based-pages/need-delivery-queenstown-section";
import needdeliveryrandburg from "./pages/location-based-pages/need-delivery-randburg-section";
import needdeliveryroodepoort from "./pages/location-based-pages/need-delivery-roodepoort-section";
import needdeliveryrustenburg from "./pages/location-based-pages/need-delivery-rustenburg-section";
import needdeliverysaldanha from "./pages/location-based-pages/need-delivery-saldanha-section";
import needdeliverysandton from "./pages/location-based-pages/need-delivery-sandton-section";
import needdeliverysoweto from "./pages/location-based-pages/need-delivery-soweto-section";
import needdeliverysprings from "./pages/location-based-pages/need-delivery-springs-section";
import needdeliverystanderton from "./pages/location-based-pages/need-delivery-standerton-section";
import needdeliverythohoyandou from "./pages/location-based-pages/need-delivery-thohoyandou-section";
import needdeliverytzaneen from "./pages/location-based-pages/need-delivery-tzaneen-section";
import needdeliveryuitenhage from "./pages/location-based-pages/need-delivery-uitenhage-section";
import needdeliveryulundi from "./pages/location-based-pages/need-delivery-ulundi-section";
import needdeliveryumtata from "./pages/location-based-pages/need-delivery-umtata-section";
import needdeliveryupington from "./pages/location-based-pages/need-delivery-upington-section";
import needdeliveryvanderbijlpark from "./pages/location-based-pages/need-delivery-vanderbijlpark-section";
import needdeliveryvereeniging from "./pages/location-based-pages/need-delivery-vereeniging-section";
import needdeliveryvolksrust from "./pages/location-based-pages/need-delivery-volksrust-section";
import needdeliveryvredenburg from "./pages/location-based-pages/need-delivery-vredenburg-section";
import needdeliveryvryburg from "./pages/location-based-pages/need-delivery-vryburg-section";
import needdeliveryvryheid from "./pages/location-based-pages/need-delivery-vryheid-section";
import needdeliverywelkom from "./pages/location-based-pages/need-delivery-welkom-section";
import needdeliveryworcester from "./pages/location-based-pages/need-delivery-worcester-section";
import onewaytruckalberton from "./pages/location-based-pages/one-way-truck-alberton-section";
import onewaytruckaliwalnorth from "./pages/location-based-pages/one-way-truck-aliwal-north-section";
import onewaytruckbeaufortwest from "./pages/location-based-pages/one-way-truck-beaufort-west-section";
import onewaytruckbenoni from "./pages/location-based-pages/one-way-truck-benoni-section";
import onewaytruckbethal from "./pages/location-based-pages/one-way-truck-bethal-section";
import onewaytruckbhisho from "./pages/location-based-pages/one-way-truck-bhisho-section";
import onewaytruckbloemfontein from "./pages/location-based-pages/one-way-truck-bloemfontein-section";
import onewaytruckbrakpan from "./pages/location-based-pages/one-way-truck-brakpan-section";
import onewaytruckbrits from "./pages/location-based-pages/one-way-truck-brits-section";
import onewaytruckcapetown from "./pages/location-based-pages/one-way-truck-cape-town-section";
import onewaytruckcarletonville from "./pages/location-based-pages/one-way-truck-carletonville-section";
import onewaytruckcenturion from "./pages/location-based-pages/one-way-truck-centurion-section";
import onewaytruckcradock from "./pages/location-based-pages/one-way-truck-cradock-section";
import onewaytruckdeaar from "./pages/location-based-pages/one-way-truck-de-aar-section";
import onewaytruckdurban from "./pages/location-based-pages/one-way-truck-durban-section";
import onewaytruckeastlondon from "./pages/location-based-pages/one-way-truck-east-london-section";
import onewaytruckferndale from "./pages/location-based-pages/one-way-truck-ferndale-section";
import onewaytruckgeorge from "./pages/location-based-pages/one-way-truck-george-section";
import onewaytruckgraaffreinet from "./pages/location-based-pages/one-way-truck-graaff-reinet-section";
import onewaytruckgrahamstown from "./pages/location-based-pages/one-way-truck-grahamstown-section";
import onewaytruckhermanus from "./pages/location-based-pages/one-way-truck-hermanus-section";
import onewaytruckjeffreysbay from "./pages/location-based-pages/one-way-truck-jeffreys-bay-section";
import onewaytruckjohannesburg from "./pages/location-based-pages/one-way-truck-johannesburg-section";
import onewaytruckkimberley from "./pages/location-based-pages/one-way-truck-kimberley-section";
import onewaytruckklerksdorp from "./pages/location-based-pages/one-way-truck-klerksdorp-section";
import onewaytruckknysna from "./pages/location-based-pages/one-way-truck-knysna-section";
import onewaytruckkroonstad from "./pages/location-based-pages/one-way-truck-kroonstad-section";
import onewaytruckkuilsrivier from "./pages/location-based-pages/one-way-truck-kuilsrivier-section";
import onewaytruckladysmith from "./pages/location-based-pages/one-way-truck-ladysmith-section";
import onewaytrucklebowakgomo from "./pages/location-based-pages/one-way-truck-lebowakgomo-section";
import onewaytrucklichtenburg from "./pages/location-based-pages/one-way-truck-lichtenburg-section";
import onewaytruckmahikeng from "./pages/location-based-pages/one-way-truck-mahikeng-section";
import onewaytruckmalmesbury from "./pages/location-based-pages/one-way-truck-malmesbury-section";
import onewaytruckmiddelburg from "./pages/location-based-pages/one-way-truck-middelburg-section";
import onewaytruckmidrand from "./pages/location-based-pages/one-way-truck-midrand-section";
import onewaytruckmmabatho from "./pages/location-based-pages/one-way-truck-mmabatho-section";
import onewaytruckmosselbay from "./pages/location-based-pages/one-way-truck-mossel-bay-section";
import onewaytruckmusina from "./pages/location-based-pages/one-way-truck-musina-section";
import onewaytrucknelspruit from "./pages/location-based-pages/one-way-truck-nelspruit-section";
import onewaytruckoudtshoorn from "./pages/location-based-pages/one-way-truck-oudtshoorn-section";
import onewaytruckpaarl from "./pages/location-based-pages/one-way-truck-paarl-section";
import onewaytruckpietermaritzburg from "./pages/location-based-pages/one-way-truck-pietermaritzburg-section";
import onewaytruckpolokwane from "./pages/location-based-pages/one-way-truck-polokwane-section";
import onewaytruckportelizabeth from "./pages/location-based-pages/one-way-truck-port-elizabeth-section";
import onewaytruckportshepstone from "./pages/location-based-pages/one-way-truck-port-shepstone-section";
import onewaytruckpotchefstroom from "./pages/location-based-pages/one-way-truck-potchefstroom-section";
import onewaytruckpretoria from "./pages/location-based-pages/one-way-truck-pretoria-section";
import onewaytruckqueenstown from "./pages/location-based-pages/one-way-truck-queenstown-section";
import onewaytruckrandburg from "./pages/location-based-pages/one-way-truck-randburg-section";
import onewaytruckroodepoort from "./pages/location-based-pages/one-way-truck-roodepoort-section";
import onewaytruckrustenburg from "./pages/location-based-pages/one-way-truck-rustenburg-section";
import onewaytrucksaldanha from "./pages/location-based-pages/one-way-truck-saldanha-section";
import onewaytrucksandton from "./pages/location-based-pages/one-way-truck-sandton-section";
import onewaytrucksoweto from "./pages/location-based-pages/one-way-truck-soweto-section";
import onewaytrucksprings from "./pages/location-based-pages/one-way-truck-springs-section";
import onewaytruckstanderton from "./pages/location-based-pages/one-way-truck-standerton-section";
import onewaytruckthohoyandou from "./pages/location-based-pages/one-way-truck-thohoyandou-section";
import onewaytrucktzaneen from "./pages/location-based-pages/one-way-truck-tzaneen-section";
import onewaytruckuitenhage from "./pages/location-based-pages/one-way-truck-uitenhage-section";
import onewaytruckulundi from "./pages/location-based-pages/one-way-truck-ulundi-section";
import onewaytruckumtata from "./pages/location-based-pages/one-way-truck-umtata-section";
import onewaytruckupington from "./pages/location-based-pages/one-way-truck-upington-section";
import onewaytruckvanderbijlpark from "./pages/location-based-pages/one-way-truck-vanderbijlpark-section";
import onewaytruckvereeniging from "./pages/location-based-pages/one-way-truck-vereeniging-section";
import onewaytruckvolksrust from "./pages/location-based-pages/one-way-truck-volksrust-section";
import onewaytruckvredenburg from "./pages/location-based-pages/one-way-truck-vredenburg-section";
import onewaytruckvryburg from "./pages/location-based-pages/one-way-truck-vryburg-section";
import onewaytruckvryheid from "./pages/location-based-pages/one-way-truck-vryheid-section";
import onewaytruckwelkom from "./pages/location-based-pages/one-way-truck-welkom-section";
import onewaytruckworcester from "./pages/location-based-pages/one-way-truck-worcester-section";
import onlineorderdeliveryalberton from "./pages/location-based-pages/online-order-delivery-alberton-section";
import onlineorderdeliveryaliwalnorth from "./pages/location-based-pages/online-order-delivery-aliwal-north-section";
import onlineorderdeliverybeaufortwest from "./pages/location-based-pages/online-order-delivery-beaufort-west-section";
import onlineorderdeliverybenoni from "./pages/location-based-pages/online-order-delivery-benoni-section";
import onlineorderdeliverybethal from "./pages/location-based-pages/online-order-delivery-bethal-section";
import onlineorderdeliverybhisho from "./pages/location-based-pages/online-order-delivery-bhisho-section";
import onlineorderdeliverybloemfontein from "./pages/location-based-pages/online-order-delivery-bloemfontein-section";
import onlineorderdeliverybrakpan from "./pages/location-based-pages/online-order-delivery-brakpan-section";
import onlineorderdeliverybrits from "./pages/location-based-pages/online-order-delivery-brits-section";
import onlineorderdeliverycapetown from "./pages/location-based-pages/online-order-delivery-cape-town-section";
import onlineorderdeliverycarletonville from "./pages/location-based-pages/online-order-delivery-carletonville-section";
import onlineorderdeliverycenturion from "./pages/location-based-pages/online-order-delivery-centurion-section";
import onlineorderdeliverycradock from "./pages/location-based-pages/online-order-delivery-cradock-section";
import onlineorderdeliverydeaar from "./pages/location-based-pages/online-order-delivery-de-aar-section";
import onlineorderdeliverydurban from "./pages/location-based-pages/online-order-delivery-durban-section";
import onlineorderdeliveryeastlondon from "./pages/location-based-pages/online-order-delivery-east-london-section";
import onlineorderdeliveryferndale from "./pages/location-based-pages/online-order-delivery-ferndale-section";
import onlineorderdeliverygeorge from "./pages/location-based-pages/online-order-delivery-george-section";
import onlineorderdeliverygraaffreinet from "./pages/location-based-pages/online-order-delivery-graaff-reinet-section";
import onlineorderdeliverygrahamstown from "./pages/location-based-pages/online-order-delivery-grahamstown-section";
import onlineorderdeliveryhermanus from "./pages/location-based-pages/online-order-delivery-hermanus-section";
import onlineorderdeliveryjeffreysbay from "./pages/location-based-pages/online-order-delivery-jeffreys-bay-section";
import onlineorderdeliveryjohannesburg from "./pages/location-based-pages/online-order-delivery-johannesburg-section";
import onlineorderdeliverykimberley from "./pages/location-based-pages/online-order-delivery-kimberley-section";
import onlineorderdeliveryklerksdorp from "./pages/location-based-pages/online-order-delivery-klerksdorp-section";
import onlineorderdeliveryknysna from "./pages/location-based-pages/online-order-delivery-knysna-section";
import onlineorderdeliverykroonstad from "./pages/location-based-pages/online-order-delivery-kroonstad-section";
import onlineorderdeliverykuilsrivier from "./pages/location-based-pages/online-order-delivery-kuilsrivier-section";
import onlineorderdeliveryladysmith from "./pages/location-based-pages/online-order-delivery-ladysmith-section";
import onlineorderdeliverylebowakgomo from "./pages/location-based-pages/online-order-delivery-lebowakgomo-section";
import onlineorderdeliverylichtenburg from "./pages/location-based-pages/online-order-delivery-lichtenburg-section";
import onlineorderdeliverymahikeng from "./pages/location-based-pages/online-order-delivery-mahikeng-section";
import onlineorderdeliverymalmesbury from "./pages/location-based-pages/online-order-delivery-malmesbury-section";
import onlineorderdeliverymiddelburg from "./pages/location-based-pages/online-order-delivery-middelburg-section";
import onlineorderdeliverymidrand from "./pages/location-based-pages/online-order-delivery-midrand-section";
import onlineorderdeliverymmabatho from "./pages/location-based-pages/online-order-delivery-mmabatho-section";
import onlineorderdeliverymosselbay from "./pages/location-based-pages/online-order-delivery-mossel-bay-section";
import onlineorderdeliverymusina from "./pages/location-based-pages/online-order-delivery-musina-section";
import onlineorderdeliverynelspruit from "./pages/location-based-pages/online-order-delivery-nelspruit-section";
import onlineorderdeliveryoudtshoorn from "./pages/location-based-pages/online-order-delivery-oudtshoorn-section";
import onlineorderdeliverypaarl from "./pages/location-based-pages/online-order-delivery-paarl-section";
import onlineorderdeliverypietermaritzburg from "./pages/location-based-pages/online-order-delivery-pietermaritzburg-section";
import onlineorderdeliverypolokwane from "./pages/location-based-pages/online-order-delivery-polokwane-section";
import onlineorderdeliveryportelizabeth from "./pages/location-based-pages/online-order-delivery-port-elizabeth-section";
import onlineorderdeliveryportshepstone from "./pages/location-based-pages/online-order-delivery-port-shepstone-section";
import onlineorderdeliverypotchefstroom from "./pages/location-based-pages/online-order-delivery-potchefstroom-section";
import onlineorderdeliverypretoria from "./pages/location-based-pages/online-order-delivery-pretoria-section";
import onlineorderdeliveryqueenstown from "./pages/location-based-pages/online-order-delivery-queenstown-section";
import onlineorderdeliveryrandburg from "./pages/location-based-pages/online-order-delivery-randburg-section";
import onlineorderdeliveryroodepoort from "./pages/location-based-pages/online-order-delivery-roodepoort-section";
import onlineorderdeliveryrustenburg from "./pages/location-based-pages/online-order-delivery-rustenburg-section";
import onlineorderdeliverysaldanha from "./pages/location-based-pages/online-order-delivery-saldanha-section";
import onlineorderdeliverysandton from "./pages/location-based-pages/online-order-delivery-sandton-section";
import onlineorderdeliverysoweto from "./pages/location-based-pages/online-order-delivery-soweto-section";
import onlineorderdeliverysprings from "./pages/location-based-pages/online-order-delivery-springs-section";
import onlineorderdeliverystanderton from "./pages/location-based-pages/online-order-delivery-standerton-section";
import onlineorderdeliverythohoyandou from "./pages/location-based-pages/online-order-delivery-thohoyandou-section";
import onlineorderdeliverytzaneen from "./pages/location-based-pages/online-order-delivery-tzaneen-section";
import onlineorderdeliveryuitenhage from "./pages/location-based-pages/online-order-delivery-uitenhage-section";
import onlineorderdeliveryulundi from "./pages/location-based-pages/online-order-delivery-ulundi-section";
import onlineorderdeliveryumtata from "./pages/location-based-pages/online-order-delivery-umtata-section";
import onlineorderdeliveryupington from "./pages/location-based-pages/online-order-delivery-upington-section";
import onlineorderdeliveryvanderbijlpark from "./pages/location-based-pages/online-order-delivery-vanderbijlpark-section";
import onlineorderdeliveryvereeniging from "./pages/location-based-pages/online-order-delivery-vereeniging-section";
import onlineorderdeliveryvolksrust from "./pages/location-based-pages/online-order-delivery-volksrust-section";
import onlineorderdeliveryvredenburg from "./pages/location-based-pages/online-order-delivery-vredenburg-section";
import onlineorderdeliveryvryburg from "./pages/location-based-pages/online-order-delivery-vryburg-section";
import onlineorderdeliveryvryheid from "./pages/location-based-pages/online-order-delivery-vryheid-section";
import onlineorderdeliverywelkom from "./pages/location-based-pages/online-order-delivery-welkom-section";
import onlineorderdeliveryworcester from "./pages/location-based-pages/online-order-delivery-worcester-section";
import overnightdeliveryalberton from "./pages/location-based-pages/overnight-delivery-alberton-section";
import overnightdeliveryaliwalnorth from "./pages/location-based-pages/overnight-delivery-aliwal-north-section";
import overnightdeliverybeaufortwest from "./pages/location-based-pages/overnight-delivery-beaufort-west-section";
import overnightdeliverybenoni from "./pages/location-based-pages/overnight-delivery-benoni-section";
import overnightdeliverybethal from "./pages/location-based-pages/overnight-delivery-bethal-section";
import overnightdeliverybhisho from "./pages/location-based-pages/overnight-delivery-bhisho-section";
import overnightdeliverybloemfontein from "./pages/location-based-pages/overnight-delivery-bloemfontein-section";
import overnightdeliverybrakpan from "./pages/location-based-pages/overnight-delivery-brakpan-section";
import overnightdeliverybrits from "./pages/location-based-pages/overnight-delivery-brits-section";
import overnightdeliverycapetown from "./pages/location-based-pages/overnight-delivery-cape-town-section";
import overnightdeliverycarletonville from "./pages/location-based-pages/overnight-delivery-carletonville-section";
import overnightdeliverycenturion from "./pages/location-based-pages/overnight-delivery-centurion-section";
import overnightdeliverycradock from "./pages/location-based-pages/overnight-delivery-cradock-section";
import overnightdeliverydeaar from "./pages/location-based-pages/overnight-delivery-de-aar-section";
import overnightdeliverydurban from "./pages/location-based-pages/overnight-delivery-durban-section";
import overnightdeliveryeastlondon from "./pages/location-based-pages/overnight-delivery-east-london-section";
import overnightdeliveryferndale from "./pages/location-based-pages/overnight-delivery-ferndale-section";
import overnightdeliverygeorge from "./pages/location-based-pages/overnight-delivery-george-section";
import overnightdeliverygraaffreinet from "./pages/location-based-pages/overnight-delivery-graaff-reinet-section";
import overnightdeliverygrahamstown from "./pages/location-based-pages/overnight-delivery-grahamstown-section";
import overnightdeliveryhermanus from "./pages/location-based-pages/overnight-delivery-hermanus-section";
import overnightdeliveryjeffreysbay from "./pages/location-based-pages/overnight-delivery-jeffreys-bay-section";
import overnightdeliveryjohannesburg from "./pages/location-based-pages/overnight-delivery-johannesburg-section";
import overnightdeliverykimberley from "./pages/location-based-pages/overnight-delivery-kimberley-section";
import overnightdeliveryklerksdorp from "./pages/location-based-pages/overnight-delivery-klerksdorp-section";
import overnightdeliveryknysna from "./pages/location-based-pages/overnight-delivery-knysna-section";
import overnightdeliverykroonstad from "./pages/location-based-pages/overnight-delivery-kroonstad-section";
import overnightdeliverykuilsrivier from "./pages/location-based-pages/overnight-delivery-kuilsrivier-section";
import overnightdeliveryladysmith from "./pages/location-based-pages/overnight-delivery-ladysmith-section";
import overnightdeliverylebowakgomo from "./pages/location-based-pages/overnight-delivery-lebowakgomo-section";
import overnightdeliverylichtenburg from "./pages/location-based-pages/overnight-delivery-lichtenburg-section";
import overnightdeliverymahikeng from "./pages/location-based-pages/overnight-delivery-mahikeng-section";
import overnightdeliverymalmesbury from "./pages/location-based-pages/overnight-delivery-malmesbury-section";
import overnightdeliverymiddelburg from "./pages/location-based-pages/overnight-delivery-middelburg-section";
import overnightdeliverymidrand from "./pages/location-based-pages/overnight-delivery-midrand-section";
import overnightdeliverymmabatho from "./pages/location-based-pages/overnight-delivery-mmabatho-section";
import overnightdeliverymosselbay from "./pages/location-based-pages/overnight-delivery-mossel-bay-section";
import overnightdeliverymusina from "./pages/location-based-pages/overnight-delivery-musina-section";
import overnightdeliverynelspruit from "./pages/location-based-pages/overnight-delivery-nelspruit-section";
import overnightdeliveryoudtshoorn from "./pages/location-based-pages/overnight-delivery-oudtshoorn-section";
import overnightdeliverypaarl from "./pages/location-based-pages/overnight-delivery-paarl-section";
import overnightdeliverypietermaritzburg from "./pages/location-based-pages/overnight-delivery-pietermaritzburg-section";
import overnightdeliverypolokwane from "./pages/location-based-pages/overnight-delivery-polokwane-section";
import overnightdeliveryportelizabeth from "./pages/location-based-pages/overnight-delivery-port-elizabeth-section";
import overnightdeliveryportshepstone from "./pages/location-based-pages/overnight-delivery-port-shepstone-section";
import overnightdeliverypotchefstroom from "./pages/location-based-pages/overnight-delivery-potchefstroom-section";
import overnightdeliverypretoria from "./pages/location-based-pages/overnight-delivery-pretoria-section";
import overnightdeliveryqueenstown from "./pages/location-based-pages/overnight-delivery-queenstown-section";
import overnightdeliveryrandburg from "./pages/location-based-pages/overnight-delivery-randburg-section";
import overnightdeliveryroodepoort from "./pages/location-based-pages/overnight-delivery-roodepoort-section";
import overnightdeliveryrustenburg from "./pages/location-based-pages/overnight-delivery-rustenburg-section";
import overnightdeliverysaldanha from "./pages/location-based-pages/overnight-delivery-saldanha-section";
import overnightdeliverysandton from "./pages/location-based-pages/overnight-delivery-sandton-section";
import overnightdeliverysoweto from "./pages/location-based-pages/overnight-delivery-soweto-section";
import overnightdeliverysprings from "./pages/location-based-pages/overnight-delivery-springs-section";
import overnightdeliverystanderton from "./pages/location-based-pages/overnight-delivery-standerton-section";
import overnightdeliverythohoyandou from "./pages/location-based-pages/overnight-delivery-thohoyandou-section";
import overnightdeliverytzaneen from "./pages/location-based-pages/overnight-delivery-tzaneen-section";
import overnightdeliveryuitenhage from "./pages/location-based-pages/overnight-delivery-uitenhage-section";
import overnightdeliveryulundi from "./pages/location-based-pages/overnight-delivery-ulundi-section";
import overnightdeliveryumtata from "./pages/location-based-pages/overnight-delivery-umtata-section";
import overnightdeliveryupington from "./pages/location-based-pages/overnight-delivery-upington-section";
import overnightdeliveryvanderbijlpark from "./pages/location-based-pages/overnight-delivery-vanderbijlpark-section";
import overnightdeliveryvereeniging from "./pages/location-based-pages/overnight-delivery-vereeniging-section";
import overnightdeliveryvolksrust from "./pages/location-based-pages/overnight-delivery-volksrust-section";
import overnightdeliveryvredenburg from "./pages/location-based-pages/overnight-delivery-vredenburg-section";
import overnightdeliveryvryburg from "./pages/location-based-pages/overnight-delivery-vryburg-section";
import overnightdeliveryvryheid from "./pages/location-based-pages/overnight-delivery-vryheid-section";
import overnightdeliverywelkom from "./pages/location-based-pages/overnight-delivery-welkom-section";
import overnightdeliveryworcester from "./pages/location-based-pages/overnight-delivery-worcester-section";
import packagedeliveryalberton from "./pages/location-based-pages/package-delivery-alberton-section";
import packagedeliveryaliwalnorth from "./pages/location-based-pages/package-delivery-aliwal-north-section";
import packagedeliverybeaufortwest from "./pages/location-based-pages/package-delivery-beaufort-west-section";
import packagedeliverybenoni from "./pages/location-based-pages/package-delivery-benoni-section";
import packagedeliverybethal from "./pages/location-based-pages/package-delivery-bethal-section";
import packagedeliverybhisho from "./pages/location-based-pages/package-delivery-bhisho-section";
import packagedeliverybloemfontein from "./pages/location-based-pages/package-delivery-bloemfontein-section";
import packagedeliverybrakpan from "./pages/location-based-pages/package-delivery-brakpan-section";
import packagedeliverybrits from "./pages/location-based-pages/package-delivery-brits-section";
import packagedeliverycapetown from "./pages/location-based-pages/package-delivery-cape-town-section";
import packagedeliverycarletonville from "./pages/location-based-pages/package-delivery-carletonville-section";
import packagedeliverycenturion from "./pages/location-based-pages/package-delivery-centurion-section";
import packagedeliverycradock from "./pages/location-based-pages/package-delivery-cradock-section";
import packagedeliverydeaar from "./pages/location-based-pages/package-delivery-de-aar-section";
import packagedeliverydurban from "./pages/location-based-pages/package-delivery-durban-section";
import packagedeliveryeastlondon from "./pages/location-based-pages/package-delivery-east-london-section";
import packagedeliveryferndale from "./pages/location-based-pages/package-delivery-ferndale-section";
import packagedeliverygeorge from "./pages/location-based-pages/package-delivery-george-section";
import packagedeliverygraaffreinet from "./pages/location-based-pages/package-delivery-graaff-reinet-section";
import packagedeliverygrahamstown from "./pages/location-based-pages/package-delivery-grahamstown-section";
import packagedeliveryhermanus from "./pages/location-based-pages/package-delivery-hermanus-section";
import packagedeliveryjeffreysbay from "./pages/location-based-pages/package-delivery-jeffreys-bay-section";
import packagedeliveryjohannesburg from "./pages/location-based-pages/package-delivery-johannesburg-section";
import packagedeliverykimberley from "./pages/location-based-pages/package-delivery-kimberley-section";
import packagedeliveryklerksdorp from "./pages/location-based-pages/package-delivery-klerksdorp-section";
import packagedeliveryknysna from "./pages/location-based-pages/package-delivery-knysna-section";
import packagedeliverykroonstad from "./pages/location-based-pages/package-delivery-kroonstad-section";
import packagedeliverykuilsrivier from "./pages/location-based-pages/package-delivery-kuilsrivier-section";
import packagedeliveryladysmith from "./pages/location-based-pages/package-delivery-ladysmith-section";
import packagedeliverylebowakgomo from "./pages/location-based-pages/package-delivery-lebowakgomo-section";
import packagedeliverylichtenburg from "./pages/location-based-pages/package-delivery-lichtenburg-section";
import packagedeliverymahikeng from "./pages/location-based-pages/package-delivery-mahikeng-section";
import packagedeliverymalmesbury from "./pages/location-based-pages/package-delivery-malmesbury-section";
import packagedeliverymiddelburg from "./pages/location-based-pages/package-delivery-middelburg-section";
import packagedeliverymidrand from "./pages/location-based-pages/package-delivery-midrand-section";
import packagedeliverymmabatho from "./pages/location-based-pages/package-delivery-mmabatho-section";
import packagedeliverymosselbay from "./pages/location-based-pages/package-delivery-mossel-bay-section";
import packagedeliverymusina from "./pages/location-based-pages/package-delivery-musina-section";
import packagedeliverynelspruit from "./pages/location-based-pages/package-delivery-nelspruit-section";
import packagedeliveryoudtshoorn from "./pages/location-based-pages/package-delivery-oudtshoorn-section";
import packagedeliverypaarl from "./pages/location-based-pages/package-delivery-paarl-section";
import packagedeliverypietermaritzburg from "./pages/location-based-pages/package-delivery-pietermaritzburg-section";
import packagedeliverypolokwane from "./pages/location-based-pages/package-delivery-polokwane-section";
import packagedeliveryportelizabeth from "./pages/location-based-pages/package-delivery-port-elizabeth-section";
import packagedeliveryportshepstone from "./pages/location-based-pages/package-delivery-port-shepstone-section";
import packagedeliverypotchefstroom from "./pages/location-based-pages/package-delivery-potchefstroom-section";
import packagedeliverypretoria from "./pages/location-based-pages/package-delivery-pretoria-section";
import packagedeliveryqueenstown from "./pages/location-based-pages/package-delivery-queenstown-section";
import packagedeliveryrandburg from "./pages/location-based-pages/package-delivery-randburg-section";
import packagedeliveryroodepoort from "./pages/location-based-pages/package-delivery-roodepoort-section";
import packagedeliveryrustenburg from "./pages/location-based-pages/package-delivery-rustenburg-section";
import packagedeliverysaldanha from "./pages/location-based-pages/package-delivery-saldanha-section";
import packagedeliverysandton from "./pages/location-based-pages/package-delivery-sandton-section";
import packagedeliverysoweto from "./pages/location-based-pages/package-delivery-soweto-section";
import packagedeliverysprings from "./pages/location-based-pages/package-delivery-springs-section";
import packagedeliverystanderton from "./pages/location-based-pages/package-delivery-standerton-section";
import packagedeliverythohoyandou from "./pages/location-based-pages/package-delivery-thohoyandou-section";
import packagedeliverytzaneen from "./pages/location-based-pages/package-delivery-tzaneen-section";
import packagedeliveryuitenhage from "./pages/location-based-pages/package-delivery-uitenhage-section";
import packagedeliveryulundi from "./pages/location-based-pages/package-delivery-ulundi-section";
import packagedeliveryumtata from "./pages/location-based-pages/package-delivery-umtata-section";
import packagedeliveryupington from "./pages/location-based-pages/package-delivery-upington-section";
import packagedeliveryvanderbijlpark from "./pages/location-based-pages/package-delivery-vanderbijlpark-section";
import packagedeliveryvereeniging from "./pages/location-based-pages/package-delivery-vereeniging-section";
import packagedeliveryvolksrust from "./pages/location-based-pages/package-delivery-volksrust-section";
import packagedeliveryvredenburg from "./pages/location-based-pages/package-delivery-vredenburg-section";
import packagedeliveryvryburg from "./pages/location-based-pages/package-delivery-vryburg-section";
import packagedeliveryvryheid from "./pages/location-based-pages/package-delivery-vryheid-section";
import packagedeliverywelkom from "./pages/location-based-pages/package-delivery-welkom-section";
import packagedeliveryworcester from "./pages/location-based-pages/package-delivery-worcester-section";
import quicktruckhirealberton from "./pages/location-based-pages/quick-truck-hire-alberton-section";
import quicktruckhirealiwalnorth from "./pages/location-based-pages/quick-truck-hire-aliwal-north-section";
import quicktruckhirebeaufortwest from "./pages/location-based-pages/quick-truck-hire-beaufort-west-section";
import quicktruckhirebenoni from "./pages/location-based-pages/quick-truck-hire-benoni-section";
import quicktruckhirebethal from "./pages/location-based-pages/quick-truck-hire-bethal-section";
import quicktruckhirebhisho from "./pages/location-based-pages/quick-truck-hire-bhisho-section";
import quicktruckhirebloemfontein from "./pages/location-based-pages/quick-truck-hire-bloemfontein-section";
import quicktruckhirebrakpan from "./pages/location-based-pages/quick-truck-hire-brakpan-section";
import quicktruckhirebrits from "./pages/location-based-pages/quick-truck-hire-brits-section";
import quicktruckhirecapetown from "./pages/location-based-pages/quick-truck-hire-cape-town-section";
import quicktruckhirecarletonville from "./pages/location-based-pages/quick-truck-hire-carletonville-section";
import quicktruckhirecenturion from "./pages/location-based-pages/quick-truck-hire-centurion-section";
import quicktruckhirecradock from "./pages/location-based-pages/quick-truck-hire-cradock-section";
import quicktruckhiredeaar from "./pages/location-based-pages/quick-truck-hire-de-aar-section";
import quicktruckhiredurban from "./pages/location-based-pages/quick-truck-hire-durban-section";
import quicktruckhireeastlondon from "./pages/location-based-pages/quick-truck-hire-east-london-section";
import quicktruckhireferndale from "./pages/location-based-pages/quick-truck-hire-ferndale-section";
import quicktruckhiregeorge from "./pages/location-based-pages/quick-truck-hire-george-section";
import quicktruckhiregraaffreinet from "./pages/location-based-pages/quick-truck-hire-graaff-reinet-section";
import quicktruckhiregrahamstown from "./pages/location-based-pages/quick-truck-hire-grahamstown-section";
import quicktruckhirehermanus from "./pages/location-based-pages/quick-truck-hire-hermanus-section";
import quicktruckhirejeffreysbay from "./pages/location-based-pages/quick-truck-hire-jeffreys-bay-section";
import quicktruckhirejohannesburg from "./pages/location-based-pages/quick-truck-hire-johannesburg-section";
import quicktruckhirekimberley from "./pages/location-based-pages/quick-truck-hire-kimberley-section";
import quicktruckhireklerksdorp from "./pages/location-based-pages/quick-truck-hire-klerksdorp-section";
import quicktruckhireknysna from "./pages/location-based-pages/quick-truck-hire-knysna-section";
import quicktruckhirekroonstad from "./pages/location-based-pages/quick-truck-hire-kroonstad-section";
import quicktruckhirekuilsrivier from "./pages/location-based-pages/quick-truck-hire-kuilsrivier-section";
import quicktruckhireladysmith from "./pages/location-based-pages/quick-truck-hire-ladysmith-section";
import quicktruckhirelebowakgomo from "./pages/location-based-pages/quick-truck-hire-lebowakgomo-section";
import quicktruckhirelichtenburg from "./pages/location-based-pages/quick-truck-hire-lichtenburg-section";
import quicktruckhiremahikeng from "./pages/location-based-pages/quick-truck-hire-mahikeng-section";
import quicktruckhiremalmesbury from "./pages/location-based-pages/quick-truck-hire-malmesbury-section";
import quicktruckhiremiddelburg from "./pages/location-based-pages/quick-truck-hire-middelburg-section";
import quicktruckhiremidrand from "./pages/location-based-pages/quick-truck-hire-midrand-section";
import quicktruckhiremmabatho from "./pages/location-based-pages/quick-truck-hire-mmabatho-section";
import quicktruckhiremosselbay from "./pages/location-based-pages/quick-truck-hire-mossel-bay-section";
import quicktruckhiremusina from "./pages/location-based-pages/quick-truck-hire-musina-section";
import quicktruckhirenelspruit from "./pages/location-based-pages/quick-truck-hire-nelspruit-section";
import quicktruckhireoudtshoorn from "./pages/location-based-pages/quick-truck-hire-oudtshoorn-section";
import quicktruckhirepaarl from "./pages/location-based-pages/quick-truck-hire-paarl-section";
import quicktruckhirepietermaritzburg from "./pages/location-based-pages/quick-truck-hire-pietermaritzburg-section";
import quicktruckhirepolokwane from "./pages/location-based-pages/quick-truck-hire-polokwane-section";
import quicktruckhireportelizabeth from "./pages/location-based-pages/quick-truck-hire-port-elizabeth-section";
import quicktruckhireportshepstone from "./pages/location-based-pages/quick-truck-hire-port-shepstone-section";
import quicktruckhirepotchefstroom from "./pages/location-based-pages/quick-truck-hire-potchefstroom-section";
import quicktruckhirepretoria from "./pages/location-based-pages/quick-truck-hire-pretoria-section";
import quicktruckhirequeenstown from "./pages/location-based-pages/quick-truck-hire-queenstown-section";
import quicktruckhirerandburg from "./pages/location-based-pages/quick-truck-hire-randburg-section";
import quicktruckhireroodepoort from "./pages/location-based-pages/quick-truck-hire-roodepoort-section";
import quicktruckhirerustenburg from "./pages/location-based-pages/quick-truck-hire-rustenburg-section";
import quicktruckhiresaldanha from "./pages/location-based-pages/quick-truck-hire-saldanha-section";
import quicktruckhiresandton from "./pages/location-based-pages/quick-truck-hire-sandton-section";
import quicktruckhiresoweto from "./pages/location-based-pages/quick-truck-hire-soweto-section";
import quicktruckhiresprings from "./pages/location-based-pages/quick-truck-hire-springs-section";
import quicktruckhirestanderton from "./pages/location-based-pages/quick-truck-hire-standerton-section";
import quicktruckhirethohoyandou from "./pages/location-based-pages/quick-truck-hire-thohoyandou-section";
import quicktruckhiretzaneen from "./pages/location-based-pages/quick-truck-hire-tzaneen-section";
import quicktruckhireuitenhage from "./pages/location-based-pages/quick-truck-hire-uitenhage-section";
import quicktruckhireulundi from "./pages/location-based-pages/quick-truck-hire-ulundi-section";
import quicktruckhireumtata from "./pages/location-based-pages/quick-truck-hire-umtata-section";
import quicktruckhireupington from "./pages/location-based-pages/quick-truck-hire-upington-section";
import quicktruckhirevanderbijlpark from "./pages/location-based-pages/quick-truck-hire-vanderbijlpark-section";
import quicktruckhirevereeniging from "./pages/location-based-pages/quick-truck-hire-vereeniging-section";
import quicktruckhirevolksrust from "./pages/location-based-pages/quick-truck-hire-volksrust-section";
import quicktruckhirevredenburg from "./pages/location-based-pages/quick-truck-hire-vredenburg-section";
import quicktruckhirevryburg from "./pages/location-based-pages/quick-truck-hire-vryburg-section";
import quicktruckhirevryheid from "./pages/location-based-pages/quick-truck-hire-vryheid-section";
import quicktruckhirewelkom from "./pages/location-based-pages/quick-truck-hire-welkom-section";
import quicktruckhireworcester from "./pages/location-based-pages/quick-truck-hire-worcester-section";
import quicktruckrentalalberton from "./pages/location-based-pages/quick-truck-rental-alberton-section";
import quicktruckrentalaliwalnorth from "./pages/location-based-pages/quick-truck-rental-aliwal-north-section";
import quicktruckrentalbeaufortwest from "./pages/location-based-pages/quick-truck-rental-beaufort-west-section";
import quicktruckrentalbenoni from "./pages/location-based-pages/quick-truck-rental-benoni-section";
import quicktruckrentalbethal from "./pages/location-based-pages/quick-truck-rental-bethal-section";
import quicktruckrentalbhisho from "./pages/location-based-pages/quick-truck-rental-bhisho-section";
import quicktruckrentalbloemfontein from "./pages/location-based-pages/quick-truck-rental-bloemfontein-section";
import quicktruckrentalbrakpan from "./pages/location-based-pages/quick-truck-rental-brakpan-section";
import quicktruckrentalbrits from "./pages/location-based-pages/quick-truck-rental-brits-section";
import quicktruckrentalcapetown from "./pages/location-based-pages/quick-truck-rental-cape-town-section";
import quicktruckrentalcarletonville from "./pages/location-based-pages/quick-truck-rental-carletonville-section";
import quicktruckrentalcenturion from "./pages/location-based-pages/quick-truck-rental-centurion-section";
import quicktruckrentalcradock from "./pages/location-based-pages/quick-truck-rental-cradock-section";
import quicktruckrentaldeaar from "./pages/location-based-pages/quick-truck-rental-de-aar-section";
import quicktruckrentaldurban from "./pages/location-based-pages/quick-truck-rental-durban-section";
import quicktruckrentaleastlondon from "./pages/location-based-pages/quick-truck-rental-east-london-section";
import quicktruckrentalferndale from "./pages/location-based-pages/quick-truck-rental-ferndale-section";
import quicktruckrentalgeorge from "./pages/location-based-pages/quick-truck-rental-george-section";
import quicktruckrentalgraaffreinet from "./pages/location-based-pages/quick-truck-rental-graaff-reinet-section";
import quicktruckrentalgrahamstown from "./pages/location-based-pages/quick-truck-rental-grahamstown-section";
import quicktruckrentalhermanus from "./pages/location-based-pages/quick-truck-rental-hermanus-section";
import quicktruckrentaljeffreysbay from "./pages/location-based-pages/quick-truck-rental-jeffreys-bay-section";
import quicktruckrentaljohannesburg from "./pages/location-based-pages/quick-truck-rental-johannesburg-section";
import quicktruckrentalkimberley from "./pages/location-based-pages/quick-truck-rental-kimberley-section";
import quicktruckrentalklerksdorp from "./pages/location-based-pages/quick-truck-rental-klerksdorp-section";
import quicktruckrentalknysna from "./pages/location-based-pages/quick-truck-rental-knysna-section";
import quicktruckrentalkroonstad from "./pages/location-based-pages/quick-truck-rental-kroonstad-section";
import quicktruckrentalkuilsrivier from "./pages/location-based-pages/quick-truck-rental-kuilsrivier-section";
import quicktruckrentalladysmith from "./pages/location-based-pages/quick-truck-rental-ladysmith-section";
import quicktruckrentallebowakgomo from "./pages/location-based-pages/quick-truck-rental-lebowakgomo-section";
import quicktruckrentallichtenburg from "./pages/location-based-pages/quick-truck-rental-lichtenburg-section";
import quicktruckrentalmahikeng from "./pages/location-based-pages/quick-truck-rental-mahikeng-section";
import quicktruckrentalmalmesbury from "./pages/location-based-pages/quick-truck-rental-malmesbury-section";
import quicktruckrentalmiddelburg from "./pages/location-based-pages/quick-truck-rental-middelburg-section";
import quicktruckrentalmidrand from "./pages/location-based-pages/quick-truck-rental-midrand-section";
import quicktruckrentalmmabatho from "./pages/location-based-pages/quick-truck-rental-mmabatho-section";
import quicktruckrentalmosselbay from "./pages/location-based-pages/quick-truck-rental-mossel-bay-section";
import quicktruckrentalmusina from "./pages/location-based-pages/quick-truck-rental-musina-section";
import quicktruckrentalnelspruit from "./pages/location-based-pages/quick-truck-rental-nelspruit-section";
import quicktruckrentaloudtshoorn from "./pages/location-based-pages/quick-truck-rental-oudtshoorn-section";
import quicktruckrentalpaarl from "./pages/location-based-pages/quick-truck-rental-paarl-section";
import quicktruckrentalpietermaritzburg from "./pages/location-based-pages/quick-truck-rental-pietermaritzburg-section";
import quicktruckrentalpolokwane from "./pages/location-based-pages/quick-truck-rental-polokwane-section";
import quicktruckrentalportelizabeth from "./pages/location-based-pages/quick-truck-rental-port-elizabeth-section";
import quicktruckrentalportshepstone from "./pages/location-based-pages/quick-truck-rental-port-shepstone-section";
import quicktruckrentalpotchefstroom from "./pages/location-based-pages/quick-truck-rental-potchefstroom-section";
import quicktruckrentalpretoria from "./pages/location-based-pages/quick-truck-rental-pretoria-section";
import quicktruckrentalqueenstown from "./pages/location-based-pages/quick-truck-rental-queenstown-section";
import quicktruckrentalrandburg from "./pages/location-based-pages/quick-truck-rental-randburg-section";
import quicktruckrentalroodepoort from "./pages/location-based-pages/quick-truck-rental-roodepoort-section";
import quicktruckrentalrustenburg from "./pages/location-based-pages/quick-truck-rental-rustenburg-section";
import quicktruckrentalsaldanha from "./pages/location-based-pages/quick-truck-rental-saldanha-section";
import quicktruckrentalsandton from "./pages/location-based-pages/quick-truck-rental-sandton-section";
import quicktruckrentalsoweto from "./pages/location-based-pages/quick-truck-rental-soweto-section";
import quicktruckrentalsprings from "./pages/location-based-pages/quick-truck-rental-springs-section";
import quicktruckrentalstanderton from "./pages/location-based-pages/quick-truck-rental-standerton-section";
import quicktruckrentalthohoyandou from "./pages/location-based-pages/quick-truck-rental-thohoyandou-section";
import quicktruckrentaltzaneen from "./pages/location-based-pages/quick-truck-rental-tzaneen-section";
import quicktruckrentaluitenhage from "./pages/location-based-pages/quick-truck-rental-uitenhage-section";
import quicktruckrentalulundi from "./pages/location-based-pages/quick-truck-rental-ulundi-section";
import quicktruckrentalumtata from "./pages/location-based-pages/quick-truck-rental-umtata-section";
import quicktruckrentalupington from "./pages/location-based-pages/quick-truck-rental-upington-section";
import quicktruckrentalvanderbijlpark from "./pages/location-based-pages/quick-truck-rental-vanderbijlpark-section";
import quicktruckrentalvereeniging from "./pages/location-based-pages/quick-truck-rental-vereeniging-section";
import quicktruckrentalvolksrust from "./pages/location-based-pages/quick-truck-rental-volksrust-section";
import quicktruckrentalvredenburg from "./pages/location-based-pages/quick-truck-rental-vredenburg-section";
import quicktruckrentalvryburg from "./pages/location-based-pages/quick-truck-rental-vryburg-section";
import quicktruckrentalvryheid from "./pages/location-based-pages/quick-truck-rental-vryheid-section";
import quicktruckrentalwelkom from "./pages/location-based-pages/quick-truck-rental-welkom-section";
import quicktruckrentalworcester from "./pages/location-based-pages/quick-truck-rental-worcester-section";
import removalsalberton from "./pages/location-based-pages/removals-alberton-section";
import removalsaliwalnorth from "./pages/location-based-pages/removals-aliwal-north-section";
import removalsbeaufortwest from "./pages/location-based-pages/removals-beaufort-west-section";
import removalsbenoni from "./pages/location-based-pages/removals-benoni-section";
import removalsbethal from "./pages/location-based-pages/removals-bethal-section";
import removalsbhisho from "./pages/location-based-pages/removals-bhisho-section";
import removalsbloemfontein from "./pages/location-based-pages/removals-bloemfontein-section";
import removalsbrakpan from "./pages/location-based-pages/removals-brakpan-section";
import removalsbrits from "./pages/location-based-pages/removals-brits-section";
import removalscapetown from "./pages/location-based-pages/removals-cape-town-section";
import removalscarletonville from "./pages/location-based-pages/removals-carletonville-section";
import removalscenturion from "./pages/location-based-pages/removals-centurion-section";
import removalscradock from "./pages/location-based-pages/removals-cradock-section";
import removalsdeaar from "./pages/location-based-pages/removals-de-aar-section";
import removalsdurban from "./pages/location-based-pages/removals-durban-section";
import removalseastlondon from "./pages/location-based-pages/removals-east-london-section";
import removalsferndale from "./pages/location-based-pages/removals-ferndale-section";
import removalsgeorge from "./pages/location-based-pages/removals-george-section";
import removalsgraaffreinet from "./pages/location-based-pages/removals-graaff-reinet-section";
import removalsgrahamstown from "./pages/location-based-pages/removals-grahamstown-section";
import removalshermanus from "./pages/location-based-pages/removals-hermanus-section";
import removalsjeffreysbay from "./pages/location-based-pages/removals-jeffreys-bay-section";
import removalsjohannesburg from "./pages/location-based-pages/removals-johannesburg-section";
import removalskimberley from "./pages/location-based-pages/removals-kimberley-section";
import removalsklerksdorp from "./pages/location-based-pages/removals-klerksdorp-section";
import removalsknysna from "./pages/location-based-pages/removals-knysna-section";
import removalskroonstad from "./pages/location-based-pages/removals-kroonstad-section";
import removalskuilsrivier from "./pages/location-based-pages/removals-kuilsrivier-section";
import removalsladysmith from "./pages/location-based-pages/removals-ladysmith-section";
import removalslebowakgomo from "./pages/location-based-pages/removals-lebowakgomo-section";
import removalslichtenburg from "./pages/location-based-pages/removals-lichtenburg-section";
import removalsmahikeng from "./pages/location-based-pages/removals-mahikeng-section";
import removalsmalmesbury from "./pages/location-based-pages/removals-malmesbury-section";
import removalsmiddelburg from "./pages/location-based-pages/removals-middelburg-section";
import removalsmidrand from "./pages/location-based-pages/removals-midrand-section";
import removalsmmabatho from "./pages/location-based-pages/removals-mmabatho-section";
import removalsmosselbay from "./pages/location-based-pages/removals-mossel-bay-section";
import removalsmusina from "./pages/location-based-pages/removals-musina-section";
import removalsnelspruit from "./pages/location-based-pages/removals-nelspruit-section";
import removalsoudtshoorn from "./pages/location-based-pages/removals-oudtshoorn-section";
import removalspaarl from "./pages/location-based-pages/removals-paarl-section";
import removalspietermaritzburg from "./pages/location-based-pages/removals-pietermaritzburg-section";
import removalspolokwane from "./pages/location-based-pages/removals-polokwane-section";
import removalsportelizabeth from "./pages/location-based-pages/removals-port-elizabeth-section";
import removalsportshepstone from "./pages/location-based-pages/removals-port-shepstone-section";
import removalspotchefstroom from "./pages/location-based-pages/removals-potchefstroom-section";
import removalspretoria from "./pages/location-based-pages/removals-pretoria-section";
import removalsqueenstown from "./pages/location-based-pages/removals-queenstown-section";
import removalsrandburg from "./pages/location-based-pages/removals-randburg-section";
import removalsroodepoort from "./pages/location-based-pages/removals-roodepoort-section";
import removalsrustenburg from "./pages/location-based-pages/removals-rustenburg-section";
import removalssaldanha from "./pages/location-based-pages/removals-saldanha-section";
import removalssandton from "./pages/location-based-pages/removals-sandton-section";
import removalssoweto from "./pages/location-based-pages/removals-soweto-section";
import removalssprings from "./pages/location-based-pages/removals-springs-section";
import removalsstanderton from "./pages/location-based-pages/removals-standerton-section";
import removalsthohoyandou from "./pages/location-based-pages/removals-thohoyandou-section";
import removalstzaneen from "./pages/location-based-pages/removals-tzaneen-section";
import removalsuitenhage from "./pages/location-based-pages/removals-uitenhage-section";
import removalsulundi from "./pages/location-based-pages/removals-ulundi-section";
import removalsumtata from "./pages/location-based-pages/removals-umtata-section";
import removalsupington from "./pages/location-based-pages/removals-upington-section";
import removalsvanderbijlpark from "./pages/location-based-pages/removals-vanderbijlpark-section";
import removalsvereeniging from "./pages/location-based-pages/removals-vereeniging-section";
import removalsvolksrust from "./pages/location-based-pages/removals-volksrust-section";
import removalsvredenburg from "./pages/location-based-pages/removals-vredenburg-section";
import removalsvryburg from "./pages/location-based-pages/removals-vryburg-section";
import removalsvryheid from "./pages/location-based-pages/removals-vryheid-section";
import removalswelkom from "./pages/location-based-pages/removals-welkom-section";
import removalsworcester from "./pages/location-based-pages/removals-worcester-section";
import rentabakkiealberton from "./pages/location-based-pages/rent-a-bakkie-alberton-section";
import rentabakkiealiwalnorth from "./pages/location-based-pages/rent-a-bakkie-aliwal-north-section";
import rentabakkiebeaufortwest from "./pages/location-based-pages/rent-a-bakkie-beaufort-west-section";
import rentabakkiebenoni from "./pages/location-based-pages/rent-a-bakkie-benoni-section";
import rentabakkiebethal from "./pages/location-based-pages/rent-a-bakkie-bethal-section";
import rentabakkiebhisho from "./pages/location-based-pages/rent-a-bakkie-bhisho-section";
import rentabakkiebloemfontein from "./pages/location-based-pages/rent-a-bakkie-bloemfontein-section";
import rentabakkiebrakpan from "./pages/location-based-pages/rent-a-bakkie-brakpan-section";
import rentabakkiebrits from "./pages/location-based-pages/rent-a-bakkie-brits-section";
import rentabakkiecapetown from "./pages/location-based-pages/rent-a-bakkie-cape-town-section";
import rentabakkiecarletonville from "./pages/location-based-pages/rent-a-bakkie-carletonville-section";
import rentabakkiecenturion from "./pages/location-based-pages/rent-a-bakkie-centurion-section";
import rentabakkiecradock from "./pages/location-based-pages/rent-a-bakkie-cradock-section";
import rentabakkiedeaar from "./pages/location-based-pages/rent-a-bakkie-de-aar-section";
import rentabakkiedurban from "./pages/location-based-pages/rent-a-bakkie-durban-section";
import rentabakkieeastlondon from "./pages/location-based-pages/rent-a-bakkie-east-london-section";
import rentabakkieferndale from "./pages/location-based-pages/rent-a-bakkie-ferndale-section";
import rentabakkiegeorge from "./pages/location-based-pages/rent-a-bakkie-george-section";
import rentabakkiegraaffreinet from "./pages/location-based-pages/rent-a-bakkie-graaff-reinet-section";
import rentabakkiegrahamstown from "./pages/location-based-pages/rent-a-bakkie-grahamstown-section";
import rentabakkiehermanus from "./pages/location-based-pages/rent-a-bakkie-hermanus-section";
import rentabakkiejeffreysbay from "./pages/location-based-pages/rent-a-bakkie-jeffreys-bay-section";
import rentabakkiejohannesburg from "./pages/location-based-pages/rent-a-bakkie-johannesburg-section";
import rentabakkiekimberley from "./pages/location-based-pages/rent-a-bakkie-kimberley-section";
import rentabakkieklerksdorp from "./pages/location-based-pages/rent-a-bakkie-klerksdorp-section";
import rentabakkieknysna from "./pages/location-based-pages/rent-a-bakkie-knysna-section";
import rentabakkiekroonstad from "./pages/location-based-pages/rent-a-bakkie-kroonstad-section";
import rentabakkiekuilsrivier from "./pages/location-based-pages/rent-a-bakkie-kuilsrivier-section";
import rentabakkieladysmith from "./pages/location-based-pages/rent-a-bakkie-ladysmith-section";
import rentabakkielebowakgomo from "./pages/location-based-pages/rent-a-bakkie-lebowakgomo-section";
import rentabakkielichtenburg from "./pages/location-based-pages/rent-a-bakkie-lichtenburg-section";
import rentabakkiemahikeng from "./pages/location-based-pages/rent-a-bakkie-mahikeng-section";
import rentabakkiemalmesbury from "./pages/location-based-pages/rent-a-bakkie-malmesbury-section";
import rentabakkiemiddelburg from "./pages/location-based-pages/rent-a-bakkie-middelburg-section";
import rentabakkiemidrand from "./pages/location-based-pages/rent-a-bakkie-midrand-section";
import rentabakkiemmabatho from "./pages/location-based-pages/rent-a-bakkie-mmabatho-section";
import rentabakkiemosselbay from "./pages/location-based-pages/rent-a-bakkie-mossel-bay-section";
import rentabakkiemusina from "./pages/location-based-pages/rent-a-bakkie-musina-section";
import rentabakkienelspruit from "./pages/location-based-pages/rent-a-bakkie-nelspruit-section";
import rentabakkieoudtshoorn from "./pages/location-based-pages/rent-a-bakkie-oudtshoorn-section";
import rentabakkiepaarl from "./pages/location-based-pages/rent-a-bakkie-paarl-section";
import rentabakkiepietermaritzburg from "./pages/location-based-pages/rent-a-bakkie-pietermaritzburg-section";
import rentabakkiepolokwane from "./pages/location-based-pages/rent-a-bakkie-polokwane-section";
import rentabakkieportelizabeth from "./pages/location-based-pages/rent-a-bakkie-port-elizabeth-section";
import rentabakkieportshepstone from "./pages/location-based-pages/rent-a-bakkie-port-shepstone-section";
import rentabakkiepotchefstroom from "./pages/location-based-pages/rent-a-bakkie-potchefstroom-section";
import rentabakkiepretoria from "./pages/location-based-pages/rent-a-bakkie-pretoria-section";
import rentabakkiequeenstown from "./pages/location-based-pages/rent-a-bakkie-queenstown-section";
import rentabakkierandburg from "./pages/location-based-pages/rent-a-bakkie-randburg-section";
import rentabakkieroodepoort from "./pages/location-based-pages/rent-a-bakkie-roodepoort-section";
import rentabakkierustenburg from "./pages/location-based-pages/rent-a-bakkie-rustenburg-section";
import rentabakkiesaldanha from "./pages/location-based-pages/rent-a-bakkie-saldanha-section";
import rentabakkiesandton from "./pages/location-based-pages/rent-a-bakkie-sandton-section";
import rentabakkiesoweto from "./pages/location-based-pages/rent-a-bakkie-soweto-section";
import rentabakkiesprings from "./pages/location-based-pages/rent-a-bakkie-springs-section";
import rentabakkiestanderton from "./pages/location-based-pages/rent-a-bakkie-standerton-section";
import rentabakkiethohoyandou from "./pages/location-based-pages/rent-a-bakkie-thohoyandou-section";
import rentabakkietzaneen from "./pages/location-based-pages/rent-a-bakkie-tzaneen-section";
import rentabakkieuitenhage from "./pages/location-based-pages/rent-a-bakkie-uitenhage-section";
import rentabakkieulundi from "./pages/location-based-pages/rent-a-bakkie-ulundi-section";
import rentabakkieumtata from "./pages/location-based-pages/rent-a-bakkie-umtata-section";
import rentabakkieupington from "./pages/location-based-pages/rent-a-bakkie-upington-section";
import rentabakkievanderbijlpark from "./pages/location-based-pages/rent-a-bakkie-vanderbijlpark-section";
import rentabakkievereeniging from "./pages/location-based-pages/rent-a-bakkie-vereeniging-section";
import rentabakkievolksrust from "./pages/location-based-pages/rent-a-bakkie-volksrust-section";
import rentabakkievredenburg from "./pages/location-based-pages/rent-a-bakkie-vredenburg-section";
import rentabakkievryburg from "./pages/location-based-pages/rent-a-bakkie-vryburg-section";
import rentabakkievryheid from "./pages/location-based-pages/rent-a-bakkie-vryheid-section";
import rentabakkiewelkom from "./pages/location-based-pages/rent-a-bakkie-welkom-section";
import rentabakkieworcester from "./pages/location-based-pages/rent-a-bakkie-worcester-section";
import retaildeliveryalberton from "./pages/location-based-pages/retail-delivery-alberton-section";
import retaildeliveryaliwalnorth from "./pages/location-based-pages/retail-delivery-aliwal-north-section";
import retaildeliverybeaufortwest from "./pages/location-based-pages/retail-delivery-beaufort-west-section";
import retaildeliverybenoni from "./pages/location-based-pages/retail-delivery-benoni-section";
import retaildeliverybethal from "./pages/location-based-pages/retail-delivery-bethal-section";
import retaildeliverybhisho from "./pages/location-based-pages/retail-delivery-bhisho-section";
import retaildeliverybloemfontein from "./pages/location-based-pages/retail-delivery-bloemfontein-section";
import retaildeliverybrakpan from "./pages/location-based-pages/retail-delivery-brakpan-section";
import retaildeliverybrits from "./pages/location-based-pages/retail-delivery-brits-section";
import retaildeliverycapetown from "./pages/location-based-pages/retail-delivery-cape-town-section";
import retaildeliverycarletonville from "./pages/location-based-pages/retail-delivery-carletonville-section";
import retaildeliverycenturion from "./pages/location-based-pages/retail-delivery-centurion-section";
import retaildeliverycradock from "./pages/location-based-pages/retail-delivery-cradock-section";
import retaildeliverydeaar from "./pages/location-based-pages/retail-delivery-de-aar-section";
import retaildeliverydurban from "./pages/location-based-pages/retail-delivery-durban-section";
import retaildeliveryeastlondon from "./pages/location-based-pages/retail-delivery-east-london-section";
import retaildeliveryferndale from "./pages/location-based-pages/retail-delivery-ferndale-section";
import retaildeliverygeorge from "./pages/location-based-pages/retail-delivery-george-section";
import retaildeliverygraaffreinet from "./pages/location-based-pages/retail-delivery-graaff-reinet-section";
import retaildeliverygrahamstown from "./pages/location-based-pages/retail-delivery-grahamstown-section";
import retaildeliveryhermanus from "./pages/location-based-pages/retail-delivery-hermanus-section";
import retaildeliveryjeffreysbay from "./pages/location-based-pages/retail-delivery-jeffreys-bay-section";
import retaildeliveryjohannesburg from "./pages/location-based-pages/retail-delivery-johannesburg-section";
import retaildeliverykimberley from "./pages/location-based-pages/retail-delivery-kimberley-section";
import retaildeliveryklerksdorp from "./pages/location-based-pages/retail-delivery-klerksdorp-section";
import retaildeliveryknysna from "./pages/location-based-pages/retail-delivery-knysna-section";
import retaildeliverykroonstad from "./pages/location-based-pages/retail-delivery-kroonstad-section";
import retaildeliverykuilsrivier from "./pages/location-based-pages/retail-delivery-kuilsrivier-section";
import retaildeliveryladysmith from "./pages/location-based-pages/retail-delivery-ladysmith-section";
import retaildeliverylebowakgomo from "./pages/location-based-pages/retail-delivery-lebowakgomo-section";
import retaildeliverylichtenburg from "./pages/location-based-pages/retail-delivery-lichtenburg-section";
import retaildeliverymahikeng from "./pages/location-based-pages/retail-delivery-mahikeng-section";
import retaildeliverymalmesbury from "./pages/location-based-pages/retail-delivery-malmesbury-section";
import retaildeliverymiddelburg from "./pages/location-based-pages/retail-delivery-middelburg-section";
import retaildeliverymidrand from "./pages/location-based-pages/retail-delivery-midrand-section";
import retaildeliverymmabatho from "./pages/location-based-pages/retail-delivery-mmabatho-section";
import retaildeliverymosselbay from "./pages/location-based-pages/retail-delivery-mossel-bay-section";
import retaildeliverymusina from "./pages/location-based-pages/retail-delivery-musina-section";
import retaildeliverynelspruit from "./pages/location-based-pages/retail-delivery-nelspruit-section";
import retaildeliveryoudtshoorn from "./pages/location-based-pages/retail-delivery-oudtshoorn-section";
import retaildeliverypaarl from "./pages/location-based-pages/retail-delivery-paarl-section";
import retaildeliverypietermaritzburg from "./pages/location-based-pages/retail-delivery-pietermaritzburg-section";
import retaildeliverypolokwane from "./pages/location-based-pages/retail-delivery-polokwane-section";
import retaildeliveryportelizabeth from "./pages/location-based-pages/retail-delivery-port-elizabeth-section";
import retaildeliveryportshepstone from "./pages/location-based-pages/retail-delivery-port-shepstone-section";
import retaildeliverypotchefstroom from "./pages/location-based-pages/retail-delivery-potchefstroom-section";
import retaildeliverypretoria from "./pages/location-based-pages/retail-delivery-pretoria-section";
import retaildeliveryqueenstown from "./pages/location-based-pages/retail-delivery-queenstown-section";
import retaildeliveryrandburg from "./pages/location-based-pages/retail-delivery-randburg-section";
import retaildeliveryroodepoort from "./pages/location-based-pages/retail-delivery-roodepoort-section";
import retaildeliveryrustenburg from "./pages/location-based-pages/retail-delivery-rustenburg-section";
import retaildeliverysaldanha from "./pages/location-based-pages/retail-delivery-saldanha-section";
import retaildeliverysandton from "./pages/location-based-pages/retail-delivery-sandton-section";
import retaildeliverysoweto from "./pages/location-based-pages/retail-delivery-soweto-section";
import retaildeliverysprings from "./pages/location-based-pages/retail-delivery-springs-section";
import retaildeliverystanderton from "./pages/location-based-pages/retail-delivery-standerton-section";
import retaildeliverythohoyandou from "./pages/location-based-pages/retail-delivery-thohoyandou-section";
import retaildeliverytzaneen from "./pages/location-based-pages/retail-delivery-tzaneen-section";
import retaildeliveryuitenhage from "./pages/location-based-pages/retail-delivery-uitenhage-section";
import retaildeliveryulundi from "./pages/location-based-pages/retail-delivery-ulundi-section";
import retaildeliveryumtata from "./pages/location-based-pages/retail-delivery-umtata-section";
import retaildeliveryupington from "./pages/location-based-pages/retail-delivery-upington-section";
import retaildeliveryvanderbijlpark from "./pages/location-based-pages/retail-delivery-vanderbijlpark-section";
import retaildeliveryvereeniging from "./pages/location-based-pages/retail-delivery-vereeniging-section";
import retaildeliveryvolksrust from "./pages/location-based-pages/retail-delivery-volksrust-section";
import retaildeliveryvredenburg from "./pages/location-based-pages/retail-delivery-vredenburg-section";
import retaildeliveryvryburg from "./pages/location-based-pages/retail-delivery-vryburg-section";
import retaildeliveryvryheid from "./pages/location-based-pages/retail-delivery-vryheid-section";
import retaildeliverywelkom from "./pages/location-based-pages/retail-delivery-welkom-section";
import retaildeliveryworcester from "./pages/location-based-pages/retail-delivery-worcester-section";
import samedaydeliveryalberton from "./pages/location-based-pages/same-day-delivery-alberton-section";
import samedaydeliveryaliwalnorth from "./pages/location-based-pages/same-day-delivery-aliwal-north-section";
import samedaydeliverybeaufortwest from "./pages/location-based-pages/same-day-delivery-beaufort-west-section";
import samedaydeliverybenoni from "./pages/location-based-pages/same-day-delivery-benoni-section";
import samedaydeliverybethal from "./pages/location-based-pages/same-day-delivery-bethal-section";
import samedaydeliverybhisho from "./pages/location-based-pages/same-day-delivery-bhisho-section";
import samedaydeliverybloemfontein from "./pages/location-based-pages/same-day-delivery-bloemfontein-section";
import samedaydeliverybrakpan from "./pages/location-based-pages/same-day-delivery-brakpan-section";
import samedaydeliverybrits from "./pages/location-based-pages/same-day-delivery-brits-section";
import samedaydeliverycapetown from "./pages/location-based-pages/same-day-delivery-cape-town-section";
import samedaydeliverycarletonville from "./pages/location-based-pages/same-day-delivery-carletonville-section";
import samedaydeliverycenturion from "./pages/location-based-pages/same-day-delivery-centurion-section";
import samedaydeliverycradock from "./pages/location-based-pages/same-day-delivery-cradock-section";
import samedaydeliverydeaar from "./pages/location-based-pages/same-day-delivery-de-aar-section";
import samedaydeliverydurban from "./pages/location-based-pages/same-day-delivery-durban-section";
import samedaydeliveryeastlondon from "./pages/location-based-pages/same-day-delivery-east-london-section";
import samedaydeliveryferndale from "./pages/location-based-pages/same-day-delivery-ferndale-section";
import samedaydeliverygeorge from "./pages/location-based-pages/same-day-delivery-george-section";
import samedaydeliverygraaffreinet from "./pages/location-based-pages/same-day-delivery-graaff-reinet-section";
import samedaydeliverygrahamstown from "./pages/location-based-pages/same-day-delivery-grahamstown-section";
import samedaydeliveryhermanus from "./pages/location-based-pages/same-day-delivery-hermanus-section";
import samedaydeliveryjeffreysbay from "./pages/location-based-pages/same-day-delivery-jeffreys-bay-section";
import samedaydeliveryjohannesburg from "./pages/location-based-pages/same-day-delivery-johannesburg-section";
import samedaydeliverykimberley from "./pages/location-based-pages/same-day-delivery-kimberley-section";
import samedaydeliveryklerksdorp from "./pages/location-based-pages/same-day-delivery-klerksdorp-section";
import samedaydeliveryknysna from "./pages/location-based-pages/same-day-delivery-knysna-section";
import samedaydeliverykroonstad from "./pages/location-based-pages/same-day-delivery-kroonstad-section";
import samedaydeliverykuilsrivier from "./pages/location-based-pages/same-day-delivery-kuilsrivier-section";
import samedaydeliveryladysmith from "./pages/location-based-pages/same-day-delivery-ladysmith-section";
import samedaydeliverylebowakgomo from "./pages/location-based-pages/same-day-delivery-lebowakgomo-section";
import samedaydeliverylichtenburg from "./pages/location-based-pages/same-day-delivery-lichtenburg-section";
import samedaydeliverymahikeng from "./pages/location-based-pages/same-day-delivery-mahikeng-section";
import samedaydeliverymalmesbury from "./pages/location-based-pages/same-day-delivery-malmesbury-section";
import samedaydeliverymiddelburg from "./pages/location-based-pages/same-day-delivery-middelburg-section";
import samedaydeliverymidrand from "./pages/location-based-pages/same-day-delivery-midrand-section";
import samedaydeliverymmabatho from "./pages/location-based-pages/same-day-delivery-mmabatho-section";
import samedaydeliverymosselbay from "./pages/location-based-pages/same-day-delivery-mossel-bay-section";
import samedaydeliverymusina from "./pages/location-based-pages/same-day-delivery-musina-section";
import samedaydeliverynelspruit from "./pages/location-based-pages/same-day-delivery-nelspruit-section";
import samedaydeliveryoudtshoorn from "./pages/location-based-pages/same-day-delivery-oudtshoorn-section";
import samedaydeliverypaarl from "./pages/location-based-pages/same-day-delivery-paarl-section";
import samedaydeliverypietermaritzburg from "./pages/location-based-pages/same-day-delivery-pietermaritzburg-section";
import samedaydeliverypolokwane from "./pages/location-based-pages/same-day-delivery-polokwane-section";
import samedaydeliveryportelizabeth from "./pages/location-based-pages/same-day-delivery-port-elizabeth-section";
import samedaydeliveryportshepstone from "./pages/location-based-pages/same-day-delivery-port-shepstone-section";
import samedaydeliverypotchefstroom from "./pages/location-based-pages/same-day-delivery-potchefstroom-section";
import samedaydeliverypretoria from "./pages/location-based-pages/same-day-delivery-pretoria-section";
import samedaydeliveryqueenstown from "./pages/location-based-pages/same-day-delivery-queenstown-section";
import samedaydeliveryrandburg from "./pages/location-based-pages/same-day-delivery-randburg-section";
import samedaydeliveryroodepoort from "./pages/location-based-pages/same-day-delivery-roodepoort-section";
import samedaydeliveryrustenburg from "./pages/location-based-pages/same-day-delivery-rustenburg-section";
import samedaydeliverysaldanha from "./pages/location-based-pages/same-day-delivery-saldanha-section";
import samedaydeliverysandton from "./pages/location-based-pages/same-day-delivery-sandton-section";
import samedaydeliverysoweto from "./pages/location-based-pages/same-day-delivery-soweto-section";
import samedaydeliverysprings from "./pages/location-based-pages/same-day-delivery-springs-section";
import samedaydeliverystanderton from "./pages/location-based-pages/same-day-delivery-standerton-section";
import samedaydeliverythohoyandou from "./pages/location-based-pages/same-day-delivery-thohoyandou-section";
import samedaydeliverytzaneen from "./pages/location-based-pages/same-day-delivery-tzaneen-section";
import samedaydeliveryuitenhage from "./pages/location-based-pages/same-day-delivery-uitenhage-section";
import samedaydeliveryulundi from "./pages/location-based-pages/same-day-delivery-ulundi-section";
import samedaydeliveryumtata from "./pages/location-based-pages/same-day-delivery-umtata-section";
import samedaydeliveryupington from "./pages/location-based-pages/same-day-delivery-upington-section";
import samedaydeliveryvanderbijlpark from "./pages/location-based-pages/same-day-delivery-vanderbijlpark-section";
import samedaydeliveryvereeniging from "./pages/location-based-pages/same-day-delivery-vereeniging-section";
import samedaydeliveryvolksrust from "./pages/location-based-pages/same-day-delivery-volksrust-section";
import samedaydeliveryvredenburg from "./pages/location-based-pages/same-day-delivery-vredenburg-section";
import samedaydeliveryvryburg from "./pages/location-based-pages/same-day-delivery-vryburg-section";
import samedaydeliveryvryheid from "./pages/location-based-pages/same-day-delivery-vryheid-section";
import samedaydeliverywelkom from "./pages/location-based-pages/same-day-delivery-welkom-section";
import samedaydeliveryworcester from "./pages/location-based-pages/same-day-delivery-worcester-section";
import transportationalberton from "./pages/location-based-pages/transportation-alberton-section";
import transportationaliwalnorth from "./pages/location-based-pages/transportation-aliwal-north-section";
import transportationbeaufortwest from "./pages/location-based-pages/transportation-beaufort-west-section";
import transportationbenoni from "./pages/location-based-pages/transportation-benoni-section";
import transportationbethal from "./pages/location-based-pages/transportation-bethal-section";
import transportationbhisho from "./pages/location-based-pages/transportation-bhisho-section";
import transportationbloemfontein from "./pages/location-based-pages/transportation-bloemfontein-section";
import transportationbrakpan from "./pages/location-based-pages/transportation-brakpan-section";
import transportationbrits from "./pages/location-based-pages/transportation-brits-section";
import transportationcapetown from "./pages/location-based-pages/transportation-cape-town-section";
import transportationcarletonville from "./pages/location-based-pages/transportation-carletonville-section";
import transportationcenturion from "./pages/location-based-pages/transportation-centurion-section";
import transportationcompanyalberton from "./pages/location-based-pages/transportation-company-alberton-section";
import transportationcompanyaliwalnorth from "./pages/location-based-pages/transportation-company-aliwal-north-section";
import transportationcompanybeaufortwest from "./pages/location-based-pages/transportation-company-beaufort-west-section";
import transportationcompanybenoni from "./pages/location-based-pages/transportation-company-benoni-section";
import transportationcompanybethal from "./pages/location-based-pages/transportation-company-bethal-section";
import transportationcompanybhisho from "./pages/location-based-pages/transportation-company-bhisho-section";
import transportationcompanybloemfontein from "./pages/location-based-pages/transportation-company-bloemfontein-section";
import transportationcompanybrakpan from "./pages/location-based-pages/transportation-company-brakpan-section";
import transportationcompanybrits from "./pages/location-based-pages/transportation-company-brits-section";
import transportationcompanycapetown from "./pages/location-based-pages/transportation-company-cape-town-section";
import transportationcompanycarletonville from "./pages/location-based-pages/transportation-company-carletonville-section";
import transportationcompanycenturion from "./pages/location-based-pages/transportation-company-centurion-section";
import transportationcompanycradock from "./pages/location-based-pages/transportation-company-cradock-section";
import transportationcompanydeaar from "./pages/location-based-pages/transportation-company-de-aar-section";
import transportationcompanydurban from "./pages/location-based-pages/transportation-company-durban-section";
import transportationcompanyeastlondon from "./pages/location-based-pages/transportation-company-east-london-section";
import transportationcompanyferndale from "./pages/location-based-pages/transportation-company-ferndale-section";
import transportationcompanygeorge from "./pages/location-based-pages/transportation-company-george-section";
import transportationcompanygraaffreinet from "./pages/location-based-pages/transportation-company-graaff-reinet-section";
import transportationcompanygrahamstown from "./pages/location-based-pages/transportation-company-grahamstown-section";
import transportationcompanyhermanus from "./pages/location-based-pages/transportation-company-hermanus-section";
import transportationcompanyjeffreysbay from "./pages/location-based-pages/transportation-company-jeffreys-bay-section";
import transportationcompanyjohannesburg from "./pages/location-based-pages/transportation-company-johannesburg-section";
import transportationcompanykimberley from "./pages/location-based-pages/transportation-company-kimberley-section";
import transportationcompanyklerksdorp from "./pages/location-based-pages/transportation-company-klerksdorp-section";
import transportationcompanyknysna from "./pages/location-based-pages/transportation-company-knysna-section";
import transportationcompanykroonstad from "./pages/location-based-pages/transportation-company-kroonstad-section";
import transportationcompanykuilsrivier from "./pages/location-based-pages/transportation-company-kuilsrivier-section";
import transportationcompanyladysmith from "./pages/location-based-pages/transportation-company-ladysmith-section";
import transportationcompanylebowakgomo from "./pages/location-based-pages/transportation-company-lebowakgomo-section";
import transportationcompanylichtenburg from "./pages/location-based-pages/transportation-company-lichtenburg-section";
import transportationcompanymahikeng from "./pages/location-based-pages/transportation-company-mahikeng-section";
import transportationcompanymalmesbury from "./pages/location-based-pages/transportation-company-malmesbury-section";
import transportationcompanymiddelburg from "./pages/location-based-pages/transportation-company-middelburg-section";
import transportationcompanymidrand from "./pages/location-based-pages/transportation-company-midrand-section";
import transportationcompanymmabatho from "./pages/location-based-pages/transportation-company-mmabatho-section";
import transportationcompanymosselbay from "./pages/location-based-pages/transportation-company-mossel-bay-section";
import transportationcompanymusina from "./pages/location-based-pages/transportation-company-musina-section";
import transportationcompanynelspruit from "./pages/location-based-pages/transportation-company-nelspruit-section";
import transportationcompanyoudtshoorn from "./pages/location-based-pages/transportation-company-oudtshoorn-section";
import transportationcompanypaarl from "./pages/location-based-pages/transportation-company-paarl-section";
import transportationcompanypietermaritzburg from "./pages/location-based-pages/transportation-company-pietermaritzburg-section";
import transportationcompanypolokwane from "./pages/location-based-pages/transportation-company-polokwane-section";
import transportationcompanyportelizabeth from "./pages/location-based-pages/transportation-company-port-elizabeth-section";
import transportationcompanyportshepstone from "./pages/location-based-pages/transportation-company-port-shepstone-section";
import transportationcompanypotchefstroom from "./pages/location-based-pages/transportation-company-potchefstroom-section";
import transportationcompanypretoria from "./pages/location-based-pages/transportation-company-pretoria-section";
import transportationcompanyqueenstown from "./pages/location-based-pages/transportation-company-queenstown-section";
import transportationcompanyrandburg from "./pages/location-based-pages/transportation-company-randburg-section";
import transportationcompanyroodepoort from "./pages/location-based-pages/transportation-company-roodepoort-section";
import transportationcompanyrustenburg from "./pages/location-based-pages/transportation-company-rustenburg-section";
import transportationcompanysaldanha from "./pages/location-based-pages/transportation-company-saldanha-section";
import transportationcompanysandton from "./pages/location-based-pages/transportation-company-sandton-section";
import transportationcompanysoweto from "./pages/location-based-pages/transportation-company-soweto-section";
import transportationcompanysprings from "./pages/location-based-pages/transportation-company-springs-section";
import transportationcompanystanderton from "./pages/location-based-pages/transportation-company-standerton-section";
import transportationcompanythohoyandou from "./pages/location-based-pages/transportation-company-thohoyandou-section";
import transportationcompanytzaneen from "./pages/location-based-pages/transportation-company-tzaneen-section";
import transportationcompanyuitenhage from "./pages/location-based-pages/transportation-company-uitenhage-section";
import transportationcompanyulundi from "./pages/location-based-pages/transportation-company-ulundi-section";
import transportationcompanyumtata from "./pages/location-based-pages/transportation-company-umtata-section";
import transportationcompanyupington from "./pages/location-based-pages/transportation-company-upington-section";
import transportationcompanyvanderbijlpark from "./pages/location-based-pages/transportation-company-vanderbijlpark-section";
import transportationcompanyvereeniging from "./pages/location-based-pages/transportation-company-vereeniging-section";
import transportationcompanyvolksrust from "./pages/location-based-pages/transportation-company-volksrust-section";
import transportationcompanyvredenburg from "./pages/location-based-pages/transportation-company-vredenburg-section";
import transportationcompanyvryburg from "./pages/location-based-pages/transportation-company-vryburg-section";
import transportationcompanyvryheid from "./pages/location-based-pages/transportation-company-vryheid-section";
import transportationcompanywelkom from "./pages/location-based-pages/transportation-company-welkom-section";
import transportationcompanyworcester from "./pages/location-based-pages/transportation-company-worcester-section";
import transportationcradock from "./pages/location-based-pages/transportation-cradock-section";
import transportationdeaar from "./pages/location-based-pages/transportation-de-aar-section";
import transportationdurban from "./pages/location-based-pages/transportation-durban-section";
import transportationeastlondon from "./pages/location-based-pages/transportation-east-london-section";
import transportationferndale from "./pages/location-based-pages/transportation-ferndale-section";
import transportationgeorge from "./pages/location-based-pages/transportation-george-section";
import transportationgraaffreinet from "./pages/location-based-pages/transportation-graaff-reinet-section";
import transportationgrahamstown from "./pages/location-based-pages/transportation-grahamstown-section";
import transportationhermanus from "./pages/location-based-pages/transportation-hermanus-section";
import transportationjeffreysbay from "./pages/location-based-pages/transportation-jeffreys-bay-section";
import transportationjohannesburg from "./pages/location-based-pages/transportation-johannesburg-section";
import transportationkimberley from "./pages/location-based-pages/transportation-kimberley-section";
import transportationklerksdorp from "./pages/location-based-pages/transportation-klerksdorp-section";
import transportationknysna from "./pages/location-based-pages/transportation-knysna-section";
import transportationkroonstad from "./pages/location-based-pages/transportation-kroonstad-section";
import transportationkuilsrivier from "./pages/location-based-pages/transportation-kuilsrivier-section";
import transportationladysmith from "./pages/location-based-pages/transportation-ladysmith-section";
import transportationlebowakgomo from "./pages/location-based-pages/transportation-lebowakgomo-section";
import transportationlichtenburg from "./pages/location-based-pages/transportation-lichtenburg-section";
import transportationmahikeng from "./pages/location-based-pages/transportation-mahikeng-section";
import transportationmalmesbury from "./pages/location-based-pages/transportation-malmesbury-section";
import transportationmiddelburg from "./pages/location-based-pages/transportation-middelburg-section";
import transportationmidrand from "./pages/location-based-pages/transportation-midrand-section";
import transportationmmabatho from "./pages/location-based-pages/transportation-mmabatho-section";
import transportationmosselbay from "./pages/location-based-pages/transportation-mossel-bay-section";
import transportationmusina from "./pages/location-based-pages/transportation-musina-section";
import transportationnelspruit from "./pages/location-based-pages/transportation-nelspruit-section";
import transportationoudtshoorn from "./pages/location-based-pages/transportation-oudtshoorn-section";
import transportationpaarl from "./pages/location-based-pages/transportation-paarl-section";
import transportationpietermaritzburg from "./pages/location-based-pages/transportation-pietermaritzburg-section";
import transportationpolokwane from "./pages/location-based-pages/transportation-polokwane-section";
import transportationportelizabeth from "./pages/location-based-pages/transportation-port-elizabeth-section";
import transportationportshepstone from "./pages/location-based-pages/transportation-port-shepstone-section";
import transportationpotchefstroom from "./pages/location-based-pages/transportation-potchefstroom-section";
import transportationpretoria from "./pages/location-based-pages/transportation-pretoria-section";
import transportationqueenstown from "./pages/location-based-pages/transportation-queenstown-section";
import transportationrandburg from "./pages/location-based-pages/transportation-randburg-section";
import transportationroodepoort from "./pages/location-based-pages/transportation-roodepoort-section";
import transportationrustenburg from "./pages/location-based-pages/transportation-rustenburg-section";
import transportationsaldanha from "./pages/location-based-pages/transportation-saldanha-section";
import transportationsandton from "./pages/location-based-pages/transportation-sandton-section";
import transportationsoweto from "./pages/location-based-pages/transportation-soweto-section";
import transportationsprings from "./pages/location-based-pages/transportation-springs-section";
import transportationstanderton from "./pages/location-based-pages/transportation-standerton-section";
import transportationthohoyandou from "./pages/location-based-pages/transportation-thohoyandou-section";
import transportationtzaneen from "./pages/location-based-pages/transportation-tzaneen-section";
import transportationuitenhage from "./pages/location-based-pages/transportation-uitenhage-section";
import transportationulundi from "./pages/location-based-pages/transportation-ulundi-section";
import transportationumtata from "./pages/location-based-pages/transportation-umtata-section";
import transportationupington from "./pages/location-based-pages/transportation-upington-section";
import transportationvanderbijlpark from "./pages/location-based-pages/transportation-vanderbijlpark-section";
import transportationvereeniging from "./pages/location-based-pages/transportation-vereeniging-section";
import transportationvolksrust from "./pages/location-based-pages/transportation-volksrust-section";
import transportationvredenburg from "./pages/location-based-pages/transportation-vredenburg-section";
import transportationvryburg from "./pages/location-based-pages/transportation-vryburg-section";
import transportationvryheid from "./pages/location-based-pages/transportation-vryheid-section";
import transportationwelkom from "./pages/location-based-pages/transportation-welkom-section";
import transportationworcester from "./pages/location-based-pages/transportation-worcester-section";
import truckanddriverhirealberton from "./pages/location-based-pages/truck-and-driver-hire-alberton-section";
import truckanddriverhirealiwalnorth from "./pages/location-based-pages/truck-and-driver-hire-aliwal-north-section";
import truckanddriverhirebeaufortwest from "./pages/location-based-pages/truck-and-driver-hire-beaufort-west-section";
import truckanddriverhirebenoni from "./pages/location-based-pages/truck-and-driver-hire-benoni-section";
import truckanddriverhirebethal from "./pages/location-based-pages/truck-and-driver-hire-bethal-section";
import truckanddriverhirebhisho from "./pages/location-based-pages/truck-and-driver-hire-bhisho-section";
import truckanddriverhirebloemfontein from "./pages/location-based-pages/truck-and-driver-hire-bloemfontein-section";
import truckanddriverhirebrakpan from "./pages/location-based-pages/truck-and-driver-hire-brakpan-section";
import truckanddriverhirebrits from "./pages/location-based-pages/truck-and-driver-hire-brits-section";
import truckanddriverhirecapetown from "./pages/location-based-pages/truck-and-driver-hire-cape-town-section";
import truckanddriverhirecarletonville from "./pages/location-based-pages/truck-and-driver-hire-carletonville-section";
import truckanddriverhirecenturion from "./pages/location-based-pages/truck-and-driver-hire-centurion-section";
import truckanddriverhirecradock from "./pages/location-based-pages/truck-and-driver-hire-cradock-section";
import truckanddriverhiredeaar from "./pages/location-based-pages/truck-and-driver-hire-de-aar-section";
import truckanddriverhiredurban from "./pages/location-based-pages/truck-and-driver-hire-durban-section";
import truckanddriverhireeastlondon from "./pages/location-based-pages/truck-and-driver-hire-east-london-section";
import truckanddriverhireferndale from "./pages/location-based-pages/truck-and-driver-hire-ferndale-section";
import truckanddriverhiregeorge from "./pages/location-based-pages/truck-and-driver-hire-george-section";
import truckanddriverhiregraaffreinet from "./pages/location-based-pages/truck-and-driver-hire-graaff-reinet-section";
import truckanddriverhiregrahamstown from "./pages/location-based-pages/truck-and-driver-hire-grahamstown-section";
import truckanddriverhirehermanus from "./pages/location-based-pages/truck-and-driver-hire-hermanus-section";
import truckanddriverhirejeffreysbay from "./pages/location-based-pages/truck-and-driver-hire-jeffreys-bay-section";
import truckanddriverhirejohannesburg from "./pages/location-based-pages/truck-and-driver-hire-johannesburg-section";
import truckanddriverhirekimberley from "./pages/location-based-pages/truck-and-driver-hire-kimberley-section";
import truckanddriverhireklerksdorp from "./pages/location-based-pages/truck-and-driver-hire-klerksdorp-section";
import truckanddriverhireknysna from "./pages/location-based-pages/truck-and-driver-hire-knysna-section";
import truckanddriverhirekroonstad from "./pages/location-based-pages/truck-and-driver-hire-kroonstad-section";
import truckanddriverhirekuilsrivier from "./pages/location-based-pages/truck-and-driver-hire-kuilsrivier-section";
import truckanddriverhireladysmith from "./pages/location-based-pages/truck-and-driver-hire-ladysmith-section";
import truckanddriverhirelebowakgomo from "./pages/location-based-pages/truck-and-driver-hire-lebowakgomo-section";
import truckanddriverhirelichtenburg from "./pages/location-based-pages/truck-and-driver-hire-lichtenburg-section";
import truckanddriverhiremahikeng from "./pages/location-based-pages/truck-and-driver-hire-mahikeng-section";
import truckanddriverhiremalmesbury from "./pages/location-based-pages/truck-and-driver-hire-malmesbury-section";
import truckanddriverhiremiddelburg from "./pages/location-based-pages/truck-and-driver-hire-middelburg-section";
import truckanddriverhiremidrand from "./pages/location-based-pages/truck-and-driver-hire-midrand-section";
import truckanddriverhiremmabatho from "./pages/location-based-pages/truck-and-driver-hire-mmabatho-section";
import truckanddriverhiremosselbay from "./pages/location-based-pages/truck-and-driver-hire-mossel-bay-section";
import truckanddriverhiremusina from "./pages/location-based-pages/truck-and-driver-hire-musina-section";
import truckanddriverhirenelspruit from "./pages/location-based-pages/truck-and-driver-hire-nelspruit-section";
import truckanddriverhireoudtshoorn from "./pages/location-based-pages/truck-and-driver-hire-oudtshoorn-section";
import truckanddriverhirepaarl from "./pages/location-based-pages/truck-and-driver-hire-paarl-section";
import truckanddriverhirepietermaritzburg from "./pages/location-based-pages/truck-and-driver-hire-pietermaritzburg-section";
import truckanddriverhirepolokwane from "./pages/location-based-pages/truck-and-driver-hire-polokwane-section";
import truckanddriverhireportelizabeth from "./pages/location-based-pages/truck-and-driver-hire-port-elizabeth-section";
import truckanddriverhireportshepstone from "./pages/location-based-pages/truck-and-driver-hire-port-shepstone-section";
import truckanddriverhirepotchefstroom from "./pages/location-based-pages/truck-and-driver-hire-potchefstroom-section";
import truckanddriverhirepretoria from "./pages/location-based-pages/truck-and-driver-hire-pretoria-section";
import truckanddriverhirequeenstown from "./pages/location-based-pages/truck-and-driver-hire-queenstown-section";
import truckanddriverhirerandburg from "./pages/location-based-pages/truck-and-driver-hire-randburg-section";
import truckanddriverhireroodepoort from "./pages/location-based-pages/truck-and-driver-hire-roodepoort-section";
import truckanddriverhirerustenburg from "./pages/location-based-pages/truck-and-driver-hire-rustenburg-section";
import truckanddriverhiresaldanha from "./pages/location-based-pages/truck-and-driver-hire-saldanha-section";
import truckanddriverhiresandton from "./pages/location-based-pages/truck-and-driver-hire-sandton-section";
import truckanddriverhiresoweto from "./pages/location-based-pages/truck-and-driver-hire-soweto-section";
import truckanddriverhiresprings from "./pages/location-based-pages/truck-and-driver-hire-springs-section";
import truckanddriverhirestanderton from "./pages/location-based-pages/truck-and-driver-hire-standerton-section";
import truckanddriverhirethohoyandou from "./pages/location-based-pages/truck-and-driver-hire-thohoyandou-section";
import truckanddriverhiretzaneen from "./pages/location-based-pages/truck-and-driver-hire-tzaneen-section";
import truckanddriverhireuitenhage from "./pages/location-based-pages/truck-and-driver-hire-uitenhage-section";
import truckanddriverhireulundi from "./pages/location-based-pages/truck-and-driver-hire-ulundi-section";
import truckanddriverhireumtata from "./pages/location-based-pages/truck-and-driver-hire-umtata-section";
import truckanddriverhireupington from "./pages/location-based-pages/truck-and-driver-hire-upington-section";
import truckanddriverhirevanderbijlpark from "./pages/location-based-pages/truck-and-driver-hire-vanderbijlpark-section";
import truckanddriverhirevereeniging from "./pages/location-based-pages/truck-and-driver-hire-vereeniging-section";
import truckanddriverhirevolksrust from "./pages/location-based-pages/truck-and-driver-hire-volksrust-section";
import truckanddriverhirevredenburg from "./pages/location-based-pages/truck-and-driver-hire-vredenburg-section";
import truckanddriverhirevryburg from "./pages/location-based-pages/truck-and-driver-hire-vryburg-section";
import truckanddriverhirevryheid from "./pages/location-based-pages/truck-and-driver-hire-vryheid-section";
import truckanddriverhirewelkom from "./pages/location-based-pages/truck-and-driver-hire-welkom-section";
import truckanddriverhireworcester from "./pages/location-based-pages/truck-and-driver-hire-worcester-section";
import uberalberton from "./pages/location-based-pages/uber-alberton-section";
import uberaliwalnorth from "./pages/location-based-pages/uber-aliwal-north-section";
import uberbeaufortwest from "./pages/location-based-pages/uber-beaufort-west-section";
import uberbenoni from "./pages/location-based-pages/uber-benoni-section";
import uberbethal from "./pages/location-based-pages/uber-bethal-section";
import uberbhisho from "./pages/location-based-pages/uber-bhisho-section";
import uberbloemfontein from "./pages/location-based-pages/uber-bloemfontein-section";
import uberbrakpan from "./pages/location-based-pages/uber-brakpan-section";
import uberbrits from "./pages/location-based-pages/uber-brits-section";
import ubercapetown from "./pages/location-based-pages/uber-cape-town-section";
import ubercarletonville from "./pages/location-based-pages/uber-carletonville-section";
import ubercenturion from "./pages/location-based-pages/uber-centurion-section";
import ubercradock from "./pages/location-based-pages/uber-cradock-section";
import uberdeaar from "./pages/location-based-pages/uber-de-aar-section";
import uberdurban from "./pages/location-based-pages/uber-durban-section";
import ubereastlondon from "./pages/location-based-pages/uber-east-london-section";
import uberferndale from "./pages/location-based-pages/uber-ferndale-section";
import ubergeorge from "./pages/location-based-pages/uber-george-section";
import ubergraaffreinet from "./pages/location-based-pages/uber-graaff-reinet-section";
import ubergrahamstown from "./pages/location-based-pages/uber-grahamstown-section";
import uberhermanus from "./pages/location-based-pages/uber-hermanus-section";
import uberjeffreysbay from "./pages/location-based-pages/uber-jeffreys-bay-section";
import uberjohannesburg from "./pages/location-based-pages/uber-johannesburg-section";
import uberkimberley from "./pages/location-based-pages/uber-kimberley-section";
import uberklerksdorp from "./pages/location-based-pages/uber-klerksdorp-section";
import uberknysna from "./pages/location-based-pages/uber-knysna-section";
import uberkroonstad from "./pages/location-based-pages/uber-kroonstad-section";
import uberkuilsrivier from "./pages/location-based-pages/uber-kuilsrivier-section";
import uberladysmith from "./pages/location-based-pages/uber-ladysmith-section";
import uberlebowakgomo from "./pages/location-based-pages/uber-lebowakgomo-section";
import uberlichtenburg from "./pages/location-based-pages/uber-lichtenburg-section";
import ubermahikeng from "./pages/location-based-pages/uber-mahikeng-section";
import ubermalmesbury from "./pages/location-based-pages/uber-malmesbury-section";
import ubermiddelburg from "./pages/location-based-pages/uber-middelburg-section";
import ubermidrand from "./pages/location-based-pages/uber-midrand-section";
import ubermmabatho from "./pages/location-based-pages/uber-mmabatho-section";
import ubermosselbay from "./pages/location-based-pages/uber-mossel-bay-section";
import ubermusina from "./pages/location-based-pages/uber-musina-section";
import ubernelspruit from "./pages/location-based-pages/uber-nelspruit-section";
import uberoudtshoorn from "./pages/location-based-pages/uber-oudtshoorn-section";
import uberpaarl from "./pages/location-based-pages/uber-paarl-section";
import uberpietermaritzburg from "./pages/location-based-pages/uber-pietermaritzburg-section";
import uberpolokwane from "./pages/location-based-pages/uber-polokwane-section";
import uberportelizabeth from "./pages/location-based-pages/uber-port-elizabeth-section";
import uberportshepstone from "./pages/location-based-pages/uber-port-shepstone-section";
import uberpotchefstroom from "./pages/location-based-pages/uber-potchefstroom-section";
import uberpretoria from "./pages/location-based-pages/uber-pretoria-section";
import uberqueenstown from "./pages/location-based-pages/uber-queenstown-section";
import uberrandburg from "./pages/location-based-pages/uber-randburg-section";
import uberroodepoort from "./pages/location-based-pages/uber-roodepoort-section";
import uberrustenburg from "./pages/location-based-pages/uber-rustenburg-section";
import ubersaldanha from "./pages/location-based-pages/uber-saldanha-section";
import ubersandton from "./pages/location-based-pages/uber-sandton-section";
import ubersoweto from "./pages/location-based-pages/uber-soweto-section";
import ubersprings from "./pages/location-based-pages/uber-springs-section";
import uberstanderton from "./pages/location-based-pages/uber-standerton-section";
import uberthohoyandou from "./pages/location-based-pages/uber-thohoyandou-section";
import ubertruckalberton from "./pages/location-based-pages/uber-truck-alberton-section";
import ubertruckaliwalnorth from "./pages/location-based-pages/uber-truck-aliwal-north-section";
import ubertruckbeaufortwest from "./pages/location-based-pages/uber-truck-beaufort-west-section";
import ubertruckbenoni from "./pages/location-based-pages/uber-truck-benoni-section";
import ubertruckbethal from "./pages/location-based-pages/uber-truck-bethal-section";
import ubertruckbhisho from "./pages/location-based-pages/uber-truck-bhisho-section";
import ubertruckbloemfontein from "./pages/location-based-pages/uber-truck-bloemfontein-section";
import ubertruckbrakpan from "./pages/location-based-pages/uber-truck-brakpan-section";
import ubertruckbrits from "./pages/location-based-pages/uber-truck-brits-section";
import ubertruckcapetown from "./pages/location-based-pages/uber-truck-cape-town-section";
import ubertruckcarletonville from "./pages/location-based-pages/uber-truck-carletonville-section";
import ubertruckcenturion from "./pages/location-based-pages/uber-truck-centurion-section";
import ubertruckcradock from "./pages/location-based-pages/uber-truck-cradock-section";
import ubertruckdeaar from "./pages/location-based-pages/uber-truck-de-aar-section";
import ubertruckdurban from "./pages/location-based-pages/uber-truck-durban-section";
import ubertruckeastlondon from "./pages/location-based-pages/uber-truck-east-london-section";
import ubertruckferndale from "./pages/location-based-pages/uber-truck-ferndale-section";
import ubertruckgeorge from "./pages/location-based-pages/uber-truck-george-section";
import ubertruckgraaffreinet from "./pages/location-based-pages/uber-truck-graaff-reinet-section";
import ubertruckgrahamstown from "./pages/location-based-pages/uber-truck-grahamstown-section";
import ubertruckhermanus from "./pages/location-based-pages/uber-truck-hermanus-section";
import ubertruckjeffreysbay from "./pages/location-based-pages/uber-truck-jeffreys-bay-section";
import ubertruckjohannesburg from "./pages/location-based-pages/uber-truck-johannesburg-section";
import ubertruckkimberley from "./pages/location-based-pages/uber-truck-kimberley-section";
import ubertruckklerksdorp from "./pages/location-based-pages/uber-truck-klerksdorp-section";
import ubertruckknysna from "./pages/location-based-pages/uber-truck-knysna-section";
import ubertruckkroonstad from "./pages/location-based-pages/uber-truck-kroonstad-section";
import ubertruckkuilsrivier from "./pages/location-based-pages/uber-truck-kuilsrivier-section";
import ubertruckladysmith from "./pages/location-based-pages/uber-truck-ladysmith-section";
import ubertrucklebowakgomo from "./pages/location-based-pages/uber-truck-lebowakgomo-section";
import ubertrucklichtenburg from "./pages/location-based-pages/uber-truck-lichtenburg-section";
import ubertruckmahikeng from "./pages/location-based-pages/uber-truck-mahikeng-section";
import ubertruckmalmesbury from "./pages/location-based-pages/uber-truck-malmesbury-section";
import ubertruckmiddelburg from "./pages/location-based-pages/uber-truck-middelburg-section";
import ubertruckmidrand from "./pages/location-based-pages/uber-truck-midrand-section";
import ubertruckmmabatho from "./pages/location-based-pages/uber-truck-mmabatho-section";
import ubertruckmosselbay from "./pages/location-based-pages/uber-truck-mossel-bay-section";
import ubertruckmusina from "./pages/location-based-pages/uber-truck-musina-section";
import ubertrucknelspruit from "./pages/location-based-pages/uber-truck-nelspruit-section";
import ubertruckoudtshoorn from "./pages/location-based-pages/uber-truck-oudtshoorn-section";
import ubertruckpaarl from "./pages/location-based-pages/uber-truck-paarl-section";
import ubertruckpietermaritzburg from "./pages/location-based-pages/uber-truck-pietermaritzburg-section";
import ubertruckpolokwane from "./pages/location-based-pages/uber-truck-polokwane-section";
import ubertruckportelizabeth from "./pages/location-based-pages/uber-truck-port-elizabeth-section";
import ubertruckportshepstone from "./pages/location-based-pages/uber-truck-port-shepstone-section";
import ubertruckpotchefstroom from "./pages/location-based-pages/uber-truck-potchefstroom-section";
import ubertruckpretoria from "./pages/location-based-pages/uber-truck-pretoria-section";
import ubertruckqueenstown from "./pages/location-based-pages/uber-truck-queenstown-section";
import ubertruckrandburg from "./pages/location-based-pages/uber-truck-randburg-section";
import ubertruckroodepoort from "./pages/location-based-pages/uber-truck-roodepoort-section";
import ubertruckrustenburg from "./pages/location-based-pages/uber-truck-rustenburg-section";
import ubertrucksaldanha from "./pages/location-based-pages/uber-truck-saldanha-section";
import ubertrucksandton from "./pages/location-based-pages/uber-truck-sandton-section";
import ubertrucksoweto from "./pages/location-based-pages/uber-truck-soweto-section";
import ubertrucksprings from "./pages/location-based-pages/uber-truck-springs-section";
import ubertruckstanderton from "./pages/location-based-pages/uber-truck-standerton-section";
import ubertruckthohoyandou from "./pages/location-based-pages/uber-truck-thohoyandou-section";
import ubertrucktzaneen from "./pages/location-based-pages/uber-truck-tzaneen-section";
import ubertruckuitenhage from "./pages/location-based-pages/uber-truck-uitenhage-section";
import ubertruckulundi from "./pages/location-based-pages/uber-truck-ulundi-section";
import ubertruckumtata from "./pages/location-based-pages/uber-truck-umtata-section";
import ubertruckupington from "./pages/location-based-pages/uber-truck-upington-section";
import ubertruckvanderbijlpark from "./pages/location-based-pages/uber-truck-vanderbijlpark-section";
import ubertruckvereeniging from "./pages/location-based-pages/uber-truck-vereeniging-section";
import ubertruckvolksrust from "./pages/location-based-pages/uber-truck-volksrust-section";
import ubertruckvredenburg from "./pages/location-based-pages/uber-truck-vredenburg-section";
import ubertruckvryburg from "./pages/location-based-pages/uber-truck-vryburg-section";
import ubertruckvryheid from "./pages/location-based-pages/uber-truck-vryheid-section";
import ubertruckwelkom from "./pages/location-based-pages/uber-truck-welkom-section";
import ubertruckworcester from "./pages/location-based-pages/uber-truck-worcester-section";
import ubertzaneen from "./pages/location-based-pages/uber-tzaneen-section";
import uberuitenhage from "./pages/location-based-pages/uber-uitenhage-section";
import uberulundi from "./pages/location-based-pages/uber-ulundi-section";
import uberumtata from "./pages/location-based-pages/uber-umtata-section";
import uberupington from "./pages/location-based-pages/uber-upington-section";
import ubervanderbijlpark from "./pages/location-based-pages/uber-vanderbijlpark-section";
import ubervereeniging from "./pages/location-based-pages/uber-vereeniging-section";
import ubervolksrust from "./pages/location-based-pages/uber-volksrust-section";
import ubervredenburg from "./pages/location-based-pages/uber-vredenburg-section";
import ubervryburg from "./pages/location-based-pages/uber-vryburg-section";
import ubervryheid from "./pages/location-based-pages/uber-vryheid-section";
import uberwelkom from "./pages/location-based-pages/uber-welkom-section";
import uberworcester from "./pages/location-based-pages/uber-worcester-section";
import vanhirealberton from "./pages/location-based-pages/van-hire--alberton-section";
import vanhirealiwalnorth from "./pages/location-based-pages/van-hire--aliwal-north-section";
import vanhirebeaufortwest from "./pages/location-based-pages/van-hire--beaufort-west-section";
import vanhirebenoni from "./pages/location-based-pages/van-hire--benoni-section";
import vanhirebethal from "./pages/location-based-pages/van-hire--bethal-section";
import vanhirebhisho from "./pages/location-based-pages/van-hire--bhisho-section";
import vanhirebloemfontein from "./pages/location-based-pages/van-hire--bloemfontein-section";
import vanhirebrakpan from "./pages/location-based-pages/van-hire--brakpan-section";
import vanhirebrits from "./pages/location-based-pages/van-hire--brits-section";
import vanhirecapetown from "./pages/location-based-pages/van-hire--cape-town-section";
import vanhirecarletonville from "./pages/location-based-pages/van-hire--carletonville-section";
import vanhirecenturion from "./pages/location-based-pages/van-hire--centurion-section";
import vanhirecradock from "./pages/location-based-pages/van-hire--cradock-section";
import vanhiredeaar from "./pages/location-based-pages/van-hire--de-aar-section";
import vanhiredurban from "./pages/location-based-pages/van-hire--durban-section";
import vanhireeastlondon from "./pages/location-based-pages/van-hire--east-london-section";
import vanhireferndale from "./pages/location-based-pages/van-hire--ferndale-section";
import vanhiregeorge from "./pages/location-based-pages/van-hire--george-section";
import vanhiregraaffreinet from "./pages/location-based-pages/van-hire--graaff-reinet-section";
import vanhiregrahamstown from "./pages/location-based-pages/van-hire--grahamstown-section";
import vanhirehermanus from "./pages/location-based-pages/van-hire--hermanus-section";
import vanhirejeffreysbay from "./pages/location-based-pages/van-hire--jeffreys-bay-section";
import vanhirejohannesburg from "./pages/location-based-pages/van-hire--johannesburg-section";
import vanhirekimberley from "./pages/location-based-pages/van-hire--kimberley-section";
import vanhireklerksdorp from "./pages/location-based-pages/van-hire--klerksdorp-section";
import vanhireknysna from "./pages/location-based-pages/van-hire--knysna-section";
import vanhirekroonstad from "./pages/location-based-pages/van-hire--kroonstad-section";
import vanhirekuilsrivier from "./pages/location-based-pages/van-hire--kuilsrivier-section";
import vanhireladysmith from "./pages/location-based-pages/van-hire--ladysmith-section";
import vanhirelebowakgomo from "./pages/location-based-pages/van-hire--lebowakgomo-section";
import vanhirelichtenburg from "./pages/location-based-pages/van-hire--lichtenburg-section";
import vanhiremahikeng from "./pages/location-based-pages/van-hire--mahikeng-section";
import vanhiremalmesbury from "./pages/location-based-pages/van-hire--malmesbury-section";
import vanhiremiddelburg from "./pages/location-based-pages/van-hire--middelburg-section";
import vanhiremidrand from "./pages/location-based-pages/van-hire--midrand-section";
import vanhiremmabatho from "./pages/location-based-pages/van-hire--mmabatho-section";
import vanhiremosselbay from "./pages/location-based-pages/van-hire--mossel-bay-section";
import vanhiremusina from "./pages/location-based-pages/van-hire--musina-section";
import vanhirenelspruit from "./pages/location-based-pages/van-hire--nelspruit-section";
import vanhireoudtshoorn from "./pages/location-based-pages/van-hire--oudtshoorn-section";
import vanhirepaarl from "./pages/location-based-pages/van-hire--paarl-section";
import vanhirepietermaritzburg from "./pages/location-based-pages/van-hire--pietermaritzburg-section";
import vanhirepolokwane from "./pages/location-based-pages/van-hire--polokwane-section";
import vanhireportelizabeth from "./pages/location-based-pages/van-hire--port-elizabeth-section";
import vanhireportshepstone from "./pages/location-based-pages/van-hire--port-shepstone-section";
import vanhirepotchefstroom from "./pages/location-based-pages/van-hire--potchefstroom-section";
import vanhirepretoria from "./pages/location-based-pages/van-hire--pretoria-section";
import vanhirequeenstown from "./pages/location-based-pages/van-hire--queenstown-section";
import vanhirerandburg from "./pages/location-based-pages/van-hire--randburg-section";
import vanhireroodepoort from "./pages/location-based-pages/van-hire--roodepoort-section";
import vanhirerustenburg from "./pages/location-based-pages/van-hire--rustenburg-section";
import vanhiresaldanha from "./pages/location-based-pages/van-hire--saldanha-section";
import vanhiresandton from "./pages/location-based-pages/van-hire--sandton-section";
import vanhiresoweto from "./pages/location-based-pages/van-hire--soweto-section";
import vanhiresprings from "./pages/location-based-pages/van-hire--springs-section";
import vanhirestanderton from "./pages/location-based-pages/van-hire--standerton-section";
import vanhirethohoyandou from "./pages/location-based-pages/van-hire--thohoyandou-section";
import vanhiretzaneen from "./pages/location-based-pages/van-hire--tzaneen-section";
import vanhireuitenhage from "./pages/location-based-pages/van-hire--uitenhage-section";
import vanhireulundi from "./pages/location-based-pages/van-hire--ulundi-section";
import vanhireumtata from "./pages/location-based-pages/van-hire--umtata-section";
import vanhireupington from "./pages/location-based-pages/van-hire--upington-section";
import vanhirevanderbijlpark from "./pages/location-based-pages/van-hire--vanderbijlpark-section";
import vanhirevereeniging from "./pages/location-based-pages/van-hire--vereeniging-section";
import vanhirevolksrust from "./pages/location-based-pages/van-hire--volksrust-section";
import vanhirevredenburg from "./pages/location-based-pages/van-hire--vredenburg-section";
import vanhirevryburg from "./pages/location-based-pages/van-hire--vryburg-section";
import vanhirevryheid from "./pages/location-based-pages/van-hire--vryheid-section";
import vanhirewelkom from "./pages/location-based-pages/van-hire--welkom-section";
import vanhireworcester from "./pages/location-based-pages/van-hire--worcester-section";
import vehiclerentalalberton from "./pages/location-based-pages/vehicle-rental-alberton-section";
import vehiclerentalaliwalnorth from "./pages/location-based-pages/vehicle-rental-aliwal-north-section";
import vehiclerentalbeaufortwest from "./pages/location-based-pages/vehicle-rental-beaufort-west-section";
import vehiclerentalbenoni from "./pages/location-based-pages/vehicle-rental-benoni-section";
import vehiclerentalbethal from "./pages/location-based-pages/vehicle-rental-bethal-section";
import vehiclerentalbhisho from "./pages/location-based-pages/vehicle-rental-bhisho-section";
import vehiclerentalbloemfontein from "./pages/location-based-pages/vehicle-rental-bloemfontein-section";
import vehiclerentalbrakpan from "./pages/location-based-pages/vehicle-rental-brakpan-section";
import vehiclerentalbrits from "./pages/location-based-pages/vehicle-rental-brits-section";
import vehiclerentalcapetown from "./pages/location-based-pages/vehicle-rental-cape-town-section";
import vehiclerentalcarletonville from "./pages/location-based-pages/vehicle-rental-carletonville-section";
import vehiclerentalcenturion from "./pages/location-based-pages/vehicle-rental-centurion-section";
import vehiclerentalcradock from "./pages/location-based-pages/vehicle-rental-cradock-section";
import vehiclerentaldeaar from "./pages/location-based-pages/vehicle-rental-de-aar-section";
import vehiclerentaldurban from "./pages/location-based-pages/vehicle-rental-durban-section";
import vehiclerentaleastlondon from "./pages/location-based-pages/vehicle-rental-east-london-section";
import vehiclerentalferndale from "./pages/location-based-pages/vehicle-rental-ferndale-section";
import vehiclerentalgeorge from "./pages/location-based-pages/vehicle-rental-george-section";
import vehiclerentalgraaffreinet from "./pages/location-based-pages/vehicle-rental-graaff-reinet-section";
import vehiclerentalgrahamstown from "./pages/location-based-pages/vehicle-rental-grahamstown-section";
import vehiclerentalhermanus from "./pages/location-based-pages/vehicle-rental-hermanus-section";
import vehiclerentaljeffreysbay from "./pages/location-based-pages/vehicle-rental-jeffreys-bay-section";
import vehiclerentaljohannesburg from "./pages/location-based-pages/vehicle-rental-johannesburg-section";
import vehiclerentalkimberley from "./pages/location-based-pages/vehicle-rental-kimberley-section";
import vehiclerentalklerksdorp from "./pages/location-based-pages/vehicle-rental-klerksdorp-section";
import vehiclerentalknysna from "./pages/location-based-pages/vehicle-rental-knysna-section";
import vehiclerentalkroonstad from "./pages/location-based-pages/vehicle-rental-kroonstad-section";
import vehiclerentalkuilsrivier from "./pages/location-based-pages/vehicle-rental-kuilsrivier-section";
import vehiclerentalladysmith from "./pages/location-based-pages/vehicle-rental-ladysmith-section";
import vehiclerentallebowakgomo from "./pages/location-based-pages/vehicle-rental-lebowakgomo-section";
import vehiclerentallichtenburg from "./pages/location-based-pages/vehicle-rental-lichtenburg-section";
import vehiclerentalmahikeng from "./pages/location-based-pages/vehicle-rental-mahikeng-section";
import vehiclerentalmalmesbury from "./pages/location-based-pages/vehicle-rental-malmesbury-section";
import vehiclerentalmiddelburg from "./pages/location-based-pages/vehicle-rental-middelburg-section";
import vehiclerentalmidrand from "./pages/location-based-pages/vehicle-rental-midrand-section";
import vehiclerentalmmabatho from "./pages/location-based-pages/vehicle-rental-mmabatho-section";
import vehiclerentalmosselbay from "./pages/location-based-pages/vehicle-rental-mossel-bay-section";
import vehiclerentalmusina from "./pages/location-based-pages/vehicle-rental-musina-section";
import vehiclerentalnelspruit from "./pages/location-based-pages/vehicle-rental-nelspruit-section";
import vehiclerentaloudtshoorn from "./pages/location-based-pages/vehicle-rental-oudtshoorn-section";
import vehiclerentalpaarl from "./pages/location-based-pages/vehicle-rental-paarl-section";
import vehiclerentalpietermaritzburg from "./pages/location-based-pages/vehicle-rental-pietermaritzburg-section";
import vehiclerentalpolokwane from "./pages/location-based-pages/vehicle-rental-polokwane-section";
import vehiclerentalportelizabeth from "./pages/location-based-pages/vehicle-rental-port-elizabeth-section";
import vehiclerentalportshepstone from "./pages/location-based-pages/vehicle-rental-port-shepstone-section";
import vehiclerentalpotchefstroom from "./pages/location-based-pages/vehicle-rental-potchefstroom-section";
import vehiclerentalpretoria from "./pages/location-based-pages/vehicle-rental-pretoria-section";
import vehiclerentalqueenstown from "./pages/location-based-pages/vehicle-rental-queenstown-section";
import vehiclerentalrandburg from "./pages/location-based-pages/vehicle-rental-randburg-section";
import vehiclerentalroodepoort from "./pages/location-based-pages/vehicle-rental-roodepoort-section";
import vehiclerentalrustenburg from "./pages/location-based-pages/vehicle-rental-rustenburg-section";
import vehiclerentalsaldanha from "./pages/location-based-pages/vehicle-rental-saldanha-section";
import vehiclerentalsandton from "./pages/location-based-pages/vehicle-rental-sandton-section";
import vehiclerentalsoweto from "./pages/location-based-pages/vehicle-rental-soweto-section";
import vehiclerentalsprings from "./pages/location-based-pages/vehicle-rental-springs-section";
import vehiclerentalstanderton from "./pages/location-based-pages/vehicle-rental-standerton-section";
import vehiclerentalthohoyandou from "./pages/location-based-pages/vehicle-rental-thohoyandou-section";
import vehiclerentaltzaneen from "./pages/location-based-pages/vehicle-rental-tzaneen-section";
import vehiclerentaluitenhage from "./pages/location-based-pages/vehicle-rental-uitenhage-section";
import vehiclerentalulundi from "./pages/location-based-pages/vehicle-rental-ulundi-section";
import vehiclerentalumtata from "./pages/location-based-pages/vehicle-rental-umtata-section";
import vehiclerentalupington from "./pages/location-based-pages/vehicle-rental-upington-section";
import vehiclerentalvanderbijlpark from "./pages/location-based-pages/vehicle-rental-vanderbijlpark-section";
import vehiclerentalvereeniging from "./pages/location-based-pages/vehicle-rental-vereeniging-section";
import vehiclerentalvolksrust from "./pages/location-based-pages/vehicle-rental-volksrust-section";
import vehiclerentalvredenburg from "./pages/location-based-pages/vehicle-rental-vredenburg-section";
import vehiclerentalvryburg from "./pages/location-based-pages/vehicle-rental-vryburg-section";
import vehiclerentalvryheid from "./pages/location-based-pages/vehicle-rental-vryheid-section";
import vehiclerentalwelkom from "./pages/location-based-pages/vehicle-rental-welkom-section";
import vehiclerentalworcester from "./pages/location-based-pages/vehicle-rental-worcester-section";

const routes = [
  {
    path: "/regular-deliveries-johannesburg",
    component: regularDeliveriesJohannesburg,
  },

  { path: "/1-ton-alberton", component: ton1alberton },
  { path: "/1-ton-aliwal-north", component: ton1aliwalnorth },
  { path: "/1-ton-beaufort-west", component: ton1beaufortwest },
  { path: "/1-ton-benoni", component: ton1benoni },
  { path: "/1-ton-bethal", component: ton1bethal },
  { path: "/1-ton-bhisho", component: ton1bhisho },
  { path: "/1-ton-bloemfontein", component: ton1bloemfontein },
  { path: "/1-ton-brakpan", component: ton1brakpan },
  { path: "/1-ton-brits", component: ton1brits },
  { path: "/1-ton-cape-town", component: ton1capetown },
  { path: "/1-ton-carletonville", component: ton1carletonville },
  { path: "/1-ton-centurion", component: ton1centurion },
  { path: "/1-ton-cradock", component: ton1cradock },
  { path: "/1-ton-de-aar", component: ton1deaar },
  { path: "/1-ton-durban", component: ton1durban },
  { path: "/1-ton-east-london", component: ton1eastlondon },
  { path: "/1-ton-ferndale", component: ton1ferndale },
  { path: "/1-ton-george", component: ton1george },
  { path: "/1-ton-graaff-reinet", component: ton1graaffreinet },
  { path: "/1-ton-grahamstown", component: ton1grahamstown },
  { path: "/1-ton-hermanus", component: ton1hermanus },
  { path: "/1-ton-jeffreys-bay", component: ton1jeffreysbay },
  { path: "/1-ton-johannesburg", component: ton1johannesburg },
  { path: "/1-ton-kimberley", component: ton1kimberley },
  { path: "/1-ton-klerksdorp", component: ton1klerksdorp },
  { path: "/1-ton-knysna", component: ton1knysna },
  { path: "/1-ton-kroonstad", component: ton1kroonstad },
  { path: "/1-ton-kuilsrivier", component: ton1kuilsrivier },
  { path: "/1-ton-ladysmith", component: ton1ladysmith },
  { path: "/1-ton-lebowakgomo", component: ton1lebowakgomo },
  { path: "/1-ton-lichtenburg", component: ton1lichtenburg },
  { path: "/1-ton-mahikeng", component: ton1mahikeng },
  { path: "/1-ton-malmesbury", component: ton1malmesbury },
  { path: "/1-ton-middelburg", component: ton1middelburg },
  { path: "/1-ton-midrand", component: ton1midrand },
  { path: "/1-ton-mmabatho", component: ton1mmabatho },
  { path: "/1-ton-mossel-bay", component: ton1mosselbay },
  { path: "/1-ton-musina", component: ton1musina },
  { path: "/1-ton-nelspruit", component: ton1nelspruit },
  { path: "/1-ton-oudtshoorn", component: ton1oudtshoorn },
  { path: "/1-ton-paarl", component: ton1paarl },
  { path: "/1-ton-pietermaritzburg", component: ton1pietermaritzburg },
  { path: "/1-ton-polokwane", component: ton1polokwane },
  { path: "/1-ton-port-elizabeth", component: ton1portelizabeth },
  { path: "/1-ton-port-shepstone", component: ton1portshepstone },
  { path: "/1-ton-potchefstroom", component: ton1potchefstroom },
  { path: "/1-ton-pretoria", component: ton1pretoria },
  { path: "/1-ton-queenstown", component: ton1queenstown },
  { path: "/1-ton-randburg", component: ton1randburg },
  { path: "/1-ton-roodepoort", component: ton1roodepoort },
  { path: "/1-ton-rustenburg", component: ton1rustenburg },
  { path: "/1-ton-saldanha", component: ton1saldanha },
  { path: "/1-ton-sandton", component: ton1sandton },
  { path: "/1-ton-soweto", component: ton1soweto },
  { path: "/1-ton-springs", component: ton1springs },
  { path: "/1-ton-standerton", component: ton1standerton },
  { path: "/1-ton-thohoyandou", component: ton1thohoyandou },
  { path: "/1-ton-tzaneen", component: ton1tzaneen },
  { path: "/1-ton-uitenhage", component: ton1uitenhage },
  { path: "/1-ton-ulundi", component: ton1ulundi },
  { path: "/1-ton-umtata", component: ton1umtata },
  { path: "/1-ton-upington", component: ton1upington },
  { path: "/1-ton-vanderbijlpark", component: ton1vanderbijlpark },
  { path: "/1-ton-vereeniging", component: ton1vereeniging },
  { path: "/1-ton-volksrust", component: ton1volksrust },
  { path: "/1-ton-vredenburg", component: ton1vredenburg },
  { path: "/1-ton-vryburg", component: ton1vryburg },
  { path: "/1-ton-vryheid", component: ton1vryheid },
  { path: "/1-ton-welkom", component: ton1welkom },
  { path: "/1-ton-worcester", component: ton1worcester },
  { path: "/1.5-ton-alberton", component: ton15alberton },
  { path: "/1.5-ton-aliwal-north", component: ton15aliwalnorth },
  { path: "/1.5-ton-beaufort-west", component: ton15beaufortwest },
  { path: "/1.5-ton-benoni", component: ton15benoni },
  { path: "/1.5-ton-bethal", component: ton15bethal },
  { path: "/1.5-ton-bhisho", component: ton15bhisho },
  { path: "/1.5-ton-bloemfontein", component: ton15bloemfontein },
  { path: "/1.5-ton-brakpan", component: ton15brakpan },
  { path: "/1.5-ton-brits", component: ton15brits },
  { path: "/1.5-ton-cape-town", component: ton15capetown },
  { path: "/1.5-ton-carletonville", component: ton15carletonville },
  { path: "/1.5-ton-centurion", component: ton15centurion },
  { path: "/1.5-ton-cradock", component: ton15cradock },
  { path: "/1.5-ton-de-aar", component: ton15deaar },
  { path: "/1.5-ton-durban", component: ton15durban },
  { path: "/1.5-ton-east-london", component: ton15eastlondon },
  { path: "/1.5-ton-ferndale", component: ton15ferndale },
  { path: "/1.5-ton-george", component: ton15george },
  { path: "/1.5-ton-graaff-reinet", component: ton15graaffreinet },
  { path: "/1.5-ton-grahamstown", component: ton15grahamstown },
  { path: "/1.5-ton-hermanus", component: ton15hermanus },
  { path: "/1.5-ton-jeffreys-bay", component: ton15jeffreysbay },
  { path: "/1.5-ton-johannesburg", component: ton15johannesburg },
  { path: "/1.5-ton-kimberley", component: ton15kimberley },
  { path: "/1.5-ton-klerksdorp", component: ton15klerksdorp },
  { path: "/1.5-ton-knysna", component: ton15knysna },
  { path: "/1.5-ton-kroonstad", component: ton15kroonstad },
  { path: "/1.5-ton-kuilsrivier", component: ton15kuilsrivier },
  { path: "/1.5-ton-ladysmith", component: ton15ladysmith },
  { path: "/1.5-ton-lebowakgomo", component: ton15lebowakgomo },
  { path: "/1.5-ton-lichtenburg", component: ton15lichtenburg },
  { path: "/1.5-ton-mahikeng", component: ton15mahikeng },
  { path: "/1.5-ton-malmesbury", component: ton15malmesbury },
  { path: "/1.5-ton-middelburg", component: ton15middelburg },
  { path: "/1.5-ton-midrand", component: ton15midrand },
  { path: "/1.5-ton-mmabatho", component: ton15mmabatho },
  { path: "/1.5-ton-mossel-bay", component: ton15mosselbay },
  { path: "/1.5-ton-musina", component: ton15musina },
  { path: "/1.5-ton-nelspruit", component: ton15nelspruit },
  { path: "/1.5-ton-oudtshoorn", component: ton15oudtshoorn },
  { path: "/1.5-ton-paarl", component: ton15paarl },
  { path: "/1.5-ton-pietermaritzburg", component: ton15pietermaritzburg },
  { path: "/1.5-ton-polokwane", component: ton15polokwane },
  { path: "/1.5-ton-port-elizabeth", component: ton15portelizabeth },
  { path: "/1.5-ton-port-shepstone", component: ton15portshepstone },
  { path: "/1.5-ton-potchefstroom", component: ton15potchefstroom },
  { path: "/1.5-ton-pretoria", component: ton15pretoria },
  { path: "/1.5-ton-queenstown", component: ton15queenstown },
  { path: "/1.5-ton-randburg", component: ton15randburg },
  { path: "/1.5-ton-roodepoort", component: ton15roodepoort },
  { path: "/1.5-ton-rustenburg", component: ton15rustenburg },
  { path: "/1.5-ton-saldanha", component: ton15saldanha },
  { path: "/1.5-ton-sandton", component: ton15sandton },
  { path: "/1.5-ton-soweto", component: ton15soweto },
  { path: "/1.5-ton-springs", component: ton15springs },
  { path: "/1.5-ton-standerton", component: ton15standerton },
  { path: "/1.5-ton-thohoyandou", component: ton15thohoyandou },
  { path: "/1.5-ton-tzaneen", component: ton15tzaneen },
  { path: "/1.5-ton-uitenhage", component: ton15uitenhage },
  { path: "/1.5-ton-ulundi", component: ton15ulundi },
  { path: "/1.5-ton-umtata", component: ton15umtata },
  { path: "/1.5-ton-upington", component: ton15upington },
  { path: "/1.5-ton-vanderbijlpark", component: ton15vanderbijlpark },
  { path: "/1.5-ton-vereeniging", component: ton15vereeniging },
  { path: "/1.5-ton-volksrust", component: ton15volksrust },
  { path: "/1.5-ton-vredenburg", component: ton15vredenburg },
  { path: "/1.5-ton-vryburg", component: ton15vryburg },
  { path: "/1.5-ton-vryheid", component: ton15vryheid },
  { path: "/1.5-ton-welkom", component: ton15welkom },
  { path: "/1.5-ton-worcester", component: ton15worcester },
  { path: "/4-ton-alberton", component: ton4alberton },
  { path: "/4-ton-aliwal-north", component: ton4aliwalnorth },
  { path: "/4-ton-beaufort-west", component: ton4beaufortwest },
  { path: "/4-ton-benoni", component: ton4benoni },
  { path: "/4-ton-bethal", component: ton4bethal },
  { path: "/4-ton-bhisho", component: ton4bhisho },
  { path: "/4-ton-bloemfontein", component: ton4bloemfontein },
  { path: "/4-ton-brakpan", component: ton4brakpan },
  { path: "/4-ton-brits", component: ton4brits },
  { path: "/4-ton-cape-town", component: ton4capetown },
  { path: "/4-ton-carletonville", component: ton4carletonville },
  { path: "/4-ton-centurion", component: ton4centurion },
  { path: "/4-ton-cradock", component: ton4cradock },
  { path: "/4-ton-de-aar", component: ton4deaar },
  { path: "/4-ton-durban", component: ton4durban },
  { path: "/4-ton-east-london", component: ton4eastlondon },
  { path: "/4-ton-ferndale", component: ton4ferndale },
  { path: "/4-ton-george", component: ton4george },
  { path: "/4-ton-graaff-reinet", component: ton4graaffreinet },
  { path: "/4-ton-grahamstown", component: ton4grahamstown },
  { path: "/4-ton-hermanus", component: ton4hermanus },
  { path: "/4-ton-jeffreys-bay", component: ton4jeffreysbay },
  { path: "/4-ton-johannesburg", component: ton4johannesburg },
  { path: "/4-ton-kimberley", component: ton4kimberley },
  { path: "/4-ton-klerksdorp", component: ton4klerksdorp },
  { path: "/4-ton-knysna", component: ton4knysna },
  { path: "/4-ton-kroonstad", component: ton4kroonstad },
  { path: "/4-ton-kuilsrivier", component: ton4kuilsrivier },
  { path: "/4-ton-ladysmith", component: ton4ladysmith },
  { path: "/4-ton-lebowakgomo", component: ton4lebowakgomo },
  { path: "/4-ton-lichtenburg", component: ton4lichtenburg },
  { path: "/4-ton-mahikeng", component: ton4mahikeng },
  { path: "/4-ton-malmesbury", component: ton4malmesbury },
  { path: "/4-ton-middelburg", component: ton4middelburg },
  { path: "/4-ton-midrand", component: ton4midrand },
  { path: "/4-ton-mmabatho", component: ton4mmabatho },
  { path: "/4-ton-mossel-bay", component: ton4mosselbay },
  { path: "/4-ton-musina", component: ton4musina },
  { path: "/4-ton-nelspruit", component: ton4nelspruit },
  { path: "/4-ton-oudtshoorn", component: ton4oudtshoorn },
  { path: "/4-ton-paarl", component: ton4paarl },
  { path: "/4-ton-pietermaritzburg", component: ton4pietermaritzburg },
  { path: "/4-ton-polokwane", component: ton4polokwane },
  { path: "/4-ton-port-elizabeth", component: ton4portelizabeth },
  { path: "/4-ton-port-shepstone", component: ton4portshepstone },
  { path: "/4-ton-potchefstroom", component: ton4potchefstroom },
  { path: "/4-ton-pretoria", component: ton4pretoria },
  { path: "/4-ton-queenstown", component: ton4queenstown },
  { path: "/4-ton-randburg", component: ton4randburg },
  { path: "/4-ton-roodepoort", component: ton4roodepoort },
  { path: "/4-ton-rustenburg", component: ton4rustenburg },
  { path: "/4-ton-saldanha", component: ton4saldanha },
  { path: "/4-ton-sandton", component: ton4sandton },
  { path: "/4-ton-soweto", component: ton4soweto },
  { path: "/4-ton-springs", component: ton4springs },
  { path: "/4-ton-standerton", component: ton4standerton },
  { path: "/4-ton-thohoyandou", component: ton4thohoyandou },
  { path: "/4-ton-tzaneen", component: ton4tzaneen },
  { path: "/4-ton-uitenhage", component: ton4uitenhage },
  { path: "/4-ton-ulundi", component: ton4ulundi },
  { path: "/4-ton-umtata", component: ton4umtata },
  { path: "/4-ton-upington", component: ton4upington },
  { path: "/4-ton-vanderbijlpark", component: ton4vanderbijlpark },
  { path: "/4-ton-vereeniging", component: ton4vereeniging },
  { path: "/4-ton-volksrust", component: ton4volksrust },
  { path: "/4-ton-vredenburg", component: ton4vredenburg },
  { path: "/4-ton-vryburg", component: ton4vryburg },
  { path: "/4-ton-vryheid", component: ton4vryheid },
  { path: "/4-ton-welkom", component: ton4welkom },
  { path: "/4-ton-worcester", component: ton4worcester },
  { path: "/8-ton-alberton", component: ton8alberton },
  { path: "/8-ton-aliwal-north", component: ton8aliwalnorth },
  { path: "/8-ton-beaufort-west", component: ton8beaufortwest },
  { path: "/8-ton-benoni", component: ton8benoni },
  { path: "/8-ton-bethal", component: ton8bethal },
  { path: "/8-ton-bhisho", component: ton8bhisho },
  { path: "/8-ton-bloemfontein", component: ton8bloemfontein },
  { path: "/8-ton-brakpan", component: ton8brakpan },
  { path: "/8-ton-brits", component: ton8brits },
  { path: "/8-ton-cape-town", component: ton8capetown },
  { path: "/8-ton-carletonville", component: ton8carletonville },
  { path: "/8-ton-centurion", component: ton8centurion },
  { path: "/8-ton-cradock", component: ton8cradock },
  { path: "/8-ton-de-aar", component: ton8deaar },
  { path: "/8-ton-durban", component: ton8durban },
  { path: "/8-ton-east-london", component: ton8eastlondon },
  { path: "/8-ton-ferndale", component: ton8ferndale },
  { path: "/8-ton-george", component: ton8george },
  { path: "/8-ton-graaff-reinet", component: ton8graaffreinet },
  { path: "/8-ton-grahamstown", component: ton8grahamstown },
  { path: "/8-ton-hermanus", component: ton8hermanus },
  { path: "/8-ton-jeffreys-bay", component: ton8jeffreysbay },
  { path: "/8-ton-johannesburg", component: ton8johannesburg },
  { path: "/8-ton-kimberley", component: ton8kimberley },
  { path: "/8-ton-klerksdorp", component: ton8klerksdorp },
  { path: "/8-ton-knysna", component: ton8knysna },
  { path: "/8-ton-kroonstad", component: ton8kroonstad },
  { path: "/8-ton-kuilsrivier", component: ton8kuilsrivier },
  { path: "/8-ton-ladysmith", component: ton8ladysmith },
  { path: "/8-ton-lebowakgomo", component: ton8lebowakgomo },
  { path: "/8-ton-lichtenburg", component: ton8lichtenburg },
  { path: "/8-ton-mahikeng", component: ton8mahikeng },
  { path: "/8-ton-malmesbury", component: ton8malmesbury },
  { path: "/8-ton-middelburg", component: ton8middelburg },
  { path: "/8-ton-midrand", component: ton8midrand },
  { path: "/8-ton-mmabatho", component: ton8mmabatho },
  { path: "/8-ton-mossel-bay", component: ton8mosselbay },
  { path: "/8-ton-musina", component: ton8musina },
  { path: "/8-ton-nelspruit", component: ton8nelspruit },
  { path: "/8-ton-oudtshoorn", component: ton8oudtshoorn },
  { path: "/8-ton-paarl", component: ton8paarl },
  { path: "/8-ton-pietermaritzburg", component: ton8pietermaritzburg },
  { path: "/8-ton-polokwane", component: ton8polokwane },
  { path: "/8-ton-port-elizabeth", component: ton8portelizabeth },
  { path: "/8-ton-port-shepstone", component: ton8portshepstone },
  { path: "/8-ton-potchefstroom", component: ton8potchefstroom },
  { path: "/8-ton-pretoria", component: ton8pretoria },
  { path: "/8-ton-queenstown", component: ton8queenstown },
  { path: "/8-ton-randburg", component: ton8randburg },
  { path: "/8-ton-roodepoort", component: ton8roodepoort },
  { path: "/8-ton-rustenburg", component: ton8rustenburg },
  { path: "/8-ton-saldanha", component: ton8saldanha },
  { path: "/8-ton-sandton", component: ton8sandton },
  { path: "/8-ton-soweto", component: ton8soweto },
  { path: "/8-ton-springs", component: ton8springs },
  { path: "/8-ton-standerton", component: ton8standerton },
  { path: "/8-ton-thohoyandou", component: ton8thohoyandou },
  { path: "/8-ton-tzaneen", component: ton8tzaneen },
  { path: "/8-ton-uitenhage", component: ton8uitenhage },
  { path: "/8-ton-ulundi", component: ton8ulundi },
  { path: "/8-ton-umtata", component: ton8umtata },
  { path: "/8-ton-upington", component: ton8upington },
  { path: "/8-ton-vanderbijlpark", component: ton8vanderbijlpark },
  { path: "/8-ton-vereeniging", component: ton8vereeniging },
  { path: "/8-ton-volksrust", component: ton8volksrust },
  { path: "/8-ton-vredenburg", component: ton8vredenburg },
  { path: "/8-ton-vryburg", component: ton8vryburg },
  { path: "/8-ton-vryheid", component: ton8vryheid },
  { path: "/8-ton-welkom", component: ton8welkom },
  { path: "/8-ton-worcester", component: ton8worcester },
  { path: "/bakkie-hire--alberton", component: bakkiehirealberton },
  { path: "/bakkie-hire--aliwal-north", component: bakkiehirealiwalnorth },
  { path: "/bakkie-hire--beaufort-west", component: bakkiehirebeaufortwest },
  { path: "/bakkie-hire--benoni", component: bakkiehirebenoni },
  { path: "/bakkie-hire--bethal", component: bakkiehirebethal },
  { path: "/bakkie-hire--bhisho", component: bakkiehirebhisho },
  { path: "/bakkie-hire--bloemfontein", component: bakkiehirebloemfontein },
  { path: "/bakkie-hire--brakpan", component: bakkiehirebrakpan },
  { path: "/bakkie-hire--brits", component: bakkiehirebrits },
  { path: "/bakkie-hire--cape-town", component: bakkiehirecapetown },
  { path: "/bakkie-hire--carletonville", component: bakkiehirecarletonville },
  { path: "/bakkie-hire--centurion", component: bakkiehirecenturion },
  { path: "/bakkie-hire--cradock", component: bakkiehirecradock },
  { path: "/bakkie-hire--de-aar", component: bakkiehiredeaar },
  { path: "/bakkie-hire--durban", component: bakkiehiredurban },
  { path: "/bakkie-hire--east-london", component: bakkiehireeastlondon },
  { path: "/bakkie-hire--ferndale", component: bakkiehireferndale },
  { path: "/bakkie-hire--george", component: bakkiehiregeorge },
  { path: "/bakkie-hire--graaff-reinet", component: bakkiehiregraaffreinet },
  { path: "/bakkie-hire--grahamstown", component: bakkiehiregrahamstown },
  { path: "/bakkie-hire--hermanus", component: bakkiehirehermanus },
  { path: "/bakkie-hire--jeffreys-bay", component: bakkiehirejeffreysbay },
  { path: "/bakkie-hire--johannesburg", component: bakkiehirejohannesburg },
  { path: "/bakkie-hire--kimberley", component: bakkiehirekimberley },
  { path: "/bakkie-hire--klerksdorp", component: bakkiehireklerksdorp },
  { path: "/bakkie-hire--knysna", component: bakkiehireknysna },
  { path: "/bakkie-hire--kroonstad", component: bakkiehirekroonstad },
  { path: "/bakkie-hire--kuilsrivier", component: bakkiehirekuilsrivier },
  { path: "/bakkie-hire--ladysmith", component: bakkiehireladysmith },
  { path: "/bakkie-hire--lebowakgomo", component: bakkiehirelebowakgomo },
  { path: "/bakkie-hire--lichtenburg", component: bakkiehirelichtenburg },
  { path: "/bakkie-hire--mahikeng", component: bakkiehiremahikeng },
  { path: "/bakkie-hire--malmesbury", component: bakkiehiremalmesbury },
  { path: "/bakkie-hire--middelburg", component: bakkiehiremiddelburg },
  { path: "/bakkie-hire--midrand", component: bakkiehiremidrand },
  { path: "/bakkie-hire--mmabatho", component: bakkiehiremmabatho },
  { path: "/bakkie-hire--mossel-bay", component: bakkiehiremosselbay },
  { path: "/bakkie-hire--musina", component: bakkiehiremusina },
  { path: "/bakkie-hire--nelspruit", component: bakkiehirenelspruit },
  { path: "/bakkie-hire--oudtshoorn", component: bakkiehireoudtshoorn },
  { path: "/bakkie-hire--paarl", component: bakkiehirepaarl },
  {
    path: "/bakkie-hire--pietermaritzburg",
    component: bakkiehirepietermaritzburg,
  },
  { path: "/bakkie-hire--polokwane", component: bakkiehirepolokwane },
  { path: "/bakkie-hire--port-elizabeth", component: bakkiehireportelizabeth },
  { path: "/bakkie-hire--port-shepstone", component: bakkiehireportshepstone },
  { path: "/bakkie-hire--potchefstroom", component: bakkiehirepotchefstroom },
  { path: "/bakkie-hire--pretoria", component: bakkiehirepretoria },
  { path: "/bakkie-hire--queenstown", component: bakkiehirequeenstown },
  { path: "/bakkie-hire--randburg", component: bakkiehirerandburg },
  { path: "/bakkie-hire--roodepoort", component: bakkiehireroodepoort },
  { path: "/bakkie-hire--rustenburg", component: bakkiehirerustenburg },
  { path: "/bakkie-hire--saldanha", component: bakkiehiresaldanha },
  { path: "/bakkie-hire--sandton", component: bakkiehiresandton },
  { path: "/bakkie-hire--soweto", component: bakkiehiresoweto },
  { path: "/bakkie-hire--springs", component: bakkiehiresprings },
  { path: "/bakkie-hire--standerton", component: bakkiehirestanderton },
  { path: "/bakkie-hire--thohoyandou", component: bakkiehirethohoyandou },
  { path: "/bakkie-hire--tzaneen", component: bakkiehiretzaneen },
  { path: "/bakkie-hire--uitenhage", component: bakkiehireuitenhage },
  { path: "/bakkie-hire--ulundi", component: bakkiehireulundi },
  { path: "/bakkie-hire--umtata", component: bakkiehireumtata },
  { path: "/bakkie-hire--upington", component: bakkiehireupington },
  { path: "/bakkie-hire--vanderbijlpark", component: bakkiehirevanderbijlpark },
  { path: "/bakkie-hire--vereeniging", component: bakkiehirevereeniging },
  { path: "/bakkie-hire--volksrust", component: bakkiehirevolksrust },
  { path: "/bakkie-hire--vredenburg", component: bakkiehirevredenburg },
  { path: "/bakkie-hire--vryburg", component: bakkiehirevryburg },
  { path: "/bakkie-hire--vryheid", component: bakkiehirevryheid },
  { path: "/bakkie-hire--welkom", component: bakkiehirewelkom },
  { path: "/bakkie-hire--worcester", component: bakkiehireworcester },
  { path: "/business-delivery-alberton", component: businessdeliveryalberton },
  {
    path: "/business-delivery-aliwal-north",
    component: businessdeliveryaliwalnorth,
  },
  {
    path: "/business-delivery-beaufort-west",
    component: businessdeliverybeaufortwest,
  },
  { path: "/business-delivery-benoni", component: businessdeliverybenoni },
  { path: "/business-delivery-bethal", component: businessdeliverybethal },
  { path: "/business-delivery-bhisho", component: businessdeliverybhisho },
  {
    path: "/business-delivery-bloemfontein",
    component: businessdeliverybloemfontein,
  },
  { path: "/business-delivery-brakpan", component: businessdeliverybrakpan },
  { path: "/business-delivery-brits", component: businessdeliverybrits },
  { path: "/business-delivery-cape-town", component: businessdeliverycapetown },
  {
    path: "/business-delivery-carletonville",
    component: businessdeliverycarletonville,
  },
  {
    path: "/business-delivery-centurion",
    component: businessdeliverycenturion,
  },
  { path: "/business-delivery-cradock", component: businessdeliverycradock },
  { path: "/business-delivery-de-aar", component: businessdeliverydeaar },
  { path: "/business-delivery-durban", component: businessdeliverydurban },
  {
    path: "/business-delivery-east-london",
    component: businessdeliveryeastlondon,
  },
  { path: "/business-delivery-ferndale", component: businessdeliveryferndale },
  { path: "/business-delivery-george", component: businessdeliverygeorge },
  {
    path: "/business-delivery-graaff-reinet",
    component: businessdeliverygraaffreinet,
  },
  {
    path: "/business-delivery-grahamstown",
    component: businessdeliverygrahamstown,
  },
  { path: "/business-delivery-hermanus", component: businessdeliveryhermanus },
  {
    path: "/business-delivery-jeffreys-bay",
    component: businessdeliveryjeffreysbay,
  },
  {
    path: "/business-delivery-johannesburg",
    component: businessdeliveryjohannesburg,
  },
  {
    path: "/business-delivery-kimberley",
    component: businessdeliverykimberley,
  },
  {
    path: "/business-delivery-klerksdorp",
    component: businessdeliveryklerksdorp,
  },
  { path: "/business-delivery-knysna", component: businessdeliveryknysna },
  {
    path: "/business-delivery-kroonstad",
    component: businessdeliverykroonstad,
  },
  {
    path: "/business-delivery-kuilsrivier",
    component: businessdeliverykuilsrivier,
  },
  {
    path: "/business-delivery-ladysmith",
    component: businessdeliveryladysmith,
  },
  {
    path: "/business-delivery-lebowakgomo",
    component: businessdeliverylebowakgomo,
  },
  {
    path: "/business-delivery-lichtenburg",
    component: businessdeliverylichtenburg,
  },
  { path: "/business-delivery-mahikeng", component: businessdeliverymahikeng },
  {
    path: "/business-delivery-malmesbury",
    component: businessdeliverymalmesbury,
  },
  {
    path: "/business-delivery-middelburg",
    component: businessdeliverymiddelburg,
  },
  { path: "/business-delivery-midrand", component: businessdeliverymidrand },
  { path: "/business-delivery-mmabatho", component: businessdeliverymmabatho },
  {
    path: "/business-delivery-mossel-bay",
    component: businessdeliverymosselbay,
  },
  { path: "/business-delivery-musina", component: businessdeliverymusina },
  {
    path: "/business-delivery-nelspruit",
    component: businessdeliverynelspruit,
  },
  {
    path: "/business-delivery-oudtshoorn",
    component: businessdeliveryoudtshoorn,
  },
  { path: "/business-delivery-paarl", component: businessdeliverypaarl },
  {
    path: "/business-delivery-pietermaritzburg",
    component: businessdeliverypietermaritzburg,
  },
  {
    path: "/business-delivery-polokwane",
    component: businessdeliverypolokwane,
  },
  {
    path: "/business-delivery-port-elizabeth",
    component: businessdeliveryportelizabeth,
  },
  {
    path: "/business-delivery-port-shepstone",
    component: businessdeliveryportshepstone,
  },
  {
    path: "/business-delivery-potchefstroom",
    component: businessdeliverypotchefstroom,
  },
  { path: "/business-delivery-pretoria", component: businessdeliverypretoria },
  {
    path: "/business-delivery-queenstown",
    component: businessdeliveryqueenstown,
  },
  { path: "/business-delivery-randburg", component: businessdeliveryrandburg },
  {
    path: "/business-delivery-roodepoort",
    component: businessdeliveryroodepoort,
  },
  {
    path: "/business-delivery-rustenburg",
    component: businessdeliveryrustenburg,
  },
  { path: "/business-delivery-saldanha", component: businessdeliverysaldanha },
  { path: "/business-delivery-sandton", component: businessdeliverysandton },
  { path: "/business-delivery-soweto", component: businessdeliverysoweto },
  { path: "/business-delivery-springs", component: businessdeliverysprings },
  {
    path: "/business-delivery-standerton",
    component: businessdeliverystanderton,
  },
  {
    path: "/business-delivery-thohoyandou",
    component: businessdeliverythohoyandou,
  },
  { path: "/business-delivery-tzaneen", component: businessdeliverytzaneen },
  {
    path: "/business-delivery-uitenhage",
    component: businessdeliveryuitenhage,
  },
  { path: "/business-delivery-ulundi", component: businessdeliveryulundi },
  { path: "/business-delivery-umtata", component: businessdeliveryumtata },
  { path: "/business-delivery-upington", component: businessdeliveryupington },
  {
    path: "/business-delivery-vanderbijlpark",
    component: businessdeliveryvanderbijlpark,
  },
  {
    path: "/business-delivery-vereeniging",
    component: businessdeliveryvereeniging,
  },
  {
    path: "/business-delivery-volksrust",
    component: businessdeliveryvolksrust,
  },
  {
    path: "/business-delivery-vredenburg",
    component: businessdeliveryvredenburg,
  },
  { path: "/business-delivery-vryburg", component: businessdeliveryvryburg },
  { path: "/business-delivery-vryheid", component: businessdeliveryvryheid },
  { path: "/business-delivery-welkom", component: businessdeliverywelkom },
  {
    path: "/business-delivery-worcester",
    component: businessdeliveryworcester,
  },
  {
    path: "/cheap-movers-near-me-alberton",
    component: cheapmoversnearmealberton,
  },
  {
    path: "/cheap-movers-near-me-aliwal-north",
    component: cheapmoversnearmealiwalnorth,
  },
  {
    path: "/cheap-movers-near-me-beaufort-west",
    component: cheapmoversnearmebeaufortwest,
  },
  { path: "/cheap-movers-near-me-benoni", component: cheapmoversnearmebenoni },
  { path: "/cheap-movers-near-me-bethal", component: cheapmoversnearmebethal },
  { path: "/cheap-movers-near-me-bhisho", component: cheapmoversnearmebhisho },
  {
    path: "/cheap-movers-near-me-bloemfontein",
    component: cheapmoversnearmebloemfontein,
  },
  {
    path: "/cheap-movers-near-me-brakpan",
    component: cheapmoversnearmebrakpan,
  },
  { path: "/cheap-movers-near-me-brits", component: cheapmoversnearmebrits },
  {
    path: "/cheap-movers-near-me-cape-town",
    component: cheapmoversnearmecapetown,
  },
  {
    path: "/cheap-movers-near-me-carletonville",
    component: cheapmoversnearmecarletonville,
  },
  {
    path: "/cheap-movers-near-me-centurion",
    component: cheapmoversnearmecenturion,
  },
  {
    path: "/cheap-movers-near-me-cradock",
    component: cheapmoversnearmecradock,
  },
  { path: "/cheap-movers-near-me-de-aar", component: cheapmoversnearmedeaar },
  { path: "/cheap-movers-near-me-durban", component: cheapmoversnearmedurban },
  {
    path: "/cheap-movers-near-me-east-london",
    component: cheapmoversnearmeeastlondon,
  },
  {
    path: "/cheap-movers-near-me-ferndale",
    component: cheapmoversnearmeferndale,
  },
  { path: "/cheap-movers-near-me-george", component: cheapmoversnearmegeorge },
  {
    path: "/cheap-movers-near-me-graaff-reinet",
    component: cheapmoversnearmegraaffreinet,
  },
  {
    path: "/cheap-movers-near-me-grahamstown",
    component: cheapmoversnearmegrahamstown,
  },
  {
    path: "/cheap-movers-near-me-hermanus",
    component: cheapmoversnearmehermanus,
  },
  {
    path: "/cheap-movers-near-me-jeffreys-bay",
    component: cheapmoversnearmejeffreysbay,
  },
  {
    path: "/cheap-movers-near-me-johannesburg",
    component: cheapmoversnearmejohannesburg,
  },
  {
    path: "/cheap-movers-near-me-kimberley",
    component: cheapmoversnearmekimberley,
  },
  {
    path: "/cheap-movers-near-me-klerksdorp",
    component: cheapmoversnearmeklerksdorp,
  },
  { path: "/cheap-movers-near-me-knysna", component: cheapmoversnearmeknysna },
  {
    path: "/cheap-movers-near-me-kroonstad",
    component: cheapmoversnearmekroonstad,
  },
  {
    path: "/cheap-movers-near-me-kuilsrivier",
    component: cheapmoversnearmekuilsrivier,
  },
  {
    path: "/cheap-movers-near-me-ladysmith",
    component: cheapmoversnearmeladysmith,
  },
  {
    path: "/cheap-movers-near-me-lebowakgomo",
    component: cheapmoversnearmelebowakgomo,
  },
  {
    path: "/cheap-movers-near-me-lichtenburg",
    component: cheapmoversnearmelichtenburg,
  },
  {
    path: "/cheap-movers-near-me-mahikeng",
    component: cheapmoversnearmemahikeng,
  },
  {
    path: "/cheap-movers-near-me-malmesbury",
    component: cheapmoversnearmemalmesbury,
  },
  {
    path: "/cheap-movers-near-me-middelburg",
    component: cheapmoversnearmemiddelburg,
  },
  {
    path: "/cheap-movers-near-me-midrand",
    component: cheapmoversnearmemidrand,
  },
  {
    path: "/cheap-movers-near-me-mmabatho",
    component: cheapmoversnearmemmabatho,
  },
  {
    path: "/cheap-movers-near-me-mossel-bay",
    component: cheapmoversnearmemosselbay,
  },
  { path: "/cheap-movers-near-me-musina", component: cheapmoversnearmemusina },
  {
    path: "/cheap-movers-near-me-nelspruit",
    component: cheapmoversnearmenelspruit,
  },
  {
    path: "/cheap-movers-near-me-oudtshoorn",
    component: cheapmoversnearmeoudtshoorn,
  },
  { path: "/cheap-movers-near-me-paarl", component: cheapmoversnearmepaarl },
  {
    path: "/cheap-movers-near-me-pietermaritzburg",
    component: cheapmoversnearmepietermaritzburg,
  },
  {
    path: "/cheap-movers-near-me-polokwane",
    component: cheapmoversnearmepolokwane,
  },
  {
    path: "/cheap-movers-near-me-port-elizabeth",
    component: cheapmoversnearmeportelizabeth,
  },
  {
    path: "/cheap-movers-near-me-port-shepstone",
    component: cheapmoversnearmeportshepstone,
  },
  {
    path: "/cheap-movers-near-me-potchefstroom",
    component: cheapmoversnearmepotchefstroom,
  },
  {
    path: "/cheap-movers-near-me-pretoria",
    component: cheapmoversnearmepretoria,
  },
  {
    path: "/cheap-movers-near-me-queenstown",
    component: cheapmoversnearmequeenstown,
  },
  {
    path: "/cheap-movers-near-me-randburg",
    component: cheapmoversnearmerandburg,
  },
  {
    path: "/cheap-movers-near-me-roodepoort",
    component: cheapmoversnearmeroodepoort,
  },
  {
    path: "/cheap-movers-near-me-rustenburg",
    component: cheapmoversnearmerustenburg,
  },
  {
    path: "/cheap-movers-near-me-saldanha",
    component: cheapmoversnearmesaldanha,
  },
  {
    path: "/cheap-movers-near-me-sandton",
    component: cheapmoversnearmesandton,
  },
  { path: "/cheap-movers-near-me-soweto", component: cheapmoversnearmesoweto },
  {
    path: "/cheap-movers-near-me-springs",
    component: cheapmoversnearmesprings,
  },
  {
    path: "/cheap-movers-near-me-standerton",
    component: cheapmoversnearmestanderton,
  },
  {
    path: "/cheap-movers-near-me-thohoyandou",
    component: cheapmoversnearmethohoyandou,
  },
  {
    path: "/cheap-movers-near-me-tzaneen",
    component: cheapmoversnearmetzaneen,
  },
  {
    path: "/cheap-movers-near-me-uitenhage",
    component: cheapmoversnearmeuitenhage,
  },
  { path: "/cheap-movers-near-me-ulundi", component: cheapmoversnearmeulundi },
  { path: "/cheap-movers-near-me-umtata", component: cheapmoversnearmeumtata },
  {
    path: "/cheap-movers-near-me-upington",
    component: cheapmoversnearmeupington,
  },
  {
    path: "/cheap-movers-near-me-vanderbijlpark",
    component: cheapmoversnearmevanderbijlpark,
  },
  {
    path: "/cheap-movers-near-me-vereeniging",
    component: cheapmoversnearmevereeniging,
  },
  {
    path: "/cheap-movers-near-me-volksrust",
    component: cheapmoversnearmevolksrust,
  },
  {
    path: "/cheap-movers-near-me-vredenburg",
    component: cheapmoversnearmevredenburg,
  },
  {
    path: "/cheap-movers-near-me-vryburg",
    component: cheapmoversnearmevryburg,
  },
  {
    path: "/cheap-movers-near-me-vryheid",
    component: cheapmoversnearmevryheid,
  },
  { path: "/cheap-movers-near-me-welkom", component: cheapmoversnearmewelkom },
  {
    path: "/cheap-movers-near-me-worcester",
    component: cheapmoversnearmeworcester,
  },
  { path: "/cheap-removals-alberton", component: cheapremovalsalberton },
  { path: "/cheap-removals-aliwal-north", component: cheapremovalsaliwalnorth },
  {
    path: "/cheap-removals-beaufort-west",
    component: cheapremovalsbeaufortwest,
  },
  { path: "/cheap-removals-benoni", component: cheapremovalsbenoni },
  { path: "/cheap-removals-bethal", component: cheapremovalsbethal },
  { path: "/cheap-removals-bhisho", component: cheapremovalsbhisho },
  {
    path: "/cheap-removals-bloemfontein",
    component: cheapremovalsbloemfontein,
  },
  { path: "/cheap-removals-brakpan", component: cheapremovalsbrakpan },
  { path: "/cheap-removals-brits", component: cheapremovalsbrits },
  { path: "/cheap-removals-cape-town", component: cheapremovalscapetown },
  {
    path: "/cheap-removals-carletonville",
    component: cheapremovalscarletonville,
  },
  { path: "/cheap-removals-centurion", component: cheapremovalscenturion },
  { path: "/cheap-removals-cradock", component: cheapremovalscradock },
  { path: "/cheap-removals-de-aar", component: cheapremovalsdeaar },
  { path: "/cheap-removals-durban", component: cheapremovalsdurban },
  { path: "/cheap-removals-east-london", component: cheapremovalseastlondon },
  { path: "/cheap-removals-ferndale", component: cheapremovalsferndale },
  { path: "/cheap-removals-george", component: cheapremovalsgeorge },
  {
    path: "/cheap-removals-graaff-reinet",
    component: cheapremovalsgraaffreinet,
  },
  { path: "/cheap-removals-grahamstown", component: cheapremovalsgrahamstown },
  { path: "/cheap-removals-hermanus", component: cheapremovalshermanus },
  { path: "/cheap-removals-jeffreys-bay", component: cheapremovalsjeffreysbay },
  {
    path: "/cheap-removals-johannesburg",
    component: cheapremovalsjohannesburg,
  },
  { path: "/cheap-removals-kimberley", component: cheapremovalskimberley },
  { path: "/cheap-removals-klerksdorp", component: cheapremovalsklerksdorp },
  { path: "/cheap-removals-knysna", component: cheapremovalsknysna },
  { path: "/cheap-removals-kroonstad", component: cheapremovalskroonstad },
  { path: "/cheap-removals-kuilsrivier", component: cheapremovalskuilsrivier },
  { path: "/cheap-removals-ladysmith", component: cheapremovalsladysmith },
  { path: "/cheap-removals-lebowakgomo", component: cheapremovalslebowakgomo },
  { path: "/cheap-removals-lichtenburg", component: cheapremovalslichtenburg },
  { path: "/cheap-removals-mahikeng", component: cheapremovalsmahikeng },
  { path: "/cheap-removals-malmesbury", component: cheapremovalsmalmesbury },
  { path: "/cheap-removals-middelburg", component: cheapremovalsmiddelburg },
  { path: "/cheap-removals-midrand", component: cheapremovalsmidrand },
  { path: "/cheap-removals-mmabatho", component: cheapremovalsmmabatho },
  { path: "/cheap-removals-mossel-bay", component: cheapremovalsmosselbay },
  { path: "/cheap-removals-musina", component: cheapremovalsmusina },
  { path: "/cheap-removals-nelspruit", component: cheapremovalsnelspruit },
  { path: "/cheap-removals-oudtshoorn", component: cheapremovalsoudtshoorn },
  { path: "/cheap-removals-paarl", component: cheapremovalspaarl },
  {
    path: "/cheap-removals-pietermaritzburg",
    component: cheapremovalspietermaritzburg,
  },
  { path: "/cheap-removals-polokwane", component: cheapremovalspolokwane },
  {
    path: "/cheap-removals-port-elizabeth",
    component: cheapremovalsportelizabeth,
  },
  {
    path: "/cheap-removals-port-shepstone",
    component: cheapremovalsportshepstone,
  },
  {
    path: "/cheap-removals-potchefstroom",
    component: cheapremovalspotchefstroom,
  },
  { path: "/cheap-removals-pretoria", component: cheapremovalspretoria },
  { path: "/cheap-removals-queenstown", component: cheapremovalsqueenstown },
  { path: "/cheap-removals-randburg", component: cheapremovalsrandburg },
  { path: "/cheap-removals-roodepoort", component: cheapremovalsroodepoort },
  { path: "/cheap-removals-rustenburg", component: cheapremovalsrustenburg },
  { path: "/cheap-removals-saldanha", component: cheapremovalssaldanha },
  { path: "/cheap-removals-sandton", component: cheapremovalssandton },
  { path: "/cheap-removals-soweto", component: cheapremovalssoweto },
  { path: "/cheap-removals-springs", component: cheapremovalssprings },
  { path: "/cheap-removals-standerton", component: cheapremovalsstanderton },
  { path: "/cheap-removals-thohoyandou", component: cheapremovalsthohoyandou },
  { path: "/cheap-removals-tzaneen", component: cheapremovalstzaneen },
  { path: "/cheap-removals-uitenhage", component: cheapremovalsuitenhage },
  { path: "/cheap-removals-ulundi", component: cheapremovalsulundi },
  { path: "/cheap-removals-umtata", component: cheapremovalsumtata },
  { path: "/cheap-removals-upington", component: cheapremovalsupington },
  {
    path: "/cheap-removals-vanderbijlpark",
    component: cheapremovalsvanderbijlpark,
  },
  { path: "/cheap-removals-vereeniging", component: cheapremovalsvereeniging },
  { path: "/cheap-removals-volksrust", component: cheapremovalsvolksrust },
  { path: "/cheap-removals-vredenburg", component: cheapremovalsvredenburg },
  { path: "/cheap-removals-vryburg", component: cheapremovalsvryburg },
  { path: "/cheap-removals-vryheid", component: cheapremovalsvryheid },
  { path: "/cheap-removals-welkom", component: cheapremovalswelkom },
  { path: "/cheap-removals-worcester", component: cheapremovalsworcester },
  {
    path: "/cheap-transportation-alberton",
    component: cheaptransportationalberton,
  },
  {
    path: "/cheap-transportation-aliwal-north",
    component: cheaptransportationaliwalnorth,
  },
  {
    path: "/cheap-transportation-beaufort-west",
    component: cheaptransportationbeaufortwest,
  },
  {
    path: "/cheap-transportation-benoni",
    component: cheaptransportationbenoni,
  },
  {
    path: "/cheap-transportation-bethal",
    component: cheaptransportationbethal,
  },
  {
    path: "/cheap-transportation-bhisho",
    component: cheaptransportationbhisho,
  },
  {
    path: "/cheap-transportation-bloemfontein",
    component: cheaptransportationbloemfontein,
  },
  {
    path: "/cheap-transportation-brakpan",
    component: cheaptransportationbrakpan,
  },
  { path: "/cheap-transportation-brits", component: cheaptransportationbrits },
  {
    path: "/cheap-transportation-cape-town",
    component: cheaptransportationcapetown,
  },
  {
    path: "/cheap-transportation-carletonville",
    component: cheaptransportationcarletonville,
  },
  {
    path: "/cheap-transportation-centurion",
    component: cheaptransportationcenturion,
  },
  {
    path: "/cheap-transportation-cradock",
    component: cheaptransportationcradock,
  },
  { path: "/cheap-transportation-de-aar", component: cheaptransportationdeaar },
  {
    path: "/cheap-transportation-durban",
    component: cheaptransportationdurban,
  },
  {
    path: "/cheap-transportation-east-london",
    component: cheaptransportationeastlondon,
  },
  {
    path: "/cheap-transportation-ferndale",
    component: cheaptransportationferndale,
  },
  {
    path: "/cheap-transportation-george",
    component: cheaptransportationgeorge,
  },
  {
    path: "/cheap-transportation-graaff-reinet",
    component: cheaptransportationgraaffreinet,
  },
  {
    path: "/cheap-transportation-grahamstown",
    component: cheaptransportationgrahamstown,
  },
  {
    path: "/cheap-transportation-hermanus",
    component: cheaptransportationhermanus,
  },
  {
    path: "/cheap-transportation-jeffreys-bay",
    component: cheaptransportationjeffreysbay,
  },
  {
    path: "/cheap-transportation-johannesburg",
    component: cheaptransportationjohannesburg,
  },
  {
    path: "/cheap-transportation-kimberley",
    component: cheaptransportationkimberley,
  },
  {
    path: "/cheap-transportation-klerksdorp",
    component: cheaptransportationklerksdorp,
  },
  {
    path: "/cheap-transportation-knysna",
    component: cheaptransportationknysna,
  },
  {
    path: "/cheap-transportation-kroonstad",
    component: cheaptransportationkroonstad,
  },
  {
    path: "/cheap-transportation-kuilsrivier",
    component: cheaptransportationkuilsrivier,
  },
  {
    path: "/cheap-transportation-ladysmith",
    component: cheaptransportationladysmith,
  },
  {
    path: "/cheap-transportation-lebowakgomo",
    component: cheaptransportationlebowakgomo,
  },
  {
    path: "/cheap-transportation-lichtenburg",
    component: cheaptransportationlichtenburg,
  },
  {
    path: "/cheap-transportation-mahikeng",
    component: cheaptransportationmahikeng,
  },
  {
    path: "/cheap-transportation-malmesbury",
    component: cheaptransportationmalmesbury,
  },
  {
    path: "/cheap-transportation-middelburg",
    component: cheaptransportationmiddelburg,
  },
  {
    path: "/cheap-transportation-midrand",
    component: cheaptransportationmidrand,
  },
  {
    path: "/cheap-transportation-mmabatho",
    component: cheaptransportationmmabatho,
  },
  {
    path: "/cheap-transportation-mossel-bay",
    component: cheaptransportationmosselbay,
  },
  {
    path: "/cheap-transportation-musina",
    component: cheaptransportationmusina,
  },
  {
    path: "/cheap-transportation-nelspruit",
    component: cheaptransportationnelspruit,
  },
  {
    path: "/cheap-transportation-oudtshoorn",
    component: cheaptransportationoudtshoorn,
  },
  { path: "/cheap-transportation-paarl", component: cheaptransportationpaarl },
  {
    path: "/cheap-transportation-pietermaritzburg",
    component: cheaptransportationpietermaritzburg,
  },
  {
    path: "/cheap-transportation-polokwane",
    component: cheaptransportationpolokwane,
  },
  {
    path: "/cheap-transportation-port-elizabeth",
    component: cheaptransportationportelizabeth,
  },
  {
    path: "/cheap-transportation-port-shepstone",
    component: cheaptransportationportshepstone,
  },
  {
    path: "/cheap-transportation-potchefstroom",
    component: cheaptransportationpotchefstroom,
  },
  {
    path: "/cheap-transportation-pretoria",
    component: cheaptransportationpretoria,
  },
  {
    path: "/cheap-transportation-queenstown",
    component: cheaptransportationqueenstown,
  },
  {
    path: "/cheap-transportation-randburg",
    component: cheaptransportationrandburg,
  },
  {
    path: "/cheap-transportation-roodepoort",
    component: cheaptransportationroodepoort,
  },
  {
    path: "/cheap-transportation-rustenburg",
    component: cheaptransportationrustenburg,
  },
  {
    path: "/cheap-transportation-saldanha",
    component: cheaptransportationsaldanha,
  },
  {
    path: "/cheap-transportation-sandton",
    component: cheaptransportationsandton,
  },
  {
    path: "/cheap-transportation-soweto",
    component: cheaptransportationsoweto,
  },
  {
    path: "/cheap-transportation-springs",
    component: cheaptransportationsprings,
  },
  {
    path: "/cheap-transportation-standerton",
    component: cheaptransportationstanderton,
  },
  {
    path: "/cheap-transportation-thohoyandou",
    component: cheaptransportationthohoyandou,
  },
  {
    path: "/cheap-transportation-tzaneen",
    component: cheaptransportationtzaneen,
  },
  {
    path: "/cheap-transportation-uitenhage",
    component: cheaptransportationuitenhage,
  },
  {
    path: "/cheap-transportation-ulundi",
    component: cheaptransportationulundi,
  },
  {
    path: "/cheap-transportation-umtata",
    component: cheaptransportationumtata,
  },
  {
    path: "/cheap-transportation-upington",
    component: cheaptransportationupington,
  },
  {
    path: "/cheap-transportation-vanderbijlpark",
    component: cheaptransportationvanderbijlpark,
  },
  {
    path: "/cheap-transportation-vereeniging",
    component: cheaptransportationvereeniging,
  },
  {
    path: "/cheap-transportation-volksrust",
    component: cheaptransportationvolksrust,
  },
  {
    path: "/cheap-transportation-vredenburg",
    component: cheaptransportationvredenburg,
  },
  {
    path: "/cheap-transportation-vryburg",
    component: cheaptransportationvryburg,
  },
  {
    path: "/cheap-transportation-vryheid",
    component: cheaptransportationvryheid,
  },
  {
    path: "/cheap-transportation-welkom",
    component: cheaptransportationwelkom,
  },
  {
    path: "/cheap-transportation-worcester",
    component: cheaptransportationworcester,
  },
  { path: "/courier--alberton", component: courieralberton },
  { path: "/courier--aliwal-north", component: courieraliwalnorth },
  { path: "/courier--beaufort-west", component: courierbeaufortwest },
  { path: "/courier--benoni", component: courierbenoni },
  { path: "/courier--bethal", component: courierbethal },
  { path: "/courier--bhisho", component: courierbhisho },
  { path: "/courier--bloemfontein", component: courierbloemfontein },
  { path: "/courier--brakpan", component: courierbrakpan },
  { path: "/courier--brits", component: courierbrits },
  { path: "/courier--cape-town", component: couriercapetown },
  { path: "/courier--carletonville", component: couriercarletonville },
  { path: "/courier--centurion", component: couriercenturion },
  { path: "/courier--cradock", component: couriercradock },
  { path: "/courier--de-aar", component: courierdeaar },
  { path: "/courier--durban", component: courierdurban },
  { path: "/courier--east-london", component: couriereastlondon },
  { path: "/courier--ferndale", component: courierferndale },
  { path: "/courier--george", component: couriergeorge },
  { path: "/courier--graaff-reinet", component: couriergraaffreinet },
  { path: "/courier--grahamstown", component: couriergrahamstown },
  { path: "/courier--hermanus", component: courierhermanus },
  { path: "/courier--jeffreys-bay", component: courierjeffreysbay },
  { path: "/courier--johannesburg", component: courierjohannesburg },
  { path: "/courier--kimberley", component: courierkimberley },
  { path: "/courier--klerksdorp", component: courierklerksdorp },
  { path: "/courier--knysna", component: courierknysna },
  { path: "/courier--kroonstad", component: courierkroonstad },
  { path: "/courier--kuilsrivier", component: courierkuilsrivier },
  { path: "/courier--ladysmith", component: courierladysmith },
  { path: "/courier--lebowakgomo", component: courierlebowakgomo },
  { path: "/courier--lichtenburg", component: courierlichtenburg },
  { path: "/courier--mahikeng", component: couriermahikeng },
  { path: "/courier--malmesbury", component: couriermalmesbury },
  { path: "/courier--middelburg", component: couriermiddelburg },
  { path: "/courier--midrand", component: couriermidrand },
  { path: "/courier--mmabatho", component: couriermmabatho },
  { path: "/courier--mossel-bay", component: couriermosselbay },
  { path: "/courier--musina", component: couriermusina },
  { path: "/courier--nelspruit", component: couriernelspruit },
  { path: "/courier--oudtshoorn", component: courieroudtshoorn },
  { path: "/courier--paarl", component: courierpaarl },
  { path: "/courier--pietermaritzburg", component: courierpietermaritzburg },
  { path: "/courier--polokwane", component: courierpolokwane },
  { path: "/courier--port-elizabeth", component: courierportelizabeth },
  { path: "/courier--port-shepstone", component: courierportshepstone },
  { path: "/courier--potchefstroom", component: courierpotchefstroom },
  { path: "/courier--pretoria", component: courierpretoria },
  { path: "/courier--queenstown", component: courierqueenstown },
  { path: "/courier--randburg", component: courierrandburg },
  { path: "/courier--roodepoort", component: courierroodepoort },
  { path: "/courier--rustenburg", component: courierrustenburg },
  { path: "/courier--saldanha", component: couriersaldanha },
  { path: "/courier--sandton", component: couriersandton },
  { path: "/courier--soweto", component: couriersoweto },
  { path: "/courier--springs", component: couriersprings },
  { path: "/courier--standerton", component: courierstanderton },
  { path: "/courier--thohoyandou", component: courierthohoyandou },
  { path: "/courier--tzaneen", component: couriertzaneen },
  { path: "/courier--uitenhage", component: courieruitenhage },
  { path: "/courier--ulundi", component: courierulundi },
  { path: "/courier--umtata", component: courierumtata },
  { path: "/courier--upington", component: courierupington },
  { path: "/courier--vanderbijlpark", component: couriervanderbijlpark },
  { path: "/courier--vereeniging", component: couriervereeniging },
  { path: "/courier--volksrust", component: couriervolksrust },
  { path: "/courier--vredenburg", component: couriervredenburg },
  { path: "/courier--vryburg", component: couriervryburg },
  { path: "/courier--vryheid", component: couriervryheid },
  { path: "/courier--welkom", component: courierwelkom },
  { path: "/courier--worcester", component: courierworcester },
  { path: "/delivery-alberton", component: deliveryalberton },
  { path: "/delivery-aliwal-north", component: deliveryaliwalnorth },
  { path: "/delivery-beaufort-west", component: deliverybeaufortwest },
  { path: "/delivery-benoni", component: deliverybenoni },
  { path: "/delivery-bethal", component: deliverybethal },
  { path: "/delivery-bhisho", component: deliverybhisho },
  { path: "/delivery-bloemfontein", component: deliverybloemfontein },
  { path: "/delivery-brakpan", component: deliverybrakpan },
  { path: "/delivery-brits", component: deliverybrits },
  { path: "/delivery-cape-town", component: deliverycapetown },
  { path: "/delivery-carletonville", component: deliverycarletonville },
  { path: "/delivery-centurion", component: deliverycenturion },
  { path: "/delivery-cradock", component: deliverycradock },
  { path: "/delivery-de-aar", component: deliverydeaar },
  { path: "/delivery-durban", component: deliverydurban },
  { path: "/delivery-east-london", component: deliveryeastlondon },
  { path: "/delivery-ferndale", component: deliveryferndale },
  { path: "/delivery-george", component: deliverygeorge },
  { path: "/delivery-graaff-reinet", component: deliverygraaffreinet },
  { path: "/delivery-grahamstown", component: deliverygrahamstown },
  { path: "/delivery-hermanus", component: deliveryhermanus },
  { path: "/delivery-jeffreys-bay", component: deliveryjeffreysbay },
  { path: "/delivery-johannesburg", component: deliveryjohannesburg },
  { path: "/delivery-kimberley", component: deliverykimberley },
  { path: "/delivery-klerksdorp", component: deliveryklerksdorp },
  { path: "/delivery-knysna", component: deliveryknysna },
  { path: "/delivery-kroonstad", component: deliverykroonstad },
  { path: "/delivery-kuilsrivier", component: deliverykuilsrivier },
  { path: "/delivery-ladysmith", component: deliveryladysmith },
  { path: "/delivery-lebowakgomo", component: deliverylebowakgomo },
  { path: "/delivery-lichtenburg", component: deliverylichtenburg },
  { path: "/delivery-mahikeng", component: deliverymahikeng },
  { path: "/delivery-malmesbury", component: deliverymalmesbury },
  { path: "/delivery-middelburg", component: deliverymiddelburg },
  { path: "/delivery-midrand", component: deliverymidrand },
  { path: "/delivery-mmabatho", component: deliverymmabatho },
  { path: "/delivery-mossel-bay", component: deliverymosselbay },
  { path: "/delivery-musina", component: deliverymusina },
  { path: "/delivery-nelspruit", component: deliverynelspruit },
  { path: "/delivery-oudtshoorn", component: deliveryoudtshoorn },
  { path: "/delivery-paarl", component: deliverypaarl },
  { path: "/delivery-pietermaritzburg", component: deliverypietermaritzburg },
  { path: "/delivery-polokwane", component: deliverypolokwane },
  { path: "/delivery-port-elizabeth", component: deliveryportelizabeth },
  { path: "/delivery-port-shepstone", component: deliveryportshepstone },
  { path: "/delivery-potchefstroom", component: deliverypotchefstroom },
  { path: "/delivery-pretoria", component: deliverypretoria },
  { path: "/delivery-queenstown", component: deliveryqueenstown },
  { path: "/delivery-randburg", component: deliveryrandburg },
  { path: "/delivery-roodepoort", component: deliveryroodepoort },
  { path: "/delivery-rustenburg", component: deliveryrustenburg },
  { path: "/delivery-saldanha", component: deliverysaldanha },
  { path: "/delivery-sandton", component: deliverysandton },
  { path: "/delivery-soweto", component: deliverysoweto },
  { path: "/delivery-springs", component: deliverysprings },
  { path: "/delivery-standerton", component: deliverystanderton },
  { path: "/delivery-thohoyandou", component: deliverythohoyandou },
  { path: "/delivery-tzaneen", component: deliverytzaneen },
  { path: "/delivery-uitenhage", component: deliveryuitenhage },
  { path: "/delivery-ulundi", component: deliveryulundi },
  { path: "/delivery-umtata", component: deliveryumtata },
  { path: "/delivery-upington", component: deliveryupington },
  { path: "/delivery-vanderbijlpark", component: deliveryvanderbijlpark },
  { path: "/delivery-vereeniging", component: deliveryvereeniging },
  { path: "/delivery-volksrust", component: deliveryvolksrust },
  { path: "/delivery-vredenburg", component: deliveryvredenburg },
  { path: "/delivery-vryburg", component: deliveryvryburg },
  { path: "/delivery-vryheid", component: deliveryvryheid },
  { path: "/delivery-welkom", component: deliverywelkom },
  { path: "/delivery-worcester", component: deliveryworcester },
  {
    path: "/door-to-door-delivery-alberton",
    component: doortodoordeliveryalberton,
  },
  {
    path: "/door-to-door-delivery-aliwal-north",
    component: doortodoordeliveryaliwalnorth,
  },
  {
    path: "/door-to-door-delivery-beaufort-west",
    component: doortodoordeliverybeaufortwest,
  },
  {
    path: "/door-to-door-delivery-benoni",
    component: doortodoordeliverybenoni,
  },
  {
    path: "/door-to-door-delivery-bethal",
    component: doortodoordeliverybethal,
  },
  {
    path: "/door-to-door-delivery-bhisho",
    component: doortodoordeliverybhisho,
  },
  {
    path: "/door-to-door-delivery-bloemfontein",
    component: doortodoordeliverybloemfontein,
  },
  {
    path: "/door-to-door-delivery-brakpan",
    component: doortodoordeliverybrakpan,
  },
  { path: "/door-to-door-delivery-brits", component: doortodoordeliverybrits },
  {
    path: "/door-to-door-delivery-cape-town",
    component: doortodoordeliverycapetown,
  },
  {
    path: "/door-to-door-delivery-carletonville",
    component: doortodoordeliverycarletonville,
  },
  {
    path: "/door-to-door-delivery-centurion",
    component: doortodoordeliverycenturion,
  },
  {
    path: "/door-to-door-delivery-cradock",
    component: doortodoordeliverycradock,
  },
  { path: "/door-to-door-delivery-de-aar", component: doortodoordeliverydeaar },
  {
    path: "/door-to-door-delivery-durban",
    component: doortodoordeliverydurban,
  },
  {
    path: "/door-to-door-delivery-east-london",
    component: doortodoordeliveryeastlondon,
  },
  {
    path: "/door-to-door-delivery-ferndale",
    component: doortodoordeliveryferndale,
  },
  {
    path: "/door-to-door-delivery-george",
    component: doortodoordeliverygeorge,
  },
  {
    path: "/door-to-door-delivery-graaff-reinet",
    component: doortodoordeliverygraaffreinet,
  },
  {
    path: "/door-to-door-delivery-grahamstown",
    component: doortodoordeliverygrahamstown,
  },
  {
    path: "/door-to-door-delivery-hermanus",
    component: doortodoordeliveryhermanus,
  },
  {
    path: "/door-to-door-delivery-jeffreys-bay",
    component: doortodoordeliveryjeffreysbay,
  },
  {
    path: "/door-to-door-delivery-johannesburg",
    component: doortodoordeliveryjohannesburg,
  },
  {
    path: "/door-to-door-delivery-kimberley",
    component: doortodoordeliverykimberley,
  },
  {
    path: "/door-to-door-delivery-klerksdorp",
    component: doortodoordeliveryklerksdorp,
  },
  {
    path: "/door-to-door-delivery-knysna",
    component: doortodoordeliveryknysna,
  },
  {
    path: "/door-to-door-delivery-kroonstad",
    component: doortodoordeliverykroonstad,
  },
  {
    path: "/door-to-door-delivery-kuilsrivier",
    component: doortodoordeliverykuilsrivier,
  },
  {
    path: "/door-to-door-delivery-ladysmith",
    component: doortodoordeliveryladysmith,
  },
  {
    path: "/door-to-door-delivery-lebowakgomo",
    component: doortodoordeliverylebowakgomo,
  },
  {
    path: "/door-to-door-delivery-lichtenburg",
    component: doortodoordeliverylichtenburg,
  },
  {
    path: "/door-to-door-delivery-mahikeng",
    component: doortodoordeliverymahikeng,
  },
  {
    path: "/door-to-door-delivery-malmesbury",
    component: doortodoordeliverymalmesbury,
  },
  {
    path: "/door-to-door-delivery-middelburg",
    component: doortodoordeliverymiddelburg,
  },
  {
    path: "/door-to-door-delivery-midrand",
    component: doortodoordeliverymidrand,
  },
  {
    path: "/door-to-door-delivery-mmabatho",
    component: doortodoordeliverymmabatho,
  },
  {
    path: "/door-to-door-delivery-mossel-bay",
    component: doortodoordeliverymosselbay,
  },
  {
    path: "/door-to-door-delivery-musina",
    component: doortodoordeliverymusina,
  },
  {
    path: "/door-to-door-delivery-nelspruit",
    component: doortodoordeliverynelspruit,
  },
  {
    path: "/door-to-door-delivery-oudtshoorn",
    component: doortodoordeliveryoudtshoorn,
  },
  { path: "/door-to-door-delivery-paarl", component: doortodoordeliverypaarl },
  {
    path: "/door-to-door-delivery-pietermaritzburg",
    component: doortodoordeliverypietermaritzburg,
  },
  {
    path: "/door-to-door-delivery-polokwane",
    component: doortodoordeliverypolokwane,
  },
  {
    path: "/door-to-door-delivery-port-elizabeth",
    component: doortodoordeliveryportelizabeth,
  },
  {
    path: "/door-to-door-delivery-port-shepstone",
    component: doortodoordeliveryportshepstone,
  },
  {
    path: "/door-to-door-delivery-potchefstroom",
    component: doortodoordeliverypotchefstroom,
  },
  {
    path: "/door-to-door-delivery-pretoria",
    component: doortodoordeliverypretoria,
  },
  {
    path: "/door-to-door-delivery-queenstown",
    component: doortodoordeliveryqueenstown,
  },
  {
    path: "/door-to-door-delivery-randburg",
    component: doortodoordeliveryrandburg,
  },
  {
    path: "/door-to-door-delivery-roodepoort",
    component: doortodoordeliveryroodepoort,
  },
  {
    path: "/door-to-door-delivery-rustenburg",
    component: doortodoordeliveryrustenburg,
  },
  {
    path: "/door-to-door-delivery-saldanha",
    component: doortodoordeliverysaldanha,
  },
  {
    path: "/door-to-door-delivery-sandton",
    component: doortodoordeliverysandton,
  },
  {
    path: "/door-to-door-delivery-soweto",
    component: doortodoordeliverysoweto,
  },
  {
    path: "/door-to-door-delivery-springs",
    component: doortodoordeliverysprings,
  },
  {
    path: "/door-to-door-delivery-standerton",
    component: doortodoordeliverystanderton,
  },
  {
    path: "/door-to-door-delivery-thohoyandou",
    component: doortodoordeliverythohoyandou,
  },
  {
    path: "/door-to-door-delivery-tzaneen",
    component: doortodoordeliverytzaneen,
  },
  {
    path: "/door-to-door-delivery-uitenhage",
    component: doortodoordeliveryuitenhage,
  },
  {
    path: "/door-to-door-delivery-ulundi",
    component: doortodoordeliveryulundi,
  },
  {
    path: "/door-to-door-delivery-umtata",
    component: doortodoordeliveryumtata,
  },
  {
    path: "/door-to-door-delivery-upington",
    component: doortodoordeliveryupington,
  },
  {
    path: "/door-to-door-delivery-vanderbijlpark",
    component: doortodoordeliveryvanderbijlpark,
  },
  {
    path: "/door-to-door-delivery-vereeniging",
    component: doortodoordeliveryvereeniging,
  },
  {
    path: "/door-to-door-delivery-volksrust",
    component: doortodoordeliveryvolksrust,
  },
  {
    path: "/door-to-door-delivery-vredenburg",
    component: doortodoordeliveryvredenburg,
  },
  {
    path: "/door-to-door-delivery-vryburg",
    component: doortodoordeliveryvryburg,
  },
  {
    path: "/door-to-door-delivery-vryheid",
    component: doortodoordeliveryvryheid,
  },
  {
    path: "/door-to-door-delivery-welkom",
    component: doortodoordeliverywelkom,
  },
  {
    path: "/door-to-door-delivery-worcester",
    component: doortodoordeliveryworcester,
  },
  {
    path: "/equipment-transport-alberton",
    component: equipmenttransportalberton,
  },
  {
    path: "/equipment-transport-aliwal-north",
    component: equipmenttransportaliwalnorth,
  },
  {
    path: "/equipment-transport-beaufort-west",
    component: equipmenttransportbeaufortwest,
  },
  { path: "/equipment-transport-benoni", component: equipmenttransportbenoni },
  { path: "/equipment-transport-bethal", component: equipmenttransportbethal },
  { path: "/equipment-transport-bhisho", component: equipmenttransportbhisho },
  {
    path: "/equipment-transport-bloemfontein",
    component: equipmenttransportbloemfontein,
  },
  {
    path: "/equipment-transport-brakpan",
    component: equipmenttransportbrakpan,
  },
  { path: "/equipment-transport-brits", component: equipmenttransportbrits },
  {
    path: "/equipment-transport-cape-town",
    component: equipmenttransportcapetown,
  },
  {
    path: "/equipment-transport-carletonville",
    component: equipmenttransportcarletonville,
  },
  {
    path: "/equipment-transport-centurion",
    component: equipmenttransportcenturion,
  },
  {
    path: "/equipment-transport-cradock",
    component: equipmenttransportcradock,
  },
  { path: "/equipment-transport-de-aar", component: equipmenttransportdeaar },
  { path: "/equipment-transport-durban", component: equipmenttransportdurban },
  {
    path: "/equipment-transport-east-london",
    component: equipmenttransporteastlondon,
  },
  {
    path: "/equipment-transport-ferndale",
    component: equipmenttransportferndale,
  },
  { path: "/equipment-transport-george", component: equipmenttransportgeorge },
  {
    path: "/equipment-transport-graaff-reinet",
    component: equipmenttransportgraaffreinet,
  },
  {
    path: "/equipment-transport-grahamstown",
    component: equipmenttransportgrahamstown,
  },
  {
    path: "/equipment-transport-hermanus",
    component: equipmenttransporthermanus,
  },
  {
    path: "/equipment-transport-jeffreys-bay",
    component: equipmenttransportjeffreysbay,
  },
  {
    path: "/equipment-transport-johannesburg",
    component: equipmenttransportjohannesburg,
  },
  {
    path: "/equipment-transport-kimberley",
    component: equipmenttransportkimberley,
  },
  {
    path: "/equipment-transport-klerksdorp",
    component: equipmenttransportklerksdorp,
  },
  { path: "/equipment-transport-knysna", component: equipmenttransportknysna },
  {
    path: "/equipment-transport-kroonstad",
    component: equipmenttransportkroonstad,
  },
  {
    path: "/equipment-transport-kuilsrivier",
    component: equipmenttransportkuilsrivier,
  },
  {
    path: "/equipment-transport-ladysmith",
    component: equipmenttransportladysmith,
  },
  {
    path: "/equipment-transport-lebowakgomo",
    component: equipmenttransportlebowakgomo,
  },
  {
    path: "/equipment-transport-lichtenburg",
    component: equipmenttransportlichtenburg,
  },
  {
    path: "/equipment-transport-mahikeng",
    component: equipmenttransportmahikeng,
  },
  {
    path: "/equipment-transport-malmesbury",
    component: equipmenttransportmalmesbury,
  },
  {
    path: "/equipment-transport-middelburg",
    component: equipmenttransportmiddelburg,
  },
  {
    path: "/equipment-transport-midrand",
    component: equipmenttransportmidrand,
  },
  {
    path: "/equipment-transport-mmabatho",
    component: equipmenttransportmmabatho,
  },
  {
    path: "/equipment-transport-mossel-bay",
    component: equipmenttransportmosselbay,
  },
  { path: "/equipment-transport-musina", component: equipmenttransportmusina },
  {
    path: "/equipment-transport-nelspruit",
    component: equipmenttransportnelspruit,
  },
  {
    path: "/equipment-transport-oudtshoorn",
    component: equipmenttransportoudtshoorn,
  },
  { path: "/equipment-transport-paarl", component: equipmenttransportpaarl },
  {
    path: "/equipment-transport-pietermaritzburg",
    component: equipmenttransportpietermaritzburg,
  },
  {
    path: "/equipment-transport-polokwane",
    component: equipmenttransportpolokwane,
  },
  {
    path: "/equipment-transport-port-elizabeth",
    component: equipmenttransportportelizabeth,
  },
  {
    path: "/equipment-transport-port-shepstone",
    component: equipmenttransportportshepstone,
  },
  {
    path: "/equipment-transport-potchefstroom",
    component: equipmenttransportpotchefstroom,
  },
  {
    path: "/equipment-transport-pretoria",
    component: equipmenttransportpretoria,
  },
  {
    path: "/equipment-transport-queenstown",
    component: equipmenttransportqueenstown,
  },
  {
    path: "/equipment-transport-randburg",
    component: equipmenttransportrandburg,
  },
  {
    path: "/equipment-transport-roodepoort",
    component: equipmenttransportroodepoort,
  },
  {
    path: "/equipment-transport-rustenburg",
    component: equipmenttransportrustenburg,
  },
  {
    path: "/equipment-transport-saldanha",
    component: equipmenttransportsaldanha,
  },
  {
    path: "/equipment-transport-sandton",
    component: equipmenttransportsandton,
  },
  { path: "/equipment-transport-soweto", component: equipmenttransportsoweto },
  {
    path: "/equipment-transport-springs",
    component: equipmenttransportsprings,
  },
  {
    path: "/equipment-transport-standerton",
    component: equipmenttransportstanderton,
  },
  {
    path: "/equipment-transport-thohoyandou",
    component: equipmenttransportthohoyandou,
  },
  {
    path: "/equipment-transport-tzaneen",
    component: equipmenttransporttzaneen,
  },
  {
    path: "/equipment-transport-uitenhage",
    component: equipmenttransportuitenhage,
  },
  { path: "/equipment-transport-ulundi", component: equipmenttransportulundi },
  { path: "/equipment-transport-umtata", component: equipmenttransportumtata },
  {
    path: "/equipment-transport-upington",
    component: equipmenttransportupington,
  },
  {
    path: "/equipment-transport-vanderbijlpark",
    component: equipmenttransportvanderbijlpark,
  },
  {
    path: "/equipment-transport-vereeniging",
    component: equipmenttransportvereeniging,
  },
  {
    path: "/equipment-transport-volksrust",
    component: equipmenttransportvolksrust,
  },
  {
    path: "/equipment-transport-vredenburg",
    component: equipmenttransportvredenburg,
  },
  {
    path: "/equipment-transport-vryburg",
    component: equipmenttransportvryburg,
  },
  {
    path: "/equipment-transport-vryheid",
    component: equipmenttransportvryheid,
  },
  { path: "/equipment-transport-welkom", component: equipmenttransportwelkom },
  {
    path: "/equipment-transport-worcester",
    component: equipmenttransportworcester,
  },
  { path: "/express-delivery-alberton", component: expressdeliveryalberton },
  {
    path: "/express-delivery-aliwal-north",
    component: expressdeliveryaliwalnorth,
  },
  {
    path: "/express-delivery-beaufort-west",
    component: expressdeliverybeaufortwest,
  },
  { path: "/express-delivery-benoni", component: expressdeliverybenoni },
  { path: "/express-delivery-bethal", component: expressdeliverybethal },
  { path: "/express-delivery-bhisho", component: expressdeliverybhisho },
  {
    path: "/express-delivery-bloemfontein",
    component: expressdeliverybloemfontein,
  },
  { path: "/express-delivery-brakpan", component: expressdeliverybrakpan },
  { path: "/express-delivery-brits", component: expressdeliverybrits },
  { path: "/express-delivery-cape-town", component: expressdeliverycapetown },
  {
    path: "/express-delivery-carletonville",
    component: expressdeliverycarletonville,
  },
  { path: "/express-delivery-centurion", component: expressdeliverycenturion },
  { path: "/express-delivery-cradock", component: expressdeliverycradock },
  { path: "/express-delivery-de-aar", component: expressdeliverydeaar },
  { path: "/express-delivery-durban", component: expressdeliverydurban },
  {
    path: "/express-delivery-east-london",
    component: expressdeliveryeastlondon,
  },
  { path: "/express-delivery-ferndale", component: expressdeliveryferndale },
  { path: "/express-delivery-george", component: expressdeliverygeorge },
  {
    path: "/express-delivery-graaff-reinet",
    component: expressdeliverygraaffreinet,
  },
  {
    path: "/express-delivery-grahamstown",
    component: expressdeliverygrahamstown,
  },
  { path: "/express-delivery-hermanus", component: expressdeliveryhermanus },
  {
    path: "/express-delivery-jeffreys-bay",
    component: expressdeliveryjeffreysbay,
  },
  {
    path: "/express-delivery-johannesburg",
    component: expressdeliveryjohannesburg,
  },
  { path: "/express-delivery-kimberley", component: expressdeliverykimberley },
  {
    path: "/express-delivery-klerksdorp",
    component: expressdeliveryklerksdorp,
  },
  { path: "/express-delivery-knysna", component: expressdeliveryknysna },
  { path: "/express-delivery-kroonstad", component: expressdeliverykroonstad },
  {
    path: "/express-delivery-kuilsrivier",
    component: expressdeliverykuilsrivier,
  },
  { path: "/express-delivery-ladysmith", component: expressdeliveryladysmith },
  {
    path: "/express-delivery-lebowakgomo",
    component: expressdeliverylebowakgomo,
  },
  {
    path: "/express-delivery-lichtenburg",
    component: expressdeliverylichtenburg,
  },
  { path: "/express-delivery-mahikeng", component: expressdeliverymahikeng },
  {
    path: "/express-delivery-malmesbury",
    component: expressdeliverymalmesbury,
  },
  {
    path: "/express-delivery-middelburg",
    component: expressdeliverymiddelburg,
  },
  { path: "/express-delivery-midrand", component: expressdeliverymidrand },
  { path: "/express-delivery-mmabatho", component: expressdeliverymmabatho },
  { path: "/express-delivery-mossel-bay", component: expressdeliverymosselbay },
  { path: "/express-delivery-musina", component: expressdeliverymusina },
  { path: "/express-delivery-nelspruit", component: expressdeliverynelspruit },
  {
    path: "/express-delivery-oudtshoorn",
    component: expressdeliveryoudtshoorn,
  },
  { path: "/express-delivery-paarl", component: expressdeliverypaarl },
  {
    path: "/express-delivery-pietermaritzburg",
    component: expressdeliverypietermaritzburg,
  },
  { path: "/express-delivery-polokwane", component: expressdeliverypolokwane },
  {
    path: "/express-delivery-port-elizabeth",
    component: expressdeliveryportelizabeth,
  },
  {
    path: "/express-delivery-port-shepstone",
    component: expressdeliveryportshepstone,
  },
  {
    path: "/express-delivery-potchefstroom",
    component: expressdeliverypotchefstroom,
  },
  { path: "/express-delivery-pretoria", component: expressdeliverypretoria },
  {
    path: "/express-delivery-queenstown",
    component: expressdeliveryqueenstown,
  },
  { path: "/express-delivery-randburg", component: expressdeliveryrandburg },
  {
    path: "/express-delivery-roodepoort",
    component: expressdeliveryroodepoort,
  },
  {
    path: "/express-delivery-rustenburg",
    component: expressdeliveryrustenburg,
  },
  { path: "/express-delivery-saldanha", component: expressdeliverysaldanha },
  { path: "/express-delivery-sandton", component: expressdeliverysandton },
  { path: "/express-delivery-soweto", component: expressdeliverysoweto },
  { path: "/express-delivery-springs", component: expressdeliverysprings },
  {
    path: "/express-delivery-standerton",
    component: expressdeliverystanderton,
  },
  {
    path: "/express-delivery-thohoyandou",
    component: expressdeliverythohoyandou,
  },
  { path: "/express-delivery-tzaneen", component: expressdeliverytzaneen },
  { path: "/express-delivery-uitenhage", component: expressdeliveryuitenhage },
  { path: "/express-delivery-ulundi", component: expressdeliveryulundi },
  { path: "/express-delivery-umtata", component: expressdeliveryumtata },
  { path: "/express-delivery-upington", component: expressdeliveryupington },
  {
    path: "/express-delivery-vanderbijlpark",
    component: expressdeliveryvanderbijlpark,
  },
  {
    path: "/express-delivery-vereeniging",
    component: expressdeliveryvereeniging,
  },
  { path: "/express-delivery-volksrust", component: expressdeliveryvolksrust },
  {
    path: "/express-delivery-vredenburg",
    component: expressdeliveryvredenburg,
  },
  { path: "/express-delivery-vryburg", component: expressdeliveryvryburg },
  { path: "/express-delivery-vryheid", component: expressdeliveryvryheid },
  { path: "/express-delivery-welkom", component: expressdeliverywelkom },
  { path: "/express-delivery-worcester", component: expressdeliveryworcester },
  {
    path: "/flexible-delivery-options-alberton",
    component: flexibledeliveryoptionsalberton,
  },
  {
    path: "/flexible-delivery-options-aliwal-north",
    component: flexibledeliveryoptionsaliwalnorth,
  },
  {
    path: "/flexible-delivery-options-beaufort-west",
    component: flexibledeliveryoptionsbeaufortwest,
  },
  {
    path: "/flexible-delivery-options-benoni",
    component: flexibledeliveryoptionsbenoni,
  },
  {
    path: "/flexible-delivery-options-bethal",
    component: flexibledeliveryoptionsbethal,
  },
  {
    path: "/flexible-delivery-options-bhisho",
    component: flexibledeliveryoptionsbhisho,
  },
  {
    path: "/flexible-delivery-options-bloemfontein",
    component: flexibledeliveryoptionsbloemfontein,
  },
  {
    path: "/flexible-delivery-options-brakpan",
    component: flexibledeliveryoptionsbrakpan,
  },
  {
    path: "/flexible-delivery-options-brits",
    component: flexibledeliveryoptionsbrits,
  },
  {
    path: "/flexible-delivery-options-cape-town",
    component: flexibledeliveryoptionscapetown,
  },
  {
    path: "/flexible-delivery-options-carletonville",
    component: flexibledeliveryoptionscarletonville,
  },
  {
    path: "/flexible-delivery-options-centurion",
    component: flexibledeliveryoptionscenturion,
  },
  {
    path: "/flexible-delivery-options-cradock",
    component: flexibledeliveryoptionscradock,
  },
  {
    path: "/flexible-delivery-options-de-aar",
    component: flexibledeliveryoptionsdeaar,
  },
  {
    path: "/flexible-delivery-options-durban",
    component: flexibledeliveryoptionsdurban,
  },
  {
    path: "/flexible-delivery-options-east-london",
    component: flexibledeliveryoptionseastlondon,
  },
  {
    path: "/flexible-delivery-options-ferndale",
    component: flexibledeliveryoptionsferndale,
  },
  {
    path: "/flexible-delivery-options-george",
    component: flexibledeliveryoptionsgeorge,
  },
  {
    path: "/flexible-delivery-options-graaff-reinet",
    component: flexibledeliveryoptionsgraaffreinet,
  },
  {
    path: "/flexible-delivery-options-grahamstown",
    component: flexibledeliveryoptionsgrahamstown,
  },
  {
    path: "/flexible-delivery-options-hermanus",
    component: flexibledeliveryoptionshermanus,
  },
  {
    path: "/flexible-delivery-options-jeffreys-bay",
    component: flexibledeliveryoptionsjeffreysbay,
  },
  {
    path: "/flexible-delivery-options-johannesburg",
    component: flexibledeliveryoptionsjohannesburg,
  },
  {
    path: "/flexible-delivery-options-kimberley",
    component: flexibledeliveryoptionskimberley,
  },
  {
    path: "/flexible-delivery-options-klerksdorp",
    component: flexibledeliveryoptionsklerksdorp,
  },
  {
    path: "/flexible-delivery-options-knysna",
    component: flexibledeliveryoptionsknysna,
  },
  {
    path: "/flexible-delivery-options-kroonstad",
    component: flexibledeliveryoptionskroonstad,
  },
  {
    path: "/flexible-delivery-options-kuilsrivier",
    component: flexibledeliveryoptionskuilsrivier,
  },
  {
    path: "/flexible-delivery-options-ladysmith",
    component: flexibledeliveryoptionsladysmith,
  },
  {
    path: "/flexible-delivery-options-lebowakgomo",
    component: flexibledeliveryoptionslebowakgomo,
  },
  {
    path: "/flexible-delivery-options-lichtenburg",
    component: flexibledeliveryoptionslichtenburg,
  },
  {
    path: "/flexible-delivery-options-mahikeng",
    component: flexibledeliveryoptionsmahikeng,
  },
  {
    path: "/flexible-delivery-options-malmesbury",
    component: flexibledeliveryoptionsmalmesbury,
  },
  {
    path: "/flexible-delivery-options-middelburg",
    component: flexibledeliveryoptionsmiddelburg,
  },
  {
    path: "/flexible-delivery-options-midrand",
    component: flexibledeliveryoptionsmidrand,
  },
  {
    path: "/flexible-delivery-options-mmabatho",
    component: flexibledeliveryoptionsmmabatho,
  },
  {
    path: "/flexible-delivery-options-mossel-bay",
    component: flexibledeliveryoptionsmosselbay,
  },
  {
    path: "/flexible-delivery-options-musina",
    component: flexibledeliveryoptionsmusina,
  },
  {
    path: "/flexible-delivery-options-nelspruit",
    component: flexibledeliveryoptionsnelspruit,
  },
  {
    path: "/flexible-delivery-options-oudtshoorn",
    component: flexibledeliveryoptionsoudtshoorn,
  },
  {
    path: "/flexible-delivery-options-paarl",
    component: flexibledeliveryoptionspaarl,
  },
  {
    path: "/flexible-delivery-options-pietermaritzburg",
    component: flexibledeliveryoptionspietermaritzburg,
  },
  {
    path: "/flexible-delivery-options-polokwane",
    component: flexibledeliveryoptionspolokwane,
  },
  {
    path: "/flexible-delivery-options-port-elizabeth",
    component: flexibledeliveryoptionsportelizabeth,
  },
  {
    path: "/flexible-delivery-options-port-shepstone",
    component: flexibledeliveryoptionsportshepstone,
  },
  {
    path: "/flexible-delivery-options-potchefstroom",
    component: flexibledeliveryoptionspotchefstroom,
  },
  {
    path: "/flexible-delivery-options-pretoria",
    component: flexibledeliveryoptionspretoria,
  },
  {
    path: "/flexible-delivery-options-queenstown",
    component: flexibledeliveryoptionsqueenstown,
  },
  {
    path: "/flexible-delivery-options-randburg",
    component: flexibledeliveryoptionsrandburg,
  },
  {
    path: "/flexible-delivery-options-roodepoort",
    component: flexibledeliveryoptionsroodepoort,
  },
  {
    path: "/flexible-delivery-options-rustenburg",
    component: flexibledeliveryoptionsrustenburg,
  },
  {
    path: "/flexible-delivery-options-saldanha",
    component: flexibledeliveryoptionssaldanha,
  },
  {
    path: "/flexible-delivery-options-sandton",
    component: flexibledeliveryoptionssandton,
  },
  {
    path: "/flexible-delivery-options-soweto",
    component: flexibledeliveryoptionssoweto,
  },
  {
    path: "/flexible-delivery-options-springs",
    component: flexibledeliveryoptionssprings,
  },
  {
    path: "/flexible-delivery-options-standerton",
    component: flexibledeliveryoptionsstanderton,
  },
  {
    path: "/flexible-delivery-options-thohoyandou",
    component: flexibledeliveryoptionsthohoyandou,
  },
  {
    path: "/flexible-delivery-options-tzaneen",
    component: flexibledeliveryoptionstzaneen,
  },
  {
    path: "/flexible-delivery-options-uitenhage",
    component: flexibledeliveryoptionsuitenhage,
  },
  {
    path: "/flexible-delivery-options-ulundi",
    component: flexibledeliveryoptionsulundi,
  },
  {
    path: "/flexible-delivery-options-umtata",
    component: flexibledeliveryoptionsumtata,
  },
  {
    path: "/flexible-delivery-options-upington",
    component: flexibledeliveryoptionsupington,
  },
  {
    path: "/flexible-delivery-options-vanderbijlpark",
    component: flexibledeliveryoptionsvanderbijlpark,
  },
  {
    path: "/flexible-delivery-options-vereeniging",
    component: flexibledeliveryoptionsvereeniging,
  },
  {
    path: "/flexible-delivery-options-volksrust",
    component: flexibledeliveryoptionsvolksrust,
  },
  {
    path: "/flexible-delivery-options-vredenburg",
    component: flexibledeliveryoptionsvredenburg,
  },
  {
    path: "/flexible-delivery-options-vryburg",
    component: flexibledeliveryoptionsvryburg,
  },
  {
    path: "/flexible-delivery-options-vryheid",
    component: flexibledeliveryoptionsvryheid,
  },
  {
    path: "/flexible-delivery-options-welkom",
    component: flexibledeliveryoptionswelkom,
  },
  {
    path: "/flexible-delivery-options-worcester",
    component: flexibledeliveryoptionsworcester,
  },
  { path: "/fragile-delivery-alberton", component: fragiledeliveryalberton },
  {
    path: "/fragile-delivery-aliwal-north",
    component: fragiledeliveryaliwalnorth,
  },
  {
    path: "/fragile-delivery-beaufort-west",
    component: fragiledeliverybeaufortwest,
  },
  { path: "/fragile-delivery-benoni", component: fragiledeliverybenoni },
  { path: "/fragile-delivery-bethal", component: fragiledeliverybethal },
  { path: "/fragile-delivery-bhisho", component: fragiledeliverybhisho },
  {
    path: "/fragile-delivery-bloemfontein",
    component: fragiledeliverybloemfontein,
  },
  { path: "/fragile-delivery-brakpan", component: fragiledeliverybrakpan },
  { path: "/fragile-delivery-brits", component: fragiledeliverybrits },
  { path: "/fragile-delivery-cape-town", component: fragiledeliverycapetown },
  {
    path: "/fragile-delivery-carletonville",
    component: fragiledeliverycarletonville,
  },
  { path: "/fragile-delivery-centurion", component: fragiledeliverycenturion },
  { path: "/fragile-delivery-cradock", component: fragiledeliverycradock },
  { path: "/fragile-delivery-de-aar", component: fragiledeliverydeaar },
  { path: "/fragile-delivery-durban", component: fragiledeliverydurban },
  {
    path: "/fragile-delivery-east-london",
    component: fragiledeliveryeastlondon,
  },
  { path: "/fragile-delivery-ferndale", component: fragiledeliveryferndale },
  { path: "/fragile-delivery-george", component: fragiledeliverygeorge },
  {
    path: "/fragile-delivery-graaff-reinet",
    component: fragiledeliverygraaffreinet,
  },
  {
    path: "/fragile-delivery-grahamstown",
    component: fragiledeliverygrahamstown,
  },
  { path: "/fragile-delivery-hermanus", component: fragiledeliveryhermanus },
  {
    path: "/fragile-delivery-jeffreys-bay",
    component: fragiledeliveryjeffreysbay,
  },
  {
    path: "/fragile-delivery-johannesburg",
    component: fragiledeliveryjohannesburg,
  },
  { path: "/fragile-delivery-kimberley", component: fragiledeliverykimberley },
  {
    path: "/fragile-delivery-klerksdorp",
    component: fragiledeliveryklerksdorp,
  },
  { path: "/fragile-delivery-knysna", component: fragiledeliveryknysna },
  { path: "/fragile-delivery-kroonstad", component: fragiledeliverykroonstad },
  {
    path: "/fragile-delivery-kuilsrivier",
    component: fragiledeliverykuilsrivier,
  },
  { path: "/fragile-delivery-ladysmith", component: fragiledeliveryladysmith },
  {
    path: "/fragile-delivery-lebowakgomo",
    component: fragiledeliverylebowakgomo,
  },
  {
    path: "/fragile-delivery-lichtenburg",
    component: fragiledeliverylichtenburg,
  },
  { path: "/fragile-delivery-mahikeng", component: fragiledeliverymahikeng },
  {
    path: "/fragile-delivery-malmesbury",
    component: fragiledeliverymalmesbury,
  },
  {
    path: "/fragile-delivery-middelburg",
    component: fragiledeliverymiddelburg,
  },
  { path: "/fragile-delivery-midrand", component: fragiledeliverymidrand },
  { path: "/fragile-delivery-mmabatho", component: fragiledeliverymmabatho },
  { path: "/fragile-delivery-mossel-bay", component: fragiledeliverymosselbay },
  { path: "/fragile-delivery-musina", component: fragiledeliverymusina },
  { path: "/fragile-delivery-nelspruit", component: fragiledeliverynelspruit },
  {
    path: "/fragile-delivery-oudtshoorn",
    component: fragiledeliveryoudtshoorn,
  },
  { path: "/fragile-delivery-paarl", component: fragiledeliverypaarl },
  {
    path: "/fragile-delivery-pietermaritzburg",
    component: fragiledeliverypietermaritzburg,
  },
  { path: "/fragile-delivery-polokwane", component: fragiledeliverypolokwane },
  {
    path: "/fragile-delivery-port-elizabeth",
    component: fragiledeliveryportelizabeth,
  },
  {
    path: "/fragile-delivery-port-shepstone",
    component: fragiledeliveryportshepstone,
  },
  {
    path: "/fragile-delivery-potchefstroom",
    component: fragiledeliverypotchefstroom,
  },
  { path: "/fragile-delivery-pretoria", component: fragiledeliverypretoria },
  {
    path: "/fragile-delivery-queenstown",
    component: fragiledeliveryqueenstown,
  },
  { path: "/fragile-delivery-randburg", component: fragiledeliveryrandburg },
  {
    path: "/fragile-delivery-roodepoort",
    component: fragiledeliveryroodepoort,
  },
  {
    path: "/fragile-delivery-rustenburg",
    component: fragiledeliveryrustenburg,
  },
  { path: "/fragile-delivery-saldanha", component: fragiledeliverysaldanha },
  { path: "/fragile-delivery-sandton", component: fragiledeliverysandton },
  { path: "/fragile-delivery-soweto", component: fragiledeliverysoweto },
  { path: "/fragile-delivery-springs", component: fragiledeliverysprings },
  {
    path: "/fragile-delivery-standerton",
    component: fragiledeliverystanderton,
  },
  {
    path: "/fragile-delivery-thohoyandou",
    component: fragiledeliverythohoyandou,
  },
  { path: "/fragile-delivery-tzaneen", component: fragiledeliverytzaneen },
  { path: "/fragile-delivery-uitenhage", component: fragiledeliveryuitenhage },
  { path: "/fragile-delivery-ulundi", component: fragiledeliveryulundi },
  { path: "/fragile-delivery-umtata", component: fragiledeliveryumtata },
  { path: "/fragile-delivery-upington", component: fragiledeliveryupington },
  {
    path: "/fragile-delivery-vanderbijlpark",
    component: fragiledeliveryvanderbijlpark,
  },
  {
    path: "/fragile-delivery-vereeniging",
    component: fragiledeliveryvereeniging,
  },
  { path: "/fragile-delivery-volksrust", component: fragiledeliveryvolksrust },
  {
    path: "/fragile-delivery-vredenburg",
    component: fragiledeliveryvredenburg,
  },
  { path: "/fragile-delivery-vryburg", component: fragiledeliveryvryburg },
  { path: "/fragile-delivery-vryheid", component: fragiledeliveryvryheid },
  { path: "/fragile-delivery-welkom", component: fragiledeliverywelkom },
  { path: "/fragile-delivery-worcester", component: fragiledeliveryworcester },
  { path: "/freight-delivery-alberton", component: freightdeliveryalberton },
  {
    path: "/freight-delivery-aliwal-north",
    component: freightdeliveryaliwalnorth,
  },
  {
    path: "/freight-delivery-beaufort-west",
    component: freightdeliverybeaufortwest,
  },
  { path: "/freight-delivery-benoni", component: freightdeliverybenoni },
  { path: "/freight-delivery-bethal", component: freightdeliverybethal },
  { path: "/freight-delivery-bhisho", component: freightdeliverybhisho },
  {
    path: "/freight-delivery-bloemfontein",
    component: freightdeliverybloemfontein,
  },
  { path: "/freight-delivery-brakpan", component: freightdeliverybrakpan },
  { path: "/freight-delivery-brits", component: freightdeliverybrits },
  { path: "/freight-delivery-cape-town", component: freightdeliverycapetown },
  {
    path: "/freight-delivery-carletonville",
    component: freightdeliverycarletonville,
  },
  { path: "/freight-delivery-centurion", component: freightdeliverycenturion },
  { path: "/freight-delivery-cradock", component: freightdeliverycradock },
  { path: "/freight-delivery-de-aar", component: freightdeliverydeaar },
  { path: "/freight-delivery-durban", component: freightdeliverydurban },
  {
    path: "/freight-delivery-east-london",
    component: freightdeliveryeastlondon,
  },
  { path: "/freight-delivery-ferndale", component: freightdeliveryferndale },
  { path: "/freight-delivery-george", component: freightdeliverygeorge },
  {
    path: "/freight-delivery-graaff-reinet",
    component: freightdeliverygraaffreinet,
  },
  {
    path: "/freight-delivery-grahamstown",
    component: freightdeliverygrahamstown,
  },
  { path: "/freight-delivery-hermanus", component: freightdeliveryhermanus },
  {
    path: "/freight-delivery-jeffreys-bay",
    component: freightdeliveryjeffreysbay,
  },
  {
    path: "/freight-delivery-johannesburg",
    component: freightdeliveryjohannesburg,
  },
  { path: "/freight-delivery-kimberley", component: freightdeliverykimberley },
  {
    path: "/freight-delivery-klerksdorp",
    component: freightdeliveryklerksdorp,
  },
  { path: "/freight-delivery-knysna", component: freightdeliveryknysna },
  { path: "/freight-delivery-kroonstad", component: freightdeliverykroonstad },
  {
    path: "/freight-delivery-kuilsrivier",
    component: freightdeliverykuilsrivier,
  },
  { path: "/freight-delivery-ladysmith", component: freightdeliveryladysmith },
  {
    path: "/freight-delivery-lebowakgomo",
    component: freightdeliverylebowakgomo,
  },
  {
    path: "/freight-delivery-lichtenburg",
    component: freightdeliverylichtenburg,
  },
  { path: "/freight-delivery-mahikeng", component: freightdeliverymahikeng },
  {
    path: "/freight-delivery-malmesbury",
    component: freightdeliverymalmesbury,
  },
  {
    path: "/freight-delivery-middelburg",
    component: freightdeliverymiddelburg,
  },
  { path: "/freight-delivery-midrand", component: freightdeliverymidrand },
  { path: "/freight-delivery-mmabatho", component: freightdeliverymmabatho },
  { path: "/freight-delivery-mossel-bay", component: freightdeliverymosselbay },
  { path: "/freight-delivery-musina", component: freightdeliverymusina },
  { path: "/freight-delivery-nelspruit", component: freightdeliverynelspruit },
  {
    path: "/freight-delivery-oudtshoorn",
    component: freightdeliveryoudtshoorn,
  },
  { path: "/freight-delivery-paarl", component: freightdeliverypaarl },
  {
    path: "/freight-delivery-pietermaritzburg",
    component: freightdeliverypietermaritzburg,
  },
  { path: "/freight-delivery-polokwane", component: freightdeliverypolokwane },
  {
    path: "/freight-delivery-port-elizabeth",
    component: freightdeliveryportelizabeth,
  },
  {
    path: "/freight-delivery-port-shepstone",
    component: freightdeliveryportshepstone,
  },
  {
    path: "/freight-delivery-potchefstroom",
    component: freightdeliverypotchefstroom,
  },
  { path: "/freight-delivery-pretoria", component: freightdeliverypretoria },
  {
    path: "/freight-delivery-queenstown",
    component: freightdeliveryqueenstown,
  },
  { path: "/freight-delivery-randburg", component: freightdeliveryrandburg },
  {
    path: "/freight-delivery-roodepoort",
    component: freightdeliveryroodepoort,
  },
  {
    path: "/freight-delivery-rustenburg",
    component: freightdeliveryrustenburg,
  },
  { path: "/freight-delivery-saldanha", component: freightdeliverysaldanha },
  { path: "/freight-delivery-sandton", component: freightdeliverysandton },
  { path: "/freight-delivery-soweto", component: freightdeliverysoweto },
  { path: "/freight-delivery-springs", component: freightdeliverysprings },
  {
    path: "/freight-delivery-standerton",
    component: freightdeliverystanderton,
  },
  {
    path: "/freight-delivery-thohoyandou",
    component: freightdeliverythohoyandou,
  },
  { path: "/freight-delivery-tzaneen", component: freightdeliverytzaneen },
  { path: "/freight-delivery-uitenhage", component: freightdeliveryuitenhage },
  { path: "/freight-delivery-ulundi", component: freightdeliveryulundi },
  { path: "/freight-delivery-umtata", component: freightdeliveryumtata },
  { path: "/freight-delivery-upington", component: freightdeliveryupington },
  {
    path: "/freight-delivery-vanderbijlpark",
    component: freightdeliveryvanderbijlpark,
  },
  {
    path: "/freight-delivery-vereeniging",
    component: freightdeliveryvereeniging,
  },
  { path: "/freight-delivery-volksrust", component: freightdeliveryvolksrust },
  {
    path: "/freight-delivery-vredenburg",
    component: freightdeliveryvredenburg,
  },
  { path: "/freight-delivery-vryburg", component: freightdeliveryvryburg },
  { path: "/freight-delivery-vryheid", component: freightdeliveryvryheid },
  { path: "/freight-delivery-welkom", component: freightdeliverywelkom },
  { path: "/freight-delivery-worcester", component: freightdeliveryworcester },
  {
    path: "/furniture-delivery-alberton",
    component: furnituredeliveryalberton,
  },
  {
    path: "/furniture-delivery-aliwal-north",
    component: furnituredeliveryaliwalnorth,
  },
  {
    path: "/furniture-delivery-beaufort-west",
    component: furnituredeliverybeaufortwest,
  },
  { path: "/furniture-delivery-benoni", component: furnituredeliverybenoni },
  { path: "/furniture-delivery-bethal", component: furnituredeliverybethal },
  { path: "/furniture-delivery-bhisho", component: furnituredeliverybhisho },
  {
    path: "/furniture-delivery-bloemfontein",
    component: furnituredeliverybloemfontein,
  },
  { path: "/furniture-delivery-brakpan", component: furnituredeliverybrakpan },
  { path: "/furniture-delivery-brits", component: furnituredeliverybrits },
  {
    path: "/furniture-delivery-cape-town",
    component: furnituredeliverycapetown,
  },
  {
    path: "/furniture-delivery-carletonville",
    component: furnituredeliverycarletonville,
  },
  {
    path: "/furniture-delivery-centurion",
    component: furnituredeliverycenturion,
  },
  { path: "/furniture-delivery-cradock", component: furnituredeliverycradock },
  { path: "/furniture-delivery-de-aar", component: furnituredeliverydeaar },
  { path: "/furniture-delivery-durban", component: furnituredeliverydurban },
  {
    path: "/furniture-delivery-east-london",
    component: furnituredeliveryeastlondon,
  },
  {
    path: "/furniture-delivery-ferndale",
    component: furnituredeliveryferndale,
  },
  { path: "/furniture-delivery-george", component: furnituredeliverygeorge },
  {
    path: "/furniture-delivery-graaff-reinet",
    component: furnituredeliverygraaffreinet,
  },
  {
    path: "/furniture-delivery-grahamstown",
    component: furnituredeliverygrahamstown,
  },
  {
    path: "/furniture-delivery-hermanus",
    component: furnituredeliveryhermanus,
  },
  {
    path: "/furniture-delivery-jeffreys-bay",
    component: furnituredeliveryjeffreysbay,
  },
  {
    path: "/furniture-delivery-johannesburg",
    component: furnituredeliveryjohannesburg,
  },
  {
    path: "/furniture-delivery-kimberley",
    component: furnituredeliverykimberley,
  },
  {
    path: "/furniture-delivery-klerksdorp",
    component: furnituredeliveryklerksdorp,
  },
  { path: "/furniture-delivery-knysna", component: furnituredeliveryknysna },
  {
    path: "/furniture-delivery-kroonstad",
    component: furnituredeliverykroonstad,
  },
  {
    path: "/furniture-delivery-kuilsrivier",
    component: furnituredeliverykuilsrivier,
  },
  {
    path: "/furniture-delivery-ladysmith",
    component: furnituredeliveryladysmith,
  },
  {
    path: "/furniture-delivery-lebowakgomo",
    component: furnituredeliverylebowakgomo,
  },
  {
    path: "/furniture-delivery-lichtenburg",
    component: furnituredeliverylichtenburg,
  },
  {
    path: "/furniture-delivery-mahikeng",
    component: furnituredeliverymahikeng,
  },
  {
    path: "/furniture-delivery-malmesbury",
    component: furnituredeliverymalmesbury,
  },
  {
    path: "/furniture-delivery-middelburg",
    component: furnituredeliverymiddelburg,
  },
  { path: "/furniture-delivery-midrand", component: furnituredeliverymidrand },
  {
    path: "/furniture-delivery-mmabatho",
    component: furnituredeliverymmabatho,
  },
  {
    path: "/furniture-delivery-mossel-bay",
    component: furnituredeliverymosselbay,
  },
  { path: "/furniture-delivery-musina", component: furnituredeliverymusina },
  {
    path: "/furniture-delivery-nelspruit",
    component: furnituredeliverynelspruit,
  },
  {
    path: "/furniture-delivery-oudtshoorn",
    component: furnituredeliveryoudtshoorn,
  },
  { path: "/furniture-delivery-paarl", component: furnituredeliverypaarl },
  {
    path: "/furniture-delivery-pietermaritzburg",
    component: furnituredeliverypietermaritzburg,
  },
  {
    path: "/furniture-delivery-polokwane",
    component: furnituredeliverypolokwane,
  },
  {
    path: "/furniture-delivery-port-elizabeth",
    component: furnituredeliveryportelizabeth,
  },
  {
    path: "/furniture-delivery-port-shepstone",
    component: furnituredeliveryportshepstone,
  },
  {
    path: "/furniture-delivery-potchefstroom",
    component: furnituredeliverypotchefstroom,
  },
  {
    path: "/furniture-delivery-pretoria",
    component: furnituredeliverypretoria,
  },
  {
    path: "/furniture-delivery-queenstown",
    component: furnituredeliveryqueenstown,
  },
  {
    path: "/furniture-delivery-randburg",
    component: furnituredeliveryrandburg,
  },
  {
    path: "/furniture-delivery-roodepoort",
    component: furnituredeliveryroodepoort,
  },
  {
    path: "/furniture-delivery-rustenburg",
    component: furnituredeliveryrustenburg,
  },
  {
    path: "/furniture-delivery-saldanha",
    component: furnituredeliverysaldanha,
  },
  { path: "/furniture-delivery-sandton", component: furnituredeliverysandton },
  { path: "/furniture-delivery-soweto", component: furnituredeliverysoweto },
  { path: "/furniture-delivery-springs", component: furnituredeliverysprings },
  {
    path: "/furniture-delivery-standerton",
    component: furnituredeliverystanderton,
  },
  {
    path: "/furniture-delivery-thohoyandou",
    component: furnituredeliverythohoyandou,
  },
  { path: "/furniture-delivery-tzaneen", component: furnituredeliverytzaneen },
  {
    path: "/furniture-delivery-uitenhage",
    component: furnituredeliveryuitenhage,
  },
  { path: "/furniture-delivery-ulundi", component: furnituredeliveryulundi },
  { path: "/furniture-delivery-umtata", component: furnituredeliveryumtata },
  {
    path: "/furniture-delivery-upington",
    component: furnituredeliveryupington,
  },
  {
    path: "/furniture-delivery-vanderbijlpark",
    component: furnituredeliveryvanderbijlpark,
  },
  {
    path: "/furniture-delivery-vereeniging",
    component: furnituredeliveryvereeniging,
  },
  {
    path: "/furniture-delivery-volksrust",
    component: furnituredeliveryvolksrust,
  },
  {
    path: "/furniture-delivery-vredenburg",
    component: furnituredeliveryvredenburg,
  },
  { path: "/furniture-delivery-vryburg", component: furnituredeliveryvryburg },
  { path: "/furniture-delivery-vryheid", component: furnituredeliveryvryheid },
  { path: "/furniture-delivery-welkom", component: furnituredeliverywelkom },
  {
    path: "/furniture-delivery-worcester",
    component: furnituredeliveryworcester,
  },
  { path: "/furniture-movers-alberton", component: furnituremoversalberton },
  {
    path: "/furniture-movers-aliwal-north",
    component: furnituremoversaliwalnorth,
  },
  {
    path: "/furniture-movers-beaufort-west",
    component: furnituremoversbeaufortwest,
  },
  { path: "/furniture-movers-benoni", component: furnituremoversbenoni },
  { path: "/furniture-movers-bethal", component: furnituremoversbethal },
  { path: "/furniture-movers-bhisho", component: furnituremoversbhisho },
  {
    path: "/furniture-movers-bloemfontein",
    component: furnituremoversbloemfontein,
  },
  { path: "/furniture-movers-brakpan", component: furnituremoversbrakpan },
  { path: "/furniture-movers-brits", component: furnituremoversbrits },
  { path: "/furniture-movers-cape-town", component: furnituremoverscapetown },
  {
    path: "/furniture-movers-carletonville",
    component: furnituremoverscarletonville,
  },
  { path: "/furniture-movers-centurion", component: furnituremoverscenturion },
  { path: "/furniture-movers-cradock", component: furnituremoverscradock },
  { path: "/furniture-movers-de-aar", component: furnituremoversdeaar },
  { path: "/furniture-movers-durban", component: furnituremoversdurban },
  {
    path: "/furniture-movers-east-london",
    component: furnituremoverseastlondon,
  },
  { path: "/furniture-movers-ferndale", component: furnituremoversferndale },
  { path: "/furniture-movers-george", component: furnituremoversgeorge },
  {
    path: "/furniture-movers-graaff-reinet",
    component: furnituremoversgraaffreinet,
  },
  {
    path: "/furniture-movers-grahamstown",
    component: furnituremoversgrahamstown,
  },
  { path: "/furniture-movers-hermanus", component: furnituremovershermanus },
  {
    path: "/furniture-movers-jeffreys-bay",
    component: furnituremoversjeffreysbay,
  },
  {
    path: "/furniture-movers-johannesburg",
    component: furnituremoversjohannesburg,
  },
  { path: "/furniture-movers-kimberley", component: furnituremoverskimberley },
  {
    path: "/furniture-movers-klerksdorp",
    component: furnituremoversklerksdorp,
  },
  { path: "/furniture-movers-knysna", component: furnituremoversknysna },
  { path: "/furniture-movers-kroonstad", component: furnituremoverskroonstad },
  {
    path: "/furniture-movers-kuilsrivier",
    component: furnituremoverskuilsrivier,
  },
  { path: "/furniture-movers-ladysmith", component: furnituremoversladysmith },
  {
    path: "/furniture-movers-lebowakgomo",
    component: furnituremoverslebowakgomo,
  },
  {
    path: "/furniture-movers-lichtenburg",
    component: furnituremoverslichtenburg,
  },
  { path: "/furniture-movers-mahikeng", component: furnituremoversmahikeng },
  {
    path: "/furniture-movers-malmesbury",
    component: furnituremoversmalmesbury,
  },
  {
    path: "/furniture-movers-middelburg",
    component: furnituremoversmiddelburg,
  },
  { path: "/furniture-movers-midrand", component: furnituremoversmidrand },
  { path: "/furniture-movers-mmabatho", component: furnituremoversmmabatho },
  { path: "/furniture-movers-mossel-bay", component: furnituremoversmosselbay },
  { path: "/furniture-movers-musina", component: furnituremoversmusina },
  { path: "/furniture-movers-nelspruit", component: furnituremoversnelspruit },
  {
    path: "/furniture-movers-oudtshoorn",
    component: furnituremoversoudtshoorn,
  },
  { path: "/furniture-movers-paarl", component: furnituremoverspaarl },
  {
    path: "/furniture-movers-pietermaritzburg",
    component: furnituremoverspietermaritzburg,
  },
  { path: "/furniture-movers-polokwane", component: furnituremoverspolokwane },
  {
    path: "/furniture-movers-port-elizabeth",
    component: furnituremoversportelizabeth,
  },
  {
    path: "/furniture-movers-port-shepstone",
    component: furnituremoversportshepstone,
  },
  {
    path: "/furniture-movers-potchefstroom",
    component: furnituremoverspotchefstroom,
  },
  { path: "/furniture-movers-pretoria", component: furnituremoverspretoria },
  {
    path: "/furniture-movers-queenstown",
    component: furnituremoversqueenstown,
  },
  { path: "/furniture-movers-randburg", component: furnituremoversrandburg },
  {
    path: "/furniture-movers-roodepoort",
    component: furnituremoversroodepoort,
  },
  {
    path: "/furniture-movers-rustenburg",
    component: furnituremoversrustenburg,
  },
  { path: "/furniture-movers-saldanha", component: furnituremoverssaldanha },
  { path: "/furniture-movers-sandton", component: furnituremoverssandton },
  { path: "/furniture-movers-soweto", component: furnituremoverssoweto },
  { path: "/furniture-movers-springs", component: furnituremoverssprings },
  {
    path: "/furniture-movers-standerton",
    component: furnituremoversstanderton,
  },
  {
    path: "/furniture-movers-thohoyandou",
    component: furnituremoversthohoyandou,
  },
  { path: "/furniture-movers-tzaneen", component: furnituremoverstzaneen },
  { path: "/furniture-movers-uitenhage", component: furnituremoversuitenhage },
  { path: "/furniture-movers-ulundi", component: furnituremoversulundi },
  { path: "/furniture-movers-umtata", component: furnituremoversumtata },
  { path: "/furniture-movers-upington", component: furnituremoversupington },
  {
    path: "/furniture-movers-vanderbijlpark",
    component: furnituremoversvanderbijlpark,
  },
  {
    path: "/furniture-movers-vereeniging",
    component: furnituremoversvereeniging,
  },
  { path: "/furniture-movers-volksrust", component: furnituremoversvolksrust },
  {
    path: "/furniture-movers-vredenburg",
    component: furnituremoversvredenburg,
  },
  { path: "/furniture-movers-vryburg", component: furnituremoversvryburg },
  { path: "/furniture-movers-vryheid", component: furnituremoversvryheid },
  { path: "/furniture-movers-welkom", component: furnituremoverswelkom },
  { path: "/furniture-movers-worcester", component: furnituremoversworcester },
  { path: "/goods-transport-alberton", component: goodstransportalberton },
  {
    path: "/goods-transport-aliwal-north",
    component: goodstransportaliwalnorth,
  },
  {
    path: "/goods-transport-beaufort-west",
    component: goodstransportbeaufortwest,
  },
  { path: "/goods-transport-benoni", component: goodstransportbenoni },
  { path: "/goods-transport-bethal", component: goodstransportbethal },
  { path: "/goods-transport-bhisho", component: goodstransportbhisho },
  {
    path: "/goods-transport-bloemfontein",
    component: goodstransportbloemfontein,
  },
  { path: "/goods-transport-brakpan", component: goodstransportbrakpan },
  { path: "/goods-transport-brits", component: goodstransportbrits },
  { path: "/goods-transport-cape-town", component: goodstransportcapetown },
  {
    path: "/goods-transport-carletonville",
    component: goodstransportcarletonville,
  },
  { path: "/goods-transport-centurion", component: goodstransportcenturion },
  { path: "/goods-transport-cradock", component: goodstransportcradock },
  { path: "/goods-transport-de-aar", component: goodstransportdeaar },
  { path: "/goods-transport-durban", component: goodstransportdurban },
  { path: "/goods-transport-east-london", component: goodstransporteastlondon },
  { path: "/goods-transport-ferndale", component: goodstransportferndale },
  { path: "/goods-transport-george", component: goodstransportgeorge },
  {
    path: "/goods-transport-graaff-reinet",
    component: goodstransportgraaffreinet,
  },
  {
    path: "/goods-transport-grahamstown",
    component: goodstransportgrahamstown,
  },
  { path: "/goods-transport-hermanus", component: goodstransporthermanus },
  {
    path: "/goods-transport-jeffreys-bay",
    component: goodstransportjeffreysbay,
  },
  {
    path: "/goods-transport-johannesburg",
    component: goodstransportjohannesburg,
  },
  { path: "/goods-transport-kimberley", component: goodstransportkimberley },
  { path: "/goods-transport-klerksdorp", component: goodstransportklerksdorp },
  { path: "/goods-transport-knysna", component: goodstransportknysna },
  { path: "/goods-transport-kroonstad", component: goodstransportkroonstad },
  {
    path: "/goods-transport-kuilsrivier",
    component: goodstransportkuilsrivier,
  },
  { path: "/goods-transport-ladysmith", component: goodstransportladysmith },
  {
    path: "/goods-transport-lebowakgomo",
    component: goodstransportlebowakgomo,
  },
  {
    path: "/goods-transport-lichtenburg",
    component: goodstransportlichtenburg,
  },
  { path: "/goods-transport-mahikeng", component: goodstransportmahikeng },
  { path: "/goods-transport-malmesbury", component: goodstransportmalmesbury },
  { path: "/goods-transport-middelburg", component: goodstransportmiddelburg },
  { path: "/goods-transport-midrand", component: goodstransportmidrand },
  { path: "/goods-transport-mmabatho", component: goodstransportmmabatho },
  { path: "/goods-transport-mossel-bay", component: goodstransportmosselbay },
  { path: "/goods-transport-musina", component: goodstransportmusina },
  { path: "/goods-transport-nelspruit", component: goodstransportnelspruit },
  { path: "/goods-transport-oudtshoorn", component: goodstransportoudtshoorn },
  { path: "/goods-transport-paarl", component: goodstransportpaarl },
  {
    path: "/goods-transport-pietermaritzburg",
    component: goodstransportpietermaritzburg,
  },
  { path: "/goods-transport-polokwane", component: goodstransportpolokwane },
  {
    path: "/goods-transport-port-elizabeth",
    component: goodstransportportelizabeth,
  },
  {
    path: "/goods-transport-port-shepstone",
    component: goodstransportportshepstone,
  },
  {
    path: "/goods-transport-potchefstroom",
    component: goodstransportpotchefstroom,
  },
  { path: "/goods-transport-pretoria", component: goodstransportpretoria },
  { path: "/goods-transport-queenstown", component: goodstransportqueenstown },
  { path: "/goods-transport-randburg", component: goodstransportrandburg },
  { path: "/goods-transport-roodepoort", component: goodstransportroodepoort },
  { path: "/goods-transport-rustenburg", component: goodstransportrustenburg },
  { path: "/goods-transport-saldanha", component: goodstransportsaldanha },
  { path: "/goods-transport-sandton", component: goodstransportsandton },
  { path: "/goods-transport-soweto", component: goodstransportsoweto },
  { path: "/goods-transport-springs", component: goodstransportsprings },
  { path: "/goods-transport-standerton", component: goodstransportstanderton },
  {
    path: "/goods-transport-thohoyandou",
    component: goodstransportthohoyandou,
  },
  { path: "/goods-transport-tzaneen", component: goodstransporttzaneen },
  { path: "/goods-transport-uitenhage", component: goodstransportuitenhage },
  { path: "/goods-transport-ulundi", component: goodstransportulundi },
  { path: "/goods-transport-umtata", component: goodstransportumtata },
  { path: "/goods-transport-upington", component: goodstransportupington },
  {
    path: "/goods-transport-vanderbijlpark",
    component: goodstransportvanderbijlpark,
  },
  {
    path: "/goods-transport-vereeniging",
    component: goodstransportvereeniging,
  },
  { path: "/goods-transport-volksrust", component: goodstransportvolksrust },
  { path: "/goods-transport-vredenburg", component: goodstransportvredenburg },
  { path: "/goods-transport-vryburg", component: goodstransportvryburg },
  { path: "/goods-transport-vryheid", component: goodstransportvryheid },
  { path: "/goods-transport-welkom", component: goodstransportwelkom },
  { path: "/goods-transport-worcester", component: goodstransportworcester },
  { path: "/h100-alberton", component: h100alberton },
  { path: "/h100-aliwal-north", component: h100aliwalnorth },
  { path: "/h100-beaufort-west", component: h100beaufortwest },
  { path: "/h100-benoni", component: h100benoni },
  { path: "/h100-bethal", component: h100bethal },
  { path: "/h100-bhisho", component: h100bhisho },
  { path: "/h100-bloemfontein", component: h100bloemfontein },
  { path: "/h100-brakpan", component: h100brakpan },
  { path: "/h100-brits", component: h100brits },
  { path: "/h100-cape-town", component: h100capetown },
  { path: "/h100-carletonville", component: h100carletonville },
  { path: "/h100-centurion", component: h100centurion },
  { path: "/h100-cradock", component: h100cradock },
  { path: "/h100-de-aar", component: h100deaar },
  { path: "/h100-durban", component: h100durban },
  { path: "/h100-east-london", component: h100eastlondon },
  { path: "/h100-ferndale", component: h100ferndale },
  { path: "/h100-george", component: h100george },
  { path: "/h100-graaff-reinet", component: h100graaffreinet },
  { path: "/h100-grahamstown", component: h100grahamstown },
  { path: "/h100-hermanus", component: h100hermanus },
  { path: "/h100-jeffreys-bay", component: h100jeffreysbay },
  { path: "/h100-johannesburg", component: h100johannesburg },
  { path: "/h100-kimberley", component: h100kimberley },
  { path: "/h100-klerksdorp", component: h100klerksdorp },
  { path: "/h100-knysna", component: h100knysna },
  { path: "/h100-kroonstad", component: h100kroonstad },
  { path: "/h100-kuilsrivier", component: h100kuilsrivier },
  { path: "/h100-ladysmith", component: h100ladysmith },
  { path: "/h100-lebowakgomo", component: h100lebowakgomo },
  { path: "/h100-lichtenburg", component: h100lichtenburg },
  { path: "/h100-mahikeng", component: h100mahikeng },
  { path: "/h100-malmesbury", component: h100malmesbury },
  { path: "/h100-middelburg", component: h100middelburg },
  { path: "/h100-midrand", component: h100midrand },
  { path: "/h100-mmabatho", component: h100mmabatho },
  { path: "/h100-mossel-bay", component: h100mosselbay },
  { path: "/h100-musina", component: h100musina },
  { path: "/h100-nelspruit", component: h100nelspruit },
  { path: "/h100-oudtshoorn", component: h100oudtshoorn },
  { path: "/h100-paarl", component: h100paarl },
  { path: "/h100-pietermaritzburg", component: h100pietermaritzburg },
  { path: "/h100-polokwane", component: h100polokwane },
  { path: "/h100-port-elizabeth", component: h100portelizabeth },
  { path: "/h100-port-shepstone", component: h100portshepstone },
  { path: "/h100-potchefstroom", component: h100potchefstroom },
  { path: "/h100-pretoria", component: h100pretoria },
  { path: "/h100-queenstown", component: h100queenstown },
  { path: "/h100-randburg", component: h100randburg },
  { path: "/h100-roodepoort", component: h100roodepoort },
  { path: "/h100-rustenburg", component: h100rustenburg },
  { path: "/h100-saldanha", component: h100saldanha },
  { path: "/h100-sandton", component: h100sandton },
  { path: "/h100-soweto", component: h100soweto },
  { path: "/h100-springs", component: h100springs },
  { path: "/h100-standerton", component: h100standerton },
  { path: "/h100-thohoyandou", component: h100thohoyandou },
  { path: "/h100-tzaneen", component: h100tzaneen },
  { path: "/h100-uitenhage", component: h100uitenhage },
  { path: "/h100-ulundi", component: h100ulundi },
  { path: "/h100-umtata", component: h100umtata },
  { path: "/h100-upington", component: h100upington },
  { path: "/h100-vanderbijlpark", component: h100vanderbijlpark },
  { path: "/h100-vereeniging", component: h100vereeniging },
  { path: "/h100-volksrust", component: h100volksrust },
  { path: "/h100-vredenburg", component: h100vredenburg },
  { path: "/h100-vryburg", component: h100vryburg },
  { path: "/h100-vryheid", component: h100vryheid },
  { path: "/h100-welkom", component: h100welkom },
  { path: "/h100-worcester", component: h100worcester },
  {
    path: "/heavy-equipment-delivery-alberton",
    component: heavyequipmentdeliveryalberton,
  },
  {
    path: "/heavy-equipment-delivery-aliwal-north",
    component: heavyequipmentdeliveryaliwalnorth,
  },
  {
    path: "/heavy-equipment-delivery-beaufort-west",
    component: heavyequipmentdeliverybeaufortwest,
  },
  {
    path: "/heavy-equipment-delivery-benoni",
    component: heavyequipmentdeliverybenoni,
  },
  {
    path: "/heavy-equipment-delivery-bethal",
    component: heavyequipmentdeliverybethal,
  },
  {
    path: "/heavy-equipment-delivery-bhisho",
    component: heavyequipmentdeliverybhisho,
  },
  {
    path: "/heavy-equipment-delivery-bloemfontein",
    component: heavyequipmentdeliverybloemfontein,
  },
  {
    path: "/heavy-equipment-delivery-brakpan",
    component: heavyequipmentdeliverybrakpan,
  },
  {
    path: "/heavy-equipment-delivery-brits",
    component: heavyequipmentdeliverybrits,
  },
  {
    path: "/heavy-equipment-delivery-cape-town",
    component: heavyequipmentdeliverycapetown,
  },
  {
    path: "/heavy-equipment-delivery-carletonville",
    component: heavyequipmentdeliverycarletonville,
  },
  {
    path: "/heavy-equipment-delivery-centurion",
    component: heavyequipmentdeliverycenturion,
  },
  {
    path: "/heavy-equipment-delivery-cradock",
    component: heavyequipmentdeliverycradock,
  },
  {
    path: "/heavy-equipment-delivery-de-aar",
    component: heavyequipmentdeliverydeaar,
  },
  {
    path: "/heavy-equipment-delivery-durban",
    component: heavyequipmentdeliverydurban,
  },
  {
    path: "/heavy-equipment-delivery-east-london",
    component: heavyequipmentdeliveryeastlondon,
  },
  {
    path: "/heavy-equipment-delivery-ferndale",
    component: heavyequipmentdeliveryferndale,
  },
  {
    path: "/heavy-equipment-delivery-george",
    component: heavyequipmentdeliverygeorge,
  },
  {
    path: "/heavy-equipment-delivery-graaff-reinet",
    component: heavyequipmentdeliverygraaffreinet,
  },
  {
    path: "/heavy-equipment-delivery-grahamstown",
    component: heavyequipmentdeliverygrahamstown,
  },
  {
    path: "/heavy-equipment-delivery-hermanus",
    component: heavyequipmentdeliveryhermanus,
  },
  {
    path: "/heavy-equipment-delivery-jeffreys-bay",
    component: heavyequipmentdeliveryjeffreysbay,
  },
  {
    path: "/heavy-equipment-delivery-johannesburg",
    component: heavyequipmentdeliveryjohannesburg,
  },
  {
    path: "/heavy-equipment-delivery-kimberley",
    component: heavyequipmentdeliverykimberley,
  },
  {
    path: "/heavy-equipment-delivery-klerksdorp",
    component: heavyequipmentdeliveryklerksdorp,
  },
  {
    path: "/heavy-equipment-delivery-knysna",
    component: heavyequipmentdeliveryknysna,
  },
  {
    path: "/heavy-equipment-delivery-kroonstad",
    component: heavyequipmentdeliverykroonstad,
  },
  {
    path: "/heavy-equipment-delivery-kuilsrivier",
    component: heavyequipmentdeliverykuilsrivier,
  },
  {
    path: "/heavy-equipment-delivery-ladysmith",
    component: heavyequipmentdeliveryladysmith,
  },
  {
    path: "/heavy-equipment-delivery-lebowakgomo",
    component: heavyequipmentdeliverylebowakgomo,
  },
  {
    path: "/heavy-equipment-delivery-lichtenburg",
    component: heavyequipmentdeliverylichtenburg,
  },
  {
    path: "/heavy-equipment-delivery-mahikeng",
    component: heavyequipmentdeliverymahikeng,
  },
  {
    path: "/heavy-equipment-delivery-malmesbury",
    component: heavyequipmentdeliverymalmesbury,
  },
  {
    path: "/heavy-equipment-delivery-middelburg",
    component: heavyequipmentdeliverymiddelburg,
  },
  {
    path: "/heavy-equipment-delivery-midrand",
    component: heavyequipmentdeliverymidrand,
  },
  {
    path: "/heavy-equipment-delivery-mmabatho",
    component: heavyequipmentdeliverymmabatho,
  },
  {
    path: "/heavy-equipment-delivery-mossel-bay",
    component: heavyequipmentdeliverymosselbay,
  },
  {
    path: "/heavy-equipment-delivery-musina",
    component: heavyequipmentdeliverymusina,
  },
  {
    path: "/heavy-equipment-delivery-nelspruit",
    component: heavyequipmentdeliverynelspruit,
  },
  {
    path: "/heavy-equipment-delivery-oudtshoorn",
    component: heavyequipmentdeliveryoudtshoorn,
  },
  {
    path: "/heavy-equipment-delivery-paarl",
    component: heavyequipmentdeliverypaarl,
  },
  {
    path: "/heavy-equipment-delivery-pietermaritzburg",
    component: heavyequipmentdeliverypietermaritzburg,
  },
  {
    path: "/heavy-equipment-delivery-polokwane",
    component: heavyequipmentdeliverypolokwane,
  },
  {
    path: "/heavy-equipment-delivery-port-elizabeth",
    component: heavyequipmentdeliveryportelizabeth,
  },
  {
    path: "/heavy-equipment-delivery-port-shepstone",
    component: heavyequipmentdeliveryportshepstone,
  },
  {
    path: "/heavy-equipment-delivery-potchefstroom",
    component: heavyequipmentdeliverypotchefstroom,
  },
  {
    path: "/heavy-equipment-delivery-pretoria",
    component: heavyequipmentdeliverypretoria,
  },
  {
    path: "/heavy-equipment-delivery-queenstown",
    component: heavyequipmentdeliveryqueenstown,
  },
  {
    path: "/heavy-equipment-delivery-randburg",
    component: heavyequipmentdeliveryrandburg,
  },
  {
    path: "/heavy-equipment-delivery-roodepoort",
    component: heavyequipmentdeliveryroodepoort,
  },
  {
    path: "/heavy-equipment-delivery-rustenburg",
    component: heavyequipmentdeliveryrustenburg,
  },
  {
    path: "/heavy-equipment-delivery-saldanha",
    component: heavyequipmentdeliverysaldanha,
  },
  {
    path: "/heavy-equipment-delivery-sandton",
    component: heavyequipmentdeliverysandton,
  },
  {
    path: "/heavy-equipment-delivery-soweto",
    component: heavyequipmentdeliverysoweto,
  },
  {
    path: "/heavy-equipment-delivery-springs",
    component: heavyequipmentdeliverysprings,
  },
  {
    path: "/heavy-equipment-delivery-standerton",
    component: heavyequipmentdeliverystanderton,
  },
  {
    path: "/heavy-equipment-delivery-thohoyandou",
    component: heavyequipmentdeliverythohoyandou,
  },
  {
    path: "/heavy-equipment-delivery-tzaneen",
    component: heavyequipmentdeliverytzaneen,
  },
  {
    path: "/heavy-equipment-delivery-uitenhage",
    component: heavyequipmentdeliveryuitenhage,
  },
  {
    path: "/heavy-equipment-delivery-ulundi",
    component: heavyequipmentdeliveryulundi,
  },
  {
    path: "/heavy-equipment-delivery-umtata",
    component: heavyequipmentdeliveryumtata,
  },
  {
    path: "/heavy-equipment-delivery-upington",
    component: heavyequipmentdeliveryupington,
  },
  {
    path: "/heavy-equipment-delivery-vanderbijlpark",
    component: heavyequipmentdeliveryvanderbijlpark,
  },
  {
    path: "/heavy-equipment-delivery-vereeniging",
    component: heavyequipmentdeliveryvereeniging,
  },
  {
    path: "/heavy-equipment-delivery-volksrust",
    component: heavyequipmentdeliveryvolksrust,
  },
  {
    path: "/heavy-equipment-delivery-vredenburg",
    component: heavyequipmentdeliveryvredenburg,
  },
  {
    path: "/heavy-equipment-delivery-vryburg",
    component: heavyequipmentdeliveryvryburg,
  },
  {
    path: "/heavy-equipment-delivery-vryheid",
    component: heavyequipmentdeliveryvryheid,
  },
  {
    path: "/heavy-equipment-delivery-welkom",
    component: heavyequipmentdeliverywelkom,
  },
  {
    path: "/heavy-equipment-delivery-worcester",
    component: heavyequipmentdeliveryworcester,
  },
  { path: "/local-delivery-alberton", component: localdeliveryalberton },
  { path: "/local-delivery-aliwal-north", component: localdeliveryaliwalnorth },
  {
    path: "/local-delivery-beaufort-west",
    component: localdeliverybeaufortwest,
  },
  { path: "/local-delivery-benoni", component: localdeliverybenoni },
  { path: "/local-delivery-bethal", component: localdeliverybethal },
  { path: "/local-delivery-bhisho", component: localdeliverybhisho },
  {
    path: "/local-delivery-bloemfontein",
    component: localdeliverybloemfontein,
  },
  { path: "/local-delivery-brakpan", component: localdeliverybrakpan },
  { path: "/local-delivery-brits", component: localdeliverybrits },
  { path: "/local-delivery-cape-town", component: localdeliverycapetown },
  {
    path: "/local-delivery-carletonville",
    component: localdeliverycarletonville,
  },
  { path: "/local-delivery-centurion", component: localdeliverycenturion },
  { path: "/local-delivery-cradock", component: localdeliverycradock },
  { path: "/local-delivery-de-aar", component: localdeliverydeaar },
  { path: "/local-delivery-durban", component: localdeliverydurban },
  { path: "/local-delivery-east-london", component: localdeliveryeastlondon },
  { path: "/local-delivery-ferndale", component: localdeliveryferndale },
  { path: "/local-delivery-george", component: localdeliverygeorge },
  {
    path: "/local-delivery-graaff-reinet",
    component: localdeliverygraaffreinet,
  },
  { path: "/local-delivery-grahamstown", component: localdeliverygrahamstown },
  { path: "/local-delivery-hermanus", component: localdeliveryhermanus },
  { path: "/local-delivery-jeffreys-bay", component: localdeliveryjeffreysbay },
  {
    path: "/local-delivery-johannesburg",
    component: localdeliveryjohannesburg,
  },
  { path: "/local-delivery-kimberley", component: localdeliverykimberley },
  { path: "/local-delivery-klerksdorp", component: localdeliveryklerksdorp },
  { path: "/local-delivery-knysna", component: localdeliveryknysna },
  { path: "/local-delivery-kroonstad", component: localdeliverykroonstad },
  { path: "/local-delivery-kuilsrivier", component: localdeliverykuilsrivier },
  { path: "/local-delivery-ladysmith", component: localdeliveryladysmith },
  { path: "/local-delivery-lebowakgomo", component: localdeliverylebowakgomo },
  { path: "/local-delivery-lichtenburg", component: localdeliverylichtenburg },
  { path: "/local-delivery-mahikeng", component: localdeliverymahikeng },
  { path: "/local-delivery-malmesbury", component: localdeliverymalmesbury },
  { path: "/local-delivery-middelburg", component: localdeliverymiddelburg },
  { path: "/local-delivery-midrand", component: localdeliverymidrand },
  { path: "/local-delivery-mmabatho", component: localdeliverymmabatho },
  { path: "/local-delivery-mossel-bay", component: localdeliverymosselbay },
  { path: "/local-delivery-musina", component: localdeliverymusina },
  { path: "/local-delivery-nelspruit", component: localdeliverynelspruit },
  { path: "/local-delivery-oudtshoorn", component: localdeliveryoudtshoorn },
  { path: "/local-delivery-paarl", component: localdeliverypaarl },
  {
    path: "/local-delivery-pietermaritzburg",
    component: localdeliverypietermaritzburg,
  },
  { path: "/local-delivery-polokwane", component: localdeliverypolokwane },
  {
    path: "/local-delivery-port-elizabeth",
    component: localdeliveryportelizabeth,
  },
  {
    path: "/local-delivery-port-shepstone",
    component: localdeliveryportshepstone,
  },
  {
    path: "/local-delivery-potchefstroom",
    component: localdeliverypotchefstroom,
  },
  { path: "/local-delivery-pretoria", component: localdeliverypretoria },
  { path: "/local-delivery-queenstown", component: localdeliveryqueenstown },
  { path: "/local-delivery-randburg", component: localdeliveryrandburg },
  { path: "/local-delivery-roodepoort", component: localdeliveryroodepoort },
  { path: "/local-delivery-rustenburg", component: localdeliveryrustenburg },
  { path: "/local-delivery-saldanha", component: localdeliverysaldanha },
  { path: "/local-delivery-sandton", component: localdeliverysandton },
  { path: "/local-delivery-soweto", component: localdeliverysoweto },
  { path: "/local-delivery-springs", component: localdeliverysprings },
  { path: "/local-delivery-standerton", component: localdeliverystanderton },
  { path: "/local-delivery-thohoyandou", component: localdeliverythohoyandou },
  { path: "/local-delivery-tzaneen", component: localdeliverytzaneen },
  { path: "/local-delivery-uitenhage", component: localdeliveryuitenhage },
  { path: "/local-delivery-ulundi", component: localdeliveryulundi },
  { path: "/local-delivery-umtata", component: localdeliveryumtata },
  { path: "/local-delivery-upington", component: localdeliveryupington },
  {
    path: "/local-delivery-vanderbijlpark",
    component: localdeliveryvanderbijlpark,
  },
  { path: "/local-delivery-vereeniging", component: localdeliveryvereeniging },
  { path: "/local-delivery-volksrust", component: localdeliveryvolksrust },
  { path: "/local-delivery-vredenburg", component: localdeliveryvredenburg },
  { path: "/local-delivery-vryburg", component: localdeliveryvryburg },
  { path: "/local-delivery-vryheid", component: localdeliveryvryheid },
  { path: "/local-delivery-welkom", component: localdeliverywelkom },
  { path: "/local-delivery-worcester", component: localdeliveryworcester },
  {
    path: "/local-movers-near-me-alberton",
    component: localmoversnearmealberton,
  },
  {
    path: "/local-movers-near-me-aliwal-north",
    component: localmoversnearmealiwalnorth,
  },
  {
    path: "/local-movers-near-me-beaufort-west",
    component: localmoversnearmebeaufortwest,
  },
  { path: "/local-movers-near-me-benoni", component: localmoversnearmebenoni },
  { path: "/local-movers-near-me-bethal", component: localmoversnearmebethal },
  { path: "/local-movers-near-me-bhisho", component: localmoversnearmebhisho },
  {
    path: "/local-movers-near-me-bloemfontein",
    component: localmoversnearmebloemfontein,
  },
  {
    path: "/local-movers-near-me-brakpan",
    component: localmoversnearmebrakpan,
  },
  { path: "/local-movers-near-me-brits", component: localmoversnearmebrits },
  {
    path: "/local-movers-near-me-cape-town",
    component: localmoversnearmecapetown,
  },
  {
    path: "/local-movers-near-me-carletonville",
    component: localmoversnearmecarletonville,
  },
  {
    path: "/local-movers-near-me-centurion",
    component: localmoversnearmecenturion,
  },
  {
    path: "/local-movers-near-me-cradock",
    component: localmoversnearmecradock,
  },
  { path: "/local-movers-near-me-de-aar", component: localmoversnearmedeaar },
  { path: "/local-movers-near-me-durban", component: localmoversnearmedurban },
  {
    path: "/local-movers-near-me-east-london",
    component: localmoversnearmeeastlondon,
  },
  {
    path: "/local-movers-near-me-ferndale",
    component: localmoversnearmeferndale,
  },
  { path: "/local-movers-near-me-george", component: localmoversnearmegeorge },
  {
    path: "/local-movers-near-me-graaff-reinet",
    component: localmoversnearmegraaffreinet,
  },
  {
    path: "/local-movers-near-me-grahamstown",
    component: localmoversnearmegrahamstown,
  },
  {
    path: "/local-movers-near-me-hermanus",
    component: localmoversnearmehermanus,
  },
  {
    path: "/local-movers-near-me-jeffreys-bay",
    component: localmoversnearmejeffreysbay,
  },
  {
    path: "/local-movers-near-me-johannesburg",
    component: localmoversnearmejohannesburg,
  },
  {
    path: "/local-movers-near-me-kimberley",
    component: localmoversnearmekimberley,
  },
  {
    path: "/local-movers-near-me-klerksdorp",
    component: localmoversnearmeklerksdorp,
  },
  { path: "/local-movers-near-me-knysna", component: localmoversnearmeknysna },
  {
    path: "/local-movers-near-me-kroonstad",
    component: localmoversnearmekroonstad,
  },
  {
    path: "/local-movers-near-me-kuilsrivier",
    component: localmoversnearmekuilsrivier,
  },
  {
    path: "/local-movers-near-me-ladysmith",
    component: localmoversnearmeladysmith,
  },
  {
    path: "/local-movers-near-me-lebowakgomo",
    component: localmoversnearmelebowakgomo,
  },
  {
    path: "/local-movers-near-me-lichtenburg",
    component: localmoversnearmelichtenburg,
  },
  {
    path: "/local-movers-near-me-mahikeng",
    component: localmoversnearmemahikeng,
  },
  {
    path: "/local-movers-near-me-malmesbury",
    component: localmoversnearmemalmesbury,
  },
  {
    path: "/local-movers-near-me-middelburg",
    component: localmoversnearmemiddelburg,
  },
  {
    path: "/local-movers-near-me-midrand",
    component: localmoversnearmemidrand,
  },
  {
    path: "/local-movers-near-me-mmabatho",
    component: localmoversnearmemmabatho,
  },
  {
    path: "/local-movers-near-me-mossel-bay",
    component: localmoversnearmemosselbay,
  },
  { path: "/local-movers-near-me-musina", component: localmoversnearmemusina },
  {
    path: "/local-movers-near-me-nelspruit",
    component: localmoversnearmenelspruit,
  },
  {
    path: "/local-movers-near-me-oudtshoorn",
    component: localmoversnearmeoudtshoorn,
  },
  { path: "/local-movers-near-me-paarl", component: localmoversnearmepaarl },
  {
    path: "/local-movers-near-me-pietermaritzburg",
    component: localmoversnearmepietermaritzburg,
  },
  {
    path: "/local-movers-near-me-polokwane",
    component: localmoversnearmepolokwane,
  },
  {
    path: "/local-movers-near-me-port-elizabeth",
    component: localmoversnearmeportelizabeth,
  },
  {
    path: "/local-movers-near-me-port-shepstone",
    component: localmoversnearmeportshepstone,
  },
  {
    path: "/local-movers-near-me-potchefstroom",
    component: localmoversnearmepotchefstroom,
  },
  {
    path: "/local-movers-near-me-pretoria",
    component: localmoversnearmepretoria,
  },
  {
    path: "/local-movers-near-me-queenstown",
    component: localmoversnearmequeenstown,
  },
  {
    path: "/local-movers-near-me-randburg",
    component: localmoversnearmerandburg,
  },
  {
    path: "/local-movers-near-me-roodepoort",
    component: localmoversnearmeroodepoort,
  },
  {
    path: "/local-movers-near-me-rustenburg",
    component: localmoversnearmerustenburg,
  },
  {
    path: "/local-movers-near-me-saldanha",
    component: localmoversnearmesaldanha,
  },
  {
    path: "/local-movers-near-me-sandton",
    component: localmoversnearmesandton,
  },
  { path: "/local-movers-near-me-soweto", component: localmoversnearmesoweto },
  {
    path: "/local-movers-near-me-springs",
    component: localmoversnearmesprings,
  },
  {
    path: "/local-movers-near-me-standerton",
    component: localmoversnearmestanderton,
  },
  {
    path: "/local-movers-near-me-thohoyandou",
    component: localmoversnearmethohoyandou,
  },
  {
    path: "/local-movers-near-me-tzaneen",
    component: localmoversnearmetzaneen,
  },
  {
    path: "/local-movers-near-me-uitenhage",
    component: localmoversnearmeuitenhage,
  },
  { path: "/local-movers-near-me-ulundi", component: localmoversnearmeulundi },
  { path: "/local-movers-near-me-umtata", component: localmoversnearmeumtata },
  {
    path: "/local-movers-near-me-upington",
    component: localmoversnearmeupington,
  },
  {
    path: "/local-movers-near-me-vanderbijlpark",
    component: localmoversnearmevanderbijlpark,
  },
  {
    path: "/local-movers-near-me-vereeniging",
    component: localmoversnearmevereeniging,
  },
  {
    path: "/local-movers-near-me-volksrust",
    component: localmoversnearmevolksrust,
  },
  {
    path: "/local-movers-near-me-vredenburg",
    component: localmoversnearmevredenburg,
  },
  {
    path: "/local-movers-near-me-vryburg",
    component: localmoversnearmevryburg,
  },
  {
    path: "/local-movers-near-me-vryheid",
    component: localmoversnearmevryheid,
  },
  { path: "/local-movers-near-me-welkom", component: localmoversnearmewelkom },
  {
    path: "/local-movers-near-me-worcester",
    component: localmoversnearmeworcester,
  },
  { path: "/long-distance-alberton", component: longdistancealberton },
  { path: "/long-distance-aliwal-north", component: longdistancealiwalnorth },
  { path: "/long-distance-beaufort-west", component: longdistancebeaufortwest },
  { path: "/long-distance-benoni", component: longdistancebenoni },
  { path: "/long-distance-bethal", component: longdistancebethal },
  { path: "/long-distance-bhisho", component: longdistancebhisho },
  { path: "/long-distance-bloemfontein", component: longdistancebloemfontein },
  { path: "/long-distance-brakpan", component: longdistancebrakpan },
  { path: "/long-distance-brits", component: longdistancebrits },
  { path: "/long-distance-cape-town", component: longdistancecapetown },
  {
    path: "/long-distance-carletonville",
    component: longdistancecarletonville,
  },
  { path: "/long-distance-centurion", component: longdistancecenturion },
  { path: "/long-distance-cradock", component: longdistancecradock },
  { path: "/long-distance-de-aar", component: longdistancedeaar },
  { path: "/long-distance-durban", component: longdistancedurban },
  { path: "/long-distance-east-london", component: longdistanceeastlondon },
  { path: "/long-distance-ferndale", component: longdistanceferndale },
  { path: "/long-distance-george", component: longdistancegeorge },
  { path: "/long-distance-graaff-reinet", component: longdistancegraaffreinet },
  { path: "/long-distance-grahamstown", component: longdistancegrahamstown },
  { path: "/long-distance-hermanus", component: longdistancehermanus },
  { path: "/long-distance-jeffreys-bay", component: longdistancejeffreysbay },
  { path: "/long-distance-johannesburg", component: longdistancejohannesburg },
  { path: "/long-distance-kimberley", component: longdistancekimberley },
  { path: "/long-distance-klerksdorp", component: longdistanceklerksdorp },
  { path: "/long-distance-knysna", component: longdistanceknysna },
  { path: "/long-distance-kroonstad", component: longdistancekroonstad },
  { path: "/long-distance-kuilsrivier", component: longdistancekuilsrivier },
  { path: "/long-distance-ladysmith", component: longdistanceladysmith },
  { path: "/long-distance-lebowakgomo", component: longdistancelebowakgomo },
  { path: "/long-distance-lichtenburg", component: longdistancelichtenburg },
  { path: "/long-distance-mahikeng", component: longdistancemahikeng },
  { path: "/long-distance-malmesbury", component: longdistancemalmesbury },
  { path: "/long-distance-middelburg", component: longdistancemiddelburg },
  { path: "/long-distance-midrand", component: longdistancemidrand },
  { path: "/long-distance-mmabatho", component: longdistancemmabatho },
  { path: "/long-distance-mossel-bay", component: longdistancemosselbay },
  { path: "/long-distance-musina", component: longdistancemusina },
  { path: "/long-distance-nelspruit", component: longdistancenelspruit },
  { path: "/long-distance-oudtshoorn", component: longdistanceoudtshoorn },
  { path: "/long-distance-paarl", component: longdistancepaarl },
  {
    path: "/long-distance-pietermaritzburg",
    component: longdistancepietermaritzburg,
  },
  { path: "/long-distance-polokwane", component: longdistancepolokwane },
  {
    path: "/long-distance-port-elizabeth",
    component: longdistanceportelizabeth,
  },
  {
    path: "/long-distance-port-shepstone",
    component: longdistanceportshepstone,
  },
  {
    path: "/long-distance-potchefstroom",
    component: longdistancepotchefstroom,
  },
  { path: "/long-distance-pretoria", component: longdistancepretoria },
  { path: "/long-distance-queenstown", component: longdistancequeenstown },
  { path: "/long-distance-randburg", component: longdistancerandburg },
  { path: "/long-distance-roodepoort", component: longdistanceroodepoort },
  { path: "/long-distance-rustenburg", component: longdistancerustenburg },
  { path: "/long-distance-saldanha", component: longdistancesaldanha },
  { path: "/long-distance-sandton", component: longdistancesandton },
  { path: "/long-distance-soweto", component: longdistancesoweto },
  { path: "/long-distance-springs", component: longdistancesprings },
  { path: "/long-distance-standerton", component: longdistancestanderton },
  { path: "/long-distance-thohoyandou", component: longdistancethohoyandou },
  { path: "/long-distance-tzaneen", component: longdistancetzaneen },
  { path: "/long-distance-uitenhage", component: longdistanceuitenhage },
  { path: "/long-distance-ulundi", component: longdistanceulundi },
  { path: "/long-distance-umtata", component: longdistanceumtata },
  { path: "/long-distance-upington", component: longdistanceupington },
  {
    path: "/long-distance-vanderbijlpark",
    component: longdistancevanderbijlpark,
  },
  { path: "/long-distance-vereeniging", component: longdistancevereeniging },
  { path: "/long-distance-volksrust", component: longdistancevolksrust },
  { path: "/long-distance-vredenburg", component: longdistancevredenburg },
  { path: "/long-distance-vryburg", component: longdistancevryburg },
  { path: "/long-distance-vryheid", component: longdistancevryheid },
  { path: "/long-distance-welkom", component: longdistancewelkom },
  { path: "/long-distance-worcester", component: longdistanceworcester },
  { path: "/mighty-ex8-alberton", component: mightyex8alberton },
  { path: "/mighty-ex8-aliwal-north", component: mightyex8aliwalnorth },
  { path: "/mighty-ex8-beaufort-west", component: mightyex8beaufortwest },
  { path: "/mighty-ex8-benoni", component: mightyex8benoni },
  { path: "/mighty-ex8-bethal", component: mightyex8bethal },
  { path: "/mighty-ex8-bhisho", component: mightyex8bhisho },
  { path: "/mighty-ex8-bloemfontein", component: mightyex8bloemfontein },
  { path: "/mighty-ex8-brakpan", component: mightyex8brakpan },
  { path: "/mighty-ex8-brits", component: mightyex8brits },
  { path: "/mighty-ex8-cape-town", component: mightyex8capetown },
  { path: "/mighty-ex8-carletonville", component: mightyex8carletonville },
  { path: "/mighty-ex8-centurion", component: mightyex8centurion },
  { path: "/mighty-ex8-cradock", component: mightyex8cradock },
  { path: "/mighty-ex8-de-aar", component: mightyex8deaar },
  { path: "/mighty-ex8-durban", component: mightyex8durban },
  { path: "/mighty-ex8-east-london", component: mightyex8eastlondon },
  { path: "/mighty-ex8-ferndale", component: mightyex8ferndale },
  { path: "/mighty-ex8-george", component: mightyex8george },
  { path: "/mighty-ex8-graaff-reinet", component: mightyex8graaffreinet },
  { path: "/mighty-ex8-grahamstown", component: mightyex8grahamstown },
  { path: "/mighty-ex8-hermanus", component: mightyex8hermanus },
  { path: "/mighty-ex8-jeffreys-bay", component: mightyex8jeffreysbay },
  { path: "/mighty-ex8-johannesburg", component: mightyex8johannesburg },
  { path: "/mighty-ex8-kimberley", component: mightyex8kimberley },
  { path: "/mighty-ex8-klerksdorp", component: mightyex8klerksdorp },
  { path: "/mighty-ex8-knysna", component: mightyex8knysna },
  { path: "/mighty-ex8-kroonstad", component: mightyex8kroonstad },
  { path: "/mighty-ex8-kuilsrivier", component: mightyex8kuilsrivier },
  { path: "/mighty-ex8-ladysmith", component: mightyex8ladysmith },
  { path: "/mighty-ex8-lebowakgomo", component: mightyex8lebowakgomo },
  { path: "/mighty-ex8-lichtenburg", component: mightyex8lichtenburg },
  { path: "/mighty-ex8-mahikeng", component: mightyex8mahikeng },
  { path: "/mighty-ex8-malmesbury", component: mightyex8malmesbury },
  { path: "/mighty-ex8-middelburg", component: mightyex8middelburg },
  { path: "/mighty-ex8-midrand", component: mightyex8midrand },
  { path: "/mighty-ex8-mmabatho", component: mightyex8mmabatho },
  { path: "/mighty-ex8-mossel-bay", component: mightyex8mosselbay },
  { path: "/mighty-ex8-musina", component: mightyex8musina },
  { path: "/mighty-ex8-nelspruit", component: mightyex8nelspruit },
  { path: "/mighty-ex8-oudtshoorn", component: mightyex8oudtshoorn },
  { path: "/mighty-ex8-paarl", component: mightyex8paarl },
  {
    path: "/mighty-ex8-pietermaritzburg",
    component: mightyex8pietermaritzburg,
  },
  { path: "/mighty-ex8-polokwane", component: mightyex8polokwane },
  { path: "/mighty-ex8-port-elizabeth", component: mightyex8portelizabeth },
  { path: "/mighty-ex8-port-shepstone", component: mightyex8portshepstone },
  { path: "/mighty-ex8-potchefstroom", component: mightyex8potchefstroom },
  { path: "/mighty-ex8-pretoria", component: mightyex8pretoria },
  { path: "/mighty-ex8-queenstown", component: mightyex8queenstown },
  { path: "/mighty-ex8-randburg", component: mightyex8randburg },
  { path: "/mighty-ex8-roodepoort", component: mightyex8roodepoort },
  { path: "/mighty-ex8-rustenburg", component: mightyex8rustenburg },
  { path: "/mighty-ex8-saldanha", component: mightyex8saldanha },
  { path: "/mighty-ex8-sandton", component: mightyex8sandton },
  { path: "/mighty-ex8-soweto", component: mightyex8soweto },
  { path: "/mighty-ex8-springs", component: mightyex8springs },
  { path: "/mighty-ex8-standerton", component: mightyex8standerton },
  { path: "/mighty-ex8-thohoyandou", component: mightyex8thohoyandou },
  { path: "/mighty-ex8-tzaneen", component: mightyex8tzaneen },
  { path: "/mighty-ex8-uitenhage", component: mightyex8uitenhage },
  { path: "/mighty-ex8-ulundi", component: mightyex8ulundi },
  { path: "/mighty-ex8-umtata", component: mightyex8umtata },
  { path: "/mighty-ex8-upington", component: mightyex8upington },
  { path: "/mighty-ex8-vanderbijlpark", component: mightyex8vanderbijlpark },
  { path: "/mighty-ex8-vereeniging", component: mightyex8vereeniging },
  { path: "/mighty-ex8-volksrust", component: mightyex8volksrust },
  { path: "/mighty-ex8-vredenburg", component: mightyex8vredenburg },
  { path: "/mighty-ex8-vryburg", component: mightyex8vryburg },
  { path: "/mighty-ex8-vryheid", component: mightyex8vryheid },
  { path: "/mighty-ex8-welkom", component: mightyex8welkom },
  { path: "/mighty-ex8-worcester", component: mightyex8worcester },
  { path: "/moving-alberton", component: movingalberton },
  { path: "/moving-aliwal-north", component: movingaliwalnorth },
  { path: "/moving-beaufort-west", component: movingbeaufortwest },
  { path: "/moving-benoni", component: movingbenoni },
  { path: "/moving-bethal", component: movingbethal },
  { path: "/moving-bhisho", component: movingbhisho },
  { path: "/moving-bloemfontein", component: movingbloemfontein },
  { path: "/moving-brakpan", component: movingbrakpan },
  { path: "/moving-brits", component: movingbrits },
  { path: "/moving-cape-town", component: movingcapetown },
  { path: "/moving-carletonville", component: movingcarletonville },
  { path: "/moving-centurion", component: movingcenturion },
  { path: "/moving-cradock", component: movingcradock },
  { path: "/moving-de-aar", component: movingdeaar },
  { path: "/moving-durban", component: movingdurban },
  { path: "/moving-east-london", component: movingeastlondon },
  { path: "/moving-ferndale", component: movingferndale },
  { path: "/moving-george", component: movinggeorge },
  { path: "/moving-graaff-reinet", component: movinggraaffreinet },
  { path: "/moving-grahamstown", component: movinggrahamstown },
  { path: "/moving-hermanus", component: movinghermanus },
  { path: "/moving-jeffreys-bay", component: movingjeffreysbay },
  { path: "/moving-johannesburg", component: movingjohannesburg },
  { path: "/moving-kimberley", component: movingkimberley },
  { path: "/moving-klerksdorp", component: movingklerksdorp },
  { path: "/moving-knysna", component: movingknysna },
  { path: "/moving-kroonstad", component: movingkroonstad },
  { path: "/moving-kuilsrivier", component: movingkuilsrivier },
  { path: "/moving-ladysmith", component: movingladysmith },
  { path: "/moving-lebowakgomo", component: movinglebowakgomo },
  { path: "/moving-lichtenburg", component: movinglichtenburg },
  { path: "/moving-mahikeng", component: movingmahikeng },
  { path: "/moving-malmesbury", component: movingmalmesbury },
  { path: "/moving-middelburg", component: movingmiddelburg },
  { path: "/moving-midrand", component: movingmidrand },
  { path: "/moving-mmabatho", component: movingmmabatho },
  { path: "/moving-mossel-bay", component: movingmosselbay },
  { path: "/moving-musina", component: movingmusina },
  { path: "/moving-nelspruit", component: movingnelspruit },
  { path: "/moving-oudtshoorn", component: movingoudtshoorn },
  { path: "/moving-out-alberton", component: movingoutalberton },
  { path: "/moving-out-aliwal-north", component: movingoutaliwalnorth },
  { path: "/moving-out-beaufort-west", component: movingoutbeaufortwest },
  { path: "/moving-out-benoni", component: movingoutbenoni },
  { path: "/moving-out-bethal", component: movingoutbethal },
  { path: "/moving-out-bhisho", component: movingoutbhisho },
  { path: "/moving-out-bloemfontein", component: movingoutbloemfontein },
  { path: "/moving-out-brakpan", component: movingoutbrakpan },
  { path: "/moving-out-brits", component: movingoutbrits },
  { path: "/moving-out-cape-town", component: movingoutcapetown },
  { path: "/moving-out-carletonville", component: movingoutcarletonville },
  { path: "/moving-out-centurion", component: movingoutcenturion },
  { path: "/moving-out-cradock", component: movingoutcradock },
  { path: "/moving-out-de-aar", component: movingoutdeaar },
  { path: "/moving-out-durban", component: movingoutdurban },
  { path: "/moving-out-east-london", component: movingouteastlondon },
  { path: "/moving-out-ferndale", component: movingoutferndale },
  { path: "/moving-out-george", component: movingoutgeorge },
  { path: "/moving-out-graaff-reinet", component: movingoutgraaffreinet },
  { path: "/moving-out-grahamstown", component: movingoutgrahamstown },
  { path: "/moving-out-hermanus", component: movingouthermanus },
  { path: "/moving-out-jeffreys-bay", component: movingoutjeffreysbay },
  { path: "/moving-out-johannesburg", component: movingoutjohannesburg },
  { path: "/moving-out-kimberley", component: movingoutkimberley },
  { path: "/moving-out-klerksdorp", component: movingoutklerksdorp },
  { path: "/moving-out-knysna", component: movingoutknysna },
  { path: "/moving-out-kroonstad", component: movingoutkroonstad },
  { path: "/moving-out-kuilsrivier", component: movingoutkuilsrivier },
  { path: "/moving-out-ladysmith", component: movingoutladysmith },
  { path: "/moving-out-lebowakgomo", component: movingoutlebowakgomo },
  { path: "/moving-out-lichtenburg", component: movingoutlichtenburg },
  { path: "/moving-out-mahikeng", component: movingoutmahikeng },
  { path: "/moving-out-malmesbury", component: movingoutmalmesbury },
  { path: "/moving-out-middelburg", component: movingoutmiddelburg },
  { path: "/moving-out-midrand", component: movingoutmidrand },
  { path: "/moving-out-mmabatho", component: movingoutmmabatho },
  { path: "/moving-out-mossel-bay", component: movingoutmosselbay },
  { path: "/moving-out-musina", component: movingoutmusina },
  { path: "/moving-out-nelspruit", component: movingoutnelspruit },
  { path: "/moving-out-oudtshoorn", component: movingoutoudtshoorn },
  { path: "/moving-out-paarl", component: movingoutpaarl },
  {
    path: "/moving-out-pietermaritzburg",
    component: movingoutpietermaritzburg,
  },
  { path: "/moving-out-polokwane", component: movingoutpolokwane },
  { path: "/moving-out-port-elizabeth", component: movingoutportelizabeth },
  { path: "/moving-out-port-shepstone", component: movingoutportshepstone },
  { path: "/moving-out-potchefstroom", component: movingoutpotchefstroom },
  { path: "/moving-out-pretoria", component: movingoutpretoria },
  { path: "/moving-out-queenstown", component: movingoutqueenstown },
  { path: "/moving-out-randburg", component: movingoutrandburg },
  { path: "/moving-out-roodepoort", component: movingoutroodepoort },
  { path: "/moving-out-rustenburg", component: movingoutrustenburg },
  { path: "/moving-out-saldanha", component: movingoutsaldanha },
  { path: "/moving-out-sandton", component: movingoutsandton },
  { path: "/moving-out-soweto", component: movingoutsoweto },
  { path: "/moving-out-springs", component: movingoutsprings },
  { path: "/moving-out-standerton", component: movingoutstanderton },
  { path: "/moving-out-thohoyandou", component: movingoutthohoyandou },
  { path: "/moving-out-tzaneen", component: movingouttzaneen },
  { path: "/moving-out-uitenhage", component: movingoutuitenhage },
  { path: "/moving-out-ulundi", component: movingoutulundi },
  { path: "/moving-out-umtata", component: movingoutumtata },
  { path: "/moving-out-upington", component: movingoutupington },
  { path: "/moving-out-vanderbijlpark", component: movingoutvanderbijlpark },
  { path: "/moving-out-vereeniging", component: movingoutvereeniging },
  { path: "/moving-out-volksrust", component: movingoutvolksrust },
  { path: "/moving-out-vredenburg", component: movingoutvredenburg },
  { path: "/moving-out-vryburg", component: movingoutvryburg },
  { path: "/moving-out-vryheid", component: movingoutvryheid },
  { path: "/moving-out-welkom", component: movingoutwelkom },
  { path: "/moving-out-worcester", component: movingoutworcester },
  { path: "/moving-paarl", component: movingpaarl },
  { path: "/moving-pietermaritzburg", component: movingpietermaritzburg },
  { path: "/moving-polokwane", component: movingpolokwane },
  { path: "/moving-port-elizabeth", component: movingportelizabeth },
  { path: "/moving-port-shepstone", component: movingportshepstone },
  { path: "/moving-potchefstroom", component: movingpotchefstroom },
  { path: "/moving-pretoria", component: movingpretoria },
  { path: "/moving-queenstown", component: movingqueenstown },
  { path: "/moving-randburg", component: movingrandburg },
  { path: "/moving-roodepoort", component: movingroodepoort },
  { path: "/moving-rustenburg", component: movingrustenburg },
  { path: "/moving-saldanha", component: movingsaldanha },
  { path: "/moving-sandton", component: movingsandton },
  { path: "/moving-soweto", component: movingsoweto },
  { path: "/moving-springs", component: movingsprings },
  { path: "/moving-standerton", component: movingstanderton },
  { path: "/moving-thohoyandou", component: movingthohoyandou },
  { path: "/moving-tzaneen", component: movingtzaneen },
  { path: "/moving-uitenhage", component: movinguitenhage },
  { path: "/moving-ulundi", component: movingulundi },
  { path: "/moving-umtata", component: movingumtata },
  { path: "/moving-upington", component: movingupington },
  { path: "/moving-vanderbijlpark", component: movingvanderbijlpark },
  { path: "/moving-vereeniging", component: movingvereeniging },
  { path: "/moving-volksrust", component: movingvolksrust },
  { path: "/moving-vredenburg", component: movingvredenburg },
  { path: "/moving-vryburg", component: movingvryburg },
  { path: "/moving-vryheid", component: movingvryheid },
  { path: "/moving-welkom", component: movingwelkom },
  { path: "/moving-worcester", component: movingworcester },
  { path: "/need-delivery-alberton", component: needdeliveryalberton },
  { path: "/need-delivery-aliwal-north", component: needdeliveryaliwalnorth },
  { path: "/need-delivery-beaufort-west", component: needdeliverybeaufortwest },
  { path: "/need-delivery-benoni", component: needdeliverybenoni },
  { path: "/need-delivery-bethal", component: needdeliverybethal },
  { path: "/need-delivery-bhisho", component: needdeliverybhisho },
  { path: "/need-delivery-bloemfontein", component: needdeliverybloemfontein },
  { path: "/need-delivery-brakpan", component: needdeliverybrakpan },
  { path: "/need-delivery-brits", component: needdeliverybrits },
  { path: "/need-delivery-cape-town", component: needdeliverycapetown },
  {
    path: "/need-delivery-carletonville",
    component: needdeliverycarletonville,
  },
  { path: "/need-delivery-centurion", component: needdeliverycenturion },
  { path: "/need-delivery-cradock", component: needdeliverycradock },
  { path: "/need-delivery-de-aar", component: needdeliverydeaar },
  { path: "/need-delivery-durban", component: needdeliverydurban },
  { path: "/need-delivery-east-london", component: needdeliveryeastlondon },
  { path: "/need-delivery-ferndale", component: needdeliveryferndale },
  { path: "/need-delivery-george", component: needdeliverygeorge },
  { path: "/need-delivery-graaff-reinet", component: needdeliverygraaffreinet },
  { path: "/need-delivery-grahamstown", component: needdeliverygrahamstown },
  { path: "/need-delivery-hermanus", component: needdeliveryhermanus },
  { path: "/need-delivery-jeffreys-bay", component: needdeliveryjeffreysbay },
  { path: "/need-delivery-johannesburg", component: needdeliveryjohannesburg },
  { path: "/need-delivery-kimberley", component: needdeliverykimberley },
  { path: "/need-delivery-klerksdorp", component: needdeliveryklerksdorp },
  { path: "/need-delivery-knysna", component: needdeliveryknysna },
  { path: "/need-delivery-kroonstad", component: needdeliverykroonstad },
  { path: "/need-delivery-kuilsrivier", component: needdeliverykuilsrivier },
  { path: "/need-delivery-ladysmith", component: needdeliveryladysmith },
  { path: "/need-delivery-lebowakgomo", component: needdeliverylebowakgomo },
  { path: "/need-delivery-lichtenburg", component: needdeliverylichtenburg },
  { path: "/need-delivery-mahikeng", component: needdeliverymahikeng },
  { path: "/need-delivery-malmesbury", component: needdeliverymalmesbury },
  { path: "/need-delivery-middelburg", component: needdeliverymiddelburg },
  { path: "/need-delivery-midrand", component: needdeliverymidrand },
  { path: "/need-delivery-mmabatho", component: needdeliverymmabatho },
  { path: "/need-delivery-mossel-bay", component: needdeliverymosselbay },
  { path: "/need-delivery-musina", component: needdeliverymusina },
  { path: "/need-delivery-nelspruit", component: needdeliverynelspruit },
  { path: "/need-delivery-oudtshoorn", component: needdeliveryoudtshoorn },
  { path: "/need-delivery-paarl", component: needdeliverypaarl },
  {
    path: "/need-delivery-pietermaritzburg",
    component: needdeliverypietermaritzburg,
  },
  { path: "/need-delivery-polokwane", component: needdeliverypolokwane },
  {
    path: "/need-delivery-port-elizabeth",
    component: needdeliveryportelizabeth,
  },
  {
    path: "/need-delivery-port-shepstone",
    component: needdeliveryportshepstone,
  },
  {
    path: "/need-delivery-potchefstroom",
    component: needdeliverypotchefstroom,
  },
  { path: "/need-delivery-pretoria", component: needdeliverypretoria },
  { path: "/need-delivery-queenstown", component: needdeliveryqueenstown },
  { path: "/need-delivery-randburg", component: needdeliveryrandburg },
  { path: "/need-delivery-roodepoort", component: needdeliveryroodepoort },
  { path: "/need-delivery-rustenburg", component: needdeliveryrustenburg },
  { path: "/need-delivery-saldanha", component: needdeliverysaldanha },
  { path: "/need-delivery-sandton", component: needdeliverysandton },
  { path: "/need-delivery-soweto", component: needdeliverysoweto },
  { path: "/need-delivery-springs", component: needdeliverysprings },
  { path: "/need-delivery-standerton", component: needdeliverystanderton },
  { path: "/need-delivery-thohoyandou", component: needdeliverythohoyandou },
  { path: "/need-delivery-tzaneen", component: needdeliverytzaneen },
  { path: "/need-delivery-uitenhage", component: needdeliveryuitenhage },
  { path: "/need-delivery-ulundi", component: needdeliveryulundi },
  { path: "/need-delivery-umtata", component: needdeliveryumtata },
  { path: "/need-delivery-upington", component: needdeliveryupington },
  {
    path: "/need-delivery-vanderbijlpark",
    component: needdeliveryvanderbijlpark,
  },
  { path: "/need-delivery-vereeniging", component: needdeliveryvereeniging },
  { path: "/need-delivery-volksrust", component: needdeliveryvolksrust },
  { path: "/need-delivery-vredenburg", component: needdeliveryvredenburg },
  { path: "/need-delivery-vryburg", component: needdeliveryvryburg },
  { path: "/need-delivery-vryheid", component: needdeliveryvryheid },
  { path: "/need-delivery-welkom", component: needdeliverywelkom },
  { path: "/need-delivery-worcester", component: needdeliveryworcester },
  { path: "/one-way-truck-alberton", component: onewaytruckalberton },
  { path: "/one-way-truck-aliwal-north", component: onewaytruckaliwalnorth },
  { path: "/one-way-truck-beaufort-west", component: onewaytruckbeaufortwest },
  { path: "/one-way-truck-benoni", component: onewaytruckbenoni },
  { path: "/one-way-truck-bethal", component: onewaytruckbethal },
  { path: "/one-way-truck-bhisho", component: onewaytruckbhisho },
  { path: "/one-way-truck-bloemfontein", component: onewaytruckbloemfontein },
  { path: "/one-way-truck-brakpan", component: onewaytruckbrakpan },
  { path: "/one-way-truck-brits", component: onewaytruckbrits },
  { path: "/one-way-truck-cape-town", component: onewaytruckcapetown },
  { path: "/one-way-truck-carletonville", component: onewaytruckcarletonville },
  { path: "/one-way-truck-centurion", component: onewaytruckcenturion },
  { path: "/one-way-truck-cradock", component: onewaytruckcradock },
  { path: "/one-way-truck-de-aar", component: onewaytruckdeaar },
  { path: "/one-way-truck-durban", component: onewaytruckdurban },
  { path: "/one-way-truck-east-london", component: onewaytruckeastlondon },
  { path: "/one-way-truck-ferndale", component: onewaytruckferndale },
  { path: "/one-way-truck-george", component: onewaytruckgeorge },
  { path: "/one-way-truck-graaff-reinet", component: onewaytruckgraaffreinet },
  { path: "/one-way-truck-grahamstown", component: onewaytruckgrahamstown },
  { path: "/one-way-truck-hermanus", component: onewaytruckhermanus },
  { path: "/one-way-truck-jeffreys-bay", component: onewaytruckjeffreysbay },
  { path: "/one-way-truck-johannesburg", component: onewaytruckjohannesburg },
  { path: "/one-way-truck-kimberley", component: onewaytruckkimberley },
  { path: "/one-way-truck-klerksdorp", component: onewaytruckklerksdorp },
  { path: "/one-way-truck-knysna", component: onewaytruckknysna },
  { path: "/one-way-truck-kroonstad", component: onewaytruckkroonstad },
  { path: "/one-way-truck-kuilsrivier", component: onewaytruckkuilsrivier },
  { path: "/one-way-truck-ladysmith", component: onewaytruckladysmith },
  { path: "/one-way-truck-lebowakgomo", component: onewaytrucklebowakgomo },
  { path: "/one-way-truck-lichtenburg", component: onewaytrucklichtenburg },
  { path: "/one-way-truck-mahikeng", component: onewaytruckmahikeng },
  { path: "/one-way-truck-malmesbury", component: onewaytruckmalmesbury },
  { path: "/one-way-truck-middelburg", component: onewaytruckmiddelburg },
  { path: "/one-way-truck-midrand", component: onewaytruckmidrand },
  { path: "/one-way-truck-mmabatho", component: onewaytruckmmabatho },
  { path: "/one-way-truck-mossel-bay", component: onewaytruckmosselbay },
  { path: "/one-way-truck-musina", component: onewaytruckmusina },
  { path: "/one-way-truck-nelspruit", component: onewaytrucknelspruit },
  { path: "/one-way-truck-oudtshoorn", component: onewaytruckoudtshoorn },
  { path: "/one-way-truck-paarl", component: onewaytruckpaarl },
  {
    path: "/one-way-truck-pietermaritzburg",
    component: onewaytruckpietermaritzburg,
  },
  { path: "/one-way-truck-polokwane", component: onewaytruckpolokwane },
  {
    path: "/one-way-truck-port-elizabeth",
    component: onewaytruckportelizabeth,
  },
  {
    path: "/one-way-truck-port-shepstone",
    component: onewaytruckportshepstone,
  },
  { path: "/one-way-truck-potchefstroom", component: onewaytruckpotchefstroom },
  { path: "/one-way-truck-pretoria", component: onewaytruckpretoria },
  { path: "/one-way-truck-queenstown", component: onewaytruckqueenstown },
  { path: "/one-way-truck-randburg", component: onewaytruckrandburg },
  { path: "/one-way-truck-roodepoort", component: onewaytruckroodepoort },
  { path: "/one-way-truck-rustenburg", component: onewaytruckrustenburg },
  { path: "/one-way-truck-saldanha", component: onewaytrucksaldanha },
  { path: "/one-way-truck-sandton", component: onewaytrucksandton },
  { path: "/one-way-truck-soweto", component: onewaytrucksoweto },
  { path: "/one-way-truck-springs", component: onewaytrucksprings },
  { path: "/one-way-truck-standerton", component: onewaytruckstanderton },
  { path: "/one-way-truck-thohoyandou", component: onewaytruckthohoyandou },
  { path: "/one-way-truck-tzaneen", component: onewaytrucktzaneen },
  { path: "/one-way-truck-uitenhage", component: onewaytruckuitenhage },
  { path: "/one-way-truck-ulundi", component: onewaytruckulundi },
  { path: "/one-way-truck-umtata", component: onewaytruckumtata },
  { path: "/one-way-truck-upington", component: onewaytruckupington },
  {
    path: "/one-way-truck-vanderbijlpark",
    component: onewaytruckvanderbijlpark,
  },
  { path: "/one-way-truck-vereeniging", component: onewaytruckvereeniging },
  { path: "/one-way-truck-volksrust", component: onewaytruckvolksrust },
  { path: "/one-way-truck-vredenburg", component: onewaytruckvredenburg },
  { path: "/one-way-truck-vryburg", component: onewaytruckvryburg },
  { path: "/one-way-truck-vryheid", component: onewaytruckvryheid },
  { path: "/one-way-truck-welkom", component: onewaytruckwelkom },
  { path: "/one-way-truck-worcester", component: onewaytruckworcester },
  {
    path: "/online-order-delivery-alberton",
    component: onlineorderdeliveryalberton,
  },
  {
    path: "/online-order-delivery-aliwal-north",
    component: onlineorderdeliveryaliwalnorth,
  },
  {
    path: "/online-order-delivery-beaufort-west",
    component: onlineorderdeliverybeaufortwest,
  },
  {
    path: "/online-order-delivery-benoni",
    component: onlineorderdeliverybenoni,
  },
  {
    path: "/online-order-delivery-bethal",
    component: onlineorderdeliverybethal,
  },
  {
    path: "/online-order-delivery-bhisho",
    component: onlineorderdeliverybhisho,
  },
  {
    path: "/online-order-delivery-bloemfontein",
    component: onlineorderdeliverybloemfontein,
  },
  {
    path: "/online-order-delivery-brakpan",
    component: onlineorderdeliverybrakpan,
  },
  { path: "/online-order-delivery-brits", component: onlineorderdeliverybrits },
  {
    path: "/online-order-delivery-cape-town",
    component: onlineorderdeliverycapetown,
  },
  {
    path: "/online-order-delivery-carletonville",
    component: onlineorderdeliverycarletonville,
  },
  {
    path: "/online-order-delivery-centurion",
    component: onlineorderdeliverycenturion,
  },
  {
    path: "/online-order-delivery-cradock",
    component: onlineorderdeliverycradock,
  },
  {
    path: "/online-order-delivery-de-aar",
    component: onlineorderdeliverydeaar,
  },
  {
    path: "/online-order-delivery-durban",
    component: onlineorderdeliverydurban,
  },
  {
    path: "/online-order-delivery-east-london",
    component: onlineorderdeliveryeastlondon,
  },
  {
    path: "/online-order-delivery-ferndale",
    component: onlineorderdeliveryferndale,
  },
  {
    path: "/online-order-delivery-george",
    component: onlineorderdeliverygeorge,
  },
  {
    path: "/online-order-delivery-graaff-reinet",
    component: onlineorderdeliverygraaffreinet,
  },
  {
    path: "/online-order-delivery-grahamstown",
    component: onlineorderdeliverygrahamstown,
  },
  {
    path: "/online-order-delivery-hermanus",
    component: onlineorderdeliveryhermanus,
  },
  {
    path: "/online-order-delivery-jeffreys-bay",
    component: onlineorderdeliveryjeffreysbay,
  },
  {
    path: "/online-order-delivery-johannesburg",
    component: onlineorderdeliveryjohannesburg,
  },
  {
    path: "/online-order-delivery-kimberley",
    component: onlineorderdeliverykimberley,
  },
  {
    path: "/online-order-delivery-klerksdorp",
    component: onlineorderdeliveryklerksdorp,
  },
  {
    path: "/online-order-delivery-knysna",
    component: onlineorderdeliveryknysna,
  },
  {
    path: "/online-order-delivery-kroonstad",
    component: onlineorderdeliverykroonstad,
  },
  {
    path: "/online-order-delivery-kuilsrivier",
    component: onlineorderdeliverykuilsrivier,
  },
  {
    path: "/online-order-delivery-ladysmith",
    component: onlineorderdeliveryladysmith,
  },
  {
    path: "/online-order-delivery-lebowakgomo",
    component: onlineorderdeliverylebowakgomo,
  },
  {
    path: "/online-order-delivery-lichtenburg",
    component: onlineorderdeliverylichtenburg,
  },
  {
    path: "/online-order-delivery-mahikeng",
    component: onlineorderdeliverymahikeng,
  },
  {
    path: "/online-order-delivery-malmesbury",
    component: onlineorderdeliverymalmesbury,
  },
  {
    path: "/online-order-delivery-middelburg",
    component: onlineorderdeliverymiddelburg,
  },
  {
    path: "/online-order-delivery-midrand",
    component: onlineorderdeliverymidrand,
  },
  {
    path: "/online-order-delivery-mmabatho",
    component: onlineorderdeliverymmabatho,
  },
  {
    path: "/online-order-delivery-mossel-bay",
    component: onlineorderdeliverymosselbay,
  },
  {
    path: "/online-order-delivery-musina",
    component: onlineorderdeliverymusina,
  },
  {
    path: "/online-order-delivery-nelspruit",
    component: onlineorderdeliverynelspruit,
  },
  {
    path: "/online-order-delivery-oudtshoorn",
    component: onlineorderdeliveryoudtshoorn,
  },
  { path: "/online-order-delivery-paarl", component: onlineorderdeliverypaarl },
  {
    path: "/online-order-delivery-pietermaritzburg",
    component: onlineorderdeliverypietermaritzburg,
  },
  {
    path: "/online-order-delivery-polokwane",
    component: onlineorderdeliverypolokwane,
  },
  {
    path: "/online-order-delivery-port-elizabeth",
    component: onlineorderdeliveryportelizabeth,
  },
  {
    path: "/online-order-delivery-port-shepstone",
    component: onlineorderdeliveryportshepstone,
  },
  {
    path: "/online-order-delivery-potchefstroom",
    component: onlineorderdeliverypotchefstroom,
  },
  {
    path: "/online-order-delivery-pretoria",
    component: onlineorderdeliverypretoria,
  },
  {
    path: "/online-order-delivery-queenstown",
    component: onlineorderdeliveryqueenstown,
  },
  {
    path: "/online-order-delivery-randburg",
    component: onlineorderdeliveryrandburg,
  },
  {
    path: "/online-order-delivery-roodepoort",
    component: onlineorderdeliveryroodepoort,
  },
  {
    path: "/online-order-delivery-rustenburg",
    component: onlineorderdeliveryrustenburg,
  },
  {
    path: "/online-order-delivery-saldanha",
    component: onlineorderdeliverysaldanha,
  },
  {
    path: "/online-order-delivery-sandton",
    component: onlineorderdeliverysandton,
  },
  {
    path: "/online-order-delivery-soweto",
    component: onlineorderdeliverysoweto,
  },
  {
    path: "/online-order-delivery-springs",
    component: onlineorderdeliverysprings,
  },
  {
    path: "/online-order-delivery-standerton",
    component: onlineorderdeliverystanderton,
  },
  {
    path: "/online-order-delivery-thohoyandou",
    component: onlineorderdeliverythohoyandou,
  },
  {
    path: "/online-order-delivery-tzaneen",
    component: onlineorderdeliverytzaneen,
  },
  {
    path: "/online-order-delivery-uitenhage",
    component: onlineorderdeliveryuitenhage,
  },
  {
    path: "/online-order-delivery-ulundi",
    component: onlineorderdeliveryulundi,
  },
  {
    path: "/online-order-delivery-umtata",
    component: onlineorderdeliveryumtata,
  },
  {
    path: "/online-order-delivery-upington",
    component: onlineorderdeliveryupington,
  },
  {
    path: "/online-order-delivery-vanderbijlpark",
    component: onlineorderdeliveryvanderbijlpark,
  },
  {
    path: "/online-order-delivery-vereeniging",
    component: onlineorderdeliveryvereeniging,
  },
  {
    path: "/online-order-delivery-volksrust",
    component: onlineorderdeliveryvolksrust,
  },
  {
    path: "/online-order-delivery-vredenburg",
    component: onlineorderdeliveryvredenburg,
  },
  {
    path: "/online-order-delivery-vryburg",
    component: onlineorderdeliveryvryburg,
  },
  {
    path: "/online-order-delivery-vryheid",
    component: onlineorderdeliveryvryheid,
  },
  {
    path: "/online-order-delivery-welkom",
    component: onlineorderdeliverywelkom,
  },
  {
    path: "/online-order-delivery-worcester",
    component: onlineorderdeliveryworcester,
  },
  {
    path: "/overnight-delivery-alberton",
    component: overnightdeliveryalberton,
  },
  {
    path: "/overnight-delivery-aliwal-north",
    component: overnightdeliveryaliwalnorth,
  },
  {
    path: "/overnight-delivery-beaufort-west",
    component: overnightdeliverybeaufortwest,
  },
  { path: "/overnight-delivery-benoni", component: overnightdeliverybenoni },
  { path: "/overnight-delivery-bethal", component: overnightdeliverybethal },
  { path: "/overnight-delivery-bhisho", component: overnightdeliverybhisho },
  {
    path: "/overnight-delivery-bloemfontein",
    component: overnightdeliverybloemfontein,
  },
  { path: "/overnight-delivery-brakpan", component: overnightdeliverybrakpan },
  { path: "/overnight-delivery-brits", component: overnightdeliverybrits },
  {
    path: "/overnight-delivery-cape-town",
    component: overnightdeliverycapetown,
  },
  {
    path: "/overnight-delivery-carletonville",
    component: overnightdeliverycarletonville,
  },
  {
    path: "/overnight-delivery-centurion",
    component: overnightdeliverycenturion,
  },
  { path: "/overnight-delivery-cradock", component: overnightdeliverycradock },
  { path: "/overnight-delivery-de-aar", component: overnightdeliverydeaar },
  { path: "/overnight-delivery-durban", component: overnightdeliverydurban },
  {
    path: "/overnight-delivery-east-london",
    component: overnightdeliveryeastlondon,
  },
  {
    path: "/overnight-delivery-ferndale",
    component: overnightdeliveryferndale,
  },
  { path: "/overnight-delivery-george", component: overnightdeliverygeorge },
  {
    path: "/overnight-delivery-graaff-reinet",
    component: overnightdeliverygraaffreinet,
  },
  {
    path: "/overnight-delivery-grahamstown",
    component: overnightdeliverygrahamstown,
  },
  {
    path: "/overnight-delivery-hermanus",
    component: overnightdeliveryhermanus,
  },
  {
    path: "/overnight-delivery-jeffreys-bay",
    component: overnightdeliveryjeffreysbay,
  },
  {
    path: "/overnight-delivery-johannesburg",
    component: overnightdeliveryjohannesburg,
  },
  {
    path: "/overnight-delivery-kimberley",
    component: overnightdeliverykimberley,
  },
  {
    path: "/overnight-delivery-klerksdorp",
    component: overnightdeliveryklerksdorp,
  },
  { path: "/overnight-delivery-knysna", component: overnightdeliveryknysna },
  {
    path: "/overnight-delivery-kroonstad",
    component: overnightdeliverykroonstad,
  },
  {
    path: "/overnight-delivery-kuilsrivier",
    component: overnightdeliverykuilsrivier,
  },
  {
    path: "/overnight-delivery-ladysmith",
    component: overnightdeliveryladysmith,
  },
  {
    path: "/overnight-delivery-lebowakgomo",
    component: overnightdeliverylebowakgomo,
  },
  {
    path: "/overnight-delivery-lichtenburg",
    component: overnightdeliverylichtenburg,
  },
  {
    path: "/overnight-delivery-mahikeng",
    component: overnightdeliverymahikeng,
  },
  {
    path: "/overnight-delivery-malmesbury",
    component: overnightdeliverymalmesbury,
  },
  {
    path: "/overnight-delivery-middelburg",
    component: overnightdeliverymiddelburg,
  },
  { path: "/overnight-delivery-midrand", component: overnightdeliverymidrand },
  {
    path: "/overnight-delivery-mmabatho",
    component: overnightdeliverymmabatho,
  },
  {
    path: "/overnight-delivery-mossel-bay",
    component: overnightdeliverymosselbay,
  },
  { path: "/overnight-delivery-musina", component: overnightdeliverymusina },
  {
    path: "/overnight-delivery-nelspruit",
    component: overnightdeliverynelspruit,
  },
  {
    path: "/overnight-delivery-oudtshoorn",
    component: overnightdeliveryoudtshoorn,
  },
  { path: "/overnight-delivery-paarl", component: overnightdeliverypaarl },
  {
    path: "/overnight-delivery-pietermaritzburg",
    component: overnightdeliverypietermaritzburg,
  },
  {
    path: "/overnight-delivery-polokwane",
    component: overnightdeliverypolokwane,
  },
  {
    path: "/overnight-delivery-port-elizabeth",
    component: overnightdeliveryportelizabeth,
  },
  {
    path: "/overnight-delivery-port-shepstone",
    component: overnightdeliveryportshepstone,
  },
  {
    path: "/overnight-delivery-potchefstroom",
    component: overnightdeliverypotchefstroom,
  },
  {
    path: "/overnight-delivery-pretoria",
    component: overnightdeliverypretoria,
  },
  {
    path: "/overnight-delivery-queenstown",
    component: overnightdeliveryqueenstown,
  },
  {
    path: "/overnight-delivery-randburg",
    component: overnightdeliveryrandburg,
  },
  {
    path: "/overnight-delivery-roodepoort",
    component: overnightdeliveryroodepoort,
  },
  {
    path: "/overnight-delivery-rustenburg",
    component: overnightdeliveryrustenburg,
  },
  {
    path: "/overnight-delivery-saldanha",
    component: overnightdeliverysaldanha,
  },
  { path: "/overnight-delivery-sandton", component: overnightdeliverysandton },
  { path: "/overnight-delivery-soweto", component: overnightdeliverysoweto },
  { path: "/overnight-delivery-springs", component: overnightdeliverysprings },
  {
    path: "/overnight-delivery-standerton",
    component: overnightdeliverystanderton,
  },
  {
    path: "/overnight-delivery-thohoyandou",
    component: overnightdeliverythohoyandou,
  },
  { path: "/overnight-delivery-tzaneen", component: overnightdeliverytzaneen },
  {
    path: "/overnight-delivery-uitenhage",
    component: overnightdeliveryuitenhage,
  },
  { path: "/overnight-delivery-ulundi", component: overnightdeliveryulundi },
  { path: "/overnight-delivery-umtata", component: overnightdeliveryumtata },
  {
    path: "/overnight-delivery-upington",
    component: overnightdeliveryupington,
  },
  {
    path: "/overnight-delivery-vanderbijlpark",
    component: overnightdeliveryvanderbijlpark,
  },
  {
    path: "/overnight-delivery-vereeniging",
    component: overnightdeliveryvereeniging,
  },
  {
    path: "/overnight-delivery-volksrust",
    component: overnightdeliveryvolksrust,
  },
  {
    path: "/overnight-delivery-vredenburg",
    component: overnightdeliveryvredenburg,
  },
  { path: "/overnight-delivery-vryburg", component: overnightdeliveryvryburg },
  { path: "/overnight-delivery-vryheid", component: overnightdeliveryvryheid },
  { path: "/overnight-delivery-welkom", component: overnightdeliverywelkom },
  {
    path: "/overnight-delivery-worcester",
    component: overnightdeliveryworcester,
  },
  { path: "/package-delivery-alberton", component: packagedeliveryalberton },
  {
    path: "/package-delivery-aliwal-north",
    component: packagedeliveryaliwalnorth,
  },
  {
    path: "/package-delivery-beaufort-west",
    component: packagedeliverybeaufortwest,
  },
  { path: "/package-delivery-benoni", component: packagedeliverybenoni },
  { path: "/package-delivery-bethal", component: packagedeliverybethal },
  { path: "/package-delivery-bhisho", component: packagedeliverybhisho },
  {
    path: "/package-delivery-bloemfontein",
    component: packagedeliverybloemfontein,
  },
  { path: "/package-delivery-brakpan", component: packagedeliverybrakpan },
  { path: "/package-delivery-brits", component: packagedeliverybrits },
  { path: "/package-delivery-cape-town", component: packagedeliverycapetown },
  {
    path: "/package-delivery-carletonville",
    component: packagedeliverycarletonville,
  },
  { path: "/package-delivery-centurion", component: packagedeliverycenturion },
  { path: "/package-delivery-cradock", component: packagedeliverycradock },
  { path: "/package-delivery-de-aar", component: packagedeliverydeaar },
  { path: "/package-delivery-durban", component: packagedeliverydurban },
  {
    path: "/package-delivery-east-london",
    component: packagedeliveryeastlondon,
  },
  { path: "/package-delivery-ferndale", component: packagedeliveryferndale },
  { path: "/package-delivery-george", component: packagedeliverygeorge },
  {
    path: "/package-delivery-graaff-reinet",
    component: packagedeliverygraaffreinet,
  },
  {
    path: "/package-delivery-grahamstown",
    component: packagedeliverygrahamstown,
  },
  { path: "/package-delivery-hermanus", component: packagedeliveryhermanus },
  {
    path: "/package-delivery-jeffreys-bay",
    component: packagedeliveryjeffreysbay,
  },
  {
    path: "/package-delivery-johannesburg",
    component: packagedeliveryjohannesburg,
  },
  { path: "/package-delivery-kimberley", component: packagedeliverykimberley },
  {
    path: "/package-delivery-klerksdorp",
    component: packagedeliveryklerksdorp,
  },
  { path: "/package-delivery-knysna", component: packagedeliveryknysna },
  { path: "/package-delivery-kroonstad", component: packagedeliverykroonstad },
  {
    path: "/package-delivery-kuilsrivier",
    component: packagedeliverykuilsrivier,
  },
  { path: "/package-delivery-ladysmith", component: packagedeliveryladysmith },
  {
    path: "/package-delivery-lebowakgomo",
    component: packagedeliverylebowakgomo,
  },
  {
    path: "/package-delivery-lichtenburg",
    component: packagedeliverylichtenburg,
  },
  { path: "/package-delivery-mahikeng", component: packagedeliverymahikeng },
  {
    path: "/package-delivery-malmesbury",
    component: packagedeliverymalmesbury,
  },
  {
    path: "/package-delivery-middelburg",
    component: packagedeliverymiddelburg,
  },
  { path: "/package-delivery-midrand", component: packagedeliverymidrand },
  { path: "/package-delivery-mmabatho", component: packagedeliverymmabatho },
  { path: "/package-delivery-mossel-bay", component: packagedeliverymosselbay },
  { path: "/package-delivery-musina", component: packagedeliverymusina },
  { path: "/package-delivery-nelspruit", component: packagedeliverynelspruit },
  {
    path: "/package-delivery-oudtshoorn",
    component: packagedeliveryoudtshoorn,
  },
  { path: "/package-delivery-paarl", component: packagedeliverypaarl },
  {
    path: "/package-delivery-pietermaritzburg",
    component: packagedeliverypietermaritzburg,
  },
  { path: "/package-delivery-polokwane", component: packagedeliverypolokwane },
  {
    path: "/package-delivery-port-elizabeth",
    component: packagedeliveryportelizabeth,
  },
  {
    path: "/package-delivery-port-shepstone",
    component: packagedeliveryportshepstone,
  },
  {
    path: "/package-delivery-potchefstroom",
    component: packagedeliverypotchefstroom,
  },
  { path: "/package-delivery-pretoria", component: packagedeliverypretoria },
  {
    path: "/package-delivery-queenstown",
    component: packagedeliveryqueenstown,
  },
  { path: "/package-delivery-randburg", component: packagedeliveryrandburg },
  {
    path: "/package-delivery-roodepoort",
    component: packagedeliveryroodepoort,
  },
  {
    path: "/package-delivery-rustenburg",
    component: packagedeliveryrustenburg,
  },
  { path: "/package-delivery-saldanha", component: packagedeliverysaldanha },
  { path: "/package-delivery-sandton", component: packagedeliverysandton },
  { path: "/package-delivery-soweto", component: packagedeliverysoweto },
  { path: "/package-delivery-springs", component: packagedeliverysprings },
  {
    path: "/package-delivery-standerton",
    component: packagedeliverystanderton,
  },
  {
    path: "/package-delivery-thohoyandou",
    component: packagedeliverythohoyandou,
  },
  { path: "/package-delivery-tzaneen", component: packagedeliverytzaneen },
  { path: "/package-delivery-uitenhage", component: packagedeliveryuitenhage },
  { path: "/package-delivery-ulundi", component: packagedeliveryulundi },
  { path: "/package-delivery-umtata", component: packagedeliveryumtata },
  { path: "/package-delivery-upington", component: packagedeliveryupington },
  {
    path: "/package-delivery-vanderbijlpark",
    component: packagedeliveryvanderbijlpark,
  },
  {
    path: "/package-delivery-vereeniging",
    component: packagedeliveryvereeniging,
  },
  { path: "/package-delivery-volksrust", component: packagedeliveryvolksrust },
  {
    path: "/package-delivery-vredenburg",
    component: packagedeliveryvredenburg,
  },
  { path: "/package-delivery-vryburg", component: packagedeliveryvryburg },
  { path: "/package-delivery-vryheid", component: packagedeliveryvryheid },
  { path: "/package-delivery-welkom", component: packagedeliverywelkom },
  { path: "/package-delivery-worcester", component: packagedeliveryworcester },
  { path: "/quick-truck-hire-alberton", component: quicktruckhirealberton },
  {
    path: "/quick-truck-hire-aliwal-north",
    component: quicktruckhirealiwalnorth,
  },
  {
    path: "/quick-truck-hire-beaufort-west",
    component: quicktruckhirebeaufortwest,
  },
  { path: "/quick-truck-hire-benoni", component: quicktruckhirebenoni },
  { path: "/quick-truck-hire-bethal", component: quicktruckhirebethal },
  { path: "/quick-truck-hire-bhisho", component: quicktruckhirebhisho },
  {
    path: "/quick-truck-hire-bloemfontein",
    component: quicktruckhirebloemfontein,
  },
  { path: "/quick-truck-hire-brakpan", component: quicktruckhirebrakpan },
  { path: "/quick-truck-hire-brits", component: quicktruckhirebrits },
  { path: "/quick-truck-hire-cape-town", component: quicktruckhirecapetown },
  {
    path: "/quick-truck-hire-carletonville",
    component: quicktruckhirecarletonville,
  },
  { path: "/quick-truck-hire-centurion", component: quicktruckhirecenturion },
  { path: "/quick-truck-hire-cradock", component: quicktruckhirecradock },
  { path: "/quick-truck-hire-de-aar", component: quicktruckhiredeaar },
  { path: "/quick-truck-hire-durban", component: quicktruckhiredurban },
  {
    path: "/quick-truck-hire-east-london",
    component: quicktruckhireeastlondon,
  },
  { path: "/quick-truck-hire-ferndale", component: quicktruckhireferndale },
  { path: "/quick-truck-hire-george", component: quicktruckhiregeorge },
  {
    path: "/quick-truck-hire-graaff-reinet",
    component: quicktruckhiregraaffreinet,
  },
  {
    path: "/quick-truck-hire-grahamstown",
    component: quicktruckhiregrahamstown,
  },
  { path: "/quick-truck-hire-hermanus", component: quicktruckhirehermanus },
  {
    path: "/quick-truck-hire-jeffreys-bay",
    component: quicktruckhirejeffreysbay,
  },
  {
    path: "/quick-truck-hire-johannesburg",
    component: quicktruckhirejohannesburg,
  },
  { path: "/quick-truck-hire-kimberley", component: quicktruckhirekimberley },
  { path: "/quick-truck-hire-klerksdorp", component: quicktruckhireklerksdorp },
  { path: "/quick-truck-hire-knysna", component: quicktruckhireknysna },
  { path: "/quick-truck-hire-kroonstad", component: quicktruckhirekroonstad },
  {
    path: "/quick-truck-hire-kuilsrivier",
    component: quicktruckhirekuilsrivier,
  },
  { path: "/quick-truck-hire-ladysmith", component: quicktruckhireladysmith },
  {
    path: "/quick-truck-hire-lebowakgomo",
    component: quicktruckhirelebowakgomo,
  },
  {
    path: "/quick-truck-hire-lichtenburg",
    component: quicktruckhirelichtenburg,
  },
  { path: "/quick-truck-hire-mahikeng", component: quicktruckhiremahikeng },
  { path: "/quick-truck-hire-malmesbury", component: quicktruckhiremalmesbury },
  { path: "/quick-truck-hire-middelburg", component: quicktruckhiremiddelburg },
  { path: "/quick-truck-hire-midrand", component: quicktruckhiremidrand },
  { path: "/quick-truck-hire-mmabatho", component: quicktruckhiremmabatho },
  { path: "/quick-truck-hire-mossel-bay", component: quicktruckhiremosselbay },
  { path: "/quick-truck-hire-musina", component: quicktruckhiremusina },
  { path: "/quick-truck-hire-nelspruit", component: quicktruckhirenelspruit },
  { path: "/quick-truck-hire-oudtshoorn", component: quicktruckhireoudtshoorn },
  { path: "/quick-truck-hire-paarl", component: quicktruckhirepaarl },
  {
    path: "/quick-truck-hire-pietermaritzburg",
    component: quicktruckhirepietermaritzburg,
  },
  { path: "/quick-truck-hire-polokwane", component: quicktruckhirepolokwane },
  {
    path: "/quick-truck-hire-port-elizabeth",
    component: quicktruckhireportelizabeth,
  },
  {
    path: "/quick-truck-hire-port-shepstone",
    component: quicktruckhireportshepstone,
  },
  {
    path: "/quick-truck-hire-potchefstroom",
    component: quicktruckhirepotchefstroom,
  },
  { path: "/quick-truck-hire-pretoria", component: quicktruckhirepretoria },
  { path: "/quick-truck-hire-queenstown", component: quicktruckhirequeenstown },
  { path: "/quick-truck-hire-randburg", component: quicktruckhirerandburg },
  { path: "/quick-truck-hire-roodepoort", component: quicktruckhireroodepoort },
  { path: "/quick-truck-hire-rustenburg", component: quicktruckhirerustenburg },
  { path: "/quick-truck-hire-saldanha", component: quicktruckhiresaldanha },
  { path: "/quick-truck-hire-sandton", component: quicktruckhiresandton },
  { path: "/quick-truck-hire-soweto", component: quicktruckhiresoweto },
  { path: "/quick-truck-hire-springs", component: quicktruckhiresprings },
  { path: "/quick-truck-hire-standerton", component: quicktruckhirestanderton },
  {
    path: "/quick-truck-hire-thohoyandou",
    component: quicktruckhirethohoyandou,
  },
  { path: "/quick-truck-hire-tzaneen", component: quicktruckhiretzaneen },
  { path: "/quick-truck-hire-uitenhage", component: quicktruckhireuitenhage },
  { path: "/quick-truck-hire-ulundi", component: quicktruckhireulundi },
  { path: "/quick-truck-hire-umtata", component: quicktruckhireumtata },
  { path: "/quick-truck-hire-upington", component: quicktruckhireupington },
  {
    path: "/quick-truck-hire-vanderbijlpark",
    component: quicktruckhirevanderbijlpark,
  },
  {
    path: "/quick-truck-hire-vereeniging",
    component: quicktruckhirevereeniging,
  },
  { path: "/quick-truck-hire-volksrust", component: quicktruckhirevolksrust },
  { path: "/quick-truck-hire-vredenburg", component: quicktruckhirevredenburg },
  { path: "/quick-truck-hire-vryburg", component: quicktruckhirevryburg },
  { path: "/quick-truck-hire-vryheid", component: quicktruckhirevryheid },
  { path: "/quick-truck-hire-welkom", component: quicktruckhirewelkom },
  { path: "/quick-truck-hire-worcester", component: quicktruckhireworcester },
  { path: "/quick-truck-rental-alberton", component: quicktruckrentalalberton },
  {
    path: "/quick-truck-rental-aliwal-north",
    component: quicktruckrentalaliwalnorth,
  },
  {
    path: "/quick-truck-rental-beaufort-west",
    component: quicktruckrentalbeaufortwest,
  },
  { path: "/quick-truck-rental-benoni", component: quicktruckrentalbenoni },
  { path: "/quick-truck-rental-bethal", component: quicktruckrentalbethal },
  { path: "/quick-truck-rental-bhisho", component: quicktruckrentalbhisho },
  {
    path: "/quick-truck-rental-bloemfontein",
    component: quicktruckrentalbloemfontein,
  },
  { path: "/quick-truck-rental-brakpan", component: quicktruckrentalbrakpan },
  { path: "/quick-truck-rental-brits", component: quicktruckrentalbrits },
  {
    path: "/quick-truck-rental-cape-town",
    component: quicktruckrentalcapetown,
  },
  {
    path: "/quick-truck-rental-carletonville",
    component: quicktruckrentalcarletonville,
  },
  {
    path: "/quick-truck-rental-centurion",
    component: quicktruckrentalcenturion,
  },
  { path: "/quick-truck-rental-cradock", component: quicktruckrentalcradock },
  { path: "/quick-truck-rental-de-aar", component: quicktruckrentaldeaar },
  { path: "/quick-truck-rental-durban", component: quicktruckrentaldurban },
  {
    path: "/quick-truck-rental-east-london",
    component: quicktruckrentaleastlondon,
  },
  { path: "/quick-truck-rental-ferndale", component: quicktruckrentalferndale },
  { path: "/quick-truck-rental-george", component: quicktruckrentalgeorge },
  {
    path: "/quick-truck-rental-graaff-reinet",
    component: quicktruckrentalgraaffreinet,
  },
  {
    path: "/quick-truck-rental-grahamstown",
    component: quicktruckrentalgrahamstown,
  },
  { path: "/quick-truck-rental-hermanus", component: quicktruckrentalhermanus },
  {
    path: "/quick-truck-rental-jeffreys-bay",
    component: quicktruckrentaljeffreysbay,
  },
  {
    path: "/quick-truck-rental-johannesburg",
    component: quicktruckrentaljohannesburg,
  },
  {
    path: "/quick-truck-rental-kimberley",
    component: quicktruckrentalkimberley,
  },
  {
    path: "/quick-truck-rental-klerksdorp",
    component: quicktruckrentalklerksdorp,
  },
  { path: "/quick-truck-rental-knysna", component: quicktruckrentalknysna },
  {
    path: "/quick-truck-rental-kroonstad",
    component: quicktruckrentalkroonstad,
  },
  {
    path: "/quick-truck-rental-kuilsrivier",
    component: quicktruckrentalkuilsrivier,
  },
  {
    path: "/quick-truck-rental-ladysmith",
    component: quicktruckrentalladysmith,
  },
  {
    path: "/quick-truck-rental-lebowakgomo",
    component: quicktruckrentallebowakgomo,
  },
  {
    path: "/quick-truck-rental-lichtenburg",
    component: quicktruckrentallichtenburg,
  },
  { path: "/quick-truck-rental-mahikeng", component: quicktruckrentalmahikeng },
  {
    path: "/quick-truck-rental-malmesbury",
    component: quicktruckrentalmalmesbury,
  },
  {
    path: "/quick-truck-rental-middelburg",
    component: quicktruckrentalmiddelburg,
  },
  { path: "/quick-truck-rental-midrand", component: quicktruckrentalmidrand },
  { path: "/quick-truck-rental-mmabatho", component: quicktruckrentalmmabatho },
  {
    path: "/quick-truck-rental-mossel-bay",
    component: quicktruckrentalmosselbay,
  },
  { path: "/quick-truck-rental-musina", component: quicktruckrentalmusina },
  {
    path: "/quick-truck-rental-nelspruit",
    component: quicktruckrentalnelspruit,
  },
  {
    path: "/quick-truck-rental-oudtshoorn",
    component: quicktruckrentaloudtshoorn,
  },
  { path: "/quick-truck-rental-paarl", component: quicktruckrentalpaarl },
  {
    path: "/quick-truck-rental-pietermaritzburg",
    component: quicktruckrentalpietermaritzburg,
  },
  {
    path: "/quick-truck-rental-polokwane",
    component: quicktruckrentalpolokwane,
  },
  {
    path: "/quick-truck-rental-port-elizabeth",
    component: quicktruckrentalportelizabeth,
  },
  {
    path: "/quick-truck-rental-port-shepstone",
    component: quicktruckrentalportshepstone,
  },
  {
    path: "/quick-truck-rental-potchefstroom",
    component: quicktruckrentalpotchefstroom,
  },
  { path: "/quick-truck-rental-pretoria", component: quicktruckrentalpretoria },
  {
    path: "/quick-truck-rental-queenstown",
    component: quicktruckrentalqueenstown,
  },
  { path: "/quick-truck-rental-randburg", component: quicktruckrentalrandburg },
  {
    path: "/quick-truck-rental-roodepoort",
    component: quicktruckrentalroodepoort,
  },
  {
    path: "/quick-truck-rental-rustenburg",
    component: quicktruckrentalrustenburg,
  },
  { path: "/quick-truck-rental-saldanha", component: quicktruckrentalsaldanha },
  { path: "/quick-truck-rental-sandton", component: quicktruckrentalsandton },
  { path: "/quick-truck-rental-soweto", component: quicktruckrentalsoweto },
  { path: "/quick-truck-rental-springs", component: quicktruckrentalsprings },
  {
    path: "/quick-truck-rental-standerton",
    component: quicktruckrentalstanderton,
  },
  {
    path: "/quick-truck-rental-thohoyandou",
    component: quicktruckrentalthohoyandou,
  },
  { path: "/quick-truck-rental-tzaneen", component: quicktruckrentaltzaneen },
  {
    path: "/quick-truck-rental-uitenhage",
    component: quicktruckrentaluitenhage,
  },
  { path: "/quick-truck-rental-ulundi", component: quicktruckrentalulundi },
  { path: "/quick-truck-rental-umtata", component: quicktruckrentalumtata },
  { path: "/quick-truck-rental-upington", component: quicktruckrentalupington },
  {
    path: "/quick-truck-rental-vanderbijlpark",
    component: quicktruckrentalvanderbijlpark,
  },
  {
    path: "/quick-truck-rental-vereeniging",
    component: quicktruckrentalvereeniging,
  },
  {
    path: "/quick-truck-rental-volksrust",
    component: quicktruckrentalvolksrust,
  },
  {
    path: "/quick-truck-rental-vredenburg",
    component: quicktruckrentalvredenburg,
  },
  { path: "/quick-truck-rental-vryburg", component: quicktruckrentalvryburg },
  { path: "/quick-truck-rental-vryheid", component: quicktruckrentalvryheid },
  { path: "/quick-truck-rental-welkom", component: quicktruckrentalwelkom },
  {
    path: "/quick-truck-rental-worcester",
    component: quicktruckrentalworcester,
  },
  { path: "/removals-alberton", component: removalsalberton },
  { path: "/removals-aliwal-north", component: removalsaliwalnorth },
  { path: "/removals-beaufort-west", component: removalsbeaufortwest },
  { path: "/removals-benoni", component: removalsbenoni },
  { path: "/removals-bethal", component: removalsbethal },
  { path: "/removals-bhisho", component: removalsbhisho },
  { path: "/removals-bloemfontein", component: removalsbloemfontein },
  { path: "/removals-brakpan", component: removalsbrakpan },
  { path: "/removals-brits", component: removalsbrits },
  { path: "/removals-cape-town", component: removalscapetown },
  { path: "/removals-carletonville", component: removalscarletonville },
  { path: "/removals-centurion", component: removalscenturion },
  { path: "/removals-cradock", component: removalscradock },
  { path: "/removals-de-aar", component: removalsdeaar },
  { path: "/removals-durban", component: removalsdurban },
  { path: "/removals-east-london", component: removalseastlondon },
  { path: "/removals-ferndale", component: removalsferndale },
  { path: "/removals-george", component: removalsgeorge },
  { path: "/removals-graaff-reinet", component: removalsgraaffreinet },
  { path: "/removals-grahamstown", component: removalsgrahamstown },
  { path: "/removals-hermanus", component: removalshermanus },
  { path: "/removals-jeffreys-bay", component: removalsjeffreysbay },
  { path: "/removals-johannesburg", component: removalsjohannesburg },
  { path: "/removals-kimberley", component: removalskimberley },
  { path: "/removals-klerksdorp", component: removalsklerksdorp },
  { path: "/removals-knysna", component: removalsknysna },
  { path: "/removals-kroonstad", component: removalskroonstad },
  { path: "/removals-kuilsrivier", component: removalskuilsrivier },
  { path: "/removals-ladysmith", component: removalsladysmith },
  { path: "/removals-lebowakgomo", component: removalslebowakgomo },
  { path: "/removals-lichtenburg", component: removalslichtenburg },
  { path: "/removals-mahikeng", component: removalsmahikeng },
  { path: "/removals-malmesbury", component: removalsmalmesbury },
  { path: "/removals-middelburg", component: removalsmiddelburg },
  { path: "/removals-midrand", component: removalsmidrand },
  { path: "/removals-mmabatho", component: removalsmmabatho },
  { path: "/removals-mossel-bay", component: removalsmosselbay },
  { path: "/removals-musina", component: removalsmusina },
  { path: "/removals-nelspruit", component: removalsnelspruit },
  { path: "/removals-oudtshoorn", component: removalsoudtshoorn },
  { path: "/removals-paarl", component: removalspaarl },
  { path: "/removals-pietermaritzburg", component: removalspietermaritzburg },
  { path: "/removals-polokwane", component: removalspolokwane },
  { path: "/removals-port-elizabeth", component: removalsportelizabeth },
  { path: "/removals-port-shepstone", component: removalsportshepstone },
  { path: "/removals-potchefstroom", component: removalspotchefstroom },
  { path: "/removals-pretoria", component: removalspretoria },
  { path: "/removals-queenstown", component: removalsqueenstown },
  { path: "/removals-randburg", component: removalsrandburg },
  { path: "/removals-roodepoort", component: removalsroodepoort },
  { path: "/removals-rustenburg", component: removalsrustenburg },
  { path: "/removals-saldanha", component: removalssaldanha },
  { path: "/removals-sandton", component: removalssandton },
  { path: "/removals-soweto", component: removalssoweto },
  { path: "/removals-springs", component: removalssprings },
  { path: "/removals-standerton", component: removalsstanderton },
  { path: "/removals-thohoyandou", component: removalsthohoyandou },
  { path: "/removals-tzaneen", component: removalstzaneen },
  { path: "/removals-uitenhage", component: removalsuitenhage },
  { path: "/removals-ulundi", component: removalsulundi },
  { path: "/removals-umtata", component: removalsumtata },
  { path: "/removals-upington", component: removalsupington },
  { path: "/removals-vanderbijlpark", component: removalsvanderbijlpark },
  { path: "/removals-vereeniging", component: removalsvereeniging },
  { path: "/removals-volksrust", component: removalsvolksrust },
  { path: "/removals-vredenburg", component: removalsvredenburg },
  { path: "/removals-vryburg", component: removalsvryburg },
  { path: "/removals-vryheid", component: removalsvryheid },
  { path: "/removals-welkom", component: removalswelkom },
  { path: "/removals-worcester", component: removalsworcester },
  { path: "/rent-a-bakkie-alberton", component: rentabakkiealberton },
  { path: "/rent-a-bakkie-aliwal-north", component: rentabakkiealiwalnorth },
  { path: "/rent-a-bakkie-beaufort-west", component: rentabakkiebeaufortwest },
  { path: "/rent-a-bakkie-benoni", component: rentabakkiebenoni },
  { path: "/rent-a-bakkie-bethal", component: rentabakkiebethal },
  { path: "/rent-a-bakkie-bhisho", component: rentabakkiebhisho },
  { path: "/rent-a-bakkie-bloemfontein", component: rentabakkiebloemfontein },
  { path: "/rent-a-bakkie-brakpan", component: rentabakkiebrakpan },
  { path: "/rent-a-bakkie-brits", component: rentabakkiebrits },
  { path: "/rent-a-bakkie-cape-town", component: rentabakkiecapetown },
  { path: "/rent-a-bakkie-carletonville", component: rentabakkiecarletonville },
  { path: "/rent-a-bakkie-centurion", component: rentabakkiecenturion },
  { path: "/rent-a-bakkie-cradock", component: rentabakkiecradock },
  { path: "/rent-a-bakkie-de-aar", component: rentabakkiedeaar },
  { path: "/rent-a-bakkie-durban", component: rentabakkiedurban },
  { path: "/rent-a-bakkie-east-london", component: rentabakkieeastlondon },
  { path: "/rent-a-bakkie-ferndale", component: rentabakkieferndale },
  { path: "/rent-a-bakkie-george", component: rentabakkiegeorge },
  { path: "/rent-a-bakkie-graaff-reinet", component: rentabakkiegraaffreinet },
  { path: "/rent-a-bakkie-grahamstown", component: rentabakkiegrahamstown },
  { path: "/rent-a-bakkie-hermanus", component: rentabakkiehermanus },
  { path: "/rent-a-bakkie-jeffreys-bay", component: rentabakkiejeffreysbay },
  { path: "/rent-a-bakkie-johannesburg", component: rentabakkiejohannesburg },
  { path: "/rent-a-bakkie-kimberley", component: rentabakkiekimberley },
  { path: "/rent-a-bakkie-klerksdorp", component: rentabakkieklerksdorp },
  { path: "/rent-a-bakkie-knysna", component: rentabakkieknysna },
  { path: "/rent-a-bakkie-kroonstad", component: rentabakkiekroonstad },
  { path: "/rent-a-bakkie-kuilsrivier", component: rentabakkiekuilsrivier },
  { path: "/rent-a-bakkie-ladysmith", component: rentabakkieladysmith },
  { path: "/rent-a-bakkie-lebowakgomo", component: rentabakkielebowakgomo },
  { path: "/rent-a-bakkie-lichtenburg", component: rentabakkielichtenburg },
  { path: "/rent-a-bakkie-mahikeng", component: rentabakkiemahikeng },
  { path: "/rent-a-bakkie-malmesbury", component: rentabakkiemalmesbury },
  { path: "/rent-a-bakkie-middelburg", component: rentabakkiemiddelburg },
  { path: "/rent-a-bakkie-midrand", component: rentabakkiemidrand },
  { path: "/rent-a-bakkie-mmabatho", component: rentabakkiemmabatho },
  { path: "/rent-a-bakkie-mossel-bay", component: rentabakkiemosselbay },
  { path: "/rent-a-bakkie-musina", component: rentabakkiemusina },
  { path: "/rent-a-bakkie-nelspruit", component: rentabakkienelspruit },
  { path: "/rent-a-bakkie-oudtshoorn", component: rentabakkieoudtshoorn },
  { path: "/rent-a-bakkie-paarl", component: rentabakkiepaarl },
  {
    path: "/rent-a-bakkie-pietermaritzburg",
    component: rentabakkiepietermaritzburg,
  },
  { path: "/rent-a-bakkie-polokwane", component: rentabakkiepolokwane },
  {
    path: "/rent-a-bakkie-port-elizabeth",
    component: rentabakkieportelizabeth,
  },
  {
    path: "/rent-a-bakkie-port-shepstone",
    component: rentabakkieportshepstone,
  },
  { path: "/rent-a-bakkie-potchefstroom", component: rentabakkiepotchefstroom },
  { path: "/rent-a-bakkie-pretoria", component: rentabakkiepretoria },
  { path: "/rent-a-bakkie-queenstown", component: rentabakkiequeenstown },
  { path: "/rent-a-bakkie-randburg", component: rentabakkierandburg },
  { path: "/rent-a-bakkie-roodepoort", component: rentabakkieroodepoort },
  { path: "/rent-a-bakkie-rustenburg", component: rentabakkierustenburg },
  { path: "/rent-a-bakkie-saldanha", component: rentabakkiesaldanha },
  { path: "/rent-a-bakkie-sandton", component: rentabakkiesandton },
  { path: "/rent-a-bakkie-soweto", component: rentabakkiesoweto },
  { path: "/rent-a-bakkie-springs", component: rentabakkiesprings },
  { path: "/rent-a-bakkie-standerton", component: rentabakkiestanderton },
  { path: "/rent-a-bakkie-thohoyandou", component: rentabakkiethohoyandou },
  { path: "/rent-a-bakkie-tzaneen", component: rentabakkietzaneen },
  { path: "/rent-a-bakkie-uitenhage", component: rentabakkieuitenhage },
  { path: "/rent-a-bakkie-ulundi", component: rentabakkieulundi },
  { path: "/rent-a-bakkie-umtata", component: rentabakkieumtata },
  { path: "/rent-a-bakkie-upington", component: rentabakkieupington },
  {
    path: "/rent-a-bakkie-vanderbijlpark",
    component: rentabakkievanderbijlpark,
  },
  { path: "/rent-a-bakkie-vereeniging", component: rentabakkievereeniging },
  { path: "/rent-a-bakkie-volksrust", component: rentabakkievolksrust },
  { path: "/rent-a-bakkie-vredenburg", component: rentabakkievredenburg },
  { path: "/rent-a-bakkie-vryburg", component: rentabakkievryburg },
  { path: "/rent-a-bakkie-vryheid", component: rentabakkievryheid },
  { path: "/rent-a-bakkie-welkom", component: rentabakkiewelkom },
  { path: "/rent-a-bakkie-worcester", component: rentabakkieworcester },
  { path: "/retail-delivery-alberton", component: retaildeliveryalberton },
  {
    path: "/retail-delivery-aliwal-north",
    component: retaildeliveryaliwalnorth,
  },
  {
    path: "/retail-delivery-beaufort-west",
    component: retaildeliverybeaufortwest,
  },
  { path: "/retail-delivery-benoni", component: retaildeliverybenoni },
  { path: "/retail-delivery-bethal", component: retaildeliverybethal },
  { path: "/retail-delivery-bhisho", component: retaildeliverybhisho },
  {
    path: "/retail-delivery-bloemfontein",
    component: retaildeliverybloemfontein,
  },
  { path: "/retail-delivery-brakpan", component: retaildeliverybrakpan },
  { path: "/retail-delivery-brits", component: retaildeliverybrits },
  { path: "/retail-delivery-cape-town", component: retaildeliverycapetown },
  {
    path: "/retail-delivery-carletonville",
    component: retaildeliverycarletonville,
  },
  { path: "/retail-delivery-centurion", component: retaildeliverycenturion },
  { path: "/retail-delivery-cradock", component: retaildeliverycradock },
  { path: "/retail-delivery-de-aar", component: retaildeliverydeaar },
  { path: "/retail-delivery-durban", component: retaildeliverydurban },
  { path: "/retail-delivery-east-london", component: retaildeliveryeastlondon },
  { path: "/retail-delivery-ferndale", component: retaildeliveryferndale },
  { path: "/retail-delivery-george", component: retaildeliverygeorge },
  {
    path: "/retail-delivery-graaff-reinet",
    component: retaildeliverygraaffreinet,
  },
  {
    path: "/retail-delivery-grahamstown",
    component: retaildeliverygrahamstown,
  },
  { path: "/retail-delivery-hermanus", component: retaildeliveryhermanus },
  {
    path: "/retail-delivery-jeffreys-bay",
    component: retaildeliveryjeffreysbay,
  },
  {
    path: "/retail-delivery-johannesburg",
    component: retaildeliveryjohannesburg,
  },
  { path: "/retail-delivery-kimberley", component: retaildeliverykimberley },
  { path: "/retail-delivery-klerksdorp", component: retaildeliveryklerksdorp },
  { path: "/retail-delivery-knysna", component: retaildeliveryknysna },
  { path: "/retail-delivery-kroonstad", component: retaildeliverykroonstad },
  {
    path: "/retail-delivery-kuilsrivier",
    component: retaildeliverykuilsrivier,
  },
  { path: "/retail-delivery-ladysmith", component: retaildeliveryladysmith },
  {
    path: "/retail-delivery-lebowakgomo",
    component: retaildeliverylebowakgomo,
  },
  {
    path: "/retail-delivery-lichtenburg",
    component: retaildeliverylichtenburg,
  },
  { path: "/retail-delivery-mahikeng", component: retaildeliverymahikeng },
  { path: "/retail-delivery-malmesbury", component: retaildeliverymalmesbury },
  { path: "/retail-delivery-middelburg", component: retaildeliverymiddelburg },
  { path: "/retail-delivery-midrand", component: retaildeliverymidrand },
  { path: "/retail-delivery-mmabatho", component: retaildeliverymmabatho },
  { path: "/retail-delivery-mossel-bay", component: retaildeliverymosselbay },
  { path: "/retail-delivery-musina", component: retaildeliverymusina },
  { path: "/retail-delivery-nelspruit", component: retaildeliverynelspruit },
  { path: "/retail-delivery-oudtshoorn", component: retaildeliveryoudtshoorn },
  { path: "/retail-delivery-paarl", component: retaildeliverypaarl },
  {
    path: "/retail-delivery-pietermaritzburg",
    component: retaildeliverypietermaritzburg,
  },
  { path: "/retail-delivery-polokwane", component: retaildeliverypolokwane },
  {
    path: "/retail-delivery-port-elizabeth",
    component: retaildeliveryportelizabeth,
  },
  {
    path: "/retail-delivery-port-shepstone",
    component: retaildeliveryportshepstone,
  },
  {
    path: "/retail-delivery-potchefstroom",
    component: retaildeliverypotchefstroom,
  },
  { path: "/retail-delivery-pretoria", component: retaildeliverypretoria },
  { path: "/retail-delivery-queenstown", component: retaildeliveryqueenstown },
  { path: "/retail-delivery-randburg", component: retaildeliveryrandburg },
  { path: "/retail-delivery-roodepoort", component: retaildeliveryroodepoort },
  { path: "/retail-delivery-rustenburg", component: retaildeliveryrustenburg },
  { path: "/retail-delivery-saldanha", component: retaildeliverysaldanha },
  { path: "/retail-delivery-sandton", component: retaildeliverysandton },
  { path: "/retail-delivery-soweto", component: retaildeliverysoweto },
  { path: "/retail-delivery-springs", component: retaildeliverysprings },
  { path: "/retail-delivery-standerton", component: retaildeliverystanderton },
  {
    path: "/retail-delivery-thohoyandou",
    component: retaildeliverythohoyandou,
  },
  { path: "/retail-delivery-tzaneen", component: retaildeliverytzaneen },
  { path: "/retail-delivery-uitenhage", component: retaildeliveryuitenhage },
  { path: "/retail-delivery-ulundi", component: retaildeliveryulundi },
  { path: "/retail-delivery-umtata", component: retaildeliveryumtata },
  { path: "/retail-delivery-upington", component: retaildeliveryupington },
  {
    path: "/retail-delivery-vanderbijlpark",
    component: retaildeliveryvanderbijlpark,
  },
  {
    path: "/retail-delivery-vereeniging",
    component: retaildeliveryvereeniging,
  },
  { path: "/retail-delivery-volksrust", component: retaildeliveryvolksrust },
  { path: "/retail-delivery-vredenburg", component: retaildeliveryvredenburg },
  { path: "/retail-delivery-vryburg", component: retaildeliveryvryburg },
  { path: "/retail-delivery-vryheid", component: retaildeliveryvryheid },
  { path: "/retail-delivery-welkom", component: retaildeliverywelkom },
  { path: "/retail-delivery-worcester", component: retaildeliveryworcester },
  { path: "/same-day-delivery-alberton", component: samedaydeliveryalberton },
  {
    path: "/same-day-delivery-aliwal-north",
    component: samedaydeliveryaliwalnorth,
  },
  {
    path: "/same-day-delivery-beaufort-west",
    component: samedaydeliverybeaufortwest,
  },
  { path: "/same-day-delivery-benoni", component: samedaydeliverybenoni },
  { path: "/same-day-delivery-bethal", component: samedaydeliverybethal },
  { path: "/same-day-delivery-bhisho", component: samedaydeliverybhisho },
  {
    path: "/same-day-delivery-bloemfontein",
    component: samedaydeliverybloemfontein,
  },
  { path: "/same-day-delivery-brakpan", component: samedaydeliverybrakpan },
  { path: "/same-day-delivery-brits", component: samedaydeliverybrits },
  { path: "/same-day-delivery-cape-town", component: samedaydeliverycapetown },
  {
    path: "/same-day-delivery-carletonville",
    component: samedaydeliverycarletonville,
  },
  { path: "/same-day-delivery-centurion", component: samedaydeliverycenturion },
  { path: "/same-day-delivery-cradock", component: samedaydeliverycradock },
  { path: "/same-day-delivery-de-aar", component: samedaydeliverydeaar },
  { path: "/same-day-delivery-durban", component: samedaydeliverydurban },
  {
    path: "/same-day-delivery-east-london",
    component: samedaydeliveryeastlondon,
  },
  { path: "/same-day-delivery-ferndale", component: samedaydeliveryferndale },
  { path: "/same-day-delivery-george", component: samedaydeliverygeorge },
  {
    path: "/same-day-delivery-graaff-reinet",
    component: samedaydeliverygraaffreinet,
  },
  {
    path: "/same-day-delivery-grahamstown",
    component: samedaydeliverygrahamstown,
  },
  { path: "/same-day-delivery-hermanus", component: samedaydeliveryhermanus },
  {
    path: "/same-day-delivery-jeffreys-bay",
    component: samedaydeliveryjeffreysbay,
  },
  {
    path: "/same-day-delivery-johannesburg",
    component: samedaydeliveryjohannesburg,
  },
  { path: "/same-day-delivery-kimberley", component: samedaydeliverykimberley },
  {
    path: "/same-day-delivery-klerksdorp",
    component: samedaydeliveryklerksdorp,
  },
  { path: "/same-day-delivery-knysna", component: samedaydeliveryknysna },
  { path: "/same-day-delivery-kroonstad", component: samedaydeliverykroonstad },
  {
    path: "/same-day-delivery-kuilsrivier",
    component: samedaydeliverykuilsrivier,
  },
  { path: "/same-day-delivery-ladysmith", component: samedaydeliveryladysmith },
  {
    path: "/same-day-delivery-lebowakgomo",
    component: samedaydeliverylebowakgomo,
  },
  {
    path: "/same-day-delivery-lichtenburg",
    component: samedaydeliverylichtenburg,
  },
  { path: "/same-day-delivery-mahikeng", component: samedaydeliverymahikeng },
  {
    path: "/same-day-delivery-malmesbury",
    component: samedaydeliverymalmesbury,
  },
  {
    path: "/same-day-delivery-middelburg",
    component: samedaydeliverymiddelburg,
  },
  { path: "/same-day-delivery-midrand", component: samedaydeliverymidrand },
  { path: "/same-day-delivery-mmabatho", component: samedaydeliverymmabatho },
  {
    path: "/same-day-delivery-mossel-bay",
    component: samedaydeliverymosselbay,
  },
  { path: "/same-day-delivery-musina", component: samedaydeliverymusina },
  { path: "/same-day-delivery-nelspruit", component: samedaydeliverynelspruit },
  {
    path: "/same-day-delivery-oudtshoorn",
    component: samedaydeliveryoudtshoorn,
  },
  { path: "/same-day-delivery-paarl", component: samedaydeliverypaarl },
  {
    path: "/same-day-delivery-pietermaritzburg",
    component: samedaydeliverypietermaritzburg,
  },
  { path: "/same-day-delivery-polokwane", component: samedaydeliverypolokwane },
  {
    path: "/same-day-delivery-port-elizabeth",
    component: samedaydeliveryportelizabeth,
  },
  {
    path: "/same-day-delivery-port-shepstone",
    component: samedaydeliveryportshepstone,
  },
  {
    path: "/same-day-delivery-potchefstroom",
    component: samedaydeliverypotchefstroom,
  },
  { path: "/same-day-delivery-pretoria", component: samedaydeliverypretoria },
  {
    path: "/same-day-delivery-queenstown",
    component: samedaydeliveryqueenstown,
  },
  { path: "/same-day-delivery-randburg", component: samedaydeliveryrandburg },
  {
    path: "/same-day-delivery-roodepoort",
    component: samedaydeliveryroodepoort,
  },
  {
    path: "/same-day-delivery-rustenburg",
    component: samedaydeliveryrustenburg,
  },
  { path: "/same-day-delivery-saldanha", component: samedaydeliverysaldanha },
  { path: "/same-day-delivery-sandton", component: samedaydeliverysandton },
  { path: "/same-day-delivery-soweto", component: samedaydeliverysoweto },
  { path: "/same-day-delivery-springs", component: samedaydeliverysprings },
  {
    path: "/same-day-delivery-standerton",
    component: samedaydeliverystanderton,
  },
  {
    path: "/same-day-delivery-thohoyandou",
    component: samedaydeliverythohoyandou,
  },
  { path: "/same-day-delivery-tzaneen", component: samedaydeliverytzaneen },
  { path: "/same-day-delivery-uitenhage", component: samedaydeliveryuitenhage },
  { path: "/same-day-delivery-ulundi", component: samedaydeliveryulundi },
  { path: "/same-day-delivery-umtata", component: samedaydeliveryumtata },
  { path: "/same-day-delivery-upington", component: samedaydeliveryupington },
  {
    path: "/same-day-delivery-vanderbijlpark",
    component: samedaydeliveryvanderbijlpark,
  },
  {
    path: "/same-day-delivery-vereeniging",
    component: samedaydeliveryvereeniging,
  },
  { path: "/same-day-delivery-volksrust", component: samedaydeliveryvolksrust },
  {
    path: "/same-day-delivery-vredenburg",
    component: samedaydeliveryvredenburg,
  },
  { path: "/same-day-delivery-vryburg", component: samedaydeliveryvryburg },
  { path: "/same-day-delivery-vryheid", component: samedaydeliveryvryheid },
  { path: "/same-day-delivery-welkom", component: samedaydeliverywelkom },
  { path: "/same-day-delivery-worcester", component: samedaydeliveryworcester },
  { path: "/transportation-alberton", component: transportationalberton },
  {
    path: "/transportation-aliwal-north",
    component: transportationaliwalnorth,
  },
  {
    path: "/transportation-beaufort-west",
    component: transportationbeaufortwest,
  },
  { path: "/transportation-benoni", component: transportationbenoni },
  { path: "/transportation-bethal", component: transportationbethal },
  { path: "/transportation-bhisho", component: transportationbhisho },
  {
    path: "/transportation-bloemfontein",
    component: transportationbloemfontein,
  },
  { path: "/transportation-brakpan", component: transportationbrakpan },
  { path: "/transportation-brits", component: transportationbrits },
  { path: "/transportation-cape-town", component: transportationcapetown },
  {
    path: "/transportation-carletonville",
    component: transportationcarletonville,
  },
  { path: "/transportation-centurion", component: transportationcenturion },
  {
    path: "/transportation-company-alberton",
    component: transportationcompanyalberton,
  },
  {
    path: "/transportation-company-aliwal-north",
    component: transportationcompanyaliwalnorth,
  },
  {
    path: "/transportation-company-beaufort-west",
    component: transportationcompanybeaufortwest,
  },
  {
    path: "/transportation-company-benoni",
    component: transportationcompanybenoni,
  },
  {
    path: "/transportation-company-bethal",
    component: transportationcompanybethal,
  },
  {
    path: "/transportation-company-bhisho",
    component: transportationcompanybhisho,
  },
  {
    path: "/transportation-company-bloemfontein",
    component: transportationcompanybloemfontein,
  },
  {
    path: "/transportation-company-brakpan",
    component: transportationcompanybrakpan,
  },
  {
    path: "/transportation-company-brits",
    component: transportationcompanybrits,
  },
  {
    path: "/transportation-company-cape-town",
    component: transportationcompanycapetown,
  },
  {
    path: "/transportation-company-carletonville",
    component: transportationcompanycarletonville,
  },
  {
    path: "/transportation-company-centurion",
    component: transportationcompanycenturion,
  },
  {
    path: "/transportation-company-cradock",
    component: transportationcompanycradock,
  },
  {
    path: "/transportation-company-de-aar",
    component: transportationcompanydeaar,
  },
  {
    path: "/transportation-company-durban",
    component: transportationcompanydurban,
  },
  {
    path: "/transportation-company-east-london",
    component: transportationcompanyeastlondon,
  },
  {
    path: "/transportation-company-ferndale",
    component: transportationcompanyferndale,
  },
  {
    path: "/transportation-company-george",
    component: transportationcompanygeorge,
  },
  {
    path: "/transportation-company-graaff-reinet",
    component: transportationcompanygraaffreinet,
  },
  {
    path: "/transportation-company-grahamstown",
    component: transportationcompanygrahamstown,
  },
  {
    path: "/transportation-company-hermanus",
    component: transportationcompanyhermanus,
  },
  {
    path: "/transportation-company-jeffreys-bay",
    component: transportationcompanyjeffreysbay,
  },
  {
    path: "/transportation-company-johannesburg",
    component: transportationcompanyjohannesburg,
  },
  {
    path: "/transportation-company-kimberley",
    component: transportationcompanykimberley,
  },
  {
    path: "/transportation-company-klerksdorp",
    component: transportationcompanyklerksdorp,
  },
  {
    path: "/transportation-company-knysna",
    component: transportationcompanyknysna,
  },
  {
    path: "/transportation-company-kroonstad",
    component: transportationcompanykroonstad,
  },
  {
    path: "/transportation-company-kuilsrivier",
    component: transportationcompanykuilsrivier,
  },
  {
    path: "/transportation-company-ladysmith",
    component: transportationcompanyladysmith,
  },
  {
    path: "/transportation-company-lebowakgomo",
    component: transportationcompanylebowakgomo,
  },
  {
    path: "/transportation-company-lichtenburg",
    component: transportationcompanylichtenburg,
  },
  {
    path: "/transportation-company-mahikeng",
    component: transportationcompanymahikeng,
  },
  {
    path: "/transportation-company-malmesbury",
    component: transportationcompanymalmesbury,
  },
  {
    path: "/transportation-company-middelburg",
    component: transportationcompanymiddelburg,
  },
  {
    path: "/transportation-company-midrand",
    component: transportationcompanymidrand,
  },
  {
    path: "/transportation-company-mmabatho",
    component: transportationcompanymmabatho,
  },
  {
    path: "/transportation-company-mossel-bay",
    component: transportationcompanymosselbay,
  },
  {
    path: "/transportation-company-musina",
    component: transportationcompanymusina,
  },
  {
    path: "/transportation-company-nelspruit",
    component: transportationcompanynelspruit,
  },
  {
    path: "/transportation-company-oudtshoorn",
    component: transportationcompanyoudtshoorn,
  },
  {
    path: "/transportation-company-paarl",
    component: transportationcompanypaarl,
  },
  {
    path: "/transportation-company-pietermaritzburg",
    component: transportationcompanypietermaritzburg,
  },
  {
    path: "/transportation-company-polokwane",
    component: transportationcompanypolokwane,
  },
  {
    path: "/transportation-company-port-elizabeth",
    component: transportationcompanyportelizabeth,
  },
  {
    path: "/transportation-company-port-shepstone",
    component: transportationcompanyportshepstone,
  },
  {
    path: "/transportation-company-potchefstroom",
    component: transportationcompanypotchefstroom,
  },
  {
    path: "/transportation-company-pretoria",
    component: transportationcompanypretoria,
  },
  {
    path: "/transportation-company-queenstown",
    component: transportationcompanyqueenstown,
  },
  {
    path: "/transportation-company-randburg",
    component: transportationcompanyrandburg,
  },
  {
    path: "/transportation-company-roodepoort",
    component: transportationcompanyroodepoort,
  },
  {
    path: "/transportation-company-rustenburg",
    component: transportationcompanyrustenburg,
  },
  {
    path: "/transportation-company-saldanha",
    component: transportationcompanysaldanha,
  },
  {
    path: "/transportation-company-sandton",
    component: transportationcompanysandton,
  },
  {
    path: "/transportation-company-soweto",
    component: transportationcompanysoweto,
  },
  {
    path: "/transportation-company-springs",
    component: transportationcompanysprings,
  },
  {
    path: "/transportation-company-standerton",
    component: transportationcompanystanderton,
  },
  {
    path: "/transportation-company-thohoyandou",
    component: transportationcompanythohoyandou,
  },
  {
    path: "/transportation-company-tzaneen",
    component: transportationcompanytzaneen,
  },
  {
    path: "/transportation-company-uitenhage",
    component: transportationcompanyuitenhage,
  },
  {
    path: "/transportation-company-ulundi",
    component: transportationcompanyulundi,
  },
  {
    path: "/transportation-company-umtata",
    component: transportationcompanyumtata,
  },
  {
    path: "/transportation-company-upington",
    component: transportationcompanyupington,
  },
  {
    path: "/transportation-company-vanderbijlpark",
    component: transportationcompanyvanderbijlpark,
  },
  {
    path: "/transportation-company-vereeniging",
    component: transportationcompanyvereeniging,
  },
  {
    path: "/transportation-company-volksrust",
    component: transportationcompanyvolksrust,
  },
  {
    path: "/transportation-company-vredenburg",
    component: transportationcompanyvredenburg,
  },
  {
    path: "/transportation-company-vryburg",
    component: transportationcompanyvryburg,
  },
  {
    path: "/transportation-company-vryheid",
    component: transportationcompanyvryheid,
  },
  {
    path: "/transportation-company-welkom",
    component: transportationcompanywelkom,
  },
  {
    path: "/transportation-company-worcester",
    component: transportationcompanyworcester,
  },
  { path: "/transportation-cradock", component: transportationcradock },
  { path: "/transportation-de-aar", component: transportationdeaar },
  { path: "/transportation-durban", component: transportationdurban },
  { path: "/transportation-east-london", component: transportationeastlondon },
  { path: "/transportation-ferndale", component: transportationferndale },
  { path: "/transportation-george", component: transportationgeorge },
  {
    path: "/transportation-graaff-reinet",
    component: transportationgraaffreinet,
  },
  { path: "/transportation-grahamstown", component: transportationgrahamstown },
  { path: "/transportation-hermanus", component: transportationhermanus },
  {
    path: "/transportation-jeffreys-bay",
    component: transportationjeffreysbay,
  },
  {
    path: "/transportation-johannesburg",
    component: transportationjohannesburg,
  },
  { path: "/transportation-kimberley", component: transportationkimberley },
  { path: "/transportation-klerksdorp", component: transportationklerksdorp },
  { path: "/transportation-knysna", component: transportationknysna },
  { path: "/transportation-kroonstad", component: transportationkroonstad },
  { path: "/transportation-kuilsrivier", component: transportationkuilsrivier },
  { path: "/transportation-ladysmith", component: transportationladysmith },
  { path: "/transportation-lebowakgomo", component: transportationlebowakgomo },
  { path: "/transportation-lichtenburg", component: transportationlichtenburg },
  { path: "/transportation-mahikeng", component: transportationmahikeng },
  { path: "/transportation-malmesbury", component: transportationmalmesbury },
  { path: "/transportation-middelburg", component: transportationmiddelburg },
  { path: "/transportation-midrand", component: transportationmidrand },
  { path: "/transportation-mmabatho", component: transportationmmabatho },
  { path: "/transportation-mossel-bay", component: transportationmosselbay },
  { path: "/transportation-musina", component: transportationmusina },
  { path: "/transportation-nelspruit", component: transportationnelspruit },
  { path: "/transportation-oudtshoorn", component: transportationoudtshoorn },
  { path: "/transportation-paarl", component: transportationpaarl },
  {
    path: "/transportation-pietermaritzburg",
    component: transportationpietermaritzburg,
  },
  { path: "/transportation-polokwane", component: transportationpolokwane },
  {
    path: "/transportation-port-elizabeth",
    component: transportationportelizabeth,
  },
  {
    path: "/transportation-port-shepstone",
    component: transportationportshepstone,
  },
  {
    path: "/transportation-potchefstroom",
    component: transportationpotchefstroom,
  },
  { path: "/transportation-pretoria", component: transportationpretoria },
  { path: "/transportation-queenstown", component: transportationqueenstown },
  { path: "/transportation-randburg", component: transportationrandburg },
  { path: "/transportation-roodepoort", component: transportationroodepoort },
  { path: "/transportation-rustenburg", component: transportationrustenburg },
  { path: "/transportation-saldanha", component: transportationsaldanha },
  { path: "/transportation-sandton", component: transportationsandton },
  { path: "/transportation-soweto", component: transportationsoweto },
  { path: "/transportation-springs", component: transportationsprings },
  { path: "/transportation-standerton", component: transportationstanderton },
  { path: "/transportation-thohoyandou", component: transportationthohoyandou },
  { path: "/transportation-tzaneen", component: transportationtzaneen },
  { path: "/transportation-uitenhage", component: transportationuitenhage },
  { path: "/transportation-ulundi", component: transportationulundi },
  { path: "/transportation-umtata", component: transportationumtata },
  { path: "/transportation-upington", component: transportationupington },
  {
    path: "/transportation-vanderbijlpark",
    component: transportationvanderbijlpark,
  },
  { path: "/transportation-vereeniging", component: transportationvereeniging },
  { path: "/transportation-volksrust", component: transportationvolksrust },
  { path: "/transportation-vredenburg", component: transportationvredenburg },
  { path: "/transportation-vryburg", component: transportationvryburg },
  { path: "/transportation-vryheid", component: transportationvryheid },
  { path: "/transportation-welkom", component: transportationwelkom },
  { path: "/transportation-worcester", component: transportationworcester },
  {
    path: "/truck-and-driver-hire-alberton",
    component: truckanddriverhirealberton,
  },
  {
    path: "/truck-and-driver-hire-aliwal-north",
    component: truckanddriverhirealiwalnorth,
  },
  {
    path: "/truck-and-driver-hire-beaufort-west",
    component: truckanddriverhirebeaufortwest,
  },
  {
    path: "/truck-and-driver-hire-benoni",
    component: truckanddriverhirebenoni,
  },
  {
    path: "/truck-and-driver-hire-bethal",
    component: truckanddriverhirebethal,
  },
  {
    path: "/truck-and-driver-hire-bhisho",
    component: truckanddriverhirebhisho,
  },
  {
    path: "/truck-and-driver-hire-bloemfontein",
    component: truckanddriverhirebloemfontein,
  },
  {
    path: "/truck-and-driver-hire-brakpan",
    component: truckanddriverhirebrakpan,
  },
  { path: "/truck-and-driver-hire-brits", component: truckanddriverhirebrits },
  {
    path: "/truck-and-driver-hire-cape-town",
    component: truckanddriverhirecapetown,
  },
  {
    path: "/truck-and-driver-hire-carletonville",
    component: truckanddriverhirecarletonville,
  },
  {
    path: "/truck-and-driver-hire-centurion",
    component: truckanddriverhirecenturion,
  },
  {
    path: "/truck-and-driver-hire-cradock",
    component: truckanddriverhirecradock,
  },
  { path: "/truck-and-driver-hire-de-aar", component: truckanddriverhiredeaar },
  {
    path: "/truck-and-driver-hire-durban",
    component: truckanddriverhiredurban,
  },
  {
    path: "/truck-and-driver-hire-east-london",
    component: truckanddriverhireeastlondon,
  },
  {
    path: "/truck-and-driver-hire-ferndale",
    component: truckanddriverhireferndale,
  },
  {
    path: "/truck-and-driver-hire-george",
    component: truckanddriverhiregeorge,
  },
  {
    path: "/truck-and-driver-hire-graaff-reinet",
    component: truckanddriverhiregraaffreinet,
  },
  {
    path: "/truck-and-driver-hire-grahamstown",
    component: truckanddriverhiregrahamstown,
  },
  {
    path: "/truck-and-driver-hire-hermanus",
    component: truckanddriverhirehermanus,
  },
  {
    path: "/truck-and-driver-hire-jeffreys-bay",
    component: truckanddriverhirejeffreysbay,
  },
  {
    path: "/truck-and-driver-hire-johannesburg",
    component: truckanddriverhirejohannesburg,
  },
  {
    path: "/truck-and-driver-hire-kimberley",
    component: truckanddriverhirekimberley,
  },
  {
    path: "/truck-and-driver-hire-klerksdorp",
    component: truckanddriverhireklerksdorp,
  },
  {
    path: "/truck-and-driver-hire-knysna",
    component: truckanddriverhireknysna,
  },
  {
    path: "/truck-and-driver-hire-kroonstad",
    component: truckanddriverhirekroonstad,
  },
  {
    path: "/truck-and-driver-hire-kuilsrivier",
    component: truckanddriverhirekuilsrivier,
  },
  {
    path: "/truck-and-driver-hire-ladysmith",
    component: truckanddriverhireladysmith,
  },
  {
    path: "/truck-and-driver-hire-lebowakgomo",
    component: truckanddriverhirelebowakgomo,
  },
  {
    path: "/truck-and-driver-hire-lichtenburg",
    component: truckanddriverhirelichtenburg,
  },
  {
    path: "/truck-and-driver-hire-mahikeng",
    component: truckanddriverhiremahikeng,
  },
  {
    path: "/truck-and-driver-hire-malmesbury",
    component: truckanddriverhiremalmesbury,
  },
  {
    path: "/truck-and-driver-hire-middelburg",
    component: truckanddriverhiremiddelburg,
  },
  {
    path: "/truck-and-driver-hire-midrand",
    component: truckanddriverhiremidrand,
  },
  {
    path: "/truck-and-driver-hire-mmabatho",
    component: truckanddriverhiremmabatho,
  },
  {
    path: "/truck-and-driver-hire-mossel-bay",
    component: truckanddriverhiremosselbay,
  },
  {
    path: "/truck-and-driver-hire-musina",
    component: truckanddriverhiremusina,
  },
  {
    path: "/truck-and-driver-hire-nelspruit",
    component: truckanddriverhirenelspruit,
  },
  {
    path: "/truck-and-driver-hire-oudtshoorn",
    component: truckanddriverhireoudtshoorn,
  },
  { path: "/truck-and-driver-hire-paarl", component: truckanddriverhirepaarl },
  {
    path: "/truck-and-driver-hire-pietermaritzburg",
    component: truckanddriverhirepietermaritzburg,
  },
  {
    path: "/truck-and-driver-hire-polokwane",
    component: truckanddriverhirepolokwane,
  },
  {
    path: "/truck-and-driver-hire-port-elizabeth",
    component: truckanddriverhireportelizabeth,
  },
  {
    path: "/truck-and-driver-hire-port-shepstone",
    component: truckanddriverhireportshepstone,
  },
  {
    path: "/truck-and-driver-hire-potchefstroom",
    component: truckanddriverhirepotchefstroom,
  },
  {
    path: "/truck-and-driver-hire-pretoria",
    component: truckanddriverhirepretoria,
  },
  {
    path: "/truck-and-driver-hire-queenstown",
    component: truckanddriverhirequeenstown,
  },
  {
    path: "/truck-and-driver-hire-randburg",
    component: truckanddriverhirerandburg,
  },
  {
    path: "/truck-and-driver-hire-roodepoort",
    component: truckanddriverhireroodepoort,
  },
  {
    path: "/truck-and-driver-hire-rustenburg",
    component: truckanddriverhirerustenburg,
  },
  {
    path: "/truck-and-driver-hire-saldanha",
    component: truckanddriverhiresaldanha,
  },
  {
    path: "/truck-and-driver-hire-sandton",
    component: truckanddriverhiresandton,
  },
  {
    path: "/truck-and-driver-hire-soweto",
    component: truckanddriverhiresoweto,
  },
  {
    path: "/truck-and-driver-hire-springs",
    component: truckanddriverhiresprings,
  },
  {
    path: "/truck-and-driver-hire-standerton",
    component: truckanddriverhirestanderton,
  },
  {
    path: "/truck-and-driver-hire-thohoyandou",
    component: truckanddriverhirethohoyandou,
  },
  {
    path: "/truck-and-driver-hire-tzaneen",
    component: truckanddriverhiretzaneen,
  },
  {
    path: "/truck-and-driver-hire-uitenhage",
    component: truckanddriverhireuitenhage,
  },
  {
    path: "/truck-and-driver-hire-ulundi",
    component: truckanddriverhireulundi,
  },
  {
    path: "/truck-and-driver-hire-umtata",
    component: truckanddriverhireumtata,
  },
  {
    path: "/truck-and-driver-hire-upington",
    component: truckanddriverhireupington,
  },
  {
    path: "/truck-and-driver-hire-vanderbijlpark",
    component: truckanddriverhirevanderbijlpark,
  },
  {
    path: "/truck-and-driver-hire-vereeniging",
    component: truckanddriverhirevereeniging,
  },
  {
    path: "/truck-and-driver-hire-volksrust",
    component: truckanddriverhirevolksrust,
  },
  {
    path: "/truck-and-driver-hire-vredenburg",
    component: truckanddriverhirevredenburg,
  },
  {
    path: "/truck-and-driver-hire-vryburg",
    component: truckanddriverhirevryburg,
  },
  {
    path: "/truck-and-driver-hire-vryheid",
    component: truckanddriverhirevryheid,
  },
  {
    path: "/truck-and-driver-hire-welkom",
    component: truckanddriverhirewelkom,
  },
  {
    path: "/truck-and-driver-hire-worcester",
    component: truckanddriverhireworcester,
  },
  { path: "/uber-alberton", component: uberalberton },
  { path: "/uber-aliwal-north", component: uberaliwalnorth },
  { path: "/uber-beaufort-west", component: uberbeaufortwest },
  { path: "/uber-benoni", component: uberbenoni },
  { path: "/uber-bethal", component: uberbethal },
  { path: "/uber-bhisho", component: uberbhisho },
  { path: "/uber-bloemfontein", component: uberbloemfontein },
  { path: "/uber-brakpan", component: uberbrakpan },
  { path: "/uber-brits", component: uberbrits },
  { path: "/uber-cape-town", component: ubercapetown },
  { path: "/uber-carletonville", component: ubercarletonville },
  { path: "/uber-centurion", component: ubercenturion },
  { path: "/uber-cradock", component: ubercradock },
  { path: "/uber-de-aar", component: uberdeaar },
  { path: "/uber-durban", component: uberdurban },
  { path: "/uber-east-london", component: ubereastlondon },
  { path: "/uber-ferndale", component: uberferndale },
  { path: "/uber-george", component: ubergeorge },
  { path: "/uber-graaff-reinet", component: ubergraaffreinet },
  { path: "/uber-grahamstown", component: ubergrahamstown },
  { path: "/uber-hermanus", component: uberhermanus },
  { path: "/uber-jeffreys-bay", component: uberjeffreysbay },
  { path: "/uber-johannesburg", component: uberjohannesburg },
  { path: "/uber-kimberley", component: uberkimberley },
  { path: "/uber-klerksdorp", component: uberklerksdorp },
  { path: "/uber-knysna", component: uberknysna },
  { path: "/uber-kroonstad", component: uberkroonstad },
  { path: "/uber-kuilsrivier", component: uberkuilsrivier },
  { path: "/uber-ladysmith", component: uberladysmith },
  { path: "/uber-lebowakgomo", component: uberlebowakgomo },
  { path: "/uber-lichtenburg", component: uberlichtenburg },
  { path: "/uber-mahikeng", component: ubermahikeng },
  { path: "/uber-malmesbury", component: ubermalmesbury },
  { path: "/uber-middelburg", component: ubermiddelburg },
  { path: "/uber-midrand", component: ubermidrand },
  { path: "/uber-mmabatho", component: ubermmabatho },
  { path: "/uber-mossel-bay", component: ubermosselbay },
  { path: "/uber-musina", component: ubermusina },
  { path: "/uber-nelspruit", component: ubernelspruit },
  { path: "/uber-oudtshoorn", component: uberoudtshoorn },
  { path: "/uber-paarl", component: uberpaarl },
  { path: "/uber-pietermaritzburg", component: uberpietermaritzburg },
  { path: "/uber-polokwane", component: uberpolokwane },
  { path: "/uber-port-elizabeth", component: uberportelizabeth },
  { path: "/uber-port-shepstone", component: uberportshepstone },
  { path: "/uber-potchefstroom", component: uberpotchefstroom },
  { path: "/uber-pretoria", component: uberpretoria },
  { path: "/uber-queenstown", component: uberqueenstown },
  { path: "/uber-randburg", component: uberrandburg },
  { path: "/uber-roodepoort", component: uberroodepoort },
  { path: "/uber-rustenburg", component: uberrustenburg },
  { path: "/uber-saldanha", component: ubersaldanha },
  { path: "/uber-sandton", component: ubersandton },
  { path: "/uber-soweto", component: ubersoweto },
  { path: "/uber-springs", component: ubersprings },
  { path: "/uber-standerton", component: uberstanderton },
  { path: "/uber-thohoyandou", component: uberthohoyandou },
  { path: "/uber-truck-alberton", component: ubertruckalberton },
  { path: "/uber-truck-aliwal-north", component: ubertruckaliwalnorth },
  { path: "/uber-truck-beaufort-west", component: ubertruckbeaufortwest },
  { path: "/uber-truck-benoni", component: ubertruckbenoni },
  { path: "/uber-truck-bethal", component: ubertruckbethal },
  { path: "/uber-truck-bhisho", component: ubertruckbhisho },
  { path: "/uber-truck-bloemfontein", component: ubertruckbloemfontein },
  { path: "/uber-truck-brakpan", component: ubertruckbrakpan },
  { path: "/uber-truck-brits", component: ubertruckbrits },
  { path: "/uber-truck-cape-town", component: ubertruckcapetown },
  { path: "/uber-truck-carletonville", component: ubertruckcarletonville },
  { path: "/uber-truck-centurion", component: ubertruckcenturion },
  { path: "/uber-truck-cradock", component: ubertruckcradock },
  { path: "/uber-truck-de-aar", component: ubertruckdeaar },
  { path: "/uber-truck-durban", component: ubertruckdurban },
  { path: "/uber-truck-east-london", component: ubertruckeastlondon },
  { path: "/uber-truck-ferndale", component: ubertruckferndale },
  { path: "/uber-truck-george", component: ubertruckgeorge },
  { path: "/uber-truck-graaff-reinet", component: ubertruckgraaffreinet },
  { path: "/uber-truck-grahamstown", component: ubertruckgrahamstown },
  { path: "/uber-truck-hermanus", component: ubertruckhermanus },
  { path: "/uber-truck-jeffreys-bay", component: ubertruckjeffreysbay },
  { path: "/uber-truck-johannesburg", component: ubertruckjohannesburg },
  { path: "/uber-truck-kimberley", component: ubertruckkimberley },
  { path: "/uber-truck-klerksdorp", component: ubertruckklerksdorp },
  { path: "/uber-truck-knysna", component: ubertruckknysna },
  { path: "/uber-truck-kroonstad", component: ubertruckkroonstad },
  { path: "/uber-truck-kuilsrivier", component: ubertruckkuilsrivier },
  { path: "/uber-truck-ladysmith", component: ubertruckladysmith },
  { path: "/uber-truck-lebowakgomo", component: ubertrucklebowakgomo },
  { path: "/uber-truck-lichtenburg", component: ubertrucklichtenburg },
  { path: "/uber-truck-mahikeng", component: ubertruckmahikeng },
  { path: "/uber-truck-malmesbury", component: ubertruckmalmesbury },
  { path: "/uber-truck-middelburg", component: ubertruckmiddelburg },
  { path: "/uber-truck-midrand", component: ubertruckmidrand },
  { path: "/uber-truck-mmabatho", component: ubertruckmmabatho },
  { path: "/uber-truck-mossel-bay", component: ubertruckmosselbay },
  { path: "/uber-truck-musina", component: ubertruckmusina },
  { path: "/uber-truck-nelspruit", component: ubertrucknelspruit },
  { path: "/uber-truck-oudtshoorn", component: ubertruckoudtshoorn },
  { path: "/uber-truck-paarl", component: ubertruckpaarl },
  {
    path: "/uber-truck-pietermaritzburg",
    component: ubertruckpietermaritzburg,
  },
  { path: "/uber-truck-polokwane", component: ubertruckpolokwane },
  { path: "/uber-truck-port-elizabeth", component: ubertruckportelizabeth },
  { path: "/uber-truck-port-shepstone", component: ubertruckportshepstone },
  { path: "/uber-truck-potchefstroom", component: ubertruckpotchefstroom },
  { path: "/uber-truck-pretoria", component: ubertruckpretoria },
  { path: "/uber-truck-queenstown", component: ubertruckqueenstown },
  { path: "/uber-truck-randburg", component: ubertruckrandburg },
  { path: "/uber-truck-roodepoort", component: ubertruckroodepoort },
  { path: "/uber-truck-rustenburg", component: ubertruckrustenburg },
  { path: "/uber-truck-saldanha", component: ubertrucksaldanha },
  { path: "/uber-truck-sandton", component: ubertrucksandton },
  { path: "/uber-truck-soweto", component: ubertrucksoweto },
  { path: "/uber-truck-springs", component: ubertrucksprings },
  { path: "/uber-truck-standerton", component: ubertruckstanderton },
  { path: "/uber-truck-thohoyandou", component: ubertruckthohoyandou },
  { path: "/uber-truck-tzaneen", component: ubertrucktzaneen },
  { path: "/uber-truck-uitenhage", component: ubertruckuitenhage },
  { path: "/uber-truck-ulundi", component: ubertruckulundi },
  { path: "/uber-truck-umtata", component: ubertruckumtata },
  { path: "/uber-truck-upington", component: ubertruckupington },
  { path: "/uber-truck-vanderbijlpark", component: ubertruckvanderbijlpark },
  { path: "/uber-truck-vereeniging", component: ubertruckvereeniging },
  { path: "/uber-truck-volksrust", component: ubertruckvolksrust },
  { path: "/uber-truck-vredenburg", component: ubertruckvredenburg },
  { path: "/uber-truck-vryburg", component: ubertruckvryburg },
  { path: "/uber-truck-vryheid", component: ubertruckvryheid },
  { path: "/uber-truck-welkom", component: ubertruckwelkom },
  { path: "/uber-truck-worcester", component: ubertruckworcester },
  { path: "/uber-tzaneen", component: ubertzaneen },
  { path: "/uber-uitenhage", component: uberuitenhage },
  { path: "/uber-ulundi", component: uberulundi },
  { path: "/uber-umtata", component: uberumtata },
  { path: "/uber-upington", component: uberupington },
  { path: "/uber-vanderbijlpark", component: ubervanderbijlpark },
  { path: "/uber-vereeniging", component: ubervereeniging },
  { path: "/uber-volksrust", component: ubervolksrust },
  { path: "/uber-vredenburg", component: ubervredenburg },
  { path: "/uber-vryburg", component: ubervryburg },
  { path: "/uber-vryheid", component: ubervryheid },
  { path: "/uber-welkom", component: uberwelkom },
  { path: "/uber-worcester", component: uberworcester },
  { path: "/van-hire--alberton", component: vanhirealberton },
  { path: "/van-hire--aliwal-north", component: vanhirealiwalnorth },
  { path: "/van-hire--beaufort-west", component: vanhirebeaufortwest },
  { path: "/van-hire--benoni", component: vanhirebenoni },
  { path: "/van-hire--bethal", component: vanhirebethal },
  { path: "/van-hire--bhisho", component: vanhirebhisho },
  { path: "/van-hire--bloemfontein", component: vanhirebloemfontein },
  { path: "/van-hire--brakpan", component: vanhirebrakpan },
  { path: "/van-hire--brits", component: vanhirebrits },
  { path: "/van-hire--cape-town", component: vanhirecapetown },
  { path: "/van-hire--carletonville", component: vanhirecarletonville },
  { path: "/van-hire--centurion", component: vanhirecenturion },
  { path: "/van-hire--cradock", component: vanhirecradock },
  { path: "/van-hire--de-aar", component: vanhiredeaar },
  { path: "/van-hire--durban", component: vanhiredurban },
  { path: "/van-hire--east-london", component: vanhireeastlondon },
  { path: "/van-hire--ferndale", component: vanhireferndale },
  { path: "/van-hire--george", component: vanhiregeorge },
  { path: "/van-hire--graaff-reinet", component: vanhiregraaffreinet },
  { path: "/van-hire--grahamstown", component: vanhiregrahamstown },
  { path: "/van-hire--hermanus", component: vanhirehermanus },
  { path: "/van-hire--jeffreys-bay", component: vanhirejeffreysbay },
  { path: "/van-hire--johannesburg", component: vanhirejohannesburg },
  { path: "/van-hire--kimberley", component: vanhirekimberley },
  { path: "/van-hire--klerksdorp", component: vanhireklerksdorp },
  { path: "/van-hire--knysna", component: vanhireknysna },
  { path: "/van-hire--kroonstad", component: vanhirekroonstad },
  { path: "/van-hire--kuilsrivier", component: vanhirekuilsrivier },
  { path: "/van-hire--ladysmith", component: vanhireladysmith },
  { path: "/van-hire--lebowakgomo", component: vanhirelebowakgomo },
  { path: "/van-hire--lichtenburg", component: vanhirelichtenburg },
  { path: "/van-hire--mahikeng", component: vanhiremahikeng },
  { path: "/van-hire--malmesbury", component: vanhiremalmesbury },
  { path: "/van-hire--middelburg", component: vanhiremiddelburg },
  { path: "/van-hire--midrand", component: vanhiremidrand },
  { path: "/van-hire--mmabatho", component: vanhiremmabatho },
  { path: "/van-hire--mossel-bay", component: vanhiremosselbay },
  { path: "/van-hire--musina", component: vanhiremusina },
  { path: "/van-hire--nelspruit", component: vanhirenelspruit },
  { path: "/van-hire--oudtshoorn", component: vanhireoudtshoorn },
  { path: "/van-hire--paarl", component: vanhirepaarl },
  { path: "/van-hire--pietermaritzburg", component: vanhirepietermaritzburg },
  { path: "/van-hire--polokwane", component: vanhirepolokwane },
  { path: "/van-hire--port-elizabeth", component: vanhireportelizabeth },
  { path: "/van-hire--port-shepstone", component: vanhireportshepstone },
  { path: "/van-hire--potchefstroom", component: vanhirepotchefstroom },
  { path: "/van-hire--pretoria", component: vanhirepretoria },
  { path: "/van-hire--queenstown", component: vanhirequeenstown },
  { path: "/van-hire--randburg", component: vanhirerandburg },
  { path: "/van-hire--roodepoort", component: vanhireroodepoort },
  { path: "/van-hire--rustenburg", component: vanhirerustenburg },
  { path: "/van-hire--saldanha", component: vanhiresaldanha },
  { path: "/van-hire--sandton", component: vanhiresandton },
  { path: "/van-hire--soweto", component: vanhiresoweto },
  { path: "/van-hire--springs", component: vanhiresprings },
  { path: "/van-hire--standerton", component: vanhirestanderton },
  { path: "/van-hire--thohoyandou", component: vanhirethohoyandou },
  { path: "/van-hire--tzaneen", component: vanhiretzaneen },
  { path: "/van-hire--uitenhage", component: vanhireuitenhage },
  { path: "/van-hire--ulundi", component: vanhireulundi },
  { path: "/van-hire--umtata", component: vanhireumtata },
  { path: "/van-hire--upington", component: vanhireupington },
  { path: "/van-hire--vanderbijlpark", component: vanhirevanderbijlpark },
  { path: "/van-hire--vereeniging", component: vanhirevereeniging },
  { path: "/van-hire--volksrust", component: vanhirevolksrust },
  { path: "/van-hire--vredenburg", component: vanhirevredenburg },
  { path: "/van-hire--vryburg", component: vanhirevryburg },
  { path: "/van-hire--vryheid", component: vanhirevryheid },
  { path: "/van-hire--welkom", component: vanhirewelkom },
  { path: "/van-hire--worcester", component: vanhireworcester },
  { path: "/vehicle-rental-alberton", component: vehiclerentalalberton },
  { path: "/vehicle-rental-aliwal-north", component: vehiclerentalaliwalnorth },
  {
    path: "/vehicle-rental-beaufort-west",
    component: vehiclerentalbeaufortwest,
  },
  { path: "/vehicle-rental-benoni", component: vehiclerentalbenoni },
  { path: "/vehicle-rental-bethal", component: vehiclerentalbethal },
  { path: "/vehicle-rental-bhisho", component: vehiclerentalbhisho },
  {
    path: "/vehicle-rental-bloemfontein",
    component: vehiclerentalbloemfontein,
  },
  { path: "/vehicle-rental-brakpan", component: vehiclerentalbrakpan },
  { path: "/vehicle-rental-brits", component: vehiclerentalbrits },
  { path: "/vehicle-rental-cape-town", component: vehiclerentalcapetown },
  {
    path: "/vehicle-rental-carletonville",
    component: vehiclerentalcarletonville,
  },
  { path: "/vehicle-rental-centurion", component: vehiclerentalcenturion },
  { path: "/vehicle-rental-cradock", component: vehiclerentalcradock },
  { path: "/vehicle-rental-de-aar", component: vehiclerentaldeaar },
  { path: "/vehicle-rental-durban", component: vehiclerentaldurban },
  { path: "/vehicle-rental-east-london", component: vehiclerentaleastlondon },
  { path: "/vehicle-rental-ferndale", component: vehiclerentalferndale },
  { path: "/vehicle-rental-george", component: vehiclerentalgeorge },
  {
    path: "/vehicle-rental-graaff-reinet",
    component: vehiclerentalgraaffreinet,
  },
  { path: "/vehicle-rental-grahamstown", component: vehiclerentalgrahamstown },
  { path: "/vehicle-rental-hermanus", component: vehiclerentalhermanus },
  { path: "/vehicle-rental-jeffreys-bay", component: vehiclerentaljeffreysbay },
  {
    path: "/vehicle-rental-johannesburg",
    component: vehiclerentaljohannesburg,
  },
  { path: "/vehicle-rental-kimberley", component: vehiclerentalkimberley },
  { path: "/vehicle-rental-klerksdorp", component: vehiclerentalklerksdorp },
  { path: "/vehicle-rental-knysna", component: vehiclerentalknysna },
  { path: "/vehicle-rental-kroonstad", component: vehiclerentalkroonstad },
  { path: "/vehicle-rental-kuilsrivier", component: vehiclerentalkuilsrivier },
  { path: "/vehicle-rental-ladysmith", component: vehiclerentalladysmith },
  { path: "/vehicle-rental-lebowakgomo", component: vehiclerentallebowakgomo },
  { path: "/vehicle-rental-lichtenburg", component: vehiclerentallichtenburg },
  { path: "/vehicle-rental-mahikeng", component: vehiclerentalmahikeng },
  { path: "/vehicle-rental-malmesbury", component: vehiclerentalmalmesbury },
  { path: "/vehicle-rental-middelburg", component: vehiclerentalmiddelburg },
  { path: "/vehicle-rental-midrand", component: vehiclerentalmidrand },
  { path: "/vehicle-rental-mmabatho", component: vehiclerentalmmabatho },
  { path: "/vehicle-rental-mossel-bay", component: vehiclerentalmosselbay },
  { path: "/vehicle-rental-musina", component: vehiclerentalmusina },
  { path: "/vehicle-rental-nelspruit", component: vehiclerentalnelspruit },
  { path: "/vehicle-rental-oudtshoorn", component: vehiclerentaloudtshoorn },
  { path: "/vehicle-rental-paarl", component: vehiclerentalpaarl },
  {
    path: "/vehicle-rental-pietermaritzburg",
    component: vehiclerentalpietermaritzburg,
  },
  { path: "/vehicle-rental-polokwane", component: vehiclerentalpolokwane },
  {
    path: "/vehicle-rental-port-elizabeth",
    component: vehiclerentalportelizabeth,
  },
  {
    path: "/vehicle-rental-port-shepstone",
    component: vehiclerentalportshepstone,
  },
  {
    path: "/vehicle-rental-potchefstroom",
    component: vehiclerentalpotchefstroom,
  },
  { path: "/vehicle-rental-pretoria", component: vehiclerentalpretoria },
  { path: "/vehicle-rental-queenstown", component: vehiclerentalqueenstown },
  { path: "/vehicle-rental-randburg", component: vehiclerentalrandburg },
  { path: "/vehicle-rental-roodepoort", component: vehiclerentalroodepoort },
  { path: "/vehicle-rental-rustenburg", component: vehiclerentalrustenburg },
  { path: "/vehicle-rental-saldanha", component: vehiclerentalsaldanha },
  { path: "/vehicle-rental-sandton", component: vehiclerentalsandton },
  { path: "/vehicle-rental-soweto", component: vehiclerentalsoweto },
  { path: "/vehicle-rental-springs", component: vehiclerentalsprings },
  { path: "/vehicle-rental-standerton", component: vehiclerentalstanderton },
  { path: "/vehicle-rental-thohoyandou", component: vehiclerentalthohoyandou },
  { path: "/vehicle-rental-tzaneen", component: vehiclerentaltzaneen },
  { path: "/vehicle-rental-uitenhage", component: vehiclerentaluitenhage },
  { path: "/vehicle-rental-ulundi", component: vehiclerentalulundi },
  { path: "/vehicle-rental-umtata", component: vehiclerentalumtata },
  { path: "/vehicle-rental-upington", component: vehiclerentalupington },
  {
    path: "/vehicle-rental-vanderbijlpark",
    component: vehiclerentalvanderbijlpark,
  },
  { path: "/vehicle-rental-vereeniging", component: vehiclerentalvereeniging },
  { path: "/vehicle-rental-volksrust", component: vehiclerentalvolksrust },
  { path: "/vehicle-rental-vredenburg", component: vehiclerentalvredenburg },
  { path: "/vehicle-rental-vryburg", component: vehiclerentalvryburg },
  { path: "/vehicle-rental-vryheid", component: vehiclerentalvryheid },
  { path: "/vehicle-rental-welkom", component: vehiclerentalwelkom },
  { path: "/vehicle-rental-worcester", component: vehiclerentalworcester },

  { path: "/furniture-delivery-services", component: deliveriesForYourHome },
  { path: "/bakkie-hire", component: bakkieHire },
  { path: "/bakkie-hire-johannesburg", component: bakkieHire },
  { path: "/bakkie-hire-randburg", component: bakkieHire },
  { path: "/randburg-furniture-transport", component: bakkieHire },
  { path: "/sandton-furniture-transport", component: bakkieHire },
  { path: "/midrand-furniture-transport", component: bakkieHire },
  { path: "/centurion-furniture-transport", component: bakkieHire },
  { path: "/pretoria-furniture-transport", component: bakkieHire },
  { path: "/johannesburg-furniture-transport", component: bakkieHire },
  { path: "/bakkie-hire-pretoria", component: bakkieHire },
  { path: "/bakkie-hire-sandton", component: bakkieHire },
  { path: "/bakkie-hire-roodeport", component: bakkieHire },
  { path: "/bakkie-hire-soweto", component: bakkieHire },
  { path: "/bakkie-hire-gauteng", component: bakkieHire },
  { path: "/bakkie-hire-midrand", component: bakkieHire },
  { path: "/bakkie-hire-centurion", component: bakkieHire },
  { path: "/vehicle-hire-price-survey", component: vehicleHirePriceSurvey },
  { path: "/truck-hire", component: truckHire },
  { path: "/regular-deliveries", component: regularDeliveries },
  { path: "/truck-hire-thanks", component: truckHireThanks },
  { path: "/customer-truck-hire", component: landing },
  { path: "/marketplace", component: marketplace },
  { path: "/welo", component: welo },
  { path: "/terms", component: terms },
  { path: "/driver-training", component: drivertraining },
  { path: "/contract-trip-edit", component: contracttripedit },
  { path: "/privacy", component: privacy },
  { path: "/checkout-load-capacity", component: checkoutloadcapacity },
  { path: "/about", component: about },
  { path: "/what-is-qiktruck", component: investors },
  { path: "/remote-work-at-qiktruck", component: remoteworkatqiktruck },
  { path: "/faqs", component: faqs },
  { path: "/vacancies", component: vacancies },
  { path: "/customer-truck-hire-contracts", component: landing },
  { path: "/investors", component: investors },
  { path: "/deliver-with-price", component: deliverwithprice },
  { path: "/promo", component: promo },
  { path: "/contract-trip", component: contracttrip },
  { path: "/contract-trips", component: contracttrips },
  { path: "/insurance", component: insurance },
  { path: "/developers", component: developers },
  { path: "/transportation-network", component: transportationnetwork },
  { path: "/unsubscribe", component: unsubscribe },
  { path: "/ads", component: ads },
  { path: "/driver-packages", component: driverpackages },
  { path: "/boost", component: boost },
  { path: "/articles/:id?", component: articles },
  { path: "/howitworks", component: about },
  { path: "/social", component: social },
  { path: "/contact", component: about },
  { path: "/pricing", component: pricing },
  { path: "/footer", component: footer },
  { path: "/driver-guide", component: driverguide },
  { path: "/track-order", component: trackorder },
  { path: "/orders", component: orders },
  { path: "/customer-profile", component: landing },
  { path: "/truck-hire", component: truckHire },
  { path: "/partner-drivers", component: vehicleRegistration },
  { path: "/goods-list", component: goodslist },
  { path: "/supplier-registration", component: supplierregistration },
  { path: "/", component: landing },
  { path: "/quote", component: landing },
  {
    path: "/customer-regular-trip-driver",
    component: landing,
  },
  { path: "/realestate-partnerships", component: realestatepartnerships },
  { path: "*", component: f404 },
];

export default routes;
