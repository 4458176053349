import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Reliable Long Distance Services in Vryheid"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/long-distance-vryheid"}
          pageHeading={"Reliable Long Distance Services in Vryheid"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get reliable Long Distance services in Vryheid with our team of dedicated drivers and top-quality vehicles at QikTruck. We understand the importance of punctuality and accuracy in delivery, and strive to consistently provide superior service. Sign up now and start experiencing the peace of mind that comes with our services."}
          pageDescription2={"Need reliable Long Distance services in Vryheid? QikTruck is here to help! Our team of dedicated drivers and top-quality vehicles ensure that your deliveries are made on time and with the utmost accuracy. From package delivery to furniture transportation, we have the solutions you need. Sign up now and start experiencing the peace of mind that comes with our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
