import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={"Adverts on our delivery vehicles."}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Boost your sales when you advertise on our delivery vehicles."
          }
          image={"https://media.qiktruck.co.za/qiktruck-ads-header.png"}
          pathname={"https://qiktruck.co.za/ads"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h3>Advertise on our Vehicles at affordable prices</h3>
                <br />
                <p>
                  Please contact: <b> support@qiktruck.co.za </b>for more
                  information.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-header.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-header-2.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-header-3.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-vehicle.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-vehicle-2.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-vehicle-3.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-header-4.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-header-5.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <img
                  src="https://media.qiktruck.co.za/qiktruck-ads-header-6.png"
                  width={"100%"}
                  alt={"src-im"}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col>
                <a href="/vehicle-frame-advertising.html">READ more here</a>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
