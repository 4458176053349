import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import CurrencyFormat from "react-currency-format";
import SEO from "react-seo-component";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import constants from "../../Constants";
import util from "../../util";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();
    let vehicleid = "";
    let trucktype = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      vehicleid = queryParams.get("v") || "";
      trucktype = queryParams.get("t") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }
    this.state = {
      loading: false,
      openSuccessModal: false,
      apierror: "",
      location: {
        lat: 0,
        lng: 0,
      },
      fullname: "",
      email: "",
      mobilenumber: "",
      daily: "",
      weekly: "",
      monthly: "",
      insurer: "",
      insurance: "",
      tracker: "",
      tracking: "",
      vehicles: 0,
      vehicletypes: [],
      vehicleid,
      gitter: "",
      gitcost: "",
      gitcover: "",
      trucktype,
      tips: {
        daily: 0,
        weekly: 0,
        monthly: 0,
        insurance: 0,
        tracking: 0,
        gitcost: 0,
        gitcover: 0,
      },
    };
  }
  getPrices() {
    axios
      .get(baseURL + "/vehiclehirestats?trucktype=" + this.state.trucktype)
      .then((repos) => {
        const tips = repos.data;
        this.setState({ tips });
      });
  }
  showTip(tip) {
    return (
      <span className="price-survey-tip">
        <CurrencyFormat
          value={Math.ceil(tip) || 0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          decimalScale={2}
        />
      </span>
    );
  }
  showTipHint(tip) {
    return (
      <span className="price-survey-hint">
        Industry average:{" "}
        <CurrencyFormat
          value={Math.ceil(tip) || 0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={" "}
          decimalScale={2}
        />
      </span>
    );
  }
  componentDidMount() {
    this.getPrices();
  }
  validStepOne() {
    const {
      trucktype,
      fullname,
      email,
      mobilenumber,
      daily,
      weekly,
      monthly,
      vehicleid,
    } = this.state;
    if (
      !vehicleid &&
      fullname.length < 2 &&
      email.length < 2 &&
      mobilenumber.length < 5
    )
      return false;

    return (
      daily.length > 1 &&
      weekly.length > 1 &&
      monthly.length > 1 &&
      trucktype.length > 1
    );
  }

  registerVehicleHirePrice() {
    const {
      trucktype,
      location,
      fullname,
      email,
      mobilenumber,
      daily,
      weekly,
      monthly,
      insurer,
      insurance,
      tracker,
      tracking,
      vehicleid,
      gitter,
      gitcost,
      gitcover,
    } = this.state;
    this.setState({ loading: true, apierror: "" });
    axios
      .post(baseURL + "/register-vehicle-hire-price", {
        trucktype,
        location,
        fullname,
        email,
        mobilenumber,
        daily,
        weekly,
        monthly,
        insurer,
        insurance,
        tracker,
        tracking,
        vehicleid,
        gitter,
        gitcost,
        gitcover,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        this.setState({
          loading: false,
          openSuccessModal: true,
        });
      })
      .catch((e) => {
        this.setState({ loading: false, apierror: e.message });
        Bugsnag.notify(e);
      });
  }

  render() {
    const { vehicletypes, vehicleid, trucktype, tips } = this.state;
    if (vehicletypes.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
        });
      });
    return (
      <React.Fragment>
        <SEO
          title={"Vehicle Hire Prices by Drivers"}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Annual Driver vehicle hire survey. Boost quotation success."
          }
          image={"https://media.qiktruck.co.za/partner-driver.jpeg"}
          pathname={"https://qiktruck.co.za/vehicle-hire-price-survey"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section" id="location">
          <Modal isOpen={this.state.openSuccessModal}>
            <ModalHeader>
              <i className={"mdi mdi-check-circle order-received"}></i>
              Details received
            </ModalHeader>

            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.setState(
                    {
                      openSuccessModal: false,
                      trackYourOrder: true,
                    },
                    () => {
                      window.location.href = "/";
                    }
                  )
                }
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="hero-wrapper mb-4">
                  <h4 id={"vehiclepicker"}>For Vehicle Owners</h4>
                  <p className="text-uppercase">2022 Vehicle Hire Prices</p>
                  {(!vehicleid || vehicleid === "") && (
                    <Row className="mb-5 landing-menu">
                      <h3 className="font-15">
                        Select your Vehicle Size{" "}
                        <span style={{ color: "#e03c31" }}>*</span>
                      </h3>
                      <p></p>
                      {vehicletypes
                        .filter((v) => v.display === true)
                        ?.map((vehicle) => {
                          const image = `https://media.qiktruck.co.za/${vehicle.type}.png`;
                          return (
                            <Col
                              key={vehicle.type}
                              lg={4}
                              md={4}
                              sm={4}
                              xs={6}
                              xl={2}
                              className={"grey-border-bottom "}
                              onClick={() =>
                                this.setState(
                                  {
                                    trucktype: vehicle.type,
                                  },
                                  () => {
                                    this.getPrices();
                                    window.document
                                      .getElementById("registrationform")
                                      .scrollIntoView();
                                  }
                                )
                              }
                            >
                              <div
                                className={
                                  this.state.trucktype === vehicle.type
                                    ? "selected-truck"
                                    : ""
                                }
                              ></div>
                              <img
                                src={image}
                                alt=""
                                className={
                                  "menu-truck " +
                                  (this.state.trucktype === vehicle.type
                                    ? "menu-truck-selected"
                                    : "")
                                }
                                height="20"
                              />
                              <i
                                className={
                                  "mdi mdi-check-circle ml-2 " +
                                  (this.state.trucktype === vehicle.type
                                    ? "checked-truck"
                                    : "hide")
                                }
                              ></i>
                              <p className="menu-truck-tip">
                                {vehicle.tip.shortform}
                                <br /> <b>±{vehicle.tonnes} Ton</b>
                              </p>
                            </Col>
                          );
                        })}
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
            <p id={"registrationform"}></p>
            {trucktype?.length > 2 && vehicleid?.length > 2 && (
              <p style={{ color: "green" }}>
                <hr />
                <img
                  src={`https://media.qiktruck.co.za/${this.state.trucktype}.png`}
                  alt=""
                  width={130}
                />{" "}
                <br />
                <b style={{ fontSize: 24 }}>
                  Your vehicle received a quotation request.
                </b>
                <hr />
              </p>
            )}
            {trucktype.length > 0 && (
              <>
                <p style={{ color: "orange" }}>
                  Check suggestions in orange below. Based on other drivers and
                  quotations Averages.
                </p>
                <p style={{ color: "#007bff" }}>
                  Check industry Averages in blue below.
                </p>
              </>
            )}
            <h5>Submit your Prices below</h5>
            <br />
            {(!vehicleid || vehicleid === "") && (
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <FormGroup>
                    <span className="price-survey-label">Your name</span>
                    <div>
                      <Input
                        id="fullname"
                        name="fullname"
                        type="text"
                        onChange={(e) => {
                          this.setState({ fullname: e.target.value });
                        }}
                      />{" "}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <FormGroup>
                    <span className="price-survey-label">Your email</span>
                    <div>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />{" "}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <FormGroup>
                    <span className="price-survey-label">
                      Your mobile number
                    </span>
                    <div>
                      <Input
                        id="mobilenumber"
                        name="mobilenumber"
                        type="number"
                        onChange={(e) => {
                          this.setState({ mobilenumber: e.target.value });
                        }}
                      />{" "}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <FormGroup className="mb-4">
                    <span className="price-survey-label">Vehicle location</span>
                    <PlacesAutocomplete
                      type="text"
                      className="form-control"
                      id="location"
                      placeholder="enter street address"
                      required
                      onPlaceSelected={(place) => {
                        if (!place?.geometry?.location?.lat()) {
                          Bugsnag.notify(
                            new Error(
                              "V REGISTRATION place not found: " +
                                this.state.email
                            )
                          );
                        }
                        this.setState({
                          location: {
                            lat: place?.geometry?.location?.lat(),
                            lng: place?.geometry?.location?.lng(),
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            <hr />
            <Row>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <h5>Vehicle hire (With Driver)</h5>
                  <em>Prices must include fuel</em>
                  <br />
                  <br />
                  <br />
                  <span className="price-survey-label">
                    Daily Hire Price (Max 100Km per day)
                  </span>
                  <div>
                    <Input
                      id="daily"
                      name="daily"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ daily: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">R</span>
                    {this.showTip(tips.daily)}
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <span className="price-survey-label">
                    Weekly Hire Price (Max 700Km per week)
                  </span>
                  <div>
                    <Input
                      id="weekly"
                      name="weekly"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ weekly: e.target.value });
                      }}
                    />
                    <span className="price-survey-currency">R</span>
                    {this.showTip(tips.weekly)}
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <span className="price-survey-label">
                    Monthly Hire Price (Max 2800Km per month)
                  </span>
                  <div>
                    <Input
                      id="monthly"
                      name="monthly"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ monthly: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">R</span>
                    {this.showTip(tips.monthly)}
                  </div>
                </FormGroup>
              </Col>

              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <br />
                  <br />
                  <h5>Insurance cover</h5>
                  <em>Your current payments or previous quotation.</em>
                  <br />
                  <em>Leave empty if unavailable.</em>
                  <br />
                  <br />
                  <span className="price-survey-label">
                    Your Insurance provider for the vehicle
                  </span>
                  <div>
                    <Input
                      id="insurer"
                      name="insurer"
                      type="text"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ insurer: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">Name</span>
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <span className="price-survey-label">
                    Total Monthly insurance cost
                  </span>
                  <div>
                    <Input
                      id="insurance"
                      name="insurance"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ insurance: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">R</span>
                  </div>
                  {this.showTipHint(tips.insurance)}
                </FormGroup>
              </Col>

              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <br />
                  <br />
                  <h5>GIT - Goods In Transit insurance</h5>
                  <em>Your current payments or previous quotation.</em>
                  <br />
                  <em>Leave empty if unavailable.</em>
                  <br />
                  <br />
                  <span className="price-survey-label">
                    Your GIT provider for the vehicle
                  </span>
                  <div>
                    <Input
                      id="gitter"
                      name="gitter"
                      type="text"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ gitter: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">Name</span>
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <span className="price-survey-label">
                    Total Monthly git cost
                  </span>
                  <div>
                    <Input
                      id="gitcost"
                      name="gitcost"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ gitcost: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">R (cost)</span>
                  </div>
                  {this.showTipHint(tips.gitcost)}
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <span className="price-survey-label">Total git COVER</span>
                  <div>
                    <Input
                      id="gitcover"
                      name="gitcover"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ gitcover: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">
                      R (full cover)
                    </span>
                  </div>
                  {this.showTipHint(tips.gitcover)}
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <br />
                  <br />
                  <h5>Vehicle tracking</h5>
                  <em>Do you have a tracker on your vehicle?</em>
                  <br />
                  <em>Leave empty if unavailable.</em>
                  <br />
                  <br />
                  <br />
                  <span className="price-survey-label">
                    Your Tracking device provider for the vehicle
                  </span>
                  <br />
                  <div>
                    <Input
                      id="tracker"
                      name="tracker"
                      type="text"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ tracker: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">Name</span>
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12} sm={12} className="price-survey-row">
                <FormGroup>
                  <span className="price-survey-label">
                    Total Monthly Vehicle Tracking cost
                  </span>
                  <div>
                    <Input
                      id="tracking"
                      name="tracking"
                      type="number"
                      className="price-survey-field"
                      onChange={(e) => {
                        this.setState({ tracking: e.target.value });
                      }}
                    />{" "}
                    <span className="price-survey-currency">R</span>
                  </div>
                  {this.showTipHint(tips.tracking)}
                </FormGroup>
              </Col>
            </Row>

            {this.state.loading === false && this.state.apierror.length > 0 && (
              <p className="error-message">{this.state.apierror}</p>
            )}
            <div>
              <br />
              <p style={{ color: "brown" }}>
                1. Prices must include fuel costs.
                <br />
                2. Prices must include a Driver.
                <br />
                3. Orders go to the most affordable transporter.
                <br />
                <br />
                <span style={{ color: "orange" }}>
                  All values in Orange are just suggestions based on other
                  drivers.
                </span>
              </p>
              <br />
              <Button
                className="float-right mb-4"
                color="primary"
                size="sm"
                onClick={() => {
                  this.registerVehicleHirePrice();
                }}
                disabled={!this.validStepOne() || this.state.loading}
              >
                SAVE YOUR PRICES <i className="mdi mdi-arrow-up-bold ml-2"></i>
              </Button>
              <div
                className="selected-button-truck"
                onClick={() =>
                  window.document
                    .getElementById("vehiclepicker")
                    .scrollIntoView()
                }
              >
                <div className="highlight"></div>
                {this.state.trucktype?.length > 0 ? (
                  <>
                    <img
                      src={`https://media.qiktruck.co.za/${this.state.trucktype}.png`}
                      alt=""
                    />
                    <br />
                    <span style={{ color: "grey" }}>Change vehicle?</span>
                  </>
                ) : (
                  <span style={{ color: "red" }}>
                    Select a vehicle. No vehicle selected
                  </span>
                )}
              </div>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
