function validateObject(obj) {
    var errors = [];

    if (obj.promocode && typeof obj.promocode !== 'string') {
        errors.push({property: 'promocode', error: 'Invalid promocode'});
    }

    if (!Number.isInteger(obj.runners) || obj.runners < 0) {
        errors.push({property: 'runners', error: 'Invalid number of runners'});
    }

    if (!Number.isInteger(obj.floors) || obj.floors < 0) {
        errors.push({property: 'floors', error: 'Invalid number of floors'});
    }

    if (!obj.description || typeof obj.description !== 'string') {
        errors.push({property: 'description', error: 'Invalid description'});
    }

    if (!obj.delivery || typeof obj.delivery.lat !== 'number' || typeof obj.delivery.lng !== 'number') {
        errors.push({property: 'delivery', error: 'Invalid delivery coordinates'});
    }

    if (!obj.collection || typeof obj.collection.lat !== 'number' || typeof obj.collection.lng !== 'number') {
        errors.push({property: 'collection', error: 'Invalid collection coordinates'});
    }

    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!obj.email || !emailRegex.test(obj.email)) {
        errors.push({property: 'email', error: 'Invalid email'});
    }

    var mobileNumberRegex = /^[0-9]{10,15}$/;
    if (!obj.mobilenumber || !mobileNumberRegex.test(obj.mobilenumber)) {
        errors.push({property: 'mobilenumber', error: 'Invalid mobile number'});
    }

    if (!obj.firstname || typeof obj.firstname !== 'string') {
        errors.push({property: 'firstname', error: 'Invalid firstname'});
    }

    if (!obj.lastname || typeof obj.lastname !== 'string') {
        errors.push({property: 'lastname', error: 'Invalid lastname'});
    }

    var dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!obj.collectiondate || !dateRegex.test(obj.collectiondate)) {
        errors.push({property: 'collectiondate', error: 'Invalid collection date'});
    }

    var timeRegex = /^\d{2}:\d{2}$/;
    if (!obj.collectiontime || !timeRegex.test(obj.collectiontime)) {
        errors.push({property: 'collectiontime', error: 'Invalid collection time'});
    }

    if (!Array.isArray(obj.goodslist) || obj.goodslist.length === 0) {
        errors.push({property: 'goods', error: 'Invalid goods list'});
    }

    return errors;
}

module.exports = validateObject;