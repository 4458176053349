import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <SEO
          title={"Transportation Network."}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={"Data based routing and job allocation."}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/transportation-network"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h3>
                  Learn more about our use of Data Analytics to improve
                  job scheduling and routing for all our trips.
                </h3>

                <p>
                  <i className="mdi mdi-star"></i>We are on a mission to create
                  the largest Load Capacity dataset.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Organising and
                  packaging this load capacity as a service.
                </p>
                <p>
                  <i className="mdi mdi-star"></i>Making Load Capacity available and
                  affordable.
                </p>
                <p>
                  <b>Density</b>
                  <img
                    className="google-map"
                    src="https://media.qiktruck.co.za/southafrica-map-vehicles-qiktruck.png"
                    alt="media.qiktruck.co.za/southafrica-map-vehicles-qiktruck"
                  />
                  <br />
                </p>
                <p>
                  <b>Vehicles</b>
                  <img
                    className="google-map"
                    src="https://media.qiktruck.co.za/gauteng-map-vehicles-qiktruck.png"
                    alt="media.qiktruck.co.za/gauteng-map-vehicles-qiktruck"
                  />
                  <br />
                </p>
                <p>
                  <b>Jobs and vehicles</b>
                  <img
                    className="google-map"
                    src="https://media.qiktruck.co.za/johannesburg-map-vehicles-qiktruck.png"
                    alt="media.qiktruck.co.za/johannesburg-map-vehicles-qiktruck"
                  />
                  <br />
                </p>
                <p>
                  <b> support@qiktruck.co.za </b>for more information.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
