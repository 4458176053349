import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import { withRouter } from "react-router";
import constants from "../../Constants";
import HeaderTips from "../common/HeaderTips";
const baseURL = constants.baseURL;
class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      openLogIn: false,
      loading: false,
      success: false,
      apierror: "",
      email: "",
      otp: "",
      id: "",
      showPasswordField: false,
    };
  }
  validStepOne() {
    const { email } = this.state;
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
  validStepTwo() {
    const { email, otp, id } = this.state;
    return email !== "" && otp !== "" && id !== "";
  }
  getDriverOTP() {
    const { email } = this.state;
    this.setState({ loading: true, apierror: "" });
    axios
      .post(baseURL + "/customer-otp", { email })
      .then((response) => {
        this.setState({
          loading: false,
          showPasswordField: true,
          id: response.data.id,
        });
      })
      .catch((e) => {
        alert(
          "An account error occured please try again or contact your admin."
        );
        this.setState({
          loading: false,
        });
      });
  }
  validateOTP() {
    const { email, otp, id } = this.state;
    this.setState({
      loading: true,
      apierror: "",
    });
    axios
      .post(baseURL + "/validate-customer-otp", {
        email,
        otp,
        id,
      })
      .then((response) => {
        if (response.status === 200) {
          //set cookie
          cookie.save("qtcid_", response.data.qtcid_, {
            path: "/",
            maxAge: 86400,
            secure: true,
          });
          this.setState(
            {
              loading: false,
              apierror: "",
            },
            () => (window.location.href = "/customer-profile")
          );
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Request failed. Please try again.",
        });
      });
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar expand="lg" className={"navbar-custom " + this.props.navClass}>
          <div className="menu-container">
            <div className="new-menu">
              <NavbarBrand
                className="logo"
                onClick={() =>
                  !cookie.load("qtcid_")
                    ? this.props.history.push(
                        "/?src=hdr&t=" + new Date().getTime()
                      )
                    : this.props.history.push("/customer-profile")
                }
              >
                <img
                  src={
                    "https://media.qiktruck.co.za/dark-green-qiktruck-logo.png"
                  }
                  alt=""
                  className="logo-dark"
                />
                {/* <Flags.ZA className="country-flag moving-flag" />
                <Flags.KE className="country-flag moving-flag" />
                <Flags.ZW className="country-flag moving-flag" />
                <Flags.BW className="country-flag moving-flag" />
                <Flags.NG className="country-flag moving-flag" /> */}
              </NavbarBrand>
            </div>

            {/* <div
              className="customer-login"
              onClick={() =>
                !cookie.load("qtcid_")
                  ? this.setState({ openLogIn: true })
                  : this.props.history.push("/customer-profile")
              }
              // href={
              //   "/track-order?" + (cookie.load("currentOrder") || "noorders")
              // }
            >
              {!cookie.load("qtcid_") ? (
                <span className="mdi mdi-cart"> Customers ></span>
              ) : (
                <span className="mdi mdi-cart"> Profile / Menu ></span>
              )}
            </div> */}
            <div className="custom-nav">
              <HeaderTips />
              {/* <div
                className="nav-delivery-quotation tracker"
                onClick={() =>
                  !cookie.load("qtcid_")
                    ? this.setState({ openLogIn: true })
                    : this.props.history.push("/customer-profile")
                }
                // href={
                //   "/track-order?" + (cookie.load("currentOrder") || "noorders")
                // }
              >
                {!cookie.load("qtcid_") ? "Business login" : "Dashboard"}
              </div> */}
            </div>
            <Modal isOpen={this.state.openLogIn}>
              <ModalHeader>
                <i className={"mdi mdi-account order-received"}></i>
                Customers
              </ModalHeader>
              <ModalBody>
                {this.state.showPasswordField === false && (
                  <>
                    <FormGroup className="login-form">
                      <h5>Let's start</h5>
                      <Input
                        id="email"
                        name="email"
                        placeholder="Email address"
                        type="email"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                      <br />
                      <Button
                        className="fill-width mb-4"
                        color="primary"
                        onClick={() => {
                          this.getDriverOTP();
                        }}
                        disabled={!this.validStepOne() || this.state.loading}
                      >
                        Next
                        <i className="mdi mdi-arrow-right ml-2"></i>
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openLogIn: false,
                          });
                        }}
                        className="float-left"
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                  </>
                )}
                {this.state.showPasswordField && (
                  <>
                    <div
                      onClick={() =>
                        this.setState({
                          showPasswordField: false,
                        })
                      }
                    >
                      <p>
                        <i className="mdi mdi-arrow-left ml-3 orange">
                          email not received? click here.
                        </i>
                        <br />
                      </p>
                    </div>
                    <FormGroup className="login-form">
                      <h5 for="otp">Check your email for the password</h5>
                      <Input
                        id="otp"
                        name="otp"
                        placeholder="Enter password here"
                        type="otp"
                        onChange={(e) => {
                          this.setState({
                            otp: e?.target?.value?.toUpperCase(),
                          });
                        }}
                      />
                      <br />
                      <Button
                        className="fill-width  mb-4"
                        color="primary"
                        onClick={() => {
                          this.validateOTP();
                        }}
                        disabled={!this.validStepTwo() || this.state.loading}
                      >
                        Next
                        <i className="mdi mdi-arrow-right ml-2"></i>
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openLogIn: false,
                          });
                        }}
                        className="float-left"
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                  </>
                )}
                {this.state.loading === false &&
                  this.state.apierror.length > 0 && (
                    <p className="error-message">{this.state.apierror}</p>
                  )}
              </ModalBody>
              <ModalFooter>
                <br />
                <p>
                  <b>Drivers</b> app or website >{" "}
                  <a href="https://driver.qiktruck.co.za">Driver Login</a>
                </p>
              </ModalFooter>
            </Modal>
          </div>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default withRouter(NavbarPage);
