import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import SEO from "react-seo-component";
import LatLngToAddress from "../../components/common/LatLngToAddress";
import constants from "../../Constants";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let orderid = "";
    let email = "";
    let paynow = "";
    let success = "";
    let cancel = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      orderid = queryParams.get("orderid") || "";
      email = queryParams.get("email") || "";
      paynow = queryParams.get("paynow") || "";
      success = queryParams.get("success") || "";
      cancel = queryParams.get("cancel") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      loading: false,
      trackedOrderValid: false,
      apierror: "",
      orderid,
      email,
      paynow,
      orderdetails: {},
      success,
      cancel,
      orders: [],
    };
  }
  getOrders() {
    this.setState({
      loading: true,
      apierror: "",
    });
    axios
      .get(baseURL + "/customer-orders", {
        headers: {
          "Content-Type": "application/json",
          Authorization: cookie.load("qtcid_"),
        },
      })
      .then((response) => {
        this.setState({
          orders: response.data,
        });
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    if (cookie.load("qtcid_")) this.getOrders();
  }

  render() {
    return (
      <React.Fragment>
        <SEO
          title={"Track your orders."}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/orders"}
          twitterUsername={"qiktrucksa"}
        />
        <div className="orders-table">
          <h2 className="font-18 text-uppercase">Your orders</h2>
          <hr />
          {this.state.loading && (
            <center>
              <p>fetching your orders...</p>
            </center>
          )}
          {!this.state.loading && this.state.orders.length === 0 && (
            <center>
              <p>0 orders to date</p>
              <a
                href="/quote"
                onClick={() => {
                  window.location.href = "/quote";
                  //force navigation to quotations page
                }}
              >
                <p className="footer-quotation-button font-16">
                  <span>
                    Get a FREE Instant Quotation &gt;
                  </span>
                </p>
              </a>
            </center>
          )}
          {/* 
              collectiondate: "2022-05-27"
              category: "furniture"
              collectionlat: -26.0918881
              collectionlng: 27.9933079
              collectiontime: "12:12"
              customeronetimepasswordid: "9904ef49-48dd-4124-b43a-699cc0ac817d"
              customquote: 0
              dateadded: "2022-05-21T14:19:49.066Z"
              deliverylat: -29.85868039999999
              deliverylng: 31.0218404
              description: "durban"
              driverpaid: 0
              email: "MATSIYANA.E@GMAIL.COM"
              firstname: "emmanuel"
              floors: 0
              lastname: "matsiyana"
              mobilenumber: "0824277002"
              orderid: "5534c717-3154-4d83-ae25-79764e2b2e2b"
              orderstatus: "in-review"
              otp: "RMTLLA"
              promocode: ""
              quoteid: "d1c642cb-3214-4a75-ba23-9f6a649f65a4"
              runners: 0
              sellingprice: "911000"
              trucktype: "1.5tonopen"
              vehicleid: "ee4846a3-c8b3-4444-9b2c-ba1b46dc939d" 
              */}
          {this.state.orders?.map((o, index) => (
            <a
              key={index}
              className="order-row"
              href={
                "/track-order?paynow=yes&orderid=" +
                o?.orderid +
                "&email=" +
                o?.email
              }
            >
              <div>
                <div className="order-vehicle">
                  <div className="order-date">
                    {o?.collectiondate} at {o?.collectiontime}
                  </div>
                  <img
                    src={`https://media.qiktruck.co.za/${o?.trucktype}.png`}
                    alt=""
                    height={50}
                  />
                </div>

                <div className="order-price">
                  <CurrencyFormat
                    value={o?.sellingprice / 100}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"R"}
                    decimalScale={2}
                  />
                </div>
              </div>
              <div className="order-details">
                <b className={o?.orderstatus}>{o?.orderstatus}</b>
                <br />
                <LatLngToAddress
                  lat={o?.collectionlat}
                  lng={o?.collectionlng}
                />
                <b> to </b>
                <LatLngToAddress lat={o?.deliverylat} lng={o?.deliverylng} />
                <br />
                {o?.description}
              </div>
            </a>
          ))}
          {this.state.loading === false && this.state.apierror.length > 0 && (
            <p className="error-message">{this.state.apierror}</p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Section;
