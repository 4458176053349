import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"On-Demand Goods transport Services in Pietermaritzburg"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/goods-transport-pietermaritzburg"}
          pageHeading={"On-Demand Goods transport Services in Pietermaritzburg"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get on-demand Goods transport services in Pietermaritzburg with our team of experienced drivers and state-of-the-art vehicles at QikTruck. We offer fast, reliable delivery whenever you need it, with real-time tracking and easy online ordering. Sign up now and start enjoying the convenience of our services."}
          pageDescription2={"Need on-demand Goods transport services in Pietermaritzburg? Look no further! Our team of experienced drivers and state-of-the-art vehicles at QikTruck offer fast, reliable delivery whenever you need it, with real-time tracking and easy online ordering. Sign up now and start enjoying the convenience of our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
