import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Professional Transportation company Services in Ferndale"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/transportation-company-ferndale"}
          pageHeading={"Professional Transportation company Services in Ferndale"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get professional Transportation company services in Ferndale with our team of experienced drivers and state-of-the-art vehicles at QikTruck. We are dedicated to providing top-notch service and ensuring that your transportation and delivery needs are met with the highest level of professionalism and reliability. Sign up now and start enjoying the peace of mind that comes with our services."}
          pageDescription2={"Need professional Transportation company services in Ferndale? Look no further! Our team of experienced drivers and state-of-the-art vehicles at QikTruck are dedicated to providing top-notch service and ensuring that your transportation and delivery needs are met with the highest level of professionalism and reliability. From package delivery to furniture transportation, we have the solutions you need. Sign up now and start enjoying the peace of mind that comes with our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
