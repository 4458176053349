import React, { Component } from "react";
import SEO from "react-seo-component";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import Bugsnag from "@bugsnag/js";
import constants from "../../Constants";
import util from "../../util";

const baseURL = constants.baseURL;

class Section extends Component {
  constructor() {
    super();

    let email = "";
    let mailer = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      email = queryParams.get("email") || "";
      mailer = queryParams.get("mailer") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      email: email || "",
      mailer: mailer || "",
      loading: false,
    };
  }

  getVehicleTypes(category) {
    this.setState({
      loading: true,
    });
    const { email, mailer } = this.state;
    axios
      .post(baseURL + "/unsubscribe", {
        email,
        mailer,
        category,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((repos) => {
        window.location.href = "/";
      })
      .catch((e) => {
        console.log("Wellll");
      });
  }
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review you instant online quotation now."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/developers"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h5>
                  Here's a promo code for <h1>up to 10% off</h1> on your next order
                </h5>
                <br />
                <h3>QIKTRUCK2023</h3>
                <br />
                Up to 10% off!
                <br />
                Discount code.
                <br />
                <br />
                <hr />
                <br />
                <p>
                  If you wish to stop receiving the <b>Daily</b> newsletter
                  please{" "}
                  <button
                    disabled={this.state.loading}
                    onClick={() => this.getVehicleTypes("daily")}
                  >
                    click here
                  </button>
                </p>
                <p>
                  If you wish to stop receiving the <b>Weekly</b> newsletter
                  please{" "}
                  <button
                    disabled={this.state.loading}
                    onClick={() => this.getVehicleTypes("weekly")}
                  >
                    click here
                  </button>
                </p>
                <p>
                  If you wish to stop receiving the <b>Monthly</b> newsletter
                  please{" "}
                  <button
                    disabled={this.state.loading}
                    onClick={() => this.getVehicleTypes("monthly")}
                  >
                    click here
                  </button>
                </p>
                <p>
                  If you wish to stop receiving <b>General Company</b>{" "}
                  newsletter please{" "}
                  <button
                    disabled={this.state.loading}
                    onClick={() => this.getVehicleTypes("company")}
                  >
                    click here
                  </button>
                </p>
                <p>
                  If you wish to stop receiving general <b>Promotional</b>{" "}
                  newsletter please{" "}
                  <button
                    disabled={this.state.loading}
                    onClick={() => this.getVehicleTypes("promotional")}
                  >
                    click here
                  </button>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
