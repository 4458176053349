import axios from "axios";
import React, { Component } from "react";
import SEO from "react-seo-component";
import {
  Col,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import constants from "../../Constants";
import util from "../../util";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAPIRequest: false,
      success: false,
      apierror: "",
      apitype: "free",
      loading: false,
      done: false,
      email: "",
      description: "",
    };
  }
  validStepOne() {
    const { email, description } = this.state;
    return email?.length > 5 && description?.length > 5;
  }

  subscribe() {
    const { email, description, apitype } = this.state;
    this.setState({ loading: true, apierror: "" });
    axios
      .post(baseURL + "/api-registrations", {
        email,
        description,
        apitype,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        this.setState({
          loading: false,
          done: true,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          done: false,
          apierror: "an error occured, please try again.",
        });
      });
  }
  render() {
    return (
      <React.Fragment>
        <SEO
          title={
            "QikTruck Developer API - Streamline Your Logistics Operations"
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "QikTruck Developer API enables seamless integration of your logistics operations with our platform. Access our extensive network of carriers and optimize your supply chain. Instant Quoations, gps tracking and many more."
          }
          image={"https://media.qiktruck.co.za/qiktruck-developer-api.png"}
          pathname={"https://qiktruck.co.za/developers"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home" className="developer-api-documentation">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h3>Developer API</h3>
                <br />
                <img
                  src="https://media.qiktruck.co.za/warehouse-apis.jpg"
                  alt="warehouse-apis"
                  className="warehouse-apis"
                />
                <br />
                <br />
                <p>
                  QikTruck is a revolutionary platform that offers on-demand
                  truck and driver hire, allowing businesses and individuals to
                  manage their deliveries seamlessly. With the QikTruck
                  Developer API, you can integrate your application with our
                  platform and offer your customers the ability to
                  <br /> <br />
                  <i className="mdi mdi-star ml-2 orange"></i>
                  Get quotations,
                  <br />
                  <i className="mdi mdi-star ml-2 orange"></i>
                  Schedule trips,
                  <br />
                  <i className="mdi mdi-star ml-2 orange"></i>
                  Insure trips,
                  <br />
                  <i className="mdi mdi-star ml-2 orange"></i>
                  Manage deliveries, and
                  <br />
                  <i className="mdi mdi-star ml-2 orange"></i>
                  Process payments, all in one place.
                </p>
                <p>
                  Learn more about specific transportation API endpoints at{" "}
                  <a href="https://api.qiktruck.co.za/docs">
                   QikTruck API documentation
                  </a>
                </p>
                <p>
                  We have over 1000 vehicles registered and distributed across
                  South Africa.
                </p>
                <p>
                  Our API provides you with a wide range of features, including
                  real-time tracking, automated dispatching, and route
                  optimization. You can also use our API to access data related
                  to fuel consumption, vehicle maintenance, and driver behavior,
                  providing you with valuable insights to optimize your
                  operations.
                </p>
                <p>
                  Integrating with QikTruck is simple and hassle-free. We offer
                  comprehensive documentation and a sandbox environment to help
                  you get started quickly. Our API supports RESTful
                  architecture, which means you can use any programming language
                  or framework of your choice.
                </p>
                <h2>Why choose QikTruck Developer API?</h2>
                <ul>
                  <li>
                    Offer your customers a seamless experience by integrating
                    on-demand truck and driver hire into your application
                  </li>
                  <li>
                    Reduce development time and costs by leveraging our existing
                    platform
                  </li>
                  <li>
                    Access real-time data to make informed decisions and drive
                    business growth
                  </li>
                  <li>Benefit from our reliable and scalable infrastructure</li>
                  <li>
                    Enjoy dedicated support from our experienced team of
                    developers
                  </li>
                </ul>
                <h2>Impact of the QikTruck Developer API?</h2>
                <p>
                  A company, XYZ, manages a fleet of 50 trucks and processes an
                  average of 500 deliveries per day across multiple cities.
                  Their current delivery management system is manual and
                  time-consuming, resulting in delays and inefficiencies in
                  their operations.
                </p>
                <p>
                  A company, XYZ-2, has customers and they need to deliver
                  purchased goods to each customer. Booking a local truck and
                  driver is a manual process and it takes a lot of time and
                  effort. Company does not know if the transportation will be
                  insured.
                </p>
                <p>
                  A company, XYZ-3, is a real estate agency. Their customers
                  need help with calculating moving costs. Booking a local truck
                  and driver is a manual process and it takes a lot of time and
                  effort. Company does not know if the transportation will be
                  insured. Company XYZ-3 can benefit from QikTruck discounted
                  trips.
                </p>
                <h2>Solution:</h2>
                <p>
                  XYZ decides to integrate the QikTruck Developer API into their
                  web system to streamline their operations and improve their
                  customer experience. <br />
                  The QikTruck API enables them to access real-time quotations,
                  tracking, automated dispatching, and route optimization,
                  providing valuable insights to optimize their operations.
                </p>
                <h2>Impact:</h2>
                <ul>
                  <li>
                    Reduced delivery time by 25%: With real-time tracking, XYZ
                    can monitor the location of their trucks and drivers in
                    real-time, enabling them to make adjustments to their routes
                    on the fly. As a result, they are able to reduce the average
                    delivery time by 25%.
                  </li>
                  <li>
                    Increased delivery capacity by 20%: The QikTruck API's
                    automated dispatching feature enables XYZ to optimize their
                    fleet and increase their delivery capacity by 20%, resulting
                    in higher customer satisfaction and revenue growth.
                  </li>
                  <li>
                    Improved operational efficiency by 30%: With access to data
                    related to fuel consumption, vehicle maintenance, and driver
                    behavior, XYZ is able to make informed decisions and
                    optimize their operations. They reduce fuel consumption by
                    15% and vehicle maintenance costs by 10%, resulting in a 30%
                    improvement in operational efficiency.
                  </li>
                  <li>
                    Enhanced customer experience: By offering their customers
                    the ability to track their deliveries in real-time and
                    providing accurate delivery estimates, XYZ is able to
                    improve their customer experience and increase customer
                    loyalty.
                  </li>
                  <li>
                    Save in development costs: By leveraging the QikTruck
                    Developer API's existing platform, XYZ is able to reduce
                    their development time and costs by 50%, resulting in large
                    savings.
                  </li>
                </ul>
                <p>
                  By integrating the QikTruck Developer API into their delivery
                  management system, XYZ is able to streamline their operations,
                  reduce costs, and improve their customer experience. The
                  QikTruck API's real-time tracking, automated dispatching, and
                  route optimization features provide valuable insights to
                  optimize their operations, resulting in significant
                  improvements in efficiency, capacity, and delivery time.
                </p>
                <p>Ready to revolutionize your delivery management?</p>{" "}
                <p>
                  <img
                    src="https://media.qiktruck.co.za/qiktruck-developer-api.png"
                    alt="qiktruck-developer-api"
                    className="api-images"
                  />
                </p>
                <p>
                  <h2>Pricing:</h2>
                  The QikTruck Developer API offers four different pricing tiers
                  to accommodate businesses of varying sizes and needs. The Free
                  Tier is ideal for those who want to try out the API without
                  committing to any cost. The Starter Plan is suitable for small
                  to medium-sized businesses that require basic truck and driver
                  information and scheduling capabilities, with a fixed number
                  of trips per day or month. The Pro Plan offers more advanced
                  features and a higher volume of trips than the Starter Plan,
                  with premium features such as real-time tracking and advanced
                  analytics. The Enterprise Plan is tailored to the specific
                  needs of large businesses, with custom pricing based on volume
                  and features required, and offers unlimited trips, dedicated
                  support, and custom branding on vehicles.
                </p>
                <div className="free-api-tier">
                  <ul>
                    <h2>Free Tier:</h2>

                    <li>
                      This tier is a great way to try out the QikTruck Developer
                      API without any cost commitment.
                    </li>
                    <li>
                      The limited number of API calls is 300 calls per day or
                      6000 calls per month, depending on the complexity of the
                      API and the resources required to serve the requests.
                    </li>
                    <li>
                      Basic truck and driver information and basic scheduling
                      capabilities.
                    </li>
                    <li>
                      All your drivers are AI driven (D) for trip events. They
                      accept trips, decline with reason, cancel trips to cover
                      all real world use cases.
                    </li>
                    <li>
                      Test server data. All responses are generated by AI driven
                      tools in a simulated environment.
                    </li>
                    <li>We still use real world prices.</li>
                    <button
                      onClick={() => {
                        this.setState({
                          openAPIRequest: true,
                          apitype: "free",
                        });
                      }}
                    >
                      Request API access
                    </button>
                  </ul>
                </div>
                <div className="developer-prices">
                  <ul>
                    <h2>Starter Plan:</h2>
                    <li>
                      Small to medium-sized businesses that require basic truck
                      and driver information, and scheduling capabilities.
                    </li>
                    <li>
                      The fixed number of trips is 100 trips per day or 2000
                      trips per month, depending on the needs of the target
                      market.
                    </li>
                    <li>
                      Driver wait time (acceptance rate) is up to 3 hours.
                    </li>
                    <li>
                      Technical support and documentation is included to help
                      customers integrate the API into their existing systems
                      and workflows.
                    </li>
                    <li>
                      <strong>Up to 30% discount on trips.</strong>
                    </li>
                    <button
                      onClick={() => {
                        this.setState({
                          openAPIRequest: true,
                          apitype: "starter",
                          apierror: "",
                        });
                      }}
                    >
                      Request API access
                    </button>
                  </ul>
                  <ul>
                    <h2>Pro Plan:</h2>
                    <li>
                      The Pro plan provides additional value to businesses that
                      require a higher volume of trips or more advanced
                      features, such as real-time tracking and priority support.
                    </li>
                    <li>
                      The number of trips could be around 2,000-5,000 trips per
                      day or 60,000-150,000 trips per month, depending on the
                      target market.
                    </li>
                    <li>
                      Driver wait time (acceptance rate) is 30 minutes max.
                    </li>
                    <li>
                      Premium features include more advanced scheduling
                      capabilities, customizable alerts and notifications, and
                      advanced analytics and reporting.
                    </li>
                    <li>
                      <strong>Up to 40% discount on trips.</strong>
                    </li>
                    <button
                      onClick={() => {
                        this.setState({
                          openAPIRequest: true,
                          apitype: "pro",

                          apierror: "",
                        });
                      }}
                    >
                      Request API access
                    </button>
                  </ul>
                  <ul>
                    <h2>Enterprise Plan:</h2>
                    <li>
                      The Enterprise plan tailored to the specific needs of
                      large businesses and includes custom pricing based on the
                      volume of trips and the features required.
                    </li>
                    <li>
                      The plan include unlimited trips, advanced features such
                      as predictive analytics, dedicated support, and a
                      service-level agreement (SLA) to ensure uptime and
                      performance.
                    </li>
                    <li>
                      We work closely with enterprise customers to understand
                      their specific needs and requirements, and to provide
                      customized solutions that meet those needs.
                    </li>
                    <li>Custom prices.</li>
                    <li>Custom branding on vehicles.</li>
                    <button
                      onClick={() => {
                        this.setState({
                          openAPIRequest: true,
                          apitype: "enterprise",
                          apierror: "",
                        });
                      }}
                    >
                      Request API access
                    </button>
                  </ul>
                </div>
                <p>
                  <img
                    src="https://media.qiktruck.co.za/qiktruck-user-experience-api.png"
                    alt="qiktruck-user-experience-api.png"
                    className="api-images"
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <Modal isOpen={this.state.openAPIRequest} className="api-request">
          <ModalHeader>
            <i className={"mdi mdi-access-point-network order-received"}></i>
            API Access
            <i
              className={"mdi mdi-close-circle-outline ml-2 close price-tag"}
              onClick={() => {
                this.setState({
                  openAPIRequest: false,
                  apitype: "free",
                  apierror: "",
                  done: false,
                });
              }}
            ></i>
          </ModalHeader>
          <ModalBody>
            <Input
              id="email"
              name="email"
              placeholder="you@email.com *"
              autoComplete="new-password"
              onChange={(e) => {
                this.setState({ email: e.target.value, apierror: "" });
              }}
            />
            <br />
            <Input
              id="description"
              name="description"
              type="textarea"
              defaultValue={this.state.description}
              style={{ minHeight: 240 }}
              placeholder="Describe your use case... *
              Example:
              We want to use the api for our website and mobile apps.
              We are a company providing services on this website https://...  or app ...
              "
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
            />

            {this.state.loading && (
              <p className="float-right">
                {" "}
                ... processing <i className="mdi mdi-truck-fast ml-2"></i>
              </p>
            )}
            {this.state.done && (
              <p className="float-right dark-green">
                ... request received ... request in review{" "}
                <i className="mdi mdi-trophy-award ml-2"></i>
              </p>
            )}
            {this.state.apierror.length > 0 && (
              <p className="float-right dark-red">
                ...{this.state.apierror}
                <i className="mdi mdi-heart-broken ml-2"></i>
              </p>
            )}
          </ModalBody>
          {!this.state.done && (
            <ModalFooter>
              <Button
                color="primary"
                className="float-right wide-button"
                onClick={() => {
                  this.subscribe();
                }}
                disabled={!this.validStepOne() || this.state.loading}
              >
                Next
                <i className="mdi mdi-arrow-right ml-2"></i>
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default Section;
