import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import SEO from "react-seo-component";
import { Col, Container, Label, Row } from "reactstrap";
import LatLngToAddress from "../../components/common/LatLngToAddress";
import constants from "../../Constants";
import { withRouter } from "react-router";
import ProfileMenuHeader from "../customer-profile/ProfileMenuHeader";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let contractorderid = "";
    let email = "";
    let paynow = "";
    let success = "";
    let cancel = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      contractorderid = queryParams.get("contractorderid") || "";
      email = queryParams.get("email") || "";
      paynow = queryParams.get("paynow") || "";
      success = queryParams.get("success") || "";
      cancel = queryParams.get("cancel") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      loading: false,
      trackedOrderValid: false,
      apierror: "",
      contractorderid,
      email,
      paynow,
      orderdetails: {},
      success,
      cancel,
    };
  }

  customerGetTrip() {
    const { contractorderid } = this.state;

    this.setState({
      loading: true,
      apierror: "",
    });
    axios
      .post(
        baseURL + "/customer-get-trip",
        {
          contractorderid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: cookie.load("qtcid_"),
          },
        }
      )
      .then((response) => {
        this.setState({
          orderdetails: response.data,
        });
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally(() => this.setState({ loading: false }));
  }
  cancelOrder() {
    const { contractorderid } = this.state.orderdetails;

    this.setState({
      loading: true,
      apierror: "",
    });

    axios
      .post(
        baseURL + "/customer-stop-contract-order",
        {
          contractorderid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: cookie.load("qtcid_"),
          },
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally((e) => this.setState({ loading: false }));
  }
  componentDidMount() {
    this.customerGetTrip();
  }

  render() {
    const { orderdetails } = this.state;
    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/track-order"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section contract-trip-track" id="location">
          <Container style={{ display: "grid" }}>
            <Row>
              <Col lg={12}>
                <div className="hero-wrapper mb-4">
                  <ProfileMenuHeader
                    heading={"Track your trip"}
                    back={"/contract-trips"}
                  />
                  <h4 className="text-uppercase">
                    {orderdetails.orderstatus !== "complete" &&
                      orderdetails.orderstatus !== "stopped" && (
                        <>
                          <div
                            className="edit-trip"
                            onClick={() => {
                              this.props.history.push(
                                "/contract-trip-edit?v=" +
                                  orderdetails.vehicleid +
                                  "&customercontractid=" +
                                  orderdetails.customercontractid +
                                  "&contractorderid=" +
                                  orderdetails.contractorderid
                              );
                            }}
                          >
                            Edit
                          </div>
                        </>
                      )}
                  </h4>
                </div>
              </Col>
            </Row>
            {this.state.loading === false && this.state.apierror.length > 0 && (
              <p className="error-message">{this.state.apierror}</p>
            )}
            {this.state.orderdetails.contractorderid && (
              <div className="receipt">
                <Row>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Order ID</Label>
                    <div>{orderdetails.contractorderid.split("-")[0]}</div>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Date & Time</Label>
                    <div>
                      {orderdetails.collectiondate} at{" "}
                      {orderdetails.collectiontime}
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Status</Label>
                    <div>{orderdetails.orderstatus || "assigned"}</div>
                    <br />
                  </Col>

                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Driver details</Label>
                    <div>
                      {orderdetails.driverfirstname ||
                        (orderdetails.orderstatus !== "stopped" && (
                          <div className="looking-for-driver">
                            "Assigning Driver"
                            <br /> <br /> Please wait while we find a driver
                            near you.
                            <br />
                            The driver will call you once they accept the job.
                          </div>
                        ))}
                      <br />
                      {orderdetails.drivermobilenumber || ""}
                      <br />
                      <br />
                      {/* <Button
                        className="mb-4"
                        size="sm"
                        type="submit"
                        style={{ fontSize: "10px" }}
                        onClick={() => {
                          this.props.history.push(
                            "/checkout-load-capacity?v=" +
                              orderdetails.vehicleid +
                              "&customercontractid=" +
                              orderdetails.customercontractid
                          );
                        }}
                      >
                        BOOK DRIVER AGAIN
                        <i className="mdi mdi-star  green-color ml-2"></i>
                      </Button> */}
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={6}>
                    <Label>Vehicle</Label>
                    <div>
                      <img
                        src={`https://media.qiktruck.co.za/${orderdetails?.drivertrucktype}.png`}
                        alt=""
                        height={60}
                      />
                      <br />
                      {orderdetails.driverlicensenumber || ""}
                      <br />
                      <br />
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={6}>
                    <Label>Distance</Label>
                    <div>
                      {((orderdetails.distance || 0) / 1000)}km
                    </div>
                    <br />
                    <Label>Time</Label>
                    <div>
                      {(orderdetails.triptime / 60).toPrecision(2)} minutes
                    </div>
                    <br />
                  </Col>
                </Row>
                <br />
                <br />
                <h4>Collection</h4>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label>From</Label>
                    <div>
                      <LatLngToAddress
                        lat={orderdetails.collectionlat}
                        lng={orderdetails.collectionlng}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label>Instructions</Label>
                    <p>{orderdetails.collectiondescription}</p>
                    <p>{orderdetails.collectionemail}</p>
                    <p>{orderdetails.collectionmobilenumber}</p>
                  </Col>
                </Row>
                <br />
                <br />
                <h4>Delivery</h4>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label>To</Label>
                    <div>
                      <LatLngToAddress
                        lat={orderdetails.deliverylat}
                        lng={orderdetails.deliverylng}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label>Instructions</Label>
                    <p>{orderdetails.deliverydescription}</p>
                    <p>{orderdetails.deliveryemail}</p>
                    <p>{orderdetails.deliverymobilenumber}</p>
                  </Col>
                </Row>
                <br />
                <br />
                {orderdetails.orderstatus !== "complete" &&
                  orderdetails.orderstatus !== "stopped" && (
                    <>
                      <div
                        className="cancel-trip"
                        onClick={() => {
                          this.cancelOrder();
                        }}
                        disabled={this.state.loading}
                      >
                        Stop this order
                      </div>

                      <div
                        className="edit-trip"
                        onClick={() => {
                          this.props.history.push(
                            "/contract-trip-edit?v=" +
                              orderdetails.vehicleid +
                              "&customercontractid=" +
                              orderdetails.customercontractid +
                              "&contractorderid=" +
                              orderdetails.contractorderid
                          );
                        }}
                      >
                        Edit
                      </div>
                    </>
                  )}
                {this.state.loading === false &&
                  this.state.apierror.length > 0 && (
                    <p className="error-message">{this.state.apierror}</p>
                  )}
              </div>
            )}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Section);
