import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* Importing section */}
        <Section
          title={"Affordable uber truck Services in Jeffreys Bay"}
          titleTemplate={"QikTruck"}
          titleSeparator={"-"}
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/uber-truck-jeffreys-bay"}
          pageHeading={"Affordable uber truck Services in Jeffreys Bay"}
          pageSubHeading={"For your home or business"}
          pageDescription={"Get affordable uber truck services in Jeffreys Bay with our competitive rates and flexible payment options. Whether you need to transport packages, furniture, or equipment, we have the solutions you need. Sign up now and start enjoying the convenience and affordability of our services."}
          pageDescription2={"Looking for affordable uber truck services in Jeffreys Bay? Look no further! Our competitive rates and flexible payment options make it easy to get the uber truck services you need. Whether you need to transport packages, furniture, or equipment, we have the solutions you need. Sign up now and start enjoying the convenience and affordability of our services."}
        />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
