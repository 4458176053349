import React from "react";
import { Col } from "reactstrap";
import CurrencyFormat from "react-currency-format";
const PriceView = ({
  sellingPrice,
  discount,
  loading,
  trucktype,
  acceptOffer,
  disabled,
  showButton,
  setPromoCode,
}) => {
  return (
    <Col
      lg={4}
      sm={12}
      className={
        "sellingPrice " +
        (sellingPrice ? "active" : "") +
        " " +
        (disabled ? "notactive" : "")
      }
    >
      <div className="fullquote">
        {showButton === false ? (
          <div className="fullquote-days">
            The price below can be edited based on the offer from the customer.
            Enter the customer offer amount above.
          </div>
        ) : (
          <div className="fullquote-days">
            The price shown is for a fully packed vehicle with Driver and fuel
            included.
            {!disabled && (
              <div className="hints hints2">
                If your goods do not require a full vehicle.{" "}
                <button
                  className="inline-button"
                  onClick={() => {
                    //set promocode called EXPRESS
                    //set discount to 20%
                    //set showButton to false
                    setPromoCode("EXPRESS");
                  }}
                >
                  click here.
                </button>
                <br />
                The new price will be negotiable after review by the QikTruck
                team.
                <br />
                <br />
                <button
                  className="inline-button orange"
                  onClick={() => {
                    //set promocode called EXPRESS
                    //set discount to 20%
                    //set showButton to false
                    setPromoCode("");
                  }}
                >
                  change to a full vehicle
                </button>
              </div>
            )}
          </div>
        )}
        <img
          src={`https://media.qiktruck.co.za/${trucktype}.png`}
          alt=""
          className={"menu-mini-truck-left "}
          height="20"
        />
        <div className="trucktype-left">
          {trucktype?.length < 20 ? trucktype : "..."}
        </div>
        {loading === false ? (
          <>
            {discount > 0 && <span className="oldprice">negotiable price</span>}
            {/* {sellingPrice > 0 && ( */}
            <>
              <CurrencyFormat
                value={sellingPrice / 100} //cents to rands
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R"}
                decimalScale={2}
                className="sellingPriceValue"
              />
              {/* {sellingPrice > 0 && ( */}
              {showButton !== false && (
                <button
                  className="sell-nextbutton"
                  disabled={disabled}
                  onClick={() => acceptOffer()}
                >
                  Next
                </button>
              )}
              {/* )} */}
            </>
            {/* )} */}
          </>
        ) : (
          "calculating..."
        )}
      </div>
    </Col>
  );
};

export default PriceView;
