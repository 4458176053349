import axios from "axios";
import React, { Component } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import constants from "../../Constants";
import util from "../../util";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      done: false,
      email: "",
    };
  }

  validStepOne() {
    const { email } = this.state;
    return email?.length > 5;
  }

  subscribe() {
    const { email } = this.state;
    this.setState({ loading: true });
    axios
      .post(baseURL + "/newsletter-subscriptions", {
        email,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        this.setState({
          loading: false,
          done: true,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  onChange(e) {
    console.log("E", e);
  }
  render() {
    return (
      <Row className="newsletter-form">
        <Col lg={4} md={12} sm={12}></Col>
        <Col lg={4} md={12} sm={12}>
          {!this.state.done ? (
            <FormGroup>
         
              <br />
              <br />
              <br />
              <br />
              <h4>Insider news</h4>
              <Input
                id="email"
                name="email"
                placeholder="you@email.com"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />

              <br />
              <Button
                color="primary"
                className="float-right"
                onClick={() => {
                  this.subscribe();
                }}
                disabled={
                  !this.validStepOne() || this.state.loading || this.state.done
                }
              >
                Subscribe
                <i className="mdi mdi-arrow-right ml-2"></i>
              </Button>
            </FormGroup>
          ) : (
            <p>
              Thaanks a lotttto! Check {this.state.email} for all the news we
              send every week.{" "}
              <a href="/quote/#quotestart" className="nav-get-quote">
                Get a Quotation :)
              </a>
            </p>
          )}
        </Col>
        <Col lg={4} md={12} sm={12}></Col>
      </Row>
    );
  }
}

export default Section;
