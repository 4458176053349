import React from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

const items = [
  {
    id: 1,
    content:
      "Arrived 10 min earlier and arrived safely at delivery site, exelent and profesional service.",
    author: "SL MO",
  },
  {
    id: 2,
    content:
      "I can recommend using QikTruck. Service was awesome. No long waits for goods to be delivered. Really a great experience. I will definitely use them again. Well done",
    author: "Garry",
  },
  {
    id: 2,
    content:
      "They are so professional, very neat ,very fast , , they arrived on time and very friendly and sooo affordable. Thank you so much",
    author: "Soft travels",
  },
  {
    id: 2,
    content:
      "QikTruck was such a lifesaver. Quick response and great communication and on time.  Basetsana is really very professional and eager to assist. Small and large trucking needs ….give them a try.",
    author: "Natasia McMahon",
  },
  {
    id: 2,
    content:
      "A seamless process and a great experience - quick and easy to make the booking and the transport arrived well on time with a friendly and helpful driver. I would highly recommend this service, well done QikTruck and thank you.",
    author: "Brigitte Cook",
  },
  // ... add more reviews as needed ...
];

const ReviewsSlider = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div className="review-content">"{item.content}"</div>
        <CarouselCaption captionText={item.author} captionHeader="" />
      </CarouselItem>
    );
  });

  return (
    <div className="reviews-slider-container">
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
};

export default ReviewsSlider;
