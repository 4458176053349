import React, { Component } from "react";

import { Col, Row } from "reactstrap";
class MoreInfo extends Component {
  render() {
    return (
      <Row>
        <Col lg={4} md={4} className="truck-hire">
          <h3 className="font-18">
            <i className="mdi mdi-truck"></i> Goods transportation
          </h3>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Deliver goods to your Customers,
              Warehouse, Shop and Home.
            </a>
          </p>
          <p>
            <a href="/bakkie-hire?src=landing">
              <i className="mdi mdi-star"></i>Manufacturers, Retailers, Supply
              shops.
            </a>
          </p>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Once-off, Short term and Long term
              transportation.
            </a>
          </p>
          <img
            src="https://media.qiktruck.co.za/31-december-2023-1.jpg"
            className="intro-image"
            alt="empowering-farmers"
          ></img>
        </Col>
        <Col lg={4} md={4} className="move-anything">
          <h3 className="font-18">
            <i className="mdi mdi-home"></i> Man and Van Services
          </h3>
          <p>
            <a href="/furniture-delivery-services?src=landing">
              <i className="mdi mdi-star"></i>Home and Office moves on demand.
            </a>
          </p>
          <p>
            <a href="/furniture-delivery-services?src=landing">
              <i className="mdi mdi-star"></i>Furniture, Home Equipment,
              Personal goods.
            </a>
          </p>
          <p>
            <a href="/furniture-delivery-services?src=landing">
              <i className="mdi mdi-star"></i>Get help to carry and deliver on
              time.
            </a>
          </p>
          <img
            src="https://media.qiktruck.co.za/31-december-2023-2.jpg"
            className="intro-image"
            alt="empowering-farmers"
          ></img>
        </Col>

        <Col lg={4} md={4} className="move-goods">
          <h3 className="font-18">
            <i className="mdi mdi-clock"></i> Schedule trips
          </h3>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Schedule transportation for your
              goods.
            </a>
          </p>
          <p>
            <a href="/bakkie-hire?src=landing">
              <i className="mdi mdi-star"></i>Trained and trusted drivers for long distance and short distance trips.
            </a>
          </p>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Insured services 24/7 for the vehicle and goods in transit.
            </a>
          </p>
          <img
            src="https://media.qiktruck.co.za/31-december-2023-3.jpg"
            className="intro-image"
            alt="empowering-farmers"
          ></img>
        </Col>
      </Row>
    );
  }
}

export default MoreInfo;
